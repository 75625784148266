import {GET_RA_CATEGORIES, GET_RA_REG_COST} from './actions'
const initState = {};
const RaReducer = (state=initState,{type,payload})=>{
    switch(type){
        case GET_RA_CATEGORIES:
            return {...state, assortment:payload}
        case GET_RA_REG_COST:
            return {...state, regCostAss:payload}
        default: return state;
    }
}
export default RaReducer;