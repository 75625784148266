import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Table, Checkbox, Grid } from 'semantic-ui-react';
import QuickViewDetails from './QuickViewDetails';
import Dialog from 'react-dialog';
import _, { findLastIndex } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import renderImages from '../common/RenderImages';
import { TierIcon, ProductIndicators } from './TierIcon';
import { MaskPrice } from './MaskPrice';
import { computeGrossMargin } from '../ImpulseMerchandising/POG';
import { closeModal, addimg, delimg,isMaxQtyValid } from './productHandler';
import utilities,{maskPrice,getPrefernceType,shouldHideCostForPreference,shouldHideOrigAndCurrCostForPreference,
  shouldHideSUGG_RETAILForPreference,showSRPMaskPricePref,getCloseoutRegularCost, getCloseoutCurrentCost} from '../../utils/utilities';
import AddToCartModal from '../Cart/AddToCartModal';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import history from '../../history';
import { orderTypes, addProduct, createNAddCart } from '../Cart/actions';
import { orderNames } from '../Cart/constants';
import { nonStockMessage } from './constants';
import BusinessRules from './BusinessRules'
import { 
  DISCONTINUED, 
  CLOSEOUTS, 
  IMPULSE_MERCHANDISING, 
  RETAIL_ASSORTMENTS, 
  HOTDEALS, 
  MONTHLY_SPECIALS,
  HIDE_QUICKVIEW_PAGE,
  TV_PRODUCT_SCAN,
  COMPETITOR_PRODUCTS,
  PLANOGRAMS
} from '../Categories/constants';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import  './ProductListing.css';
import config from '../../config';
import {  getImageURl } from '../../urlConstants'
export const scanListing = [TV_PRODUCT_SCAN, COMPETITOR_PRODUCTS];
export const assortmentListing = [RETAIL_ASSORTMENTS,PLANOGRAMS,'DOM',IMPULSE_MERCHANDISING];

class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.closeModal = closeModal.bind(this);
    this.addimg = addimg.bind(this);
    this.delimg = delimg.bind(this);

    this.state = {
      visible: false,
      wordLimit: 0,
      show: false,
      productFav:'',
      item: '',
      quickviewdata: '',
      click: '',
      expand: '',
      productSelectedForModal: null,
      indexForModal: 0,
      indexForModalMs:0,
      isMultistoreModalOpen:false,
      isAddToCartModalOpen: false,
      viewOnly: this.props.viewType,
      missing: this.props?.missing && this.props?.missing ? this.props?.missing : null,
      toastMessage: '' //  Product added to cart..
    };
  }

  expand = num => {
    this.setState({
      expand: 'expan',
      item: num
    });
  };
  enterFav = (Item_num) =>{
    this.setState({
      show:true,
      productFav:Item_num
    })
  }
  leaveFav  = (product) =>{
    this.setState({
      show:false,
      productFav:''
    })
  }
  componentDidMount (){
    this.resize();
    window.addEventListener("resize", this.resize);
  }
  resize=()=>{
    if(window.innerWidth <= 1024){
      this.setState({wordLimit: 12});
    }
    if(window.innerWidth <= 1097){
      this.setState({wordLimit:42});
    }
    if(window.innerWidth <= 1240){
      this.setState({wordLimit:92});
    }
    else {
      this.setState({wordLimit:142})
    }
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.resize);
  }

  getLongDesc = (product,isDOM) => {
    let longDesc = '';
    let Item_num = product.Item_Number;
    if(isDOM){
      if(config.showMediumDesp){
        longDesc = product.Product_Title  || product.short_desc ||  product.Product_Name;
      }
      else{
        longDesc = product.short_desc ||  product.Product_Name;
      }
    }
    else{
      if(config.showMediumDesp){
        longDesc = product.Product_Title || product.Long_Description ||  product.Product_Name;
      }
      else{
        longDesc = product.Long_Description;
      }
    }
    return(
      longDesc && (
        <p className='listingDescription listingDescription-compact'>
          {longDesc.length < (this.state.wordLimit + 8)
            ? this.highlightText(longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase()) 
            : this.highlightText(longDesc.substr(0,1).toUpperCase() + longDesc.substr(0, this.state.wordLimit).substr(1, this.state.wordLimit).toLowerCase()  + '...')}
          <u className='cursor_p'
              onClick={() => this.expand(Item_num)}
          >
            {longDesc.length < (this.state.wordLimit + 8)
              ? ''
              : 'more'}
          </u>
        </p>
      )
    )
  }

  highlightText = (longDesc) => {
    let searchData = (this.props.searchValue && this.props.searchKey === 'Description') ? 
    decodeURIComponent(this.props.searchValue).toLowerCase() : '';
    let index = searchData ? longDesc.toLowerCase().indexOf(searchData) : -1;
    return(
      <React.Fragment>{index!=-1 ? longDesc.substring(0,index):longDesc}<b style={{color: '#CC0000'}}> {index!=-1 ? longDesc.substring(index,index+searchData.length):''}</b>{index!=-1 ? longDesc.substring(index + searchData.length): ''}</React.Fragment>
    )
  }

  getLongDescription = (product,isDOM) => {
    let longDesc = '';
    if(isDOM){
      if(config.showMediumDesp){
        longDesc = product.Product_Title  || product.short_desc ||  product.Product_Name;
      }
      else{
        longDesc = product.short_desc ||  product.Product_Name;
      }
    }
    else{
      if(config.showMediumDesp){
        longDesc = product.Product_Title || product.Long_Description ||  product.Product_Name;
      }
      else{
        longDesc = product.Long_Description;
      }
    }
    if(longDesc){
      longDesc =  longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase()
    }
    else{
      longDesc = "";
    }
    return longDesc;
  }

  openCartModal = (product, index) => {
    this.setState({
      productSelectedForModal: product,
      indexForModal: index,
      isAddToCartModalOpen: true
    });
  };
  
  multistoreHandleCarts= (product, index) => {
    this.setState({
      productSelectedForModal: product,
      indexForModalMs: index,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.setState({ isMultistoreModalOpen: false });
  }

  closeCartModal = () => {
    this.setState({ isAddToCartModalOpen: false});
  };

  closeModalexpand = () => {
    this.setState({
      expand: '',
      item: ''
    });
  };

  openModal(data) {
    this.setState({
      visible: true,
      item: data,
      click: 'clicked',
      expand: ''
    });
    this.props.blurfun && this.props.blurfun(true);
  };

  handleAddToCart = (product, index) => {
    const { tableType, addToCart, isShowAllWarehouse, storeId } = this.props;
    const qty = this.props.quantities[index];
    const or = this.props.overrideRetails[index];
    const rdcNum = utilities.rdcBasedonCentralship(product.rdctype, product.CsRDC, this.props.rdc);
    if (isShowAllWarehouse && qty > product.Available) {
      history.push({
        pathname: `/all-warehouses/${product.Item_Number}`,
        state: { fromPLP: true }
      });
      return;
    }
    if (!this.props.validateProductQuantity(
          product.S_Pack_Type,
          product.S_Pack_Qty,
          this.props.quantities[index],
          product.maxQty
        )) {
      return;
    }
    if(tableType=='Monthly Specials') {
      let qtyValues = qty.filter(val=> {
        if(parseInt(val)) {
          return parseInt(val)
        }
      })
      let totalQty = qtyValues.length > 0 && qtyValues.reduce((a,b) => parseInt(a)+parseInt(b))
      if(!isMaxQtyValid(totalQty,product.maxQty,false)) {
        return
      }
    }
    const isAssortmentListing =
      tableType === RETAIL_ASSORTMENTS ||
      tableType === 'Planograms' ||
      tableType === 'DOM' ||
      tableType === IMPULSE_MERCHANDISING ||
      tableType === 'Reunion' ||
      tableType === MONTHLY_SPECIALS;
    if (
      !isAssortmentListing &&
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length > 0
    ) {
      this.openCartModal(product, index);
    } else if (
      !isAssortmentListing &&
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length === 0
    ) {
      if (product.whStockStatusMessage && product.whStockStatusMessage.toLowerCase() == 'not stocked') {
        utilities.showToast(nonStockMessage, true);
        return;
      } else {
        addToCart(
          product.Item_Number,
          product.Member_Cost,
          qty,
          or,
          product.S_Pack_Type,
          product.S_Pack_Qty,
          'new',
          rdcNum,
          storeId
        );
      }
    } else if (tableType === 'Reunion') {
      addToCart(
        product.Item_Number,
        index,
        product.relay_nbr,
        product.Vendor_Id,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        product.promo_nbr,
        storeId
      );
    } else if (tableType === MONTHLY_SPECIALS) {
      const  carts = this.props.msCarts;
      const eventId = carts && carts.filter(cart => cart.cartName === `EVENT#${this.props.eventId}`)
      let newCart = (carts.length === 0 || eventId.length === 0) ? true : false
      addToCart(
        product.Item_Number,
        index,
        product.RelayNbr,
        product.PromoNbr,
        product.vendorID,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        storeId,
        newCart,
        product.maxQty
      );
    }else if (tableType === IMPULSE_MERCHANDISING) {
      const imInfoType = orderNames[IMPULSE_MERCHANDISING].infoType;
      const existingCart = this.props.carts.find(
        cart => cart.TypeId === imInfoType
      );
      if (!existingCart) {
        this.props.createNAddCart(
          product.Item_Number,
          qty,
          or,
          product.Member_Cost,
          'REGULAR',
          imInfoType,
          orderTypes[imInfoType],
          this.props.rdc,
          storeId
        );
      } else {
        this.props.addProduct(
          existingCart.cartId,
          product.Item_Number,
          qty,
          or,
          product.Member_Cost,
          'REGULAR',
          existingCart.cartName,
          this.props.rdc,
          storeId
        );
      }
    } else if(tableType === 'DOM'){
        addToCart(
          product.Item_Number,
          product.spc_cost,
          qty,
          or,
          product.S_Pack_Type,
          product.S_Pack_Qty,
          product.promo_nbr
        );
    }else {
      if (product.whStockStatusMessage && product.whStockStatusMessage.toLowerCase() == 'not stocked') {
        utilities.showToast(nonStockMessage, true);
        return;
      } else {
        addToCart(
          product.Item_Number,
          product.Member_Cost,
          qty,
          or,
          product.S_Pack_Type,
          product.S_Pack_Qty,
          null,
          rdcNum,
          storeId
        );
      }
    }
  };
  handleKeyDown = (e, product, index)=>{
    const msCheck = utilities.msExist(this.props.multiStoreDetails);
    if(this.props.multiStoreDetails.access !== 1 && e.key === 'Enter' && !this.props.isReunionOnlyStore){
        if(msCheck && msCheck > 1){
          this.multistoreHandleCarts(product, index);
        }
        else{
          this.handleAddToCart(product,index);
        }
    }
  } 
  render() {
    const {
      quantities,
      overrideRetails,
      handleChange,
      handleChangeblur,
      tableType,
     // categoryData: products,
      addToCart,
      isShowAllWarehouse,
      assortmentId,
      assortmentName
    } = this.props;
    let { categoryData: products} =  this.props
    if(this.props?.isCtbAssortment && [0, 2].includes(parseInt(this.props?.missing))) {
      products = products.filter(product=>{
        if(parseInt(product.IsMissingItem)=== 0) {
          return product
        }
        else {
          return
        }
      })
    }
    const isDiscontinued = tableType === DISCONTINUED;
    const isReunionListing = tableType === 'Reunion';
    const isMonthlyListing = tableType === MONTHLY_SPECIALS;
    const isDefaultListing = tableType === 'default';
    const isNewItemstListing = tableType === 'NewItems';
    const isCloseoutItems = tableType === CLOSEOUTS;
    const isHotDeals = tableType === HOTDEALS;
    const isImListing = tableType === IMPULSE_MERCHANDISING;
    const isScanListing =  scanListing.indexOf(tableType) !== -1 ? true : false;
    const isAssortmentListing = assortmentListing.indexOf(tableType) !== -1 ? true : false;      
    const link = '/product/';
    const tooltipStyle = {
      display: this.state.show ? 'block' : 'none'
    }
    let msCheck = utilities.msExist(this.props.multiStoreDetails);
    const getPrefer =  getPrefernceType(this.props);
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const showSuggRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
    const showSRPMaskPrice = showSRPMaskPricePref(this.props.viewType,getPrefer);
    return (
      <>
        <AddToCartModal
          isAddtoCartModalopen={this.state.isAddToCartModalOpen}
          closeCartModal={this.closeCartModal}
          addToCart={addToCart}
          product={this.state.productSelectedForModal}
          index={this.state.indexForModal}
          qty={quantities[this.state.indexForModal]}
          or={overrideRetails[this.state.indexForModal]}
        ></AddToCartModal>
          <MultistoreToCartModal
            isMultistoreModalOpen={this.state.isMultistoreModalOpen}
            assortmentId={this.props.assortmentId}
            closeMultistoreModal={this.closeMultistoreModal}
            multiStoreCartavailable={this.props.multiStoreCartavailable}
            product={this.state.productSelectedForModal}
            quantitiesMs={this.props.quantities}
            overrideRetail = {this.props.overrideRetails[this.state.indexForModalMs]}
            tableType={this.props.tableType}
            indexCheck={this.state.indexForModalMs}
            handleChangeValue={this.props.handleChangeValue}
        />
        {products &&
          products.map((product, index) => {
            const isDiscontinuedHideAddToCart = product.whStockStatus === 'DIS' ? true : false
            const isHighlight = (tableType===IMPULSE_MERCHANDISING || tableType==='Planograms') 
                                  && parseInt(product.recQty) !== parseInt(quantities[index]);
            return (
              <Segment className={isHighlight?'highlight product_card-compact':'product_card-compact'}
                       key={index}
              >
                {this.state.visible && this.state.item === product.Item_Number && (
                  <div className='QuickviewDialog' onMouseLeave={this.closeModal}>
                    <Dialog width='1' height='1' position='absolute'>
                      <QuickViewDetails
                        getprefer={
                          this.props.getprefer &&
                          this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
                        }
                        tableType={tableType}
                        Item_Number={product.Item_Number}
                        viewType={this.props.viewType}
                        QuickCost ={product.Member_Cost}
                        productPlp = {product}
                      />
                    </Dialog>
                  </div>
                )}
                {this.state.expand === 'expan' &&
                  this.state.item === product.Item_Number && (
                    <div className='DescriptionDialog' onMouseLeave={this.closeModalexpand}>
                      <Dialog width='1' height='1' position='absolute'>
                      {tableType === 'DOM' ?
                              this.getLongDescription(product,true) :
                              this.getLongDescription(product,false)
                      }
                      </Dialog>
                    </div>
                  )}
                <div
                  className={
                    this.state.click === 'clicked'
                      ? 'blur_state two-column-display'
                      : 'two-column-display'
                  }
                >
                  <div className={this.props.img_style === 'none'?'one-column-display-invisible':'one-column-display'} onMouseLeave={() => {this.delimg('delimg');}}>
                    <div className='card_list_wrap card-position'>
                      <Link
                       onClick={this.props.setBackButtonPath} 
                        to={{
                          pathname: link + product.Item_Number,
                          state: {
                            title: this.props.title,

                            listType: this.props.listType,
                            assortmentType: this.props.assortmentType,
                            muzak:product.muzak,
                            savingsOutOfWarehouse:product.savingsOutOfWarehouse,
                            promoHistory :product.promoHistory ,
                            futurePromo:product.futurePromo,
                            showAvailableField: !isShowAllWarehouse,
                            isReunionListing,
                            isHotDeals,

                            isMonthlyListing,
                            isWow:this.props.isWow,
                            MonthlySpecialSaving: product.Savings,
                            MonthlyEventCost: product.spc_cost,
                            MonthlyRegularCost: product.reg_cost,
                            MonthlyWHCost: product.future_cost > 0 ? product.future_cost : product.Cost,
                            MonthlyWHCostT1: product.T1_zfptcost >0 ? product.T1_zfptcost : 0,
                            monthlyStock: product.stocked,
                            monthlyRelayNbr:product.RelayNbr,
                            monthlyPromoNbr:product.PromoNbr,
                            monthlyAssmentNbr:product.AsstmtID,
                            missing: this.props?.missing,
                            isCtbAssortment : this.props?.isCtbAssortment,
                            assortmentName:this.props?.assortmentName,
                            assortmentId:this.props?.assortmentId
                          }
                        }}
                        className={
                          this.props.img_style === 'none'
                            ? 'disable_img_click'
                            : 'card_list_img'
                        }
                      >
                        {renderImages.getImage({
                          src: product.Image_File_Name
                            ? product.Image_File_Name
                            : product.altImg && product.altImg === 1 ?  getImageURl() + `/nsimages/${product.Item_Number}` + ".jpg"
                            :'https://images.truevalue.com/getimage.asp?id=' +
                              product.Item_Number +
                              '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
                          style: { display: this.props.img_style },
                          alt: 'product',
                          className: 'ui image image_lisitng',
                          onMouseEnter: () => {
                            this.addimg('addimg', product.Item_Number);
                          }
                        })}
                      </Link>
                    </div>
                    {HIDE_QUICKVIEW_PAGE.indexOf(tableType) === -1 &&
                      window.innerWidth > 767 && !this.props.isCtbAssortment &&(
                        <div>
                          <span
                            className='view_button-compact-visible'
                            onClick={() => {
                              this.openModal(product.Item_Number);
                            }}
                          >
                            Quick View
                          </span>
                        </div>
                      )}
                  </div>
                  <div className='fifteen wide column row-display'>
                    <div className = "plp_header">
                      <div className = "plp_product_header">
                    <Link
                           onClick={this.props.setBackButtonPath} 
                            to={{
                              pathname: link + product.Item_Number,
                              state: {
                                title: this.props.title,
                                listType: this.props.listType,
                                assortmentType: this.props.assortmentType,
                                muzak:product.muzak,
                                savingsOutOfWarehouse:product.savingsOutOfWarehouse,
                                promoHistory :product.promoHistory ,
                                futurePromo:product.futurePromo,
                                showAvailableField: !isShowAllWarehouse,
                                isReunionListing,
                                isMonthlyListing,
                                isWow:this.props.isWow,
                                MonthlySpecialSaving: product.Savings,
                                MonthlyEventCost: product.spc_cost,
                                MonthlyRegularCost: product.reg_cost,
                                MonthlyWHCost: product.future_cost > 0 ? product.future_cost : product.Cost,
                                MonthlyWHCostT1: product.T1_zfptcost >0 ? product.T1_zfptcost : 0,
                                monthlyStock: product.stocked,
                                monthlyRelayNbr:product.RelayNbr,
                                monthlyPromoNbr:product.PromoNbr,
                                monthlyAssmentNbr:product.AsstmtID,
                                missing: this.props?.missing,
                                isCtbAssortment : this.props?.isCtbAssortment,
                                assortmentName:this.props?.assortmentName,
                                assortmentId:this.props?.assortmentId
                              }
                            }}
                          >
                        <b className='First_level_details product_title-compact'>
                            {product.shortDesc || product.short_desc || product.Product_Name || product.Product_Title || product.shortdesc}
                            </b>
                          </Link>
                         
                          { !isMonthlyListing ?  <b className='vendor-span vendor-span-compact'><Link
                             onClick={this.props.setBackButtonPath} 
                              to={{
                                pathname: product.Vendor_Name
                                  ? '/vendor/' +
                                    utilities.replaceAll(
                                      product.Vendor_Name,
                                      '/',
                                      '%2F'
                                    )+ '/' +(product.Vendor_Id || product.vendorID || product.vendor_id)
                                  : '#',
                                state: { vendorId: product.Vendor_Id }
                              }}
                            >
                              {product.Vendor_Name}
                            </Link>  </b>:  <b className='vendor-name-ms'>  {product.Vendor_Name} </b>}
                         
                          </div>
                          <div className = 'compare-n-fav-compact'>
                        {!isAssortmentListing && 
                          !isScanListing && 
                          ((!isReunionListing && !isMonthlyListing)  ? (
                            <span className='ui checkbox compare-n-fav'>
                              <div className='fav_icon_hover' style={tooltipStyle}>{this.state.show===true && this.state.productFav === product.Item_Number?'Favorites':''}</div>
                              <i
                                className={
                                  product.favorited
                                    ? 'heart icon'
                                    : 'heart outline icon'
                                }
                                style={{ cursor: 'pointer'}}
                               onMouseEnter = {() =>this.enterFav(product.Item_Number)}
                               onMouseLeave = {() =>this.leaveFav(product.Item_Number)}
                                onClick={() =>
                                  this.props.toggleFavorite(product.Item_Number)
                                }
                              />
                              {!isScanListing && (
                                <>
                                  <span className = 'compare-compact'>
                                    Compare{' '}
                                  </span>
                                  <Checkbox
                                    checked={product.selected}
                                    tabIndex = '0'
                                    style={{ cursor: 'pointer'}}
                                    className='compare_checkbox'
                                    onClick={() =>
                                        this.props.selectForCompare(
                                          product.Item_Number,
                                          !product.selected
                                      )
                                    }
                                  />
                                </>
                              )}
                            </span>
                          ) : (
                            <span className='compare-n-fav padding_right'>
                              SEQ# :{product.seq_nbr}
                            </span>
                          ))}
                    </div>
                        </div>
               
                    <div className = 'plp_column-display'>
                      <div
                      className = {
                        isDiscontinued ||
                        isReunionListing ||
                        isMonthlyListing ||
                        isNewItemstListing ||
                        isCloseoutItems ||
                        isHotDeals? 'plp_text-custom': 'plp_text-default'
                      }
                        computer={
                          isDiscontinued ||
                          isReunionListing ||
                          isMonthlyListing ||
                          isNewItemstListing ||
                          isCloseoutItems ||
                          isHotDeals
                            ? 6
                            : 7
                        }
                        tablet={
                          isDiscontinued ||
                          isReunionListing ||
                          isMonthlyListing ||
                          isNewItemstListing ||
                          isCloseoutItems ||
                          isHotDeals
                            ? 5
                            : 8
                        }
                      >
                        {tableType === 'DOM' ?
                        (
                          this.getLongDesc(product,true)
                        )
                        :
                        this.getLongDesc(product,false)
                        }
                        {tableType === 'DOM' && product.promo_comment && <p className = 'listingDescription-compact'>{product.promo_comment}</p> }
                      </div>
                      <div
                        computer={
                          isDiscontinued ||
                          isReunionListing ||
                          isMonthlyListing ||
                          isNewItemstListing ||
                          isCloseoutItems ||
                          isHotDeals
                            ? 10
                            : 9
                        }
                        tablet={
                          isDiscontinued ||
                          isReunionListing ||
                          isMonthlyListing ||
                          isNewItemstListing ||
                          isCloseoutItems ||
                          isHotDeals
                            ? 9
                            : 7
                        }
                        className = {isDiscontinued ||
                          isReunionListing ||
                          isMonthlyListing ||
                          isNewItemstListing ||
                          isCloseoutItems ||
                          isHotDeals?'plp_table-compact-custom':'plp_table-compact-default'}
                      >
                        <Table stackable className={ isDiscontinued ||
                          isReunionListing ||
                          isMonthlyListing ||
                          isNewItemstListing ||
                          isCloseoutItems ||
                          isHotDeals?'very basic table table-compact':'very basic table table-compact'} >
                          <Table.Body className='listingProduct table_body-compact'>
                            {!isDefaultListing ? (
                              <CustomTableView
                                viewPOG={this.props.multiStoreDetails.viewPOG}
                                PogMbr={this.props.multiStoreDetails.PogMbr}
                                CatMbr={this.props.multiStoreDetails.CatMbr}
                                getprefer={
                                    this.props.getprefer &&
                                    this.props.getprefer.customerView
                                      ? this.props.getprefer.customerView
                                      : 'c1'
                                }
                                multiStoreDetails = {this.props.multiStoreDetails}
                                tableType={tableType}
                                assortmentId={assortmentId}
                                product={product}
                                viewType={this.props.viewType}
                                handleQtyChange={handleChange}
                                handleQtyChangeblur={handleChangeblur}
                                index={index}
                                quantities={quantities}
                                overrideRetail={overrideRetails[index]}
                                title={this.props.title}
                                listType={this.props.listType}
                                isShowAllWarehouse={isShowAllWarehouse}
                                access = {this.props.multiStoreDetails.access}
                                isCloseoutItems={this.props.isCloseoutItems}
                                RAMarketFlag={this.props.RAMarketFlag}
                                handleKeyDown = {this.handleKeyDown}
                                displayMemberCostAndLbl = {displayMemberCostAndLbl}
                              />
                            ) : (
                              <>
                                {(this.props.multiStoreDetails.access !== 1  || 
                                  (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) 
                                  ? (
                                    <>
                                      <Table.Row className = "collapsing">
                                        <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                          <span>Item#:</span>
                                        </Table.Cell>
                                        <Table.Cell className='First_level_details First_level_details-compact compact left aligned collasping'>
                                           {product.Item_Number}
	                                         </Table.Cell>
                                           {!isShowAllWarehouse ? (
                                    <>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        Pack: 
                                      </Table.Cell>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        {product.S_Pack_Type}
                                        {product.S_Pack_Qty}
                                      </Table.Cell>
                                    </>
                                  ) : (
                                    <>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'/>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing' />
                                    </>
                                  )}
                                  {showSuggRetailPrice && <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                          <span className>{utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL",getPrefer)}: </span>
                                        </Table.Cell>
                                        }
                                        {showSuggRetailPrice&&
                                        <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        {this.props.viewType===MEMBER_VIEW?"$"+utilities.toIntlNumberFormat(product.SuggestedRetail):showSRPMaskPrice?maskPrice(product.SuggestedRetail):"$"+utilities.toIntlNumberFormat(product.SuggestedRetail)}
                                        </Table.Cell>}
                                  
                                         {/* <Table.Cell className='First_level_details First_level_details-compact'>
                                          <span>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}</span>
                                        </Table.Cell>
                                        <Table.Cell className='First_level_details First_level_details-compact'>
                                        {(this.props.multiStoreDetails.access !== 1  
                                          || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr))
                                            ? <MaskPrice
                                                getprefer={
                                                  this.props.getprefer &&
                                                  this.props.getprefer.customerView
                                                    ? this.props.getprefer.customerView
                                                    : 'c1'
                                                }
                                                viewType={this.props.viewType}
                                                fieldVal={utilities.getCostForQty(
                                                  product,
                                                  quantities[index] || '',
                                                  true
                                                )}
                                                field='Member Cost'
                                              />
                                            :''
                                        }
                                        {product.tiered && (
                                          <TierIcon
                                            tierLevel={utilities.getTierLevelForQty(
                                              product,
                                              quantities[index]
                                            )}
                                          />
                                        )}
                                        </Table.Cell> */}
                                      </Table.Row>
                                      <Table.Row className = 'collapsing'>
                                        {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) ? (
                                          <>
                                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                          <span>Model#: </span>
                                        </Table.Cell>
                                        <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                          {product.Model}
                                        </Table.Cell>
                                          <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                                        <span>Alternate SKU#:  </span>
                                                      </Table.Cell>
                                           <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                                        {product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr }
                                                      </Table.Cell>
                                          </>
                                        ):( 
                                          <>
                                        <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        <span>Model#: </span>
                                      </Table.Cell>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        {product.Model}
                                      </Table.Cell>
                                      <Table.Cell></Table.Cell>
                                      <Table.Cell></Table.Cell>
                                      </>)}   
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                    <span>Override Retail: </span>
                                  </Table.Cell>
                                  <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                    <input
                                      className='qty-box input-compact'
                                      id={'or-box' + product.Item_Number}
                                      data-item-number={index}
                                      data-field='or'
                                      maxLength={7}
                                      onChange={handleChange}
                                      value={overrideRetails[index] || ''}
                                    />
                                  </Table.Cell>                                   
                                      </Table.Row>
                                    </>
                                    ) 
                                  :(
                                  <>
                                    <Table.Row className = 'collapsing'>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        <span>Item#: </span>
                                      </Table.Cell>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        {product.Item_Number}
                                      </Table.Cell>
                                      {!isShowAllWarehouse && (
                                    <>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        Pack: 
                                      </Table.Cell>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        {product.S_Pack_Type}
                                        {product.S_Pack_Qty}
                                      </Table.Cell>
                                    </>)}
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                    <span>Override Retail: </span>
                                  </Table.Cell>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                    <input
                                      className='qty-box input-compact'
                                      id={'or-box' + product.Item_Number}
                                      data-item-number={index}
                                      data-field='or'
                                      maxLength={7}
                                      onChange={handleChange}
                                      value={overrideRetails[index] || ''}
                                    />
                                  </Table.Cell>  
                                    </Table.Row>    
                                      <Table.Row className = 'collapsing'>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        <span>Model#: </span>
                                      </Table.Cell>
                                      <Table.Cell
                                        className='First_level_details First_level_details-compact collapsing'
                                      >
                                        {product.Model}
                                      </Table.Cell>
                                      {
                                        this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
                                          <>
                                            <Table.Cell className='First_level_details First_level_details-compact collapsing' style = {{backgroundColor: '#d6d8db'}}>
                                                <span>Alternate SKU#: </span>
                                            </Table.Cell>
                                            <Table.Cell className='First_level_details First_level_details-compact collapsing' style={{ width: '6rem' }}>
                                                {product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr }
                                            </Table.Cell>
                                          </>
                                        )}
                                      </Table.Row>                                                        
                                  </>
                                )} 
                                <Table.Row className = 'collapsing'>
                                  {(!isShowAllWarehouse || (isShowAllWarehouse && tableType === HOTDEALS)) ? (
                                    <>
                                    <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                    <span className={`availablelabel ${product.Available && 'First_level_details First_level_details-compact collapsing'}`}>
                                          Available:
                                        </span>
                                      </Table.Cell>
                                      <Table.Cell
                                        className={
                                          product.Available &&
                                          parseInt(product.Available) > 0
                                            ? 'First_level_details First_level_details-compact collapsing'
                                            : 'First_level_details_red cost_red_color First_level_details-compact collapsing'
                                        }
                                      >
                                        {utilities.checkForHubAndSpokeIndictor(product, 'compact')}
                                      </Table.Cell>
                                    </>
                                  ) :  (
                                    <>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        Pack: 
                                      </Table.Cell>
                                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        {product.S_Pack_Type}
                                        {product.S_Pack_Qty}
                                      </Table.Cell>
                                    </>
                                  )}
                                        { displayMemberCostAndLbl && <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                          <span>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}:</span>
                                        </Table.Cell>
                                         }
                                        { displayMemberCostAndLbl ? <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                        {(this.props.multiStoreDetails.access !== 1  
                                          || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr))
                                            ? 
                                            <MaskPrice
                                                getprefer={
                                                  this.props.getprefer &&
                                                  this.props.getprefer.customerView
                                                    ? this.props.getprefer.customerView
                                                    : 'c1'
                                                }
                                                viewType={this.props.viewType}
                                                fieldVal={utilities.getCostForQtyPlpNew(
                                                  product,
                                                  quantities[index] || ''
                                                )}
                                                field='Member Cost'
                                              />
                                            :''
                                        }
                                        {product?.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW &&(
                                          <TierIcon
                                            tierLevel={utilities.getCostForQtyPlpNew(
                                              product,
                                              quantities[index],
                                              true
                                            )}
                                            tierLetter={quantities[index] && parseInt(quantities[index]) > 0 ? true : false}
                                          />
                                        )}
                                        </Table.Cell>
                                        :
                                         <> 
                                          <Table.Cell/>
                                          <Table.Cell/>
                                         </>
                                       }
                                        <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                    Quantity:
                                  </Table.Cell>
                                  <Table.Cell className = 'collapsing'>
                                    <input
                                      className='qty-box input-compact'
                                      id={'qty-box' + product.Item_Number}
                                      type='text'
                                      data-item-number={index}
                                      data-field='qty'
                                      data-pack-type={product.S_Pack_Type}
                                      data-pack-qty={product.S_Pack_Qty}
                                      onChange={handleChange}
                                      value={quantities[index] || ''}
                                      onKeyDown = {(e)=>this.handleKeyDown(e, product, index)}
                                    />
                                    </Table.Cell>
                                   </Table.Row>
                              </>
                            )}
                            <Table.Row className = 'collapsing'>
                            {!utilities.isEmptyOrNullString(product?.whStockStatusMessage) ? (<>
                               <Table.Cell className='First_level_details First_level_details-compact collapsing'>Stock Status:</Table.Cell>
                               <Table.Cell colSpan= "3" className='First_level_details First_level_details-compact collapsing stockStausMsgTd'><span className="stockStausMsg">{product.whStockStatusMessage}</span></Table.Cell>
                               </>): null} 
                             {product.maxQty ? (<>
                               <Table.Cell className='First_level_details First_level_details-compact collapsing'>Max Qty:</Table.Cell>
                               <Table.Cell className='First_level_details First_level_details-compact collapsing'>{product.maxQty}</Table.Cell>
                               </>): null}
                            </Table.Row>
                            {/* product.altNbr && !utilities.isEmptyOrNullString(product.altNbr) && (
                              <Table.Row className = 'collapsing'>
                                <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                    <span>Rec. Alt Sku: </span>
                                </Table.Cell>
                                <Table.Cell className='First_level_details First_level_details-compact collapsing' style={{ width: '6rem' }}>
                                    <u><Link to={'/product/' + product.altNbr}>{product.altNbr}</Link></u>
                                </Table.Cell>
                              </Table.Row>
                            )*/}
                            {product.alternateItem?.Number && product.alternateItem.Number > 0 ? (
                              <Table.Row>
                                <Table.Cell className='First_level_details First_level_details-compact collapsing'>{product.alternateItem?.Label}</Table.Cell>
                                <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                                  <Link
                                     to={{
                                       pathname: '/product/' + product.alternateItem?.Number,
                                       state: {
                                         listType: this.props.listType
                                       }
                                     }}>{product.alternateItem?.Number}</Link></Table.Cell>
                              </Table.Row>
                            ): null}
                            <Table.Row>
                              {
                                 (isDiscontinued ||isCloseoutItems) && 
                                 ( this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID))?
                                  (
                                    <>
                                     <Table.Cell colspan = {2} style = {{borderLeft: 'none'}}></Table.Cell>
                                     <Table.Cell colspan = {2} style = {{borderLeft: 'noneS'}}> <span className = 'restricted-product'>{(product?.Restrictions?.length > 0)
                                    ? utilities.restrictedDetail(product?.Restrictions)
                                    : ''}</span></Table.Cell>
                                  <Table.Cell className='First_level_details First_level_details-compact collapsing' style = {{borderRight: 'none'}}>
                                      Alternate SKU#:
                                    </Table.Cell>
                                    <Table.Cell className = 'First_level_details First_level_details-compact collapsing' style = {{borderRight: 'none'}}>
                                      {product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}
                                    </Table.Cell>
                                    </>
                                  ):(
                                    tableType === 'Planograms' || tableType === HOTDEALS || tableType === IMPULSE_MERCHANDISING || tableType === 'DOM'||
                                    tableType === 'RetailAssortments'?
                                  (<>
                                    <Table.Cell colspan = {2} style = {{borderLeft: 'none', borderRight:'none'}}> <span className = 'restricted-product'>{(product?.Restrictions?.length > 0)
                                    ? utilities.restrictedDetail(product?.Restrictions)
                                    : ''}</span></Table.Cell>
                                    </>):(
                                      tableType === 'NewItems' || isDiscontinued || isCloseoutItems?
                                      <><Table.Cell colspan = {4} style = {{borderLeft: 'none'}}></Table.Cell>
                                      <Table.Cell colspan = {2} style = {{borderRight:'none'}}><span className = 'restricted-product'> {(product?.Restrictions?.length > 0)
                                    ? utilities.restrictedDetail(product?.Restrictions)
                                    : ''}</span></Table.Cell>
                                      </>: tableType ===  MONTHLY_SPECIALS ? <Table.Cell colspan = {4} style = {{borderLeft: 'none'}}></Table.Cell>:
                                      <>
                                       <Table.Cell colspan = {2} style = {{borderLeft: 'none'}}></Table.Cell>
                                       <Table.Cell colspan = {2} style = {{borderRight: 'none'}}> <span className = 'restricted-product'>{(product?.Restrictions?.length > 0)
                                    ? utilities.restrictedDetail(product?.Restrictions): ''}</span></Table.Cell>
                                      </>
                                    )
                                  )
                              }
                              {isHotDeals ? (<Table.Cell colspan = {4} style = {{border: 'none'}}></Table.Cell>): null}
                              <Table.Cell colspan = {2} style = {{borderLeft: 'none'}}>
                                    {tableType !== COMPETITOR_PRODUCTS &&
                          (!isAssortmentListing ||
                            tableType === 'DOM' ||
                            (isImListing &&
                              (assortmentId === 'CLIP01' ||
                                assortmentId === 'CTOP01'))) && (
                            <div className = 'plp_add_to_cart-compact'>
                              {this.props.multiStoreDetails.access !== 1 && !isDiscontinuedHideAddToCart ? (
                                <>
                                 {msCheck && msCheck > 1 ?
                                 !this.props.isReunionOnlyStore ?
                                  <button
                                  className='red ui button plp_button-compact'
                                  onClick={() =>
                                    this.multistoreHandleCarts(product, index)
                                  }
                                >
                                    Add to Store
                                  </button>
                                  : <>
                                      <button className='red ui button' disabled>Add to Store</button>
                                      <p class="disabledMessage disabledMessage-compact">*Ordering restricted</p>
                                    </>
                                :!this.props.isReunionOnlyStore ?
                                <button
                                  className='red ui button plp_button-compact'
                                  onClick={() =>
                                    this.handleAddToCart(product, index)
                                  }
                                >
                                  Add to Cart
                                </button>
                                : <>
                                    <button className='red ui button plp_button-compact' disabled>Add to Cart</button>
                                    <p class="disabledMessage disabledMessage-compact">*Ordering restricted</p>
                                  </>
                              }
                              </>
                              )
                              :
                              <>
                              <button className='red ui button' style={{ visibility:"hidden" }}>Add to Store</button>
                              </>}
                            </div>
                          )}
                                      </Table.Cell>

                            </Table.Row>               
                          </Table.Body>
                        </Table>                        
                      </div>
                    </div>
                    <div className = "plp_footer">
                <div className='indicators-div indicators-div-compact'>
                          <ProductIndicators product={product} isPLP='PLP' view = 'compact' />
                        </div>  
                          </div> 

                  </div>
                </div>
              </Segment>
            );
          })}
      </>
    );
  }
}

const mapStateToProps = state => {
  const carts = state.cart.orders.allCarts;
  return {
    carts,
    currentCartType: state?.cart?.orders?.currentCart?.cartInfoName,
    rdc: state.SessionReducer.rdc,
    whCarts: carts && carts.filter(cart => cart.cartInfoName === 'REGULAR'),
    msCarts: carts && carts.filter(cart => cart.cartInfoName === 'MS'),
    getprefer: state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,

  };
};

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        addProduct,
        createNAddCart
      },
      dispatch
    )
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);

const CustomTableView = ({
  product,
  viewType,
  tableType,
  handleQtyChange,
  handleQtyChangeblur,
  index,
  quantities,
  overrideRetail,
  title,
  listType,
  isShowAllWarehouse,
  getprefer,
  assortmentId,
  access,
  CatMbr,
  viewPOG,
  PogMbr,
  multiStoreDetails,
  RAMarketFlag,
  handleKeyDown,
  displayMemberCostAndLbl
}) => {
  const quantity = quantities[index];
  const memberCost = 
    <MaskPrice
      viewType={viewType}
      getprefer={getprefer}
      fieldVal={
        tableType === CLOSEOUTS ||
        tableType === DISCONTINUED || tableType === 'NewItems' ? utilities.getCostForQtyPlpNew(product, quantity || '')
        : tableType === IMPULSE_MERCHANDISING
        ? utilities.getCostForQty(product, quantity || '', true)
        : tableType === HOTDEALS ? utilities.getCostForQtyPlpNew(product, quantity || '')
        : tableType === MONTHLY_SPECIALS
        ? utilities.getCostForQtyPlpNew(product.pricing , quantity[0] || '')
        :  tableType === 'DOM'
        ? utilities.getCostForQtyHotDeals((product || (product && product.pricing)), quantity || '', true)
        : tableType === 'Planograms'
        ? utilities.getCostForQtyPlpNew(product && product.pricing, quantity || '')
        : product.Member_Cost
      }
      field={(tableType === "DOM" || tableType===MONTHLY_SPECIALS) ? "Member Cost black" :'Member Cost'}
    />
    const currentcost = <MaskPrice
    viewType={viewType}
    getprefer={getprefer}
    fieldVal={
      tableType === CLOSEOUTS         
        ? utilities.getCostForQty(product, quantity || '', true)
        :tableType === DISCONTINUED
          ? utilities.getCostForQty(product, quantity || '', true)
          : tableType === 'Planograms'
            ? utilities.planogramGetCostForQty(product, quantity || '', true)
            : tableType === HOTDEALS 
            ? utilities.getCostForQtyHotDeals(product, quantity || '', true)
            : product.Member_Cost
    }
    field='Member Cost'
  />
  const regularcost = <MaskPrice
    viewType={viewType}
    getprefer={getprefer}
    fieldVal={product.Regular_Cost || product?.pricing?.Regular_Cost}
    field='Regular Cost'
  />
  const montlySpeialFuturecost = <MaskPrice
    viewType={viewType}
    getprefer={getprefer}
    fieldVal={product.future_cost}
    field='Regular Cost'
  />
  const regularCostTier = <>
    {regularcost}
    {viewType === MEMBER_VIEW && ( product.tiered || product?.pricing?.tiered ) && <TierIcon tierLevel={'2'} tierLetter={true}/>}
  </>
   const futureCostTier = <>
   {montlySpeialFuturecost}
   {viewType === MEMBER_VIEW && ( product.tiered || product?.pricing?.tiered ) && <TierIcon tierLevel={'2'} tierLetter={true}/>}
 </>
  let price = BusinessRules.suggestedRetailPriceForPlp(product,product.variableRetail,viewType, multiStoreDetails);
  const srpCost = 
            <MaskPrice
              viewType={viewType}
              getprefer={getprefer}
              fieldVal={price || '0'}
              field = {"Suggested Retail"}
          />

  const costWithTier = product?.Member_Cost_Tier
    ? <>
        {memberCost}
        {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
      </>
    : memberCost;

  const hotDealsCostWithTier = product?.Member_Cost_Tier
  ? <>
      {memberCost}
      {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
    </>
  : memberCost;

  const costWithTierImpulse = product.tiered && product.tiered !== 'False'
    ? <>
        {memberCost}
        <TierIcon tierLevel={utilities.getTierLevelForQty(product, quantity,tableType)} />
      </>
    : memberCost;

    const costWithTierPlanograms = product && product.pricing && product.pricing?.Member_Cost_Tier
    ? <>
        {memberCost}
        {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product && product.pricing, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
      </>
    : memberCost;

    const costWithTierMonthlySpecials = product && product.pricing && product.pricing?.Member_Cost_Tier
    ? <>
        {memberCost}
        {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product && product.pricing, (quantity && quantity[0]) || '',true)} tierLetter={quantity && quantity[0] && parseInt(quantity[0]) > 0 ? true : false}/>}
      </>
    : memberCost;
    const costWithTierHotDeals =(
      (product?.tiered && product?.tiered !== 'False') 
        || (product && product?.pricing && product?.pricing?.tiered)) ?
     <>
       {memberCost}
      <TierIcon tierLevel={utilities.getTierLevelForQtyHotDeals((product || (product && product.pricing)), quantity)} />
     </>
   : memberCost;

  const RetailMemCost = 
                      RAMarketFlag==='Y' && product.Reunion_Cost && parseInt(product.Reunion_Cost) === 0 ? '-' :
                      <MaskPrice
                        getprefer={getprefer}
                        viewType={viewType}
                        fieldVal={RAMarketFlag==='Y' ? product.Reunion_Cost : product.pricing ? product.pricing.Cost : ''}
                        field={'Member Cost'}
                      />
                      
  let price_evt = product && product.spc_cost
  const evtCost = <MaskPrice
                    getprefer={getprefer}
                    viewType={viewType}
                    fieldVal={price_evt}
                  />
  let tbl_vals = [];
  const dipslayOrigAndCurrCost = shouldHideOrigAndCurrCostForPreference(viewType ,tableType,getprefer);
  const showMemberCostAndLbl =  shouldHideCostForPreference(viewType,getprefer);
  const displaySRPCost = shouldHideSUGG_RETAILForPreference(viewType,getprefer);
  const closeOutRegularCost = getCloseoutRegularCost(product)
  const closeOutMemberCost = getCloseoutCurrentCost(product)
  if (tableType === 'NewItems') {
    //            { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL"), val: srpCost }
    tbl_vals = [
      ...((access !== 1|| (access === 1 && CatMbr))?[
       [{ label: 'Item#:', val: product.Item_Number },
        { label: 'Date Added:', val: product.creation_date },
        displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':', val: utilities.emptyPriceCheck(costWithTier) },
        displaySRPCost && { label: utilities.getLabelsByType(viewType,"SUGG_RETAIL", getprefer)+':', val: srpCost },
      ],
        [{ label: 'Model#:', val: product.Model },multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) ? (
          {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}):[],
          [],
          {
            label: 'Override Retail:',
            val: overrideRetail,
            type: 'or',
            is_text_box: true
          }
        ],
        [
          ...(isShowAllWarehouse
            ? [{}]
            : [
                {
                  label: 'Available:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                }
              ]),
              
          {
            label: 'Pack:',
            val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
              product.S_Pack_Qty ? product.S_Pack_Qty : ''
            }`
          },
             [],
              {
                label: 'Quantity:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
        ]
      ]:
      [
        [
          { label: 'Item#:', val: product.Item_Number },
          {
            label: 'Pack:',
            val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
              product.S_Pack_Qty ? product.S_Pack_Qty : ''
            }`
          },
          displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost }
        ],
        [
          { label: 'Model#:', val: product.Model },
          multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) ? (
            {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}
          ):[],
          {
            label: 'Override Retail:',
            val: overrideRetail,
            type: 'or',
            is_text_box: true
          }
        ],
        [
          ...(isShowAllWarehouse
            ? [{}]
            : [
                {
                  label: 'Available:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? 'First_level_details'
                      : 'First_level_details_red cost_red_color '
                }
              ]),  { label: 'Date Added:', val: product.creation_date },
              {
                label: 'Quantity:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
        ]
    ])
  ]
  } else if (tableType === DISCONTINUED || tableType === CLOSEOUTS) {
    tbl_vals = [
      ...((access !== 1 || (access === 1 && CatMbr))
      ?[
       [ 
         { label: 'Item#:', val: product.Item_Number },
         {
          label: 'Pack:',
          val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
            product.S_Pack_Qty ? product.S_Pack_Qty : ''
          }`
        },
        dipslayOrigAndCurrCost && {
          label:utilities.getLabelsByType(viewType,"REGULAR_COST")+':' ,
          val: utilities.emptyPriceCheck(regularCostTier),
        },
        // { label: 'CURRENT COST:', val: currentcost }
        displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost }
      ],
      [
        { label: 'Model#:', val: product.Model },
        ...(isShowAllWarehouse
          ? [{}]
          : [
              {
                label: 'Disc. Date:',
                val: product.discontinued_date
                  ? utilities.getDateFromTimeStamp(
                      new Date(product.discontinued_date)
                    )
                  : '-'
              }
            ]),
            /*dipslayOrigAndCurrCost&&{ label: utilities.getLabelsByType(viewType,"CURR_COST")+':',
                                      val: <>{utilities.getCostBasedOnnatvalue(viewType,closeOutCurrentCost[0],getprefer)} {closeOutCurrentCost[1]&& (<span className="tier_icon">{closeOutCurrentCost[1]}</span>)} </>},*/
          displayMemberCostAndLbl && {label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':',  val: <>{utilities.emptyPriceCheck(costWithTier)}</>, },
        {
          label: 'Override Retail:',
          val: overrideRetail,
          type: 'or',
          is_text_box: true
        }
      ],
      [
             ...(isShowAllWarehouse
              ? [
                  {
                    label: 'Disc. Date:',
                    val: product.discontinued_date
                      ? utilities.getDateFromTimeStamp(
                          new Date(product.discontinued_date)
                        )
                      : ''
                  }
                ]
              : [
                  {
                    label: 'Available:',
                    val:utilities.checkForHubAndSpokeIndictor(product),
                    labelClass:
                      product.Available && parseInt(product.Available) > 0
                        ? 'First_level_details'
                        : 'First_level_details_red cost_red_color '
                  }
                ]),
                {
                  label:'',
                  val:''
                },
                // {label: utilities.getLabelsByType(viewType,"MEMBER_COST",getprefer), val: costWithTier },           
                /*{
                  label: product.sub_type_cd ==='3' || (product.substistuteorreferal && utilities.checkRef(product.substistuteorreferal))? 'Referral Item:':'Substitute:' ,
                  val:product.sub_type_cd? utilities.hyphenForZero(product.sub_type_cd) ==='3'? utilities.hyphenForNullsub(product.substitute_item) : utilities.hyphenForNullref(product.substitute_item)
                  :product.substistuteorreferal? product.substistuteorreferal: utilities.hyphenForNullsub(product.substistuteorreferal)
                },*/
                ( viewType === MEMBER_VIEW ? 
                  { label: '% Off:',
                    val: utilities.hyphenForZero(product.savings && Number(product.savings) > 0 ? `${Number(product.savings).toFixed(2)}`: '-')
                  } : {}
                ),
                {
                  label: 'Quantity:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                }
      ]
      ]:[
        [
          { label: 'Item#:', val: product.Item_Number },
          {
            label: 'Pack:',
            val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
              product.S_Pack_Qty ? product.S_Pack_Qty : ''
            }`
          },
          ...(isShowAllWarehouse
            ? [{}]
            : [
          displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost }
            ])
        ],
        [
          { label: 'Model#:', val: product.Model },
          multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
          && ({label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}),
          ...(isShowAllWarehouse
            ? [{}]
            : [
                {
                  label: 'Disc. Date:',
                  val: product.discontinued_date
                    ? utilities.getDateFromTimeStamp(
                        new Date(product.discontinued_date)
                      )
                    : '-'
                }
              ]),

          {
            label: 'Override Retail:',
            val: overrideRetail,
            type: 'or',
            is_text_box: true
          }
        ],
        [
          ...(isShowAllWarehouse
            ? [
                {
                  label: 'Disc. Date:',
                  val: product.discontinued_date
                    ? utilities.getDateFromTimeStamp(
                        new Date(product.discontinued_date)
                      )
                    : {}
                }
              ]
            : [
                {
                  label: 'Available:',
                  val:utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? 'First_level_details'
                      : 'First_level_details_red cost_red_color '
                }
              ]),
              {
                label: 'Substitute:',
                val: utilities.hyphenForZero(
                  utilities.hyphenForNullsub(product.substitute_item)
                )
              },
                (viewType === MEMBER_VIEW ? 
                  {
                    label: '% Off:',
                    val: utilities.hyphenForZero(product.savings && Number(product.savings) > 0 ? `${Number(product.savings).toFixed(2)}`: '-')
                  } : {}
                ),
                  {
                    label: 'Quantity:',
                    val: quantity,
                    type: 'qty',
                    is_text_box: true
                  }
      ]])
      ]
  } else if (tableType === HOTDEALS) {
    tbl_vals = [
      ...((access !== 1 || (access === 1 && CatMbr))
        ? [
            [
              { label: 'Item#:', val: product.Item_Number },
              {
                label: 'Pack:',
                val: `${product.packType ? product.packType : ''}${
                  product.packQty ? product.packQty : ''
                }`
              },
              dipslayOrigAndCurrCost && {
                label:utilities.getLabelsByType(viewType,"REGULAR_COST")+':' ,
                val: utilities.emptyPriceCheck(regularCostTier),
              },
              displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost }
              
            ],
            [
             { label: 'Model#:', val: product.Model },
             ...(isShowAllWarehouse
              ? [{}]
              : [
                  {
                    label: 'Disc. Date:',
                    val: product.tbdDate
                    ? utilities.getDateFromTimeStamp(
                        new Date(product.tbdDate)
                      )
                    : '-'
                  }
                ]),
              displayMemberCostAndLbl && {label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':',  val: <>{utilities.emptyPriceCheck(hotDealsCostWithTier)}</>, },
              {
                label: 'Override Retail:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              }
            ],
            [
              {
                label: 'Available:',
                val:utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass: 
                  product.available && parseInt(product.available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              },
              {
                label:'',
                val:''
              },
              ( viewType === MEMBER_VIEW ? 
                { label: '% Off:',
                  val: utilities.hyphenForZero(product.Savings && Number(product.Savings) > 0 ? `${Number(product.Savings).toFixed(2)}`: '-')
                } : {}
              ),
              {
                label: 'Quantity:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
          ]
          :
          [
            [
              { label: 'ITEM#:', val: product.Item_Number },
              { label: 'MODEL#:', val: product.Model },
              {
                label: 'OVERRIDE RETAIL:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              }
            ],
            [
              {
                label: 'AVAILABLE:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.available && parseInt(product.available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              },
              {
                label: '% OFF:',
                val: utilities.hyphenForZero(product.Savings && Number(product.Savings) > 0 ? `${Number(product.Savings).toFixed(2)}`: '-')
              },
              {
                label: 'QUANTITY:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
            [
              {
                label: 'DISC. DATE:',
                val: product.discontinued_date
                  ? utilities.getDateFromTimeStamp(
                      new Date(product.discontinued_date)
                    )
                  : '-'
              },
              {
                label: 'PACK:',
                val: `${product.packType ? product.packType : ''}${
                  product.packQty ? product.packQty : ''
                }`
              },
              {
                label: 'SUBSTITUTE:',
                val: utilities.hyphenForZero(
                  utilities.hyphenForNullsub(product.subitemNum)
                )
              }
            ],
            [multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}
           )]           
          ])
    ];
  }
  else if (tableType === 'Planograms') {
    tbl_vals = [
      ...((access !== 1 || PogMbr)
        ? [
            [
              { label: 'Item#:', val: product.Item_Number },
              displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost }
            ],
            [
              { label: 'Model#:', val: product.Model },
              { label: 'Sugg. Qty:', val: product.recQty },
            ],
            [
              {
                label: 'Available:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.Available && parseInt(product.Available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              },
              displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':', val: costWithTierPlanograms }       
            ],
            [
              multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            ? ({label: 'Alternate SKU#:', val: product.aliasSku}) : [],
            ],
            [
              { label: 'UPC:', val: product.UpcNum },
              {
                label: 'Order Qty:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
            [
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              {}
            ]
          ]
          :
          [
            [
              { label: 'Item#:', val: product.Item_Number },
              { label: 'Model#:', val: product.Model }
            ],
            [
              {
                label: 'Available:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.Available && parseInt(product.Available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              },
              {
                label: 'Order Qty:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
            [
              multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
              { label: 'UPC:', val: product.UpcNum },
            ],
            [
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
               displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost }
            ]
          ])
    ];
  } else if (tableType === 'RetailAssortments') {
    tbl_vals = [
      ...((access !== 1 ||  (viewPOG && PogMbr))
        ? [
            [
              { label: 'Item#:', val: product.Item_Number },
              displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            ],
            [
              multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              ? ({label: 'Alternate SKU#:', val: product.aliasSku}) : [],
            ],
            [
              { label: 'Model#:', val: product.model },
              showMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST") + ':', val: RetailMemCost }              
            ],
            [
              {label: 'UPC:', val: product.UpcNum},
              { label: 'Quantity:', val: product.Available },
          ]
          ]
          :
          [
            [
              { label: 'Item#:', val: product.Item_Number },
              { label: 'Model#:', val: product.model }
            ],
            [ multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              && ({label: 'Alternate SKU#:', val: product.aliasSku}),
              { label: 'Quantity:', val: utilities.checkForHubAndSpokeIndictor(product) },
             displaySRPCost && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost }
             ]
          ])
    ];
  } else if (tableType === 'DOM') {
    tbl_vals = [
      ...((access === 1 && (access !== 1 && CatMbr))
        ? [
            [
              { label: 'Item#:', val: product.Item_Number },
              { label: 'Model#:', val: product.Model }
            ],
            [
              !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                { label: 'Model#:', val: product.model }
            ],
            [
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              {
                label: 'Available:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.Available && parseInt(product.Available) > 0
                    ? 'First_level_details'
                    : 'First_level_details_red cost_red_color '
              }
            ],
            [
              {
                label: 'Quantity:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              },
              // {
              //   label: 'RESTRICTED',
              //   val: product.Restricted ? 'Restricted Product' : '',
              //   className: 't-center restricted-product'
              // }
            ]
          ]
        : [
            [
              { label: 'Item#:', val: product.Item_Number },
              displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST") + ':', val: costWithTierHotDeals }
            ],
            [
              { label: 'Model#:', val: product.Model },
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              }
            ],
            [
              !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
              && ({label: 'Alternate SKU#:', val: product.aliasSku}),
              {
                label: 'Quantity:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
            [
              {
                label: 'Available:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                      product.Available && parseInt(product.Available) > 0
                        ? 'First_level_details'
                        : 'First_level_details_red cost_red_color'
              },
              {
                label: 'Promo Retail:',
                val:
                  !utilities.isEmptyOrNullString(product.promo_retail) ? '$' + Number(product.promo_retail).toFixed(2) : '-'
              }
            ],
            [
              {
                label: 'Promo Cost:',
                val: !utilities.isEmptyOrNullString(product.spc_cost) ? '$' + Number(product.spc_cost).toFixed(2) : '-',
                labelClass:'First_level_details_red'
              },
              { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            ],[
              // {
              //   label: 'RESTRICTED',
              //   val: product.Restricted ? 'Restricted Product' : '',
              //   className: 't-center restricted-product'
              // },
            ]
          ])
    ];
  } else if (tableType === IMPULSE_MERCHANDISING) {
    tbl_vals = [
      ...((access !== 1 || (viewPOG && PogMbr))
        ? [
            [
              { label: 'Item#:', val: product.Item_Number },
              displayMemberCostAndLbl &&{ label: utilities.getLabelsByType(viewType,"MEMBER_COST") + ':', val: costWithTierImpulse }
            ],
            [
              multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
              { label: 'Model#:', val: product.Model }             
            ],
            [
              {
                label: 'Available:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.Available && parseInt(product.Available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              },
              { label: 'Suggested Qty:', val: product.recQty }
            ],
            [
               { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              {
                label: 'Quantity:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
            [
              {
                label: 'Gross Margin:',
                val: computeGrossMargin(product.Srp_Cost, product.Member_Cost)
              },
            ],
            // [
            //   {
            //     label: 'Restricted',
            //     val: product.Restricted ? 'Restricted Product' : '',
            //     className: 't-center restricted-product'
            //   }
            // ]
          ]
          :
          [
            [
              { label: 'Item#:', val: product.Item_Number },
              { label: 'Model#:', val: product.Model }
            ],
            [
              multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),              
              {
                label: 'Available:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.Available && parseInt(product.Available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              }
            ],
            [
              { label: 'Suggested Qty:', val: product.recQty },
              {
                label: 'Quantity:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
            [
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              {
                label: 'Gross Margin:',
                val: computeGrossMargin(product.Srp_Cost, product.Member_Cost)
              },
               { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost }
            ],
            // [
            //   {
            //     label: 'RESTRICTED',
            //     val: product.Restricted ? 'Restricted Product' : '',
            //     className: 't-center restricted-product'
            //   }
            // ]
          ])
    ];
  } else if (tableType === 'Reunion') {
    tbl_vals = [
      ...((access !== 1 || (access === 1 && CatMbr))
        ? [
            [
              { label: 'ITEM#:', val: product.Item_Number },
               { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost },
              { label: 'SHIP WEEK:', val: 'QUANTITY' }
            ],
            [
              { label: 'MODEL#:', val: product.Model },
              {
                label: 'OVERRIDE RETAIL:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              },
              ...(utilities.isEmptyOrNullString(product.shipdate1)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate1),
                      type: 'qty1',
                      is_text_box: true,
                      val: quantity[0]
                    }
                  ])
            ],
            [
              { label: 'UPC:', val: product.UpcNum },
              {
                label: 'SAVINGS:',
                val: utilities.hyphenForNull(product.Savings) + ' %'
              },
              ...(utilities.isEmptyOrNullString(product.shipdate2)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate2),
                      type: 'qty2',
                      is_text_box: true,
                      val: quantity[1]
                    }
                  ])
            ],
            [
              ...((access === 1 && (access !== 1 && !CatMbr))
                ? []
                : [
                    {
                      label: 'WH COST:',
                      val:
                        (product.future_cost &&
                          parseInt(product.future_cost)) !== 0
                          ? '$' + product.future_cost
                          : '$' + product.Cost
                    }
                  ]),
              {
                label: 'PACK:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              ...(utilities.isEmptyOrNullString(product.shipdate3)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate3),
                      type: 'qty3',
                      is_text_box: true,
                      val: quantity[2]
                    }
                  ])
            ],
            [
              ...((access === 1 && (access !== 1 && !CatMbr))
                ? []
                : [
                    {
                      label: 'EVT COST:',
                      val: utilities.hyphenForNull('$' + product.spc_cost),
                      labelClass:'First_level_details_red'
                    }
                  ]),
              {
                label: 'NON-STOCKED:',
                val: !product.stocked || product.stocked !== '1' ? 'Y' : 'N'
              },
              ...(utilities.isEmptyOrNullString(product.shipdate4)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate4),
                      type: 'qty4',
                      is_text_box: true,
                      val: quantity[3]
                    }
                  ])
            ],
            [
              ...(utilities.isEmptyOrNullString(product.future_cost)
                ? [
                    {
                      label: 'RESTRICTED:',
                      val: 'Pricing available at Reunion',
                      className: 't-center'
                    }
                  ]
                : []),
              ...(utilities.isEmptyOrNullString(product.Restricted)
                ? [
                    {
                      label: 'RESTRICTED:',
                      val: product.Restricted ? 'Restricted Product' : '',
                      className: 't-center restricted-product'
                    }
                  ]
                : [])
            ]
          ]
          :
          [
            [
              { label: 'ITEM#:', val: product.Item_Number },
              { label: 'MODEL#:', val: product.Model },
              { label: 'SHIP WEEK:', val: 'QUANTITY' }
            ],
            [
              { label: 'UPC:', val: product.UpcNum },
              {
                label: 'OVERRIDE RETAIL:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              },
              ...(utilities.isEmptyOrNullString(product.shipdate1)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate1),
                      type: 'qty1',
                      is_text_box: true,
                      val: quantity[0]
                    }
                  ])
            ],
            [
              {
                label: 'PACK:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              {
                label: 'SAVINGS:',
                val: utilities.hyphenForNull(product.Savings) + ' %'
              },
              ...(utilities.isEmptyOrNullString(product.shipdate2)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate2),
                      type: 'qty2',
                      is_text_box: true,
                      val: quantity[1]
                    }
                  ])
            ],
            [
              {
                label: 'NON-STOCKED:',
                val: !product.stocked || product.stocked !== '1' ? 'Y' : 'N'
              },
              [],
              ...(utilities.isEmptyOrNullString(product.shipdate3)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate3),
                      type: 'qty3',
                      is_text_box: true,
                      val: quantity[2]
                    }
                  ])
            ],
            [
              [],
              [],

              ...(utilities.isEmptyOrNullString(product.shipdate4)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipdate4),
                      type: 'qty4',
                      is_text_box: true,
                      val: quantity[3]
                    }
                  ])
            ]
          ])
    ];
  } else if (tableType === MONTHLY_SPECIALS) {
    const savingsValue = product.Savings ? product.Savings.split('%')[0] : 0;
    tbl_vals = [
      ...((access !== 1 || (access === 1 && CatMbr))
        ? [
            [
              { label: 'Item#:', val: product.Item_Number },
              ...((access === 1 && (access !== 1 && !CatMbr))
                ? []
                : [
                    displayMemberCostAndLbl && {
                      label: `${utilities.getLabelsByType(viewType,"REGULAR_COST")}:`,
                      val :  product.future_cost && product.future_cost > 0 ? utilities.emptyPriceCheck(futureCostTier) : quantity[0] > 0 ? costWithTierMonthlySpecials : utilities.emptyPriceCheck(regularCostTier)
                    }
                  ]),
              { label: 'Ship Week:', val: 'QUANTITY' }
            ],
            [
              { label: 'Model#:', val: product.Model },
              ...((access === 1 && (access !== 1 && !CatMbr))
                ? []
                : [
                    displayMemberCostAndLbl && {
                      label: `${utilities.getLabelsByType(viewType,"EVENT_COST")}:`,
                      val: utilities.getCostBasedOnnatvalue(viewType, product.spc_cost,getprefer),
                      labelClass:'First_level_details_red'
                    }
                  ]),            
              ...(utilities.isEmptyOrNullString(product.shipDate1)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate1),
                      type: 'qty1',
                      is_text_box: true,
                      val: quantity[0]
                    }
                  ])
            ],
            [
              { label: 'UPC:', val: product.UpcNum },
              viewType === MEMBER_VIEW && {
                label: 'Savings:',
                val: utilities.hyphenForZero(savingsValue && Number(savingsValue) > 0 ? `${Number(savingsValue).toFixed(2)}%`: '-'),
                labelClass:'First_level_details_red'
              },
              ...(utilities.isEmptyOrNullString(product.shipDate2)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate2),
                      type: 'qty2',
                      is_text_box: true,
                      val: quantity[1]
                    }
                  ])
            ],
            [
            !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
              ? ({label: 'Alternate SKU#:', val: product.aliasSku}) : [],  
            ], 
            [
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              {
                label: 'Override Retail:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              },
              ...(utilities.isEmptyOrNullString(product.shipDate3)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate3),
                      type: 'qty3',
                      is_text_box: true,
                      val: quantity[2]
                    }
                  ])
            ],
            [
              {
                label: 'Non-Stocked:',
                val: product.stocked
              },

              ...(utilities.isEmptyOrNullString(product.shipDate4)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate4),
                      type: 'qty4',
                      is_text_box: true,
                      val: quantity[3]
                    }
                  ])
            ],
            [ ...(product?.Restrictions?.length > 0 ?[{
              label : 'RESTRICTED:',
              val : (product?.Restrictions?.length > 0)
                ? utilities.restrictedDetail(product?.Restrictions)
                : '',
                className: 't-center restricted-product'
            }] : []),
              ...(utilities.isEmptyOrNullString(product.future_cost)
                ? [
                    // {
                    //   label: 'RESTRICTED',
                    //   val: 'Pricing available at Monthly',
                    //   className: 't-center'
                    // }
                  ]
                : []),
              ...(utilities.isEmptyOrNullString(product.Restricted)
                ? [
                    // {
                    //   label: 'RESTRICTED',
                    //   val: product.Restricted ? 'Restricted Product' : '',
                    //   className: 't-center restricted-product'
                    // }
                  ]
                : [])
            ]
          ]
          :
          [
            [
              { label: 'Item#:', val: product.Item_Number },
              { label: 'Model#:', val: product.Model },
              { label: 'Ship Week:', val: 'QUANTITY' }
            ],
            [
              { label: 'UPC:', val: product.UpcNum },
              {
                label: 'Override Retail:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              },
              ...(utilities.isEmptyOrNullString(product.shipDate1)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate1) + ':',
                      type: 'qty1',
                      is_text_box: true,
                      val: quantity[0]
                    }
                  ])
            ],
            [
              !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
              ? ({label: 'Alternate SKU#:', val: product.aliasSku}) : [],
              {
                label: 'Savings:',
                val: utilities.hyphenForNull(product.Savings)
              },
              ...(utilities.isEmptyOrNullString(product.shipDate2)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate2),
                      type: 'qty2',
                      is_text_box: true,
                      val: quantity[1]
                    }
                  ])
            ],
            [
              {
                label: 'Pack:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              {
                label: 'Non-Stocked:',
                val: product.stocked
              },
              ...(utilities.isEmptyOrNullString(product.shipDate3)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate3),
                      type: 'qty3',
                      is_text_box: true,
                      val: quantity[2]
                    }
                  ])
            ],
            [
              [],
              [],

              ...(utilities.isEmptyOrNullString(product.shipDate4)
                ? []
                : [
                    {
                      label: utilities.getDateFromTimeStamp(product.shipDate4),
                      type: 'qty4',
                      is_text_box: true,
                      val: quantity[3]
                    }
                  ])
            ]
          ])
    ];
  } else if (tableType === TV_PRODUCT_SCAN) {
    tbl_vals = [
      ...((access !== 1 || (access === 1 && CatMbr))
        ?  [
            [
              { label: 'ITEM#:', val: product.Item_Number },
              
              {
                label: 'PACK:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
              { label: 'SUGG. RETAIL', val: srpCost }
              
            ],
            [
              { label: 'MODEL#:', val: product.Model },
              { label: '', val: '' },
              {
                label: 'OVERRIDE RETAIL:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              }
              
            ],
            [
              {
                label: 'AVAILABLE:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.Available && parseInt(product.Available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              },
              displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':', val: memberCost },
              {
                label: 'QUANTITY:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
          ]
          :
          [
            [
              { label: 'ITEM#:', val: product.Item_Number },
              { label: 'MODEL#:', val: product.Model },
              
              {
                label: 'PACK:',
                val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                  product.S_Pack_Qty ? product.S_Pack_Qty : ''
                }`
              },
            ],
            [
              {
                label: 'AVAILABLE:',
                val: utilities.checkForHubAndSpokeIndictor(product, 'compact'),
                labelClass:
                  product.Available && parseInt(product.Available) > 0
                    ? ''
                    : 'First_level_details_red cost_red_color '
              },
              {
                label: 'OVERRIDE RETAIL:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              },
              
              {
                label: 'QUANTITY:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }
            ],
          ])
    ];
  } else if (tableType === COMPETITOR_PRODUCTS) {
    tbl_vals = [
      [
        { label: 'COMPETITOR NAME:', val: product.competitor_name },
        { label: 'ITEM#:', val: product.item_id }
      ],
      [
        {
          label: 'MODEL#:',
          val: utilities.hyphenForNull(product.model_number)
        },
        {
          label: 'NAME:',
          val: product.item_name
        }
      ],
      [
        ...((access === 1  && (access !== 1  && !CatMbr))
          ? []
          : [{ label: 'SALE PRICE:', val: product.sale_price }]),
        {
          label: 'PACK:',
          val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
            product.S_Pack_Qty ? product.S_Pack_Qty : ''
          }`
        }
      ]
    ];
  }
  return (
    <>
      {tbl_vals.map((item, i) => (
        <>
          <Table.Row>
            {item.map((cell, i) => (
              <>
                {cell.label === 'RESTRICTED:' ? (
                  <Table.Cell colSpan='2' className={cell.className}>{cell.val}</Table.Cell>
                ) : (
                  <>
                    <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                      <span className={cell.label === 'AVAILABLE:' ? 'availableWH' : ' '}>{cell.label}</span>              
                    </Table.Cell>
                    {cell.is_text_box ? (
                      cell.type === 'qty' ? (
                        <Table.Cell>
                        {tableType === 'Planograms' || (tableType === 'ImpulseMerchandising' &&
                              !(assortmentId === 'CLIP01' || assortmentId === 'CTOP01')) 
                        ?
                          <input
                            type='text'
                            id={'qty-box' + product.Item_Number}
                            data-field='qty'
                            data-item-number={index}
                            data-pack-type={product.S_Pack_Type}
                            data-pack-qty={product.S_Pack_Qty}
                            data-recQty={product.recQty}
                            onChange={handleQtyChange}
                            onBlur={handleQtyChangeblur}
                            value={cell.val ? cell.val : ''}
                            onKeyDown = {(e)=>handleKeyDown(e, product, index)}
                            className='qty-box input-compact'
                          />
                          :
                          <input
                          type='text'
                         id={'qty-box' + product.Item_Number}
                          data-field='qty'
                          data-item-number={index}
                          data-pack-type={product.S_Pack_Type}
                          data-pack-qty={product.S_Pack_Qty}
                          onChange={handleQtyChange}
                          value={cell.val ? cell.val : ''}
                          onKeyDown = {(e)=>handleKeyDown(e, product, index)}
                          className='qty-box input-compact'
                        />
                        }
                        </Table.Cell>
                      ) : cell.type === 'or' ? (
                        <>
                          <Table.Cell>
                            <input
                              data-field='or'
                              data-item-number={index}
                              data-pack-type={product.S_Pack_Type}
                              data-pack-qty={product.S_Pack_Qty}
                              onChange={handleQtyChange}
                             // onBlur={handleQtyChangeblur}
                              value={cell.val ? cell.val : ''}
                              className='qty-box input-compact'
                            />
                          </Table.Cell>
                        </>
                      ) : (
                        <Table.Cell>
                          <input
                            type='text'
                            id={cell.type + product.Item_Number}
                            data-field={cell.type}
                            data-item-number={index}
                            data-pack-type={product.S_Pack_Type}
                            data-pack-qty={product.S_Pack_Qty}
                            onChange={handleQtyChange}
                            value={cell.val ? cell.val : ''}
                            className='qty-box input-compact'
                          />
                        </Table.Cell>
                      )
                    ) : (
                      <>
                        <Table.Cell
                          className={
                            cell.labelClass
                              ? cell.labelClass + ' First_level_details First_level_details-compact left aligned collapsing'
                              : 'First_level_details First_level_details-compact left aligned collapsing'
                          }
                        >
                          {cell.is_number ? (
                            utilities.changePriceToNumber(cell.val)
                            ) : (cell.label === 'Substitute:' || cell.label === "Referral Item:" || cell.label==='Alternative Item:'  ) && cell.val&&
                            cell.val > 0 && cell.val.trim() !== '-' ? (
                            <Link
                              tabIndex = '-1'
                              to={{
                                pathname: '/product/' + utilities.refInclude(cell.val),
                                state: {
                                  title: title,
                                  listType: listType
                                }
                              }}
                            >
                              {cell.val}
                            </Link>
                          ) : cell.val
                          }
                        </Table.Cell>
                      </>
                    )}
                  </>
                )}
                </>
            ))}
          </Table.Row>
        </>
      ))}
    </>
  );
};
