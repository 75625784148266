// DEPARTMENTS ACTION TYPES
export const FETCH_DEPARTMENTS = "FETCH_DEPARTMENTS";
export const FETCH_DEPARTMENTS_SUCCESS = "FETCH_DEPARTMENTS_SUCCESS";
export const FETCH_DEPARTMENTS_FAIL = "FETCH_DEPARTMENTS_FAIL";
export const REMOVE_DEPARTMENT_FROM_LIST = 'REMOVE_DEPARTMENT_FROM_LIST';
export const ADD_DEPARTMENT_TO_LIST = 'ADD_DEPARTMENT_TO_LIST';

export const FETCH_DEPARTMENT_CLASSES = "FETCH_DEPARTMENT_CLASSES";
export const FETCH_DEPARTMENT_CLASSES_SUCCESS = "FETCH_DEPARTMENT_CLASSES_SUCCESS";
export const FETCH_DEPARTMENT_CLASSES_FAIL = "FETCH_DEPARTMENT_CLASSES_FAIL";

export const FETCH_DEPARTMENT_SUBCLASSES = "FETCH_DEPARTMENT_SUBCLASSES";
export const FETCH_DEPARTMENT_SUBCLASSES_SUCCESS = "FETCH_DEPARTMENT_SUBCLASSES_SUCCESS";
export const FETCH_DEPARTMENT_SUBCLASSES_FAIL = "FETCH_DEPARTMENT_SUBCLASSES_FAIL";

// CATALOG ACTION TYPES
export const ADD_TO_CATALOG = "ADD_TO_CATALOG";
export const ADD_TO_CATALOG_FAIL = "ADD_TO_CATALOG_FAIL";
export const ADD_TO_CATALOG_SUCCESS = "ADD_TO_CATALOG_SUCCESS";

export const REMOVE_FROM_CATALOG = "REMOVE_FROM_CATALOG";
export const SELECT_DEPARTMENT_CATALOG = "SELECT_DEPARTMENT_CATALOG";
export const CLEAR_CATALOG = "CLEAR_CATALOG";

export const SET_FILTER_VALUE = "SET_FILTER_VALUE";

export const STORE_CATALOG = "STORE_CATALOG";
export const STORE_CATALOG_SUCCESS = 'STORE_CATALOG_SUCCESS';
export const STORE_CATALOG_FAIL = 'STORE_CATALOG_FAIL';

export const TOGGLE_GRID_LIST_VIEW_STATE = 'TOGGLE_GRID_LIST_VIEW_STATE';

export const GET_CATALOGS_SAVED = "GET_CATALOGS_SAVED";
export const GET_CATALOGS_SAVED_SUCCESS = "GET_CATALOGS_SAVED_SUCCESS";
export const GET_CATALOGS_SAVED_FAIL = 'GET_CATALOGS_SAVED_FAIL';

export const DELETE_CATALOG = 'DELETE_CATALOG';
export const DELETE_CATALOG_SUCCESS = 'DELETE_CATALOG_SUCCESS';
export const DELETE_CATALOG_FAIL = 'DELETE_CATALOG_FAIL';


// FINELINE AD ACTION TYPES
export const ADD_CATALOG_AD = 'ADD_CATALOG_AD';
export const ADD_CATALOG_AD_SUCCESS = 'ADD_CATALOG_AD_SUCCESS';
export const ADD_CATALOG_AD_FAIL = 'ADD_AD_TO_CATALOG_FAIL';

export const REMOVE_CATALOG_AD = 'REMOVE_CATALOG_AD';

export const UPDATE_CATALOG_AD = 'UPDATE_CATALOG_AD';