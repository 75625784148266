import restUtils from "../../utils/restUtils";
import {imCategoriesUrl,pogListUrl} from '../../urlConstants';
export const GET_IM_CATEGORIES = "GET_IM_CATEGORIES";
export const GET_POG = "GET_POG"


export const getImCategories = () =>{
    return dispatch =>{
        
        restUtils.getData(imCategoriesUrl)
        .then(response =>{
            return dispatch({
                type: GET_IM_CATEGORIES,
                payload:response.data
            })
        }).catch(error =>{
            console.log(error);
        })
    }
}

export const getPOGs = (category) =>{
    return dispatch =>{
        
        restUtils.getData(pogListUrl+category)
        .then(response =>{
            let ImpulseCategory = category.includes('&') ? category.split('&')[0]: category
            return dispatch({
                type: GET_POG,
                payload:{catName:ImpulseCategory,POGs:response.data}
            })
        }).catch(error =>{
            console.log(error);
        })
    }
}