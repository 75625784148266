import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchVendorById } from '../actions';
import { getVendorWithDetails } from '../reducer';
import { Icon, Grid, Loader, Header } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import utilities from '../../../utils/utilities';

class VendorDetailsPopup extends Component {
  componentDidMount() {
    const { ID, vendorsWithDetails, fetchVendorById } = this.props;
    const vendorDetails = vendorsWithDetails[ID];
    if (!vendorDetails) {
      fetchVendorById(ID);
    }
  }

  render() {
    const { ID, vendorsWithDetails, vendorInfo, isReunionItem, attributeValue } = this.props;
    const vendorDetails = isReunionItem ? vendorInfo : vendorsWithDetails[ID];

    const {
      DspvendorCity = '',
      DspvendorSt = '',
      DspProvince = '',
      VendorAddress1 = '',
      VendorAddress2 = '',
      VendorCity = '',
      SaName = '',
      SaAddress1 = '',
      SaAddress2='',
      SaEmailAddr = '',
      SaCity = '',
      SaST = '',
      SaZIP = '',
      VendoMinCodeDesc = '',
      SaFirstName = '',
      SaLastName = '',
      Sa800Phone = '',
      SaPRPhone = '',
      SaFaxPhone = '',
      VendorZip = '',
      VendorSt = '',
      ScAddress1 = '',
      ScAddress2 = '',
      ScST = '',
      ScCity='',
      ScFirstName = '',
      ScLastName = '',
      ScFaxPhone = '',
      ScPRPhone = '',
      ScZIP = '',
      ScName = '',
      VendorConFirstName = '',
      VendorConLastName = '',
      VendorConPRPhone = '',
      VendorConFaxPhone = '',
      VendorConEmailAddr = '',
      VendorCon800Phone = '',
      VendorMinOrder = 0,
      VendorMinComment = '',
      AdditionalInfo = "",
      VendorPPDTermsValue = 0,
      VendorpFreightCodeDesc='',
      VendorComment='',
      VendorName = '',
      Sc800Phone = ''
    } = vendorDetails || {};
    return (
      <div className="wordBreak top-container ">
        {!vendorDetails ? (
          <>
            <Header as='h4'>{attributeValue}</Header>
            <Loader active />
          </>
        ) : (
          <div ref={el => (this.componentRef = el)} className='print-component vendorDetailInfo'>
            <Header as='h4' >
              {`${utilities.replaceAll(VendorName, '%2F', '/')}  -${ID}`}
              <ReactToPrint
                trigger={() => (
                  <i aria-hidden='true' className='print big icon'></i>
                )}
                content={() => this.componentRef}
              />
            </Header>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column className="columns" >
                  <p className="vendor-Headuline">{VendorName}</p>
                  <p>
                    {VendorAddress1} <br></br>{VendorAddress2 ?<div>{VendorAddress2}</div> : ''}
                    {VendorCity}, {VendorSt} {VendorZip}
                  </p>
                </Grid.Column>
                {SaAddress1 && <Grid.Column className="columns">
                  {SaAddress1 && <p className="vendor-Headuline">Sales Agent:</p>}
                  {SaName && <>{SaName}<br></br></>}
                  {SaAddress1 && <> {SaAddress1}<br></br></>}{SaAddress2 ?<div>{SaAddress2}</div> : ''}
                  {SaCity && <>{SaCity},</>}{SaST && <> {SaST}, </>}
                  {SaZIP}

                </Grid.Column>}
                {VendorConFirstName && <><Grid.Column className="columns">
                  <p className="vendor-Head">{`${VendorConFirstName || ''} ${VendorConLastName || ''}`}</p>
                  <p>
                    { VendorCon800Phone && <><Icon name='phone' /> {VendorCon800Phone}</>}
                  </p>
                  <p>
                    { VendorConPRPhone  && <><Icon name='phone' /> {VendorConPRPhone}</>}
                  </p>
                  <p>
                    {VendorConFaxPhone && <><Icon name='fax' /> {VendorConFaxPhone}</>}
                  </p>
                  <p>
                    {VendorConEmailAddr && <><Icon name='mail' /> {VendorConEmailAddr}</>}
                  </p>
                </Grid.Column>
                </>}
                {SaFirstName && <Grid.Column className="columns">
                  <p className="vendor-Head">{`${SaFirstName || ''} ${SaLastName || ''}`}</p>
                  <p>
                    {Sa800Phone &&
                      <><Icon name='phone' /> {Sa800Phone}</>
                    }
                  </p>
                  <p>
                    {SaPRPhone &&
                      <><Icon name='phone' /> {SaPRPhone}</>
                    }
                  </p>
                  <p>
                    {SaFaxPhone &&
                      <>
                        <Icon name='fax' /> {SaFaxPhone}
                      </>
                    }
                  </p>
                  <p>
                    {SaEmailAddr && <><Icon name='mail' /> {SaEmailAddr}</>}
                  </p>
                   </Grid.Column>}
                   {VendorConFirstName && <Grid.Column className="columns">
                  <p>
                    <span className="vendor-Headuline">DS FOB</span>: {DspvendorCity},{DspvendorSt && <> {DspvendorSt}</>}{DspProvince && <> {DspProvince}</>}
                  </p>
                  <p><span className="vendor-Headuline">Frt. Ppd. On:</span> <br /> {VendorPPDTermsValue}&nbsp;{VendorpFreightCodeDesc}<br />{VendorComment}</p>
                  <p><span className="vendor-Headuline">Min Order:</span> <br /> {VendorMinOrder}&nbsp;{VendoMinCodeDesc}<br />{VendorMinComment}</p>
                </Grid.Column>}
                {ScAddress1 && <Grid.Column className="columns">
                  {ScAddress1 && <p className="vendor-Headuline">Service Center:</p>}
                  <p>
                    {ScName && <>{ScName}<br></br></>}
                    {ScAddress1 && <>{ScAddress1}<br></br></>}{ScAddress2 ? <div>{ScAddress2}</div> : ''}
                    {ScCity && <>{ScCity},</>}{ScST && <> {ScST}, </>}
                    {ScZIP}  
                  </p>
                </Grid.Column>}                
                <Grid.Column className="columns">

                </Grid.Column>
                {ScFirstName && <Grid.Column className="columns">
                  <p className="vendor-Head">{`${ScFirstName || ''} ${ScLastName || ''}`}</p>
                  <p>
                    {
                      Sc800Phone &&
                      <><Icon name='phone' /> {Sc800Phone}</>}
                  </p>
                  <p>
                    {
                      ScPRPhone &&
                      <><Icon name='phone' /> {ScPRPhone}</>}
                  </p>
                  <p>
                    {ScFaxPhone &&
                        <><Icon name='fax' /> {ScFaxPhone}</>
                      }
                  </p>
                </Grid.Column>}
              </Grid.Row>
            </Grid>
            <p className="vendor-Headuline">Additional Information: </p>
            <div className='vendorAddressInfo'><p>{AdditionalInfo}</p></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    vendorsWithDetails: getVendorWithDetails(state.vendors)
  };
};

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        fetchVendorById
      },
      dispatch
    )
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailsPopup);