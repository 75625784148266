import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {getPlanograms} from '../Planograms/actions';
class PlanogramsTable extends Component {
    handleClickApi=(planogram)=>{
        
        const {planogramData}=this.props;
        let planograms = {
            categoryName : planogram.categoryName,
            departmentName:planogram.departmentName,
            lastUpdatedDate:planogram.lastUpdatedDate,
            pdfUrl:planogram.pdfUrl,
            planogramNumber:planogram.planogramNumber,
            planogramSubName:planogram.planogramSubName,
            sizeString:planogram.sizeString,
            region:planogram.region,
            type:planogram.type,
        }
        this.props.getPlanograms(planograms);
    }
    render() {
        const{planograms}=this.props;
        return(
            <table className='ui celled table down_table'>
                <tbody>
                    {planograms && planograms.length > 0 ?
                        planograms.map(planogram =>{
                            return(
                                <tr>
                                    <td className='pdp-planograms' id='PdpPlanogram'>
                                        <Link onClick = {()=>this.handleClickApi(planogram)} 
                                            to ={{pathname:`/planogram/${planogram.planogramNumber}/${encodeURIComponent(planogram.categoryName)}/${encodeURIComponent(planogram.departmentName)}/${encodeURIComponent(planogram.planogramSubName)}`}}>
                                            {planogram.message}
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <p className = 't-center down_table'> No Planograms found!</p>
                    }
                </tbody>
            </table>
        );
    }
}

const mapStateToProps=(state)=>{

    return {
        planogramData: state.PlanogramReducer.planogram
    }
}

const mapDispatchToProps = (dispatch)=>{
    return Object.assign({ dispatch }, bindActionCreators({getPlanograms}, dispatch));
  }

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramsTable);