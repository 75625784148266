import React from 'react';
import './styles.css';
import { Loader } from 'semantic-ui-react';
import InternalServerError from '../../ErrorPages/500_error_page';
import PromoListing from './PromoListing';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import { REUNION_EVENT_LISTING } from '../../../urlConstants';
import restUtils from "../../../utils/restUtils";
import utilities from '../../../utils/utilities';
import UnderConstruction from "../../UnderConstruction";

class AllReunion extends React.Component {
  constructor(props) {
    super(props);
    const { fromReunionHome, shoppingId, shoppingAreaName } = props.match?.params;
    const shoppingAreaMetaId = props.location?.state?.shoppingMetatDataId;
    this.state = {
      shoppingId: shoppingId,
      shoppingAreaMetaId: shoppingAreaMetaId  ? shoppingAreaMetaId : props.location?.state?.shoppingAreaMetaId ? props.location?.state?.shoppingAreaMetaId : shoppingId,
      shoppingAreaName: shoppingAreaName,
      reunionEvents: [],
      serverError: false,
      isLoading: false,
      header: '',
      fromReunionHome : fromReunionHome,
      eventEnableAfter: '',
      eventEnableBefore:'',
      eventMessage: '',
    }
  }

  componentDidMount() {
    this.getReunionEvents();
  }

  componentWillReceiveProps(newProps) {
    if (this.state.loading && newProps.loaded) {
      this.setState({ loading: false })
    }
    if (newProps?.match?.params?.shoppingId !== this.props?.match?.params?.shoppingId) {
      this.setState({ shoppingId: newProps.match.params.shoppingId, shoppingAreaMetaId: newProps.match.params.shoppingAreaMetaId })
      this.getReunionEvents(newProps);
    }
  }

  getReunionEvents = (newProps) => {
    const shoppingId = newProps?.match?.params?.shoppingId 
                        ? newProps.match.params.shoppingId 
                        : this.props?.match?.params?.shoppingId 
                          ? this.props.match.params.shoppingId 
                          : '';
    this.setState({ isLoading: true });
    let url = `${REUNION_EVENT_LISTING}/?shoppingId=${shoppingId}`;
    restUtils
      .getData(url)
      .then(response => {
        if(response.data 
            && (response.data?.evtStsActiveBefore === false || response.data?.evtStsActiveAfter === false)) {
          this.setState({
            eventEnableAfter: response.data?.evtStsActiveAfter,
            eventEnableBefore: response.data?.evtStsActiveBefore,
            eventMessage: response.data?.Message
          })
          return;
        } else {
        this.setState({
          reunionEvents: response.data,
          header: response?.data?.shoppingName,
          isLoading: false
        });
      }
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { header, isLoading, eventEnableAfter, eventEnableBefore, eventMessage, shoppingAreaName} = this.state;
    const {fromReunionHome, shoppingAreaMetaId } = this.state;
    const breadcrumbPath = [
      ...fromReunionHome?[
        {link:'/reunionOrdering', text:'REUNION HOME'}
      ]:[],
      ...!utilities.isEmptyOrNullString(header)?[{ link: {pathname: this.props.location.pathname,state: {fromReunionHome:fromReunionHome, shoppingAreaMetaId: shoppingAreaMetaId}}, text: header }]:[]
    ];
    const reunionEventListing = this.state.reunionEvents;
    if((eventEnableAfter === false || eventEnableBefore === false ) || reunionEventListing ) {
      return (
        <>
          {(eventEnableAfter === false || eventEnableBefore === false ) 
            ?  <UnderConstruction  eventMessage={eventMessage} shoppingAreaName={shoppingAreaName}/>
            :  this.state.serverError 
              ?  <InternalServerError />
              :
              <div className="pageWrap">
                {isLoading && (
                  <div>
                    <Loader active />
                  </div>
                )}
                {!isLoading &&
                  <>
                    <div className='pageHeader'>
                      {header}
                    </div>
                    <BreadcrumbComp path={breadcrumbPath} />
                    <PromoListing
                      fromReunionHome={fromReunionHome}
                      shoppingAreaMetaId={shoppingAreaMetaId}
                      reunionEvents={reunionEventListing}
                    />
                  </>
                }
              </div>
          }
        </>
      )
    } else {
      return (
        <>{isLoading && <Loader active />}</>
      )
    }
  }
}

export default AllReunion;
