import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSelectedChildStore } from '../Login/actions';
import _ from 'lodash';
import './MultiStore.css';
import {  getSelect} from '../common/ComponentUtilities';
import { getSortByDirection } from '../common/CustomComponents';

class MultiStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childStoreList : {},
            childStoresSelected : [],
            orderedList : []
        }        
        this.sortDirection = 'asc';
        this.sortParam = 'storeNbr';
    }

    componentDidMount(){
       this.getSelectedStoreDetails();
    }

    getSelectedStoreDetails = () => {
        let {childStoreList, childStoresSelected} = this.props;
        let multiStoreList = {};
        childStoreList && Object.keys(childStoreList).map(key => {
            let storeName = childStoreList[key].split('-');
            multiStoreList[key] = storeName[1].toUpperCase();
        })
        let orderedList = Object.keys(childStoreList).sort(function(a,b){
            return a -b;
        })
        orderedList = orderedList.filter(ele => ele !== this.props.storeId)
        this.setState({
            childStoreList: multiStoreList,
            orderedList,
            childStoresSelected
        })
    }

    handleStoreCheck = (storeID) => {       
        let { childStoresSelected} = this.state;
        if (!childStoresSelected.includes(storeID)) 
        {
            childStoresSelected.push(storeID);
        }
        else
        {
            childStoresSelected = childStoresSelected.filter(storeId => storeId != storeID)
        }
        this.setState({
            childStoresSelected
        });
        this.props.setSelectedChildStore(childStoresSelected);
    };

    checkForSelected = (storeId) => {
        const { childStoresSelected } = this.state;
        return childStoresSelected.includes(storeId);
    };

    selectAllOrRemoveAll = (e) => {
        let { childStoresSelected,childStoreList } = this.state;                 
        let allSelected = childStoresSelected.length == Object.keys(childStoreList).length;
        if(allSelected)
        childStoresSelected = [];
        else{
            Object.keys(childStoreList).map((storeId) => {
                !childStoresSelected.includes(storeId) && childStoresSelected.push(storeId)
            });
        }
        this.setState({
            childStoresSelected
        })
        this.props.setSelectedChildStore(childStoresSelected);
    };

    getSortByFields = ()=>{
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown', value : this.sortParam};
        const options = [
                            {props:{value:'storeName', id: 'orderDate', className:'SelectOption'}, text: 'Store Name'},
                            {props:{value:'storeNbr', id: 'invoiceNbr', className:'SelectOption'}, text: 'Store Number'},
                        ];
    
        return(      
            getSelect(selectProps, options)
        );
    }

    changeSortDirection = e => {
        this.page = 1;
        this.sortDirection = e.currentTarget.value;
        this.scrollPosition = window.pageYOffset;
        this.sortValue();
      };

      changeSortParam=(e)=>{
        this.scrollPosition = window.pageYOffset;
         this.sortParam = e.currentTarget.value;
         this.sortValue();
      }

      sortValue=()=>{
        let{childStoreList} = this.state;
        let orderedList = Object.keys(childStoreList);

        if(this.sortParam === 'storeName'  && this.sortDirection === 'desc'){
            orderedList = Object.keys(childStoreList).sort(function(a,b){
                return b[1] -a[1];
            })    
        }
        if(this.sortParam === 'storeName'  && this.sortDirection === 'asc'){
            orderedList = Object.keys(childStoreList).sort(function(a,b){
                return a[1] -b[1];
            })
        } 
   
        if(this.sortParam === 'storeNbr'  && this.sortDirection === 'desc'){
            orderedList = Object.keys(childStoreList).sort(function(a,b){
                return b -a;
            })    
        }
        
        if(this.sortParam === 'storeNbr'  && this.sortDirection === 'asc'){
            orderedList = Object.keys(childStoreList).sort(function(a,b){
                return a -b;
            })
        }  
        orderedList = orderedList.filter(ele => ele !== this.props.storeId)
        this.setState({
            orderedList
        })
      }
    
  
    render () {
        const sortBy = this.getSortByFields();
        const {
            childStoreList, orderedList
          } = this.state;
          const { storeId } = this.props
      return (
            <div className='multistore-selection'>
              <div className='segment_store'>
                <div className='page-header displayInline'>Multi-Store Select</div>
              </div>
              {Object.keys(childStoreList).length > 1 &&
              <div className='segment_store_selectAll'>
                  <span className='store-select-all-span'
                    onClick={this.selectAllOrRemoveAll}
                  >
                    Select/Clear All
                  </span>
                  <div className="ms-sort">
                    <span>Sort By</span>
                        {   sortBy }
                        {
                            getSortByDirection(this.changeSortDirection,null, null, this.sortDirection)
                        }
                  </div>
              </div>}
              <div className='segment_store_list'>
                <div className='two wide column multoStoreView'>
                    <Stores
                      key={storeId}
                      storeName={childStoreList[storeId]}
                      handleStoreCheck={this.handleStoreCheck}
                      selected={this.checkForSelected(storeId)}
                      disabled={Object.keys(childStoreList).length === 1}
	                      storeId={storeId} />                
                    {!_.isEmpty(orderedList) && orderedList.map((storeId, index) => {
                        return (
                        <Stores
                            key={storeId}
                            storeName={childStoreList[storeId]}
                            index={index}
                            handleStoreCheck={this.handleStoreCheck}
                            selected={this.checkForSelected(storeId)}
                            disabled={Object.keys(childStoreList).length === 1}
                            storeId={storeId}
                        />
                        );
                    })}
                </div>
              </div>
            </div>
      )
    }
}

const Stores = props => {
    const {
      index,
      handleStoreCheck,
      selected,
      key,
      storeName,
      disabled,
      storeId     
    } = props;
  
    return (
        <div className='select_store_check'>
            <label className='selectlabel'></label>
            <input
                type='checkbox'
                name = {'storeCheck'+storeId}
                onChange={() => handleStoreCheck(storeId)}
                checked={selected}
                disabled={disabled}
            />{storeId}{' - '}{storeName}
        </div>        
    );
  };

 

const mapDispatchToProps = dispatch => {
    return Object.assign(
      { dispatch },
      bindActionCreators({ setSelectedChildStore }, dispatch)
    );
  };

const mapStateToProps = state => {
    const { SessionReducer } = state;
    const { storeId, userName,multiStoreDetails,childStoresSelected } = SessionReducer;
    return { 
    userName,
    storeId ,
    multiStoreDetails,
    childStoreList:multiStoreDetails?.childStoreList,
    childStoresSelected
    };
};
  
export default connect(mapStateToProps,mapDispatchToProps)(MultiStore);