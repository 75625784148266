import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import GetContentWithStyle from '../WordPress/GetContentWithStyle';
import './style.css'
import restUtils from '../../utils/restUtils';
import { getWordpressGraphQLContent } from '../../urlConstants'
import _ from 'lodash'
import utilities from '../../utils/utilities';
import history from '../../history';

class WpTemplate extends Component {
  constructor(props){
    super(props);
    this.state={
      pageId: this.props?.match?.params?.pageId ? this.props?.match?.params?.pageId : null,
      data: null,
      isPost: this.props?.location?.state?.isPost ? this.props?.location?.state?.isPost : null
    }
  }
  componentDidMount(){
    if(this.props.location.pathname.toLowerCase() === '/content/mol.html') {
      history.push('/home')
      return
    }
    this.processContent(this.state.pageId)
  }
  componentDidUpdate() {
    const pageId = this.props?.match?.params?.pageId ? this.props?.match?.params?.pageId : null
    if(pageId !== this.state.pageId) {
      this.setState({
        pageId
      })
      this.processContent(pageId)
    }
  }
  componentWillReceiveProps(nextProps) {
      if(nextProps?.location?.state?.isPost && this.state.isPost !== nextProps?.location?.state?.isPost) {
        this.setState({
          isPost: nextProps?.location?.state?.isPost
        })
        this.processContent(this.state.pageId)
      }
  }
  processContent = (pageId) =>{
    utilities.setLoader(true)
    const urlVal = `/?type=${this.state.isPost ? 'post' :'page'}&id=${pageId}`
    restUtils.getDataWithBearer(`${getWordpressGraphQLContent()+urlVal}`).then(res=>{
      const data = res.data
      utilities.setLoader(false)
      this.setState({data})
      }).catch(err=>{
        console.log(err)
        utilities.setLoader(false)
      })
  }
  render() {
    return (
        <Segment className="wpTemplateStyle">
        {!_.isEmpty(this.state.data) && <GetContentWithStyle  data = {this.state.data} isPost={this.state.isPost}/>}
        </Segment>
    )
}
}
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return Object.assign(
      { dispatch },
    bindActionCreators({   }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WpTemplate);