import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Responsive, Segment } from 'semantic-ui-react';
import ProductListing from '../common/ProductListing';
import ProductListingGrid from '../common/ProductListingGrid';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import _ from 'lodash';
import classnames from 'classnames';
import FinelineCatalogRefineSelection from '../FinelineCatalog/FinelineCatalogRefineSelection/index';
import FinelineCatalogPreview from '../FinelineCatalog/FinelineCatalogPreview';
import FinlineCatalogCategoryRenderer from '../FinelineCatalog/FinelineCatalogCategoryPage';
import FinelineCatalogProductListItem from '../common/FinelineCatalogProductListItem';
import FinelineCatalogProductGridItem from '../common/FinelineCatalogProductGridItem';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class FinelineIndex extends Component {
  constructor(props) {
    super(props);
    const vendorExist =
      props.location &&
        props.location.state &&
        props.location.state.vendorExists
        ? true
        : false;
    this.state = {
      categoryData: [],
      loading: false,
      isNoProducts: false,
      img_txt_to_display:
        this.props.getprefer &&
          this.props.getprefer.thumbnailImage &&
          this.props.getprefer.thumbnailImage !== 'Y'
          ? 'Show Images'
          : 'Hide Images',
      serverError: false,
      showFilters:
        this.props.getprefer && this.props.getprefer.filterView
          ? this.props.getprefer.filterView === 'E'
            ? true
            : false
          : window.innerWidth <= 768
            ? false
            : true,
      viewType:
        vendorExist === false
          ? props.getprefer &&
            props.getprefer.prdctListlayoutFormat &&
            props.getprefer.prdctListlayoutFormat !== 'L'
            ? 'grid'
            : 'list'
          : props.getprefer &&
            props.getprefer.vendorPrdctLayoutFmt &&
            props.getprefer.vendorPrdctLayoutFmt !== 'L'
            ? 'grid'
            : 'list'
    };
    this.handleSort = this.handleSort.bind(this);
  }

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  handleSort(e) {
    this.setState({ viewType: e });
    return this.e;
  }

  getnoproducts = (totalItems) => {
    return (
      <>
        <div className='listing-header'>
          <span>
            {totalItems} products
          </span>
        </div>
        <div className='no_prdcts_display'>
          <h1>No Products to Display!</h1>
        </div>
      </>
    );
  };

  showHideImages = evt => {
    let img_txt_to_display = evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

  getOptionsSection = () => {
    return (
      <div className='options-div'>
        <div className='right-justified'>
          {<FinlineCatalogCategoryRenderer.selectionView />}
        </div>

        <Responsive minWidth={1024}>
          <div id='icon_align'>
            <i
              className='list icon cursor_p'
              value='List'
              id='list_icon'
              onClick={() => this.handleSort('list')}
            ></i>{' '}
            |
            <i
              className='th icon cursor_p'
              value='Grid'
              id='grid_icon'
              onClick={() => this.handleSort('grid')}
            ></i>
          </div>
        </Responsive>
      </div>
    );
  };

  renderFilterSection = () => {
    return (
      <div id='filter_width_ipad'>
        <FinelineCatalogRefineSelection
          toggleShowFilter={this.toggleShowFilter}
          showFilters={this.state.showFilters}
        />
      </div>
    )
  }
  
  renderHeaderSection = () => {
    return (
      <div className='displayFlex bread-crumb'>
          <div className='page-header' id='Warehouse_products'>Build your Catalog</div>
      </div>
    )
  }

  render() {
    const {
      img_txt_to_display,
      viewType,
      isNoProducts,
      loading,
      showFilters
    } = this.state;
    const { finelineCategories, finelineCatalogDepartmentSelected } = this.props;
    const finelineCategoryData = finelineCatalogDepartmentSelected ? finelineCategories.filter((c) => c.Department === finelineCatalogDepartmentSelected) : finelineCategories;
    const img_style = img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    const cat =
      viewType === 'list' && window.innerWidth >= 1024 ? (
        finelineCategoryData && finelineCategoryData.map((p) => (
          <FinelineCatalogProductListItem
            product={p}
            img_style={img_style}
            filters={this.props.finelineCatalogFilters}
          />
        ))
      ) : (
          <div className='ui stackable column grid product-grid'>
            {finelineCategoryData && finelineCategoryData.map((p) => (
              <FinelineCatalogProductGridItem
                product={p}
                img_style={img_style}
                filters={this.props.finelineCatalogFilters}
              />
            ))}
          </div>
      );

    return (
      <div className={classnames('categories', { 'filter-open': showFilters })}>
        {loading && (
          <div>
            <Loader active />
          </div>
        )}
        {this.state.serverError ? (
          <InternalServerError />
        ) : (
            <Segment id='productDetailInfo'>
              {this.renderHeaderSection()}
              {this.getOptionsSection()}

              <div className='displayFlex allOrdersTable'>
                {this.renderFilterSection()}

                <div id='grid_width' className='full-width pl_data'>
                  <div className="ui segment">
                    <FinelineCatalogPreview viewType={viewType} />
                  </div>

                  {isNoProducts ? this.getnoproducts(this.state.totalItems) :
                    <>
                      <FinlineCatalogCategoryRenderer.pagesCount showHideImages={this.showHideImages} imageState={this.state.img_txt_to_display}>
                        {cat}
                      </FinlineCatalogCategoryRenderer.pagesCount>
                    </>
                  }
                </div>
              </div>
            </Segment>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { catalog } = state.finelineCatalog;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer: state.preference.listData,
    finelineCategories: state.finelineCatalog.catalog.catalogItems,
    finelineCategoriesDescription: state.finelineCatalog.catalog.catalogItemDescription,
    finelineCatalogDepartmentSelected: state.finelineCatalog.catalog.selectedDepartmentCatalog,
    finelineCatalogFilters: catalog.filters
  }
}

export default connect(mapStateToProps, null)(FinelineIndex);
