import React, {Component} from 'react'
import { Grid, Responsive} from 'semantic-ui-react';
import {getImCategories} from './actions'
import renderImages from '../common/RenderImages'
import M4S from '../images/Merch4Sucess.PNG'
import './styles.css'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { getPOGs } from './actions';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import history from '../../history';
import utilities from '../../utils/utilities';
import thumbnail2 from '../images/im_thumbnail2.png'
import thumbnail3 from '../images/im_thumbnail3.png'
import restUtils from '../../utils/restUtils';
import { pogListUrl, IMPULSE_MERCHANDISING_GUIDE, M4S_MONTHLY_PLANNER } from '../../urlConstants';

class ImpulseMerchandising extends Component{
    constructor(props){
        super(props);
        this.state = {
            categories:props.categories
        }
    }
    categoryClickHandler = (e)=>{
        const categoryId = e.target.id;
        if(categoryId === 'CLIPSTRIPS'||categoryId === 'COUNTERTOP'){
            let assortment = null;
            if(this.props.ImStore[categoryId] && this.props.ImStore[categoryId].length>0){
                assortment = this.props.ImStore[categoryId][0];
                this.props.getPOGs(categoryId);
                history.push({
                    pathname:'/im-item-listing/'+assortment.Category_Name+'/'+assortment.Ordering_FileName,
                    state:{assortment}
                })
            }else{
                restUtils.getData(pogListUrl+categoryId)
                .then(response=>{
                    assortment = response.data[0];
                    this.props.getPOGs(categoryId);
                    history.push({
                        pathname:'/im-item-listing/'+assortment.Category_Name+'/'+assortment.Ordering_FileName,
                        state:{assortment}
                    })
                })
            }
        }else{
            history.push('/impulse-merchandising/'+categoryId)
        }
    }

    componentDidMount(){
        this.props.getImCategories();
    }

    render(){
        const breadcrumbPath = [{ link: '/home', text: 'HOME' }]
        return (
            <div className='page-wrap'>
                <div className='page-header'>Impulse Merchandising</div>
                <BreadcrumbComp path={breadcrumbPath}/><br/><br/>
                <Responsive maxWidth={767}>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                {renderImages.getImage({src:M4S, className:'image2b ui image', alt:'acv'})}
                                <div className='im-card' id='ENDCAPS' onClick={this.categoryClickHandler}>
                                    End Caps
                                </div>
                                <div className='im-card' id='CHECKOUT ASSORTMENTS' onClick={this.categoryClickHandler}>
                                    Checkout Area
                                </div>
                                <div className='im-card' id='WINGPANELS' onClick={this.categoryClickHandler}>
                                    Wing Panels 
                                </div>
                                <div className='im-card' id='CLIPSTRIPS' onClick={this.categoryClickHandler}>
                                    Clip Strips
                                </div>
                                <div className='im-card' id='COUNTERTOP' onClick={this.categoryClickHandler}>
                                    Stocked Countertops
                                </div>
                                <div  className='im-downloads-card'>
                                    <div className='im-downloads'>
                                        <p>Impulse Merchandising</p>
                                        <button className='vendorNum'
                                        onClick={()=>utilities.openInNewTab(IMPULSE_MERCHANDISING_GUIDE)}>
                                            Download PDF
                                        </button>
                                    </div>
                                    {renderImages.getImage({
                                        src:thumbnail2,
                                        className:'im-thumbnail'
                                    })}
                                </div>
                                <div  className='im-downloads-card'>
                                    <div className='im-downloads'>
                                        <p>Monthly Planner</p>
                                        <button className='vendorNum'
                                        onClick={()=>utilities.openInNewTab(M4S_MONTHLY_PLANNER)}>
                                            Download PDF
                                        </button>
                                    </div>
                                    {renderImages.getImage({
                                        src:thumbnail3,
                                        className:'im-thumbnail'
                                    })}
                                </div>                                
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>
                <Responsive minWidth={768}>
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                {renderImages.getImage({src:M4S, className:'image2b ui image', alt:'acv'})}
                            </Grid.Column>
                            <Grid.Column>
                                <div className='im-card' id='ENDCAPS' onClick={this.categoryClickHandler}>
                                    End Caps
                                </div>
                                <div className='im-card' id='WINGPANELS' onClick={this.categoryClickHandler}>
                                    Wing Panels 
                                </div>
                                <div className='im-card' id='COUNTERTOP' onClick={this.categoryClickHandler}>
                                    Stocked Countertops
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className='im-card' id='CHECKOUT ASSORTMENTS' onClick={this.categoryClickHandler}>
                                    Checkout Area
                                </div>
                                <div className='im-card' id='CLIPSTRIPS' onClick={this.categoryClickHandler}>
                                    Clip Strips
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <div  className='im-downloads-card'>
                                    <div className='im-downloads'>
                                        <p>Impulse Merchandising</p>
                                        <button className='vendorNum'
                                        onClick={()=>utilities.openInNewTab(IMPULSE_MERCHANDISING_GUIDE)}>
                                            Download PDF
                                        </button>
                                    </div>
                                    {renderImages.getImage({
                                        src:thumbnail2,
                                        className:'im-thumbnail'
                                    })}
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div  className='im-downloads-card'>
                                    <div className='im-downloads'>
                                        <p>Monthly Planner</p>
                                        <button className='vendorNum'
                                        onClick={()=>utilities.openInNewTab(M4S_MONTHLY_PLANNER)}>
                                            Download PDF
                                        </button>
                                    </div>
                                    {renderImages.getImage({
                                        src:thumbnail3,
                                        className:'im-thumbnail'
                                    })}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return {
        categories:state.ImReducer.categories,
        ImStore:state.ImReducer
    }
}

const mapDispatchToProps = (dispatch)=>{
    return Object.assign(
        { dispatch },
        bindActionCreators({getImCategories,getPOGs},dispatch));
}

export default connect(mapStateToProps,mapDispatchToProps)(ImpulseMerchandising);
