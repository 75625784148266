import React from 'react';
import { Segment, Grid, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Dialog from 'react-dialog';
import renderImages from '../common/RenderImages';
import { MaskPrice } from './MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

const link = '/product/';
class FinelineCatalogProductListItem extends React.Component {
  state = {
    expand: '',
    item: '',
  };

  expand = (num) => {
    this.setState({
      expand: 'expan',
      item: num,
    });
  };

  closeModalexpand = () => {
    this.setState({
      expand: '',
      item: '',
    });
  };

  renderImage = () => {
    const { product, img_style } = this.props;

    return (
      <Link to={{ pathname: link + product.Item_Number }} className='card_list_img' >
        {renderImages.getImage({
          src: product.Image_File_Name
            ? product.Image_File_Name
            : 'https://images.truevalue.com/getimage.asp?id=' +
            product.Item_Number +
            '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
          style: { display: img_style },
          alt: 'product',
          className: 'ui image image_lisitng',
        })}
      </Link>
    );
  };

  renderItemTitle = () => {
    const { product } = this.props;

    return (
      <span>
        <span>
          <Link to={{ pathname: link + product.Item_Number }} className='card_list_img' >
            <b className='First_level_details'>
              {product.Product_Name || product.Product_Title}
            </b>
          </Link>
        </span>
        <b className='vendor-span'>
          <span style={{ textDecoration: 'underline' }}>
            {product.Vendor_Name}
          </span>
        </b>
      </span>
    );
  };

  renderItemDescription = () => {
    const { product } = this.props;

    if (product.Long_Description) {
      return (
        <p className='listingDescription'>
          {product.Long_Description.length < 250
            ? product.Long_Description
            : product.Long_Description.substr(0, 242) + '...'}
          <u
            className='cursor_p'
            onClick={() => this.expand(product.Item_Number)}
          >
            {product.Long_Description.length < 250 ? '' : 'more'}
          </u>
        </p>
      );
    }
  };

  renderExpandedItemDescription = () => {
    const { expand, item } = this.state;
    const { product } = this.props;

    if (expand === 'expan' && item === product.Item_Number) {
      return (
        <div className='DiscriptionDialog' onMouseLeave={this.closeModalexpand}>
          <Dialog width='1' height='1' position='absolute'>
            {product.Long_Description}
          </Dialog>
        </div>
      );
    }

    return null;
  };

  render() {
    const { product, filters } = this.props;

    return (
      <Segment style={{ position: 'static', margin: '1rem 1rem' }}>
        {this.renderExpandedItemDescription()}
        <div className='ui stackable two column grid'>
          <div className='one wide column left-top-padding'>
            <div className='card_list_wrap'>{this.renderImage()}</div>
          </div>
          <div className='fifteen wide column'>
            <Grid>
              <Grid.Column computer={10} tablet={10}>
                {this.renderItemTitle()}
                {this.renderItemDescription()}
              </Grid.Column>
              <Grid.Column
                className='less-side-padding'
                computer={6}
                tablet={6}
              >
                <Table
                  stackable
                  className='very basic fixed'
                  style={{ fontWeight: '1000' }}
                >
                  <Table.Body style={{ fontSize: '0.8rem' }}>
                    <Table.Row>
                      <Table.Cell className='First_level_details'>
                        <span>ITEM#:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details'>
                        {product.Item_Number}
                      </Table.Cell>
                      <Table.Cell className='First_level_details'>
                        <span>MEMBER COST:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details'>
                        {filters.memberCost !== 'Hide' && (
                          <MaskPrice
                            viewType={
                              filters.memberCost === 'Show' ? MEMBER_VIEW : null
                            }
                            field='Member Cost'
                            fieldVal={product.Member_Cost}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='First_level_details'>
                        <span>MODEL#:</span>
                      </Table.Cell>
                      <Table.Cell
                        className='First_level_details'
                        style={{ width: '6rem' }}
                      >
                        {product.Model}
                      </Table.Cell>
                      <Table.Cell>
                        <span>SUGG. RETAIL:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details'>
                        {filters.suggestedRetail !== 'Hide' && (
                          <MaskPrice
                            viewType={
                              filters.suggestedRetail === 'Show'
                                ? MEMBER_VIEW
                                : null
                            }
                            field='Suggested Retail'
                            fieldVal={product.SuggestedRetail}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <span>UPC:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details'>
                        {product.UpcNum}
                      </Table.Cell>
                      <Table.Cell className='First_level_details'>
                        PACK:
                      </Table.Cell>
                      <Table.Cell>
                        {product.S_Pack_Type}
                        {product.S_Pack_Qty}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colSpan={'2'} className='t-center'>
                        <span className='restricted-product'>
                          {product.Restricted ? 'Restricted Product' : ''}
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </Segment>
    );
  }
}

export default FinelineCatalogProductListItem;
