import _ from 'lodash';
import {
  SEARCH_ADDRESS,
  UPDATE_ADDRESS_LIST,
  DELETE_ADDRESS_LIST
} from './actions';

import utilities from '../../utils/utilities';
const initState = {
  addressList: []
};
const addressBook = (state = initState, { type, payload }) => {
  switch (type) {
    case SEARCH_ADDRESS:
      const { addressList, isLoading, lastItem, totalPages,totalItems,firstItem, currentPage } = payload;
      const { addressList: existingList } = state;
      const newAddressList = utilities.mergeBy(
        existingList,
        addressList,
        'locationId'
      );
      const sortedAddressList = _.sortBy(newAddressList, ['consumerName']);
      return { ...state, addressList: sortedAddressList, 
        lastItem, totalPages,totalItems, 
        firstItem, currentPage, isLoading };
    case DELETE_ADDRESS_LIST:
    case UPDATE_ADDRESS_LIST:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default addressBook;
