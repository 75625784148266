import _ from 'lodash';
import React from 'react';
import { getWordpressUri, getWordpressStaticUrl} from '../../urlConstants'
const GetContentWithStyle = (props) => {
    const data = props.isPost ? props?.data?.Data?.Post : props?.data?.Data?.Page
    const styleSheets =data?.EnqueuedStylesheets.Nodes.map(style=>{
        if(style?.Src) {
            return style.Src
        }
     })
     const wpUrl = getWordpressUri()
     const wpStaticUrl = getWordpressStaticUrl()
       return (<>
       {styleSheets ? (
        <>
         {styleSheets.map(src=>{
            if(src) {
                if(src.includes(wpUrl)) {
                    return <link  key={src} href={`${wpStaticUrl}/wp-styles${src.split(wpUrl)[1]}`} rel="stylesheet"/>
                }
                else {
                    return <link key={src} href={src.startsWith('https://') ? src: wpStaticUrl+'/wp-styles'+src} rel="stylesheet"/>
                }
               
            }
             })}
        <div className='wpTemplateWrapper'>
            {!_.isEmpty(data)&& (
                <>
                {data?.Title && <h3 className='wpTemplateHeading'>{data?.Title}</h3>}
                <div className='wpHompageContent' dangerouslySetInnerHTML={{__html: data.Content}}></div>
                </>
            )}
        </div>
           
            </>
       ):<p>Page Not Found</p>}
                    
       </>
   
       );
     }
   
     export default GetContentWithStyle