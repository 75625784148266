import React from "react";
import { connect } from "react-redux";
import utilities from "../../../utils/utilities";
import { getTableRow, getTable } from "../../common/ComponentUtilities";
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import { MaskPrice } from '../../common/MaskPrice';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

class CollapsiblePalletTable extends React.Component {
  getTableHeaders = () => {
    const tableHeader = [
      { props: { className: "allOdrHeader", id: "no_border" },text: "Item SKU"},
      ...utilities.isAliasSkuRequired(this.props.multiStoreDetails)?
      [{ props: { className: "allOdrHeader", id: "no_border" }, text: "Alternate SKU#" }]:[],
      { props: { className: "allOdrHeader", id: "no_border" }, text: "Model #" },
      { props: { className: "allOdrHeader", id: "no_border" }, text: "UPC" },
      { props: { className: "allOdrHeader",  }, text: "Description" },
      { props: { className: "allOdrHeader", id: "no_border" },text: "Quantity" },
      { props: { className: "allOdrHeader", id: "no_border" }, text: parseInt(this.props.shoppingAreaMetaId) === 2 ? "EVT Cost": "DS EVT Cost" },
	    { props: { className: "allOdrHeader", id: "no_border" }, text: parseInt(this.props.shoppingAreaMetaId) === 2 ? "Member Cost": "DS Cost" },
      { props: { className: "allOdrHeader", id: "no_border" }, text: "Suggested Retail" },
      { props: { className: "allOdrHeader", id: "no_border" }, text: "Non Stocked" },
      { props: { className: "allOdrHeader", id: "no_border" }, text: "Restricted" }
    ];
    return(getTableRow({},tableHeader,true))
  };
  getRows = (rowProps, product) =>{
    const{getprefer,memberViewType}=this.props;
    const columnProps = { className: 't-center', id: 'field_width' }
    const memberCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={product.mbrCost}
        field={"Member Cost black"}
      />
    );
    const marketCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={product.specWhseCost}
        field={"Member Cost black"}
      />
    );

    const srcost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={product.regSr}
      />
    );
    let row = [
      { columnProps, columnText: utilities.hyphenForNull(product.itemNbr) },
      ...utilities.isAliasSkuRequired(this.props.multiStoreDetails)?
      [{ columnProps, columnText: utilities.hyphenForNull(product.aliasSku) }]:[],
      { columnProps, columnText: utilities.hyphenForNull(product.model) },
      { columnProps, columnText: utilities.hyphenForNull(product.upc) },
      { columnProps, columnText: utilities.hyphenForNull(product.shortDesc) },
      { columnProps, columnText: utilities.hyphenForNull(product.quantity) },
      { columnProps, columnText: marketCost },
      { columnProps, columnText: memberCost},
      { columnProps, columnText: srcost},
      { columnProps, columnText: utilities.hyphenForNull(product.stocked) }
    ];
    if(product?.Restrictions?.length > 0) {
      row=[...row, {columnProps, columnText: utilities.restrictedDetail(product?.Restrictions), className: 'First_level_details t-center restricted-product' }]
    }
    else {
      row=[...row, {columnProps, columnText: "-"}]
      }
    return (
      <>
      {getTableRow(rowProps, row)}
      </>
    )
  }
  render() {
    const {isGridView,palletItemsLoading} = this.props;
    const table = getTable({
    class:classnames("stackable"), id:isGridView?'':'dc_desc_table_list'},
    this.getTableHeaders(), 
    this.props.products, 
    this.getRows, 
    {className:'pallet-table-row'}
  );
    return(
      <div className={`${isGridView?'collapsible-table-grid':''}`} >
         {palletItemsLoading && (
          <div>
            <Loader active />
          </div>
        )}
        {table} 
      </div>
    );
  }
}
const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    memberViewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW
  };
};
export default connect(mapStateToProps)(CollapsiblePalletTable)
