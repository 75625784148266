import React from 'react'
import InternalServerError from '../../ErrorPages/500_error_page';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import utilities from '../../../utils/utilities';
import { getReunionOfferings } from '../actions'
import { REUNION_OFFERS } from '../../../urlConstants';
import Filter from '../../common/Filter';
import _ from 'lodash';
import history from '../../../history';
import { ReunionCard } from '../ReunionCard';
import { isArray } from 'util';

class DiscountCityOfferListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reunionEvents: [],
            eventId: '',
            orderDeadline: '',
            loading: true,
            filters: [],
            clearFilters: false,
            appliedFilters: {},
            showFilters: (this.props.getprefer && this.props.getprefer.filterView) ? this.props.getprefer.filterView==='E'?true:false:window.innerWidth > 768?true:false,
        }
        this.filterString = {};
    }

    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };

    componentDidMount() {
        this.getReunionEvents();      
    }
    componentWillReceiveProps(newProps) {
        if (this.state.loading && newProps.loaded) {
            this.setState({ loading: false })
        }
        if (this.props.searchby !== newProps.searchby || this.props.searchvalue !== newProps.searchvalue) {
            this.getReunionEvents(newProps);
        }
    }

    
    filterCallback = (isAdd, filterKey, filterValue) => {
        let appliedFilters = this.state.appliedFilters;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
            appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
        } else if (keyExists) {
            appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
                ? appliedFilters[filterKey]
                : appliedFilters[filterKey].split(',');
            let value = appliedFilters[filterKey];
            let index = value.indexOf(filterValue);
            if (index > -1) {
                value.splice(index, 1);
            } else {
                value.push(filterValue);
            }
            if (!value.length && keyExists) delete appliedFilters[filterKey];
            else appliedFilters[filterKey] = value;
        }
        this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
        this.setState({
            appliedFilters,
            clearFilters: false,
            backPath : this.pathname
        },()=>{
            this.getReunionEvents();
        });
        
    };

    getReunionEvents = (newProps) => {
        const {searchby, searchvalue } = newProps?newProps:this.props;
        this.setState({ loading: true });
        let url = `${REUNION_OFFERS}/listevent`;              
        let requestPayLoad = this.filterString ? { ...this.filterString } : {};
        if(searchby && searchvalue){
            requestPayLoad={...requestPayLoad,
                                searchby,
                                searchvalue
                            }
        }
        this.props.getReunionOfferings(url,requestPayLoad);
    }
    showVendors = (e) => {
        const eventId = e.target.dataset?e.target.dataset.promonbr:'';
        history.push({
            pathname:'/discountCityEventVendors/'+eventId,
            state:{eventId},
        })
    }
    showItemList = (e) =>{
        const promoNbr = e.target.dataset?e.target.dataset.promonbr:'';
        const { isDiscountCity } = this.props;
        history.push({
            pathname:'/discountCityItems/'+ promoNbr,
            state:{
                isAllItems:true,
                isDiscountCity:isDiscountCity,
            }
        });
    }
    render() {
        const { reunionEvents, isDiscountCity } = this.props;
        const eventId = reunionEvents && reunionEvents.length > 0 ? reunionEvents[0].relay_nbr : '';
        const deadline = reunionEvents && reunionEvents.length > 0 ? utilities.getDateFromTimeStamp(reunionEvents[0].endOrderDate) : '';
        const gridClassName = window.innerWidth < 1024 ? 'ui stackable two column grid' : window.innerWidth < 1240 ? 'ui stackable three column grid' : 'ui stackable five column grid';
        return (
            this.state.serverError ?
                <InternalServerError />
                :
                <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
                    {this.state.loading && (
                        <div>
                            <Loader active />
                        </div>
                    )}
                    <div id='filter_width_discountCity'>
                       
                    </div>
                    <div className='page_details'>
                        <div className={gridClassName} id="reunion_items">
                            <div className="discountNumber">
                                <span>Event ID: #{eventId}</span>
                                <span className="Discount-date">Order Deadline: {deadline}</span>
                            </div>
                            {reunionEvents && reunionEvents.map(event => {
                                const promo_name = event.promo_name.trim();
                                return (
                                    <>
                                        {promo_name.includes("DISCOUNT CITY") ?
                                            <div className='column promos'>
                                                <ReunionCard
                                                    isReunion={true}
                                                    promo_nbr={event.promo_nbr}
                                                    promo_name={promo_name}
                                                    showVendors={this.showVendors}
                                                    showItemList = {this.showItemList}
                                                    isDiscountStyle={true}
                                                />
                                            </div> : ''}
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reunionEvents: state.ReunionReducer && state.ReunionReducer.events,
        loaded : state.ReunionReducer.loaded,
    }
}

const mapDispatchToProps = dispatch => {
    return Object.assign({ dispatch },
        bindActionCreators({
            getReunionOfferings
        }, dispatch)
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCityOfferListing)