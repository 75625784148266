import React from 'react';
import { GET_ITEM_RESTRICTION, UPDATE_ITEM_RESTRICTION, SHOPPING_ADMIN_LIST } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { getSelect, getTextNameField, getTableRow, getTable, getButton } from "../common/ComponentUtilities";
import "../Orders/PromoShip/styles.css";
import { Loader, Responsive} from "semantic-ui-react";
import Bin from '../../images/bin.svg';
import renderImages from '../common/RenderImages';
import "./admin.css"
import _ from 'lodash'
import utilities from '../../utils/utilities';
import {getExcelUploadField} from '../common/CustomComponents';
import { quantityValidator } from '../common/productHandler';
import { downloadExcel, readExcelData } from '../common/GenericExcelExport/exportUtils';
export const ITEM_RESTRICTION_REQ_FIELDS = ['itemNbr','qunatity'];
export const ITEM_RESTRICTION_TEMPLATE_NAME = 'ItemRestrictionTemplate';
export const ITEM_RESTRICTION_TEMPLATE = [{'Item #':'', 'Quantity Limit':''}];
export const columnDefs = {0:'itemNbr', 1: 'qunatity'};
class LimitQuantity extends React.Component {
  constructor(props) {
    super(props)
    const eventName = this.props?.match?.path?.indexOf('BargainBurners') !== -1
      ? 'BargainBurners'
      : this.props?.match?.path.indexOf('Wow') !== -1 ? 'WOW' : '';
    //const eventName =this.props?.match?.path;
    this.state = {
      rows: [],
      loading: false,
      shoppingArea : {},
      eventName : eventName,
      fileName:''
    };    
    this.file = '';
  }

  componentDidMount() {
    this.getItemRestriction();
  }

  getItemRestriction = () => {
    this.setState({ loading: true, disableSave: true});
    const shoppingMetadataId = this.state.eventName === 'BargainBurners' ? 1 : 5;
    restUtils.getData(GET_ITEM_RESTRICTION+'?shoppingMetadataId='+shoppingMetadataId)
      .then(response => {
        // Get item restriction data  
        const rows = response.data;
        //this.getShoppingAreas(rows, shoppingMetadataId);
        this.setState({ rows,loading: false, disableSave : false });
      })
      .catch(error => {console.log(error); this.setState({ loading: false, disableSave : false })})
      .finally(this.setState({ loading: false }));
  }

  getShoppingAreas = (rows, shoppingMetadataId) => {
    restUtils.getData(SHOPPING_ADMIN_LIST)
      .then(response => {
        const ShoppingAreaFiltered = response.data.filter(function(item) {return item.shoppingMetadataId === shoppingMetadataId});
        const shoppingArea = ShoppingAreaFiltered.map(item => ({ text: item.shoppingName, props: { value: item.shoppingId, id: item.shoppingId } }));
        shoppingArea.unshift({ text: "Select...", props: { value: undefined, id: "default" } });
        this.setState({ rows, shoppingArea,loading: false, disableSave : false });
      })
      .catch(error =>  {console.log(error); this.setState({ loading: false, disableSave : false })})
      .finally(this.setState({ loading: false }));
  }

  getItemRestrictionHeaders = () => {
    const tableHeader = [
      // { props: { className: 'rowHeader', id: 'no_border' }, text: 'Shopping Area' },
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Item #' },
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Quantity Limit' },
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Multiplies' },
      { props: { className: 'rowHeader', id: 'no_border_admin_del' }, text: '' }
    ];
    return (getTableRow({}, tableHeader, true))
  }

  getShoppingAreaFields = (id, value, metadata, loadMore) => {    
    const selectProps = { className: 'sort-criteria combo', onChange: this.changeMetadata, id: id, value: value ,onKeyDown : loadMore };
    return getSelect(selectProps, metadata);
  }

  changeMetadata = (e) => {
    this.updateRowState(e.target);
  }

  getItemRestrictionRow = (clas, row, index) => {
    if(row.status != "D"){
      const tableDataCols = this.buildRowData(row, index);
      //let promoClassname = (promoShipOrderList?.orderList?.length === index+1) ? '' : 'promoRowdisable';
      return (
        <>
          {getTableRow({}, tableDataCols, false, 'tableRow')}
        </>
      )
    }
  }

  textOnChange = (e) => {
    const dat = e?.target?.id.split("-");
    if(dat?.includes('itemNbr') && isNaN(e?.target?.value)){
      return;
    }else if(dat?.includes('qunatity') && !quantityValidator(e?.target?.value)){
      return;
    }else{
      this.updateRowState(e.target);
    }
  }

  updateRowState = (target) => {
    const dat = target.id.split("-");
    const { rows } = this.state;
    rows[parseInt(dat[0])][dat[1]] = target.value;
    if (rows[parseInt(dat[0])]["status"] !== "I") {
      rows[parseInt(dat[0])]["status"] = "U";
    }
    this.setState({ rows });
  }

  rowTextComp = (id, value, disabled = false) => {
    return getTextNameField(id, id, value,this.textOnChange , undefined, "t-center", false, undefined, disabled);
  }

  downloadTemplate = () => {
    downloadExcel(ITEM_RESTRICTION_TEMPLATE, `${ITEM_RESTRICTION_TEMPLATE_NAME}.xlsx`);
  }

  chooseFile = (e)=>{
    const files = e.target.files[0];
    let fileReader;
    const reader = new FileReader();
    reader.onload = (e) => { 
      this.file = reader.result;
    };
    reader.readAsBinaryString(files);
    this.setState({
      fileName: files.name
    });
  }

  uploadFile = (e) => {
    let fileType = this.state.fileName.split(/\.(?=[^.]+$)/);
    let {rows,shoppingArea} = this.state;
    if(fileType[1] !== 'xlsx') {
      utilities.showToast('Kindly upload .xlsx file');
    }else {      
      this.setState({disableSave: true, loading: true});
      let data = readExcelData(this.file,{header:1,blankrows:false});
      data.forEach((row,index) => {
        let newRow = {};
        if(index !== 0){
          Object.keys(row).forEach(key => {
            let value = row[key].toString().trim();
            if((columnDefs[key] === 'itemNbr' || columnDefs[key] === 'qunatity') && isNaN(value)){
              value = '';
            }
            newRow = {...newRow, ...{[columnDefs[key]] : value}};
          });
          rows.push({...newRow, ...{shoppingMetadataId : this.state.eventName === 'BargainBurners' ? 1 : 5,isMultiples:'False', status:'I'}});
        }
      });
      this.setState({
        rows,
        disableSave: false, 
        loading: false
      });
    }
  };

  buildRowData = (row, index) => {
    const multipliesDropDown = [{ text: 'TRUE', props: { value: 'True', id: 'True' } },
      { text: 'FALSE', props: { value: 'False', id: 'False' } }];
    return (
      [
        // { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.getShoppingAreaFields(`${index}-shoppingId`, row.shoppingId, this.state.shoppingArea) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-itemNbr`, row.itemNbr, row.status === 'I' ? false : 'disabled') },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-qunatity`, row.qunatity) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.getShoppingAreaFields(`${index}-isMultiples`, row.isMultiples, multipliesDropDown, this.loadMore) },
        { columnProps: { className: 'tableCell', id: 'no_border_admin_del' }, columnText: this.getDeleteIcon(`${index}-del`) }
      ]
    );
  }

  deleteItemRestriction = (e) => {
    const index = parseInt(e.target.id.split("-")[0]);
    const { rows } = this.state;
    if (rows[index]["status"] === "I") {
      rows.splice(index, 1);
    } else {
      rows[index]["status"] = "D";
    }
    this.setState({ rows });
  }

  getDeleteIcon = (id) => {
    return renderImages.getImage({
      id,
      src: Bin,
      alt: 'bin',
      className: 'delete-icon',
      onClick: this.deleteItemRestriction
    });
  }
  
  loadMore = (e) => {
    const dat = e.target.id.split("-");
    const { rows} = this.state;
    if (parseInt(dat[0]) === rows.length - 1 && e.keyCode === 9) {      
      rows.push({itemNbr:'', qunatity:'', shoppingMetadataId : this.state.eventName === 'BargainBurners' ? 1 : 5,isMultiples:'False', status:'I'});
      this.setState({ rows });
    }
  };

  validateRequiredField = (row) => {
    return ITEM_RESTRICTION_REQ_FIELDS.every(field=> _.has(row, field) &&
                  !utilities.isEmptyOrNullString(row[field]) && row[field] !== 'Select...');
  }

  checkForDuplicateRecords = (row) => {
    const {rows} = this.state;
    return rows.filter(e => e.itemNbr === row.itemNbr).length > 1;
  }

  saveLimitQuantity = () => {
    const { rows } = this.state;
    let isValid = true;
    let isDuplicateRecordFound = false;
    rows.forEach(row => {
      if((row.status === "I" || row.status === "U") &&
          !this.validateRequiredField(row)) {
        isValid = false;
        return;
      }   
      if(row.status === 'I' && this.checkForDuplicateRecords(row)){
        isDuplicateRecordFound = true;
        return;
      }    
    })
    if(!isValid){
      utilities.showToast(`Please enter all required fields`, true);
      return;
    }
    if(isDuplicateRecordFound){
      utilities.showToast(`Duplicate Records found`, true);
      return;
    }
    const saveData = rows.filter(row => {
      return row.status === "I" || row.status === "D" || row.status === "U"
    });

    this.setState({ loading: true });
    restUtils.postData(UPDATE_ITEM_RESTRICTION, saveData)
      .then(response => {
        utilities.showToast(`Limit quantity updated successfully`);
        this.getItemRestriction();
      })
      .catch(error => console.log(error))
      .finally(this.setState({ loading: false }));
  }

  uploadItemRestrictionList = () => {
    return (
    <div className='upload-link'>
      <div className='displayFlex page-header mb-2'>
          {this.state.eventName === 'BargainBurners' ? 'Limit Quantity (Bargain Burners)' : 'Limit Quantity (WOW)'}
      </div>
      <div className='displayFlex'>
        {getExcelUploadField("Choose File", "upload-file", this.state.fileName , this.chooseFile, 'custom-input-btn', 'custom-file-input',this.downloadTemplate)}       
      </div>
      <div className='displayFlex pt-3'>
        <div className='pl-15'>
          {getButton({type:'button', className:'fluid ui red button submit', name:'uploadFile', onClick:this.uploadFile, disabled:this.state.disableSave}, 'Submit')}
        </div>
      </div>
    </div>
    );
  }

  render() {
    const { rows, loading, reunion } = this.state;
    
    return (
      <>
      {loading ? (
          <div style={{ "padding": "2rem" }}>
            <Loader active />
          </div>
        ): (
          <>
          <div className='displayFlex'> 
            <div>
                {this.uploadItemRestrictionList()}
            </div>
          </div>
          <div>
          {getTable({ className: "ui celled table all-wh-table limitQty-table", id: 'font-size-orders' }, this.getItemRestrictionHeaders(), rows, this.getItemRestrictionRow, { className: 'wh-row' })}
          {getButton({type:'button', className:'ui red button place-order-btn saveLimitQty', name:'saveShp', onClick:this.saveLimitQuantity, disabled:this.state.disableSave}, 'Save')} 
          </div>          
        </>
      )}
    </>
    );
  } 
}

export default LimitQuantity;