export const item = {
  itemno: '',
  quantity: '',
  overrideRetail: '',
  maxQty:'',
  retailCost: '',
  suggestedRetail:'',
  memberCost:'',
  extCost: '',
  productName: '',
  errorMsg: '',
  imgName:'',
  whStockStatusMessage: ''
};

export const getEmptyItems = numberOfItems => {
  const items = Array(numberOfItems);
  items.fill(item);
  return items.map(item => ({ ...item }));
};
