import React from "react";
import PropTypes from "prop-types";
import Item from "./Item";

const FinelineCatalogPreviewDepartmentList = ({
  departmentList,
  removeDepartment,
  selectDepartmentCatalog,
  selectedDepartmentCatalog,
}) => {
  return (
    <div className="department_tag_label--list">
      {departmentList.map((item, index) => (
        <Item
          key={`${item.id}-${item.name}-${index}`}
          item={item}
          removeDepartment={removeDepartment}
          selectDepartmentCatalog={selectDepartmentCatalog}
          isSelected={item.name === selectedDepartmentCatalog}
        />
      ))}
    </div>
  );
};

FinelineCatalogPreviewDepartmentList.propTypes = {
  departmentList: PropTypes.arrayOf(PropTypes.object),
  removeDepartment: PropTypes.func.isRequired,
  selectDepartmentCatalog: PropTypes.func.isRequired,
};

FinelineCatalogPreviewDepartmentList.defaultProps = {
  departmentList: [],
};

export default FinelineCatalogPreviewDepartmentList;
