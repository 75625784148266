import React from "react";
import { Link } from "react-router-dom";
import FixtureTable from "./FixtureTable";

const FixturesPageWrapperStyle = { padding: "5px" };

const FixturesPageTitleStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: 600,
};

const FixturesPagePogDetailsStyle = { marginTop: "10px", fontWeight: 600 };

const FixturesPageBackLink = { paddingRight: "15px", fontWeight: 600 };

export default function Fixtures(props) {
    const {
        fixtures,
        pogNumber,
        pogDescription,
        backLocation,
        missing
    } = props.location.state;

    return (
        <div style={FixturesPageWrapperStyle}>
            <div style={FixturesPagePogDetailsStyle}>
                {pogNumber} - {pogDescription}
            </div>
            <div style={FixturesPageTitleStyle}>
                <span>CTB Planogram Fixtures:</span>
                <Link
                    to={{
                        pathname: backLocation,
                        state: {
                            missing
                        }
                    }}
                    style={FixturesPageBackLink}
                    className="assortments_link"
                >
                    <u>Back to Assortment</u>
                </Link>
            </div>
            <FixtureTable isReadOnly showAll fixtures={fixtures} />
        </div>
    );
}
