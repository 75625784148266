import { ADD_CATALOG_AD, REMOVE_CATALOG_AD, UPDATE_CATALOG_AD } from "../actionTypes";

const initialState = {
  ads: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_CATALOG_AD:
      return {
        ...state,
        ads: [...state.ads, payload.catalogAd],
      };

    case REMOVE_CATALOG_AD:
      return {
        ...state,
        ads: state.ads.filter(
          (ad) =>
            ad.title !== payload.ad.title &&
            ad.url !== payload.ad.title &&
            ad.imageUrl !== payload.ad.imageUrl
        ),
      };
    
    case UPDATE_CATALOG_AD:
      const foundIndex = state.ads.findIndex((ad) => ad.id === payload.ad.id);
      const ads = [...state.ads];
      ads[foundIndex] = { ...ads[foundIndex], ...payload.ad };

      return {
        ...state,
        ads,
      }
    default:
      return state;
  }
};
