import React, { Component } from 'react';
import { connect } from 'react-redux';
import { utilities } from '../../utils/utilities';
import './styles.css';
import {
    CORPORATE_EBOOK, E_BOOK_TYPES, SHOPPING_AREA, VENDOR_NAME, SHOW_PROMOS,
    NO_SHOPPING_AREAS, NO_VENDOR_NAMES, NO_ACCESS_MSG, NO_PROMOS_EVENTS,
    SELECT_EBOOK_TYPE_MSG, SELECT_SHOPPINGAREA_MSG, SELECT_PROMO_EVENTS_MSG, NO_PDF_AVAILABLE, SELECT_VENDOR_MSG
} from './constants';
import { getSelect } from '../common/ComponentUtilities';
import { SHOPPING_AREA_LIST, VENDOR_LIST_EBOOKS, REUNION_EVENT_LISTING, finelineGetCatalogsUrl } from '../../urlConstants';
import restUtils from '../../utils/restUtils';
import { Loader } from 'semantic-ui-react';
import InternalServerError from '../../views/ErrorPages/500_error_page';

class corporateEbook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csrUser: utilities.checkCsrUser(this.props.multiStoreDetails),
            isLoading: false,
            serverError: false,
            allShoppingAreas: [],
            allvendorDetails: [],
            allPromoEvents: [],
            selectedEbookType: undefined,
            selectedShoppingId: undefined,
            selectedPromoEvt: undefined,
            showErrorMessage: undefined,
            ebookUrl: undefined,
            selectedVendorId: undefined
        };
    }

    getEbookTypeDropdown = () => {
        const selectProps = { className: 'combo dropdownWidth', onChange: this.changeEbookType };
        let ebookOptions = [{ props: { value: '' }, text: '--- Select ---' }];
        E_BOOK_TYPES.forEach(bookType => {
            const eachOpt = { props: { value: bookType, id: bookType, className: 'SelectOption' }, text: bookType }
            ebookOptions = [...ebookOptions, eachOpt]
        });

        return (
            getSelect(selectProps, ebookOptions)
        );
    }

    changeEbookType = (e) => {
        const selectedEbookType = e.target.value;
        this.setState({ selectedEbookType: selectedEbookType, showErrorMessage: undefined, selectedVendorId: undefined })
        if (this.ebookTypeisShopArea(selectedEbookType)) {
            this.setState({ isLoading: true });
            restUtils.getData(`${SHOPPING_AREA_LIST}?storeId=${this.props.storeId}`)
                .then(response => {
                    const { currentshoppingList, upcomingshoppingList, pastshoppingList } = response?.data;
                    const allShoppingAreas = currentshoppingList.concat(upcomingshoppingList).concat(pastshoppingList)
                    this.setState({
                        isLoading: false,
                        serverError: false,
                        allShoppingAreas: allShoppingAreas,
                        allvendorDetails: []
                    })
                }).catch(error => {
                    console.log(error)
                    this.setState({ isLoading: false, serverError: true });
                })
        }
        else if (selectedEbookType === VENDOR_NAME) {
            this.setState({ isLoading: true });
            const reqPayload = {
                    "relayYear": 2020,
                    "page": 1,
                    "resultPerPage": 2000
            }
            restUtils.postData(VENDOR_LIST_EBOOKS, reqPayload)
                .then(response => {
                    const { vendorResponse } = response?.data;
                    this.setState({
                        isLoading: false,
                        serverError: false,
                        allvendorDetails: vendorResponse
                    })
                }).catch(error => {
                    console.log(error)
                    this.setState({ isLoading: false, serverError: true });
                })
            this.setState({ allShoppingAreas: [], allPromoEvents: [], selectedShoppingId: undefined,selectedShoppingMetaId : undefined });
        }
        else {
            this.setState({ allShoppingAreas: [], allvendorDetails: [], allPromoEvents: [], selectedShoppingId: undefined,selectedShoppingMetaId : undefined });
        }
    }

    getAllShoppingAreaDropdown = (allShoppingAreas) => {
        const selectProps = { className: 'combo dropdownWidth', onChange: this.changeShoppingArea };

        let shoppingAreaOptions = [{ props: { value: '' }, text: '--- Select ---' }];
        allShoppingAreas.forEach(sArea => {
            const eachOpt = { props: { value: sArea.shoppingId+','+sArea.shoppingMetatDataId, className: 'SelectOption' }, text: sArea.shoppingName }
            shoppingAreaOptions = [...shoppingAreaOptions, eachOpt]
        });

        return (
            getSelect(selectProps, shoppingAreaOptions)
        );
    }

    getAllVendorNameDropdown = (allvendorDetails) => {
        const selectProps = { className: 'combo dropdownWidth', onChange: this.changeVendorName };

        let vendorDetails = [{ props: { value: '' }, text: '--- Select ---' }];
        allvendorDetails.forEach(vendorInfo => {
            const eachOpt = { props: { value: vendorInfo.vendorId, id: vendorInfo.vendorId, className: 'SelectOption' }, 
                                        text: vendorInfo.vendorId + ' - ' + vendorInfo.vendorName }
            vendorDetails = [...vendorDetails, eachOpt];
        });

        return (
            getSelect(selectProps, vendorDetails)
        );
    }

    changeShoppingArea = (e) => {
        const shoppingIds = e.target.value?.split(',');
        const shoppingId = shoppingIds.length > 0 ? shoppingIds[0] : 1;
        const shoppingMetatDataId = shoppingIds.length > 1 ? shoppingIds[1] : shoppingIds[0];
        this.setState({ shoppingMetatDataId : shoppingMetatDataId, selectedShoppingId: shoppingId,selectedShoppingMetaId : shoppingMetatDataId, showErrorMessage: undefined });
        if (this.showPromoEvents(shoppingMetatDataId)) {
            this.setState({ isLoading: true });
            let url = `${REUNION_EVENT_LISTING}/?shoppingId=${shoppingId}`;
            restUtils.getData(url)
                .then(response => {
                    const { eventLists } = response?.data;
                    this.setState({
                        isLoading: false,
                        serverError: false,
                        allPromoEvents: eventLists,
                        allvendorDetails: []
                    })
                }).catch(error => {
                    console.log(error)
                    this.setState({ isLoading: false, serverError: true });
                })
        }
        else {
            this.setState({ allPromoEvents: [] });
        }
    }

    getAllPromoEvents = (allPromoEvents) => {
        const selectProps = { className: 'combo dropdownWidth', onChange: this.changePromoEvent };

        let promoEventOptions = [{ props: { value: '' }, text: '--- Select ---' }];
        allPromoEvents.forEach(promoEvt => {
            const eachOpt = {
                props: { value: promoEvt.promoNbr, id: promoEvt.promoName, className: 'SelectOption' },
                text: promoEvt.promoNbr + ' - ' + promoEvt.promoName
            }
            promoEventOptions = [...promoEventOptions, eachOpt]
        });

        return (
            getSelect(selectProps, promoEventOptions)
        );
    }

    changePromoEvent = (e) => {
        this.setState({ selectedPromoEvt: e.target.value, showErrorMessage: undefined });
    }

    changeVendorName = (e) => {
        this.setState({ selectedVendorId: e.target.value, showErrorMessage: undefined });
    }

    viewEBook = (ebookPreference) => {
        const { history, location } = this.props;
        const { selectedEbookType, selectedShoppingId,shoppingMetatDataId, selectedPromoEvt, selectedVendorId} = this.state;
        const showPromos = this.showPromoEvents(shoppingMetatDataId);

        if (!selectedEbookType) {
            this.setState({ showErrorMessage: SELECT_EBOOK_TYPE_MSG });
            return;
        }
        else if (this.ebookTypeisShopArea(selectedEbookType)) {
            if (!selectedShoppingId) {
                this.setState({ showErrorMessage: SELECT_SHOPPINGAREA_MSG });
                return;
            }
            else if (showPromos && !selectedPromoEvt) {
                this.setState({ showErrorMessage: SELECT_PROMO_EVENTS_MSG });
                return;
            }
        }
        else if(this.ebookTypeisVendor(selectedEbookType)){
            if (!selectedVendorId) {
                this.setState({ showErrorMessage: SELECT_VENDOR_MSG });
                return;
            }
        }

        let requestObj = {};
        requestObj = { ...requestObj, ...{ CatType: 2, CatSubType: selectedShoppingId || selectedVendorId} };
        if (showPromos) {
            requestObj = { ...requestObj, ...{ PromoLevel: "y", PromoNbr: selectedPromoEvt } };
        }
        else {
            requestObj = { ...requestObj, ...{ PromoLevel: "n" } };
        }

        this.setState({ isLoading: true });
        restUtils.postData(finelineGetCatalogsUrl, requestObj)
            .then(response => {
                const ebookUrl = response?.data?.PDFLocation;
                this.setState({
                    isLoading: false,
                    serverError: false,
                    ebookUrl: ebookUrl
                });
                if(ebookUrl){
                    if (ebookPreference === 'view') {
                        history.push(`${location.pathname}/flipbook-viewer?pdf=${ebookUrl}`, {
                            url: ebookUrl
                        });
                    }
                    else{
                        window.open(ebookUrl, "_blank")
                    }
                }else{
                    this.setState({ showErrorMessage: NO_PDF_AVAILABLE });
                    return; 
                }
            }).catch(error => {
                console.log(error)
                this.setState({ isLoading: false, serverError: true });
            });
    };

    getEbookOptions = (ebookOptionName, dropdownVals) => {
        return (
            <div className='ebookDropdowns'>
                <span className='ebookTextNames'>{ebookOptionName}*</span>
                <span>{dropdownVals}</span>
            </div>
        )
    };

    ebookTypeisShopArea = (selectedEbookType) => {
        return selectedEbookType && selectedEbookType === SHOPPING_AREA
    }

    ebookTypeisVendor = (selectedEbookType) => {
        return selectedEbookType && selectedEbookType === VENDOR_NAME
    }

    showPromoEvents = (selectedShoppingId) => {
        return selectedShoppingId && SHOW_PROMOS.includes(selectedShoppingId)
    }

    render() {
        const { serverError, isLoading, csrUser, allShoppingAreas, allvendorDetails, allPromoEvents,
            selectedEbookType, selectedShoppingId,shoppingMetatDataId, showErrorMessage, ebookUrl } = this.state;
        const ebookTypeisShoppingArea = this.ebookTypeisShopArea(selectedEbookType);
        const ebookTypeisVendorName = this.ebookTypeisVendor(selectedEbookType);
        const showPromoDropdown = ebookTypeisShoppingArea && this.showPromoEvents(shoppingMetatDataId);
        return (
            <div className='page-wrap'>
                {serverError ? <InternalServerError /> : (
                    <>
                        {isLoading && (
                            <div>
                                <Loader active />
                            </div>
                        )}

                        {csrUser ?
                            <>
                                <div className='page-header'>{CORPORATE_EBOOK}</div>
                                <div className='pageLayout'>
                                    <div><span className='error'>{showErrorMessage}</span></div>
                                    <span className='ebookTextNames ebookType'>E-Book Type:*</span>
                                    <span>{this.getEbookTypeDropdown()}</span>

                                    {ebookTypeisShoppingArea &&
                                        this.getEbookOptions('Shopping Area:', allShoppingAreas?.length > 0 ?
                                            this.getAllShoppingAreaDropdown(allShoppingAreas) : NO_SHOPPING_AREAS)
                                    }

                                    {ebookTypeisVendorName &&
                                        this.getEbookOptions('Vendor Name:', allvendorDetails?.length > 0 ?
                                            this.getAllVendorNameDropdown(allvendorDetails) : NO_VENDOR_NAMES)
                                    }

                                    {showPromoDropdown &&
                                        this.getEbookOptions('Promo Event:', allPromoEvents?.length > 0 ?
                                            this.getAllPromoEvents(allPromoEvents) : NO_PROMOS_EVENTS)
                                    }

                                    <div className='ebookBtns'>
                                        <p className='viewRequiredFields'>* Required Field </p>
                                        <button className='ui red button' onClick={() => this.viewEBook('view')}>View</button>
                                        <button className='ui red button' onClick={() => this.viewEBook('download')} >Download PDF</button>
                                    </div>
                                </div>
                            </>
                            :
                            <div className='notAccessibleMsg'>
                                <h1>{NO_ACCESS_MSG}</h1>
                            </div>
                        }
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        storeId: state.SessionReducer?.storeId,
        multiStoreDetails: state.SessionReducer?.multiStoreDetails
    };
};

export default connect(mapStateToProps, null)(corporateEbook);