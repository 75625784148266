import React from 'react';
export const ReunionCard = ({isReunion,promo_nbr,showVendors,promo_name,showItemList,isDiscountStyle,promo_end_date}) =>{
    return (
            <div className = "reunion_card cartd_explorer">
                <span className={isDiscountStyle?'discount_card_title' :'reunionCardTitle'}
                    data-promonbr = {promo_nbr}
                    >
                        {promo_nbr} - {promo_name}
                </span>
                {
                   
                    <div className='reunion_card_footer'>
                        <span className = 'link-text'
                                data-promonbr = {promo_nbr}
                                data-orderdeadline ={promo_end_date}
                                onClick = {showItemList}> All Items </span>
                        <span 
                                className='link-text' 
                                data-promonbr = {promo_nbr}
                                onClick={showVendors}> Vendors </span>
                    </div>
                }
            </div>
    );
}