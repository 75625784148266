import React from 'react';
import { getSelect } from './ComponentUtilities';
import { Pagination } from 'semantic-ui-react';

export const getSortByDirection = (changeSortDirection,values,defaultDir,direction) => {
  let selectProps = { className: 'sort-order combo', onChange: changeSortDirection, value: defaultDir?defaultDir:'desc' };
  selectProps = direction ? {...selectProps , value : direction} : selectProps;
  const options = [
    { props: { value: values?values[0]:'asc', id: 'asc' }, text: 'Ascending' },
    { props: { value: values?values[1]:'desc', id: 'desc' }, text: 'Descending' }
  ]

  return (
    getSelect(selectProps, options)
  );
}

export const showItemCount = (pageSize, onPageSizeChange) => {
  const selectProps = { value: pageSize, className: 'select-range', onChange: onPageSizeChange };

  const options = [
    { props: { value: 50, className: 'SelectOption' }, text: 50 },
    { props: { value: 100, className: 'SelectOption' }, text: 100 },
  ];

  return (
    getSelect(selectProps, options)
  );

}

export const getExcelUploadField = (fieldName, id, value, onChange, labelClass, fieldClass, downloadTemplate) => {
  return (
    <>
      <div>
        <input type='file' id={id} name={id} onChange={onChange} onClick={(event)=> { event.target.value = null}} className={fieldClass}/>
        <input type='text' readOnly className='choose-file-label'value={value}></input>
        <label htmlFor='upload-file' className={labelClass}>
          {fieldName}
        </label>
      </div>
      <div className='template-link'>
      <span className='op-clear-label' onClick={downloadTemplate}>
        Download Template
      </span>
    </div>
    </>
  );
};

export const getImageUploadField = (fieldName, id, value, onChange, labelClass, fieldClass) => {
  return (
    <>
      <div style={{width:" 20%",
                direction: "rtl"}}>
      <label>Image (size - 450x450)</label>
      </div>
      <div style={{width:"100%"}}>
        <input type='file' accept="image/png, image/jpg, image/jpeg" id={id} name={id} onChange={onChange} onClick={(event)=> { event.target.value = null}} className={fieldClass}/>
        <input style={{width:"65%", marginLeft:"0.25rem"}} type='text' readOnly value={value}></input>
        <label style={{marginLeft:"0.5rem"}} htmlFor='upload-file' className={labelClass}>
          {fieldName}
        </label>
        </div>
    </>
  );
};

export const getPagination = (boundaryRange, currentPage, totalPages, onPageChange, ellipsisItem, siblingRange) => {
  currentPage = currentPage?parseInt(currentPage):0;
  totalPages = totalPages?parseInt(totalPages):0;
  return (
      <Pagination
      className='pagination pagination-padding-mobile'
        boundaryRange={boundaryRange}
        activePage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        ellipsisItem={ellipsisItem}
        siblingRange={siblingRange}
        firstItem={currentPage === 1 ?null:undefined}
        lastItem={currentPage === totalPages ? null:undefined}
        prevItem={currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
        nextItem={currentPage === totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
      />
  );
}