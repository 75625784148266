import restUtils from "../../../utils/restUtils";
import { deleteFavoriteVendors, getFavoriteVendors, addFavoriteVendors, getVendorIdfromName } from "../../../urlConstants";

export const ADD_VENDOR_TO_FAVORITES = 'ADD_VENDOR_TO_FAVORITES';
export const REMOVE_VENDOR_FROM_FAVORITES = 'REMOVE_VENDOR_FROM_FAVORITES';
export const GET_FAVORITE_VENDORS = 'GET_FAVORITE_VENDORS';
export const FETCHING_FAV_VENDORS = 'FETCHING_FAV_VENDORS';

export const addVendorToFavorites = (vendorName, vendorId) =>{
  return (dispatch,getState)=>{
    restUtils.getData(`${getVendorIdfromName}${vendorName}`)
    .then(response=>{
      if(!response.data.length || response.data.length===0){
        return;
      }
      const filterVendorData = response.data.filter((v) => v.ID === vendorId);
      const vendorIdInfo = filterVendorData.length > 0 ? filterVendorData[0].ID : response.data[0].ID;
      const postObj = {
        userId:getState().SessionReducer.UserId,
        vendorId:[vendorIdInfo]
      }
      restUtils.postData(addFavoriteVendors,postObj)
        .then(response=>{
          const responseData = response.data.favoriteVendorList;
          const filterResponse = responseData.filter((v) => v.VendorId.toString() === vendorIdInfo);
          const favoriteData = filterResponse.length > 0 ? filterResponse[0] : '';
          dispatch({
            type:ADD_VENDOR_TO_FAVORITES,
            payload: favoriteData ? favoriteData : vendorIdInfo
          })
        }).catch(error=>{
          console.log(error);
        })
      }).catch(error=>{
        console.log(error);
      })
  }
}

export const removeVendorFromFavorites = (vendorName, vendorId) =>{
  return (dispatch,getState) =>{
    dispatch({
      type:FETCHING_FAV_VENDORS
    })
    restUtils.getData(`${getVendorIdfromName}${vendorName}`)
      .then(response=>{
        if(!response.data.length || response.data.length === 0){
          return;
        }
        const filterVendorData = response.data.filter((v) => v.ID === vendorId);
        const vendorIdInfo = filterVendorData.length > 0 ? filterVendorData[0].ID : response.data[0].ID;
        const url = `${deleteFavoriteVendors}?userId=${getState().SessionReducer.UserId}&vendorId=${vendorIdInfo}`;
        restUtils.deleteDataWithoutToken(url)
        .then(response=>{
          dispatch({
            type:REMOVE_VENDOR_FROM_FAVORITES,
            payload:vendorIdInfo
          })
        }).catch(error=>{
          console.log(error)
        })
      }).catch(error=>{
        console.log(error)
      })
  }
}

export const getAllFavoriteVendors = () =>{
  return (dispatch,getState) =>{
    dispatch({
      type:FETCHING_FAV_VENDORS
    })
    restUtils.getData(`${getFavoriteVendors}?userId=${getState().SessionReducer.UserId}&searchBy=`)
      .then(response=>{
        dispatch({
          type:GET_FAVORITE_VENDORS,
          payload:response.data.favoriteVendorList
        })
      })
  }
}