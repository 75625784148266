import React from "react";
import { Tab, Container, Header } from "semantic-ui-react";
import { connect } from "react-redux";

import {
  fetchDepartments,
  fetchDepartmentClasses,
  fetchDepartmentSubclasses,
  fetchDepartmentClassesSuccess,
  addCatalogAd,
  removeCatalogAd,
  updateCatalogAd,
} from "../actions";
import AddAdForm from "./AddAdForm";
import SavedAds from "./SavedAds";
import "./index.css";

class FinelineCatalogAdConfiguration extends React.Component {
  state = {
    activeTabIndex: 0,
    editAd: null,
  };

  panes = [
    {
      menuItem: {
        content: "New Ad",
        className: "tab_name_ad",
        id: "tab_id",
      },
      render: () => (
        <Tab.Pane attached={false} className="pane_ad_configuration">
          <AddAdForm
            departments={this.props.departments}
            classes={this.props.classes}
            subClasses={this.props.subClasses}
            getClasses={this.props.getDepartmentClasses}
            getSubClasses={this.props.getDepartmentSubclasses}
            addAd={this.props.addCatalogAd}
            editAd={this.state.editAd}
            updateAd={this.props.updateCatalogAd}
            clearEdit={this.clearEditHandler}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content: "Saved Ads",
        className: "tab_name_ad",
        id: "tab_id",
      },
      render: () => (
        <Tab.Pane attached={false} className="pane_ad_configuration">
          <SavedAds
            ads={this.props.ads}
            removeAd={this.removeAdHandler}
            editAd={this.editAdHandler}
          />
        </Tab.Pane>
      ),
    },
  ];

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeTabIndex: activeIndex });
  }

  clearEditHandler = () => {
    this.setState({ editAd: null });
  }

  editAdHandler = (ad) => () => {
    this.setState({
      editAd: ad,
      activeTabIndex: 0,
    });
  };

  removeAdHandler = (ad) => () => {
    const { removeCatalogAd } = this.props;
    removeCatalogAd(ad);
  };

  componentDidMount() {
    const { getDepartments } = this.props;
    getDepartments();
  }

  render() {
    const { activeTabIndex } = this.state;

    return (
      <div id="container">
        <Header as="h2">Fineline Catalog Ad Configuration</Header>
        <Container className="pt-4">
          <Tab
            activeIndex={activeTabIndex}
            menu={{
              fluid: true,
              vertical: true,
              className: "tab_menu_ad_config",
            }}
            panes={this.panes}
            onTabChange={this.handleTabChange}
          />
        </Container>
      </div>
    );
  }
}

FinelineCatalogAdConfiguration.defaultProps = {
  catalog: [],
};

const mapStateToProps = (state) => {
  const { finelineCatalog } = state;
  const departmentsState = finelineCatalog.departments;
  const adsState = finelineCatalog.catalogAds;

  return {
    departments: departmentsState.departments,
    isLoading: departmentsState.loading,
    departmentsError: departmentsState.error,
    classes: departmentsState.classes,
    subClasses: departmentsState.subClasses,
    ads: adsState.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDepartments: () => dispatch(fetchDepartments()),
  getDepartmentClasses: (depId) => dispatch(fetchDepartmentClasses(depId)),
  getDepartmentSubclasses: (depId, classId) =>
    dispatch(fetchDepartmentSubclasses(depId, classId)),
  dispatchSuccessClasses: (classes) =>
    dispatch(fetchDepartmentClassesSuccess(classes)),
  addCatalogAd: (ad) => dispatch(addCatalogAd(ad)),
  removeCatalogAd: (ad) => dispatch(removeCatalogAd(ad)),
  updateCatalogAd: (ad) => dispatch(updateCatalogAd(ad)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinelineCatalogAdConfiguration);
