import { GET_REGION_DATA } from "./actions"

const initState = {}

const DomReducer = (state=initState,{type,payload}) =>{
    switch(type){
        case GET_REGION_DATA:
            return {...state,...payload};
        default : 
            return state;
    }
}

export default DomReducer;