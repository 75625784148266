import React from 'react'
import restUtils from '../../utils/restUtils';
import { Loader } from 'semantic-ui-react'
import InternalServerError from "../../views/ErrorPages/500_error_page";
import {competitorsDataUrl} from '../../urlConstants';

class CompetitorPrices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      competitors: [],
      isLoading: true,
      serverError: false
    }
  }

  componentDidMount() {
    this.getCompetitorsData();
  }

  getCompetitorsData = () => {
    restUtils.getData(competitorsDataUrl + this.props.item_upc)
      .then(response => {
        this.setState({ competitors: response.data, isLoading: false, serverError: false })
      })
      .catch(error => { console.log(error); this.setState({ isLoading: false, serverError: true }); });
  }

  render() {
    const competitorsData = this.state.competitors;
    return (
      <div>
        {this.state.isLoading &&
          <div>
            <Loader active />
          </div>
        }

        {this.state.serverError ? <InternalServerError /> :
          <div className="competitorDetails">
            {competitorsData.length <= 1 ?
              <p className="no_competitors">{this.state.isLoading ? "" : "No competitor SRP data exists for this item"}</p>
              :
              <table className="table-scroll small-first-col" id="competitorTable">
                <thead>
                  <tr>
                    <th>Competitor Name</th>
                    <th>Last Price Change</th>
                    <th>Price</th>
                    <th>Promo</th>
                  </tr>
                </thead>
                <tbody className="competitorData">
                  {competitorsData.map(competitor => {
                    return (
                      <tr>
                        <td>{competitor.competitor_name}</td>
                        <td>{competitor.updated_at ? new Date(competitor.updated_at).toLocaleDateString() : '-'}</td>
                        <td>${competitor.item_srp}</td>
                        <td>{competitor.item_promo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          </div>
        }
      </div>
    )
  }
}

export default CompetitorPrices;