import React from 'react';

const RefineFilterItemOptions = ({
  optionTypes,
  item,
  filterTitle,
  handleChange,
  selectedOption,
  filterName
}) => {
  return (
    <div className={`ui checkbox ${optionTypes} filter-cb-wrap`}>
      <input
        type={optionTypes}
        className='filter-checkbox'
        key={item.id}
        data-filter-head={filterTitle}
        data-filter-value={item.value}
        name={filterName}
        onChange={ handleChange && handleChange(item) }
        checked={!!selectedOption}
      />
      <label>{item.value}</label>
    </div>
  );
}

export default RefineFilterItemOptions;