import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import renderImages from "../common/RenderImages";
import { MEMBER_VIEW } from "../../components/NavBar/menuConstants";
import utilities from "../../utils/utilities";
import { MaskPrice } from "./MaskPrice";

const link = "/product/";

const FinelineCatalogProductGridItem = ({ product, img_style, filters }) => {
  const renderImage = () => {
    return (
      <div>
        <div className="card_img" style={{ marginTop: "0.5rem" }}>
          <Link to={{ pathname: link + product.Item_Number }} className='card_list_img'>
            {renderImages.getImage({
              src: product.Image_File_Name
                ? product.Image_File_Name
                : "https://images.truevalue.com/getimage.asp?id=" +
                product.Item_Number +
                "&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2",
              style: { display: img_style },
              alt: product.Image_File_Name,
              className: "ui image image_lisitng_grid",
            })}
          </Link>
        </div>
        <div className="gridButtonview" />
      </div>
    );
  };

  const renderTitle = () => (
    <>
      <p style={{ marginBottom: "0" }} className="t-center vendor-span">
        <Link
          to={{
            pathname: product.Vendor_Name
              ? "/vendor/" +
              utilities.replaceAll(product.Vendor_Name, "/", "%2F")
              : "#",
            state: { vendorId: product.Vendor_Id },
          }}
        >
          {product.Vendor_Name}
        </Link>
      </p>
      <p className="t-center" style={{ fontFamily: "HelveticaNeueBold" }}>
        <Link to={{ pathname: link + product.Item_Number }}>
          {product.Product_Name || product.Product_Title}
        </Link>
      </p>
    </>
  );

  return (
    <div className="column">
      <div
        className="ui segment card_item"
        id="grid_segment"
        style={{ height: "calc(100% - 8px)" }}
      >
        {renderImage()}
        {renderTitle()}
        <div className="card_wrap" style={{ paddingBottom: "0.2rem" }}>
          <div className="card_fields">
            <Grid columns={2}>
              <Grid.Column>
                <span className="card_name">ITEM#:</span>
              </Grid.Column>
              <Grid.Column>
                <b className="column First_level_details">
                  {product.Item_Number}
                </b>
              </Grid.Column>
              <Grid.Column>
                <span className="card_name">MODEL#:</span>
              </Grid.Column>
              <Grid.Column>
                <b className="First_level_details">{product.Model}</b>
              </Grid.Column>
              <Grid.Column>
                <span className="card_name">UPC:</span>
              </Grid.Column>
              <Grid.Column>
                <b className="First_level_details">{product.UpcNum}</b>
              </Grid.Column>
              <Grid.Column>
                <span className="card_name">MEMBER COST:</span>
              </Grid.Column>
              <Grid.Column>
                {filters.memberCost !== "Hide" && (
                  <MaskPrice
                    viewType={
                      filters.memberCost === "Show" ? MEMBER_VIEW : null
                    }
                    field="Member Cost"
                    fieldVal={product.Member_Cost}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                <span className="card_name">SUGG. RETAIL:</span>
              </Grid.Column>
              <Grid.Column>
                {filters.suggestedRetail !== "Hide" && (
                  <MaskPrice
                    viewType={
                      filters.suggestedRetail === "Show" ? MEMBER_VIEW : null
                    }
                    field="Suggested Retail"
                    fieldVal={product.SuggestedRetail}
                  />
                )}
              </Grid.Column>
              <Grid.Column>
                <span className="card_name">PACK:</span>
              </Grid.Column>
              <Grid.Column>
                <b className="First_level_details">
                  {product.S_Pack_Type}
                  {product.S_Pack_Qty}
                </b>
              </Grid.Column>
            </Grid>
          </div>
        </div>
        <div className="restricted-product t-center">
          {product.Restricted ? "Restricted Product" : "    "}
        </div>
      </div>
    </div>
  );
};

export default FinelineCatalogProductGridItem;
