import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import history from '../../history';
import renderImages from '../../views/common/RenderImages';
import search_img from '../../views/images/SearchIcon.png';
import utilities from '../../utils/utilities';
import { connect } from 'react-redux';
import {configure} from 'react-hotkeys';
 
 configure({
   ignoreTags: ['input', 'select', 'textarea'],
   ignoreEventsCondition: function() {}
 });
 
class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      searchFocus: this.props.searchFocus,
      searchType : (this.props.multiStoreDetails && ((this.props.multiStoreDetails.viewCat && !this.props.multiStoreDetails.viewPOG) || this.props.multiStoreDetails.access!==1)) ? 
        window.location.pathname.split("/")[1] === 'contentSearch' ? 'content' : 'product' : 'content'
    };
    this.nameInput = React.createRef();
  }

  componentDidMount() {
    utilities.isDesktop() && this.focusSearchboxBasedonScreen();
   
  }

  componentDidUpdate() {
    utilities.isDesktop() && this.focusSearchboxBasedonScreen();
  }

  focusSearchboxBasedonScreen = () => {
    const getprefer = this.props.getprefer;
    const browerPath = window.location.pathname.split("/");
    if(browerPath[0] === "" && browerPath[1] === "product"){
      if(getprefer && (getprefer.cursorPlacement === '' || getprefer.cursorPlacement === 'S'))

        utilities.isDesktop() &&this.nameInput.current.focus();
    }else{
      if(window.location.pathname.split("/")[1] !== 'Scan') {
        this.nameInput.current.focus();
      }

    }
  }

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.search();
      return;
    }
  };

  changeSearchValue = e => {
    this.setState({ searchValue: e.target.value });
  };

  search = (e, skey, sValue) => {
    let value = sValue ? sValue : this.state.searchValue;
    if(value.trim()==='') {
      utilities.showToast("Please enter something to search");
      this.nameInput.current.focus();
      return;
    }
    const strValue = value
    value = encodeURIComponent(value);
    value = utilities.replaceAll(value, '%', '%25')
    /*To do can use  router of just search without any key to search across entire Table,
    for now using description to search across entire table*/
    if(this.state.searchType === 'content') {
      history.push({
        pathname: '/contentSearch/'+ value,
        state: {
          heading: strValue
        }
      })
    }else {
      history.push('/search/Description/' + value);
    } 
    this.setState({
      searchValue:''
    })
  };
  handleSelect = (e) =>{
    this.setState({
      searchType: e.target.value
    })
  }
  render() {
    return (
      <>
       <i className='caret down icon' />
      <select onChange={this.handleSelect} value={this.state.searchType}>
        {(this.props.multiStoreDetails && ((this.props.multiStoreDetails.viewCat && !this.props.multiStoreDetails.viewPOG) || this.props.multiStoreDetails.access!==1)) &&
        <option value='product'>Product</option>}
        <option value='content'>Content</option>
      </select>
        <input
          type='text'
          autoFocus = {utilities.isDesktop() && window.location.pathname.split("/")[1] !== 'Scan'}
          onMouseEnter = {()=>this.nameInput.current.focus()}
          id='searchbox'
          placeholder= {this.state.searchType === 'content' ? 'Search Content': 'Search Product'}
          className='search-box'
          onKeyDown={this.handleEnterKey}
          value={this.state.searchValue}
          onChange={this.changeSearchValue}
          ref={this.nameInput} 
          key = 'focusInput'
        />
         {renderImages.getImage({ src: search_img, className: 'searchIcon_address cursor_p', onClick: this.search })}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    getprefer: state.preference.listData,
    searchFocus: state.navbar.searchFocus,
    multiStoreDetails : state.SessionReducer.multiStoreDetails
  };
};
export default connect(mapStateToProps, null)(CategoryList);

