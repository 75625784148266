import {
  FETCH_DEPARTMENTS,
  FETCH_DEPARTMENTS_FAIL,
  FETCH_DEPARTMENTS_SUCCESS,

  FETCH_DEPARTMENT_CLASSES,
  FETCH_DEPARTMENT_CLASSES_SUCCESS,
  FETCH_DEPARTMENT_CLASSES_FAIL,

  FETCH_DEPARTMENT_SUBCLASSES_FAIL,
  FETCH_DEPARTMENT_SUBCLASSES_SUCCESS,
  FETCH_DEPARTMENT_SUBCLASSES,
  REMOVE_DEPARTMENT_FROM_LIST,
  ADD_DEPARTMENT_TO_LIST,
} from '../actionTypes';

const departmentsInitialState = {
  loading: {
    departments: false,
    classes: false,
    subClasses: false
  },
  departments: [],
  error: null,
  classes: [],
  subClasses: [],
};

const departmentsReducer = (state = departmentsInitialState, action) => {
  switch(action.type) {
    case FETCH_DEPARTMENTS:
      return {
        ...state,
        loading: { departments: true },
        error: null,
      }
    case FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        loading: { departments: false },
        error: null,
        departments: action.payload.departments
      }
    case FETCH_DEPARTMENTS_FAIL:
      return {
        ...state,
        error: {
          type: 'department',
          error: action.payload,
        },
        loading: { departments: false }
      }
    case FETCH_DEPARTMENT_CLASSES:
      return {
        ...state,
        loading: { classes: true },
        error: null,
        subClasses: [],
        classes: [],
      }
    case FETCH_DEPARTMENT_CLASSES_SUCCESS:
      return {
        ...state,
        loading: { classes: false },
        classes: action.payload.classes,
      }
    case FETCH_DEPARTMENT_CLASSES_FAIL:
      return {
        ...state,
        loading: { classes: false },
        error: {
          type: 'class',
          error: action.payload
        }
      }
    case FETCH_DEPARTMENT_SUBCLASSES:
      return {
        ...state,
        loading: { subClasses: true },
        error: null,
        subClasses: [],
      }
    case FETCH_DEPARTMENT_SUBCLASSES_SUCCESS:
      return {
        ...state,
        loading: { subClasses: false },
        subClasses: action.payload.subClasses
      };
    case FETCH_DEPARTMENT_SUBCLASSES_FAIL:
      return {
        ...state,
        loading: { subClasses: false },
        error: {
          type: 'subclass',
          error: action.payload
        }
      }
    case REMOVE_DEPARTMENT_FROM_LIST:
      return {
        ...state,
        departments: state.departments.filter((d) => d.name !== action.payload.name )
      }
    case ADD_DEPARTMENT_TO_LIST:
      return {
        ...state,
        departments: [...state.departments, action.payload]
      }
    default:
      return state;
  };
}

export default departmentsReducer;
