import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Segment, Loader } from 'semantic-ui-react'
import restUtils from '../../utils/restUtils';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import './styles.css'
import search_img from '../images/Search.svg';
import renderImages from '../common/RenderImages';
import RetailAssortmentLandingList from './RetailAssortmentLandingList';
import RetailAssortmentLandingGrid from './RetailAssortmentLandingGrid';
import { FLASH_SALES_DATA, FLASH_SALES_URL } from '../../urlConstants';
import utilities from '../../utils/utilities';
import _ from 'lodash';
import { isArray } from 'util';
import Filter from '../common/Filter';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import { FLASH_SALES } from '../common/constants';

class RetailAssortments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assortments: [],
      displayType: (this.props.getprefer && this.props.getprefer.prdctListlayoutFormat && this.props.getprefer.prdctListlayoutFormat !== 'L') ? 'grid' : 'list',
      isNoProducts: false,
      serverError: false,
      loading: false,
      seleDeptName: '',
      searchType: 'itemNumber',
      filters: [],
      clearFilters: false,
      appliedFilters: {},
      endDate: '',
      searchNumber: '',
      backPath: '',
      isFlashsales: this.props.location && this.props.location.pathname === '/flashSales' ? true : false,
      flashSalesData: false,
      showFilters: (this.props.getprefer && this.props.getprefer.filterView) ? this.props.getprefer.filterView === 'E' ? true : false : window.innerWidth > 768 ? true : false,
    }
    this.sortParam = '';
    this.sortDirection = '';
    this.searchParam = "itemNumber";
    this.searchNumber = '';
    this.handleSort = this.handleSort.bind(this);
    this.filterString = {};
    this.pathname = '';
    this.appliedFilters = {};
    this.patterns = {
      itemNumber: '[0-9]+',
      assortNumber: '[a-zA-Z0-9-]+',
      alternateSKU: '[0-9]+',
    };
    this.scrollPosition = 0;
  }

  componentDidMount() {
    this.checkFlashSalesExists();
  }

  checkFlashSalesExists() {
    this.setState({ loading: true });
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    const falshsalesurl = FLASH_SALES_URL + `?rdc=${this.props.rdc}`;
    restUtils.getData(falshsalesurl)
      .then(response => {
        const flashSalesData = response.data;
        if (flashSalesData === 1) {
          this.setState({ flashSalesData: true, loading: false });
          if (queryParam[1]) {
            this.getUrlFilters(queryParam);
          }
          else {
            this.getAssortmentData();
          }
        } else {
          this.setState({ flashSalesData: false, isNoProducts: true, loading: false });
          return;
        }
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      deptName,
      searchType,
      searchNumber
    } = nextProps.match ? nextProps.match.params : this.state;
    this.filterString = {};
    this.appliedFilters = {};
    this.searchParam = "itemNumber";
    this.searchNumber = '';
    this.sortParam = '';
    this.sortDirection = '';
    this.setState({
      appliedFilters: {},
      clearFilters: true,
      searchNumber: '',
    });
    if (
      this.state.seleDeptName !== deptName ||
      this.state.searchType !== searchType ||
      this.state.searchNumber !== searchNumber
    ) {
      this.pathname = nextProps.location && nextProps.location.pathname;
      this.getAssortmentData(nextProps);
    }
  }

  handleSort(e) {
    this.setState({ displayType: e });
  }
  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  onFilterChange = e => {
    const { validity = {}, value } = e.target;
    this.scrollPosition = window.pageYOffset;
    e.target.pattern = this.patterns[this.searchParam];
    if (!validity.valid) {
      e.preventDefault();
      return;
    }
    this.setState({
      searchNumber: value
    });
  };
  filterCallback = (isAdd, filterKey, filterValue) => {
    let appliedFilters = this.state.appliedFilters;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
    } else if (keyExists) {
      appliedFilters[filterKey] = isArray(appliedFilters[filterKey]) ? appliedFilters[filterKey] : appliedFilters[filterKey].split(",")
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        value.push(filterValue);
      }
      if (!value.length && keyExists) delete appliedFilters[filterKey];
      else appliedFilters[filterKey] = value;
    }
    this.appliedFilters = appliedFilters;
    if (!appliedFilters.hasOwnProperty('Department')) {
      appliedFilters = { ...appliedFilters, ['Department']: [FLASH_SALES] };
    }
    this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
    this.setState({
      appliedFilters,
      clearFilters: false
    });
    this.getFilteredData();
  };

  getUrlFilters = (queryParam) => {
    let deptName = FLASH_SALES;
    let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
    if (searchParam !== '') {
      let search = searchParam.split('=');
      this.searchParam = search[0];
      this.searchNumber = search[1].split('&')[0];
    }
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' :
      queryParam[1].includes('SortType=desc') ? 'desc' : '';
    this.scrollPosition = queryParam[1].includes('scrollPosition=') ? queryParam[1].split('scrollPosition=')[1].split('&')[0] : 0;
    this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    this.appliedFilters = utilities.getFilterArray(this.filterString);
    if (!this.appliedFilters.hasOwnProperty('Department') && deptName) {
      this.appliedFilters = { ...this.appliedFilters, ['Department']: [deptName] };
    }
    this.filterString = utilities.getQueryJsonAssortment(this.appliedFilters);
    this.setState({
      seleDeptName: deptName,
      appliedFilters: this.appliedFilters,
      searchNumber: this.searchNumber,
      backPath: this.pathname
    }, () => {
      this.getFilteredData();
    });
  }

  setBackButtonPath = () => {
    let pathName = '';
    if (this.searchNumber.length > 0) {
      pathName = pathName + '&searchBy=' + this.searchParam + '=' + this.searchNumber;
    }
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam=' + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType=' + this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    pathName = this.scrollPosition > 0 ? pathName + '&scrollPosition=' + this.scrollPosition : pathName;
    if (!_.isEmpty(this.appliedFilters)) {
      pathName = pathName + '&filterString=' + utilities.getQueryStrForFilterString(this.appliedFilters);
    }
    let pushUrl = '';
    if (pathName !== '') {
      pushUrl = this.pathname + '?' + pathName;
      window.history.pushState(window.history.state, "", pushUrl);
    } else {
      pushUrl = this.pathname;
    }
    this.setState({
      backPath: pushUrl
    })
  }

  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }

  getFilteredData = () => {
    if (this.state.searchType === 'itemNumber' && parseInt(this.searchNumber) === 0) {
      utilities.showToast('Invalid item number');
      return;
    }
    let searchArr = {}
    const url = `${FLASH_SALES_DATA}`;
    if (this.filterString.filterBy === undefined || this.filterString.filterBy.length === 0) {
      const queryJson = [];
      const deptArr = {
        "name": "Department",
        "valueList": [FLASH_SALES]
      };
      queryJson.push(deptArr)
      this.filterString = { filterBy: queryJson };
    }
    if (this.searchNumber) {
      searchArr = {
        name: this.searchParam,
        valueList: this.searchNumber.split(",")
      }
    }
    const sortArr = {
      sortParam: this.sortParam.length > 0 ? this.sortParam : 'saving',
      sortType: this.sortDirection.length > 0 ? this.sortDirection : 'asc'
    };

    let requestPayLoad = {
      memberNo: this.props.storeId,
      ...this.filterString,
      searchBy: searchArr,
      sortBy: sortArr,
      rdc: this.props.rdc,
      aliasSKURetailerGrpID:this.props.aliasSKURetailerGrpID
    }
    const fitlerUrl = url + `/Filter`
    this.setState({ loading: true });
    restUtils
      .postData(fitlerUrl, requestPayLoad)
      .then(response => {
        const data = response.data.departmentList[0];
        document.getElementById('search_RetailAssortment').value = '';
        this.setState({
          isNoProducts: !data || data.assortmentsList.length === 0,
          loading: false,
          serverError: false,
          assortments: data.assortmentsList,
          endDate: response.data.endDate,
          filters: data.filters
        });
          this.setState({showFilters:true});
          for (let key in this.appliedFilters) {
            document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
          }
          this.setState({showFilters:this.props.getprefer && this.props.getprefer.filterView ? this.props.getprefer.filterView === 'E'
        ? true : false : window.innerWidth > 768 ? true : false});
          window.scrollTo(0, this.scrollPosition);
          this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }
  changeSortParam = e => {
    this.sortParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  };

  changeSortDirection = e => {
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  };

  changeSearchParam = e => {
    this.searchParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.searchNumber='';
    this.setState({ searchType: this.searchParam, searchNumber: '' });
    this.setBackButtonPath();
  };

  getAssortmentData = (newProps) => {
    let deptName = FLASH_SALES;
    this.setState({
      loading: true,
      seleDeptName: deptName
    });
    deptName = encodeURIComponent(deptName);
    const { storeId, rdc } = this.props;
    const url = `${FLASH_SALES_DATA}`;
    const sortParam = this.sortParam.length > 0 ? this.sortParam : 'saving';
    const sortType = this.sortDirection.length > 0 ? this.sortDirection : 'asc';
    restUtils.getData(
      url + `/FlashSaleList?departmentID=${deptName}&memberNo=${storeId}&sortParam=${sortParam}&sortType=${sortType}&rdc=${rdc}&aliasSKURetailerGrpID=${this.props.aliasSKURetailerGrpID}`
    ).then(response => {
      const data = response.data.departmentList[0];
      this.setState({
        assortments: data ? data.assortmentsList : [],
        endDate: response.data ? response.data.endDate : '',
        filters: data ? data.filters : [],
        loading: false,
        serverError: false,
        isNoProducts: !data || !data.assortmentsList || data.assortmentsList.length === 0
      }, () => {
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      });
    })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  searchItems = (e) => {
    this.searchNumber = this.state.searchNumber;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  }

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  render() {
    let AssortmentLinkPage = '/flashSales';
    const breadcrumbPath = [{ link: '/home', text: 'HOME' },
    { link: AssortmentLinkPage, text: FLASH_SALES }];
    return (
      <div >
        {this.state.loading &&
          <div>
            <Loader active />
          </div>
        }
        {this.state.serverError ? <InternalServerError /> : <>
          <Segment id="retail_assortments" >
            <div className='page-header'>Flash Sales</div>
            <BreadcrumbComp path={breadcrumbPath} /><br /><br />
            <div>
              <div className='ui input' id='input_searchReatil'>
                <span>{window.innerWidth < 768 ? 'Search' : 'Flash Sale Search'}</span>
                <select id='pl_dropdown' onChange={this.changeSearchParam} value={this.searchParam}>
                  <option value="itemNumber">Item #</option>
                  <option value="assortNumber">Assortment #</option>
                  {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) && (
                   <option value={'alternateSKU'}>Alternate SKU#</option>)}
                </select>
                <input
                  id='search_RetailAssortment'
                  onKeyDown={this.handleEnterKey}
                  pattern={this.patterns[this.state.searchType]}
                  onChange={this.onFilterChange}
                  value={this.state.searchNumber}
                />
                {renderImages.getImage({
                  src: search_img,
                  className: 'search_icon_address',
                  onClick: this.searchItems
                })}
              </div>
              <div className="sort_filter">
                <span className='filter-label'>Sort By</span>
                <select className='sort-criteria combo savingcomb' onChange={this.changeSortParam} value={this.sortParam} id='pl_dropdown'>
                  <option value="saving" >Savings</option>
                  {/* Removed as per the business instruction */}
                  {/* <option value="MFO" >Market First Added</option> */}
                  <option value="Dating" >Dating</option>
                </select>
                <select className='sort-order combo' onChange={this.changeSortDirection} value={this.sortDirection} id='sortAssortmentOrder'>
                  <option value='asc'>Ascending</option>
                  <option value='desc'>Descending</option>
                </select>
                {window.innerWidth >= 1023 &&
                  <span id='icon_align'>
                    <i
                      className='list icon cursor_p'
                      value='List'
                      id='list_icon'
                      onClick={() => this.handleSort('list')}></i>{' '}
                  |
                  <i
                      className='th icon cursor_p'
                      value='Grid'
                      id='grid_icon'
                      onClick={() => this.handleSort('grid')}></i>
                  </span>
                }
              </div>
            </div>

            <div className={window.innerWidth > 1023 ? "displayFlex allOrdersTable" : "allOrdersTable"}>
              <Filter
                clearFilters={this.state.clearFilters}
                filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                appliedFilters={this.state.appliedFilters}
                toggleShowFilter={this.toggleShowFilter}
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                callback={this.filterCallback}
                isNoProducts={this.state.isNoProducts}
                selecDept={FLASH_SALES}
                screenName={'FlashSales'}
              />
              {this.state.displayType === 'list' && window.innerWidth >= 1023 ?
                <RetailAssortmentLandingList
                  assortment={this.state.assortments}
                  endDate={this.state.endDate}
                  showAssortmentList={this.showAssortmentList}
                  isNoProducts={this.state.isNoProducts}
                  setBackButtonPath={this.setScrollPosition}
                  backPath={this.state.backPath}
                  isFlashsales={this.state.isFlashsales}
                /> :
                <RetailAssortmentLandingGrid
                  assortment={this.state.assortments}
                  endDate={this.state.endDate}
                  showAssortmentList={this.showAssortmentList}
                  isNoProducts={this.state.isNoProducts}
                  setBackButtonPath={this.setScrollPosition}
                  backPath={this.state.backPath}
                  isFlashsales={this.state.isFlashsales}
                />
              }
            </div>
          </Segment>
        </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const storeId = state.SessionReducer.storeId;
  return {
    userId: state.SessionReducer.UserId,
    storeId,
    getprefer: state.preference.listData,
    rdc: state.SessionReducer.rdc,
    aliasSKURetailerGrpID:state?.SessionReducer?.address ? state?.SessionReducer?.address?.aliasSKURetailerGrpID : ''
  };
}

export default connect(mapStateToProps, null)(RetailAssortments);