const baseImageUrl = "https://images.truevalue.com/getimage.asp?id=";
const imageTypeTag = "&ImageType=";
const noImagetypeTag = "&NoImageType=";
const colorImagesizeTag = "&ColorImageSize=";
const lineartImageSizeTag = "&LineArtImageSize=";
const imageTypes = ["4","5","6"]
function getMeta(url) {
    return  new Promise((resolve,reject) => {
                        var img = new Image();
                        img.onload = () => {resolve(img)}
                        img.src = url
                });
} 

export async function getMultipleImages (itemNumber) {
    let images = [];
    images.push(
        baseImageUrl+itemNumber+imageTypeTag+"2"+noImagetypeTag+"2"+colorImagesizeTag+"2"+lineartImageSizeTag+"2"
        );
    return await Promise.all(imageTypes.map(async(imageType,index) => {
        let url = baseImageUrl+itemNumber+imageTypeTag+imageType+noImagetypeTag+"2"+colorImagesizeTag+"2"+lineartImageSizeTag+"2";
        let img = await getMeta(url);
        if(img.width>1){
            images.push(url);
        }
        if(index === imageTypes.length-1){
            return images;
        }
    }));
}