import React from 'react';
import Dialog from 'react-dialog';
import utilities from '../../../utils/utilities';

export const DealsDialog = ({vendorName, spiff, member_terms, shipdate1, shipdate2, shipdate3, shipdate4, DialogClose}) =>{
    const props = window.innerWidth>768? {
        width:'1',
        height:'1',
        position:'absolute'
    }:{};
    return (
        <Dialog {...props}>
            <div className='dealDialogTitle'>{vendorName}</div>
            <div className='dialog_close' onClick={DialogClose}>X</div>
            <br /><br />
            <div>
            <span className='event_notes'>{spiff}</span>
                <br /><br />
                <div className='dating_terms'>
                    Special Dating Terms<br />
                    {member_terms} {isNaN(member_terms)?'':'Days'}
                </div>

                <div className='ship_weeks'>
                    <span>Ships week of </span>
                    <br/>
                    <span className='ship_dates'>
                        {shipdate1}<br/>
                        {!utilities.isEmptyOrNullString(shipdate2) && <>{shipdate2} <br/></>}
                        {!utilities.isEmptyOrNullString(shipdate3) && <>{shipdate3} <br/></>}
                        {!utilities.isEmptyOrNullString(shipdate4) && <>{shipdate4} <br/></>}
                    </span>                                                   
                </div>
            </div>
        </Dialog>
    )
}