import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Dialog from 'react-dialog';
import { Segment, Table, Checkbox } from 'semantic-ui-react';
import renderImages from '../common/RenderImages';
import { MaskPrice } from '../common/MaskPrice';
import { TierIcon, ProductIndicators } from '../common/TierIcon';
import utilities, {getPrefernceType, shouldHideCostForPreference, shouldHideSUGG_RETAILForPreference} from '../../utils/utilities';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import config from '../../config'; 
import QuickViewDetails from '../common/QuickViewDetails';
import { closeModal } from '../common/productHandler';

class ProductsListCard extends React.Component {
  constructor(props){
    super(props);
    this.closeModal = closeModal.bind(this);
    this.state = {
      expand: '',
      visible: false,
      item: '',
      click: '',
      isMultistoreModalOpen:false,
      quantity:utilities.emptyForNull(props.product.cart_available),
      overrideRetail:utilities.emptyForNull(props.product.cart_overrideretail)
    }
  }
  expand = num => {
    this.setState({
      expand: 'expanding',
      item: num
    });
  };
  closeModalexpand = () => {
    this.setState({
      expand: '',
      item: ''
    });
  };

  handleChange = (e) =>{
    const type = e.target.dataset.field;
    const value = e.target.value;
    if (type === 'qty' &&   parseFloat(value) <= 0) {
      utilities.showToast('Quantity should be positive number!', true);
      return;
    }
    if(type==='qty' && /^(\d+)$|^$/.test(value)){
      this.setState({quantity:value});

    }else if((type === 'or' || type ==='override') && /^(\d{0,4}\.\d{0,2}|\d{0,4})$/){
      this.setState({overrideRetail:value})
    }
  }

  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.props.closeMultistoreModal();
    this.setState({ isMultistoreModalOpen: false });
  }
  handleKeyDown = (e, product)=>{
    const msCheck = utilities.msExist(this.props.multiStoreDetails);
    if(this.props.multiStoreDetails.access !== 1 && e.key === 'Enter' && !this.props.isReunionOnlyStore){
        if(msCheck && msCheck > 1){
          this.multistoreHandleCarts(product);
      }
      else{
        this.props.addToCart(product.itemNbr, product.memberCost);
      }
  }
  }
  
  getLongDesc = (product) => {
    let longDesc = '';
    if(config.showMediumDesp){
      longDesc = product.Product_Title || product.Description;
    }
    else{
      longDesc = product.Description;
    }
    let Item_num = product.Item_Number;
    return(
      longDesc && (
        <p className='listingDescription listingDescription-compact'>
          {longDesc.length < 250
            ? longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase() 
            : longDesc.substr(0,1).toUpperCase() + longDesc.substr(1, 242).toLowerCase()  + '...'}
          <u className='cursor_p'
              onClick={() => this.expand(Item_num)}
          >
            {longDesc.length < 250
              ? ''
              : 'more'}
          </u>
        </p>
      )
    )
  }

  openModal(data) {
    this.setState({
      visible: true,
      item: data,
      click: 'clicked',
      expand: ''
    });
    this.props.blurfun && this.props.blurfun(true);
  };

  getLongDescription = (product) => {
    let longDesc = '';
    if(config.showMediumDesp){
      longDesc = product.Product_Title || product.Description;
    }
    else{
      longDesc = product.Description;
    }
    if(longDesc){
      longDesc =  longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase()
    }
    else{
      longDesc = "";
    }

    return longDesc;
  }

  render(){
    const { product } = this.props;
    const isDiscontinuedHideAddToCart = product.StockStatus === 'DIS' ? true : false
    const memberCost = product.pricing ?
                        utilities.getCostForQtyPlpNew(product.pricing, this.state.quantity)
                          :'';
    let msCheck = utilities.msExist(this.props.multiStoreDetails);
    const getPrefer =  getPrefernceType(this.props); 
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const showSuggRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
    return (
      <Segment className='product-grid-segment product_card-compact'>
        {this.state.visible && this.state.item === product.itemNbr && (
          <div className='QuickviewDialog' onMouseLeave={this.closeModal}>
            <Dialog width='1' height='1' position='absolute'>
              <QuickViewDetails
                getprefer={
                  this.props.getprefer &&
                  this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                }
                tableType={'favorites'}
                Item_Number={product.itemNbr}
                viewType={this.props.viewType}
                QuickCost ={product.Member_Cost}
                productPlp = {product}
              />
            </Dialog>
          </div>
        )}
        <MultistoreToCartModal
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          product={this.state.productSelectedForModal}
          closeMultistoreModal={this.closeMultistoreModal}
          tableType={'favorites'}
          indexCheck={this.props.indexForModalMs}
          quantitiesMs={this.state.quantity}
          handleFavItemChange={this.handleChange}
          overrideRetail = {this.state.overrideRetail}
        ></MultistoreToCartModal>
        <div className='ui stackable two-column-display'>
          <div className='one-column-display'>
            <div className='card_list_wrap'>
              <Link className='card_list_img'
                    onClick={this.props.setBackButtonPath} 
                    to={{
                        pathname:`/product/${product.itemNbr}`,
                        state:{
                          source:[{text:'FAVORITES',link:"/favorites"}]
                        }
                      }}>
                {renderImages.getImage({
                  src: this.props.imageUrl,
                  style: { display: this.props.img_style },
                  className: 'ui image image_lisitng',
                  alt: 'image'
                })}
              </Link>
            </div>
            { window.innerWidth > 767 && (
              <div>
                <span
                  className='view_button-compact-visible'
                  onClick={() => {
                    this.openModal(product.itemNbr);
                  }}
                >
                  Quick View
                </span>
              </div>
            )}
          </div>
          <div className='fifteen wide column row-display'>
            <div className = 'plp_header'>
              <div className = 'plp_product_header'>
              <b className='First_level_details product_title-compact'>
              <Link
                onClick={this.props.setBackButtonPath}
                to={{
                  pathname: '/product/' + product.itemNbr,
                }}
                >
                {product.shortDescription}
              </Link>
                  </b>
                  <b className='vendor-span vendor-span-compact'>
                    <Link
                      onClick={this.props.setBackButtonPath} 
                      to={{pathname:product.vendorName
                          ? '/vendor/' +encodeURIComponent(product.vendorName)
                          : '#', state: { vendorId: product.vendorId }}}
                    >
                      {product.vendorName}
                    </Link>
                  </b>
              </div>
              { !this.props.isMomentum && <div className='ui checkbox card_list_content_checkbox checkbox-compact'>
                  <b className='content_checkbox_b1'>
                    <span className='content_checkbox_remove'>Remove </span>
                    <Checkbox
                      checked={product.selected}
                      onClick={() =>
                        this.props.selectForRemoval(product.itemNbr)
                      }
                    />
                  </b>
                </div>}
            </div>
            <div className='ui stackable plp_column-display'>
              <div className='ten wide column compact_padding plp_text-default'>
                {this.state.expand === 'expanding' &&
                  this.state.item === product.Item_Number && (
                    <div className='DescriptionDialog favourite-More' onMouseLeave={this.closeModalexpand}>
                      <Dialog width='1' height='1' position='absolute'>
                        {this.getLongDescription(product)}
                      </Dialog>
                    </div>
                  )}
                  {this.getLongDesc(product)}
              </div>
              <div className='four wide column plp_table-compact-default'>
                <Table stackable className='very basic pl_table_font table-compact'>
                  <Table.Body className='pl_table_body table_body-compact'>
                  {this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr) ? 
                  (
                    <>
                    <Table.Row className = 'collapsing'>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        <span>Item#:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        {product.itemNbr}
                      </Table.Cell>
                     
                      { displayMemberCostAndLbl &&<Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        <span>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}:</span>
                      </Table.Cell>
                      }
                      { displayMemberCostAndLbl ? <Table.Cell className='First_level_details First_level_details-compact collapsing'>    
                      {(this.props.multiStoreDetails.access !== 1  
                        || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr))
                          ? 
                          <MaskPrice
                              getprefer={
                                this.props.getprefer &&
                                this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                              }
                              viewType={this.props.viewType}
                              fieldVal={memberCost}
                              field='Member Cost'
                            />
                          :''
                      }
                      {product.pricing && product.pricing.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW && (
                        <TierIcon
                          tierLevel={utilities.getCostForQtyPlpNew(
                            product.pricing,
                            this.state.quantity,
                            true
                          )}
                          tierLetter={this.state.quantity && parseInt(this.state.quantity) > 0 ? true : false}
                        />
                      )}
                      </Table.Cell>
                      :
                      <> 
                       <Table.Cell/>
                       <Table.Cell/>
                      </>
                      }
                    </Table.Row>
                    <Table.Row className = 'collapsing'>
                     {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && 
                      <>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        <span>Alternate SKU#:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>  
                      {product.aliasSku} 
                      </Table.Cell> </>}
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        <span>Model#:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details pl_product_model First_level_details-compact collapsing'>
                        {product.Model}
                      </Table.Cell>                      
                      {showSuggRetailPrice && <Table.Cell className='First_level_details'>
                        <span className>{utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL",getPrefer)}: </span>
                      </Table.Cell>
                      }
                      {showSuggRetailPrice &&
                      <Table.Cell className='First_level_details'>  
                      {this.props.getSuggestedRetail(product)}   
                      </Table.Cell>} 
                    </Table.Row> 
                    </>
                    )
                    :
                    (
                      <>
                        <Table.Row className = 'collapsing'>
                          <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                            <span>Item#:</span>
                          </Table.Cell>
                          <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                            {product.itemNbr}
                          </Table.Cell>
                          <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                            <span>Model#:</span>
                          </Table.Cell>
                          <Table.Cell className='First_level_details pl_product_model First_level_details-compact collapsing'>
                            {product.Model}
                          </Table.Cell>
                        </Table.Row>              
                        {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) &&            
                          <Table.Row>
                            <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                              <span>Alternate SKU#:</span>
                            </Table.Cell>
                            <Table.Cell className='First_level_details First_level_details-compact collapsing'>  
                              {product.aliasSku} 
                            </Table.Cell>
                          </Table.Row> 
                          }
                        </>
                       ) 
                    }
                    <Table.Row>                    
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        Available:
                      </Table.Cell>
                      <Table.Cell 
                        className={
                          (product.available && parseInt(product.available)) > 0 
                                ? 'First_level_details First_level_details-compact collapsing'
                                : 'First_level_details_red cost_red_color First_level_details-compact collapsing'}>
                        {utilities.checkForHubAndSpokeIndictor(product, 'compact')}
                      </Table.Cell>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        Override Retail:
                      </Table.Cell>
                      <Table.Cell>
                        <input
                          id={'or-' + product.itemNbr}
                          className='qty-box pl_qty_box input-compact'
                          data-field='or'
                          maxLength={7}
                          value = {this.state.overrideRetail}
                          onChange={this.handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        Pack:
                      </Table.Cell>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        {product.sPackType}
                        {product.sPackQty}
                      </Table.Cell>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing'>
                        Quantity:
                      </Table.Cell>
                      <Table.Cell>
                        <input
                          className='qty-box pl_qty_box input-compact'
                          id={'qty-box' + product.itemNbr}
                          data-field='qty'
                          maxLength={7}
                          value = {this.state.quantity}
                          onChange={this.handleChange}
                          onKeyDown = {(e)=>this.handleKeyDown(e, product)}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                    {product.alternateItem?.Number && product.alternateItem?.Number > 0 ? (
                      <>
                          <Table.Cell className="First_level_details First_level_details-compact collapsing">
                            <span>{product.alternateItem?.Label}</span>
                          </Table.Cell>
                          <Table.Cell className="First_level_details First_level_details-compact collapsing">
                            <Link  to={{ pathname: '/product/' + product.alternateItem.Number}}>
                              {product.alternateItem.Number}
                            </Link>
                          </Table.Cell>
                      </>
                      ):<Table.Cell colSpan="2"></Table.Cell>}
                    </Table.Row>      
                    {product.maxQty ? <Table.Row>
                      <Table.Cell className='First_level_details First_level_details-compact collapsing' style={{border: 'none'}}>Max Qty:</Table.Cell>
                      <Table.Cell colSpan= "3" className='First_level_details First_level_details-compact collapsing' style={{border: 'none'}}>{product.maxQty}</Table.Cell>
                     </Table.Row> : null }      
                    {!utilities.isEmptyOrNullString(product?.StockStatusMessage) ? <Table.Row>
                     <Table.Cell className='First_level_details First_level_details-compact collapsing'>Stock Status:</Table.Cell>
                     <Table.Cell colSpan= "3" className='First_level_details First_level_details-compact collapsing stockStausMsgTd'><span className="stockStausMsg">{product.StockStatusMessage}</span></Table.Cell>
                     </Table.Row> : null }
                    {this.props.isMomentum ? <Table.Row>
                     <Table.Cell className='First_level_details First_level_details-compact collapsing'>Momentum Code:</Table.Cell>
                     <Table.Cell colSpan= "3" className='First_level_details First_level_details-compact collapsing stockStausMsgTd'><span className="stockStausMsg">{product.MomentumCode}</span></Table.Cell>
                     </Table.Row> : null }
                    {this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr) ? 
                    <>
                    </>
                    :
                    <Table.Row>
                      <Table.Cell className='First_level_details'>
                      <span>SUGG. RETAIL:</span>
                      </Table.Cell>
                      <Table.Cell className='First_level_details'>                      
                        {this.props.getSuggestedRetail(product)}   
                      </Table.Cell> 
                    </Table.Row> 
                    }
                    <Table.Row>
                    <Table.Cell colspan = {2} style = {{borderRight: 'none'}}> <span className = 'restricted-product'>{(product?.Restrictions?.length > 0)
                                    ? utilities.restrictedDetail(product?.Restrictions): ''}</span></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colspan = {2} style = {{borderLeft: 'none'}}></Table.Cell>
                      <Table.Cell colspan = {2}>
                        <div className = 'plp_add_to_cart-compact'>
                      {this.props.multiStoreDetails.access !== 1 && !isDiscontinuedHideAddToCart &&
                      <>
                      {msCheck && msCheck > 1 ?
                      !this.props.isReunionOnlyStore ?
                      <button
                      className='red ui button plp_button-compact'
                      onClick={() =>
                        this.multistoreHandleCarts(product)
                      }
                    >
                      Add to Store
                    </button>
                    : <>
                        <button className='red ui button plp_button-compact' disabled>Add to Store</button>
                        <p class="disabledMessage disabledMessage-compact">*Ordering restricted</p>
                      </>
                    :!this.props.isReunionOnlyStore ?
                    <button
                      className='red ui button plp_button-compact'
                      onClick={() =>
                      this.props.addToCart(
                        product.itemNbr,
                        product.memberCost
                      )
                    }>
                      Add to Cart
                    </button>
                  : <>
                      <button className='red ui button plp_button-compact' disabled>Add to Cart</button>
                      <p class="disabledMessage disabledMessage-compact">*Ordering restricted</p>
                    </>
                    }
                      </>
                      }
                      </div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div className = 'plp_footer'>
            <div className ='indicators-div indicators-div-compact'>
                        <ProductIndicators product = {product} isPLP='PLP' view = 'compact'/>
                </div>
            </div>
          </div>
        </div>
        <div id='snackbar'>Product added to cart</div>
      </Segment>
    );
  };
}

const mapStateToProps = state => {
  return {
    getprefer:state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
  };
};

export default connect( mapStateToProps, null)(ProductsListCard);
