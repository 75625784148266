import React, { Component } from 'react';
import CartProductsTable from './CartProductsTable';
import { connect } from 'react-redux';
import history from '../../history';
import { bindActionCreators } from 'redux';
import { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts } from './actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Loader, Grid, Responsive } from 'semantic-ui-react';
import {utilities, shouldHideCostForPreference} from '../../utils/utilities';
import { restUtils } from '../../utils/restUtils';
import { PLANOGRAM_PLACE_ORDER } from '../../urlConstants';
import { MaskPrice } from '../common/MaskPrice';
import { totalCost } from './cartUtils';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class CheckoutPlanograms extends Component {
  constructor(props) {
    super(props);
    const checkedoutCart = props.carts.find(cart => {
      return parseInt(cart.cartId) === parseInt(props.match.params.cartName);
    });
    const {isMultiStore,multiStoreCarts} = props?.location?.state;
    this.state = {
      cartName: _.get(props, 'match.params.cartName', ''),
      cart: checkedoutCart,
      isLoading: false,
      isError: false,
      errorMsg: '',
      tstmsg: '',
      disablePlaceorderbtn: this.props.address.viewOrders,
      cartSelected:[],
      isMultiStore : isMultiStore,
      multiStoreCarts : multiStoreCarts
    };
  }
  componentDidMount(){
    if(this.props.address.viewOrders === true){
      utilities.showToast(`Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.`, true);
    }
  }
  placeOrderPlanograms = (multiStoreCarts = [],isMultiStore) => {
    this.setState({ isLoading: true });
    const { cart } = this.state;
    const { userName, storeId, checkDigit, fullMemberNbr, multiStoreDetails } = this.props;
    const url = PLANOGRAM_PLACE_ORDER;
    
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }
    else{
      multiStoreCarts = multiStoreCarts && multiStoreCarts.filter(msDetails => msDetails?.items?.length>0);
    }
    let reqPayload = [];
    reqPayload = multiStoreCarts?.map(cart => {
      const itemsDetail = cart?.items?.map((item) => {
        return { itemNbr: item.item_nbr, overrideRetail: item.overrideRetail, qty: item.userQty.toString() }
      });
      let storeCheckDigit = multiStoreDetails?.childStoreDetails[cart.storeId] ? multiStoreDetails.childStoreDetails[cart.storeId].checkDigit : checkDigit;    
      return {
          memberNbr: cart.storeId, applicationType: 'NW', checkDigit: storeCheckDigit, poNum: cart.cartName,
          molUserName: userName, datingTerms: '', shoppingCartId: cart.cartId, fullMemberNbr: fullMemberNbr,
          type: '', sqsItems: itemsDetail
        }
     })
    restUtils.postData(url, reqPayload).then((response) => {
      if (response) {
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
        if (response.error && (response.error !== null || response.error !== "")) {
          utilities.showToast(response.error, true)
        } else {
          history.push({
            pathname: `/orderConfirmation/${this.state.cartName}`,
            state: {
              cart: multiStoreCarts,
              ...this.state.cart,
              poNum: cart.cartName,
              orderTotal: cart.totalCost,
            }
          });
        }
      }
    }).catch((err) => {
      console.log(err);
      utilities.showToast(`Failed to Submit order. Please try later`, true);
      this.setState({ disablePlaceorderbtn: false, isLoading: false });
    });
  }

  placeOrderAll = () => {
    const { multiStoreCarts } = this.state;
    this.placeOrderPlanograms(multiStoreCarts,true)
  }

  placeOrderSelected = () => {
    let { multiStoreCarts } = this.state;
    let cartSelected = this.state.cartSelected;
    if(cartSelected && cartSelected.length > 0){
      multiStoreCarts = multiStoreCarts && multiStoreCarts.filter(cart =>
                                      cartSelected.includes(cart.storeId))
      this.placeOrderPlanograms(multiStoreCarts,true);
    }else{
      this.toastDelay(utilities.centreshowToast('Please select atleast one order to submit',true));
      return;
    }
  }

  handleStoreCheck= (storeId) => {
    let {cartSelected} = this.state
    if(cartSelected.includes(storeId)){
      cartSelected = cartSelected.filter(item => item !== storeId)
    }else{
      cartSelected.push(storeId)
    }
     this.setState({
      cartSelected
     })
  }

  cancelOrder = (cartId) => {
    history.push('/cart');
    this.props.deleteCart(cartId);
    this.props.notifyCarts();
  };

  render() {
    const { cart, isMultiStore} = this.state;
    let {multiStoreCarts} = this.state;
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    if(!isMultiStore){  
      multiStoreCarts = [cart];
    }  
    const  getCheckoutPrefer = this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView
    : 'c1'
     const  hideTotalExtendCost = shouldHideCostForPreference(this.props.viewType,getCheckoutPrefer)
    return (
      <div className='page-wrap'>
        <div className='page-header'>Checkout {cart ? `${cart.cartName}-${cart.cartId}` : ''}</div>
        { this.state.disablePlaceorderbtn && <div className='Note-Message'>Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.</div>}
        {!cart ? (
          <h3>
            Order already placed!{' '}
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </h3>
        ) : this.state.isError ? (
          <div className='checkoutErrorBacttoCart'>
            <h3>
              {this.state.errorMsg ? this.state.errorMsg : 'Checkout failed!'}
            </h3>
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </div>
        ) : (
              <>
                <div className='checkout-table'>
                  {this.state.isLoading && (
                    <div class="loaderWrapper">
                    <Loader active />
                    <p>Please wait while we process your order... <br/> Please don’t press the Back Button while your order is processing </p>
                    </div>
                  )}
                  
                  {isMultiStore &&
                  <>
                    <div className='t-right'> 
                      <Link to='/cart' className='multi-store-back-to-cart'>
                        Back to Cart
                      </Link>
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderSelected()} disabled={this.state.disablePlaceorderbtn}>
                        Place Selected Orders
                      </button>
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderAll()} disabled={this.state.disablePlaceorderbtn}>
                        Place All Orders
                      </button>
                    </div>    
                    <br/>                  
                  </>}
                  {multiStoreCarts.map(cart => 
                  <>
                  {isMultiStore &&  cart?.items?.length > 0 &&
                  <>
                    <div className='cart-accordion accordion-header t-center'>
                      <span>
                        {'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, cart.userId)}
                      </span>
                      <span className='Multi-cart-accordion-store-select'>
                      {'Select Store '} <input
                        type='checkbox'
                        name = {'storeCheck'+cart.userId}
                        onChange={() => this.handleStoreCheck(cart.storeId)}
                      />
                      </span>
                    </div>
                  </>
                  }
                  { cart?.items?.length > 0 && 
                  <>
                  <Responsive minWidth='1025'>
                    <div className='place-orderRA'>
                      <div>
                        {!isMultiStore && <Link to='/cart' className='back-to-cart'>
                          Back to Cart
                        </Link>}
                        <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}>
                          Cancel Order
                        </button>
                        {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                          || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                          || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                          || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                          || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                          || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                          && !this.props.hq && this.props.allowedorder)
                          ? !this.props.isProspectStore ?
                           <button className='ui red button place-order-btn' onClick={this.placeOrderPlanograms} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                           :<> 
                           <button className='ui red button place-order-btn' disabled> Place Order </button>
                           <p className="disabledMessage">*Ordering restricted</p>
                           </>
                          : ''
                        }
                      </div>
                    </div>
                  </Responsive>
                  <Responsive maxWidth='1024'>
                    <div className='place-orderRA'>
                    <Link to='/cart' className='back-to-cart'> Back to Cart </Link>
                      <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}> Cancel Order </button>
                      {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                        || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                        || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                        || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                        || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                        || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                        && !this.props.hq && this.props.allowedorder)
                        ? !this.props.isProspectStore ?
                         <button className='ui red button place-order-btn' onClick={this.placeOrderPlanograms} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                         :<> 
                           <button className='ui red button place-order-btn' disabled> Place Order </button>
	                           <p className="disabledMessage">*Ordering restricted</p>
                         </>
                        : ''
                      }
                    </div>
                  </Responsive>
                   <>
                    <CartProductsTable
                      cart={cart}
                      products={cart && cart.items}
                      isCheckout={true}
                      userId = {cart.userId}
                    />
                    <Responsive minWidth='1025'>
                      <Grid columns={3} id='shippingBlock'>
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                          <div>
                            <span>Purchase Order #:</span>
                            <span className='Planogram_PO'>{cart && cart.cartName}</span>
                          </div>
                          <div>
                            <span>Ship to:</span>
                            <span className='RAshipping'>Store</span>
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Regular Delivery </span>
                          </div>
                        </Grid.Column>

                        <Grid.Column id='RA'>
                          <div className='column RAmessage t-right'>
                            <p >
                              {' '}
                                  Prices are subject to change. Items will be priced
                                  at the time the order is fulfilled, not at the
                                  time of order placement.
                                </p>
                          </div>
                          <br />
                          { hideTotalExtendCost ?
                          <div className="extended">
                            <div className='store-combo-div totalCost'>
                              {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                              {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                                getprefer={this.props.getprefer &&
                                  this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                                }
                                viewType={this.props.viewType}
                                fieldVal={totalCost(this.state.cart)}
                                field='cart-totals'
                              />}
                            </div>
                          </div> : "" }
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                    <Responsive maxWidth='1024'>
                      <Grid id='shippingBlock'>
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                          <div>
                            <span>Purchase Order #:</span>
                            <span className='Planogram_PO'>{cart && cart.cartName}</span>
                          </div>
                          <div>
                            <span>Ship to:</span>
                            <span className='RAshipping'>Store</span>
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Regular Delivery </span>
                          </div>
                        </Grid.Column>

                        <Grid.Column className='Ramobile_checkout'>
                          <div>
                            <p> {' '}
                                Prices are subject to change. Items will be priced
                                at the time the order is fulfilled, not at the
                                time of order placement.
                              </p>
                          </div>
                          <br />
                          { hideTotalExtendCost ?
                          <div className="extended">
                            <div className='store-combo-div totalCost'>
                              {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                              {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                                getprefer={this.props.getprefer &&
                                  this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                                }
                                viewType={this.props.viewType}
                                fieldVal={this.state.cart && this.state.cart.totalCost}
                                field='cart-totals'
                              />}
                            </div>
                          </div> : "" }
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                    </>
                    </>}
                    </>
                   )}                  

                  <div id="centresnackbar" className={this.state.tstmsg && `show`} >{this.state.tstmsg}</div>
                </div>
              </>
            )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  let carts = state.cart.orders.allCarts;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    carts,
    userName: state.SessionReducer.userName,
    getprefer: state.preference.listData,
    address: state.SessionReducer.address,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    storeRDC: state.SessionReducer.rdc,
    allowedorder: state.SessionReducer.address.allowedorder,
    hq: state.SessionReducer.address.hq,
    checkDigit: state.SessionReducer.address.checkDigit,
    fullMemberNbr: state.SessionReducer.address.fullMemberNbr,
    isProspectStore : state.SessionReducer.address.isProspectStore
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts },
      dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPlanograms);
