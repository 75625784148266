import React from "react";
import BreadcrumbComp from "../../components/BreadcrumbComp";
import OptionsSection from "../ReunionEvent/AllReunion/OptionsSection";
import { getPagination } from "../common/CustomComponents";
import ItemListView from "../ReunionEvent/AllReunion/ItemListView";
import ItemGridView from "../ReunionEvent/AllReunion/ItemGridView";
import Filter from "../ReunionEvent/AllReunion/Filter";
import restUtils from "../../utils/restUtils";
import { REUNION_ITEM_LISTING } from "../../urlConstants";
import { connect } from "react-redux";
import utilities from "../../utils/utilities";
import { exportExcel } from "../common/ComponentUtilities";
import { MaskPrice } from "../common/MaskPrice";
import { viewAllCarts } from "../Cart/actions";
import {
  isSearchRequired,
  isSortRequired,
  getSortByOptions,
  getSearchByOptions,
  isShowByRequired,
  isAddToCartRequired,
  isSeqRequried,
  getPdpParams,
  getPageNumber,
  handleQtyChange,
  addToCart,
  getVendorDetails,
  setScrollPosition,
} from "../ReunionEvent/AllReunion/itemListingUtilities";
import { Loader } from "semantic-ui-react";
import { isArray } from "util";
import _ from "lodash";
import config from '../../config.json';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class CloseoutCorner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {     
      dealDetails: false,
      orderDeadline:'',
      isFilterRequired: true,
      filters: [],
      pageHeader: props.pageHeader,
      breadcrumbLinks: props.breadcrumbLinks,
      isLoading: false,
      productsData: [],
      quantities: [],
      overrideRetails: [],
      isNoProducts: false,
      imgTextToDisplay:
        props.getprefer &&
        props.getprefer.thumbnailImage &&
        props.getprefer.thumbnailImage !== "Y"
          ? "Show Images"
          : "Hide Images",
      isGridView:
        props.getprefer &&
        props.getprefer.prdctListlayoutFormat &&
        props.getprefer.prdctListlayoutFormat !== "L",
      paginationParams: {},
      productSelectedForModal: null,
      indexForModal: null,
      appliedFilters: {},
      searchPattern: "[0-9]*",
      searchValue: "",
      flowType:"1",
      shoppingAreaMetaId:"7",
      promoNbr:0,
      relayNbr:0,
      relayYear:0
    };
    this.pageSize =
      this.props.getprefer && this.props.getprefer.resultPerPage
        ? this.props.getprefer.resultPerPage
        : "50";
    this.scrollPosition = 0;
    this.sortParam = "";
    this.sortDirection = "asc";
    this.searchby = "item";
    this.searchValue = "";
    this.page = 1;
    this.defaultChecked = false;
    this.filterList = "";
    this.pathname = "";
    this.handleQtyChange = handleQtyChange.bind(this);
    this.addToCart = addToCart.bind(this);
    this.searchByOptions = getSearchByOptions('1',utilities.isAliasSkuRequired(this.props.multiStoreDetails));
    this.getVendorDetails = getVendorDetails.bind(this);
    this.setScrollPosition = setScrollPosition.bind(this);
  }

  componentDidMount() {
    let queryParam = window.location.href.split("?");
    this.pathname = this.props.location && this.props.location.pathname;
    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    } else {
      this.fetchItems();
    }
  }

  handleViewChange = (isGridView) => {
    this.setState({ isGridView });
  };
  
  showHideImages = (evt) => {
    let imgTextToDisplay =
      evt.target.innerHTML === "Show Images" ? "Hide Images" : "Show Images";
    this.setState({ imgTextToDisplay });
  };

  showDealDetails() {
    this.setState({
      dealDetails: true,
    });
  }
  DialogClose = () => this.setState({ dealDetails: false });
  getRequestPayload = (isCompleteList) => {
    const {
      eventId,
      relayNbr,
      relayYear,
      vendorId,
      paginationParams,
      searchValue,
      shoppingAreaMetaId
    } = this.state;
    const resultPerPage = !isCompleteList
      ? this.pageSize
      : this.state?.paginationParams?.totalItems;
      let currentCart = this.props?.carts 
                      && this.props?.carts.find(cart => parseInt(cart.cartId) === parseInt(this.props.cartId));
    return {
      memNbr: this.props.storeId,
      ...(!utilities.isEmptyOrNullString(searchValue)
        ? {
            searchby: this.searchby,
            searchvalue: searchValue,
          }
        : {}),
      VendorID: !vendorId ? null : vendorId,
      sortBy: this.sortParam,
      sortOrder: this.sortDirection,
      page: isCompleteList ? "1" : this.page,
      cartId: this.props.cartId,
      multiStoreCartId : utilities.msExist(this.props.multiStoreDetails) > 1 && currentCart?.parentId ? currentCart.parentId : '',
      resultPerPage,
      rdcNbr: this.props.rdc,
      isItemDtlFromWH: false,
      shoppingMetaDataId:shoppingAreaMetaId,
      aliasSKURetailerGrpID: utilities.isAliasSkuRequired(
        this.props.multiStoreDetails
      )
        ? 1
        : 0,
      ...(!_.isEmpty(this.filterList) ? { FilterBy: this.filterList } : {}),
    };
  };
  fetchItems = () => {
    this.setState({ isLoading: true });
    const payload = this.getRequestPayload();
    restUtils.postData(REUNION_ITEM_LISTING, payload).then((response) => {
      if(response.data && (response.data?.evtStsActiveBefore === false || response.data?.evtStsActiveAfter === false)) {
        this.setState({
          eventEnableAfter: response.data?.evtStsActiveAfter,
          eventEnableBefore: response.data?.evtStsActiveBefore,
          eventMessage: response.data?.Message
        })
        return;
      } else if (
        !response?.data?.reunionItemList ||
        response.data.reunionItemList.length === 0
      ) {
        this.setState((prevState) => {
          return {
            isLoading: false,
            productsData: [],
            isNoProducts: true,
            paginationParams: {
              ...prevState.paginationParams,
              totalPages: "0",
              currentPage: "0",
              totalItems: "0",
              firstItem: "0",
              lastItem: "0",
            },
          };
        });
        return;
      }
      const { data } = response;
      let filters = response?.data?.filters;
      filters.forEach(filter=>{
        if(filter.name==="Vendor Name"){
          filter.valueList = utilities.objSortValues(filter.valueList);
        }
      });
      this.setState({
        isLoading: false,
        isNoProducts: false,
        productsData: data.reunionItemList,
        paginationParams: data.pagination[0],
        orderDeadline: response.data?.OrderDeadline ? utilities.getDateFromTimeStamp(response.data.OrderDeadline): '',
        filters,
        shoppingAreaName:'Closeout',
        eventId:data.reunionItemList?.length > 0 && data.reunionItemList[0].promoNbr,
        relayNbr:data.reunionItemList?.length > 0 && data.reunionItemList[0].relayNbr,
        relayYear:data.reunionItemList?.length > 0 && data.reunionItemList[0].relayYear,
        quantities: data.reunionItemList?.map((product) => {
          return [
            utilities.emptyForNull(product.userQty),
            utilities.emptyForNull(product.userQty2),
            utilities.emptyForNull(product.userQty3),
            utilities.emptyForNull(product.userQty4),
          ];
        }),
        overrideRetails: data.reunionItemList.map((product) =>
          utilities.emptyForNull(product.overrideExisting)
        ),
      },()=>{
        window.scrollTo(0, this.scrollPosition);
      });
    }
    ).catch((error) => {
      this.setState({ isLoading: false });
      console.log(error);
    });
  };
  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        currentPage: 1,
        appliedFilters,
        clearFilters: false,
        backPath: this.pathname,
      },
      () => {
        this.page = 1;
        this.fetchItems();
        this.setBackButtonPath(appliedFilters);
      }
    );
  };
  changePageSize = (e) => {
    this.pageSize = e.currentTarget.value;
    this.page = "1";
    this.scrollPosition = window.pageYOffset;
    this.fetchItems();
    this.setBackButtonPath();
  };

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    this.page = getPageNumber(e.currentTarget.text, paginationParams);
    this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
    this.fetchItems();
    this.setBackButtonPath();
  };
  getListingHeader = () => {
    const { paginationParams, imgTextToDisplay } = this.state;
    return (
      <div className="listing-header">
        <span>
          {paginationParams.firstItem}-{paginationParams.lastItem} of{" "}
          {paginationParams.totalItems} products
        </span>
        <span onClick={this.showHideImages} id="show_and_hide_images">
          {imgTextToDisplay}
        </span>
        {paginationParams.totalPages > 0 && (
          <span className="pagination_top">
            {getPagination(
              0,
              parseInt(paginationParams.currentPage),
              parseInt(paginationParams.totalPages),
              this.onPageChange,
              null,
              1
            )}
          </span>
        )}
        {exportExcel({
          className: "exportPDF exportPdfPLP",
          onClick: this.handleExport,
        })}
      </div>
    );
  };
  getFieldSet = (product, index) => {
    let quantity = this.state.quantities[index];
    let overrideRetail = this.state.overrideRetails[index];
    const { access, CatMbr } = this.props?.multiStoreDetails;
    const { getprefer, memberViewType } = this.props;
    const { flowType, shoppingAreaId,shoppingAreaMetaId } = this.state;
    const memberCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={utilities.getCostForQtyHotDeals(
          product?.pricing,
          quantity,
          true
        )}
        field={"Member Cost black"}
      />
    );
    const costWithTier2 = utilities.getCostWithTier2(
      product,
      memberCost,
      quantity
    );
    const evtCost = utilities.getEventCost(
      product,
      utilities.getPreferenceHideMask(getprefer),
      memberViewType
    );
    const evtCostAtReunion = parseFloat(product.spcCost) === 0.0 || product.savings ===100;
    const savings =evtCostAtReunion || utilities.isEmptyOrNullString(product.savings)
                    ? "N/A"
                    : utilities.hyphenForZeroPercentage(product.savings);
    const maxQtyExists = product?.maxQtyItemRestriction>0;
    return !this.state.isGridView && window.innerWidth > 1024
      ? [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                [
                  { label: "ITEM#:", val: product.itemNbr },
                  {
                    label: "NON-STOCKED:",
                    val: product.stocked,
                  },
                  { label: "SHIP WEEK:", val: "QUANTITY" },
                ],
                [
                  { label: "MODEL#:", val: product.model },
                  {
                    label: "OVERRIDE RETAIL:",
                    val: overrideRetail,
                    index,
                    type: "or",
                    isTextBox: true,
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate1)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate1
                          ),
                          type: "qty1",
                          index,
                          isTextBox: true,
                          val: quantity[0],
                        },
                      ]),
                ],
                [
                  { label: "UPC:", val: product.upcNum },
                  {
                    label: "SAVINGS:",
                    val: savings,
                    labelClass: "red_color First_level_details",
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate2)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate2
                          ),
                          type: "qty2",
                          index,
                          isTextBox: true,
                          val: quantity[1],
                        },
                      ]),
                ],
                [
                  ...(access === 1 && access !== 1 && !CatMbr
                    ? []
                    : [
                        {
                          label: "WH COST:",
                          val: costWithTier2,
                        },
                      ]),
                  {
                    label: "PACK:",
                    val: `${product.sPackType ? product.sPackType : ""}${
                      product.sPackQty ? product.sPackQty : ""
                    }`,
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate3)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate3
                          ),
                          type: "qty3",
                          index,
                          isTextBox: true,
                          val: quantity[2],
                        },
                      ]),
                ],
                [
                  ...(access === 1 && access !== 1 && !CatMbr
                    ? []
                    : [
                        {
                          label: "EVT COST:",
                          val: evtCost,
                          labelClass: "red_color First_level_details",
                        },
                      ]),
                      {
                        label: "AVAILABLE:",
                        val: product.available,
                        labelClass: product.available > 0 ? '' : 'red_color First_level_details'
                      },
                  ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                    ? [
                        {
                          label: "Alternate SKU# :",
                          val: utilities.hyphenForNull(product.aliasSku),
                        },
                      ]
                    : [{}]),
                  ...(utilities.isEmptyOrNullString(product.shipDate4)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate4
                          ),
                          type: "qty4",
                          index,
                          isTextBox: true,
                          val: quantity[3],
                        },
                      ]),
                ],
                [
                  ...maxQtyExists?[
                    {label : product.isMultiples?"Pallet Qty :": "Max Qty :",
                      val:product.maxQtyItemRestriction
                    }
                  ]:[],
                  ...evtCostAtReunion
                    ? [
                        {
                          label: "RESTRICTED:",
                          val: "Pricing Avail @ Reunion",
                          className: "color-red t-center First_level_details",
                        },
                      ]
                    : [],
                  ...(utilities.isEmptyOrNullString(product.restricted)
                    ? [
                        {
                          label: "RESTRICTED:",
                          val: product.restricted ? "Restricted Product" : "",
                          className: "t-center restricted-product",
                        },
                      ]
                    : []),
                ],
              ]
            : [
                [
                  { label: "ITEM#:", val: product.itemNbr },
                  { label: "MODEL#:", val: product.model },
                  { label: "SHIP WEEK:", val: "QUANTITY" },
                ],
                [
                  { label: "UPC:", val: product.upcNum },
                  {
                    label: "OVERRIDE RETAIL:",
                    val: overrideRetail,
                    index,
                    type: "or",
                    isTextBox: true,
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate1)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate1
                          ),
                          type: "qty1",
                          index,
                          isTextBox: true,
                          val: quantity[0],
                        },
                      ]),
                ],
                [
                  {
                    label: "PACK:",
                    val: `${product.sPackType ? product.sPackType : ""}${
                      product.sPackQty ? product.sPackQty : ""
                    }`,
                  },
                  {
                    label: "SAVINGS:",
                    val: savings,
                    labelClass: "red_color First_level_details",
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate2)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate2
                          ),
                          type: "qty2",
                          index,
                          isTextBox: true,
                          val: quantity[1],
                        },
                      ]),
                ],
                [
                  {
                    label: "NON-STOCKED:",
                    val: product.stocked,
                  },
                  ...(utilities.isEmptyOrNullString(product.shipDate3)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate3
                          ),
                          type: "qty3",
                          index,
                          isTextBox: true,
                          val: quantity[2],
                        },
                      ]),
                ],
                [
                  ...maxQtyExists?[
                    {label : "Max Qty :",
                      val:product.maxQtyItemRestriction
                    }
                  ]:[],

                  ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                    ? [
                        {
                          label: "Alternate SKU# :",
                          val: utilities.hyphenForNull(product.aliasSku),
                        },
                      ]
                    : []),

                  ...(utilities.isEmptyOrNullString(product.shipDate4)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(
                            product.shipDate4
                          ),
                          type: "qty4",
                          index,
                          isTextBox: true,
                          val: quantity[3],
                        },
                      ]),
                ],
              ]),
        ]
      : [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                { label: "ITEM#:", val: product.itemNbr },
                { label: "MODEL#:", val: product.model },
                { label: "UPC:", val: product.upcNum },
                {
                  label: "WH COST:",
                  val: costWithTier2,
                },
                {
                  label: "EVT COST:",
                  val: evtCost,
                  classname: "red_color First_level_details",
                }, 
                {
                  label: "AVAILABLE:",
                  val: product.available,
                  classname: product.available > 0 ? '' : 'red_color First_level_details'
                },
                {
                  label: "OVERRIDE RETAIL:",
                  val: overrideRetail,
                  index,
                  type: "or",
                  index,
                  isTextBox: true,
                },
                {
                  label: "SAVINGS:",
                  val: savings,
                  classname: "red_color First_level_details",
                },
                {
                  label: "PACK:",
                  val: `${product.sPackType ? product.sPackType : ""}${
                    product.sPackQty ? product.sPackQty : ""
                  }`,
                },
                {
                  label: "NON-STOCKED:",
                  val: product.stocked,
                },
                ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                  ? [
                      {
                        label: "Alternate SKU# :",
                        val: utilities.hyphenForNull(product.aliasSku),
                      },
                    ]
                  : []),
                ... maxQtyExists? [{label:"MAX QTY : ", val:product.maxQtyItemRestriction}]:[],
                { label: "SHIP WEEK:", val: "QUANTITY" },
                ...(utilities.isEmptyOrNullString(product.shipDate1)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate1
                        ),
                        type: "qty1",
                        index,
                        val: quantity[0],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate2)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate2
                        ),
                        type: "qty2",
                        index,
                        val: quantity[1],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate3)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate3
                        ),
                        type: "qty3",
                        index,
                        val: quantity[2],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate4)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate4
                        ),
                        type: "qty4",
                        index,
                        val: quantity[3],
                        isTextBox: true,
                      },
                    ]),
                {
                  label: "RESTRICTED:",
                  val: product.restricted ? "Restricted Product" : "",
                  className: "t-center restricted-product",
                },
              ]
            : [
                { label: "ITEM#:", val: product.itemNbr },
                { label: "MODEL#:", val: product.model },
                { label: "UPC :", val: product.upcNum },
                {
                  label: "SAVINGS:",
                  val: savings,
                  classname: "red_color First_level_details",
                },
                {
                  label: "PACK:",
                  val: `${utilities.emptyForNull(
                    product.sPackType
                  )}${utilities.emptyForNull(product.sPackQty)}`,
                },
                {
                  label: "NON-STOCKED:",
                  val: product.stocked,
                },
                ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                  ? [
                      {
                        label: "Alternate SKU# :",
                        val: utilities.hyphenForNull(product.aliasSku),
                      },
                    ]
                  : []),
                { label: "SHIP WEEK:", val: "QUANTITY" },
                ...(utilities.isEmptyOrNullString(product.shipDate1)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate1
                        ),
                        type: "qty1",
                        index,
                        val: quantity[0],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate2)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate2
                        ),
                        type: "qty2",
                        index,
                        val: quantity[1],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate3)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate3
                        ),
                        type: "qty3",
                        index,
                        val: quantity[2],
                        isTextBox: true,
                      },
                    ]),
                ...(utilities.isEmptyOrNullString(product.shipDate4)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(
                          product.shipDate4
                        ),
                        type: "qty4",
                        val: quantity[3],
                        index,
                        isTextBox: true,
                      },
                    ]),
                {
                  label: "RESTRICTED:",
                  val: product.restricted ? "Restricted Product" : "",
                  className: "t-center restricted-product",
                },
              ]),
        ];
  };
  getItemParams = (product, index) => {
    let itemParams = { product };
    const fieldSet = this.getFieldSet(product, index);
    itemParams = {
      ...itemParams,
      fieldSet,
      pdpParams: getPdpParams(this.state.flowType, product),
      itemIndex: index,
    };
    return itemParams;
  };
  getItemListParams = () => {
    const { flowType } = this.state;
    return {
      handleQtyChange: this.handleQtyChange,
      isAddToCartRequired: isAddToCartRequired(flowType),
      isScanListing: false,
      isSeqRequired: isSeqRequried(flowType),
    };
  };

  getUrlFilters = (queryParam) => {
    // to reinitialise fields from url
    const queryString = queryParam[1];
    let getParams = queryParam[1].split("&");
    this.page = getParams[0].split("=")[1];
    this.pageSize = getParams[1].split("=")[1];
    this.sortParam = queryString.includes("&SortParam=")
      ? this.getFieldFromUrl(queryString, "SortParam=")
      : this.sortParam;
    this.sortDirection = queryString.includes("SortType=asc")
      ? "asc"
      : queryString.includes("SortType=desc")
      ? "desc"
      : this.sortDirection;
    if (queryString.includes("&SearchBy=")) {
      this.searchby = this.getFieldFromUrl(queryString, "SearchBy=");
    }
    if (queryString.includes("&SearchValue=")) {
      this.searchValue = this.getFieldFromUrl(queryString, "SearchValue=");
    }
    this.scrollPosition = queryString.includes("scrollPosition=")
      ? this.getFieldFromUrl(queryString, "scrollPosition=")
      : 0;
    let filterString = queryString.includes("filterString")
      ? queryString.split("filterString=")[1]
      : "";
    let appliedFilters = utilities.getFilterArrayAsMultiSelectObject(
      filterString
    );
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        searchValue: this.searchValue,
        appliedFilters: appliedFilters,
      },
      () => {
        this.fetchItems();
      }
    );
  };
  getFieldFromUrl = (queryString, fieldName) => {
    return queryString?.split(fieldName)[1]?.split("&")[0];
  };
  setBackButtonPath = (newAppliedFilters) => {
    const appliedFilters = newAppliedFilters
      ? newAppliedFilters
      : this.state.appliedFilters;
    let pathName = "";
    pathName = utilities.isEmptyOrNullString(this.page)
      ? "Page=1"
      : "Page=" + this.page;
    pathName = pathName + "&ResultsPerPage=" + this.pageSize;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    pathName = pathName + "&scrollPosition=" + this.scrollPosition;
    if (!utilities.isEmptyOrNullString(this.state.searchValue)) {
      pathName =
        pathName +
        "&SearchBy=" +
        this.searchby +
        "&SearchValue=" +
        this.state.searchValue;
    }
    pathName =
      this.scrollPosition > 0
        ? pathName + "&scrollPosition=" + this.scrollPosition
        : pathName;
    if (!_.isEmpty(this.filterList)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(appliedFilters);
    }
    if (pathName !== "") {
      let pushUrl = this.pathname + "?" + pathName;
      window.history.replaceState(window.history.state, "", pushUrl);
    }
  };
  getListing = () => {
    const cartParams = {
      addToCart: this.addToCart,
    };
    const imgStyle =
      this.state.imgTextToDisplay === "Hide Images"
        ? "displayBlock"
        : "displayNone";
    const { isGridView } = this.state;
    const itemListParams = this.getItemListParams();
    return (
      <div
        className={
          isGridView ? "ui stackable four column grid product-grid" : ""
        }
      >
        {this.state.productsData.map((product, index) => {
          const itemParams = this.getItemParams(product, index);
          return (
            <>
              {!isGridView && window.innerWidth >= 1024 ? (
                <ItemListView
                  indexCheck ={index}
                  quantities={this.state.quantities}
                  quantitiesMs={this.state.quantities}
                  flowType={this.state.flowType}
                  cartParams={cartParams}
                  itemParams={itemParams}
                  imgStyle={imgStyle}
                  product={itemParams.product}
                  shoppingAreaName={this.state.shoppingAreaName}
                  shoppingAreaId={this.state.shoppingAreaId}
                  shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                  itemListParams={itemListParams}
                  setBackButtonPath={this.setScrollPosition}
                />
              ) : (
                <ItemGridView
                  indexCheck ={index}
                  quantities={this.state.quantities}
                  quantitiesMs={this.state.quantities}
                  flowType={this.state.flowType}
                  cartParams={cartParams}
                  itemParams={itemParams}
                  shoppingAreaName={this.state.shoppingAreaName}
                  shoppingAreaId={this.state.shoppingAreaId}
                  shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                  imgStyle={imgStyle}
                  product={itemParams.product}
                  itemListParams={itemListParams}
                  setBackButtonPath={this.setScrollPosition}
                />
              )}
            </>
          );
        })}
      </div>
    );
  };
  searchItems = (e) => {
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  changeSearchValue = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  changeSearchBy = (e) => {
    const option = this.searchByOptions.find(
      (option) => option?.props?.value === e.target.value
    );
    const pattern = option?.pattern;
    this.searchby = e.target.value;
    this.setState({ searchPattern: pattern, searchValue: "" });
  };
  changeSortParam = (e) => {
    this.sortParam = e.target.value;
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  changeSortDirection = (e) => {
    this.sortDirection = e.target.value;
    this.sortParam = utilities.isEmptyOrNullString(this.sortParam)
      ? "vendorname"
      : this.sortParam;
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  handleExport = () => {
    const payload = this.getRequestPayload();
    utilities.showToast("Exporting to Excel...");
    restUtils.postData(REUNION_ITEM_LISTING, payload).then((response) => {
      if ( !response?.data?.reunionItemList || response?.data?.reunionItemList.length === 0 ) {
        utilities.showToast("No items available.", true);
        return;
      }
      else {this.createCsvData(response?.data?.reunionItemList)}
    });
  };
  createCsvData = (productsData) =>{
    utilities.showToast("Exporting to Excel...");
    const itemsList = productsData?.map((product) => {
      const preference = utilities.getPreferenceHideMask(
        this.props.getprefer
      );
      const savings = utilities.isEmptyOrNullString(product.spcCost) || parseFloat(product.spcCost) === 0.0
                        ? ""
                        : utilities.emptyForZeroPercentage(product.savings);
      let row = {};
      row = { ...row, ...{ "Item Number": utilities.emptyForNull(product.itemNbr) } };
      if (utilities.isAliasSkuRequired(this.props.multiStoreDetails)) {
        row = {
          ...row,
          ...{ "Alternate SKU#": utilities.emptyForNull(product.aliasSku) },
        };
      }
      row = {
        ...row,
        ...{
          'Short Description': utilities.emptyForNull(product.shortDesc),
          Model: utilities.emptyForNull(product.model),
          Pack: `${product.sPackType ? product.sPackType : ""}${
            product.sPackQty ? product.sPackQty : ""
          }`,
          UPC: utilities.emptyForNull(product.upcNum),
          "Item Description": config.showMediumDesp ? utilities.emptyForNull(
            product.productTitle || product.longDescription
          ) : utilities.emptyForNull(
            product.longDescription
          ),
          Pack: `${product.sPackType ? product.sPackType : ""}${
            product.sPackQty ? product.sPackQty : ""
          }`,
          "WH COST": utilities.emptyForNull(
            utilities.getCostBasedOnnatvalue(
              this.props.memberViewType,
              product.cost,
              preference
            )
          ),
          "EVT COST": utilities.emptyForNull(
            utilities.getCostBasedOnnatvalue(
              this.props.memberViewType,
              product.spcCost,
              preference
            )
          ),
            "AVAILABLE": utilities.zeroForNull(product.available),
          "Savings": utilities.emptyForNull(savings),
          "Ship Week 1": product.shipDate1 && product.shipDate1 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate1
          ) : '',
          "Ship Week 2": product.shipDate2 && product.shipDate2 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate2
          ) : '',
          "Ship Week 3": product.shipDate3 && product.shipDate3 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate3
          ) : '',
          "Ship Week 4": product.shipDate4 && product.shipDate4 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate4
          ) : '',
          "Non Stocked": product.stocked && product.stocked !== 'N/A' ? product.stocked : '',
        },
      };
      return row;
    });
    utilities.exportToCsv(itemsList, this.state.shoppingAreaName);
  }
  render() {
    const {
      isLoading,
      isNoProducts,
      isFilterRequired,
      flowType,
      vendorId,
      vendorName,
      filters,
      searchPattern,
      searchValue,
      productsData
    } = this.state;
    const breadcrumbPath = [{ link: '/home', text: 'HOME' },
    { link: '/closeoutCorner', text: 'Closeout Corner' }];
    if(productsData ) {
      return (
        <>
        <div className="page-wrap">
          <div className="page-header">
            {'Closeout Corner'}
          </div>
          <BreadcrumbComp path={breadcrumbPath} /><br /><br />
          {vendorId && this.getVendorDetails(vendorName, this.state.orderDeadline , vendorId)}
          <OptionsSection
            isSortByRequired={isSortRequired(flowType)}
            sortByList={getSortByOptions(
              flowType,
              utilities.isAliasSkuRequired(this.props.multiStoreDetails)
            )}
            changeSortParam={this.changeSortParam}
            isSearchRequired={isSearchRequired(flowType)}
            searchByList={this.searchByOptions}
            isShowByRequired={isShowByRequired(flowType)}
            searchItems={this.searchItems}
            pageSize={this.pageSize}
            changePageSize={this.changePageSize}
            changeSearchBy={this.changeSearchBy}
            changeSearchValue={this.changeSearchValue}
            isGridViewIconRequired={true}
            handleViewChange={this.handleViewChange}
            changeSortDirection={this.changeSortDirection}
            searchPattern={searchPattern}
            searchValue={searchValue}
            searchParam={this.searchby}
            sortParam={this.sortParam}
            sortOrder={this.sortDirection}
          />
          <div className="displayFlex allOrdersTable">
            {isLoading && <Loader active />}
            {isFilterRequired && (
              <Filter
                filters={filters}
                isNoProducts={isNoProducts}
                appliedFilters={this.state.appliedFilters}
                defaultChecked={this.defaultChecked}
                clearFilters={false}
                filterCallback={this.filterCallback}
              />
            )}
            <div id="grid_width" className="full-width pl_data">
              {this.getListingHeader()}
              {isNoProducts ? (
                <div className="no_prdcts_display">
                  <h1>No Products to Display!</h1>
                </div>
              ) : (
                <>
                  {this.getListing()}
                  <span className="paginationButtom">
                    {this.state.paginationParams.totalPages > 0 &&
                      getPagination(
                        0,
                        parseInt(this.state?.paginationParams?.currentPage),
                        parseInt(this.state?.paginationParams?.totalPages),
                        this.onPageChange,
                        null,
                        1
                      )}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </>
      );
    } else {
      return (
        <>{isLoading && <Loader active />}</>
      )
    }
  }
}
const mapStateToProps = (state) => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    userId: state.SessionReducer.UserId,
    userName: state.SessionReducer.address.userName,
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    memberViewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    cartId: state.cart.orders?.currentCart?.cartId,
    carts: state.cart.orders?.allCarts,
    rdc: state.SessionReducer.rdc,
    marketDate: state.cart.orders.eventDate
  };
};

export default connect(mapStateToProps, { viewAllCarts })(CloseoutCorner);
