import React from "react";
import { Table } from "semantic-ui-react";

const SavedAds = ({ ads, removeAd, editAd }) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell>Image</Table.HeaderCell>
          <Table.HeaderCell>Url</Table.HeaderCell>
          <Table.HeaderCell>Department</Table.HeaderCell>
          <Table.HeaderCell>Class</Table.HeaderCell>
          <Table.HeaderCell>Sub-Class</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      {ads.length < 1 && (
        <h3 style={{ textAlign: "center", marginTop: "1rem" }}>
          {" "}
          No ads available.{" "}
        </h3>
      )}

      {ads.length > 0 && (
        <Table.Body>
          {ads.map((ad) => (
            <Table.Row
              key={ad.id}
              className="saved_ads_table_row"
            >
              <Table.Cell textAlign="center">{ad.title}</Table.Cell>
              <Table.Cell textAlign="center">
                <a href={ad.imageUrl}>Image</a>
              </Table.Cell>
              <Table.Cell textAlign="center">{ad.url}</Table.Cell>
              <Table.Cell textAlign="center">{ad.department.name}</Table.Cell>
              <Table.Cell textAlign="center">
                {ad.departmentClass.name}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {ad.departmentSubClass.name}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <i
                  className="fa fa-trash"
                  style={{ cursor: "pointer", marginRight: "1.2rem" }}
                  onClick={removeAd(ad)}
                />
                <i
                  className="fa fa-edit"
                  style={{ cursor: "pointer" }}
                  onClick={editAd(ad)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      )}
    </Table>
  );
};

export default SavedAds;
