export const breadcrumbLinks = [{ link: '/home', text: 'Home' }];

export const colDefs = [
  {
    heading: 'Demand Class',
    key: 'Dclass',
    cellRenderer: value => {
      let renderValue = value;
      switch (value) {
        case 'E':
          renderValue = 'Promotional';
          break;
        case 'F':
          renderValue = 'Non-Classified';
          break;
          default:
      }
      return renderValue;
    }
  },
  {
    heading: 'Total Lines Ordered',
    key: 'Lines_ordered'
  },
  {
    heading: 'Non-Serviceable Lines Ordered',
    key: 'Non_ser_lines_ordered'
  },
  {
    heading: 'Serviceable Lines Ordered',
    key: 'Sev_lines_ordered'
  },
  {
    heading: 'Serviceable Lines Filled',
    key: 'Sev_lines_filled'
  },
  {
    heading: 'Weekly Fill Rates',
    key: 'Weekly_fill_rate' //'FR_for_week'
  },
  {
    heading: 'Corporate Goal',
    key: 'Corp_goal'
  },
  {
    heading: 'Member YTD',
    key: 'Member_ytd'
  },
  {
    heading: 'Corporate YTD',
    key: 'Corp_ytd'
  },
  {
    heading: 'Total Shiplaters**',
    key: 'Total_shiplaters'
  }
];

export const notes = [
  'Demand class is based on the wholesale demand item ranking for the stores servicing warehouse',
  "'A' items account for 50% of overall sales",
  "'B' items account for 25% of overall sales",
  "'C' items account for 15% of overall sales",
  "'D' items account for 10% of overall sales",
  'Seasonal items are included in ABCD classification',
  '*Lines not counted in Fill Rates calculation consists of Discontinued, Out of Season and Not Stocked in Warehouse',
  '**Shiplaters are included in Fill Rates calculation the first time they do not fill. If multiple shiplater lines are created for subsequent attempts to fill a line that has already been counted, the lines for those subsequent attempts are not included in the measure.'
];

export const summaryConfig = [
  {
    heading: 'Total Lines Ordered',
    key: 'Lines_ordered'
  },
  {
    heading: 'Non-Serviceable Lines Ordered',
    key: 'Non_ser_lines_ordered'
  },
  {
    heading: 'Serviceable Lines Ordered',
    key: 'Sev_lines_ordered'
  },
  {
    heading: 'Serviceable Lines Filled',
    key: 'Sev_lines_filled'
  },
  {
    heading: 'Weekly Fill Rates',
    key: 'Weekly_fill_rate'
  },
  {
    heading: 'Corporate Goal',
    key: 'Corp_goal'
  },
  {
    heading: 'Member YTD',
    key: 'Member_ytd'
  },
  {
    heading: 'Corporate YTD',
    key: 'Corp_ytd'
  },
  {
    heading: 'Total Shiplaters**',
    key: 'Total_shiplaters'
  }
];
