import React from "react";
import { Dropdown, DropdownItem } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { createCart, chooseCart, viewAllCarts } from "../../views/Cart/actions";
import shoppingCart from '../../views/images/ShoppingCart.png';
import shoppingCartGrey from '../../views/images/ShoppingCartGrey.png';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./styles.css";
import utilities from "../../utils/utilities";
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import _ from "lodash";

class CartFlyout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCart: props.currentCart,
      newCartName: "",
    };
  }

  handleNewCartNameChange = (e) => {
    this.setState({ newCartName: e.target.value });
  };

  createNewCart = () => {
    if (utilities.isEmptyOrNullString(this.state.newCartName)) {
      utilities.showToast("Cart name cannot be empty!");
      return;
    }
    if (this.state.newCartName === "Events") {
      utilities.showToast(
        "'Events' cart name is reserved for Reunion Event orders"
      );
      return;
    }
    if (!utilities.isValidslash(this.state.newCartName)) {
      utilities.showToast("Cart name should not contain  special chracters");
      return;
    }
    this.props.createCart(this.state.newCartName, "REGULAR");
    this.props.viewAllCarts();
    this.setState({ currentCart: this.state.newCartName, newCartName: "" });
  };

  changeCurrentCart = (e) => {
    this.setState({ currentCart: e.target.value });
    this.props.chooseCart(
      this.props.carts.find(
        (cart) => parseInt(cart.cartId) === parseInt(e.target.value)
      )
    );
  };

  render() {
    const {
      toggleCartMenu,
      showCartMenu,
      disableCart,
      currentCart,
    } = this.props;
    const totalExistingCost =
      this.props.carts &&
      this.props.carts.find(
        (cart) => parseInt(cart.cartId) === parseInt(currentCart.cartId)
      );
    let msCart = _.uniqBy(this.props.carts, "parentId");
    const totalExtCost = totalExistingCost && totalExistingCost.totalCost;
    const cartViewType =
      this.props.getprefer && this.props.getprefer.customerView
        ? this.props.getprefer.customerView
        : "c1";
    let memberCost = utilities.getCostBasedOnnatvalue(
      this.props.viewType,
      totalExtCost,
      cartViewType
    );
    memberCost = utilities.isEmptyOrNullString(memberCost)?'':' - '+memberCost;
    let isMultistoreCheck =
      utilities.msExist(this.props?.multiStoreDetails) > 1;
    const tooltip = (currentCart && !_.isEmpty(currentCart))? 
                        'Cart : '+currentCart?.cartName+memberCost
                        :'';
    return (
      <Dropdown
        className="pointing top right"
        icon={
          <img src={disableCart ? shoppingCartGrey : shoppingCart}
            title={!disableCart && tooltip}
            name="cart"
            size="large"
            className={
              disableCart
                ? "large square shopping cart grey icon cursor_p no_cursor"
                : "large square shopping cart black icon cursor_p"
            }
          />
        }
        onClick={toggleCartMenu}
        open={showCartMenu}
      >
        <Dropdown.Menu className="cart-menu" id="cart-menu">
          <div>
            <DropdownItem className="cart-menu-option">
              <Link to="/cart">View Cart</Link>
            </DropdownItem>
            <DropdownItem className="cart-menu-option italic">
              Choose Cart :
            </DropdownItem>
            <DropdownItem
              className="cart-menu-option"
              onClick={(e) => e.stopPropagation()}
            >
              <select
                value={this.props.currentCart.cartId}
                onChange={this.changeCurrentCart}
                className="cart-flyout-combo"
              >
                {this.props.carts && this.props.carts.length === 0 && (
                  <option className="select-expandcart"> </option>
                )}
                {isMultistoreCheck
                  ? msCart.map((cart) => {
                      return (
                        <option value={cart.cartId}>
                          {cart.cartName === "M4Success"
                            ? "VISMERCH"
                            : cart.cartName}
                          -{cart.cartId}
                        </option>
                      );
                    })
                  : this.props.carts.map((cart) => {
                      return (
                        <option value={cart.cartId}>
                          {cart.cartName === "M4Success"
                            ? "VISMERCH"
                            : cart.cartName}
                          -{cart.cartId}
                        </option>
                      );
                    })}
              </select>
            </DropdownItem>
            <DropdownItem className="cart-menu-option italic">
              Create New Cart :
            </DropdownItem>
            <DropdownItem className="cart-menu-option">
              <div className="new-cart-flyout-div">
                <input
                  className="new-cart-flyout-input"
                  placeholder="Cart Name.."
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => this.handleNewCartNameChange(e)}
                  value={this.state.newCartName}
                />
                <i
                  className="arrow right icon flyout-go-icon"
                  onClick={this.createNewCart}
                />
              </div>
            </DropdownItem>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => {
  const { orders } = state.cart;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    currentCart: orders.currentCart || {},
    currentOrderType: orders.currentOrderType,
    getprefer: state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails: state.SessionReducer?.multiStoreDetails,
    carts: orders.allCarts || [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ createCart, viewAllCarts, chooseCart }, dispatch)
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(CartFlyout);
