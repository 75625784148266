import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import restUtils from '../../utils/restUtils';
import _ from 'lodash';
import { Tab } from 'semantic-ui-react'
import './styles.css'
import { getWordpressCustomContent } from '../../urlConstants'
import utilities from '../../utils/utilities';
import TabContent from './TabContent'
import { getPagination } from '../common/CustomComponents';
import { Link } from 'react-router-dom';
class Retailer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          data:[],
          totalPages:null,
          currentPage: 1,
          activeIndex: 0
        }
        this.page = 1
    }
    componentDidMount() {
      this.getItemDetails()
    }
    handleTabChange = (e, { activeIndex }) => {
      const  { all_post } = this.state.data
      this.page = 1
      this.setState({ activeIndex, currentPage:1, totalPages: Math.ceil(all_post.category[activeIndex].total_count/5) },()=>{
        this.getItemDetails();
      })
    }
    renderData = () => {
      const  { all_post } = this.state.data
      if(!_.isEmpty(this.state.data)) {
     return (
      all_post.category.map(item=>{
          return(
            {
              menuItem:item.name,
              render: () => this.renderTab(item)
            }
          )
          })
        )
      }
    }
    renderTab = (item) => {
      const {page_ids} = this.state.data
      return item.posts.map((tab,index)=>{
        if(index === 0) {
          return (
            <Tab.Pane key={tab.post_id} className="retailerTabContent firstTabContent">
               <div className='retailerBanner'>
               <h2 style={{ fontSize: utilities.isMobileTab() && '3rem'}}>{page_ids[0]?.heading}</h2>
	               <p>{page_ids[0]?.sub_heading}</p>
              </div>
              <Link to={{
                pathname:`/content/${tab.post_slug}`,
                state: {
                  isPost: true
                }                
                }}><h4>{tab.title}</h4></Link>               
              <span>{tab.date}</span>
              <div dangerouslySetInnerHTML={{__html: tab.content}}></div> 
              <a className='linkSlugBtn'>{tab.category[0].name}</a>
            </Tab.Pane>
          )
        } else {
          return (
            <Tab.Pane key={tab.post_id} className="retailerTabContent">
              <Link to={{
                pathname:`/content/${tab.post_slug}`,
                state: {
                  isPost: true
                }                
                }}><h4>{tab.title}</h4></Link>   
              <span>{tab.date}</span>
              <div dangerouslySetInnerHTML={{__html: tab.content}}></div> 
              <a className={utilities.isMobileTab() ? 'linkSlugBtn linkSlugBtnMobile' : 'linkSlugBtn'}>{tab.category[0].name}</a>
            </Tab.Pane>
          )
        }
      })
    }
    tabContent = (tab) =>{
      return (
        <Tab.Pane key={tab.post_id} className="retailerTabContent">
        <Link to={{
                pathname:`/content/${tab.post_slug}`,
                state: {
                  isPost: true
                }                
        }}><h4>{tab.title}</h4></Link>    
        <span>{tab.date}</span>
        <div dangerouslySetInnerHTML={{__html: tab.content}}></div> 
        <a className='linkSlugBtn'>{tab.category[0].name}</a>
      </Tab.Pane>
      )
    }
    onPageChange = (e) =>{
      let queryPage = e.currentTarget.text;
      if (e.currentTarget.text === 'Next') {
        queryPage =
          this.state.currentPage < this.state.totalPages
            ? this.state.currentPage + 1
            : this.state.currentPage;
      }
      if (e.currentTarget.text === 'Prev') {
        queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
      }
      if (e.currentTarget.text === '»') {
        queryPage = this.state.totalPages;
      }
      if (e.currentTarget.text === '«') {
        queryPage = 1;
      }
      this.page = queryPage;
      window.scrollTo(0, 0);
      this.getItemDetails();
    }
    getItemDetails = () => {
      const valueEncode = encodeURIComponent(`retail-alerts?per_page=5&page=${this.page}`)
      restUtils.getDataWithBearer(`${getWordpressCustomContent()+valueEncode}`).then(response=>{
        this.setState({data: response.data, totalPages: Math.ceil(response.data.all_post.category[this.state.activeIndex].total_count/5), currentPage: this.page})
      }).catch(err=>{
        console.log(err)
      })
    }
    render() {
      const mode = !utilities.isMobileTab() ? true: false
        return(
          <div className='wpTemplateWrapper'>
        <div className='retailerWrapper wpTemplateStyle'>
            <Tab 
            menu={{ fluid: true, vertical: mode, tabular: true  }} 
            panes= {this.renderData()}   
            onTabChange={this.handleTabChange}           
            />
            <div className='retailerPagination'>
              {this.state.totalPages > 0 && (
                getPagination(0, this.state.currentPage, this.state.totalPages, this.onPageChange, null, 1)
              )}            
            </div>  
            </div>          
        </div>
        )        
    }
}
const mapDispatchToProps = (dispatch)=>{ 
    return Object.assign({dispatch},
      bindActionCreators({},dispatch)) 
  } 
  const mapStateToProps = (state) => { 
    return {

    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Retailer);