import React from 'react';
import utilities from '../../utils/utilities';
import {getSelect, getDatePicker, getButton} from '../common/ComponentUtilities';
import restUtils from '../../utils/restUtils';
import {Loader} from 'semantic-ui-react';
import {GET_DROPSHIP_REPORT} from '../../urlConstants';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import {downloadBinary} from '../common/GenericExcelExport/exportUtils';

export const REPORT_TYPES = ['Dropship', 'Dropship Discount City']

class DropShip extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            selectedReportType: '',
            selectedDates: {},
            serverError: false,
            disabledButton: true,
        }
    }
    getReportTypeDropDown = ()=>{
        const selectProps = {className: 'combo dropdownWidth', onChange: this.changeReportType}
        let reportTypeOptions = [{ props: { value: '' }, text: '--- Select ---' }];

        REPORT_TYPES.forEach((reportType)=>{
            const eachOpt = { props: { value: reportType, id: reportType, className: 'SelectOption' }, text: reportType }
            reportTypeOptions = [...reportTypeOptions, eachOpt]
        })
        return getSelect(selectProps, reportTypeOptions)
    }
    changeReportType = (e)=>{
        this.setState({selectedReportType: e.target.value});
        this.updateDisabledDownload();
    }
    updateSelectedDate = (id, value)=>{
        if(!value || value.length ===0){
            return;
        }
        if(id === 'startDate' || id === 'endDate'){
            const cleanedValue = utilities.getDateFromTimeStampShip(new Date(value));
            const {selectedDates} = this.state;
            selectedDates[id] = cleanedValue;
            this.setState({selectedDates});
        }
        this.updateDisabledDownload();
    }
    updateDisabledDownload = ()=>{
        const {selectedReportType, selectedDates} = this.state;
        const {startDate, endDate} = selectedDates;

        if(selectedReportType && utilities.compareDates(startDate, endDate)){
            this.setState({disabledButton: false});
        }
    }
    componentDidMount(){
        this.updateSelectedDate('startDate', new Date());
        this.updateSelectedDate('endDate', new Date());
        this.updateDisabledDownload();
    }
    datePicker = (fieldName, id, value, fieldClass, isLabel, removeTime)=>{
        return getDatePicker(fieldName, id, value? new Date(value):new Date(), this.updateSelectedDate, undefined, fieldClass, isLabel, removeTime);
    }
    reportTypeLayout = ()=>{
        return(
            <div className = 'upload-link'>
                <div className = 'displayFlex page-header mb-2'>
                    Report Type
                </div>
                <div className = 'displayFlex'>
                        {
                            this.getReportTypeDropDown()
                        }
                </div>
            </div>
        )
    }
    handleOnClick = ()=>{
        const reportType = this.state.selectedReportType === 'Dropship' ? 0: this.state.selectedReportType === 'Dropship Discount City' ? 1: null;
        if(this.state.selectedReportType === ''){
            utilities.showToast('Please Select Report Type');
        }

        const {startDate, endDate} = this.state.selectedDates;
        if(startDate >= endDate){
            utilities.showToast('Start Date must less then End Date');
        }

        this.setState({isLoading: true});
        const requestUrl  = GET_DROPSHIP_REPORT + `?reportType=${reportType}` + '&startDate='+encodeURIComponent(startDate) + 
                            '&endDate='+encodeURIComponent(endDate);

        restUtils.getData(requestUrl).then((response)=>{
            const fileName = `${this.state.selectedReportType}.xlsx`;
	        downloadBinary(response.data, fileName);
            this.setState({isLoading: false});
        }).catch((error)=>{
            console.log(error);
            utilities.showToast('Download cannot be complete');
            this.setState({isLoading:false});
        });
    }
    reunionTypeLayout = (selectedDates, isLoading)=>{
        return (
            <div className = 'upload-link'>
                <div className = 'displayFlex page-header mb-2' style = {{textAlign: 'center'}}>
                    Reunion
                </div>
                <div className = 'displayFlex'>
                    {
                        this.datePicker('Start Date  ','startDate', selectedDates.startDate, 't-center dropship_datepicker', true, true)
                    }
                    {
                        this.datePicker('End Date  ', 'endDate', selectedDates.endDate, 't-center dropship_datepicker', true, true)
                    }
                </div>
                {getButton({type:'button', className:isLoading?'ui red loading button dropship_button':'ui red button dropship_button', name:'download', onClick: this.handleOnClick, disabled: this.state.disabledButton}, 'Download')}
            </div>
        )
    }
    render(){
        const {isLoading, serverError, selectedDates} = this.state;
        return(
            <>
                {
                    serverError? <InternalServerError/> : (
                        <>
                      <div className = 'displayFlex dropship_content'>
                          <div>
                              {
                                  this.reportTypeLayout()
                              }
                        </div>
                        <div>
                              {
                                  this.reunionTypeLayout(selectedDates, isLoading)
                              }
                        </div>
                      </div>
                    </>
                    )
                }
            </>
        )
    }
}

export default DropShip;