import React from 'react';
import { Modal } from 'semantic-ui-react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { createNAddCart, chooseCart, createNAddCartOrderPad } from './actions';
import { infoTypes } from './constants';
import utilities from '../../utils/utilities';
import _ from "lodash";
class AddToCartModal extends React.Component {
  constructor(props) {
  super(props);
    this.state={
      open:false,
      selectedCartId:(this.props.whCarts && this.props.whCarts.length>0)?this.props.whCarts[0].cartId:'',
      isNew:false,
      cartName:'',
      showErrorMsg:false
    }
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.whCarts !== nextProps.whCarts) {
      this.setState({selectedCartId:(nextProps.whCarts && nextProps.whCarts.length>0)?nextProps.whCarts[0].cartId:''})
    }
  }
  handleChangeOption = (e) => {
    this.setState({isNew:e.target.value==='new',showErrorMsg:false});
  }

  handleCartChange=(e)=>{
    this.setState({selectedCartId:e.target.value});
  }

  handleChangeCartName=(e)=>{
    this.setState({cartName:e.target.value, showErrorMsg:false});
  }

  validateCartName = () => {
    const { isNew, cartName } = this.state;
    if(isNew && cartName.trim().length === 0) {
      this.setState({showErrorMsg:true});
      return false;
    }
    return true;
  }

  handleModalAddToCart= () => {
    const { isNew, cartName } = this.state;
    const {product,addToCart,qty,or,isCheckAllWarehouse,isSingle} = this.props;
    const CentralRDC = (product && product.quantity) ? product.quantity[0] : null;
    const rdcNum = isCheckAllWarehouse?
                      ''
                      :utilities.rdcBasedonCentralship((CentralRDC ? CentralRDC.CsRdc : product.CsRDC), this.props.rdc);
    if(!this.validateCartName()) {
      return;
    }
    if(isCheckAllWarehouse){
      this.props.closeCartModal(null,true,isNew,this.state.selectedCartId,cartName);
      this.setState({
        isNew:false,
        cartName:''
      });
      return;
    }
    if(isNew) {
        this.props.createNAddCart(
          product.Item_Number ? product.Item_Number: product.itemNbr,
          qty,
          or,
          product.Member_Cost,
          infoTypes[1],
          1,
          cartName,
          rdcNum
        );
    } else {
      const selectedCart = this.props.whCarts.find(cart => parseInt(cart.cartId) === parseInt(this.state.selectedCartId));
      this.props.chooseCart(selectedCart);
      addToCart(
        product.Item_Number ? product.Item_Number: product.itemNbr,
        product.Member_Cost,
        qty,
        or,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        this.state.selectedCartId,
        rdcNum);
    }
    this.props.closeCartModal();
    this.setState({
      isNew:false,
      cartName:''
    });
  }
  handleModalOrderPadAddToCart = () => {
    const { isNew, cartName } = this.state;
    const {product,addToCart} = this.props;
  
    if(!this.validateCartName()) {
      return;
    }
    if(isNew) {
        this.props.createNAddCartOrderPad("REGULAR",'01',cartName).then(res=> addToCart(product.postObjOrderPad,product.costs));
    } else {
      const selectedCart = this.props.whCarts.find(cart => parseInt(cart.cartId) === parseInt(this.state.selectedCartId));
      this.props.chooseCart(selectedCart);
    setTimeout(() => {
        addToCart(product.postObjOrderPad,product.costs);
     }, 2000);
    }
    this.props.closeCartModal();
    this.setState({
      isNew:false,
      cartName:''
    });
  }

  render() {
  const {isAddtoCartModalopen, closeCartModal} = this.props
  let isMultistoreCheck = utilities.msExist(this.props?.multiStoreDetails) > 1;
  let msCart = _.uniqBy(this.props.whCarts, "parentId");
  return (
    <Modal closeIcon
      className='add-to-cart-modal'
      onClose = {closeCartModal}
      open={isAddtoCartModalopen}>
      <Modal.Content>
        <Modal.Description className='t-center'>
          <p>There are multiple carts available for{' '}</p>
          <span className='t-center order-type'> Warehouse Products </span>
          <p>Would you like to :</p>
          <div>
            <input
              type='radio'
              label='Choose Existing cart '
              checked = {!this.state.isNew}
              name = 'cartChoice'
              value = 'existing'
              onChange = {this.handleChangeOption}
              /> Choose Existing cart &nbsp;&nbsp;
              <input
                type='radio'
                label='Create New '
                checked = {this.state.isNew}
                name = 'cartChoice'
                value = 'new'
                onChange = {this.handleChangeOption} 
                /> Create New 
            <br/>
            <br/>
          </div>
          {! this.state.isNew &&
            <select className='t-center cart-combo'
              value={this.state.selectedCartId}
              onChange={this.handleCartChange}
            >
              {isMultistoreCheck 
                 ? msCart.map(cart => (
                   <option
                   className='cart-combo-option test'
                   value={cart.cartId}
                   key={cart.cartId}>
                   {cart.cartName}-{cart.cartId}
                   </option>
                 ))
                 : this.props.whCarts.map(cart => (
                     <option
                     className='cart-combo-option single'
                     value={cart.cartId}
                     key={cart.cartId}>
                     {cart.cartName}-{cart.cartId}
                     </option>
                  ))
              }
            </select>
          }

          {this.state.isNew &&
            <div className='t-center'>
              <label style={{ fontWeight: 'bolder' }}>Cart Name</label>
              <input 
                type='text' 
                className='cart-name-input t-center' 
                value={this.state.cartName} 
                onChange={this.handleChangeCartName}/>
                {
                this.state.showErrorMsg &&
                  <p>* Cart Name is Mandatory</p>
                }
            </div>
          }
          <br/>
          <br />
          <button
            className='ui red button'
            onClick={this.props?.orderPad ? this.handleModalOrderPadAddToCart : this.handleModalAddToCart}
            style={{ float: 'right' }}>
            Submit
          </button>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )}
}

const mapStateToProps = (state) => {
const carts = state.cart.orders.allCarts;
  return {
    carts,
    currentCartType :state?.cart?.orders?.currentCart?.cartInfoName,
    whCarts : carts.filter(cart => cart.cartInfoName === 'REGULAR'),
    rdc: state.SessionReducer.rdc,
    multiStoreDetails: state.SessionReducer?.multiStoreDetails
  };
}

const mapDispatchToProps=(dispatch)=>{
  return Object.assign({dispatch},bindActionCreators({createNAddCart,chooseCart, createNAddCartOrderPad},dispatch))
}

export default connect(mapStateToProps,mapDispatchToProps)(AddToCartModal);
