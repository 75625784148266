import restUtils from "../../utils/restUtils"
import { IMPULSE_MERCHANDISING_ITEMS } from "../../urlConstants"
import _ from 'lodash';
import utilities from "../../utils/utilities";
import { isArray } from 'util';
export const fetchItemsForImpulseMerchadising = function(newProps){
    const currentProps = newProps?newProps:this.props
    const filterList = (this.filterString &&!_.isEmpty(this.filterString))?this.filterString["filterBy"]:null;
    let assortmentId = currentProps.assortmentId;
    const postObj = {
        filterBy:filterList ,
        pog:assortmentId,
        storeID:this.props.storeId,
        userId: this.props.userId,
        rdcNum :this.props.rdc,
        cartid:this.props.currentCartId,
        SortParam:utilities.isEmptyOrNullString(this.sortParam)?"ItemNo":this.sortParam,
        SortType:utilities.isEmptyOrNullString(this.sortDirection)?"asc":this.sortDirection,
        aliasSKURetailerGrpID:this.props.aliasSKURetailerGrpID
    }
    const { favorites } = currentProps;
    
    this.setState({ loading: true });
    restUtils.postData(IMPULSE_MERCHANDISING_ITEMS,postObj)
        .then(response =>{
            const { data } = response;
            if(!data || !data.Merchitemdetails || data.Merchitemdetails.length === 0){
                this.setState({
                    isNoProducts:true,
                    loading:false
                })
                return;
            }
            let categoryData = data.Merchitemdetails.map(productData => {
            const favorited =
                favorites.indexOf(parseInt(productData.Item_Number)) >-1;
                return {
                    ...productData,
                    selected: false,
                    favorited
                };
            });
            const qtyAvailableItem = categoryData.filter(product => utilities.isEmptyOrNullString(product.cart_available) ? product.recQty : product.cart_available)
            const dataSetValue = qtyAvailableItem && qtyAvailableItem.map(ele=> {
              let product = {
                item: ele.Item_Number,
                qty: utilities.isEmptyOrNullString(ele.cart_available) ? ele.recQty : ele.cart_available,
                overrideRetail: utilities.isEmptyOrNullString(ele.cart_overrideretail) ? '' : ele.cart_overrideretail,
                productItem: ele
              }
              return product
            })
            this.setState({
                categoryData,
                filters:data.filters,
                loading: false,
                dataSetValue,
                isNoProducts: !categoryData || categoryData.length === 0,
                totalItems :categoryData.length,
                firstItem : categoryData.length>0 ?1:0,
                lastItem : categoryData.length>0 ?categoryData.length:0,
                quantities:  categoryData.map(product => {
                    return utilities.isEmptyOrNullString(product.cart_available)
                      ? product.recQty
                      : product.cart_available;
                  }),
                overrideRetails:Array(categoryData.length).fill(''),
            })

            for (let key in this.state.appliedFilters) {
                let keyvalue = isArray(this.state.appliedFilters[key]) ?
                  this.state.appliedFilters[key].join('~') : this.state.appliedFilters[key];
                let filterValue = keyvalue.split('~');
                filterValue.map(value => {
                  document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
                  return value;
                });
              }
        })
        .catch(err =>{
            console.log(err)
        })

}