import React from 'react';

const PreferenceCard = (props)=>{
    const{header,description,fieldname,valuelist, currentChoice}=props;
    return (
        <div className="prefereceSingle">
            <h3>{header}</h3>
            <p>{description}</p>
            <div className="prederenceCheck"> 
                {valuelist.map(item=>
                    <>
                        <input  onChange={props.onFNameChange} 
                            type="radio" 
                            checked = {typeof(item.val) !== 'number' ? item.val === currentChoice : parseInt(item.val) === parseInt(currentChoice)}
                            name={fieldname} value={item.val} />
                        <label> {item.label}</label>
                    </>
                )} 
            </div>
        </div> 
    );
}

export default PreferenceCard;
