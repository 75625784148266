import React from 'react';
import '../styles.css';
import { Loader, } from 'semantic-ui-react';
import InternalServerError from '../../ErrorPages/500_error_page';
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';
import DiscountCityOfferListing from './DiscountCityOfferListing';
import { connect } from 'react-redux'
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import history from '../../../history';

class DiscountCity extends React.Component {
    constructor(props) {
        super(props);
        const eventId = '';
        const event = props.reunionEvents && props.reunionEvents.find(event => {
            return parseInt(event.promo_nbr) === parseInt(eventId);
        })
        this.state = {
            eventId,
            searchby: "itemnbr",
            searchvalue: '',
            dealDetails: false,
            eventIdReunion: event ? event.relay_nbr : '',
            promoNmbr: event ? event.promo_nbr : '',
            promoName: event ? event.PrmoName : '',
            serverError: false,
            isLoading: false
        }
        this.searchParam = "itemnbr";
        this.searchValue = '';
    }

    handleSearchValueChange = (e) => {
        this.searchValue = e.target.value;
    }

    handleSearchParamChange = (e) => {
        this.searchParam = e.target.value
    }

    search = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                searchby: this.searchParam,
                searchValue: this.searchValue
            })
        }
    }
    showDealDetails = () => this.setState({ dealDetails: true });

    DialogClose = () => this.setState({ dealDetails: false });

    render() {
        const { searchby, searchValue } = this.state;
        const header = '';
        let breadcrumbPath = [
            { link: '/home', text: 'HOME' },
            { link: '/discountCity', text: 'DISCOUNT CITY' }
        ];

        return (
            this.state.serverError ?
                <InternalServerError />
                :
                <div className="page-wrap">
                    {this.state.isLoading && (
                        <div>
                            <Loader active />
                        </div>
                    )}

                    <div className='page-header'>
                        Discount City
                    </div>
                    <BreadcrumbComp path={breadcrumbPath} />
                    <DiscountCityOfferListing
                        setListType={this.setListType}
                        searchby={searchby}
                        searchvalue={searchValue}
                        isDiscountCity={header === "Discount City"}
                    />
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reunionEvents: state.ReunionReducer.events
    }
}

export default connect(mapStateToProps)(DiscountCity);
