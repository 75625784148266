import React from 'react'
import InternalServerError from '../ErrorPages/500_error_page';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Filter from '../common/Filter';
import { REUNION_OFFERS } from '../../urlConstants';
import { isArray } from 'util';
import utilities from '../../utils/utilities';
import { getReunionOfferings } from './actions'
import _ from 'lodash';
import history from '../../history';

class ReunionOfferListing extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            reunionEvents: [],
            clearFilters: false,
            appliedFilters: {},
            showFilters: (this.props.getprefer && this.props.getprefer.filterView) ? this.props.getprefer.filterView==='E'?true:false:window.innerWidth > 768?true:false,
            eventId: '',
            orderDeadline: '',
            loading:true
        }
        this.filterString = {};
    }

    componentDidMount() {
        this.getReunionEvents();        
    }

    componentWillReceiveProps(newProps){
        if(this.state.loading && newProps.loaded){
            this.setState({loading:false})
        }
        if(this.props.searchby!==newProps.searchby || this.props.searchvalue!==newProps.searchvalue){
            this.getReunionEvents(newProps);
        }
    }
    
    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };

    filterCallback = (isAdd, filterKey, filterValue) => {
        let appliedFilters = this.state.appliedFilters;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
            appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
        } else if (keyExists) {
            appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
                ? appliedFilters[filterKey]
                : appliedFilters[filterKey].split(',');
            let value = appliedFilters[filterKey];
            let index = value.indexOf(filterValue);
            if (index > -1) {
                value.splice(index, 1);
            } else {
                value.push(filterValue);
            }
            if (!value.length && keyExists) delete appliedFilters[filterKey];
            else appliedFilters[filterKey] = value;
        }
        this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
        this.setState({
            appliedFilters,
            clearFilters: false
        });
        this.getReunionEvents();
    };

    getReunionEvents = (newProps) => {
        const {searchby, searchvalue } = newProps?newProps:this.props;
        this.setState({ loading: true });
        let url = `${REUNION_OFFERS}/listevent`;              
        let requestPayLoad = this.filterString ? { ...this.filterString } : {};
        if(searchby && searchvalue){
            requestPayLoad={...requestPayLoad,
                                searchby,
                                searchvalue
                            }
        }
        this.props.getReunionOfferings(url,requestPayLoad);
    }
    showVendors = (e) =>{
        const eventId = e.target.id;
        this.props.setListType(1,{eventId})
        history.push('/reunionEventVendors/'+eventId)
    }
    render(){
        const { reunionEvents,filters } = this.props;
        const eventId = reunionEvents && reunionEvents.length>0? reunionEvents[0].relay_nbr :'';
        const deadline = reunionEvents && reunionEvents.length>0 ? utilities.getDateFromTimeStamp(reunionEvents[0].endOrderDate) :'';
        return (
            this.state.serverError ?
                <InternalServerError />
                :
                    
                <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
                    {this.state.loading && (
                        <div>
                            <Loader active />
                        </div>
                    )}
                    { filters && 
                        <Filter
                            clearFilters={this.state.clearFilters}
                            filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                            appliedFilters={this.state.appliedFilters}
                            toggleShowFilter={this.toggleShowFilter}
                            showFilters={this.state.showFilters}
                            filters={this.props.filters}
                            callback={this.filterCallback}
                            isNoProducts={this.state.isNoProducts}
                        />
                    }

                    <div className='page_details'>
                        <span>Event ID: {eventId}</span>
                        <span className="padding_left">Order Deadline: {deadline}</span>
                        <div className={window.innerWidth < 1024 ? 'ui stackable two column grid' : 'ui stackable four column grid'} id="reunion_items">
                            {reunionEvents && reunionEvents.map(event => {
                                const promo_name = event.promo_name.trim();
                                return (
                                    <div className='column promos'>
                                        <div className = "reunion_card">
                                            <span className='reunion_card_title'
                                                id = {event.promo_nbr}
                                                onClick={this.showVendors}>
                                                    {event.promo_nbr} - {promo_name}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return{
        reunionEvents : state.ReunionReducer.events,
        filters : state.ReunionReducer.filters,
        loaded : state.ReunionReducer.loaded,
        getprefer:state.preference.listData
    }
}

const mapDispatchToProps = dispatch =>{
    return Object.assign({dispatch},
        bindActionCreators({
            getReunionOfferings
        },dispatch)
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(ReunionOfferListing)