import React from 'react';
import { Modal,Checkbox } from 'semantic-ui-react';
import {connect} from 'react-redux';
import restUtils from '../../utils/restUtils';
import { bindActionCreators } from 'redux';
import {createMsWarehouseAction,orderTypes,addMsWarehouseAction,createMsImpulse,addMsImpulse,createMsEvent,addcartRE,createMsPlanogram,addMsPlanogram,msSpecislMonthly,updateQty,viewAllCarts,ADD_MANY_PRODUCTS,getCartInfo} from './actions';
import AddToCartModalMs from '../Cart/AddToCartModalMs';
import { msWarehouseCart,msAssortment,msMonthlyspecials,msPlanogram,createCartUrl,raAssortmentMs} from '../../urlConstants';
import _ from 'lodash';
import './multistore.css';
import { orderNames,infoTypes } from './constants';
import { nonStockMessage } from '../../views/common/constants'
import {validateProductQuantity} from '../common/productHandler'
import utilities from '../../utils/utilities';
class MultistoreToCartModal extends React.Component {
  constructor(props) {
  super(props);
    this.state={
      storeResults:this.props.childStoreList,
      orderedStoreResults : utilities.orderedChildStoreList(this.props.childStoreDetails, this.props.ssStore),
      cartName:'',
      tstmsg:'',
      selectedMS:this.props.childStoresSelected,
      isAddToCartModalOpen: false,
      upDatedMS:[],
      submit:{},
      raStoreQty:{},
      shipStoreQty:{},
      toggleSelect:false,
      multiStoreValid:[],
      msQuantities:[],
      msRAQuantities:{},
      msOverrides:[],
      msSubmitCart:[],
      msSubmitCartShip:[],
      msSubmitRACart:[],
      showErrorMsg:false
    }
    this.shipStoreQty = {};
    this.raStoreQty = {};
    this.filterReunionStore  = this.props.childStoreDetails ? utilities.getReunionOnlyStore(this.props.childStoreDetails) : []
  }
  componentDidMount(){
      this.updatedMs();
      if(this.props.shipOptions && _.isEmpty(this.raStoreQty)) {
        this.setState({upDatedMS:[]})
      }
      this.props.updateQty(false)
  }
  componentWillReceiveProps(nextProps){
    this.clearOverrides()
    let qtyAuto= parseInt(nextProps.flowType)===4 ?
    nextProps.quantitiesMs?.[nextProps.indexCheck][0] :
    nextProps.indexCheck || nextProps.indexCheck !==''?
    nextProps.quantitiesMs?.[nextProps.indexCheck] :
    nextProps.quantitiesMs
    ;
    const editOrder = nextProps.carts.filter((c)=> { 
      return c.cartId == nextProps.currentCartId 
    })[0]?.forEdit
    if(!this.props.flowType && editOrder === 'Y'){
      this.setState({orderedStoreResults: nextProps.childStoresSelected})
    }
    else {
      this.setState({orderedStoreResults:  utilities.orderedChildStoreList(nextProps.childStoreDetails, nextProps.ssStore)})
    }
    if (parseInt(nextProps.flowType) !==4 && nextProps.quantitiesMs && !qtyAuto){
      qtyAuto = nextProps.quantitiesMs;
    }
    let qtyVendor=nextProps.indexCheck || nextProps.indexCheck !=='' ? nextProps.quantitiesMsDrop?.[nextProps.indexCheck] : nextProps.quantitiesMsDrop;
    
    if(nextProps?.quantitiesMs && this.props.assortmentType !== 'RetailAssortments' && !this.props.flowType && _.isEmpty(this.shipStoreQty) && nextProps.tableType !=='favorites' && nextProps.tableType !== 'productdetails' && !nextProps.quantitiesMs[nextProps.indexCheck] > 0) {
      this.setState({msQuantities:[],msOverrides:[]})
      return     
    } 
    this.updateData(nextProps.product,nextProps.multiStoreDetails,nextProps.tableType, nextProps.multiStoreCartavailable,qtyAuto,qtyVendor,nextProps.retailAssortmentQty,nextProps.quantitiesMs,nextProps.indexCheck,nextProps.overrideRetail,nextProps.product?.multiStoreCartoverrideretail);
    let { upDatedMS } = this.state
    if(this.props.flowType && !_.isEmpty(this.shipStoreQty)) {
      Object.keys(this.shipStoreQty).map(key1=>{
        let selectedShipStore = this.shipStoreQty[key1]
        selectedShipStore && Object.values(selectedShipStore).every(key => key === '' || key === undefined) && (
          delete upDatedMS[key1]
        )
      })
      this.setState({upDatedMS})
    }
  }

  updateData=(product,multiStoreDetails,tableType,multiStoreCartavailable,qtyAuto,qtyVendor,retailAssortmentQty,isFavorQty,indexCheck,overrideRetail,multiStoreCartoverrideretail)=>{
    let{storeResults,upDatedMS,msQuantities}=this.state;
    let msShipDate = parseInt(this.props.flowType) ===  5 ? utilities.shipDateVendor(product) :utilities.shipDate(product)  ;
        Object.keys(storeResults).filter((key)=>{
          _.isEmpty(msShipDate) && !_.isEmpty(product?.multiStoreCartavailable) && Object.keys(product?.multiStoreCartavailable).map((key1)=>{
            if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(key1)){
                upDatedMS[key]=key;
                msQuantities[key]=product?.multiStoreCartavailable[key1];
            }
          });
          if(_.isEmpty(product?.multiStoreCartavailable) && _.isEmpty(msShipDate) && (tableType==='favorites'|| tableType==='productdetails') && isFavorQty!==''){
            !_.isEmpty(storeResults) && Object.keys(storeResults).map((key1)=>{
              if(upDatedMS[key1]){
                msQuantities[key1]=isFavorQty;
              }
              else if(upDatedMS[key1]){
                msQuantities[key1]=isFavorQty;
              }
             
           })
          }
          if(_.isEmpty(product?.multiStoreCartavailable) && _.isEmpty(msShipDate) &&  tableType!=='favorites' && tableType!=='productdetails' && isFavorQty?.[indexCheck] !==''){
            !_.isEmpty(storeResults) && Object.keys(storeResults).map((key1)=>{
              if(upDatedMS[key1]){
                msQuantities[key1]=qtyAuto;
              }
              else if(upDatedMS[key1]){
                msQuantities[key1]=isFavorQty;
              }
           })
          }

          if(_.isEmpty(multiStoreCartavailable) && !_.isEmpty(retailAssortmentQty) && retailAssortmentQty !==''){
            !_.isEmpty(storeResults) && Object.keys(storeResults).map((key1)=>{
              if(upDatedMS[key1]){
                let mappush=[];
                 retailAssortmentQty.map((keyMnth) =>{
                    mappush={...mappush,[keyMnth.month]:keyMnth.quantity || keyMnth.quantity!==0 ?keyMnth.quantity:'' } 
                })
                this.raStoreQty[key1]= mappush;
              }
             
           })
          }
        if(!_.isEmpty(msShipDate))
        {
          if(this.props.quantitiesMsDrop?.[this.props.indexCheck]?.length !==0){
            if(parseInt(this.props.flowType) === 5){
            !_.isEmpty(product?.MultiStoreShipOptions) && Object.keys(product?.MultiStoreShipOptions).map((key1)=>{
            let cartAvailable = product.MultiStoreShipOptions[key1];
            if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(cartAvailable.UserId)){
              var ship = Object.keys(msShipDate).filter(function(key) {return msShipDate[key] === cartAvailable.Month})[0];
              let msUpdateqty = this.shipStoreQty[key]; 
              let value = cartAvailable.ItemQty !== "0" ? cartAvailable.ItemQty : '';
              msUpdateqty = { ...msUpdateqty, [`${ship}`]: value};
              upDatedMS[key]=key;
              this.shipStoreQty= { ...this.shipStoreQty,[`${key}`]:msUpdateqty};
            }
          });
        if(_.isEmpty(product?.MultiStoreShipOptions)){
          var shipVendor = utilities.shipDateVendorAutoDrop(product,qtyVendor);
          !_.isEmpty(storeResults) && Object.keys(storeResults).map((key1)=>{
              !_.isEmpty(shipVendor) && Object.keys(shipVendor).some((key)=>shipVendor[key])
            let  qtyExist = Object.values(shipVendor).reduce((a, item) => a + item, 0)
            if((qtyExist > 1 && upDatedMS[key1])){
                this.shipStoreQty[key1]=shipVendor
            }
            else if(upDatedMS[key1]){
              this.shipStoreQty[key1]=shipVendor
            }
          })
        }
           }
         }
         if(parseInt(this.props.flowType) !== 5 && isFavorQty?.[this.props.indexCheck]?.length !==0){
          if(parseInt(this.props.flowType) === 1 || parseInt(this.props.flowType) === 3 || parseInt(this.props.flowType) === 4){
            !_.isEmpty(product?.multiStoreCartavailable) && Object.keys(product?.multiStoreCartavailable).map((key1)=>{
              if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(key1)){
                var ship = Object.keys(msShipDate).length > 0 && Object.keys(msShipDate)[0];
                let msUpdateqty = this.shipStoreQty[key]; 
                let value=product.multiStoreCartavailable[key1] 
                            && product.multiStoreCartavailable[key1] !== "0" ? product.multiStoreCartavailable[key1] : '';
                msUpdateqty = { ...msUpdateqty, [`${ship}`]: value};
                upDatedMS[key]=key;
                this.shipStoreQty= { ...this.shipStoreQty,[`${key}`]:msUpdateqty};
              }
            });
            !_.isEmpty(product?.multiStoreCartavailable2) && Object.keys(product?.multiStoreCartavailable2).map((key1)=>{
              if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(key1)){
                var ship = Object.keys(msShipDate).length > 1 && Object.keys(msShipDate)[1];
                let msUpdateqty = this.shipStoreQty[key]; 
                let value=product.multiStoreCartavailable2[key1] 
                            && product.multiStoreCartavailable2[key1] !== "0" ? product.multiStoreCartavailable2[key1] : '';
                msUpdateqty = { ...msUpdateqty, [`${ship}`]: value};
                upDatedMS[key]=key;
                this.shipStoreQty= { ...this.shipStoreQty,[`${key}`]:msUpdateqty};
              }
            });
            !_.isEmpty(product?.multiStoreCartavailable3) && Object.keys(product?.multiStoreCartavailable3).map((key1)=>{
              if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(key1)){
                var ship = Object.keys(msShipDate).length > 2 && Object.keys(msShipDate)[2];
                let msUpdateqty = this.shipStoreQty[key]; 
                let value=product.multiStoreCartavailable3[key1] 
                            && product.multiStoreCartavailable3[key1] !== "0" ? product.multiStoreCartavailable3[key1] : '';
                msUpdateqty = { ...msUpdateqty, [`${ship}`]: value};
                upDatedMS[key]=key;
                this.shipStoreQty= { ...this.shipStoreQty,[`${key}`]:msUpdateqty};
              }
            });
            !_.isEmpty(product?.multiStoreCartavailable4) && Object.keys(product?.multiStoreCartavailable4).map((key1)=>{
              if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(key1)){
                var ship = Object.keys(msShipDate).length > 3 && Object.keys(msShipDate)[3];
                let msUpdateqty = this.shipStoreQty[key]; 
                let value=product.multiStoreCartavailable4[key1] 
                            && product.multiStoreCartavailable4[key1] !== "0" ? product.multiStoreCartavailable4[key1] : '';
                msUpdateqty = { ...msUpdateqty, [`${ship}`]: value};
                upDatedMS[key]=key;
                this.shipStoreQty= { ...this.shipStoreQty,[`${key}`]:msUpdateqty};
              }
            });
            if(_.isEmpty(product?.multiStoreCartavailable) ||  _.isEmpty(product?.multiStoreCartavailable2)
           ||  _.isEmpty(product?.multiStoreCartavailable3) ||  _.isEmpty(product?.multiStoreCartavailable4)){
              var ship =  utilities.shipDateVendorAuto(product,qtyAuto);
              !_.isEmpty(storeResults) && Object.keys(storeResults).map((key1)=>{
                 !_.isEmpty(ship) && Object.keys(ship).some((key)=>ship[key])
                let  qtyExist = Object.values(ship).reduce((a, item) => a + item, 0);
                if((qtyExist > 1 && upDatedMS[key1])){
                  this.shipStoreQty[key1]=ship
                }
                else if(upDatedMS[key1]){
                  this.shipStoreQty[key1]=ship
                }
  
                })
            }
            }
          }
          if(this.props.tableType === 'Monthly Specials' || parseInt(this.props.flowType) === 3) {
            !_.isEmpty(this.state.selectedMS) && this.state.selectedMS.map(key=>{
              if(parseInt(this.props.flowType) === 3) {
                upDatedMS[key]=key
                }  
              this.shipStoreQty = {...this.shipStoreQty, [key]:{'shipdate1': qtyAuto[0],'shipdate2': qtyAuto[1],'shipdate3': qtyAuto[2],'shipdate4': qtyAuto[3]}}
            });
          }
        }
        !_.isEmpty(multiStoreCartavailable) && Object.keys(multiStoreCartavailable).map((key1)=>{
          let cartAvailable = multiStoreCartavailable[key1];
          if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(cartAvailable.userId)){
            var ship = cartAvailable.month;
            let msUpdateqty = this.raStoreQty[key]; 
            let value = cartAvailable.quantity !== "0" ? cartAvailable.quantity : '';
            msUpdateqty = { ...msUpdateqty, [`${ship}`]: value};
            upDatedMS[key]=key;
            this.raStoreQty= { ...this.raStoreQty,[`${key}`]:msUpdateqty};
          }
        });
        if(overrideRetail || !_.isEmpty(multiStoreCartoverrideretail)) {
          let { msOverrides } = this.state
          !_.isEmpty(multiStoreCartoverrideretail) && Object.keys(multiStoreCartoverrideretail).map((key1)=>{
            if(utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(key, 5)) === parseInt(key1)){
              let value = multiStoreCartoverrideretail[key1] && multiStoreCartoverrideretail[key1] !== '0.00' ? multiStoreCartoverrideretail[key1] : null;
              msOverrides = {...msOverrides, [`${key}`]: value,[this.props.ssStore] :overrideRetail};
              this.setState({ msOverrides}) 
            } 
          })
          if(_.isEmpty(multiStoreCartoverrideretail)) {
            msOverrides = {[this.props.ssStore] :overrideRetail};
            this.setState({ msOverrides}) 
          }        
        }
      });

  }

  updatedMs=()=>{
    let{upDatedMS,selectedMS,multiStoreValid,storeResults,msQuantities}=this.state;
    !_.isEmpty(selectedMS) && Object.keys(selectedMS).map(key=>{
        upDatedMS={...upDatedMS, [selectedMS[key]]:selectedMS[key]}
    });
    !_.isEmpty(storeResults) && Object.keys(storeResults).map(key=>{
      msQuantities={...msQuantities, [key]:''}
    });
    this.setState({
        upDatedMS,
        msQuantities
    });
  }

  openCartModal = (product, msSubmitCart) => {
    this.setState({
      productSelectedForModal: product,
      submit:msSubmitCart,
      isAddToCartModalOpen: true
    });
  };

  closeCartModal = () => {
    this.clearOverrides()
    this.clearQty()
    this.setState({ isAddToCartModalOpen: false });
  };
  
  clearOverrides=()=>{
    let{msOverrides,storeResults} =this.state;
    !_.isEmpty(storeResults) && Object.keys(storeResults).map((msStore)=>{
        msOverrides ={...msOverrides,[`${msStore}`]: ''}
   })
   this.setState({
    msOverrides
   })
  }

  clearQty=()=>{
    let{msQuantities,storeResults,raStoreQty,shipStoreQty,upDatedMS} =this.state;
    let msShipDate = parseInt(this.props.flowType) ===  5 ? utilities.shipDateVendor(this.props.product) :utilities.shipDate(this.props.product);
    !_.isEmpty(storeResults) && Object.keys(storeResults).map((msStore)=>{
      msQuantities ={...msQuantities,[`${msStore}`]: ''}
      if(this.props.RAMarketFlag === 'Y' ||  parseInt(this.props.flowType) ===  2 ){
        this.props.shipOptions && this.props.shipOptions.map((key)=>{
          let msUpdateqty = this.raStoreQty[msStore]; 
          msUpdateqty = { ...msUpdateqty, [`${key.month}`]:""};
          this.raStoreQty= { ...this.raStoreQty,[`${msStore}`]:msUpdateqty};
        })
      }
      if(this.props.tableType === 'Monthly Specials' ||  parseInt(this.props.flowType) ===  1 || parseInt(this.props.flowType) ===  3 || 
      parseInt(this.props.flowType) ===  5){
        !_.isEmpty(msShipDate) && Object.keys(msShipDate).map((key)=>{
      let msUpdateqtyStore = this.shipStoreQty[msStore]; 
      msUpdateqtyStore = { ...msUpdateqtyStore, [`${key}`]:""};
      this.shipStoreQty= { ...this.shipStoreQty,[`${msStore}`]:msUpdateqtyStore};
      })
    }
    })
  this.setState({
      msQuantities,
      upDatedMS: []
  })
  }

  clearAllQuantities = (e) => {
    this.clearQty();
  };

  handleCB=(e,cbID)=>{
    let{upDatedMS}=this.state;
    if (e.target.checked) {
        upDatedMS={...upDatedMS,[cbID]:cbID};
      }
      else{
        delete upDatedMS[cbID]
      } 
      this.setState({
        upDatedMS
    })
  }
  selectHideall=()=>{
    let{storeResults,upDatedMS,toggleSelect}=this.state;
    this.setState({
        toggleSelect:!toggleSelect
    },()=>{this.storeDetails();});   
  }
  
  storeDetails=()=>{
    let{storeResults,upDatedMS,toggleSelect}=this.state;
    !_.isEmpty(storeResults) && Object.keys(storeResults).map(newMs=>{
        if(toggleSelect){
            upDatedMS={...upDatedMS, [newMs]:newMs};
        }
        if(!toggleSelect){
            delete upDatedMS[newMs]
        }
    });
    this.setState({
        upDatedMS
    });
  }

  multistorehandlerRa=(e)=>{
    let{raStoreQty, upDatedMS}=this.state;
    const { dataset, value } = e.target;
    const fieldValue = parseFloat(value);
    let { month,index,storeid} = dataset;
    let msUpdateqty = this.raStoreQty[storeid]; 
    msUpdateqty = { ...msUpdateqty, [`${month}`]:fieldValue || ''};
    this.raStoreQty= { ...this.raStoreQty,[`${storeid}`]:msUpdateqty};
    if (fieldValue > 9999) {
      this.toastDelay('Quantity should not exceed 9999!', true)
      return;
    }
    let storeValues = this.raStoreQty[storeid]
    let resetVal =  Object.values(storeValues).every(key => key === '' || key=== undefined)
    if (value > 0) {
      upDatedMS={...upDatedMS,[storeid]:storeid};
    }
    else if(resetVal){
        delete upDatedMS[storeid]     
    }
    this.setState({
      raStoreQty,
      upDatedMS
    });
  }

  multistorehandlerShip=(e)=>{
    const { dataset, value } = e.target;
    let {upDatedMS} = this.state
    let shipStoreQtyChange = this.shipStoreQty;
    let {indexCheck,handleChangeValue }=this.props;
    const fieldValue = parseFloat(value);
    let { ship,index,storeid,shipdropship,field,qtyindex} = dataset;
    let msShipDate = parseInt(this.props.flowType) ===  5 && utilities.shipDateVendor(this.props.product);
    ship = parseInt(this.props.flowType) ===  5 ? 
    Object.keys(msShipDate).filter(function(key) {return msShipDate[key] === ship})[0] : ship;
    let msUpdateqty = shipStoreQtyChange[storeid]; 
    msUpdateqty = { ...msUpdateqty, [`${ship}`]:fieldValue || ''};
    shipStoreQtyChange= { ...shipStoreQtyChange,[`${storeid}`]:msUpdateqty};
    if (fieldValue > 9999) {
      this.toastDelay('Quantity should not exceed 9999!', true)
      return;
    }
    this.shipStoreQty = shipStoreQtyChange;
    let storeValues = this.shipStoreQty[storeid]
    let resetVal =  Object.values(storeValues).every(key => key === '' || key=== undefined)
      if (value > 0) {
        upDatedMS={...upDatedMS,[storeid]:storeid};
      }
      else if(resetVal){
          delete upDatedMS[storeid]     
      }
      if(parseInt(index) === 0  && handleChangeValue){
        handleChangeValue(e,indexCheck,field,qtyindex);
      }
      this.state.selectedMS.map(key=>{
        if(this.props.tableType === 'Monthly Specials' && field === 'shipDate' && value > 0 && (this.shipStoreQty[key]['shipdate1'] == '' || this.shipStoreQty[key]['shipdate1'] == undefined)) {
            delete upDatedMS[key]
        }
      })
    this.setState({
      shipStoreQty:shipStoreQtyChange,
      upDatedMS
    })
  }

  multistorehandler=(e)=>{
    let{msQuantities,msOverrides,upDatedMS}=this.state;
    let {indexCheck,handleChangeValue, handleFavItemChange }=this.props;
    const { dataset, value } = e.target;
    const fieldValue = parseFloat(value);
    const { index,field,storeid} = dataset;
    if (field==='qty' && value > 0) {
        upDatedMS={...upDatedMS,[storeid]:storeid};
    }
    else if(field==='qty' && !(value > 0)){
        delete upDatedMS[storeid]
    } 
    switch (field) {
        case 'qty':
            msQuantities[storeid] = fieldValue || '';
            msQuantities = {...msQuantities,[`${storeid}`]:fieldValue || ''};
          if (fieldValue <= 0) {
            this.toastDelay('Quantity should be positive number!', true)
            return;
          }
          if (fieldValue > 9999) {
            this.toastDelay('Quantity should not exceed 9999!', true)
            return;
          }
          if(parseInt(index) === 0) {
            if(handleChangeValue){
              handleChangeValue(e,indexCheck,field);
            }else if(handleFavItemChange) {
              handleFavItemChange(e)
            }
          }          
        break;
        case 'override':
        if (fieldValue <= 0) {
          this.toastDelay('Override Retail should be positive number!')

            return;
            }
            if (/^(\d{0,5}\.\d{0,2}|\d{1,5})$/.test(value)) {
            msOverrides[storeid] = value || '';
            msOverrides={...msOverrides,[`${storeid}`]:value || ''}
            } else {
            msOverrides[storeid] = fieldValue || '';
            msOverrides={...msOverrides,[`${storeid}`]:fieldValue || ''}
            }
            if(handleChangeValue){
              handleChangeValue(e,indexCheck,field);
            }else if(handleFavItemChange) {
              handleFavItemChange(e)
            }
          break;
      }
      this.state.selectedMS.map(key=>{
        if(field==='qty' && value > 0 && (msQuantities[key] == '' || msQuantities[key] == undefined)) {
            delete upDatedMS[key]
        }
      })
      this.setState({
        msQuantities,
        msOverrides,
        upDatedMS
    });
  }

  toastDelay = (centretoast) => {
    this.setState({ tstmsg: centretoast });
    setTimeout(() => {
      this.setState({ tstmsg: '' });
    }, 5000);
  }

  validateProductQuantitywithoutMarket = (qty,product)=> {
    let toastError = false;
    let toastMessage = '';
    if (!qty ) {
      toastMessage = 'Please enter a valid quantity';
      toastError = true;
    }
    if (toastError) {
      this.toastDelay(toastMessage)
    }
    return !toastError;
  };

  validateProductQuantity = (qty,product)=> {
    let toastError = false;
    let toastMessage = '';
    if (!qty ) {
      toastMessage = 'Please enter a valid quantity';
      toastError = true;
    }
    if ((product.S_Pack_Type === 'F' || product.sPackType === 'F') && (qty % product.S_Pack_Qty || qty % product.sPackQty)) {
      toastMessage = `Quantity should be multiple of ${( product.S_Pack_Qty || product.sPackQty )}!`;
      toastError = true;
    }
    if (product.maxQty && qty > product.maxQty) {
      toastMessage = `Maximum Order quantity is ${product.maxQty}!`;
      toastError = true;
    }
    if (toastError) {
      this.toastDelay(toastMessage)
    }
    return !toastError;
  };

  validateProductQuantityRAMS = (qty,product)=> {
    let toastError = false;
    let toastMessage = 'Please enter a valid quantity';
    let mapShip = false;
    toastError =  !qty || (_.isEmpty(qty));
    let qtyExist = !toastError && Object.keys(qty).some((key)=> qty[key] !== '' && !isNaN(qty[key]) && parseInt(qty[key]) !== 0)
    if(qtyExist){
      toastError = false;
    }else {
      toastError = true;
    }
    if(this.props.flowType ===  "1") {
      let validateQty = Object.values(qty).some((val) => val !== '' && !validateProductQuantity(product.sPackType, product.sPackQty, parseInt(val)))
      if(validateQty) {
           return
      }
    }
    let totalQty = 0
    !_.isEmpty(qty) && Object.keys(qty).forEach((key) => {
      totalQty += !isNaN(parseInt(qty[key])) ? parseInt(qty[key]) : 0;  
    }); 
    if(this.props.tableType === 'Monthly Specials') {
      if(product.maxQty && totalQty && totalQty > product.maxQty) {
        toastMessage = `Maximum Order quantity is ${product.maxQty}`
        toastError = true;
      }
      !toastError  && !_.isEmpty(qty) && Object.keys(qty).map((key)=>{ 
        if((product.S_Pack_Type === 'F' || product.sPackType === 'F') && (qty[key] % product.S_Pack_Qty || qty[key] % product.sPackQty)) {
          toastMessage = `Quantity should be multiple of ${( product.S_Pack_Qty || product.sPackQty )}!`;
          toastError = true;
        }
      })
    }
    !toastError  && !_.isEmpty(qty) && Object.keys(qty).map((key)=>{   
      if(this.props?.shoppingAreaMetaId === "7" && product.available === 0){
        toastMessage = "Product cannot be added";
        toastError = true;
      }else if(this.props?.shoppingAreaMetaId === "7" && qty[key] > product.available){
        toastMessage = "Quantity should be less than the available";
        toastError = true;
      }else if ((product && product.maxQtyItemRestriction && product.maxQtyItemRestriction > 0)){
        if((this.props?.shoppingAreaMetaId === "1" || this.props?.shoppingAreaMetaId === "5") && !product.isMultiples && totalQty > product.maxQtyItemRestriction) {
          toastMessage = "Maximum Order quantity is " + product.maxQtyItemRestriction;
          toastError = true;
        }else if(product.isMultiples && qty[key]% product.maxQtyItemRestriction!==0){
          toastMessage = "Quantity should be a multiple of " + product.maxQtyItemRestriction;
          toastError = true;
        }
        else if((this.props?.shoppingAreaMetaId !== "1" || this.props?.shoppingAreaMetaId !== "5") && product.maxQtyPerReunion > 0 && parseInt(qty[key]) > product.maxQtyPerReunion){
          toastMessage = "Maximum Order quantity is " + product.maxQtyPerReunion;
          toastError = true;
        }
      }
      else{
          mapShip = true;
        }
      })
    if (!mapShip && toastError) {
      this.toastDelay(toastMessage)
    }
    else{
      toastError=false;
    }
    return !toastError;
  };

  flagPromoShipdateRAWithoutMk=(msSubmitCart)=>{
    let{product}=this.props;
    if(!msSubmitCart || msSubmitCart.length === 0){
      this.promoFlag=true;
      this.toastDelay('Please Enter a Valid quantity')
    }
    msSubmitCart=msSubmitCart && msSubmitCart.map((qtyValid,index)=>{
      let qty=qtyValid.qty;
      if (
       !this.validateProductQuantitywithoutMarket(qty,product)
     ) {
      this.promoFlag=true;
      this.setState({loading:false});
     }
    });
  }

  flagPromo=(msSubmitCart)=>{
    let{product}=this.props;
    if(!msSubmitCart || msSubmitCart.length === 0){
      this.promoFlag=true;
      this.toastDelay('Please Enter a Valid quantity')
    }
    msSubmitCart=msSubmitCart && msSubmitCart.map((qtyValid,index)=>{
      let qty=qtyValid.qty;
      if (
       !this.validateProductQuantity(qty,product)
     ) {
      this.promoFlag=true;
      this.setState({loading:false});
     }
    });
  }


  flagPromoShipdateRA=(msShipRAsubmit)=>{
    let{product}=this.props;
    if(!msShipRAsubmit || msShipRAsubmit.length=== 0){
      this.promoFlag=true;
      this.toastDelay('Please Enter a Valid quantity')
    }
    msShipRAsubmit= msShipRAsubmit && msShipRAsubmit.map((qtyValid,index)=>{
      let qty=qtyValid.qty;
      if (
       !this.validateProductQuantityRAMS(qty,product)
     ) {
      this.promoFlag=true;
      this.setState({loading:false});
     }
    });
  }

  getCartName=(ShoppingCartInfoTypeId)=>{
  return ShoppingCartInfoTypeId === orderNames['Regular']?.infoType
      ? 'Default'
      : orderTypes[parseInt(ShoppingCartInfoTypeId)];
  }

  multiStoreSubmit=()=>{
    let{tableType,product}=this.props;
    let{msQuantities,msOverrides,upDatedMS,msSubmitCart}=this.state;
    this.promoFlag=false;
    let msShipDate = parseInt(this.props.flowType) ===  5 ? utilities.shipDateVendor(product) :utilities.shipDate(product);
    if(this.props.RAMarketFlag === 'Y'){
      this.addcartRA();
    }
    else if(this.props.assortmentType === 'RetailAssortments'){
      this.addcartRAWithoutmarket();
    }
    else if(parseInt(this.props.flowType) ===2){
      this.addcartRAFlowType();
    }
    else if(tableType === 'Monthly Specials' ||   parseInt(this.props.flowType) ===  1 || parseInt(this.props.flowType) === 3
    || parseInt(this.props.flowType) === 5){
      let{upDatedMS,msSubmitCartShip}=this.state;
      msSubmitCartShip =  !_.isEmpty(upDatedMS) && Object.keys(upDatedMS).map(key=>{
        let shipStoreQty = {};
        if(parseInt(this.props.flowType) === 5){
          !_.isEmpty(this.shipStoreQty) && this.shipStoreQty[upDatedMS[key]] && Object.keys(this.shipStoreQty[upDatedMS[key]]).map(shipQty => {
            if(this.shipStoreQty[upDatedMS[key]][shipQty] !== '')
            shipStoreQty = {...shipStoreQty ,  [msShipDate[shipQty]] : this.shipStoreQty[upDatedMS[key]][shipQty] };
          })
        }else if(!_.isEmpty(this.shipStoreQty)){
          shipStoreQty = this.shipStoreQty[upDatedMS[key]] ? this.shipStoreQty[upDatedMS[key]] :{};
        }
        return {'storeID':upDatedMS[key],'qty': shipStoreQty,'overRideretail':msOverrides[upDatedMS[key]]}
       });
       this.promoFlag=false;
      this.flagPromoShipdateRA(msSubmitCartShip)
      !this.promoFlag && this.mshandletSpecials(msSubmitCartShip)
    }

    else if(tableType === 'DOM'){
      msSubmitCart =  !_.isEmpty(upDatedMS) && Object.keys(upDatedMS).map(key=>{
        return {'storeID':upDatedMS[key],'qty':msQuantities[upDatedMS[key]],'overRideretail':msOverrides[upDatedMS[key]]}
       })
      this.promoFlag=false;
      this.flagPromo(msSubmitCart);
      !this.promoFlag && this.reventsCarthandler(msSubmitCart)
    }

    else if(this.props.assortmentType  === 'Planograms'){
      msSubmitCart =  !_.isEmpty(upDatedMS) && Object.keys(upDatedMS).map(key=>{
        return {'storeID':upDatedMS[key],'overRideretail':msOverrides[upDatedMS[key]]}
       })
      this.promoFlag=false;
      let { quantities,categoryData} = this.props;
      const products = categoryData.filter((product, index) => {
        return quantities[index] && utilities.isValidQuantity(quantities[index]);
      });
      let toastError = false;
        let toastMessage = '';
          quantities = quantities.filter(qty => qty && parseInt(qty) > 0);
          if(!msSubmitCart || msSubmitCart.length=== 0){
            this.promoFlag=true;
            toastError =true;
            toastMessage ='Please Enter a Valid quantity';
            if (toastError) {
              this.toastDelay(toastMessage)
            }
            return !toastError;
          }
          if (!quantities || quantities.length === 0) {
            this.promoFlag=true;
             toastError =true;
             toastMessage ='Please Enter a Valid quantity';
             
        if (toastError) {
          this.toastDelay(toastMessage)
        }
        return !toastError;
          }
        products?.length > 0 && products.map((product, index) => {
        if ((product.S_Pack_Type === 'F' || product.sPackType === 'F') && (quantities[index] % product.S_Pack_Qty || quantities[index] % product.sPackQty)) {
          this.promoFlag=false;
          toastMessage = `Quantity should be multiple of ${( product.S_Pack_Qty || product.sPackQty )}!`;
          toastError = true;
          if (toastError) {
            this.toastDelay(toastMessage)
          }
          return !toastError;
        }
        else if ((parseInt(product.maxQty) && parseInt(product.maxQty) > 0 && quantities[index] > parseInt(product.maxQty))) {
          this.promoFlag=false;
          toastMessage = `Maximum Order quantity is ${product.maxQty}!`;
          toastError = true;
          if (toastError) {
            this.toastDelay(toastMessage)
          }
          return !toastError; 
        }   
      });
      if(toastError) {
        return
      }
      let itemListMap = products.map((product, index) => {
        const qty = quantities[index];
        return {
          ItemNumber: product.Item_Number,
          userQuantity: qty,
          rdcNum: this.props.rdc,
          source: 'WH',
          userName: this.props.userName
        };
      });
      !this.promoFlag && this.planogramCarthandler(itemListMap,msSubmitCart);
    }
    else if(this.props.assortmentType === 'ImpulseMerchandising'){
      msSubmitCart =  !_.isEmpty(upDatedMS) && Object.keys(upDatedMS).map(key=>{
        return {'storeID':upDatedMS[key],'overRideretail':msOverrides[upDatedMS[key]]}
       })
        this.promoFlag=false;
        let { quantities,categoryData} = this.props;
        const products = categoryData.filter((product, index) => {
          return quantities[index] && utilities.isValidQuantity(quantities[index]);
        });
        let toastError = false;
        let toastMessage = '';
          quantities = quantities.filter(qty => qty && parseInt(qty) > 0);
          if(!msSubmitCart || msSubmitCart.length=== 0){
            this.promoFlag=true;
            toastError =true;
            toastMessage ='Please Enter a Valid quantity';
            if (toastError) {
              this.toastDelay(toastMessage)
            }
            return !toastError;
          }
          if (!quantities || quantities.length === 0) {
            this.promoFlag=true;
             toastError =true;
             toastMessage ='Please Enter a Valid quantity';
             
        if (toastError) {
          this.toastDelay(toastMessage)
        }
        return !toastError;
          }

          let itemList = products.map((product, index) => {
          if ((product.S_Pack_Type === 'F' || product.sPackType === 'F') && (quantities[index] % product.S_Pack_Qty || quantities[index] % product.sPackQty)) {
            this.promoFlag=true;
            toastMessage = `Quantity should be multiple of ${( product.S_Pack_Qty || product.sPackQty )}!`;
            toastError = true;
            if (toastError) {
              this.toastDelay(toastMessage)
            }
            return !toastError;
              
          }
          
    });
    let allOrdersData = {};
    let allOrdersToDownload = [];
    !this.promoFlag && msSubmitCart && msSubmitCart.map(msStore=>{
      products.map((product, index) => {
        const qty = quantities[index];
        allOrdersData= {
          UserId:utilities.getChildStoreUserId(this.props.multiStoreDetails,utilities.padWithZeros(msStore.storeID, 5)),
          ItemNumber: product.Item_Number,
          ItemQty: qty,
          OverrideRetail: msStore.overRideretail ? msStore.overRideretail :'',
          RDC: this.props.rdc,
          ItemSource: 'WH',
          TotalQtyInOrder: qty,
          userName: this.props.userName,
          MolUserName: this.props.userName,
          ShoppingCartInfoTypeId:orderNames["ImpulseMerchandising"].infoType,
        };
        allOrdersToDownload.push(allOrdersData);
      });
    })

    !this.promoFlag && this.impulseCarthandler(allOrdersToDownload,msSubmitCart);
  }
    else{
      msSubmitCart =  !_.isEmpty(upDatedMS) && Object.keys(upDatedMS).map(key=>{
        return {'storeID':upDatedMS[key],'qty':msQuantities[upDatedMS[key]],'overRideretail':msOverrides[upDatedMS[key]]}
       })
      this.flagPromo(msSubmitCart);
      if(parseInt(this.props.flowType) === 4){
        !this.promoFlag && this.mshandletSpecials(msSubmitCart)
      }
      else{
        !this.promoFlag && this.warehouseCarthandler(msSubmitCart)
      }
    
    }
  }

  planogramCarthandler=(itemListMap,msSubmitCart)=>{
    let{assortmentId}=this.props;
    let existingCart = this.props.carts.find(
      cart => cart.TypeId === orderNames['PLANOGRAM'].infoType
    );
    if (!existingCart) {
      const Cartname =this.getCartName(orderNames['PLANOGRAM']?.infoType);
      this.props.createMsPlanogram(Cartname,'',orderNames["PLANOGRAM"].infoType,msSubmitCart,itemListMap,assortmentId);
      this.resetFielval();
      this.props.closeMultistoreModal();
    } else {
      this.props.addMsPlanogram(msSubmitCart,existingCart.cartId,itemListMap,assortmentId);
      this.resetFielval();
      this.props.closeMultistoreModal();
    }
  }



  mshandletSpecials=(msSubmitCart)=>{
    let{multiStoreDetails,product,rdc,relayNbr,shoppingAreaMetaId} =this.props;
    this.props.msSpecislMonthly(msSubmitCart,product,rdc,this.props.flowType,this.props.shoppingAreaName,'',relayNbr,shoppingAreaMetaId);
      this.resetFielval();
      this.props.closeMultistoreModal();
  }

  raFlowtypeassprtment=(msSubmitCart)=>{
    let{multiStoreDetails,product,rdc,relayNbr,shoppingAreaMetaId} =this.props;
    this.props.msSpecislMonthly(msSubmitCart,product,rdc,this.props.flowType,this.props.shoppingAreaName,this.props.eventId,relayNbr,shoppingAreaMetaId);
      this.resetFielval();
      this.props.closeMultistoreModal();
  }

  addcartRAWithoutmarket=()=>{
    let{msSubmitCart,upDatedMS,msOverrides,msQuantities} = this.state;
    let{multiStoreDetails,rdc}=this.props;
    msSubmitCart =  !_.isEmpty(upDatedMS) && Object.keys(upDatedMS).map(key=>{
      return {'storeID':upDatedMS[key],'qty':msQuantities[upDatedMS[key]]}
     })
     if(this.props?.isCtbAssortment) {
      const {msQuantities} = this.state
      const values = Object.values(msQuantities).some(ele=> ele>1)
      if(values){
        utilities.showToast('All Quantity should be 1!')
        return
      }
      msSubmitCart =  !_.isEmpty(upDatedMS) && Object.keys(upDatedMS).map(key=>{
        return {'storeID':upDatedMS[key],'qty':1}
       })
     }
     this.promoFlag=false;
     this.flagPromoShipdateRAWithoutMk(msSubmitCart)
    let itemList = [];
      itemList = !this.promoFlag && msSubmitCart?.map(msStore => {
       return {
         storeId:msStore.storeID,
         UserId:utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(msStore.storeID, 5)),
         primaryWarehouseNbr:rdc,
         "assortment":{"number":this.props?.isCtbAssortment?parseInt(this.props?.serialNumber):this.props.assortmentId,"type":"LR"},
         "quantityList":[{"month":this.props?.isCtbAssortment? this.props?.ctbShipOpt:"","quantity":msStore.qty !== "" ? msStore.qty : 0}],
         MolUserName:this.props.userName,
         "userAffiliate":true,
         "multiStoreParentUserId":this.props.userId,
         ...(this.props?.isCtbAssortment ? {type:'CTB'}: null)
       };
     });
     utilities.setLoader(true);
     restUtils
     .postData(raAssortmentMs, itemList)
     .then(response => {
        utilities.setLoader(false);
        utilities.showToast('Product Added to Cart');
        this.props.closeMultistoreModal();
        this.resetFielval();
        const {
          shoppingCartId: cartId,
          shoppingCartName: cartName,
          assortmentsInCart,
        } = response.data;
        this.props.getCartInfo(cartId)
        this.props.dispatch({
          type: ADD_MANY_PRODUCTS,
          payload: {
            cartId,
            cartName,
            items: assortmentsInCart.map((item) => {
              return {
                ItemNumber: item.number,
                qty: item.userQty,
                item_nbr: item.number,
                itemQty: item.userQty,
              };
            }),
          },
        });
     }).catch(error=>{
        utilities.setLoader(false);
     });
  }

  addcartRAFlowType=(msSubmitCart)=>{
    let{upDatedMS,msSubmitRACart}=this.state;
    let{multiStoreDetails,product,rdc} =this.props;
    let msSubmitCardItem = [];
    msSubmitRACart = !_.isEmpty(upDatedMS) &&  Object.keys(upDatedMS).map(key=>{
      if(this.raStoreQty[upDatedMS[key]]){
        msSubmitCardItem.push({'storeID':upDatedMS[key],'qty':this.raStoreQty[upDatedMS[key]]});
      }
        return msSubmitCardItem;
     })
    this.promoFlag=false;
    this.flagPromoShipdateRA(msSubmitCardItem);
    !this.promoFlag && this.raFlowtypeassprtment(msSubmitCardItem)
  }

  impulseCarthandler=(itemListMap,msSubmitCart)=>{
    let existingCart = this.props.carts.find(
      cart => cart.TypeId === orderNames['ImpulseMerchandising'].infoType
    );
    if (!existingCart) {
      const Cartname =this.getCartName(orderNames['ImpulseMerchandising']?.infoType);
      this.props.createMsImpulse(Cartname,'',orderNames["ImpulseMerchandising"].infoType,msSubmitCart,itemListMap,this.props.rdc);
      this.resetFielval();
      this.props.closeMultistoreModal();
    } else {
      this.props.addMsImpulse(msSubmitCart,existingCart.cartId,itemListMap,this.props.rdc);
      this.resetFielval();
      this.props.closeMultistoreModal();
    }
  }

  reventsCarthandler=(msSubmitCart)=>{
    let{product}=this.props;
    let existingCart = this.props.carts.find(
      cart => cart.TypeId === orderNames['DOM'].infoType && 'Promo '+product.promo_nbr === cart.cartName
    );
    if (!existingCart) {
      const Cartname ='Promo '+product.promo_nbr;
      this.props.createMsEvent(Cartname,'',orderNames["DOM"].infoType,msSubmitCart,product,this.props.rdc);
      this.resetFielval();
      this.props.closeMultistoreModal();
    } else {
      this.props.addcartRE(msSubmitCart,existingCart.cartId,product,this.props.rdc);
      this.resetFielval();
      this.props.closeMultistoreModal();
    }
  }

  warehouseCarthandler=(msSubmitCart)=>{
    let{tableType,product,flowType}=this.props;
    const isAssortmentListing =
    this.props.RAMarketFlag === 'Y'||
    tableType === 'Planograms' ||
    tableType === 'DOM' ||
    tableType === 'ImpulseMerchandising' ||
    tableType === 'Reunion' ||
    tableType === 'Monthly Specials'||
    flowType ===  '1';
    if (
      !isAssortmentListing &&
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length > 0
    ) {
      !this.promoFlag && this.props.closeMultistoreModal();
       !this.promoFlag && this.openCartModal(product,msSubmitCart);
    } else if (
      !isAssortmentListing &&
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length === 0
    ) {
     this.props.createMsWarehouseAction('Default','',orderNames["REGULAR"].infoType,msSubmitCart,product,this.props.rdc);
     this.resetFielval();
     this.props.closeMultistoreModal();
    }
    else{
      const stockStatus = product.whStockStatusMessage || product.StockStatusMessage
      const tableTypeStatus = ['productdetails', 'favorites' , 'default', 'newitems', 'discontinued', 'closeoutitems' ]
      if(this.props.tableType && tableTypeStatus.includes(this.props.tableType.toLowerCase()) && (stockStatus && stockStatus.toLowerCase() === 'not stocked')){
        utilities.showToast(nonStockMessage, true);
        return;
      } else {
        const cartId = this.props.currentCartId;
        const currentCart =  this.props.carts && this.props.carts.find(cart => parseInt(cart.cartId) === parseInt(cartId));
        const parentId = currentCart?.parentId && parseInt(currentCart?.parentId)>0?currentCart.parentId:cartId;
        this.props.addMsWarehouseAction(msSubmitCart,cartId,product,this.props.rdc);
        this.resetFielval();
        this.props.closeMultistoreModal();
      }
    }
  }
  
  resetFielval=()=>{
    let resetUpdateMs = [];
    let{childStoresSelected} = this.props;
    this.clearQty();
    this.clearOverrides();
    !_.isEmpty(childStoresSelected) && Object.keys(childStoresSelected).map(key=>{
      resetUpdateMs={...resetUpdateMs, [childStoresSelected[key]]:childStoresSelected[key]}
    });
    this.setState({
        upDatedMS:resetUpdateMs
    });
    }


  addcartRA=()=>{
    let{upDatedMS,msSubmitRACart}=this.state;
    let{multiStoreDetails,product,rdc} =this.props;
    msSubmitRACart = !_.isEmpty(upDatedMS) &&  Object.keys(upDatedMS).map(key=>{
      return {'storeID':upDatedMS[key],'qty':this.raStoreQty[upDatedMS[key]]}
     })
    this.promoFlag=false;
    this.flagPromoShipdateRA(msSubmitRACart);
    let itemList = [];
      itemList = !this.promoFlag && msSubmitRACart?.map(msStore => {
        let msMonthQty = !_.isEmpty(msStore?.qty) && Object.keys(msStore?.qty).map(key=>{
          return {"month":key,"quantity":msStore.qty[key] !== "" ? msStore.qty[key] : 0}
        })
       return {
         storeId:msStore.storeID,
         rdcNbr:rdc,
         UserId:utilities.getChildStoreUserId(multiStoreDetails,utilities.padWithZeros(msStore.storeID, 5)),
         primaryWarehouseNbr:rdc,
         "assortment":{"number":this.props.assortmentId,"type":"LR"},
         "quantityList":msMonthQty,
         MolUserName:this.props.userName,
         "userAffiliate":true
       };
     });
     utilities.setLoader(true);
     !this.promoFlag &&  restUtils
     .postData(msAssortment, itemList)
     .then(response => {
      utilities.setLoader(false);
       utilities.showToast('Product Added to Cart');
       this.props.closeMultistoreModal();
       this.resetFielval();
     }).catch(error=>{
      utilities.setLoader(false);
    });
  }

  msClose=()=>{
    this.props.closeMultistoreModal();
    this.resetFielval();
  }

  render() {
  const {isMultistoreModalOpen, closeMultistoreModal,product,assortmentType,shoppingAreaMetaId,
    assortmentId,assortmentOrder,tableType,RAMarketFlag,shipOptions,multiStoreDetails,addToCar,quantities,categoryData} = this.props;
  let{msQuantities,storeResults,upDatedMS,submit}=this.state;
  let msShipDate = parseInt(this.props.flowType) ===  5 ? utilities.shipDateVendor(product) :utilities.shipDate(product)  ;
  let { assortment={}} = this.props.RaStore;
  let impPlanogram=this.props.assortmentType==="ImpulseMerchandising"||this.props.assortmentType==="Planograms";
  let  products = this.props.categoryData && this.props.categoryData.filter((product, index) => {
  return quantities[index] && utilities.isValidQuantity(quantities[index]);
});
  return (
    <>
    <AddToCartModalMs
    isAddtoCartModalopen={this.state.isAddToCartModalOpen}
    closeCartModal={this.closeCartModal}
    product={product}
    msSubmitCart={submit}
    index={this.state.indexForModal}
    shoppingAreaMetaId={shoppingAreaMetaId}
  />
    <Modal closeIcon
      className='add-to-cart-modal multistore-modal'
      onClose = {this.msClose}
      closeOnDimmerClick={false}
      open={isMultistoreModalOpen}>
    <Modal.Header className="multistore-main">
    <div className='multistore-head'>
        <h3>Multi-Store Select</h3>
        <div className="item-spec">
          {this.props.assortmentType==="Planograms"?
          <>
            <span>{this.props.PlanogramStore?.planogram?.planogramSubName}</span><br/>
            <span> Adding {products.length} items</span>
          </>
                      :
          this.props.assortmentType==="ImpulseMerchandising"?
          <>
             <span>{this.props.DisplayName}</span><br/>
            <span> Adding {products.length} items</span>
          </>
          :
          <>       
          <span> Item # {parseInt(this.props.flowType) ===  5 || parseInt(this.props.flowType) ===  4 
          || parseInt(this.props.flowType) ===  3 ||
          parseInt(this.props.flowType) ===  1||
          parseInt(this.props.flowType) ===  2?
          product?.palletNbr || product?.itemNbr || product?.asstmtNbr:this.props.RAMarketFlag ==='Y' ? 
          assortment?.modelNbr :product && (product.Item_Number || product?.pricing?.ItemNumber)} </span>
          <span className="product-description"> {this.props.RAMarketFlag ==='Y' ? 
          assortment?.description : product && (product.Product_Name || product?.shortDescription)}</span>
          </>
          }

        </div>
        <div className="multihead-action">
            {impPlanogram && <span><a  onClick={this.selectHideall}>Select/Clear All</a></span>}        
            {!impPlanogram && 
            <>
            {parseInt(this.props.flowType) !==  5 && RAMarketFlag !== 'Y' && parseInt(this.props.flowType) !== 2 &&
               tableType !== 'Monthly Specials' && parseInt(this.props.flowType) !==  1 && parseInt(this.props.flowType) !== 4 &&
               parseInt(this.props.flowType) !== 3 &&
            <span><a onClick={this.clearOverrides}>Clear Override</a></span>}
            <span><a onClick={this.clearAllQuantities}>Clear Quantity</a></span>
            </>
            }
        </div>
    </div>

    </Modal.Header>
      <Modal.Content className='multistore-content'>
        <Modal.Description className='t-center multistore-desc'>
            <div className='multistore-section'>
            {!_.isEmpty(this.state.orderedStoreResults) &&  this.state.orderedStoreResults.map((key,index)=>{
            if(this.filterReunionStore.includes(parseInt(key)))
            return(
            <div className="multistore-selection">
            <div className="store-details">
              {impPlanogram && 
              <input type="checkbox"
              checked={upDatedMS[key] === key}
              onClick={(e)=>this.handleCB(e,key)}
              style={{ cursor: 'pointer' }}
              className='compare_checkbox'/>}
              <span>{`${this.props.childStoreDetails[key].storeID} - ${this.props.childStoreDetails[key].storeName}`}</span>
            </div>
            {!impPlanogram && 
            <div className="field-store">
              {RAMarketFlag === 'Y' || parseInt(this.props.flowType)== 2?
                <div className="quantity-store">
                {shipOptions && shipOptions.map((mnth,i)=>(
                  <>
                  <span className='rs-qtylist'>
                  <label>{mnth.month}</label>
                  <input 
                      onChange={this.multistorehandlerRa} 
                      data-index={index} 
                      data-month={mnth.month} 
                      data-storeid ={key} 
                      data-field={'raQty'} 
                      value={this.raStoreQty?.[key]?.[mnth.month]} 
                      type="text"
                      autoFocus = {i === 0 && index === 0 ?true :false}
                  />
                  </span>
                  </>
                  ))}
                  </div>   
                :
                tableType === 'Monthly Specials' || parseInt(this.props.flowType) ===  1 || parseInt(this.props.flowType) ===  3
                || parseInt(this.props.flowType) ===  5?
                <div className="quantity-store">
                {!_.isEmpty(msShipDate) && Object.keys(msShipDate).map((ship,i)=>(                                
                  <>                    
                  <span className={parseInt(this.props.flowType) ===  5 ? 'rs-qtylist':'ms-qtylist'}>
                  <label>{msShipDate[ship]}</label>
                  <input 
                      onChange={this.multistorehandlerShip} 
                      data-index={index} 
                      data-ship={parseInt(this.props.flowType) === 5 ? msShipDate[ship] : ship} 
                      data-storeid ={key} 
                      data-field={'shipDate'} 
                      data-qtyindex={i} 
                      value={this.shipStoreQty?.[key]?.[ship]} 
                      type="text"
                      autoFocus = {i ===0 && index === 0 ?true :false}
                  />
                  </span>
                  </>
                ))}
                  </div>  
                  :
                parseInt(this.props.flowType) ===  4?
                <span className="quantity-store">
                <label>Quantity</label>
                <input 
                    onChange={this.multistorehandler} 
                    data-index={index} 
                    data-storeid ={key} 
                    data-field={'qty'} 
                    value={this.state.msQuantities[key]} 
                    type="text"
                    autoFocus = {index === 0 ?true :false}
                />
              </span>
              :
                <span className="quantity-store">
                  <label>Quantity</label>
                  <input 
                      onChange={this.multistorehandler} 
                      data-index={index} 
                      data-storeid ={key} 
                      data-field={'qty'} 
                      value={this.state.msQuantities[key]} 
                      type="text"
                      autoFocus = {index === 0 ?true :false}
                  />
              </span>
               }
               {parseInt(this.props.flowType) !==  5 && RAMarketFlag !== 'Y' && parseInt(this.props.flowType) !== 2 &&
              parseInt(this.props.flowType) !==  1 && parseInt(this.props.flowType) !==  4 &&
               parseInt(this.props.flowType) !== 3 && this.props.assortmentType !== 'RetailAssortments' &&
                <span className="override-store">
                    <label>Override Retail</label>
                    <input 
                      onChange={this.multistorehandler} 
                      data-index={index} 
                      data-storeid ={key} 
                      data-field={'override'} 
                      value={this.state.msOverrides[key]} 
                      type="text"
                    />
                </span>
               }
            </div>
            }
            </div>
            )})}
            </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions id='multistore-action'>
      {!_.isEmpty(this.filterReunionStore) ?
        <button className="red ui button" onClick={this.multiStoreSubmit}>
            Submit
        </button>
        :''
      }
    </Modal.Actions>
    <div id="snackbar-ms" className={this.state.tstmsg && `show`} >{this.state.tstmsg}</div>
    </Modal>
    </>
  )}
}

const mapStateToProps = (state) => {
const { SessionReducer } = state;
const { userName,multiStoreDetails,childStoresSelected } = SessionReducer;
const carts = state.cart.orders.allCarts;
  return {
    rdc: state.SessionReducer.rdc,
    userId:state.SessionReducer.UserId,
    ssStore:state.SessionReducer.storeId,
    carts,
    currentCartType: state?.cart?.orders?.currentCart?.cartInfoName,
    whCarts: carts && carts.filter(cart => cart.cartInfoName === 'REGULAR'),
    userName,
    multiStoreDetails,
    childStoreList:multiStoreDetails?.childStoreList,
    currentCartId: state?.cart?.orders?.currentCart?.cartId,
    childStoresSelected,
    RaStore:state.RaReducer,
    PlanogramStore: state.PlanogramReducer,
    childStoreDetails: multiStoreDetails?.childStoreDetails,
  };
}

const mapDispatchToProps=(dispatch)=>{
  return Object.assign({dispatch},bindActionCreators({createMsWarehouseAction,msSpecislMonthly,addMsWarehouseAction,createMsImpulse,addMsImpulse,createMsEvent,addcartRE,createMsPlanogram,addMsPlanogram,updateQty,viewAllCarts,getCartInfo},dispatch))
}

export default connect(mapStateToProps,mapDispatchToProps)(MultistoreToCartModal);