import React, { Component } from 'react';
import ProductListingGrid from '../common/ProductListingGrid';
import restUtils from '../../utils/restUtils';
import { Responsive } from 'semantic-ui-react'
import ProductListing from '../common/ProductListing';
import { handleChange, 
        addToCart,
        validateProductQuantity,
        hideToastMessage } from '../common/productHandler';
import classnames from 'classnames';
import { MULTIPLE_ITEMS_DETAILS } from '../../urlConstants';
import { 
  addProduct, 
  createNAddCart,
  chooseCart } from '../Cart/actions';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CompetitorPrices from '../common/CompetitorPricing';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class ProductScandetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: [],
      quantities: [],
      overrideRetails: [],
      tvMatchFound:false,
      gridView:window.innerWidth<960?true:false,
      competitorsShow:false,
      isExactMatch:false
    };
    this.handleChange = handleChange.bind(this);
    this.addToCart = addToCart.bind(this);
    this.validateProductQuantity = validateProductQuantity.bind(this);
    this.hideToastMessage = hideToastMessage.bind(this);
  }

  toggleViewType=(e)=>{
    this.setState({gridView:e.target.id === 'grid_icon'})
  }

  componentWillReceiveProps(newProps){
    if(newProps.productsList!==this.props.productsList){
      this.fetchProductDetails();
    }
  }

  componentDidUpdate() {
    // after 3 sec hide toast message
    if (this.state.toastMessage) {
      this.hideToastMessage();
    }
  }
  componentDidMount(){
    this.fetchProductDetails();

  }
  fetchProductDetails = ()=>{
    const { productsList } = this.props;
    if(!productsList){
      return;
    }
    let isExactMatch = false
    let categoryData =[]
    const truevalueProducts = productsList.filter( function (product) {
      return !product.competitor_name || product.competitor_name === "TrueValue"
    });
    if(!truevalueProducts || truevalueProducts.length === 0){
      categoryData = productsList.map(product => {
            return {...product,
                        Item_Number:product.item_id,
                        Vendor_Name:product.vendor_name,
                        Image_File_Name:product.item_main_image_url,
                        Long_Description:product.item_desc_cleansed
                      }})
      this.setState({
        categoryData,
        isExactMatch,
        tvMatchFound:false
      });
      return;
    }
    if(truevalueProducts.find(({competitor_name}) => competitor_name === 'TrueValue')){
      isExactMatch = true;
    }
    let itemIds = truevalueProducts.map(({sku})=>sku);
    restUtils
      .getData(MULTIPLE_ITEMS_DETAILS + itemIds.join(','))
      .then(response=>{
        const categoryData = response.data;
        const isNoProducts = (!response.data || response.data.length === 0);
        this.setState({
          isNoProducts,
          categoryData,
          quantities:Array(categoryData.length).fill(''),
          overrideRetails:Array(categoryData.length).fill(''),
          tvMatchFound:true,
          isExactMatch,
          toastMessage:'',
          toastError:false
        })
      })
  }

  render() {
    const {
      quantities,overrideRetails,categoryData,isNoProducts,tvMatchFound,toastError,toastMessage
    } = this.state;
    
    return (
      <div className="page-wrap">
       {isNoProducts ?
          <div className='no_prdcts_display'>
            <h1>No Products to Display!</h1>
          </div>
          :
          <>
            <Responsive minWidth={1024}>
              <div id='icon_align'>
                <i className='list icon cursor_p'
                    id='list_icon'
                    onClick={this.toggleViewType}></i>{' '} 
                |
                <i className='th icon cursor_p'
                    id='grid_icon'
                    onClick={this.toggleViewType}></i>
              </div>
            </Responsive>
            {this.state.isExactMatch &&
              <>
                <CompetitorPrices
                  item_upc={categoryData[0].UpcNum}
                  pricing={categoryData[0].Srp_Cost}
                />
              </>
            }
            {this.state.gridView?
              <ProductListingGrid
                viewType={this.props.viewType}
                tableType={tvMatchFound?"tvProductScan":"competitorProducts"}
                categoryData={categoryData}
                quantities={quantities}
                overrideRetails={overrideRetails}
                handleChange = {this.handleChange}
                addToCart = {this.addToCart}
                validateProductQuantity = {this.validateProductQuantity}
              />
              :
              <ProductListing 
                viewType={this.props.viewType}
                tableType={tvMatchFound?"tvProductScan":"competitorProducts"}
                categoryData={categoryData}
                quantities={quantities}
                overrideRetails={overrideRetails}
                handleChange = {this.handleChange}
                addToCart = {this.addToCart}
                validateProductQuantity = {this.validateProductQuantity}
              />
            }
            <div id='snackbar'className={classnames({ red: toastError, show: toastMessage })}>
              {toastMessage}
            </div>
          </>
       }
      </div>
    );
  }
}

function mapStateToProps(state){
  const viewTypeStorage = localStorage.getItem('viewType');
  return{
    currentCartId: state.cart.orders.currentCart.cartId,
    getprefer:state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW
  }
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        addProduct, 
        createNAddCart,
        chooseCart,
      },
      dispatch
    )
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(ProductScandetails);
