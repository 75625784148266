import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Loader, Confirm, Pagination, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import "./CreditCardPaymentOptions.css";
import BreadcrumbComp from '../../components/BreadcrumbComp';

class CreditCardPaymentOption extends Component {
    render() {
        return(
            <div className='page-wrap cardDetails'> 
                <div className='page-header displayInline'> 
                        Credit Card Payment Options
                </div>                
                <Grid stackable className='payment-content'>
                   <Grid.Column>
                        <p>Dear True Value Customer:</p>
                        <p>True Value Company is offering a convenient way to pay your semi-monthly statement with the introduction of credit card acceptance. Like many other businesses trying to offer customers maximum payment flexibility while maintaining competitive pricing, we have elected to outsource our credit card payment processing.</p>
                        <p>We teamed up with CardX, a third-party vendor, to provide a credit card payment solution that will be available to you beginning immediately. CardX is a large payment vendor subject to the highest level of security protocols. Businesses that use CardX include medical offices, golf courses, equipment dealers, banks, insurance companies, and more.</p>
                        <p>If you choose to pay by credit card, CardX will automatically apply an industry standard 3% credit card fee to your credit card payment. This fee goes directly to CardX, and True Value Company does not receive any portion of the fee. If you do not want to take advantage of the CardX platform, you are welcome to continue with these other payment options which have no extra cost:</p>
                        <ul>
                            <li>Electronic Funds Transfer (EFT) <a target="_blank" href="https://aem.membersonline.com/content/MOL/statements-and-claims/electronic-funds-transfer.html">available here</a></li>
                            <li>
                            Mail check along with remittance advice to your assigned lockbox
                            </li>
                        </ul>
                        <p>
                        Although True Value Company highly recommends using EFT, if you choose to pay by credit card, please email your remittance detail to <a href="mailto:AR.Processing@TrueValue.com">AR.Processing@TrueValue.com</a> to ensure a speedy and accurate application of the payment. 
                        </p>

                        <p>The link to pay by credit card is <a target="_blank" href="https://urldefense.com/v3/__https:/cardx.com/pay-truevalue__;!!K7NL3gSZyw!GDw6qHVV3TOxo1IuQvRE-pQjeS6nbLG6i7FICM-UPOzoF6EA3WjUyR_pdKJrUki58kwj$">https://cardx.com/pay-truevalue</a></p>
                        <p>We look forward to continuing to provide you the highest level of service. If you have questions, please contact Accounts Receivable at <a href="mailto:AR.Processing@TrueValue.com">AR.Processing@TrueValue.com</a>.</p>
                    </Grid.Column>             
                </Grid>       
            </div>
        )
    }

}

export default CreditCardPaymentOption;