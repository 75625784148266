import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import restUtils from '../../utils/restUtils';
import _ from 'lodash';
import { Tab } from 'semantic-ui-react'
import './styles.css'
import utilities from '../../utils/utilities';
class TabContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          data:props.data
        }
    }
componentDidMount(){
    console.log(this.props)
}
componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data})
}
    render() {
        return this.state.data.posts.map(tab=>{
            return (
              <Tab.Pane key={tab.post_id} className="retailerTabContent">
                <h4>{tab.title}</h4>
                <span>{tab.date}</span>
                <div dangerouslySetInnerHTML={{__html: tab.content}}></div> 
                <a className='linkSlugBtn'>post_slug</a>
              </Tab.Pane>
            )
          })
         
        }
}
const mapDispatchToProps = (dispatch)=>{ 
    return Object.assign({dispatch},
      bindActionCreators({},dispatch)) 
  } 
  const mapStateToProps = (state) => { 
    return {

    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(TabContent);