import 'whatwg-fetch';
import {VENDOR_URL, VENDOR_DETAILS_URL } from '../../urlConstants';
import restUtils from '../../utils/restUtils';

export const REQUEST_VENDORS = 'REQUEST_VENDORS';
export const RECEIVE_VENDORS = 'RECEIVE_VENDORS';
export const RECEIVE_VENDOR = 'RECEIVE_VENDOR';

export const requestVendors = () => ({
  type: REQUEST_VENDORS
});

export const receiveVendor = vendorDetails => ({
  type: RECEIVE_VENDOR,
  vendorDetails
});

export const receiveVendors = (vendors, key) => ({
  type: RECEIVE_VENDORS,
  vendors,
  key
});

export const fetchVendors = searchKey => (dispatch,getState) => {
  dispatch(requestVendors());
  if (!searchKey) return;
  let url = `${VENDOR_URL}${searchKey.toLowerCase()}`;
  const searchKeyId = searchKey[0].toUpperCase();
  const letterNumber = /^[0-9]+$/;
  const searchKeyValue  = searchKeyId.match(letterNumber) ? "0" : searchKey;
  let grpId = getState().SessionReducer.address ? getState().SessionReducer.address.urlId : '';
  if(grpId){
    url=url+'&GroupURLId='+grpId
  }
  return restUtils
    .getData(url)
    .then(response => {
      if(response && response.data){
        dispatch(receiveVendors(response.data, searchKeyValue));
      }
    }).catch(() => {
      dispatch(receiveVendors([]));
    });
};

export const fetchVendorById = vendorId => dispatch => {
  if (vendorId) {
    const url = `${VENDOR_DETAILS_URL}?vendorId=${vendorId}`;
    return restUtils
      .getData(url)
      .then(response =>{
        if(response && response.data){
          dispatch(receiveVendor(response.data));
        }
      })
      .catch(() => {
        dispatch(receiveVendor([]));
      });
  }
  return;
};
