import {
  ADD_TO_CATALOG,
  REMOVE_FROM_CATALOG,
  CLEAR_CATALOG,
  ADD_TO_CATALOG_SUCCESS,
  SELECT_DEPARTMENT_CATALOG,
  SET_FILTER_VALUE,
  STORE_CATALOG,
  STORE_CATALOG_SUCCESS,
  STORE_CATALOG_FAIL,
  GET_CATALOGS_SAVED,
  GET_CATALOGS_SAVED_SUCCESS,
  GET_CATALOGS_SAVED_FAIL,
  DELETE_CATALOG,
  ADD_TO_CATALOG_FAIL
} from '../actionTypes';

const initialState = {
  catalog: [],
  catalogItems: [],
  catalogItemDescription: null,
  isLoading: false,
  error: null,
  selectedDepartmentCatalog: null,
  filters: {
    warehouse: 'my-warehouse',
    pageSize: 100,
    memberCost: 'Show',
    suggestedRetail: 'Show'
  },
  isCatalogsLoading: false,
  savedCatalogs: [],
}

const catalogReducer = (state = initialState, action) => {
  switch(action.type) {
    case ADD_TO_CATALOG:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case ADD_TO_CATALOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        catalogItems: action.payload.items,
        catalogItemDescription: action.payload.itemDescription,
        catalog: action.payload.catalog ? [...state.catalog, action.payload.catalog] : [...state.catalog],
        error: null
      }
    case ADD_TO_CATALOG_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case REMOVE_FROM_CATALOG:
      return {
        ...state,
        catalog: state.catalog.filter((d) => d.id !== action.payload.departmentId),
        catalogItems: action.payload.newItems,
        catalogItemDescription: action.payload.newItems.length === 0 ? null : state.catalogItemDescription,
      }
    case SELECT_DEPARTMENT_CATALOG:
      return {
        ...state,
        selectedDepartmentCatalog: (state.selectedDepartmentCatalog === action.payload.departmentId) ? null : action.payload.departmentId,
      }
    case CLEAR_CATALOG:
      return {
        ...state,
        catalog: [],
        catalogItems: action.payload.clearItems ? [] : state.catalogItems,
        catalogItemDescription: action.payload.clearItems ? null : state.catalogItemDescription
      }
    case SET_FILTER_VALUE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterValue,
        },
        selectedDepartmentCatalog: null,
      }
    case STORE_CATALOG:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case STORE_CATALOG_SUCCESS:
      return {
        ...initialState
      }
    case STORE_CATALOG_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case GET_CATALOGS_SAVED:
      return {
        ...state,
        isCatalogsLoading: true,
        error: null,
      }
    case GET_CATALOGS_SAVED_SUCCESS:
      return {
        ...state,
        isCatalogsLoading: false,
        savedCatalogs: action.payload.catalogs
      }
    case GET_CATALOGS_SAVED_FAIL:
      return { ...state, isCatalogsLoading: false }

    case DELETE_CATALOG:
      return {
        ...state,
        savedCatalogs: state.savedCatalogs.filter((c) => c.CatalogId !== action.payload.catalogId)
      }
    default:
      return state;
  }
};

export default catalogReducer;
