import React from 'react';
import { GET_ASSORTMENT_RESTRICTION, UPDATE_ASSORTMENT_RESTRICTION } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { getSelect, getTextNameField, getTableRow, getTable, getButton } from "../common/ComponentUtilities";
import "../Orders/PromoShip/styles.css";
import { Loader, Responsive} from "semantic-ui-react";
import Bin from '../../images/bin.svg';
import renderImages from '../common/RenderImages';
import "./admin.css"
import _ from 'lodash'
import utilities from '../../utils/utilities';
import {getExcelUploadField} from '../common/CustomComponents';
import { downloadExcel, readExcelData } from '../common/GenericExcelExport/exportUtils';
export const RA_RESTRICTION_REQ_FIELDS = ['assrtNbr','stateCode','country','rdcNbr','customerType','recommendedAssortment'];
export const RA_RESTRICTION_TEMPLATE_NAME = 'RARestrictionTemplate';
export const RA_RESTRICTION_TEMPLATE = [{'Asst Number':'', 'State':'', 'Country':'', 'RDC':'', 'Customer Type':'', 'Recommended Assortments to purchase':''}];
export const columnDefs = {0:'assrtNbr',1:'stateCode',2:'country',3:'rdcNbr',4:'customerType',5:'recommendedAssortment'};
class RARestrictions extends React.Component {
  constructor(props) {
    super(props)
    //const eventName =this.props?.match?.path;
    this.state = {
      rows: [],
      loading: false,
      shoppingArea : {},
      eventName : '',
      fileName:''
    };    
    this.file = '';
  }

  componentDidMount() {
    //this.getRARestriction();
  }

  getRARestriction = () => {
    this.setState({ loading: true, disableSave: true});
    restUtils.getData(GET_ASSORTMENT_RESTRICTION)
      .then(response => {
        // Get RA restriction data  
        const rows = response.data;

        this.setState({ rows,loading: false, disableSave : false });
      })
      .catch(error => {console.log(error); this.setState({ loading: false, disableSave : false })})
      .finally(this.setState({ loading: false }));
  }

  buildRowData = (row, index) => {
    return (
      [
        // { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.getShoppingAreaFields(`${index}-shoppingId`, row.shoppingId, this.state.shoppingArea) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-assrtNbr`, row.assrtNbr) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-stateCode`, row.stateCode) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-country`, row.country) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-rdcNbr`, row.rdcNbr) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-customerType`, row.customerType) },
        { columnProps: { className: 'tableCell', id: 'field_width_admin' }, columnText: this.rowTextComp(`${index}-recommendedAssortment`, row.recommendedAssortment,this.loadMore) },
        { columnProps: { className: 'tableCell', id: 'no_border_rarestriction' }, columnText: this.getDeleteIcon(`${index}-del`) }
      ]
    );
  }

  getRARestrictionHeaders = () => {
    const tableHeader = [
      // { props: { className: 'rowHeader', id: 'no_border' }, text: 'Shopping Area' },
      { props: { className: 'rowHeader', id: 'no_border_rarestriction' }, text: 'Asst Number' },
      { props: { className: 'rowHeader', id: 'no_border_rarestriction' }, text: 'State' },
      { props: { className: 'rowHeader', id: 'no_border_rarestriction' }, text: 'Country' },
      { props: { className: 'rowHeader', id: 'no_border_rarestriction' }, text: 'RDC' },
      { props: { className: 'rowHeader', id: 'no_border_rarestriction' }, text: 'Customer Type' },
      { props: { className: 'rowHeader', id: 'no_border_rarestriction' }, text: 'Recommended Assortments to purchase' },
      { props: { className: 'rowHeader', id: 'no_border_rarestriction' }, text: '' }
    ];
    return (getTableRow({}, tableHeader, true))
  }

  getRARestrictionRow = (clas, row, index) => {
    if(row.status != "D"){
      const tableDataCols = this.buildRowData(row, index);
      //let promoClassname = (promoShipOrderList?.orderList?.length === index+1) ? '' : 'promoRowdisable';
      return (
        <>
          {getTableRow({}, tableDataCols, false, 'tableRow')}
        </>
      )
    }
  }

  textOnChange = (e) => {
    const dat = e?.target?.id.split("-");
    if((dat?.includes('rdcNbr') || dat?.includes('assrtNbr')) && isNaN(e?.target?.value)){
      return;
    }
    this.updateRowState(e.target);
  }

  updateRowState = (target) => {
    const dat = target.id.split("-");
    const { rows } = this.state;
    rows[parseInt(dat[0])][dat[1]] = target.value;
    if (rows[parseInt(dat[0])]["status"] !== "I") {
      rows[parseInt(dat[0])]["status"] = "U";
    }
    this.setState({ rows });
  }

  rowTextComp = (id, value, loadMore) => {
    return getTextNameField(id, id, value, this.textOnChange, undefined, "t-center", false, loadMore);
  }

  downloadTemplate = () => {
    downloadExcel(RA_RESTRICTION_TEMPLATE, `${RA_RESTRICTION_TEMPLATE_NAME}.xlsx`);
  }

  chooseFile = (e)=>{
    const files = e.target.files[0];
    let fileReader;
    const reader = new FileReader();
    reader.onload = (e) => { 
      this.file = reader.result;
    };
    reader.readAsBinaryString(files);
    this.setState({
      fileName: files.name
    });
  }

  uploadFile = (e) => {
    let fileType = this.state.fileName.split(/\.(?=[^.]+$)/);
    let {rows,shoppingArea} = this.state;
    if(fileType[1] !== 'xlsx') {
      utilities.showToast('Kindly upload .xlsx file');
    }else {      
      this.setState({disableSave: true, loading: true});
      let data = readExcelData(this.file,{header:1,blankrows:false});
      data.forEach((row,index) => {
        let newRow = {};
        if(index !== 0){
          Object.keys(row).forEach(key => {
            newRow = {...newRow, ...{[columnDefs[key]] : row[key].toString().trim()}};
          });
          rows.push({...newRow, ...{status:'I'}});
        }
      });
      this.setState({
        rows,
        disableSave: false, 
        loading: false
      });
    }
  };

  deleteRARestriction = (e) => {
    const index = parseInt(e.target.id.split("-")[0]);
    const { rows } = this.state;
    if (rows[index]["status"] === "I") {
      rows.splice(index, 1);
    } else {
      rows[index]["status"] = "D";
    }
    this.setState({ rows });
  }

  getDeleteIcon = (id) => {
    return renderImages.getImage({
      id,
      src: Bin,
      alt: 'bin',
      className: 'delete-icon',
      onClick: this.deleteRARestriction
    });
  }
  
  loadMore = (e) => {
    const dat = e.target.id.split("-");
    const { rows} = this.state;
    if (parseInt(dat[0]) === rows.length - 1 && e.keyCode === 9) {   
      rows.push({status:'I',stateCode:'',country:'',rdcNbr:'',customerType:'',recommendedAssortment:'',itemNbr:''});   
      this.setState({ rows });
    }
  };

  validateRequiredField = (row) => {
    return !utilities.isEmptyOrNullString(row['assrtNbr']) && RA_RESTRICTION_REQ_FIELDS.some(field=> _.has(row, field) &&
                  !utilities.isEmptyOrNullString(row[field]) && row[field] !== 'Select...');
  }

  saveRARestriction = () => {
    const { rows } = this.state;
    let isValid = true;
    rows.forEach(row => {
      if((row.status === "I" || row.status === "U") &&
          !this.validateRequiredField(row)) {
        isValid = false;
        return;
      }       
    })
    if(!isValid){
      utilities.showToast(`Please enter all required fields`, true);
      return;
    }
    const saveData = rows.filter(row => {
      return row.status === "I" || row.status === "D" || row.status === "U"
    });

    this.setState({ loading: true });
    restUtils.postData(UPDATE_ASSORTMENT_RESTRICTION, saveData)
      .then(response => {
        utilities.showToast(`RA Restrictions updated successfully`);
        //this.getRARestriction();
      })
      .catch(error => console.log(error))
      .finally(this.setState({ loading: false }));
  }

  uploadRARestrictionList = () => {
    return (
    <div className='upload-link'>
      <div className='displayFlex page-header mb-2'>
          {'RA Restrictions'}
      </div>
      <div className='displayFlex'>
        {getExcelUploadField("Choose File", "upload-file", this.state.fileName , this.chooseFile, 'custom-input-btn', 'custom-file-input',this.downloadTemplate)}       
      </div>
      <div className='displayFlex pt-3'>
        <div className='pl-15'>
          {getButton({type:'button', className:'fluid ui red button submit', name:'uploadFile', onClick:this.uploadFile, disabled:this.state.disableSave}, 'Submit')}
        </div>
      </div>
    </div>
    );
  }

  render() {
    const { rows, loading, reunion } = this.state;
    
    return (
      <>
      {loading ? (
          <div style={{ "padding": "2rem" }}>
            <Loader active />
          </div>
        ): (
          <>
          <div className='displayFlex'> 
            <div>
                {this.uploadRARestrictionList()}
            </div>
          </div>
          {this.state?.rows?.length > 0 &&
          <div>
          {getTable({ className: 'ui celled table all-wh-table restriction-table', id: window.innerWidth > 1024 ? 'font-size-orders': 'admin_RA_tbl' }, this.getRARestrictionHeaders(), rows, this.getRARestrictionRow, { className: 'wh-row' })}
          {getButton({type:'button', className:'ui red button place-order-btn saveRARestriction', name:'saveShp', onClick:this.saveRARestriction, disabled:this.state.disableSave}, 'Save')} 
          </div> 
          }         
        </>
      )}
    </>
    );
  } 
}

export default RARestrictions;