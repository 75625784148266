import { ADDRESS_BOOK_API } from '../../urlConstants';
import restUtils from '../../utils/restUtils';
import utilities from '../../utils/utilities';
import _ from 'lodash';

export const SEARCH_ADDRESS = 'SEARCH_ADDRESS';
export const UPDATE_ADDRESS_LIST = 'UPDATE_ADDRESS_LIST';
export const DELETE_ADDRESS_LIST = 'DELETE_ADDRESS_LIST';

export const searchAddress = ({
  storeId,
  searchBy = '',
  pageNumber = 1,
  sortParam = '',
  sortType = 'asc',
  resultPerPage
}) => {
  return dispatch => {
    const requestUrl = `${ADDRESS_BOOK_API}/GetAddressesByStoreId?storeId=${storeId}&searchBy=${searchBy}&pageNumber=${pageNumber}&sortParam=${sortParam}&sortType=${sortType}&resultsperpage=${resultPerPage}`;
    restUtils.getData(requestUrl).then(
      res => {
        const addressList = _.get(res, ['data', 'addressList'], []);
        if (!addressList.length) {
          utilities.showToast('No address found');
        }
        dispatch({
          type: SEARCH_ADDRESS,
          payload: {
            lastItem : res.data ? res.data.lastItem : 0,
            totalPages : res.data ? res.data.totalPages : 0,
            totalItems : res.data ? res.data.totalItems : 0,
            firstItem : res.data ? res.data.firstItem : 0,
            currentPage : res.data ? res.data.currentPage : 1,
            addressList,
            isLoading: false
          }
        });
      },
      () => {
        dispatch({
          type: SEARCH_ADDRESS,
          payload: {
            addressList: [],
            isLoadingAddressFailed: true,
            isLoading: false
          }
        });
      }
    );
  };
};
export const deleteAddressList = (requestData, resultperpage, searchBy) => {
  return dispatch => {
    const requestUrl = `${ADDRESS_BOOK_API}/PostDeleteAddress`;
    restUtils.postData(requestUrl, JSON.stringify(requestData)).then(
      (response) => {  
        utilities.showToast(response.data.message, true);
        dispatch(searchAddress({storeId: requestData.storeId,searchBy:searchBy,resultPerPage: resultperpage}))
      },
      () => {
        utilities.showToast('Something went wrong. Please try again', true);
      }
    );
  };
};

export const updateAddressList = addressList => {
  return dispatch => {
    dispatch({
      type: UPDATE_ADDRESS_LIST,
      payload: {
        addressList: addressList
      }
    });
  };
};
