import { SHIP_LATER_RECEIVED, UPDATE_SHIP_LATER_SORT_ORDER } from './actions';

const shipLaters = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SHIP_LATER_SORT_ORDER:
    case SHIP_LATER_RECEIVED:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};
export default shipLaters;
