import React, { Component } from 'react';
import { Segment, Loader, Button, TextArea, Select } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import restUtils from '../../utils/restUtils';
import '../Products/ProductDetails_style.css';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import renderImages from '../common/RenderImages';
import './AskTrueValue.css';
import { PRODUCT_DETAILS, ASK_TV_EMAIL, GET_ASK_TRUEVAL } from '../../urlConstants';
import { connect } from 'react-redux';
import { TierIcon } from '../common/TierIcon';
import utilities, {getPrefernceType, shouldHideCostForPreference} from '../../utils/utilities';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { MaskPrice } from '../common/MaskPrice';
import history from '../../history';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
class AskTrueValue extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      product: undefined,
      emailAddress: this.props.primaryEmail,
      contactNumber: this.props.phoneNumber,
      username: this.props.userName,
      productMerchant: '',
      inventoryPlanner: '',
      productMerchantEmail: '',
      inventoryPlannerEmail: '',
      ccmyself: true,
      serverError: false,
      reasonForContact:"",
      showBullets : false
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    const itemNumber = this.props.match.params.productId;
    this.getProductDetails(itemNumber);
    const askUrl = `${GET_ASK_TRUEVAL}?itemId=${itemNumber}`;
    restUtils
      .getData(
        askUrl
      )
      .then(response => {
          const merchantPlannerDetails = response.data;
         const  productMerchantObj = merchantPlannerDetails?.find(item=>item.erole === 'GPM')
         const  inventoryPlannerObj = merchantPlannerDetails?.find(item=>item.erole === 'PL')
          const productMerchant = productMerchantObj?.empName ;
          const inventoryPlanner =inventoryPlannerObj?.empName ;
          const productMerchantEmail =productMerchantObj?.email;
          const inventoryPlannerEmail = inventoryPlannerObj?.email;
          this.setState({loading: false, productMerchant: productMerchant, inventoryPlanner: inventoryPlanner, 
                          productMerchantEmail: productMerchantEmail, inventoryPlannerEmail: inventoryPlannerEmail});
      }).catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  handleSubmit = () => {
    const {
      username,
      contactNumber,
      emailAddress,
      comment,
      ccmyself,
      product,
      productMerchantEmail,
      inventoryPlannerEmail,
      reasonForContact
    } = this.state;

    const {UserId, storeId, multiStoreDetails } = this.props;

    const reg = /^([a-zA-Z0-9_\-.]+)+@+[a-zA-Z0-9]+.+[A-z]/;

    if (emailAddress && !reg.test(emailAddress)) {
      utilities.showToast("Please enter valid email address", true);
      return;
    }
    
    if (!username || !contactNumber || !emailAddress || !comment || !reasonForContact){
      utilities.showToast("Please enter all required fields", true);
      return;
    }

    const requestPayload = {
      user: {
          UserID: UserId,
          StoreID: storeId,
          PrimaryWarehouseNum: multiStoreDetails && multiStoreDetails.primaryWarehouseNum
      },
      member: {
          memberName: username,
          memberPhone: contactNumber,
          memberEmail: emailAddress,
          memberNbr: storeId,
          comments: comment,
          CcEmail: ccmyself,
          reason:reasonForContact
      },
      contact: {
          PM:  productMerchantEmail,
          Planner: inventoryPlannerEmail
      },
      item: {
          itemNbr: product.Item_Number,
          pack: `${product.S_Pack_Type}${product.S_Pack_Qty}`,
          shortDesc: product.Product_Title || product.shortdesc,
          cost: product.pricing.Member_Cost,
          mfg: product.Vendor_Name,
          model: product.Model,
          longDesc: product.Product_Description || product.Long_Description,
          bullets : product.Product_Bullets,
          dept: product.deptName,
          itemclass: product.className,
          subclass: product.subclassName,
          badimg: ""
      }
  }
  this.setState({ loading: true });
  restUtils
      .postData(ASK_TV_EMAIL, requestPayload)
      .then(response => {
        this.setState({ loading: false });
        utilities.showToast("Email has sent successfully", true);
        this.onClear();
      })
      .catch(error => {
        console.log(error);
        utilities.showToast("Something went wrong, please try again.", true);
        this.setState({ loading: false });
      });
  };

  getImage = () => {
    return "https://images.truevalue.com/getimage.asp?id=" +
      this.state.product.Item_Number +
      "&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2";
  }

  getProductDetails = (itemId) => {
    const url = PRODUCT_DETAILS + itemId +
    '&storeId=' + this.props.storeId +
    '&rdc=' + this.props.rdc +
    '&grpCollectionId=' + this.props.groupCollectionId;
    this.setState({ loading: true });
    restUtils.getData(url)
      .then(response => {
        this.setState({ product: response.data, loading: false });
      })
      .catch(error => { console.log(error); this.setState({ loading: false }); });
  }

  onClear = () => {
    this.setState({ username: '', contactNumber: '', emailAddress: '', comment: '', ccmyself: true, reasonForContact:""})
  }

  editableUsers = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    });
  }

  onReasonForContactChange = (e, {value}) => {
    this.setState({
      reasonForContact: value
    });
  }

  ccMyself = () => {
    this.setState({ ccmyself: !this.state.ccmyself });
  }

  showProductBullets =() => {
    this.setState({showBullets : true});
  }

  hideProductBullets =() => {
    this.setState({showBullets : false});
  }

  render() {
    const getPrefer =  getPrefernceType(this.props);
	  const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const { product, productMerchant, inventoryPlanner, serverError } = this.state;
    const pricingDetails = product ? product.pricing : {};
    const { state = {} } = this.props.location || {};
    const {isTier2,isRaMembercost} = state;
    const options= [
      { key: 'Inventory/Stock Availability', value: 'Inventory/Stock Availability', text: 'Inventory/Stock Availability', className:'reasonMenu'},
      { key: 'Product Information', value: 'Product Information', text: 'Product Information', className:'reasonMenu'},
      { key: 'Vendor/Program Information', value: 'Vendor/Program Information', text: 'Vendor/Program Information', className:'reasonMenu'},
      { key: 'Price', value: 'Price', text: 'Price', className:'reasonMenu', text:'Price'},
      { key: 'Warehouse/Delivery', value: 'Warehouse/Delivery', text: 'Warehouse/Delivery', className:'reasonMenu'},
      { key: 'Claim', value: 'Claim', text: 'Claim', className:'reasonMenu'},
      { key: 'Discontinued/Sub/Ref', value: 'Discontinued/Sub/Ref', text: 'Discontinued/Sub/Ref', className:'reasonMenu'},
    ];
    return serverError ? (
      <InternalServerError />
    ) : (
        <Segment id="productDetailInfo">
          <div>
            {this.state.loading &&
              <div>
                <Loader active />
              </div>
            }
          </div>
          <>
            <div style={{ paddingBottom: '0.5rem' }} className="page-header displayInline">
              Ask True Value
          </div>
            {product &&
              <>
                <div id='page_navigation'>
                  <BreadcrumbComp
                    path={[
                      { link: '/home', text: 'HOME' },
                      { link: '/categories/' + product.deptName, text: product.deptName },
                      { link: '/categories/' + product.deptName + "/" + product.className, text: product.className },
                      { link: '/categories/' + product.deptName + "/" + product.className + "/" + product.subclassName, text: product.subclassName },
                    ]}
                  />
                </div>
                <div className="displayFlex" style={{ paddingTop: '1rem' }}>
                  <span className='product-name'>{product.Product_Title || product.shortdesc}  &nbsp;&nbsp;</span>
                  <Link className="vendor-span" 
                    to={{ pathname:product.Vendor_Name ? 
                      "/vendor/" +  encodeURIComponent(product.Vendor_Name) : "#",
                    state: { vendorId: product.Vendor_Id }}}>{product.Vendor_Name}</Link>
                </div>
                <div className="displayFlex" style={{ paddingTop: '0.5rem' }}>
                  <div>
                    {renderImages.getImage({ src: this.getImage(), alt: product.Item_Number })}
                  </div>
                  <div style={{ paddingLeft: '2rem' }}>
                    <div className='displayFlex'>
                      <span className="card_name" style={{ width: '7rem' }}>Item#:</span>
                      <b className="First_level_details">{product.Item_Number}</b>
                    </div>
                    <div className='displayFlex' style={{ paddingTop: '0.25rem' }}>
                      <span className="card_name" style={{ width: '7rem' }}>Model#:</span>
                      <b className="First_level_details">{product.Model}</b>
                    </div>
                    <div className='displayFlex' style={{ paddingTop: '0.25rem' }}>
                      <span className="card_name" style={{ width: '7rem' }}>Pack:</span>
                      <b className="First_level_details">{product.S_Pack_Type}{product.S_Pack_Qty}</b>
                    </div>
                    {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) && displayMemberCostAndLbl &&
                      <div className='displayFlex' style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
                        <span className="card_name" style={{ width: '7rem' }}>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}:</span>
                        <b className="First_level_details">
                        <MaskPrice
                          getprefer={this.props.getprefer &&
                            this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
                          }
                          viewType={this.props.viewType}
                          fieldVal={isRaMembercost ? product.pricing ? product.pricing.Cost : '':
                                    utilities.getCostForQtyPlpNew(pricingDetails, 0)}
                          field='Member Cost'
                        /></b>
                        { pricingDetails.tiered &&  !isRaMembercost && this.props.viewType === MEMBER_VIEW &&
                          <TierIcon tierLevel={utilities.getCostForQtyPlpNew(pricingDetails, 0, true)} tierLetter={false}/> }
                      </div>
                    }
                    <div style={{ maxWidth: '90%' }}>
                      <p style={{ textAlign: 'justify' }}>
                        {product.Product_Description ||product.Long_Description}
                      </p>
                      <div className='ui stackable one column grid'>
                        <div className='sixteen wide column'>
                          {
                            this.state.showBullets ? (
                              <ul>
                                {product.Product_Bullets && product.Product_Bullets.map(bullet => {
                                  return ( <li> {bullet}</li> )})
                                }
                                {product.Product_Bullets && product.Product_Bullets.length > 4 ?
                                  <button onClick={this.hideProductBullets} >Show less</button> 
                                  : '' 
                                }
                              </ul>) :(
                              <ul>
                                {product.Product_Bullets && product.Product_Bullets.slice(0,4).map((bullet,bullet_index) => {
                                  return ( <li> {bullet} </li>)})
                                }
                                {product.Product_Bullets && product.Product_Bullets.length > 4 ?
                                  <button onClick={this.showProductBullets}>Show more</button>
                                  : ''
                                }
                              </ul>) 
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <form id="inputForm">
                  {/*<div style={{ paddingTop: '1rem' }}>
                    <span className='contactlabel' ><b>Contact:</b></span>
                    <span className="merchantdetails">
                      Product Merchant : {productMerchant}
                      <span className="inventorydetails">
                        Inventory Planner : {inventoryPlanner}
                    </span>
                    </span>
                  </div>*/}
                  <div className="displayFlex askform">
                    <p className="autoFormText">To ensure a timely response to your inquiry, please complete the form below. If you are in need of an immediate response,
                    please contact Customer Care at 800-621-6025 for assistance.</p>
                  </div>
                  <div className="displayFlex askform" >
                    <span className="askTitle"><b>Name *:</b></span>
                    <div className="autoform">
                      <input
                        type="text"
                        value={this.state.username}
                        onChange={this.editableUsers}
                        className="askfield"
                        name="username"

                      />
                    </div>
                  </div>
                  <div className="displayFlex askform">
                    <span className="askTitle" ><b>Contact Number *:</b></span>
                    <div className="autoform" >
                      <input
                        type="text"
                        className="askfield"
                        onChange={this.editableUsers}
                        value={this.state.contactNumber}
                        name="contactNumber"
                      />
                    </div>
                  </div>
                  <div className="displayFlex askform">
                    <span className="askTitle" ><b>Email Address *:</b></span>
                    <div className="autoform">
                      <input
                        type="text"
                        className="askfield"
                        value={this.state.emailAddress}
                        name="emailAddress"
                        onChange={this.editableUsers}
                      />
                    </div>
                  </div>                 
                  <div className="displayFlex askform" >
                    <span className="askTitle" ><b> Reason for <br />Contact *:</b></span>
                    <div style={{ borderColor: 'black' }}>
                      <Select className="reasonDropdown" placeholder='Select one' selection value={this.state.reasonForContact}onChange={this.onReasonForContactChange} options={options} />
                    </div>
                  </div>
                  <div className="displayFlex askform" >
                    <span className="askTitle" ><b>Details Regarding Your Request *:</b></span>
                    <div style={{ borderColor: 'black' }}>
                      <TextArea
                        name="comment"
                        className="commentask"
                        value={this.state.comment}
                        onChange={this.editableUsers}
                      />
                    </div>
                  </div></form>
                <div
                  className="displayFlex askform asksubmit" >
                  <Button size='mini' color='red' onClick={this.handleSubmit}>Submit</Button>
                  <span
                    style={{
                      color: '#4183c4',
                      fontWeight: 'bolder',
                      paddingRight: '5rem',
                      paddingTop: '0.7rem'
                    }}
                  >
                    <u className="cursor_p" onClick={this.onClear}>Clear</u>
                  </span>
                  {/* <div className="ui checkbox askcheckbox">
                  <input onChange={this.ccMyself} label='ccmyself' checked={this.state.ccmyself} type="checkbox" name="ccmyself" />
                    <label>CC Myself</label>
                  </div> */}
                </div>
                <div className="askback" >
                  <span className='back_button' onClick={()=>{history.goBack()}}>
                    <u style={{ fontWeight: 'bolder' }}>Back to Product Details</u>
                  </span>
                </div>

              </>
            }
          </>

        </Segment>
      )
  }
  
}

const mapStateToProps = state => {
  const { userName, UserId, storeId, multiStoreDetails, rdc } = state.SessionReducer;
  const { primaryEmail, phoneNumber, groupCollectionId } = state.SessionReducer.address;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    getprefer: state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    primaryEmail, userName, phoneNumber, UserId, storeId, multiStoreDetails, rdc, groupCollectionId
  };
};

export default connect(mapStateToProps, null)(AskTrueValue);
