import React, { Component } from 'react';
import './styles.css';
import utilities from '../../../utils/utilities';
import { colDefs, breadcrumbLinks } from './modelData';
import ExcelExport from '../../common/ExcelExport';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import { Loader } from 'semantic-ui-react';
import Table from '../../common/Table';
import { CANCELED_ITEMS } from '../../../../src/urlConstants';
import InternalServerError from '../../../views/ErrorPages/500_error_page';
import { connect } from 'react-redux';
import restUtils from '../../../utils/restUtils';
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';

class CancelledItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: false,
      cancelledItems: [],
      colDefs : this.getColDefs()
    };
    this.sortParam = 'ItemNbr';
    this.sortDirection = 'asc';
    this.searchParam = 'itemNbr';
    this.searchNumber = '';
  }

  getColDefs = () => {
    if(this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID)
        && colDefs && colDefs[6].header !== 'Alternate SKU#')
    {
      colDefs.splice(6,0,
        {
          header: 'Alternate SKU#',
          key: 'aliasSku'
        });
    }
    return colDefs;
  };


  componentDidMount() {
    this.getCancelledItems();
  }

  changeSortParam = e => {
    this.sortParam = e.currentTarget.value;
    this.getCancelledItems();
  };

  changeSortDirection = e => {
    this.sortDirection = e.currentTarget.value;
    this.getCancelledItems();
  };

  changeSearchParam = e => {
    this.searchParam = e.currentTarget.value;
  };

  searchItems = e => {
    this.searchNumber = document.getElementById('search_canceled_items').value;
    this.getCancelledItems();
  };

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  getCancelledItems = () => {
    this.setState({ loading: true });
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    let cancelled_item_url = `${CANCELED_ITEMS}?storeId=${this.props.storeId}&aliasSKURetailerGrpID=${aliasSKURetailerGrpID}`
    let url = this.searchNumber ?
      `${cancelled_item_url}&${this.searchParam}=${this.searchNumber}` : `${cancelled_item_url}`;
    url = url + `&sortParam=${this.sortParam}&sortType=${this.sortDirection}`
    restUtils
      .getData(url)
      .then(response => {
        this.setState({
          cancelledItems: response.data.CancelledItemsList,
          loading: false,
          serverError: false,
          isNoProducts: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  };

  render() {
    return (
      this.state.serverError ? <InternalServerError /> :
      <div className='page-wrap cancelled-item-section'>
        {this.state.loading && (
          <div>
            <Loader active />
          </div>
        )}
        <div className='page-header'>Cancelled Items</div>
        <BreadcrumbComp path={breadcrumbLinks} />

        <br /><br />
        {this.props.multiStoreDetails.access === 1 ? (
         <>
          <h4> You don't have the permission to view this page. Please contact the True Value Administrator. </h4>
         </>
        ) : (
          <>
        <div className='ui input ' id='input_search'>
          <span>
            {window.innerWidth < 768 ? 'Search' : 'Cancelled Items Search'}
          </span>
          <select
            className='sort-criteria-canceled combo'
            id='pl_dropdown'
            onChange = {this.changeSearchParam}
          >
            <option value='itemNbr'>Item #</option>
            { this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
                 <option value='alternateSKU'>Alternate SKU#</option> )}  
          </select>
          <input
            id='search_canceled_items'
            onKeyDown={this.handleEnterKey}
            type='text'
          />
          {renderImages.getImage({
            src: search_img,
            className: 'search_icon_address cursor_p',
            onClick: this.searchItems
          })}
        </div>

        <div>
            <div className='sort_filter'>
              <span className='filter-label'>Sort By</span>
              <select
                className='sort-criteria combo'
                onChange={this.changeSortParam}
                id='pl_dropdown'
              >
                <option value='ItemNbr'>Item #</option>
                { this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
                 <option value='alternateSKU'>Alternate SKU#</option> )}   
                <option value='OrderNbr'>PO #</option>
                <option value='CancelDate'>Cancelled Date</option>
              </select>
              <select
                className='sort-order combo'
                onChange={this.changeSortDirection}
              >
                <option value='asc'>Ascending</option>
                <option value='desc'>Descending</option>
              </select>

              <span className='mr-9 inline-block'>
                Showing {this.state.cancelledItems.length} Products
              </span>
                <ExcelExport
                url={this.state.url}
                fileName='CancelledItems'
                totalItems={ this.state.cancelledItems}
                viewType={this.props.viewType}
                aliasSKURetailerGrpID={this.props.multiStoreDetails && this.props.multiStoreDetails.aliasSKURetailerGrpID}
              />
            </div>
        </div>
        <br />
        <div className='cancelled-item-grid'>
          <Table data={this.state.cancelledItems} colDefs={this.state.colDefs} />
        </div>
        </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    storeId: state.SessionReducer.storeId,
    multiStoreDetails : state.SessionReducer.multiStoreDetails
  };
};

export default connect(mapStateToProps)(CancelledItems);
