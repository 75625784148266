import React from 'react'
import './dom.css'
import history from '../../history';
import utilities from '../../utils/utilities'
import restUtils from '../../utils/restUtils';
import { Loader, Responsive } from 'semantic-ui-react';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import { getRegionData } from './actions'
import BreadcrumbComp from '../../components/BreadcrumbComp';
import { GET_PARTICIPATION_DISTRIBUTION, dealsOfTheMonthUrl } from '../../urlConstants';
import { isArray } from 'util';

class DealsOfTheMonth extends React.Component{
    constructor(props){
        super(props);
        const {zoneCode} = props;
        const region=zoneCode?
                    isArray(zoneCode)&& zoneCode.length>0
                        ?zoneCode[0]
                        :zoneCode
                    :"";
        this.state={
            deals:[],
            isLoading : true,
            isListView:false,
            region,
            futurePromotion: false
        }
        this.region=zoneCode?
                            isArray(zoneCode)&& zoneCode.length>0
                                ?zoneCode[0]
                                :zoneCode
                            :"";
    }
    componentDidMount(){
        this.props.getRegionData();
        restUtils.getData(dealsOfTheMonthUrl+this.props.storeId)
        .then(response => {
           let deals = response.data?.Promotions?.length > 0  ? response.data.Promotions: response.data?.FuturePromos
           this.setState({deals,isLoading:false})
           if(response.data?.Promotions?.length === 0) {
               this.setState({futurePromotion: true})
           }
        });

    }
    componentWillReceiveProps(nextProps) {
        if(this.props.zoneCode !== nextProps.zoneCode) {
            this.setState({region: nextProps.zoneCode})
        }
    }
    showProducts=(dom)=>{
        history.push({
            pathname:'/retail-events/'+dom.promo_nbr,
            state:dom
        })
    }

    showCircular=(e)=>{
        const zoneCode = this.state.region;
        history.push({
                pathname:'/preview-circular/'+e.target.id,
                state:{
                    zoneCode
                }
            })
    }
    toggleView =(viewType) =>{
        this.setState({ isListView: viewType === "list" });
    }
    changeRegion=(e)=>{
        this.setState({region:e.target.value});
    }
    
    render(){
        const breadcrumbPath = [{ link: '/home', text: 'HOME' },{ link: this.props.location.pathname, text: 'Retail Events'}]

        return(
            <div className='page-wrap'>
                {this.state.isLoading ?
                    <Loader active/>
                    :
                    <>
                        <div className='page-header'>
                            Retail Events
                        </div>
                        <BreadcrumbComp path={breadcrumbPath}/>
                        <br/>
                        <div className='region-label-dom'>
                            Region : <RegionDetails 
                                            region={this.region}
                                            storeId={this.props.storeId}
                                            zoneDesc={this.props.zoneDesc} 
                                            zoneCode={this.props.zoneCode}
                                            changeRegion={this.changeRegion}/>
                            {/* <span onClick={()=>utilities.openInNewTab((GET_PARTICIPATION_DISTRIBUTION()))} className='participation-distr-link'>View Participation/ Distribution Details</span> */}
                        </div>
                        <br/>
                        {<span className='dom-disclaimer'>
                            Members regular transportation rates apply. Promotional retails and member costs appearing on Membersonline or the TV Wholesale Deals Brochure are subject to change. Look here for latest pricing.
                        </span>}
                        <Responsive minWidth={1024} id="toggle-view">
                            <div className="toggle-view-icon">
                                <i
                                    className="list icon cursor_p"
                                    value="List"
                                    id="list_icon"
                                    onClick={() => this.toggleView("list")}
                                ></i>
                                |
                                <i
                                    className="th icon cursor_p"
                                    value="Grid"
                                    id="grid_icon"
                                    onClick={() => this.toggleView("grid")}
                                ></i>
                            </div>
                        </Responsive>
                        {this.state.deals && this.state.deals.length > 0 && this.state.futurePromotion && (
                                <strong>Please see the start dates(s) below for the next ordering opportunity</strong>
                        )}
                        <div className={this.state.isListView?'':'ui stackable three column grid'} style={{margin:'1rem 0rem'}}>
                            {this.state.deals && this.state.deals.length ? ( this.state.deals.map(dom=>{
                                return this.state.isListView?
                                    <DomListCard dom={dom} showCircular={this.showCircular} showProducts={this.showProducts} futurePromotion ={this.state.futurePromotion}/>
                                    :
                                    <DomCard dom={dom} showCircular={this.showCircular} showProducts={this.showProducts} futurePromotion ={this.state.futurePromotion}/>
                            })):
                            <div className='no_prdcts_display'>
                            <h1>No Retail Events to display!</h1>
                          </div>}
                        </div>
                    </>
                }
            </div>
        )
    }
}

const DomCard = ({dom,showCircular,showProducts, futurePromotion}) => {
        return (
            <div className='column zero-padding'>
                {! futurePromotion ? (
                <div className='dom-card'>
                    <div className='dom-content'>
                        <p className='dom-card-title' onClick={()=>showProducts(dom)}>{"#"+dom.promo_nbr+"-"+dom.promoName}</p>
                        <p className='dom-deadline'>Order Deadline {utilities.getDateStringFromTimeStamp(dom.endDate)}</p>
                        <div className='dom-dates'>
                            <div className='dom-ship-window' id='dom-ship-windowMobile'>
                                <span>Ship Window</span>
                                <p>{utilities.getDateStringFromTimeStamp(dom.shipDate1)+" - "+utilities.getDateStringFromTimeStamp(dom.shipDate2)}</p>
                            </div>
                            <div className='dom-start-end-dates' id='dom-ship-windowMobile'>
                                <span>Start & End Dates</span>
                                <p>{utilities.getDateStringFromTimeStamp(dom.consumerStartDate)+" - "+utilities.getDateStringFromTimeStamp(dom.consumerEndDate)}</p>
                            </div>
                        </div>
                        <div className='dom-promolink-previewlink'>
                            {/* <span className='promoships-link'>PromoShip Orders</span> */}
                            <span className='preview-link'
                                    id= { dom.promo_nbr }
                                    onClick={showCircular}>Preview Circular</span>
                        </div>
                    </div>
                    <button className='red fluid ui button' onClick={()=>showProducts(dom)}>View Items</button>
                </div>
                ):
                <div className='dom-card'>
                    <div className='dom-content'>
                    <p>{dom.PromoName}</p>
                    <p className='dom-deadline'>Start Date {utilities.getDateStringFromTimeStamp(dom.StartDate)}</p>
                    </div>
                </div>}
            </div>
        )
}

const DomListCard = ({dom, showProducts, showCircular, futurePromotion}) =>{
        return (
            <div className='zero-padding'>
            {! futurePromotion ? (
                <div className='dom-list-card'>
                    <div className='dom-list-content'>
                        <p className='dom-card-title' onClick={()=>showProducts(dom)}>{"#"+dom.promo_nbr+"-"+dom.promoName}</p>
                        <p className='dom-deadline'>Order Deadline {utilities.getDateStringFromTimeStamp(dom.endDate)}</p>
                        <div className='dom-list-ship-window'>
                            <p>Ship Window: &nbsp;
                                {utilities.getDateStringFromTimeStamp(dom.shipDate1)+" - "+utilities.getDateStringFromTimeStamp(dom.shipDate2)}
                            </p>
                        </div>
                        <div className='dom-list-start-end-dates'>
                            <p>Start & End Dates &nbsp;
                                {utilities.getDateStringFromTimeStamp(dom.consumerStartDate)+" - "+utilities.getDateStringFromTimeStamp(dom.consumerEndDate)}
                            </p>
                        </div>
                    </div>
                    <div className='dom-list-footer'>
                        {/* <span className='promoships-link'>PromoShip Orders</span> */}
                        <span className='preview-link'
                                id={dom.promo_nbr}
                                onClick={showCircular}>Preview Circular</span>
                        <button className='dom-view-items red  ui button' onClick={()=>showProducts(dom)}>View Items</button>
                    </div>
                </div>
            ):
            <div className='dom-list-card'>
                <div className='dom-list-content' style={{'justifyContent': 'space-between'}}>
                    <p>{dom.PromoName}</p>
                    <p>Start Date {utilities.getDateStringFromTimeStamp(dom.StartDate)}</p>
                </div>
            </div>
            }
            </div>
        )
}
export const RegionDetails = ({storeId,region,zoneCode,zoneDesc,changeRegion})=>{
    if(!zoneCode){
        return <></>;
    }
    if(!utilities.isAllRegionStore(storeId)){
        return  <>
                    {zoneCode?`(${zoneCode})`:""}{utilities.emptyForNull(zoneDesc)}
                </>
    }
    return (
        <>
            <select className='region-combo'
                value = {region}
                onChange={changeRegion}>
                {
                    zoneCode.map((code,index)=>{
                        return (
                            <option value={code}>({code}) {zoneDesc[index]}</option>
                        )
                    })
                }
            </select>
        </>
    )
}
const mapStateToProps = (state) =>{
    const domStorage = state.DomReducer;
    return {
        userId:state.SessionReducer.UserId,
        storeId:state.SessionReducer.storeId,
        zoneCode :domStorage.zoneCode,
        zoneDesc:domStorage.zoneDesc
    }
}
const mapDispatchToProps = (dispatch) =>{
    return Object.assign({dispatch},
        bindActionCreators({
            getRegionData
        },dispatch)
    )
}
export default connect(mapStateToProps,mapDispatchToProps)(DealsOfTheMonth);