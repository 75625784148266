import {GET_IM_CATEGORIES,GET_POG} from './actions'

const initState = {};

const ImReducer = (state=initState,{type,payload})=>{
    switch(type){
        case GET_IM_CATEGORIES:
            const endCapsDepartments = payload.map(category =>{
                return category.Display_Name.indexOf("ENDCAPS")>-1;
            })
            return {...state,endCapsDepartments,categories:payload};
        case GET_POG:
            return {...state,[payload.catName]:payload.POGs}
        default: return state;
    }
}
export default ImReducer;