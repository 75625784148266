import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchVendors } from '../actions';
import { Accordion, Icon, Grid, Popup, Loader, List } from 'semantic-ui-react';
import { getVendorsFetching, getVendors } from '../reducer';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import utilities from '../../../utils/utilities';

import VendorDetailsPopup from './vendor-details-popup';
import '../vendors.css';
 
export class AccordianItem extends React.Component {
  render() {
    const { item, content } = this.props;
    const { attributeValue = '', productCount } = item;
    return (
      <p>
        <Popup
          wide='very'
          hoverable
          content={content}
          trigger={<i className='far fa-address-card'></i>}
        />

        <Link
          to={{
            pathname: `/vendor/${attributeValue.replace(/\//g, '%2F')}`+'/'+item.ID+'/'+item.sameVendorProductCount            ,
            state: { vendorExists: true, vendorId: item.ID,vendorCount : item.sameVendorProductCount }
          }}
        >
          {attributeValue.toUpperCase()} [{productCount}]
        </Link>
      </p>
    );
  }
}

class VendorAlphabetFilter extends React.Component {
  state = {
    searchInput: '',
    alphabet: this.props.alphabet || 'A',
    vendorsList: []
  };

  componentWillReceiveProps(nextProps) {
    const { alphabet } = nextProps;
    if (alphabet !== this.props.alphabet) {
      this.setState({
        alphabet
      });
    }
  }

  componentDidMount() {
    this.props.fetchVendors('A');
  }

  onSearchInputChange = e => {
    this.setState({ searchInput: e.target.value });
  };

  onSearchInputChange = e => {
    this.setState({ searchInput: e.target.value });
  };

  onAlphabetClick = selectedAlphabet => {
    const { alphabet } = this.state;
    if (alphabet === selectedAlphabet) {
      this.setState({ alphabet: '', show: false });
    } else {
      this.setState({ alphabet: selectedAlphabet, show: false });
      this.props.fetchVendors(selectedAlphabet);
    }
  };

  prepareAlphabets = () => {
    let result = [];
    result.push(
      <List.Item
        onClick={() => this.onAlphabetClick('0-9')}
        value='0-9'
        key='0-9'
      >
        <button className='vendorNum'>0 - 9</button>
      </List.Item>
    );

    for (let i = 65; i < 91; i++) {
      const char = String.fromCharCode(i);
      result.push(
        <List.Item
          key={i}
          onClick={() => this.onAlphabetClick(char)}
          value={char}
        >
          <HashLink to={`#${char}`} scroll={utilities.scrollToElement}>
            {char}
          </HashLink>
        </List.Item>
      );
    }

    return result;
  };

  getAccordionContent = alphabetText => {
    const { vendors, loading } = this.props;
    const selectedAlphabetText = alphabetText === "0-9" ? alphabetText.split('-')[0] : alphabetText;
    if (loading === 1) {
      return (
        <div className='vendor-loader'>
          <Loader active />
        </div>
      );
    }
    let vendorList = vendors[selectedAlphabetText];
    if (!vendorList) return null;
    const { length } = vendorList;
    const chunkSize = length >= 3 ? Math.floor(vendorList.length / 3) : 1;
    vendorList = _.chunk(vendorList, chunkSize);

    return (
      <Grid
        className={
          window.innerWidth > 767
            ? ' very relaxed three column'
            : 'very relaxed one column mobileDiv'
        }
      >
        {vendorList.map((items, i) => (
          <Grid.Column key={i}>
            {items.map((item, j) => (
              <AccordianItem
                key={j}
                content={<VendorDetailsPopup {...item} />}
                item={item}
              />
            ))}
          </Grid.Column>
        ))}
      </Grid>
    );
  };

  elementContainsSearchString = (searchInput, element) =>
    searchInput
      ? element.name.toLowerCase().includes(searchInput.toLowerCase())
      : false;
  filterItems = itemList => {
    let result = [];
    const { searchInput, alphabet } = this.state;
    if (itemList && (searchInput || alphabet)) {
      result = itemList.filter(
        element =>
          element.name.charAt(0).toLowerCase() === alphabet.toLowerCase() ||
          this.elementContainsSearchString(searchInput, element)
      );
    } else {
      result = itemList || [];
    }

    return result;
  };

  showAlphabets = () => {
    this.setState(prevState => {
      return {
        show: !prevState.show
      };
    });
  };

  render() {
    const { alphabet } = this.state;
    const aToZ = this.prepareAlphabets();
    const atoZdropdown = <div className='AtoZButton'>{aToZ}</div>;
    return (
      <div className='alphabet-filter-wrapper'>
        <div className='alphabets-device'>
          <label for="alpSelect">Select:</label>
          <button id="alpSelect" className='showAtoZ' onClick={this.showAlphabets}>
           {alphabet} <i class="caret down icon"></i>
          </button>
          {this.state.show ? atoZdropdown : null}
        </div>
        {/* <div className='alphabet-accordion'> */}
        <List horizontal className='alphabets'>
          {aToZ}
        </List>
        {/* </div> */}
        <div className='alphabet-accordion'>
          <Accordion fluid styled>
            {aToZ.map((item, key) => (
              <div
                id={aToZ[key + 0] ? aToZ[key + 0].props.value : 'Z'}
                key={item.props.value}
              >
                <Accordion.Title
                  active={item.props.value === alphabet}
                  index={item.props.value}
                  onClick={() => this.onAlphabetClick(item.props.value)}
                >
                  <HashLink
                    to={`#${item.props.value}`}
                    scroll={utilities.scrollToElement}
                    className='accordion-title'
                  >
                    {item.props.value}
                    <Icon
                      className='plus-minus-icon'
                      name={item.props.value === alphabet ? 'minus' : 'plus'}
                    />
                  </HashLink>
                </Accordion.Title>
                <Accordion.Content active={item.props.value === alphabet}>
                  {this.getAccordionContent(item.props.value)}
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: getVendorsFetching(state.vendors),
  vendors: getVendors(state.vendors)
});

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        fetchVendors
      },
      dispatch
    )
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorAlphabetFilter);
