import React,{Component} from 'react';
import left from "../images/LeftArrowIcon.png";
import right from "../images/RightArrowIcon.png";
import renderImages from '../common/RenderImages';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Event } from '../../history';
import _ from 'lodash';
import {getRedirectUri} from '../../urlConstants'
import utilities from '../../utils/utilities';
import history from '../../history';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total:props.children.length ? props?.children?.length:0,
      currentSlide:0,
      isLoading: false
    }
  }

  componentDidMount(){
    this.setState({isLoading: true});
    this.autoSlide = setInterval(() => {
      this.showNext()
    }, 9000);
  } 

  componentWillReceiveProps({children}){
    this.setState({
      total:children.length
    },()=>{
      if(this.state.total || this.state.total ===0 ) {
        this.setState({isLoading: false})
      }
    });
  }

  showNext = () => {
    this.setState((prevState) =>
      {
        return {
          currentSlide : ( prevState.currentSlide+1 === prevState.total) ? 0 : prevState.currentSlide+1
        }
      }
    );
  }

  showPrev = () => {
    this.setState((prevState) =>
      {
        return {
          currentSlide : ( prevState.currentSlide === 0) ? prevState.total-1 : prevState.currentSlide-1
        }
      }
    );
  }

  triggerEvent = (eventDescription)=>{
    Event("HOME",eventDescription)
  }
  slideToImg =(currentSlide) => {
    console.log(currentSlide)
    this.setState({currentSlide})
  }
 componentWillUnmount () {
  clearInterval(this.autoSlide)
 }
 redirectToPage = (currentItem) => {
    if(!_.isEmpty(currentItem)) {
      const { url, id, isCmsPage} = currentItem
    const isNnwhPage = url.includes(getRedirectUri())
    if(isCmsPage === 'No') {
      if(isNnwhPage) {
        const getPath = url.split(`${getRedirectUri()}/`)
        if(getPath.length > 1) {
          history.push(getPath[1]);
          return
        }
      }else {
        utilities.openInNewTab(url)
      }
    }
    else if(isCmsPage === 'Yes') {
      if(!_.isEmpty(url)){
        history.push(url);
      }else {
        history.push(`/content/${id}`);
      }
      
    }
  }
 }
  render() {
    const {children} = this.props;
    const currentItem = (children && children.length>0) ? children[this.state.currentSlide] : {};
    let imageUrl = (children && children.length>0) ? ''+currentItem.image
                    : '';
 
    return (
      <>
      { this.state.isLoading ?  <Loader active /> :
      children && children.length>0 ?
        <>
          <div className='slides-div carousel_img_height'>
            <span>{
              renderImages.getImage({
                src:left,
                className:'carousel_left_arrow',
                onClick:this.showPrev
              })
            }
            </span>
            <div onClick={()=>this.redirectToPage(currentItem)}
                  >
                {renderImages.getImage({
                  src:imageUrl,
                  alt:imageUrl,
                  className:'carousel_img'
                  })
                }
            </div>
            <span>{
              renderImages.getImage({
                src:right,
                className:'carousel_right_arrow',
                onClick:this.showNext
              })}
            </span>
          </div>
          <div className='sliderDotsWrapprer'>
          {children && children.length>0 && children.map((ele,index)=>{
           if(index <= 3) {
            return (<span className= {this.state.currentSlide === index ? 'activeDot' : '' }key={index}onClick={()=>{this.slideToImg(index)}}></span>)
            }          
            })}
          </div>          
        </>
        : <p className='noProductMessage'>No items found!</p>
      }
      </>
    )
  }
}

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer:state.preference.listData,
  }
}

export default connect(mapStateToProps, null)(ImageCarousel);