import React from "react";
import InternalServerError from "../../ErrorPages/500_error_page";
import utilities from "../../../utils/utilities";
import _ from "lodash";
import { getCard } from "./ReunionComponentUtilities";
import history from "../../../history";
import { getEventRedirectUrl } from "./eventRedirectUtilities";

class PromoListing extends React.Component {
  constructor(props) {
    super(props);
    const shoppingAreaMetaId = decodeURIComponent(props?.location?.state?.shoppingAreaMetaId);
    this.state = {
      eventId: "",
      filters: [],
      clearFilters: false,
      appliedFilters: {},
      showFilters: utilities.getShowFilters(this.props.getprefer),
      shoppingAreaMetaId : shoppingAreaMetaId
    };
    this.filterString = {};
  }

  showVendors = (e) => {
    const { promoNbr, promoName, flowType, relayYear, relayNbr } = e?.target
      ?.dataset
      ? e.target.dataset
      : "";
    const deadline =
      this.props?.reunionEvents?.eventLists &&
      this.props.reunionEvents.eventLists.length > 0
        ? utilities.getDateFromTimeStamp(
            this.props.reunionEvents.eventLists[0].endDate
          )
        : "";
    const shoppingAreaId = this.props?.reunionEvents
      ? this.props.reunionEvents.shoppingId
      : "";
    const shoppingAreaMetaId = this.props?.shoppingAreaMetaId;
    const shoppingAreaName = this.props?.reunionEvents
      ? this.props.reunionEvents.shoppingName
      : "";
    const keyParams = {
      eventId: promoNbr,
      promoName: promoName,
      relayNbr: relayNbr,
      relayYear :relayYear,
      flowType: flowType,
      fromReunionHome:this.props.fromReunionHome,
      shoppingAreaId: shoppingAreaId,
      shoppingAreaMetaId:shoppingAreaMetaId,
      shoppingAreaName: shoppingAreaName,
      pageName: flowType === "4"
                    ? "VendorDropShipVendorListing"
                    : flowType === "5"
                    ? "VendorListingDropShipDiscountCity"
                    : "VendorListing",
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr,
        promoName,
        flowType,
        deadline,
        relayYear,
        shoppingAreaName,
        fromReunionHome:this.props.fromReunionHome,
        shoppingAreaMetaId:shoppingAreaMetaId
      },
    });
  };

  showItemList = (e) => {
    const { promoNbr, promoName, flowType, relayYear, relayNbr, allitems } = e?.target
      ?.dataset
      ? e.target.dataset
      : "";
    const shoppingAreaId = this.props?.reunionEvents
      ? this.props.reunionEvents.shoppingId
      : "";
    const shoppingAreaMetaId = this.props?.shoppingAreaMetaId;
    const shoppingAreaName = this.props?.reunionEvents
      ? this.props.reunionEvents.shoppingName
      : "";
    const keyParams = {
      eventId: promoNbr,
      promoName,
      shoppingAreaId: shoppingAreaId,
      shoppingAreaMetaId: shoppingAreaMetaId,
      shoppingAreaName: shoppingAreaName,
      flowType,
      fromReunionHome:this.props.fromReunionHome,
      relayNbr,
      relayYear,
      pageName: flowType === "1" 
                    ? "AllItems"
                    : flowType === "4" 
                    ? "AllVendorDropShipItems"
                    : flowType === "5"
                    ? "DropShipPalletAllItems"
                    : "PalletAllItems",
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr,
        promoName,
        shoppingAreaName,
        shoppingAreaId,
        shoppingAreaMetaId,
        flowType,
        relayYear,
        vendorId: null,
        allitems,
        fromReunionHome:this.props.fromReunionHome
      },
    });
  };

  showAssortmentList = (e) => {
    const { promonbr, promoName, flowType } = e?.target?.dataset
      ? e.target.dataset
      : "";
    const { reunionEvents } = this.props;
    const relayNbr =
      reunionEvents?.eventLists?.length > 0
        ? reunionEvents.eventLists[0].relayNbr
        : "";
    const deadline =
      reunionEvents.eventLists?.length > 0
        ? utilities.getDateFromTimeStamp(reunionEvents.eventLists[0].endDate)
        : "";
    const shoppingAreaId = utilities.emptyForNull(reunionEvents?.shoppingId);
    const shoppingAreaMetaId = utilities.emptyForNull(this.props?.shoppingAreaMetaId);
    const shoppingAreaName = utilities.emptyForNull(reunionEvents?.shoppingName);
    const keyParams = {
      eventId: promonbr,
      eventName: encodeURIComponent(promoName),
      shoppingAreaId: shoppingAreaId,
      shoppingAreaMetaId: shoppingAreaMetaId,
      shoppingAreaName: shoppingAreaName,
      flowType,
      relayNbr,
      fromReunionHome:this.props.fromReunionHome,
      pageName: "AssortmentListing"
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr,
        promoName,
        deadline,
        shoppingAreaName,
        shoppingAreaId,
        shoppingAreaMetaId,
        flowType,
        fromReunionHome:this.props.fromReunionHome
      },
    });
  };

  toggleShowFilter = () => {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  };

  getEvents = (eventLists) => {
    if (Array.isArray(eventLists) && !_.isEmpty(eventLists)) {
      return (
        <>
          {eventLists &&
            eventLists.map((event) => {
              const promoName = event?.promoName ? event.promoName.trim() : "";
              const promoDetails = {
                eventType: event.relayNbr,
                promoNbr: event.promoNbr,
                relayYear: event.relayYear,
                promoName: promoName,
                flowType: event.flowType,
                showVendors: this.showVendors,
                showItemList: this.showItemList,
                showAssortmentList: this.showAssortmentList,
              };
              return (
                <>
                  <div className="column">{getCard({ promoDetails })}</div>
                </>
              );
            })}
        </>
      );
    } else {
      return (
        <div className="noProdctsDisplay">
          <h1>No Events to Display!</h1>
        </div>
      );
    }
  };

  render() {
    const { eventLists } = this.props.reunionEvents;
    const eventId =
      eventLists && eventLists.length > 0 ? eventLists[0].relayNbr : "";
    const deadline =
      eventLists && eventLists.length > 0
        ? utilities.getDateFromTimeStamp(eventLists[0].endDate)
        : "";
    const gridClassName =
      window.innerWidth < 1024
        ? "ui stackable two column grid"
        : window.innerWidth < 1240
        ? "ui stackable three column grid"
        : "ui stackable five column grid";
    return this.state.serverError ? (
      <InternalServerError />
    ) : (
      <div
        className={
          window.innerWidth > 1024
            ? "displayFlex allOrdersTable"
            : "allOrdersTable"
        }
      >
        <div className="pageDetails">
          <div className={gridClassName}>
            <div className="promoNumber">
              <span>Event ID: #{eventId}</span>
              <span className="PromoDate">Order Deadline: {deadline}</span>
            </div>
            {this.getEvents(eventLists)}
          </div>
        </div>
      </div>
    );
  }
}
export default PromoListing;
