import React from 'react';
import restUtils from '../../../utils/restUtils';
import { Loader, Responsive, Confirm } from 'semantic-ui-react';
import history from '../../../history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../AllOrders/AllOrders.css';
import utilities, { shouldHideSUGG_RETAILForPreference, shouldHideCostForPreference } from '../../../utils/utilities';
import ExcelExport from '../../common/ExcelExport';
import renderImages from '../../common/RenderImages';
import { Link } from 'react-router-dom';
import { MaskPrice } from '../../common/MaskPrice';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';
import { EDIT_ORDER_ITEM_DETAILS, createCartUrl, addManyProductsUrl} from '../../../urlConstants'
import InternalServerError from '../../ErrorPages/500_error_page';
import './styles.css';
import { WH_ShoppingCartId } from '../../Cart/constants';
import ReactToPrint from 'react-to-print';
import {getCartInfo } from '../../Cart/actions';

class EditOrderItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: [],
      loading: false,
      serverError: false,
      img_txt_to_display:(this.props.getprefer && this.props.getprefer.thumbnailImage 
                            && this.props.getprefer.thumbnailImage !=='Y') 
                            ? 'Show Images': 'Hide Images',
      orderNum:this.props.match.params.orderNum,
      orderDetails:this.props.location.state ? this.props.location.state.orderDetails : null,
      confirmCancel : false,
      disableUpdateOrderbtn : false
    }
    this.sortParam = 'itemNbr';
    this.sortDirection = 'asc';
    this.scrollPosition = 0;
    this.editOrderPath = window.history?.state?.state?.pushUrl || '';
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.getItemDetails();
    }
  }

  roundNull = (data, field) => {
    const getPrefer = this.props.getprefer &&
    this.props.getprefer.customerView
    ? this.props.getprefer.customerView
    : 'c1';
    if(data){
      return <MaskPrice
        field = {field}
        getprefer={getPrefer}
        viewType={this.props.viewType}
        fieldVal={Number(data).toFixed(2)}
      />
    } else return "-"; 
  }

  showHideImages = evt => {
    let img_txt_to_display =
      evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

  changeSortParam = e => {
    this.sortParam = e.currentTarget.value;
    this.scrollPosition = 0;
    this.getItemDetails();
  };

  changeSortDirection = e => {
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = 0;
    this.getItemDetails();
  };

  confirmCancel = () => {
    this.setState({confirmCancel : true });   
  }

  closeConfirmation = () => {
    this.setState({confirmCancel:false})
  }
  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }
  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&'); 
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';   
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : '';   
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
   let scrollPosition = this.scrollPosition;
    this.setState({
      scrollPosition : scrollPosition
    },() => {
      this.getItemDetails();
    });
  }
  setBackButtonPath = () => {
    let pathName = ''; 
    this.scrollPosition = window.pageYOffset;
    pathName = '&scrollPosition='+this.scrollPosition;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam='+this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType='+this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    let pushUrl  = '?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }
  
  cancelOrder  = (orderNum, orderType, rdcNum) => { 
    const UserId = this.props.userId;
    const userName = this.props.userName;
    this.updateStatus(orderNum, orderType, rdcNum, true, '', UserId, userName,0,'F');    
  }

  updateOrder  = (orderNum, orderType, rdcNum, poNum, itemDetails) => {    
    this.setState({disableUpdateOrderbtn : true, loading: true });
    const UserId = this.props.userId;
    const userName = this.props.userName;
    const ShoppingCartInfoTypeId = WH_ShoppingCartId;
    let cartName = (itemDetails && itemDetails.length>0)
                        ?itemDetails[0].PONbr:"";
    const postObj = {
      UserId: UserId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId
    };
    let cartId;
    restUtils
      .postData( createCartUrl, postObj)
      .then(response => {        
        cartId = response.data.ShoppingCartId;
        let storeId = this.props.storeId;
        let itemList = [];
        itemList = itemDetails.map(item => {
          return {
            CartId: cartId,
            ItemNumber: item.itemNum,
            ItemQty: item.userQty,
            OverrideRetail: item.overrideRetail,
            RDC: item.rdcNum,
            ItemSource: item.source,
            userName:userName,
            TotalQtyInOrder: item.userQty,
            storeId: storeId
          };
        });
        restUtils
          .postData(addManyProductsUrl,itemList)
          .then(response => {
            let data = response.data;
            if (data.orderAlreadySubmitted) {
              utilities.showToast('Cart you are trying to add is already submitted, Please create a new cart');
              this.setState({disableUpdateOrderbtn : false, loading: false });
              return;
            } else if(data.itemList) {
              let stockFlag = false ;
              data.itemList.map((stock) => {
                if(stock.itemStockedAtRdc === true){
                  stockFlag = true;
                }

                return stock;
              })
              if(stockFlag === false) {
                const toastMsg = response?.data?.Message && response.data.Message !== '' ? response.data.Message : "Item is NOT STOCKED. Add item from other warehouses"
                utilities.showToast(toastMsg);
                return;
              } else {
                utilities.showToast('Product(s) added to cart :'+cartName);
                this.updateStatus(
                  orderNum, 
                  orderType, 
                  rdcNum, 
                  false, 
                  cartName, 
                  UserId, 
                  userName,
                  cartId,
                  'M'
                );
              }
            }            
          });
         this.props.getCartInfo(cartId)
        })
        .catch(err => {
          this.setState({disableUpdateOrderbtn : false, loading: false });
          console.log('error', err);
        });
  }

updateStatus = (
  orderNum, 
  orderType, 
  rdcNum, 
  isCancelOrder,
  oldCartName,
  UserId,
  userName,
  ShoppingCartId, 
  status) => {
    const url = `${EDIT_ORDER_ITEM_DETAILS}/updateOrderStatus`;
    const payload = {
      rdcNum: rdcNum,
      orderType: orderType,
      orderNum: orderNum,
      shoppingCartID: ShoppingCartId,
      userId: UserId,
      oldCartName:oldCartName,
      storeId: this.props.storeId,
      molUserName: userName,
      isCancelOrder: isCancelOrder,
      status : status
    };  
    restUtils
      .postData(url, payload)
      .then(response => {
        if (response) {
          if(isCancelOrder){
            utilities.showToast('The order has been cancelled successfully')
            history.push('/editOrders');
          }else{    
            history.push('/cart');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  getItemDetails = () => {
    const {orderNum, orderDetails} = this.state;
    const url = 
        `${EDIT_ORDER_ITEM_DETAILS}?storeID=${this.props.storeId}&ordertype=${orderDetails?.orderType}&orderNum=${orderNum}&rdcnum=${orderDetails?.rdcNum}
              &sortBy=${this.sortParam}&sortOrder=${this.sortDirection}&aliasSKURetailerGrpID=${this.props.aliasSKURetailerGrpID}`
    this.setState({ loading: true });
    restUtils
      .getData(url)
      .then(response => {
        this.setState({ itemDetails: response.data, loading: false, serverError: false });
        window.scrollTo(0,this.scrollPosition);
       this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  goToPreviousPage= () => {
    if(this.editOrderPath){
      history.push(this.editOrderPath)
    } else {
      history.goBack();
    }
   }

   getDesc = (description) => {
    return(
      description && (
        description.length < 20
          ? description.substr(0,1).toUpperCase() + 
          description.substr(1,description.length).toLowerCase() 
          : description.substr(0,1).toUpperCase() +
           description.substr(1,18).toLowerCase()  + '...'
      )
    )
  }

  render() {
    const {orderDetails} = this.state;
    const itemDetails = this.state.itemDetails;
    const img_style = this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    const poNbr = !utilities.isEmptyOrNullString(orderDetails.memberRefNum)
                    ?orderDetails.memberRefNum
                    :(itemDetails&&itemDetails.length>0)
                        ?itemDetails[0].PONbr
                        :"";
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType, getPrefer);
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(this.props.viewType, getPrefer); 
    return (
      this.state.serverError ? <InternalServerError /> :
        <div className='page-wrap' ref={node => (this.componentRef = node)}>
          <div>
          <span className='page-header'>Edit Orders</span>
          <span id='print_img'>
              <ReactToPrint
                pageStyle='@page{size:landscape;}'
                trigger={() => (
                  <i className='print icon' id='printicon'></i>
                )}
                content={() => this.componentRef}
              /> 
          </span>
          </div>
          {this.state.loading &&
            <div>
              <Loader active />
            </div>
          }

          <div className='sort_by'>
            <span className='filter-label'>Sort By</span>
            <select className='sort-criteria combo' onChange={this.changeSortParam} value={this.sortParam} id='pl_dropdown'>
              <option value='itemNbr'>Item #</option>
              <option value='model'>Model #</option>
              {/* <option value='noteProgram'>Program</option> */}
              <option value='shortDesc'>Description</option>
              {/* <option value='rdcNbr'>RDC</option> */}
              <option value='upc'>UPC</option>
            </select>
            <select className='sort-order combo' onChange={this.changeSortDirection} value={this.sortDirection} defaultValue='asc'>
              <option value='asc'>Ascending</option>
              <option value='desc'>Descending</option>
            </select>
            <span className='order_count'>Showing {itemDetails ? itemDetails.length: 0} Products
            <ExcelExport 
              className='excel_left excelHide' 
              fileName={'Edit Item Details'} 
              orderResults={itemDetails} 
              viewType={this.props.viewType}
              getprefer={
                this.props.getprefer &&
                this.props.getprefer.customerView
                ? this.props.getprefer.customerView
                : 'c1'
              }
              aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}/>
            </span>
          </div>
          <br />

          <div className='font-style'>
            <p>Warehouse Order</p>
            <p className='itemDetails itemDetailsMobile'>PO #:<span className='order_details'>{utilities.hyphenForNull(orderDetails.memberRefNum)}</span></p>
            {/* <p className='itemDetails itemDetailsMobileInvoice'>Invoice #:<span className='order_details'>{utilities.hyphenForNull(orderDetails.invoiceNbr)}</span></p> */}
            <p className='itemDetails itemDetailsMobileInvoice'>Order Created Date:<span className='order_details'>{utilities.hyphenForNull(utilities.getDateStringFromTimeStamp(orderDetails.dateSubmitted))}</span></p>
            <p className='itemDetails itemDetailsMobileInvoice'>TrueValue Order ID:<span className='order_details'>{utilities.hyphenForNull(orderDetails.orderNum)}</span></p>
            <p className='order_count allorderCount'>
              <span onClick={this.showHideImages} id='hideShowImages'>{this.state.img_txt_to_display}</span>
              <span id='back_button' className='underline' onClick={this.goToPreviousPage}>Back to Edit Orders</span>
            </p>
          </div>

          <div className={window.innerWidth > 1024 ? 'allOrdersTable orderstatusMargin' : 'allOrdersTableItem'}>
            <Responsive minWidth='1025'>
              <div className='eighteen wide column'>
                <table className='ui celled table all-wh-table' id='font-size-orders'>
                  <thead>
                    <tr>
                      <th className='allOdrHeader' id='no_border' ></th>
                      <th className='allOdrHeader' id='no_border' >Item #</th>
                      {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) &&
                      <th className='allOdrHeader' id='no_border' >Alternate SKU#</th>}
                      <th className='allOdrHeader' id='no_border' >UPC #</th>
                      <th className='allOdrHeader' id='no_border' >Model #</th>
                      <th className='allOdrHeader' id='title_width' style={{columnWidth : '20rem'}}>Item Description</th>
                      {/* <th className='allOdrHeader' id='no_border' >Program</th> */}
                      <th className='allOdrHeader' id='no_border' >Seq #</th>
                      <th className='allOdrHeader' id='no_border' >RDC</th>
                      <th className='allOdrHeader' id='no_border' >Weight</th>
                      <th className='allOdrHeader' id='no_border' >Pack</th>
                      <th className='allOdrHeader' id='no_border' >Qty</th>
                      {showMemberCostAndLbl && <th className='allOdrHeader' id='no_border' >{utilities.getLabelsByType(this.props.viewType,"COST", getPrefer)}</th>}
                      { showMemberCostAndLbl && <th className='allOdrHeader' id='no_border' >{utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer)}</th>}
                      { displaySRPCost && <th className='allOdrHeader' id='no_border' >{utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL", getPrefer)}</th>}
                      {/* <th className='allOdrHeader' id='no_border' >Var. Price</th>
                      <th className='allOdrHeader' id='no_border' >Var. Method</th> */}
                      <th className='allOdrHeader' id='no_border' >Source</th>
                      {/* <th className='allOdrHeader' id='no_border' >Cancel Code</th> */}
                      <th className='allOdrHeader' id='no_border' >Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemDetails ?
                      itemDetails.map((order) => {
                        const imageUrl = 'https://images.truevalue.com/getimage.asp?id=' +
                        order.itemNum +
                        '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2'
                        return (
                          <>
                            <tr >
                              <td className='t-center' id='field_width'>{renderImages.getImage({ src: imageUrl, className: 'orderImage', style: { display: img_style } })}</td>
                              <td className='t-left' id='field_width'>
                              <Link onClick={this.setScrollPosition} to={`/product/${order.itemNum}`}>{order.itemNum}</Link>
                              </td>
                              {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) &&
                              <td className='t-center' id='field_width'>{order.aliasSku}</td>}
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.upcNum)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.modelNum)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(this.getDesc(order.shortDesc))}<br />
                                  {order.mfg}
                              </td>
                              {/* <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.noteProgram)}</td> */}
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.seqNum)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.rdcNum)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.weight)}</td>
                              <td className='t-center' id='field_width'>{utilities.emptyForNull(order.packType)}{utilities.zeroForNull(order.packQty)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.userQty)}</td>
                              { showMemberCostAndLbl && <td className='t-center' id='field_width'>{utilities.isBinLabel(order.userQty) ? '-': this.roundNull(order.cost, 'Member Cost black')}</td>}
                              { showMemberCostAndLbl && <td className='t-center' id='field_width'>{utilities.isBinLabel(order.userQty) ? '-': this.roundNull(order.extCost, 'Member Cost black')}</td>}
                              { displaySRPCost && <td className='t-center' id='field_width'>{order.overrideRetail && order.overrideRetail > 0 ? `* ${utilities.hyphenForNull(utilities.getCostBasedOnViewType(this.props.viewType, order.overrideRetail,'Suggested Retail', getPrefer))}` : utilities.hyphenForNull(utilities.getCostBasedOnViewType(this.props.viewType, order.srCost,'Suggested Retail', getPrefer))}</td>}
                              {/* <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.variablePrice)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.variableMethod)}</td> */}
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.source)}</td>
                              {/* <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.cancelRsnCode)}</td> */}
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.status)}</td>
                            </tr>
                            <tr className='wh-row'>
                              <td colSpan={20} className='t-left' >
                                  {/* <i className='addedBy'>
                                    {`Added By: ${order.userName ? order.userName : ''}`}
                                  </i> */}
                              </td>
                            </tr>
                          </>
                        );
                      }) : 'No items to display!'
                    }
                  </tbody>
                </table>
              </div>
            </Responsive>
            <Responsive maxWidth='1024'>
              {itemDetails && itemDetails.map(order => {
                return <OrderCard 
                  order={order} 
                  aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID} 
                  img_style={this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none'} 
                  getPrefer={this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'}
                  viewType={this.props.viewType}/>
              })}
            </Responsive> 
            { itemDetails.length > 0 && 
            <div className='wrapUpdateBtns'>             
            {  ["57", "58"].includes(itemDetails[0]?.orderOrigin) ? '':
              <button className='red ui button cancel-select-order'
                onClick={() => this.updateOrder(orderDetails.orderNum,                                        orderDetails.orderType,
                                                orderDetails.rdcNum,
                                                orderDetails.PONbr,
                                                itemDetails)}
               disabled={this.state.disableUpdateOrderbtn}
                                                
              > Update Order
              </button>
            }          
              <button className='compare-btn cancel-select-order'                
              onClick= {this.confirmCancel}>
                    Cancel Order
              </button>  
            </div>  
           }
            <div className='t-right'>             
              
                <i className = 'addedBy'>
                    *Price reflects override retail entered when order was submitted.
                </i>
            </div>    
              <Confirm
                className='cart-confirm'
                open = {this.state.confirmCancel}
                onCancel = {this.closeConfirmation}
                header='Cancel Order'
                content={'You are about to cancel the order. Click on OK to proceed'}
                onConfirm = {() =>this.cancelOrder(orderDetails.orderNum,
                                                    orderDetails.orderType,
                                                    orderDetails.rdcNum)}
              />
          </div>
        </div>
    )
  }
}
const OrderCard = ({ order, img_style, aliasSKURetailerGrpID, getPrefer, viewType }) => {
    const imageUrl = 'https://images.truevalue.com/getimage.asp?id=' +
                        order.itemNum +
                        '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2'
    const showMemberCostAndLbl =  shouldHideCostForPreference(viewType, getPrefer);
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(viewType, getPrefer); 
    const roundNull = (data, field) => {
        if(data){
          return <MaskPrice
            field = {field}
            getprefer={getPrefer}
            viewType={viewType}
            fieldVal={Number(data).toFixed(2)}
          />
        } else return "-"; 
      }
    return (
    <div className='all-orders-card mobile-mb-1'>
      <table id='order-card-table'>
        <td className='t-center' id='field_width'>
          {renderImages.getImage({ src: imageUrl, className: 'orderImage', style: { display: img_style } })}</td>
        <tr>
          <td className='order-card-field'>Item #</td>
          <td>{utilities.hyphenForNull(order.itemNum)}</td>
        </tr>
        {!utilities.isEmptyOrNullString(aliasSKURetailerGrpID) &&
          <>
          <tr>
            <td className='order-card-field'>Alternate SKU#</td>
            <td>{order.aliasSku}</td>
          </tr>
          </>
        }
        <tr>
          <td className='order-card-field'>ADV Code #</td>
          <td>{utilities.hyphenForNull(order.advCode1)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>UPC #</td>
          <td>{utilities.hyphenForNull(order.upcNum)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Model #</td>
          <td>{utilities.hyphenForNull(order.modelNum)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Item Description</td>
          <td >{utilities.hyphenForNull(order.shortDesc)}</td>
        </tr>
        {/* <tr>
          <td className='order-card-field'>Program</td>
          <td >{utilities.hyphenForNull(order.noteProgram)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field'>Seq #</td>
          <td >{utilities.hyphenForNull(order.seqNum)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>RDC</td>
          <td >{utilities.hyphenForNull(order.rdcNum)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Weight</td>
          <td >{utilities.hyphenForNull(order.weight)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Pack</td>
          <td>{utilities.emptyForNull(order.packType)}{utilities.emptyForNull(order.packQty)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Quantity</td>
          <td>{utilities.hyphenForNull(order.userQty)}</td>
        </tr>
        { showMemberCostAndLbl && <tr>
          <td className='order-card-field'>{utilities.getLabelsByType(viewType,"COST", getPrefer)}</td>
          <td>{utilities.isBinLabel(order.userQty) ? '-': roundNull(order.cost, 'Member Cost black')}</td>
        </tr>}
        { showMemberCostAndLbl && <tr>
          <td className='order-card-field'>{utilities.getLabelsByType(viewType,"EXTENDED_COST", getPrefer)}</td>
          <td>{utilities.isBinLabel(order.userQty) ? '-': roundNull(order.extCost, 'Member Cost black')}</td>
        </tr>}
        { displaySRPCost && <tr>
          <td className='order-card-field'>{utilities.getLabelsByType(viewType,"SUGG_RETAIL", getPrefer)}</td>
          <td>{order.overrideRetail && order.overrideRetail > 0 ? `* ${utilities.hyphenForNull(utilities.getCostBasedOnViewType(viewType, order.overrideRetail, 'Suggested Retail', getPrefer))}` : utilities.hyphenForNull(utilities.getCostBasedOnViewType(viewType, order.srCost, 'Suggested Retail', getPrefer))}</td>
        </tr>}
        {/* <tr>
          <td className='order-card-field'>Var. Price</td>
          <td>{utilities.hyphenForNull(order.variablePrice)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Var. Method</td>
          <td>{utilities.hyphenForNull(order.variableMethod)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field'>Source</td>
          <td>{utilities.hyphenForNull(order.source)}</td>
        </tr>
        {/* <tr>
          <td className='order-card-field'>Cancel Code</td>
          <td>{utilities.hyphenForNull(order.cancelRsnCode)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field' >Status</td>
          <td>{utilities.hyphenForNull(order.status)}</td>
        </tr>
      </table>
    </div>
  )
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer:state.preference.listData,
    userId:state.SessionReducer.UserId,
    userName: state.SessionReducer.userName,
    orders: state.cart.orders,
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({getCartInfo}, dispatch)
  );
};

export default connect( mapStateToProps, mapDispatchToProps)(EditOrderItemDetails);
