import React, { Component } from 'react';
import { getWordpressGraphQLContent } from '../../urlConstants';
import restUtils from '../../utils/restUtils';
import GetContent  from '../WordPress/GetContent';
import _ from 'lodash';

class WpHompageContent extends Component {
  constructor(props){
    super(props);
    this.state={
      data : [],
      wpContentSection:[]
    }
  }
  componentDidMount() {
    restUtils.getDataWithBearer(`${getWordpressGraphQLContent()}/?type=page&id=nwh-home`).then(response=>{
        const wpContentSection = response.data
        this.setState({wpContentSection})
      }).catch(err=>{
        console.log(err)
      })
  }
  render() {
    return(
      <div>
       {!_.isEmpty(this.state.wpContentSection) && <GetContent  data={this.state.wpContentSection}/> }
  </div>
    )

  }
}

export default WpHompageContent;
