import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { ORDER_UPLOAD_URL } from '../../urlConstants';
import { restUtils } from '../../utils/restUtils';
import { getEmptyItems } from '../OrderPad/defaultOrdersEntity';
import './OrderUpload.css';
import utilities from '../../utils/utilities';
import { Loader } from 'semantic-ui-react';
import cipherImag from '../images/cipherLoader.png'
import _ from 'lodash';

class OrderUpload extends React.Component {
  constructor(props) {
    super(props);
    this.fileLabel = React.createRef();
    this.state = {
      fileName: '',
      itemList:[],
      errorMessage:'',
      disableSubmit: false,
      loading: false,
      ports:[],
      selectedPort:'',
      selectedBaud:'',
      cipherLoader: false,
      timerFunction: 0
    };
    this.timer = ''
  }
  chooseFile = (e)=>{
    const file = e.target.files[0];
    this.setState({
      file,
      fileName: file.name,
      errorMessage:''
    })
  }

  uploadFile = () => {
    let fileType = this.state.fileName.split(/\.(?=[^.]+$)/);
    if(fileType[1] !== "xlsx") {
      utilities.showToast("Kindly upload .xlsx file");
    }else {
      this.setState({disableSubmit: true, loading: true});
      const presignedUrlRequested = this.getPresignedUrl();
      presignedUrlRequested
        .then(res => {
          const { presignedUrl, keyName } = res.data;
          restUtils.putData(presignedUrl, this.state.file).then(() => {
            this.processFile(keyName);
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  processFile = (s3keyName) => {
    const { storeId, userName } = this.props;
    const requestUrl = `${ORDER_UPLOAD_URL}/PostProcessOrderFile`;
    const requestData = {
      storeId: parseInt(storeId),
      molUserName: userName,
      s3keyName,
      primaryWarehouseNbr: '01',
      groupCollectionId: this.props.groupCollectionId,
      aliasSKURetailerGrpID: this.props.aliasSKURetailerGrpID
    };

    restUtils.postData(requestUrl, requestData).then(res => {
      if(res.data && res.data.itemList && res.data.itemList.length < 1000) {
        let itemList = res.data.itemList;
        let items = [];
        let itemsArray = itemList.map(item => {
            return  {
              itemno: item.itemNbr,
              quantity: item.userQty,
              overrideRetail: '',
              retailCost: '',
              extCost: '',
              productName: '',
              errorMsg: '',
              rowNbr: item.rowNbr
            };
        });
        if(itemList.length < 9){
          let numberOfItems =  9 - itemList.length;
          items = [...itemsArray, ...getEmptyItems(numberOfItems)];
        }else{
          items = itemsArray;
        }
        this.setState({disableSubmit: false, loading: false});
        history.push({
          pathname: '/orderpad',
          state: {
            uploadData: items,
            badItemErrorMap: res.data.badItemErrorMap,
            orderUpload: true
          }
        });
      }else{
        utilities.showToast("Only 999 items allowed for upload");
        this.setState({disableSubmit: false, loading: false});
      }
    }).catch(error => {
      if(error.response && error.response.data && !error.response.data.success) {
        this.setState({
          errorMessage:error.response.data.message,
          disableSubmit: false, loading: false
        })
      }
    });
  };

  getPresignedUrl = () => {
    const { storeId, userName } = this.props;
    const requestUrl = `${ORDER_UPLOAD_URL}/PostPresignedUrlForOrderFile`;
    const requestData = {
      storeId,
      molUserName: userName
    };
    return restUtils.postData(requestUrl, requestData);
  };
    

  downloadTemplate = () => {
    const requestUrl = `${ORDER_UPLOAD_URL}/PostDownloadOrderFileTemplate`;
    const { storeId, userName } = this.props;
    restUtils
      .postData(requestUrl, {
        storeId,
        molUserName: userName
      })
      .then(res => {
        const { presignedUrl } = res.data;
        window.open(presignedUrl, '_blank');
      });
  };
  changePort = (e)=> {
    const value = e.target.value
    this.setState({selectedPort: value})
  }
  changeBaudRate = (e)=> {
    const value = e.target.value
    this.setState({selectedBaud: value})
  }
  async  readAndDisplayCipher() {
    let dataString = await this.readCipher();
    let data = JSON.stringify(dataString, null, 8);
    console.log('final string ' + data); 
    if(!data.includes("WAREHOUSE")) {
      utilities.showToast('The cipher upload is only for regular Warehouse orders')
      this.setState({cipherLoader: false});
      return
    } 
    let lines = data.split('\\r');
        let cipherData = { orders: []};
        let i = 0;
        cipherData.hardwareVer1 = lines[i++];
        cipherData.hardwareVer2 = lines[i++];
        cipherData.softwareVer = lines[i++];
        cipherData.softwareHash = lines[i++];
    
    while( i < lines.length){
        let order = {};
        order.storeString = lines[i++];
        order.orderDate = lines[i++];
        if(typeof(order.orderDate) === 'string') {
          order.orderDate = order.orderDate.substring(order.orderDate.lastIndexOf('=')+1);
        }
        order.poNumber = lines[i++];
        order.orderTypeStart = lines[i++];
        order.items = [];
        while(i < lines.length && !lines[i].startsWith('WAREHOUSE')){
            var item = {};
            item.itemno = lines[i++];
            item.quantity = lines[i++];
            if(lines[i] && lines[i].includes('=')) {
              item.overrideRetail = parseInt(lines[i++].substring(1))/100;
            } else { 
              item.overrideRetail =''
            }
            order.items.push(item);
        }
        order.orderTypeEnd = lines[i++];
        order.itemCount = lines[i++];
        order.itemCount = parseInt(order?.itemCount?.substring(order.itemCount.lastIndexOf('.')+1), 10);
        cipherData.orders.push(order);
        if(lines[i] === '')
            i++;
    }
    let combinedOrder = cipherData.orders[0].items.filter(item => !isNaN(parseInt(item.itemno)))
    /*cipherData.orders.forEach(ele=>{
      combinedOrder.push(...ele.items)
    })   */
    console.log(cipherData)  
    console.log(combinedOrder)
    let filterCipherData = cipherData.orders.filter(order=> order.orderDate && order.itemCount && order.itemCount > 0)
    console.log(filterCipherData)
    if(filterCipherData[0].storeString.substring(1,6) !== this.props.storeId) {
       utilities.showToast('Cipher device store no is different from your store. Upload cancelled!')
       this.setState({cipherLoader: false});
      return
    }
    if(filterCipherData.length > 1) {
     utilities.showToast('Only 1 order will be uploaded at a time. Please tag orders separately in cipher device & retry')
     this.setState({cipherLoader: false});
    }       
    let poNumberVal = null
    if(!_.isEmpty(cipherData.orders[0].poNumber) && cipherData.orders[0].poNumber !== '' && cipherData.orders[0].poNumber.split('').length > 2) {
      poNumberVal = cipherData.orders[0].poNumber.substring(2)
    }
    history.push({
          pathname: '/orderpad',
          state: {
            uploadData: combinedOrder,
            poNumber: poNumberVal,
            fromCipher: true
          }
    });
  }
  async readCipher() {
    this.setState({cipherLoader: true}); 
  try {
    const timer = setTimeout(()=>{
      this.setState({cipherLoader: false}); 
      window.location.reload();
    },30000)
   this.timer = timer
    if (!navigator.serial)
        throw 'Serial Api is not available';
    let ports = await navigator.serial.getPorts();
    let port = null;
    if (ports.length > 0) {
        port = ports[0];
    } else {
        port = await navigator.serial.requestPort();
    }
  
    await port.open({ baudRate: 38400 });
    let cancelFlag = false;
    let data = [];
    let decoder = new TextDecoder();
    if (port.readable && !cancelFlag) {
        let dataPart = null;
        var readObj = null;
        const reader = port.readable.getReader();
        dataPart = await this.readFromDataReader(reader, decoder);
        data = dataPart;
        //console.log("finish reading:", dataPart.split('\r'));
    }
    await port.close();
    clearTimeout(this.timer)
    //console.log('exiting readCipher', data);

    return data;
  }
  catch(e){
    clearTimeout(this.timer)
    this.setState({cipherLoader: false})
    console.log('Unable to get Data')
  }
  }
  
  async readFromDataReader(reader, decoder) {
    let readResult = null;
    let data = [];
    let stopFlag = false;
    do {
        readResult = await reader.read();
        stopFlag = readResult.done;
        if (!stopFlag) {
            
            let dataChunk = readResult.value;
            let chunkLength = dataChunk.length;
  
            if (chunkLength > 0 && dataChunk[0] == 2) {
                dataChunk = dataChunk.subarray(1);
                chunkLength = dataChunk.length;
            }
  
            if (chunkLength > 0 && dataChunk[chunkLength - 1] == 3) {
                dataChunk = dataChunk.subarray(0, chunkLength - 2);
                stopFlag = true;
            }
            let chunk = decoder.decode(dataChunk);
            data.push(chunk);
        }
    } while (!stopFlag)
    await reader.releaseLock();
    return data.join("");
  }  
  async connectCipherDevice() {
    try {
      await this.readAndDisplayCipher();
    } catch(e) {
      console.log('error')
    }    
  }
  
  render() {
    const { loadCipherLab } = this.props
    return (
      <div className="orderUploadWrap">
        {this.state.loading && (
          <div>
            <Loader active />
          </div>
        )}
        {this.state.cipherLoader && (
          <div className='cipherLoaderWrap'>
            <div>
              <p className='red_color'>You must do this within 30 seconds or the process will timeout</p>
              <img src={cipherImag} />
            </div>            
          </div>
        )}
      {loadCipherLab ?  <h2 style={{padding:'1rem'}}>Order Upload</h2>:  <h2 style={{padding:'1rem'}}>Order Upload</h2>}
      <h4 className='red_color' style={{marginTop:'0',width:"100%", textAlign:'center'}}> This tool should not be used to create an event order. All event orders (i.e. Reunion, Retail, Monthly) need to be entered utilizing the event portals. <br/>Please note: Order entry should be limited to 990 items. This will assist in processing your orders without errors.</h4>
      <div className='order-upload'>
        <div className='page-header mb-2'>
          <h4>Excel Order Upload</h4>  
        </div>
        {this.props.multiStoreDetails.access === 1 ? (
          <>
           <h4> You don't have the permission to view this page. Please contact the True Value Administrator. </h4>
          </>
         ) :
        <div className='base-line'>
          <div className='displayFlex mobileUploadBtn'>
          <div>
              <span className='op-clear-label' onClick={this.downloadTemplate}>
                Download Template
              </span>
          </div>
            <div>
              <input
                type='file'
                name='upload-file'
                id='upload-file'
                onChange={this.chooseFile}
                className='custom-file-input'
              />
              <input
                type='text'
                ref={this.fileLabel}
                readOnly
                className='choose-file-label'
                value={this.state.fileName}
              ></input>
              <label htmlFor='upload-file' className='custom-input-btn'>
                Choose File
              </label>
            </div>
            <div>
              {!this.props.isReunionOnlyStore ?
              <button className='fluid ui red button submit' onClick={this.uploadFile} disabled={this.state.disableSubmit}>
                Submit
              </button>
              :<>
                <button className='fluid ui red button submit' disabled>
                  Submit
                </button>
                <p class="disabledMessage">*Ordering restricted</p>
              </>
              }
            </div>
          </div>
          {!utilities.isEmptyOrNullString(this.state.errorMessage) && 
           <div className='displayFlex pt-4'>
              <span className='error-span Alert'>{this.state.errorMessage}</span>
           </div>
          }
          <div className='upload-instructions-div normal-font'>
            <h4>INSTRUCTIONS:</h4>
            <ol>
              <li>Download the provided Excel Template or in Excel(.xlsx) make 2 columns with headings: "SKU" and "Quantity".</li>
              <li>Starting on Line 2 populate the "SKU" column with the 6 digit item numbers and the "Quantity" column with the quantity for the corresponding item number.</li>
              <li>Save the Excel sheet.</li>
              <li>On this screen, select Choose File.</li>
              <li>Browse to the Excel file that was saved.</li>
              <li>Click the Submit button.</li>
              <li>The page will display each line of the order. Correct any errors displayed or delete the order line in error. Click Add to Cart. Go to the Cart to place the order by clicking the Cart icon on the Netwarehouse Site header.</li>
            </ol>
          </div>
        </div>
        }
      </div>
      {loadCipherLab && (
      <div className='order-upload'>
      <div className='page-header mb-2'>
          <h4>Cipher Lab Order Upload</h4>
          <p className='red_color'>Only 1 order will be uploaded at a time. Please tag orders separately in Cipher Lab device and follow instructions below for each order. If uploading orders from multiple stores, ensure that the device and Netwarehouse login reflect the appropriate store number.</p>
      </div>
      <button class="red fluid ui button cipherBtn" onClick={async () => {await this.connectCipherDevice();} }>Connect and Read</button>
      <div className='upload-instructions-div normal-font'>
            <h4>INSTRUCTIONS:</h4>
            <ol>
              <li>Place the Cipher Lab into its cradle.</li>
              <li>Get the Cipher Lab into the “Ready to Xmit” mode.</li>
              <li>Click on the Connect and Read button above.</li>
              <li>On the Cipher Lab, press the blue “Enter” key.
              <p>NOTE: You must press the appropriate key within 30 seconds or the process will time out.</p>
              </li> 
              <li>The page will display each line of the order. Correct any errors displayed or delete the order line in error. Click Add to Cart.</li>
              <li>Go to the Cart to place the order by clicking the cart icon on the Netwarehouse Site header.</li>          
            </ol>
            
      </div>
      <div className='cursor_p' style={{color:"#4183c4", padding:"1rem 0", textDecoration:'underline'}}onClick={() => {  utilities.openInNewTab('https://aem.membersonline.com/content/MOL/place/store-operations-and-environment/retail-systems-pos/download-files.html') }}>
        Please click for CIPHER LAB USB Driver Downloads
      </div>
      </div>
      )}
      <p style={{textAlign: 'center', flexGrow:'1', padding:'1rem 0', width:'100%'}}>For assistance, please contact the True Value Help Desk at 800-621-6025 option 3 or <a  style={{color:"#4183c4", padding:"1rem 0", textDecoration:'underline'}}>membersonlinehelp@truevalue.com</a></p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { SessionReducer } = state;
  const { storeId, userName , address} = SessionReducer;
  return {
    storeId,
    userName,
    loadCipherLab: address?.loadCipherLab,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    isReunionOnlyStore: state.SessionReducer.address.isReunionOnlyStore,
    groupCollectionId: state.SessionReducer.address ? state.SessionReducer.address.groupCollectionId : '',
    aliasSKURetailerGrpID: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.aliasSKURetailerGrpID : ''
  };
};

export default connect(mapStateToProps, null)(OrderUpload);