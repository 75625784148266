import React from 'react';
import utilities from '../../../utils/utilities';
import restUtils from '../../../utils/restUtils';
import { PALLET_ITEM_LISTING, DROPSHIP_PALLET_ITEMLISTING } from '../../../urlConstants';

export const getPalletItemListingShipWeek = (shipDate, qty, handleQtyChange, index,flowType,getPrefer) => {
  return (
    <div className="column dropshipWeek">
      <span className="dc_card_specific">{parseInt(flowType) === 5 ?shipDate: utilities.getDateFromTimeStamp(new Date(shipDate))}</span>
      <span className="dc_card_qty_space">
        <input className="qty-box"
          value={isNaN(qty) ? '':qty}
          onChange={handleQtyChange}
          autoFocus={getPrefer}
          data-flow-type={"3"}
          data-index={parseInt(flowType) === 5 ? shipDate:index}
          type="text" />
      </span>
    </div>
  )
}

export const getPalletItemListingShipMonth = (shipDate, qty, handleQtyChange, index) => {
  return (
    <div className="column">
      <span className="dc_card_specific">{shipDate}</span>
      <span className="dc_card_qty_space">
        <input className="qty-box"
          value={qty}
          onChange={handleQtyChange}
          data-flow-type={"5"}
          data-index={index}
          type="text" />
      </span>
    </div>
  )
}

export const getPalletItems = function () {
  const { pallet, palletItems } = this.state;
  this.setState({ palletItemsLoading: true })
  const payload = {
    promoNbr: pallet.promoNbr,
    relayNbr: pallet.relayNbr,
    relayYear: pallet.relayYear,
    page: this.palletPage,
    memberNbr: this.props.getprefer.storeId,
    resultPerPage: 50,
    palletNbr: pallet.palletNbr,
    sortBy: "item_nbr",
    sortOrder: "desc",
    rdcNbr: this.props.rdc,
    shoppingId: this.props.shoppingAreaId,
    aliasSKURetailerGrpID: utilities.isAliasSkuRequired(
      this.props.multiStoreDetails
    )
      ? 1
      : ''
  }
  restUtils
    .postData(PALLET_ITEM_LISTING, payload)
    .then(response => {
      const newPalletItems = response.data.palletItems;
      this.setState({
        palletItems: [...palletItems, ...newPalletItems],
        palletItemsLoading: false,
        totalItemLength: response?.data?.pagination?.totalItems
      });
    }).catch(err => {
      this.setState({ palletItemsLoading: false })
    })
}

export const getDropShipPalletItems = function () {
  const { pallet, palletItems } = this.state;
  this.setState({ palletItemsLoading: true })
  const payload = {
    vendorId: pallet.EventSupplierSelectionID,
    promoNbr: pallet.promoNbr,
    palletId: pallet.dsPalletId,
    page: this.palletPage,
    resultPerPage: 50,
    palletNbr: pallet.palletNbr,
    sortBy: "item_nbr",
    sortOrder: "desc",
    shoppingId: this.props.shoppingAreaId,
    aliasSKURetailerGrpID: utilities.isAliasSkuRequired(
      this.props.multiStoreDetails
    )
      ? 1
      : ''
  }
  restUtils
    .postData(DROPSHIP_PALLET_ITEMLISTING, payload)
    .then(response => {
      const newPalletItems = response.data.palletItems;
      this.setState({
        palletItems: [...palletItems, ...newPalletItems],
        palletItemsLoading: false,
        totalItemLength: response?.data?.pagination?.totalItems
      });
    }).catch(err => {
      this.setState({ palletItemsLoading: false })
    })
}

export const getShipWeekQty = (shipDate, userQty, pallet, handleQtyChange, index, fieldName, isGridView) => {
  if (!utilities.isEmptyOrNullString(shipDate)) {
    const dateLabel = utilities.getDateFromTimeStamp(new Date(shipDate));
    return (

      <div className={isGridView ? 'ui two column grid' : ''}>
        <span className={isGridView ? 'column' : ''}>{dateLabel}</span>
        {!isGridView && <>&nbsp;</>}
        <span className={isGridView ? 'column' : ''}>
          <input
            type="text"
            id={"qty-box" + pallet.palletNbr + dateLabel}
            data-field={fieldName}
            data-index={index}
            data-pack={pallet.pack}
            data-flow-type = {"3"}
            onChange={handleQtyChange}
            value={userQty && parseInt(userQty) > 0 ? userQty :'' }
            className="qty-box"
          />
        </span>
      </div>
    );
  }
}

export const getDropShipWeekQty = (shipDate, userQty, pallet, handleQtyChange, index, fieldName, isGridView) => {
  if (!utilities.isEmptyOrNullString(shipDate)) {
    const dateLabel = shipDate;
    return (
      <div className={isGridView ? 'ui two column grid' : ''}>
        <span className={isGridView ? 'column' : ''}>{dateLabel}</span>
        {!isGridView && <>&nbsp;</>}
        <span className={isGridView ? 'column' : ''}>
          <input
            type="text"
            id={"qty-box" + pallet.palletNbr + dateLabel}
            name={shipDate}
            data-field={fieldName}
            data-index={index}
            data-pack={pallet.pack}
            data-flow-type = {"5"}
            onChange={handleQtyChange}
            value={userQty}
            className="qty-box"
          />
        </span>
      </div>
    );
  }
}