import restUtils from '../../utils/restUtils';
import { persistor } from '../../configureStore';
import { loginUrl, JWKS_URI, getRedirectUri, getSingleSignOnUrl } from '../../urlConstants';
import Axios from 'axios';
import utilities from '../../utils/utilities';

export const LOGIN = 'LOGIN_ACTION';
export const AUTHORIZATION = 'AUTHORIZATION';
export const GET_SECRET = 'GET_SECRET';
export const LOGOUT = 'LOGOUT';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_CHILDSTORESELECTED = 'SET_CHILDSTORESELECTED';
export const HIDEREUNIONPOPUP ='HIDEREUNIONPOPUP'
export const SETMENU = 'SETMENU'
export const SETWPMENU = 'SETWPMENU'
export const SETVENDORPOPUP = 'SETVENDORPOPUP'
export const SETHEREFORYOU = 'SETHEREFORYOU'
export const SETHEREFORYOUDROPDOWN = 'SETHEREFORYOUDROPDOWN'

export const performLogin = (UserId, Password, StoreId) => {
  return dispatch => {
    const postObj = {
      StoreId,
      UserId,
      Password
    };
    restUtils
      .postData(loginUrl, postObj)
      .then(response => {
        const user = response.data || null;

        if (user) {
          const { UserId, StoreId, address } = user;
          const isAuthenticated = UserId && UserId !== 0;
          const username = (address && address.primaryContactName) || 'Unknown';

          return dispatch({
            type: LOGIN,
            payload: {
              username,
              UserId,
              storeId: StoreId,
              address,
              isAuthenticated
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const setAuthentication = isAuthenticated => ({
  type: SET_AUTHENTICATION,
  payload: {
    isAuthenticated
  }
});

export const setSelectedChildStore = childStoresSelected => ({
    type: SET_CHILDSTORESELECTED,
    payload: {
      childStoresSelected
    }  
});

export const login = (username, UserId, storeId, address, mainRDCUnderEmergency, isAuthenticated, viewOnly, multiStoreDetails,userguid,storeguid) => {
  return dispatch => {
    dispatch({
      type: LOGIN,
      payload: {
        username,
        UserId,
        storeId,
        address,
        mainRDCUnderEmergency,
        isAuthenticated,
        viewOnly,
        multiStoreDetails,
        userguid,
        storeguid      
      }
    });
  };
};

export const authorizeUser = (id_token, access_token, expires_at) => ({
  type: AUTHORIZATION,
  payload: {
    id_token,
    access_token,
    expires_at
  }
});

export const getSecretKey = (access_token)=>{
  const jwks_url = JWKS_URI();
  return dispatch => {
    Axios.get(jwks_url)
      .then(response=>{
        dispatch({
          type:GET_SECRET,
          payload:response.data.keys[0].x5c[0]
        })
      })
  }
}
export const logout = () => {
  return dispatch => {
    localStorage.clear();
    utilities.cookieClear();
    persistor.purge().then(() => {
      return dispatch({ type: LOGOUT });
    });
  };
};
export const switchStore = (storeNo, username) => {
  const access_token = localStorage.getItem('access_token')
  return dispatch => {
    localStorage.clear();
    sessionStorage.clear();
    utilities.cookieClear();
    persistor.purge().then(() => {
      window.location.replace(`${getSingleSignOnUrl()}/?NwaToken=${access_token}&&storeNumber=${storeNo}&&userName=${username}&&redirectionURL=${getRedirectUri()}`)
    });
  };
};

export const switchBusinessTool = (url) => {
  return dispatch => {
    localStorage.clear();
    sessionStorage.clear();
    utilities.cookieClear();
    persistor.purge().then(() => {
      window.location.replace(url)
    });
  };
};

export const onlyReunion = () => ({type: HIDEREUNIONPOPUP,payload: false})
export const setEntrieMenu = ((menu)=> {
  return {
   type: SETMENU,
   payload: menu
 }
 })
 export const setWpMenu = ((menu)=> {
  return {
   type: SETWPMENU,
   payload: menu
 }
 })
 export const setHereForYou = ((data)=> {
  return {
   type: SETHEREFORYOU,
   payload: data
 }
 })

 export const setHereForYouDropdown = ((data)=>{
  return {
    type: SETHEREFORYOUDROPDOWN,
    payload: data
  }
 })

 export const setVendorPopUp = (value) => ({type: SETVENDORPOPUP,payload: value})
 