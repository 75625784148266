import React, { Component } from 'react';
import history from '../../history';
import { MaskPrice } from '../../views/common/MaskPrice';
import utilities from '../../utils/utilities';
import { connect } from 'react-redux';
import { getPlanograms } from './actions';
import { bindActionCreators } from 'redux';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class PlanogramLandingListing extends Component {
  showItemList = (planogram, backPath) => {
    this.props.setBackButtonPath(planogram);
    planogram = { ...planogram, backPath: backPath }    
    history.push({
      pathname: '/planogram/' + planogram.planogramNumber,
      state: {
        planogram: planogram,
        seleDept: planogram.departmentName,
        seleCatg: planogram.categoryName,
        backPath: planogram.backPath
      }
    });
  };

  render() {
    const {viewType} = this.props
    return (
      <div className='ui stackable one column grid' id='listing_grid'>
        {this.props.isNoProducts ? (
          <div className='no_prdcts_display'>
            <h1>No Planograms to display!</h1>
          </div>
        ) : (
            this.props.planograms.map(pl => {
              return (
                <div className='column pl-card'>
                  <PlanogramCard
                    PogMbr={this.props.multiStoreDetails.PogMbr}
                    planogram={pl}
                    showItemList={this.showItemList}
                    viewType={viewType}
                    planogram_count={this.props.planograms.length}
                    multiStoreDetails={this.props.multiStoreDetails}
                    backPath={this.props.backPath}
                    getprefer={this.props.getprefer}
                  />
                </div>
              );
          })
          )}
      </div>
    );
  }
}

const PlanogramCard = ({ planogram, showItemList, viewType, planogram_count, multiStoreDetails, PogMbr, backPath, getprefer }) => {
  let listing_fields = [];
  const memberCost = (multiStoreDetails.access !== 1 || PogMbr)
    ? <MaskPrice
      viewType={viewType}
      fieldVal={planogram.memberCost}
      getprefer={getprefer && getprefer.customerView ? getprefer.customerView : 'c1'}
      field='Member Cost'
    />
    : ''
  listing_fields = [
    ...(multiStoreDetails.access !== 1 || PogMbr
      ? [
        {
          label: 'Size:',
          val: utilities.hyphenForNull(planogram.sizeString),
          classLabel: 'pl_list_label_size',
          classValue: 'list_val_size'
        },
        {
          label: 'SKUs:',
          val: utilities.hyphenForNull(planogram.nbrSkus),
          classLabel: 'pl_list_label_sku',
          classValue: 'list_val_sku'
        },
        {
          label: 'Urbanicity:',
          val: utilities.hyphenForNull(planogram.urbanicity),
          classLabel: 'pl_list_label_urbanicty',
          classValue: 'list_val_urbanicty'
        },
        false &&{
          label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':',
          val: utilities.hyphenForNull(memberCost),
          classLabel: 'pl_list_label_membercost',
          classValue: 'list_val_membercost'
        },
        {
          label: '',
          clickHandler: showItemList,
          val: utilities.hyphenForNull(planogram.planogramNumber),
          classLabel: 'pl_list_label_size',
          classValue: 'list_val_size'
        },
        {
          label: 'Type:',
          val: utilities.hyphenForNull(planogram.type),
          classLabel: 'pl_list_label_size',
          classValue: 'list_val_size'
        },
        {
          label: 'Region:',
          val: utilities.hyphenForNull(planogram.region && planogram.region.replace('-', '')),
          classLabel: 'pl_list_label_sku',
          classValue: 'list_val_sku'
        },
        {
          label: 'Store Typical:',
          val: utilities.hyphenForNull(planogram.storeTypical),
          classLabel: 'pl_list_label_urbanicty',
          classValue: 'list_val_urbanicty'
        },
        {
          label: 'Last Updated:',
          val: utilities.hyphenForNull(
            utilities.getDateFromTimeStamp(planogram.lastUpdatedDate)
          ),
          classLabel: 'pl_list_label_membercost',
          classValue: 'list_val_membercost'
        }
      ]
      :
      [
        {
          label: 'Size:',
          val: utilities.hyphenForNull(planogram.sizeString),
          classLabel: 'pl_list_label_size',
          classValue: 'list_val_size'
        },
        {
          label: 'SKUs:',
          val: utilities.hyphenForNull(planogram.nbrSkus),
          classLabel: 'pl_list_label_sku',
          classValue: 'list_val_sku'
        },
        {
          label: 'Urbanicity:',
          val: utilities.hyphenForNull(planogram.urbanicity),
          classLabel: 'pl_list_label_urbanicty',
          classValue: 'list_val_urbanicty'
        },
        {
          label: '',
          clickHandler: showItemList,
          val: utilities.hyphenForNull(planogram.planogramNumber),
          classLabel: 'pl_list_label_size',
          classValue: 'list_val_size'
        },
        {
          label: 'Type:',
          val: utilities.hyphenForNull(planogram.type),
          classLabel: 'pl_list_label_size',
          classValue: 'list_val_size'
        },
        {
          label: 'Region:',
          val: utilities.hyphenForNull(planogram.region && planogram.region.replace('-','')),
          classLabel: 'pl_list_label_sku',
          classValue: 'list_val_sku'
        },
        {
          label: 'Store Typical:',
          val: utilities.hyphenForNull(planogram.storeTypical),
          classLabel: 'pl_list_label_urbanicty',
          classValue: 'list_val_urbanicty'
        },
        {
          label: 'Last Updated:',
          val: utilities.hyphenForNull(
            utilities.getDateFromTimeStamp(planogram.lastUpdatedDate)
          ),
          classLabel: 'pl_list_label_membercost',
          classValue: 'list_val_membercost'
        }
      ])
  ];
  return (
    <>
      <div style={{ width: '100%' }}>
        <div className='ui stackable five column grid pl-card-grid planogrid'>
          <div className='column'>
            {planogram.planogramSubName}
          </div>
          {listing_fields.map(cell => (
            <div className='column'>
              <span className={`${cell.classLabel}`} style={{ ...cell.style }}>{cell.label}{' '}</span>
              <span onClick={() => { cell.clickHandler(planogram) }}
                className={cell.label
                  ? `${cell.classValue} pl_list_fields`
                  : 'pl-asstId'
                }> {cell.val}</span>
            </div>
          ))}
        </div>
        <div className='bottom_btns'>
          <span>View Assortment: </span>
          <i className='file pdf icon large planogramexportie' id='export_btns' onClick={() => utilities.openInNewTab(planogram.pdfUrl)} />
          <button className='red fluid ui button' id='view_order_btn' onClick={() => showItemList(planogram, backPath)} >
            View/Order Items
          </button>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch)=>{
  return Object.assign({ dispatch }, bindActionCreators({getPlanograms}, dispatch));
}

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    getprefer: state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramLandingListing);
