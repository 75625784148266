import React, { Component } from 'react';
import { getWordpressGraphQLContent } from '../../urlConstants';
import restUtils from '../../utils/restUtils';
import GetContent from '../WordPress/GetContent';
import './footer.css'

class Footer extends Component {
  constructor(props){
    super(props);
    this.state={
      data : []
    }
  }
  componentDidMount() {
    const footerDataStorage = localStorage.getItem("footerData");
    const footerData = footerDataStorage ? JSON.parse(footerDataStorage) : '';
    if(footerData && window.location.pathname !== '/home'){
      this.setState({data: footerData})
    } else {
      restUtils.getDataWithBearer(`${getWordpressGraphQLContent()}/?type=page&id=tvnwh-footer`).then(response=>{
        const data = response.data
        this.setState({data})
        localStorage.setItem("footerData", JSON.stringify(data));
      }).catch(err=>{
        console.log(err)
      })
    }
  }
  render() {
    return(
      <div>
      {this.state.data && <GetContent data={this.state.data} />}
  </div>
    )

  }
}

export default Footer;
