import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../index.css';
import {
  fetchDepartments,
  fetchDepartmentClasses,
  fetchDepartmentSubclasses,
  fetchDepartmentClassesSuccess,
  fetchDepartmentSubclassesSuccess,
  addToCatalog,
  clearCatalog
} from '../actions';
import RefineFilterList from './RefineFilterList';
import { utilities } from '../../../utils/utilities';

const initialState = {
  filtersToggled: {
    "$member_cost_filter": false,
    "$suggested_retail_filter": false,
    "$fineline_department_filter": true,
    "$fineline_class_filter": true,
  },
  departmentSelected: null,
  classSelected: { id: 'All Classes', value: "All Classes", name: "All Classes" },
  subClassesSelected: [],
  memberCostOptionSelected: {
    value: "Show", id: "show"
  },
  suggestedRetailOptionSelected: {
    value: "Show", id: 'show'
  }
}

class FinelineCatalogRefineSelection extends React.Component {
  state = {
    ...initialState
  }

  componentDidMount() {
    const { getDepartments } = this.props;
    getDepartments();
  }

  componentWillUnmount() {
    this.clearDepartmentValues();    
    this.props.clearCatalog(true);
  }

  toggleFilterCategory = (id) => () => {
    this.setState((prevState) => ({
      filtersToggled: {
        ...prevState.filtersToggled,
        [id]: !prevState.filtersToggled[id]
      }
    }));
  }

  clearDepartmentValues = () => {
    const { dispatchSuccessClasses, dispatchSuccessSubclasses } = this.props;
    dispatchSuccessClasses([]);
    dispatchSuccessSubclasses([]);
  }

  handleAddToCatalog = () => {
    const { departmentSelected, classSelected, subClassesSelected } = this.state;
    const { addDepartmentToCatalog,} = this.props;

    if (!departmentSelected) {
      utilities.showToast('Selection Required: Need to select a department to add.', true);
      return;
    }
    addDepartmentToCatalog(departmentSelected, classSelected, subClassesSelected);
    this.clearDepartmentValues();
    this.setState(initialState);
  }

  handleSelectDepartment = (deptItem) => () => {
    const { id: depId } = deptItem;
    const { getDepartmentClasses } = this.props;
    getDepartmentClasses(depId);
    
    this.setState((prevState) => {
      const { departmentSelected } = prevState;
      
      if (departmentSelected && (departmentSelected.id === depId)) {
        return { departmentSelected: null, classSelected: null }
      }
      
      return { departmentSelected: deptItem }
    });
  };

  handleSelectClass = (classItem) => () => {
    const { id: classId } = classItem;
    const { departmentSelected } = this.state;
    const { getDepartmentSubclasses, dispatchSuccessSubclasses } = this.props;

    if (classId === 'All Classes') {
      dispatchSuccessSubclasses([]);
    } else if (departmentSelected) {
      getDepartmentSubclasses(departmentSelected.id, classId);
    }

    this.setState((prevState) => {
      const { classSelected } = prevState;
      
      if (classSelected && (classId === classSelected.id)) {
        return { classSelected: null }
      }

      return { classSelected: classItem }
    });
  };

  handleSelectSubclass = (subClass) => () => {
    this.setState((prevState) => {
      const { subClassesSelected } = prevState;
      const selectedSubclass = !!subClassesSelected.find((sc) => sc.id === subClass.id);

      const newSubclasses = selectedSubclass ?
        subClassesSelected.filter((sc) => sc.id !== subClass.id) :
        [ ...subClassesSelected, subClass ];

      return { subClassesSelected: newSubclasses };
    });
  }

  handleChangeFilter = (item) => (e) => {
    const filterOption = e.target.name;

    this.setState({
      [filterOption]: item,
    });
  }

  renderFilters = () => {
    const {
      toggleShowFilter,
      departments,
      classes,
      subClasses,
      isLoading
    } = this.props;

    const {
      filtersToggled,
      departmentSelected,
      classSelected,
      suggestedRetailOptionSelected,
      memberCostOptionSelected,
      subClassesSelected
    } = this.state;

    const filtersApplied = departmentSelected || classSelected;

    return (
      <>
        <button
          className="fluid ui red button add-to-catalog-button"
          onClick={this.handleAddToCatalog}
        >
          Add to Catalog
        </button>
        <div className="assortmentFilters filters">
          <span className='filter-head'>
            Refine Selection
            <span className={`refine_selection_icon ${filtersApplied?'applied-filter':''}`}
              onClick={toggleShowFilter}
            >
              <i className="filter icon" />
              <span className='shrink-arrow'>«</span>
            </span>
          </span>
          <RefineFilterList
            filtersToggled={filtersToggled}
            departments={departments}
            classes={classes}
            subClasses={subClasses}
            toggleFilterCategory={this.toggleFilterCategory}
            selectDepartment={this.handleSelectDepartment}
            selectClass={this.handleSelectClass}
            selectSubclass={this.handleSelectSubclass}
            handleChangeFilter={this.handleChangeFilter}
            departmentSelected={departmentSelected}
            classSelected={classSelected}
            subClassesSelected={subClassesSelected}
            suggestedRetailOptionSelected={suggestedRetailOptionSelected}
            memberCostOptionSelected={memberCostOptionSelected}
            isLoading={isLoading}
          />
        </div>
        <button
          className="fluid ui red button add-to-catalog-button"
          onClick={this.handleAddToCatalog}
        >
          Add to Catalog
        </button>
      </>
    )
  }

  render() {
    const { toggleShowFilter, showFilters } = this.props;

    return (
      <div>
        { !showFilters && (
          <i
            className={'filter icon applied-filter'}
            id='filter_symbol'
            onClick={toggleShowFilter}
          >
            <span className='expand-arrow'>»</span>
          </i>
        )}
        { showFilters && this.renderFilters() }
      </div>
    );
  }
};


FinelineCatalogRefineSelection.propTypes = {
  toggleShowFilter: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
  getDepartments: PropTypes.func.isRequired,
  departments: PropTypes.array,
  isLoading: PropTypes.object,
  departmentsError: PropTypes.any,
  getDepartmentClasses: PropTypes.func.isRequired,
  getDepartmentSubclasses: PropTypes.func.isRequired,
  dispatchSuccessClasses: PropTypes.func.isRequired,
  dispatchSuccessSubclasses: PropTypes.func.isRequired,
  addDepartmentToCatalog: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const { finelineCatalog } = state;
  const departmentsState = finelineCatalog.departments;

  return {
    departments: departmentsState.departments,
    isLoading: departmentsState.loading,
    departmentsError: departmentsState.error,
    classes: departmentsState.classes,
    subClasses: departmentsState.subClasses
  }
};

const mapDispatchToProps = dispatch => ({
  getDepartments: () => dispatch(fetchDepartments()),
  getDepartmentClasses: (depId) => dispatch(fetchDepartmentClasses(depId)),
  getDepartmentSubclasses: (depId, classId) => dispatch(fetchDepartmentSubclasses(depId, classId)),
  dispatchSuccessClasses: (classes) => dispatch(fetchDepartmentClassesSuccess(classes)),
  dispatchSuccessSubclasses: (subClasses) => dispatch(fetchDepartmentSubclassesSuccess(subClasses)),
  addDepartmentToCatalog: (department, classes, subClasses) => dispatch(addToCatalog(department, classes, subClasses)),
  clearCatalog: (clearItems) => dispatch(clearCatalog(clearItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinelineCatalogRefineSelection);
