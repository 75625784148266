import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";
import { getGA } from "./urlConstants.js";

ReactGA.initialize(getGA());
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.send({
    hitType: "pageview",
    page: location.pathname,
    title: "pageview",
  });
});

//For GA
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export default history;
