import React, { Component } from 'react';
import { getPreferences, updatepreferences } from './action';
import PreferenceCard from './PreferenceCard';
import PreferenceCoption from './PreferenceCoption';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './preference.css';
import {Loader} from 'semantic-ui-react';
import history from '../../history';
import { switchView } from '../../components/NavBar/actions' ;
import utilities from '../../utils/utilities';
import { MEMBER_VIEW, CUSTOMER_VIEW } from '../../components/NavBar/menuConstants';

class Preferences extends Component {
  constructor(props) {
    super(props);
    const { getprefer = {} } = this.props;
    const {
      thumbnailImage = '',
      warehouseProducts,
      resultPerPage,
      prdctListlayoutFormat,
      productSpecView,
      overrideRetail,
      filterView,
      vendorPrdctLayoutFmt,
      customerView,
      viewType,
      cursorPlacement
    } = getprefer;

    this.state = {
      image: thumbnailImage,
      warehouse: warehouseProducts,
      page: resultPerPage,
      view: prdctListlayoutFormat,
      specify: productSpecView,
      orderpad: overrideRetail,
      filters: filterView,
      vendor: vendorPrdctLayoutFmt,
      cost: customerView,
      addclassSave: '',
      addclassCancel: '',
      isLoading:props.loadpreference,
      viewTypes:viewType,
      cursorPlacement: cursorPlacement
    };
  }

  componentDidMount() {
    this.props.switchView(this.state.viewTypes === 'CV' ? CUSTOMER_VIEW : MEMBER_VIEW);
    this.props.getPreferences(this.props.username, this.props.storeId);
    this.setState({
      isLoading:this.props.loadpreference
    });

  }

  onFNameChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  
  handleClick = e => {
    this.setState({
      addclassSave: 'redpreference',
    });
    this.props.switchView(this.state.viewTypes === 'CV' ? CUSTOMER_VIEW : MEMBER_VIEW);
    this.props.updatepreferences(
      this.props.username,
      this.props.storeId,
      utilities.emptyForNull(this.state.cost),
      utilities.emptyForNull(this.state.image),
      utilities.emptyForNull(this.state.warehouse),
      this.state.page === null ? 0 : this.state.page,
      utilities.emptyForNull(this.state.view),
      utilities.emptyForNull(this.state.vendor),
      utilities.emptyForNull(this.state.orderpad),
      utilities.emptyForNull(this.state.filters),
      utilities.emptyForNull(this.state.specify),
      utilities.emptyForNull(this.state.viewTypes),
      utilities.emptyForNull(this.state.cursorPlacement)
    );

    this.setState({
      isLoading:this.props.loadpreference
    });
    utilities.showToast('Preference Saved Successfully');
  };

  handlecancel = () => {
    this.setState({
      addclassCancel: 'redpreference',
    });
    history.push('/home');
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.getprefer !== nextProps.getprefer) {
      const {
        customerView,
        thumbnailImage,
        warehouseProducts,
        resultPerPage,
        prdctListlayoutFormat,
        vendorPrdctLayoutFmt,
        productSpecView,
        overrideRetail,
        filterView,
        viewType,
        cursorPlacement
      } = nextProps.getprefer;
      this.setState({
        image: thumbnailImage ? thumbnailImage : 'Y',
        warehouse: warehouseProducts ? warehouseProducts : 'M',
        page: resultPerPage ? resultPerPage : 50,
        view: prdctListlayoutFormat ? prdctListlayoutFormat : 'L',
        specify: productSpecView ? productSpecView : 'E',
        orderpad: overrideRetail ? overrideRetail : 'Y',
        filters: filterView ? filterView : 'E',
        vendor: vendorPrdctLayoutFmt ? vendorPrdctLayoutFmt :'L',
        cost: customerView ? customerView : 'c1',
        isLoading:false,
        viewTypes:viewType ? viewType: 'MV',
        cursorPlacement: cursorPlacement ? cursorPlacement : 'S'
      });
    }
  }
  
  render() {
    const { isLoading } = this.state;
    return (
      <div className='preferenceDetail'>
        <div className='page_layout'>
          <div className='page-header preference-header'>Preferences</div>
          {isLoading ? (
          <div>
            <Loader active />
          </div>) :
          <>
          <div className='details'>
            <div className='houseHead'>
              <p>Default Netwarehouse settings for : {this.props.username} </p>
            </div>
            <div className='preferenceSave'>
              <input
                onClick={this.handlecancel}
                type='button'
                name='cancel'
                id='cancel'
                className='custom-file-input-cancel'
              />
              <label htmlFor='cancel' className='custom-input-btn-cancel'>
                Cancel
              </label>
              <input
                onClick={this.handleClick}
                type='button'
                name='save'
                id='save'
                className='custom-file-input-save'
              />
              <label
                htmlFor='save'
                className='custom-input-btn-save red button'
              >
                Save
              </label>
            </div>
          </div>
          <div className='preferenceBox'>
          <PreferenceCard
              currentChoice={this.state.viewTypes}
              onFNameChange={this.onFNameChange}
              header='View Type'
              description='Select Default View Type'
              fieldname='viewTypes'
              valuelist={[
                { val: 'MV', label: 'Member View' },
                { val: 'CV', label: 'Customer View' }
              ]}
            />
            <PreferenceCoption
              currentChoice={this.state.cost}
              onSelectChange={this.onFNameChange}
              header='Customer View'
              description='Which costs would you want to be masked or hidden?'
              fieldname='cost'
              valuelist={[
                { val: 'c1', label: 'Mask all Member Cost' },
                {
                  val: 'c2',
                  label: 'Mask all Member Costs and Suggested Retail '
                },
                {
                  val: 'c3',
                  label: 'Hide all Member Costs and Suggested Retail'
                },
                { val: 'c4', label: 'Hide  Member Cost' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.warehouse}
              onFNameChange={this.onFNameChange}
              header='Warehouse'
              description='From where do you want to check availability and place order primarily?'
              fieldname='warehouse'
              valuelist={[
                { val: 'M', label: 'My Warehouse' },
                { val: 'A', label: 'All Warehouses' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.image}
              onFNameChange={this.onFNameChange}
              header='Thumbnail Images'
              description='Would you like to Show  the thumbnails images on product listing page?'
              fieldname='image'
              valuelist={[
                { val: 'Y', label: 'Show' },
                { val: 'N', label: 'Hide' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.page}
              onFNameChange={this.onFNameChange}
              header='Results Shown on Each Page?'
              description='How many items do you prefer to see on each page?'
              fieldname='page'
              valuelist={[
                { val: 50, label: '50' },
                { val: 100, label: '100' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.view}
              onFNameChange={this.onFNameChange}
              header='Layout Format'
              description='Which type of layout would you prefer for the products  listing pages by  default?'
              fieldname='view'
              valuelist={[
                { val: 'C', label: 'Classic View' },
                { val: 'L', label: 'List View' },
                { val: 'G', label: 'Grid View' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.vendor}
              onFNameChange={this.onFNameChange}
              header='Vendor Format'
              description='Which type of layout would you prefer for the Vendor  listing pages by  default?'
              fieldname='vendor'
              valuelist={[
                { val: 'L', label: 'List View' },
                { val: 'G', label: 'Grid View' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.orderpad}
              onFNameChange={this.onFNameChange}
              header='Override Retail on Order Pad'
              description='Would you like to See the Override  Retail column on Order pad by Default?'
              fieldname='orderpad'
              valuelist={[
                { val: 'Y', label: 'Show' },
                { val: 'N', label: 'Hide' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.specify}
              onFNameChange={this.onFNameChange}
              header='Product Specification'
              description='How would you like to have your product  specification sections by default?'
              fieldname='specify'
              valuelist={[
                { val: 'E', label: 'Expanded' },
                { val: 'C', label: 'Collapsed' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.filters}
              onFNameChange={this.onFNameChange}
              header='Filters'
              description='How would you like to have your filter options by default?'
              fieldname='filters'
              valuelist={[
                { val: 'E', label: 'Expanded' },
                { val: 'C', label: 'Collapsed' }
              ]}
            />
            <PreferenceCard
              currentChoice={this.state.cursorPlacement}
              onFNameChange={this.onFNameChange}
              header='Cursor Placement'
              description='On Product Detail pages, I would prefer the cursor to be in:'
              fieldname='cursorPlacement'
              valuelist={[
                { val: 'S', label: 'The Search Box' },
                { val: 'Q', label: 'The Quantity Box' }
              ]}
            />
          </div>
          </>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ getPreferences, updatepreferences,switchView }, dispatch)
  );
};

const mapStateToProps = state => {
  return {
    username: state.SessionReducer.userName,
    storeId: state.SessionReducer.storeId,
    getprefer: state.preference.listData,
    loadpreference:!state.preference.loadpreference
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
