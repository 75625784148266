import React from 'react';

const  downloadFile = (pdfUrl) => () => {
  window.location.href = pdfUrl;
}

const LandingPageContentItem = ({
  id,
  name,
  footerText,
  isSaved,
  loadFlipbookPreview,
  deleteCatalog,
  status,
  pdfUrl
}) => (
  <div className="landing_page_content--item">
    <div className="item_content" onClick={loadFlipbookPreview(pdfUrl)}>
      {name} {!isSaved && `(${id})` } 
    </div>
    {!isSaved && (
      <div className="item_footer" onClick={downloadFile('https://test-pdf-tv.s3.us-east-2.amazonaws.com/Patio+Ordering+Book+FR19_FINAL.pdf')}>
        {footerText}
      </div>
    )}
    {isSaved && (
      <div className="item_footer saved_items">
        { status === 1 && <button disabled className="saved_item_button fluid ui teal button download">Requested ...</button>}
        { status === 2 && <button disabled className="saved_item_button fluid ui teal button download">Processing ...</button>}
        { status === 3 && <button onClick={downloadFile(pdfUrl)} target='_blank' className="saved_item_button fluid ui teal button download">Download</button>}
        <button className="saved_item_button fluid ui teal basic button delete" onClick={deleteCatalog}>Delete</button>
      </div>
    )}
  </div>
);

export default LandingPageContentItem;
