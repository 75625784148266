import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import '../WpTemplate/style.css'
import restUtils from '../../utils/restUtils';
import { getWordpressCustomContent } from '../../urlConstants'
import _ from 'lodash'
import utilities from '../../utils/utilities';
import { Link } from 'react-router-dom';
import { getPagination } from '../common/CustomComponents';

class ContentSearch extends Component {
  constructor(props){
    super(props);
    this.state={
    searchValue: this.props?.match?.params?.searchValue ? this.props?.match?.params?.searchValue : null,
      data: null,
      isLoading: false,
      totalPages: 0,
      currentPage: 1,
      noContent: false,
      heading: this.props?.location.state?.heading ? this.props?.location.state?.heading: ''
    }
    this.page = 1;
    this.scrollPosition = 0;
  }
  componentDidMount(){
   this.processContent(this.props?.match?.params?.searchValue)
  
  }
  componentWillReceiveProps(nextProps) {
    if(this.state.searchValue !== nextProps?.match?.params?.searchValue) {
        this.setState({
            searchValue : nextProps?.match?.params?.searchValue,
            heading: nextProps?.location.state?.heading ? nextProps?.location.state?.heading: ''
          })
          this.processContent(nextProps?.match?.params?.searchValue)  
    }
  }
  processContent = (value) =>{
    this.setState({
      isLoading : true,
      noContent: false
    })
    const totalValueEncode = encodeURIComponent(`search-content?search=${value}&page=${this.page}&per_page=10`)
    restUtils.getDataWithBearer(`${getWordpressCustomContent()}${totalValueEncode}`).then(res=>{
      this.setState({
      totalPages:  res.data.total_results/10,
      data:res.data.results, 
      isLoading: false,
      currentPage: this.page,
      noContent: res.data.results && res.data.results.length > 0 ? false : true})
    }).catch(err => { console.log(err);  this.setState({isLoading : false, noContent: true})})

  }
  
  onPageChange = e => {
    let queryPage = e.currentTarget.text;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? this.state.currentPage + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.page = queryPage;
    this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
    this.processContent(this.state.searchValue);
  };

  render() {
    const { searchValue, data} = this.state
    return (
      <div className='wpTemplateWrapper'>
      {this.state.isLoading && <Loader active /> }
      {!_.isEmpty(searchValue) && (
        <Segment className="wpSearchContent wpTemplateStyle">
        <h3>Search result for: {utilities.replaceSpace(this.state.heading)}</h3>
        {!_.isEmpty(data) &&  data.map(box=>{
          return (
            <div key = {box.id}className='searchContentBlock'>
              <div>
                <Link  to={`/content/${box.slug}`}>{box.title.rendered}</Link>
                <p  dangerouslySetInnerHTML={{__html: box.excerpt.rendered}}></p>
              </div>
             </div>
          )

        })}
        <div style={{display:'flex', justifyContent:'center'}}>
        {this.state.totalPages > 1 && ( 
                  getPagination(0, this.state.currentPage,this.state.totalPages, this.onPageChange, null, 1)                  
          )}
          </div>
      </Segment>
      )}
      { this.state.noContent &&
       <><div className='no_prdcts_display'><h1>No Content to Display!</h1></div></>
      } 
      </div>      
    )
}
}
const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return Object.assign(
      { dispatch },
    bindActionCreators({   }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentSearch);