import React, { Component } from 'react';
import _ from 'lodash';
import { downloadExcel, getRowData } from './exportUtils';
import restUtils from '../../../utils/restUtils';
import { utilities, shouldHideCostForPreference } from '../../../utils/utilities';
import { Loader } from 'semantic-ui-react';

export default class GenericExcelExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  exportToCSV = () => {
    const {
      fileName = 'AddressBook',
      url,
      onExportComplete,
      reqObject,
      viewType,
      getPrefer
    } = this.props;
    if (url) {
      utilities.showToast("Exporting to Excel...");
      let fileNameWithDate = `${fileName}-${utilities.getCurrDate()}.xlsx`;
      const promiseObject = reqObject
        ? restUtils.postData(url, JSON.stringify(reqObject))
        : restUtils.getData(url);

      promiseObject
        .then(response => {
          const { columnDefs } = this.props;
          const { colDefs, responseDataPath } = columnDefs;
          const responseData = _.get(response, responseDataPath, null);

          if (responseData) {
            const exportData = responseData.map(data =>
              getRowData(data, colDefs.filter(header => header.key!== "action:Remove"))
            );
            if(fileName==='ShipLater') {
              let exportShipLater = [...exportData]
              exportShipLater.map(item=> {
                item['Cost Change Date'] = utilities.getDateFromTimeStamp(item['Cost Change Date']).split('/')[2] == '1' ? '':  utilities.getDateFromTimeStamp(item['Cost Change Date'])
                item['Old Ext Cost'] = parseFloat( item['Old Ext Cost']) > 0 ? `$${utilities.addSuffixZero( item['Old Ext Cost'])}` : '';
                item['Old Cost'] = parseFloat( item['Old Cost']) > 0 ? `$${utilities.addSuffixZero( item['Old Cost'])}` : '';
                item['New Cost'] = parseFloat( item['New Cost']) > 0 ? `$${utilities.addSuffixZero( item['New Cost'])}` : '';
                item['New Ext Cost'] = parseFloat( item['New Ext Cost']) > 0 ? `$${utilities.addSuffixZero( item['New Ext Cost'])}` : '';
                
                return item
              })
              downloadExcel(exportShipLater, fileNameWithDate);
            }else if(fileName==='Edit Order') {
              const  hideTotalExtendCost = shouldHideCostForPreference(viewType,getPrefer);
              let exportEditOrder = [...exportData]
              exportEditOrder.map(item=> {
                if(hideTotalExtendCost){
                  const totalCost = item['Total Cost']
                  if(utilities.getLabelsByType(viewType,"TOTAL_COST", getPrefer) === 'TC') {
                    delete item['Total Cost'];
                  }
                  item[utilities.getLabelsByType(viewType,"TOTAL_COST", getPrefer)] = utilities.getCostBasedOnnatvalue(viewType, totalCost, getPrefer);
                } else {
                  delete item['Total Cost'];
                }
                return item
              })
              downloadExcel(exportEditOrder, fileNameWithDate);
            }else {
              downloadExcel(exportData, fileNameWithDate);
            }
            
          }
          this.setState({ loading: false }, () => {
            if (onExportComplete) {
              onExportComplete({
                payload: response,
                status: 'Success'
              });
            }
          });
        })
        .catch(error => {
          utilities.showToast("Unexpected error occured please try after some time");
          this.setState({ loading: false }, () => {
            if (onExportComplete) {
              onExportComplete({ payload: error, status: 'Error' });
            }
          });
        });
    }
  };

  render() {
    const { loading } = this.state;
    const {
      className,
      title = 'Export',
      iconClassName = 'file excel icon large',
      style = { cursor: 'pointer', verticalAlign: 'initial' }
    } = this.props;

    return (
      <>
        {loading && <Loader active />}
        <span className={className}>
          <span>{title}</span>
          <i
            className={iconClassName}
            style={style}
            onClick={this.exportToCSV}
          ></i>
        </span>
      </>
    );
  }
}
