import React from 'react';
import '../styles.css';
import { Loader, } from 'semantic-ui-react';
import InternalServerError from '../../ErrorPages/500_error_page';
import {connect} from 'react-redux';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import MonthlySpecialsEventListing from './MonthlySpecialsEventListing';
import { getTextNameField } from '../../common/ComponentUtilities';
import { saleType } from '../../../utils/utilities';

class MonthlySpecialsEvents extends React.Component {
    constructor(props){
        super(props);
        const { path } = this.props.match;
        const eventId='';
        const event = props.reunionEvents && props.reunionEvents.find(event=>{
            return parseInt(event.promo_nbr)===parseInt(eventId);
        })
        this.state={
            eventId ,
            searchby : "itemnbr",
            searchValue : '',
            dealDetails: false,
            eventIdReunion: event?event.relay_nbr:'',
            promoNmbr: event?event.promo_nbr:'',
            promoName: event?event.PrmoName:'',
            serverError: false,
            isLoading:false
        }
        this.searchParam = "itemnbr";
        this.searchValue = '';
    }
    handleSearchValueChange = (e) =>{
        this.searchValue = e.target.value;
    }
    
    handleSearchParamChange = (e) =>{
        this.searchParam = e.target.value
    }

    search = (e) =>{
        if (e.keyCode === 13) {
            this.setState({
                searchby:this.searchParam,
                searchValue : this.searchValue
            })
          }
    }
    showDealDetails = () => this.setState({ dealDetails: true});

    DialogClose = () => this.setState({ dealDetails: false });
    getHeader = () =>{
        const saleLabel = saleType(this.props.monthlySizzleSaleValue)
        return this.props.location.pathname.indexOf("wow")>-1?"WOW": saleLabel;
    }
    render() {
        const { vendorDetails, searchby, searchValue } = this.state;
        const header = this.getHeader();
        let breadcrumbPathMonthlySpecial = [
            { link: '/home', text: 'HOME' },
            { link: '#', text: header}
        ];
        return (
            this.state.serverError ?
                <InternalServerError />
                :
                <div className="page-wrap">
                    {this.state.isLoading && (
                        <div>
                            <Loader active />
                        </div>
                    )}

                    <div className='page-header'>
                        {header}
                    </div>
                    <BreadcrumbComp path={breadcrumbPathMonthlySpecial}/>
                    <br/>
                    <MonthlySpecialsEventListing  
                        pathname={this.props.location && this.props.location.pathname} 
                        setListType={this.setListType} 
                        searchby={searchby} 
                        searchvalue={searchValue} 
                        isWow = {header==="WOW"}
                        />
                </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        reunionEvents : state.ReunionReducer.events,
        monthlySizzleSaleValue: state.cart?.orders?.monthlySizzleSaleValue
    }
}

export default connect(mapStateToProps, null)(MonthlySpecialsEvents);
