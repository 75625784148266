import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Loader } from 'semantic-ui-react';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';
import { getEventRedirectUrl } from './eventRedirectUtilities';
import utilities from '../../../utils/utilities';
import restUtils from '../../../utils/restUtils';
import AssortmentListView from './AssortmentListView';
import AssortmentGridView from './AssortmentGridView';
import { ASSORTMENT_LISTS } from '../../../urlConstants';
import { ASSORTMENT_UNAVAILABLE_TEXT } from '../../common/constants';
import { getPagination } from "../../common/CustomComponents";
import Filter from "./Filter";
import { isFilterRequired } from "./ReunionComponentUtilities";
import {
  getPageNumber, getBreadcrumbLinks
} from "./itemListingUtilities";
import { isArray } from "util";
import _ from "lodash";
import history from '../../../history';
import UnderConstruction from "../../UnderConstruction";

class AssortmentsList extends Component {
  constructor(props) {
    super(props);
    const { 
      flowType, 
      eventId, 
      fromReunionHome, 
      relayNbr, 
      shoppingId
    } = props.match?.params ? props.match?.params: props ;
    const eventName = decodeURIComponent(props?.match?.params?.eventName);
    const shoppingAreaName = props?.match?.params?.shoppingAreaName ? decodeURIComponent(props?.match?.params?.shoppingAreaName): props?.shoppingAreaName;
    const shoppingAreaMetaId = props?.location?.state?.shoppingAreaMetaId ? decodeURIComponent(props?.location?.state?.shoppingAreaMetaId): props?.shoppingMetaDataId;
    this.state = {
      assortments: [],
      flowType : flowType,
      relayNbr: relayNbr,
      eventName : eventName,
      isGridView:
        props.getprefer &&
        props.getprefer.prdctListlayoutFormat &&
        props.getprefer.prdctListlayoutFormat !== "L",
      isNoProducts: false,
      serverError: false,
      isLoading: false,
      seleDeptName: '',
      searchType:  this.props?.searchBy ? this.props?.searchBy:'itemNbr',
      isFilterRequired: isFilterRequired(flowType),
      filters: [],
      eventEnableAfter: '',
      eventEnableBefore:'',
      eventMessage: '',
      clearFilters: false,
      appliedFilters: {},
      endDate: '',
      searchValue: this.props?.searchValue ? this.props?.searchValue:'',
      backPath: '',
      promoName: eventName ,
      shoppingAreaName: shoppingAreaName,
      header: shoppingAreaName,
      promoNmbr: eventId,
      shoppingAreaId: shoppingId,
      shoppingAreaMetaId : shoppingAreaMetaId,
      showFilters: utilities.getShowFilters(this.props.getprefer),
      paginationParams: {},
      fromReunionHome: fromReunionHome,
      dynamicErrorMsg: ''
    }
    this.pageSize = this.props?.getprefer?.resultPerPage
      ? this.props.getprefer.resultPerPage
      : '50';
    this.sortParam = "saving";
    this.sortDirection = "asc";
    this.searchBy =  this.props?.searchBy ? this.props?.searchBy: "itemNbr";
    this.searchValue = this.props?.searchValue ? this.props?.searchValue:'';
    this.page = 1;
    this.filterList = "";
    this.appliedFilters = {};
    this.pathname = ""
    this.scrollPosition = 0;
    this.patterns = {
      itemNbr: '[0-9]+',
      model: "[a-zA-Z0-9-/. ]*",
    };
    this.hideFilter = this.props.fromScanning && !_.isEmpty(this.props.searchValue) && true
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    } else {
      this.getAssortmentsData();
    }

    if (this.props?.location?.state !== undefined) {
      this.setState(this.props.location.state)
    }

  }

  componentWillReceiveProps(nextProps) {
    const {
      searchType,
      searchValue
    } = nextProps.match ? nextProps.match.params : this.state;
    const { promoName } = this?.props?.location?.state ? this.props.location.state : this.state;
    this.filterList = "";
    this.appliedFilters = {};
    this.searchBy = nextProps?.searchBy ? nextProps?.searchBy:'itemNbr';
	  this.searchValue = nextProps?.searchValue ? nextProps?.searchValue:'';
    this.sortParam = '';
    this.sortDirection = '';
    this.setState({
      appliedFilters: {},
      clearFilters: true,
      searchValue: nextProps?.searchValue ? nextProps?.searchValue:'',
    });
    if (
      this.state.seleDeptName !== promoName ||
      this.state.searchType !== searchType ||
      this.state.searchValue !== searchValue
    ) {
      this.pathname = nextProps.location && nextProps.location.pathname;
      this.getAssortmentsData(nextProps);
    }
  }

  getUrlFilters = (queryParam) => {
    let { promoName } = this?.props?.location?.state ? this.props.location.state : this.state;
    let searchBy = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
    if (searchBy !== '') {
      let search = searchBy.split('=');
      this.searchBy = search[0];
      this.searchValue = search[1].split('&')[0];
    }
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' :
      queryParam[1].includes('SortType=desc') ? 'desc' : '';
    this.scrollPosition = queryParam[1].includes('scrollPosition=') ? queryParam[1].split('scrollPosition=')[1].split('&')[0] : 0;
    this.filterList = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    this.appliedFilters = utilities.getFilterArrayDecode(this.filterList);
    this.filterList = utilities.getQueryJsonAssortment(this.appliedFilters);
    this.setState({
      seleDeptName: promoName,
      appliedFilters: this.appliedFilters,
      searchValue: this.searchValue,
      backPath: this.pathname
    }, () => {
      this.getAssortmentsData();
    });
  }

  handleViewChange = (isGridView) => {
    this.setState({ isGridView });
  }

  getAssortmentsData = () => {
    this.setState({ isLoading: true });
    const url = `${ASSORTMENT_LISTS}`;
    if (this.filterList.filterBy === undefined || this.filterList.filterBy.length === 0) {
      const queryJson = [];
      const deptArr = {
        "name": "Department",
        "valueList": [this.state.seleDeptName]
      };
      queryJson.push(deptArr)
      this.filterString = { filterBy: queryJson };
    }
    const requestPayLoad = {
      rdcNbr:this.props.rdc,
      shoppingId:this.state.shoppingAreaId,
      promoNbr: this.state.promoNmbr,
      relayNbr: this.state.relayNbr,
      searchBy: this.searchBy,
      searchValue: this.searchValue,
      shoppingId: this.state.shoppingAreaId,
      memberNbr: this.props.storeId,
      sortBy: this.sortParam,
      sortOrder: this.sortDirection,
      page: this.page,
      resultPerPage: this.pageSize,
      ...this.filterList
    }
    restUtils.postData(url, requestPayLoad).then((response) => {
      if(response.data && response.data.hasOwnProperty('reunionRegistrationStatus')){
        this.setState({
          dynamicErrorMsg: !response.data.reunionRegistrationStatus && response.data.Message ? response.data.Message : ''
        })
      }
      if(response.data && (response.data?.evtStsActiveBefore === false || response.data?.evtStsActiveAfter === false)) {
        this.setState({
          eventEnableAfter: response.data?.evtStsActiveAfter,
          eventEnableBefore: response.data?.evtStsActiveBefore,
          eventMessage: response.data?.Message
        })
        return;
      } else if (
        !response?.data?.assortmentResponseList ||
        response.data.assortmentResponseList.length === 0
      ) {
        this.setState((prevState) => {
          return {
            isLoading: false,
            serverError: true,
            assortments: [],
            isNoProducts: true,
            paginationParams: {
              ...prevState.paginationParams,
              totalPages: "0",
              currentPage: "0",
              totalItems: "0",
              firstItem: "0",
              lastItem: "0",
            },
          };
        });
        return;
      }
      const { data } = response;
      this.setState({
        isLoading: false,
        assortments: data.assortmentResponseList,
        paginationParams: data.pagination[0],
        filters: data.filters,
        isNoProducts: false,
        promoName: data?.promo?.Name,
	      eventName: data?.promo?.Name
      }, () => {
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      });
    });
  }

  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      appliedFilters[filterKey] = isArray(appliedFilters[filterKey]) ? appliedFilters[filterKey] : appliedFilters[filterKey].split(",")
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.appliedFilters = appliedFilters;
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.filterList = { filterBy: this.filterList };
    this.setState(
      {
        currentPage: 1,
        appliedFilters,
        clearFilters: false,
        backPath: this.pathname,
      },
      () => {
        this.page = 1;
        this.getAssortmentsData();
        this.setBackButtonPath();
      }
    );
  };

  changePageSize = (e) => {
    this.pageSize = e.currentTarget.value;
    this.page = '1';
    this.scrollPosition = window.pageYOffset;
    this.getAssortmentsData();
  }

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    this.scrollPosition = 0;
    this.page = getPageNumber(e.currentTarget.text, paginationParams);
    window.scrollTo(0, 0);
    this.getAssortmentsData();
  };

  getListingHeader = () => {
    const { paginationParams } = this.state;
    return (
      <div className="listing-header">
        <span>
          {paginationParams.firstItem}-{paginationParams.lastItem} of{" "}
          {paginationParams.totalItems} products
        </span>
        {paginationParams.totalPages > 0 && (
          <span className="pagination_top">
            {getPagination(
              0,
              parseInt(paginationParams.currentPage),
              parseInt(paginationParams.totalPages),
              this.onPageChange,
              null,
              1
            )}
          </span>
        )}
      </div>
    );
  };

  setBackButtonPath = () => {
    let pathName = "";
    if (this.searchBy) {
      pathName = pathName + '&searchBy=' + this.searchBy + '=' + this.searchValue;
    }
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    pathName =
      this.scrollPosition > 0
        ? pathName + "&scrollPosition=" + this.scrollPosition
        : pathName;
    if (!_.isEmpty(this.appliedFilters)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(this.appliedFilters);
    }
    let pushUrl = '';
    if (pathName !== '') {
      pushUrl = this.pathname + "?" + pathName;
      if(!this.props.fromScanning) {
        window.history.replaceState(window.history.state, "", pushUrl);
        }
    } else {
      pushUrl = this.pathname;
    }
    this.setState({
      backPath: pushUrl
    })
  };

  searchItems = (e) => {
    this.searchValue =this.state.searchValue;
    this.scrollPosition = window.pageYOffset;
    this.page = 1;
    if(this.state.searchType === 'itemNbr' && parseInt(this.searchValue) === 0){
      utilities.showToast('Invalid item number');
      return;
    }
    this.getAssortmentsData();
  };
  
  changeSearchValue = (e) => {
    const { validity = {}, value } = e.target;
    this.scrollPosition = window.pageYOffset;
    if (!validity.valid) {
      e.preventDefault();
      return;
    }
    this.setState({
      searchValue: value
    });
  };

  changeSearchBy = (e) => {
    this.searchBy = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.setState({searchType: this.searchBy,searchValue: ''});
  };

  changeSortParam = (e) => {
    this.sortParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getAssortmentsData();
  };

  changeSortDirection = e => {
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getAssortmentsData();
  };

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }
  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  search = (e) =>{
    if(e.target.name==='search-icon' || e.keyCode===13){ 
        this.searchItems();
    }
  };
  
  showAssortmentList = (assortment, endDate, backPath, shoppingAreaName, eventId, shoppingAreaId,shoppingAreaMetaId, flowType, promoName, fromReunionHome) => {
    this.setScrollPosition();
    assortment = {...assortment, backPath : backPath, shoppingAreaName: shoppingAreaName }
    const {promoNmbr,eventName, relayNbr} = this.state;
    const keyParams= {
      eventId: promoNmbr,
      eventName: encodeURIComponent(eventName),
      shoppingAreaId: this.state.shoppingAreaId,
      shoppingAreaMetaId: this.state.shoppingAreaMetaId,
      shoppingAreaName: this.state.shoppingAreaName,
      flowType: this.state.flowType,
      assortmentId: assortment.asstmtNbr,
      relayNbr,
      pageName: "AssortmentItemListing",
      assortmentMdlNbr : assortment.modelNbr,
      fromReunionHome:fromReunionHome
    };
    history.push({
      pathname: getEventRedirectUrl({keyParams}), 
      state: {
        assortment, endDate, flowType, shoppingAreaName, shoppingAreaId, shoppingAreaMetaId, eventId, seleDept: assortment.deptName,
        relayNbr,seleCatg: assortment.categoryName, promoName, 
        assortmentId: assortment.asstmtNbr,
        fromReunionHome:fromReunionHome
      }
    })
  }
  render() {
    const {
      header,
      promoName,
      shoppingAreaId,
      shoppingAreaMetaId,
      isLoading,
      isNoProducts,
      flowType,
      filters,
      isGridView,
      relayNbr,
      promoNmbr,
      fromReunionHome,
      eventEnableAfter,
      eventEnableBefore,
      eventMessage,
      shoppingAreaName,
      dynamicErrorMsg
     } = this.state;
    return (
      <>
      {(eventEnableAfter === false || eventEnableBefore === false ) ?
        <UnderConstruction eventMessage={eventMessage} shoppingAreaName={shoppingAreaName}/>
        :
      <div>
        {isLoading &&
          <div>
            <Loader active />
          </div>
        }
        <Segment id="reunionAssortments" >
        {!this.props?.fromScanning ? 
        <>
          <div className='pageHeader'>
          {header + " - " + promoNmbr + "-" + decodeURIComponent(promoName)}
          </div>
            <BreadcrumbComp 
              path = {getBreadcrumbLinks(
                fromReunionHome,
                flowType,
                promoNmbr,
                '',
                '',
                header,
                shoppingAreaId,
                shoppingAreaMetaId,
                '',
                relayNbr,
                promoName,
                '',
                '',
                '',
                '',
                "AssortmentListing"
              )}
            />
            </>:<h2>{header}</h2>}
            <br/><br/>
          <div>
          {!this.hideFilter &&(
            <>
            <div className='ui input' id='input_searchReatil'>
              <span>Assortment Search</span>
              <select id='pl_dropdown' onChange={this.changeSearchBy} value={this.searchBy}>
                <option value="itemNbr">Item #</option>
                <option value="model">Model #</option>
                <option value="pog">POG #</option>  need to hide until data is available 
                <option value="description">Description</option>
              </select>
              <input
                id='search_RetailAssortment'
                input = 'text'
                onKeyDown = {this.handleEnterKey}
                pattern={this.patterns[this.state.searchType]}
                onChange={this.changeSearchValue}
                value={this.state.searchValue}
                style={{width:`${window.innerWidth<768?"9.3rem":""}`}}
              />
              {renderImages.getImage({ 
                src: search_img, 
                className:'search_icon_address', 
                name: 'search-icon',
                onClick: this.search
              })}
            </div>
            <div className="sort_filter">
              <span className='filter-label'>Sort By</span>
              <select className='sort-criteria combo savingcomb' onChange={this.changeSortParam} value={this.sortParam} id='pl_dropdown'>
                <option value="saving" >Savings</option>
                <option value="dating" >Dating</option>
              </select>
              <select className='sort-order combo' onChange={this.changeSortDirection} value={this.sortDirection} id='sortAssortmentOrder'>
                <option value='asc'>Ascending</option>
                <option value='desc'>Descending</option>
              </select>
              {window.innerWidth >= 1023 &&
                <span id='icon_align'> 
                <i 
                  className='list icon cursor_p' 
                  value='List' 
                  id='list_icon' 
                  onClick={() => this.handleViewChange(false)}></i>{' '} 
                | 
                <i 
                  className='th icon cursor_p' 
                  value='Grid' 
                  id='grid_icon' 
                  onClick={() => this.handleViewChange(true)}></i> 
              </span>
              }
            </div>
            </>)}
          </div>
          <div className={window.innerWidth > 1023 ? "displayFlex allOrdersTable" : "allOrdersTable"}>
            <div id='filter_width_ipad'>
              {!this.hideFilter && isFilterRequired && (
                <Filter
                  filters={filters}
                  isNoProducts={isNoProducts}
                  appliedFilters={this.state.appliedFilters}
                  filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                  defaultChecked={this.defaultChecked}
                  clearFilters={this.state.clearFilters}
                  filterCallback={this.filterCallback}
                  showFilters={this.state.showFilters}
                  toggleShowFilter={this.toggleShowFilter}
                />
              )}
            </div>
            <div id="grid_width" className="full-width pl_data">
              {isNoProducts ? (
                <div className="no_prdcts_display">
                  <h1>{dynamicErrorMsg ? dynamicErrorMsg : ASSORTMENT_UNAVAILABLE_TEXT}</h1>
                </div>
              ) : (
                  <>
                    {this.getListingHeader()}
                    {!isGridView && window.innerWidth >= 1024 ?
                      <AssortmentListView
                        assortment={this.state.assortments}
                        endDate={this.state.endDate}
                        showAssortmentList={this.showAssortmentList}
                        isNoProducts={isNoProducts}
                        setBackButtonPath={this.setScrollPosition}
                        backPath={this.state.backPath}
                        eventId={promoNmbr}
                        shoppingAreaId={shoppingAreaId}
                        shoppingAreaMetaId={shoppingAreaMetaId}
                        shoppingAreaName={header}
                        flowType={flowType}
                        promoName={promoName}
                        fromReunionHome={fromReunionHome}
                      /> :
                      <AssortmentGridView
                        assortment={this.state.assortments}
                        endDate={this.state.endDate}
                        showAssortmentList={this.showAssortmentList}
                        isNoProducts={isNoProducts}
                        setBackButtonPath={this.setScrollPosition}
                        backPath={this.state.backPath}
                        eventId={promoNmbr}
                        shoppingAreaId={shoppingAreaId}
                        shoppingAreaMetaId={shoppingAreaMetaId}
                        shoppingAreaName={header}
                        flowType={flowType}
                        promoName={promoName}
                        fromReunionHome={fromReunionHome}
                      />
                    }
                  </>)}
            </div>
          </div>
          <span className="pagination_top">
            {this.state.paginationParams.totalPages > 0 && (
              getPagination(
                0,
                parseInt(this.state.paginationParams.currentPage),
                parseInt(this.state.paginationParams.totalPages),
                this.onPageChange,
                null,
                1
              ))}
          </span>
        </Segment>
      </div>
      }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const storeId = state.SessionReducer.storeId;
  return {
    userId: state.SessionReducer.UserId,
    storeId,
    getprefer: state.preference.listData,
    rdc: state.SessionReducer.rdc
  };
}

export default connect(mapStateToProps,null)(AssortmentsList);
