import {productListingUrl,imProductListingUrl,domListingUrl,closeOutItemsUrl, HOT_DEALS_URL} from '../../urlConstants';
export const listingUrls = 
{
    productListing : productListingUrl,
    imProductListing : imProductListingUrl,
    DOM : domListingUrl,
    DomSearch : "",
    CloseoutItems : closeOutItemsUrl,
    HotDeals: HOT_DEALS_URL

}