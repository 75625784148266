import React from "react";
import Dialog from "react-dialog";
import QuickViewDetails from "../../common/QuickViewDetails";
import { connect } from "react-redux";
import { Table, Segment, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import utilities from "../../../utils/utilities";
import { ProductIndicators } from "./itemListingUtilities";
import MultistoreToCartModal from '../../Cart/MultistoreToCartModal';
import newitemindicator from "../../images/newitemindicator.png";

import {
  isQuickViewRequired,
  isAddToCartRequired,
  getImageElement,
  getProductTitleLabel,
  getQtyField,
  getOverrideRetailField
} from "./itemListingUtilities";
import config from '../../../config';

class ItemListView extends React.Component {
  constructor(props) {
    super();
    const { flowType } = props;
    this.state = {
      expandDescription: false,
      quickviewdata: "",
      isQuickViewShown: false,
      isQuickViewRequired: isQuickViewRequired(flowType),
    };
  }
  expand = (num) => {
    this.setState({
      expandDescription: true,
    });
  };

  addImghover = (data, num) => {
    this.setState({
      quickviewdata: 'addimg',
      item: num
    });
  }

  delimg = (data) => {
    this.setState({
      quickviewdata: '',
    });
  }

  closeModalexpand = () => {
    this.setState({
      expandDescription: false,
    });
  };


  closeModal = () => {
    this.setState({
      visible: false,
      isQuickViewShown: false,
      quickviewdata: ''
    });
  };

  getLongDesc = (productitem) => {
    let longDesc = '';
    if(config.showMediumDesp){
      longDesc = productitem.productTitle||productitem.longDescription;
    }
    else{
      longDesc = productitem.longDescription;
    }
    return (
      longDesc && (
        <p className="listingDescription">
          {longDesc.length < 250
            ? longDesc.substr(0, 1).toUpperCase() +
            longDesc.substr(1, longDesc.length).toLowerCase()
            : longDesc.substr(0, 1).toUpperCase() +
            longDesc.substr(0, 242).substr(1, 242).toLowerCase() +
            "..."}
          <u className="cursor_p" onClick={() => this.expand()}>
            {longDesc.length < 250 ? "" : "more"}
          </u>
        </p>
      )
    );
  };

  getLongDescription = (productitem) => {
    let desp = '';
    if(config.showMediumDesp){
      desp = productitem.productTitle||productitem.longDescription;
    }
    else{
      desp = productitem.longDescription;
    }
    if(desp){
      desp =  desp.substr(0,1).toUpperCase() + desp.substr(1,desp.length).toLowerCase()
    }
    else{
      desp = "";
    }
    return desp;
  };

  dcItemLabel = (flowType, label) => {
    if(flowType === '5')
    switch(label) {
      case 'EVT COST:':
        return 'DS EVT COST:'
      case 'MEMBER COST:':
        return 'DS COST:'
      default:
        return label
    }
    else if(flowType==='4') {
      switch(label) {
        case 'WH COST:':
          return 'REG DS COST:'
        case 'EVT COST:':
          return 'DS EVT COST:'
        case 'OVERRIDE RETAIL:':
          return false;
        default:
          return label
      }
    }
  }
  getItemField = (cell, index, product, itemListParams,flowType) => {
    const isMaxQtyPerReunion = cell.label === "Max Qty Per Ship Window:" ? true : false
    return (
      <>
        {cell.label === "RESTRICTED:" ? (
          <Table.Cell colSpan="2" className={cell.className}>
            {cell.val}
          </Table.Cell>
        ) : (
            <>
              <Table.Cell colSpan={isMaxQtyPerReunion && 2} className={isMaxQtyPerReunion ? 'max_red_color First_level_details': 'First_level_details'}>
                <span>
                  {cell.label}
                </span>
              </Table.Cell>
              {cell.isTextBox ? (
                cell.type === "or"  ? (
                  flowType !== '4' ?
                  <Table.Cell>
                    {getOverrideRetailField(cell, itemListParams.handleQtyChange)}
                  </Table.Cell>:<Table.Cell/>
                ) :
                  (
                    <Table.Cell>
                      {getQtyField(cell, itemListParams.handleQtyChange, cell.type)}
                    </Table.Cell>
                  )
              ) : (
                  <>
                  
                    <Table.Cell
                      className={
                        cell.labelClass ? cell.labelClass : "First_level_details"
                      }
                    >
                      {cell.is_number ? (
                        utilities.changePriceToNumber(cell.val)
                      ) : (cell.label === "SUBSTITUTE:" || cell.label === "REFERRAL ITEM:" )  &&
                        cell.val.trim() !== "-" ? (
                            <Link
                              to={{
                                pathname: "/product/" + utilities.refInclude(cell.val),
                              }}
                            >
                              {cell.val}
                            </Link>
                          ) : (
                              cell.val
                            )}
                    </Table.Cell>
                  </>
                )}
            </>
          )}
      </>
    );
  };
    multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.props.closeMultistoreModal();
    this.setState({ isMultistoreModalOpen: false });
  }

  openModal = (data) => {
    this.setState({
      visible: true,
      isQuickViewShown: true,
      item: data,
      expand: ''
    });
  };

  availableCheck = (product, index, cartParams,multistore) => { 
    if(product.available === 0){
      utilities.showToast("Product cannot be added");
      return false;
    }else if(this.props.quantities[index][0] > product.available){
      utilities.showToast("Quantity should be less than the available");
      return false;
    }else{
      cartParams.addToCart(product.itemNbr, index, product.sPackType, product.sPackQty, product.maxQtyItemRestriction, product.vendorId,product.isMultiples,product.model)
    }
  }

  render() {
    const { cartParams, itemListParams, itemParams, flowType, relayNbr } = this.props;
    const { product } = itemParams;
    const { isQuickViewShown } = this.state;
    const isReunionListing="true";
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const isPDPlinkReqd = !((flowType==="4" || flowType==="5") && product.itemNbr=== 0);
    let isAltPDPapi = (flowType && (flowType==="1" || flowType==="2"));
    return (
      <>
        {/* { addToCartModalUtil(cartParams)} */}
        <MultistoreToCartModal
        isMultistoreModalOpen={this.state.isMultistoreModalOpen}
        assortmentId={this.props.assortmentId}
        closeMultistoreModal={this.closeMultistoreModal}
        multiStoreCartavailable={this.props.multiStoreCartavailable}
        product={this.state.productSelectedForModal}
        shoppingAreaName={this.props.shoppingAreaName}
        shoppingAreaId={this.props.shoppingAreaId}
        shoppingAreaMetaId={this.props.shoppingAreaMetaId}
        flowType={flowType}
        quantitiesMs={this.props.quantitiesMs}
        indexCheck={this.props.indexCheck}
        relayNbr={relayNbr}
        />
        <Segment className="product-list-card" key={product.itemNbr}>
          {this.state.expandDescription && (
            <div
              className="DescriptionDialog"
              onMouseLeave={this.closeModalexpand}
            >
              <Dialog width="1" height="1" position="absolute">
                {this.getLongDescription(product)}
              </Dialog>
            </div>
          )}
          {this.state.visible && this.state.item === product.itemNbr && (
                  <div className='QuickviewDialog' onMouseLeave={this.closeModal}>
                    <Dialog width='1' height='1' position='absolute'>
                      <QuickViewDetails
                        getprefer={
                          this.props.getprefer &&
                          this.props.getprefer.customerView
                          ? this.props.getprefer.customerView
                          : 'c1'
                      }
                      Item_Number={product.itemNbr}
                      viewType={this.props.viewType}
                      QuickCost={product.Member_Cost}
                      productDetails={product}
                      isAltPDPapi = {isAltPDPapi}
                    />
                  </Dialog>
                </div>
              )}
          <div className={isQuickViewShown? "blur_state ui stackable two column grid" : "ui stackable two column grid"}>
              <div className="one wide column left-top-padding"
              onMouseLeave={() => {
                this.delimg("");
              }}
            >
              {
              product.newItemFlag === 'Y' && flowType === '1' &&
              <img src={newitemindicator} className='new-item-image' alt=''/>
              }
              <div className="card_list_wrap" style={{'marginTop': '1rem'}}>
                {getImageElement(
                  this.props.setBackButtonPath,
                  product.itemNbr,
                  itemParams.pdpParams,
                  this.props.imgStyle,
                  this.addImghover,
                  isPDPlinkReqd,
                  product.altImg,
                  isReunionListing,
                  this.props.shoppingAreaName,
                  this.props.shoppingAreaMetaId
                )}
              </div>
              {this.state.isQuickViewRequired && isPDPlinkReqd && window.innerWidth > 767 && (
                <div>
                  <span
                    className='view_button-compact-visible'
                    onClick={() => {
                      this.openModal(product.itemNbr);
                    }}
                  >
                    Quick View
                  </span>
                </div>
              )}
            </div>
            <div className="fifteen wide column">
              <Grid>
                <Grid.Column computer={7}>
                  <div>
                    {getProductTitleLabel(
                      this.props.setBackButtonPath,
                      product.itemNbr,
                      itemParams.pdpParams,
                      product.shortDesc,
                      isPDPlinkReqd,
                      this.props.shoppingAreaMetaId,
                      product.available,
                      isReunionListing,
                      this.props.shoppingAreaName
                    )}
                    <b className="vendor-name">
                      &nbsp; {product.vendorName}
                    </b>
                  </div>
                  {this.getLongDesc(product)}
                  <div className="indicators-div">
                    <ProductIndicators product={product} isPLP="PLP" flowType = {flowType}/>
                  </div>
                </Grid.Column>
                <Grid.Column className="less-side-padding" computer={9}>
                  {itemListParams.isSeqRequired &&
                    !itemListParams.isScanListing && (
                      <>
                        <span className="compare-n-fav padding_right">
                          SEQ# :{product.seqNbr}
                        </span>
                      </>
                    )}
                  <Table stackable className="very basic plp-field-table">
                    <Table.Body className="listingProduct">
                      {itemParams.fieldSet.map((item, i) => (
                        <Table.Row>
                          {item.map((cell, index) =>
                            this.getItemField(
                              cell,
                              index,
                              product,
                              itemListParams,
                              flowType
                            )
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>

                  {isAddToCartRequired(this.props.flowType) && (
                    <div id="plp_add_to_cart">
                    {msCheck && msCheck > 1 ?
                      <button
                      className="red ui button"
                      style={{ width: "-webkit-fill-available" }}
                      onClick={() => this.multistoreHandleCarts(product)
                      }
                    >
                      Add to Store
                    </button>
                        :
                      <button
                        className="red ui button"
                        style={{ width: "-webkit-fill-available" }}
                        onClick={() =>  this.props.shoppingAreaMetaId === "7" ? this.availableCheck(product,itemParams.itemIndex, cartParams) :
                           cartParams.addToCart(product.itemNbr, itemParams.itemIndex, product.sPackType, product.sPackQty, product.maxQtyItemRestriction, product.vendorId,product.isMultiples,product.model,product.maxQtyPerReunion)}
                      >
                        Add to Cart
                      </button>
                  }
                    </div>
                  )}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const carts = state.cart.orders.allCarts;
  return {
    getprefer: state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
  };
};
export default connect(mapStateToProps)(ItemListView);
