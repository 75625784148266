import React, { Component } from 'react';
import { Button, Loader, Responsive } from 'semantic-ui-react';
import InternalServerError from '../../ErrorPages/500_error_page';
import { Link } from 'react-router-dom';
import  '../../common/ProductDetails.css';
import '../../Products/ProductDetails_style.css';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import Categories from '../../Categories';
import utilities from '../../../utils/utilities';
import { connect } from 'react-redux';
import { CUSTOMER_VIEW } from '../../../components/NavBar/menuConstants';
import { MaskPrice } from '../../common/MaskPrice';
import NotFound from '../../ErrorPages/404_error_page';
import DiscountCityItemPalletListing from '../DiscountCity/DiscountCityItemPalletListing'
import ImageGallery from '../../common/ImageGallery';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

class DiscountCitypalletListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: false,
    }
  }

  getRegCost = (regCost) =>{
    this.setState({regCost : regCost})
  }

  getRestrictedItems = (restrictedItems) => {
    this.setState({restrictedItems : restrictedItems})
  }

  render() {
    let header = 'Discount City';
    let breadcrumbPath = [
      { link: '/home', text: 'HOME' },
      { link: '/discountCity', text: header}
  ];
    const { palletDesc='',PalletSKU,palletRelayNbr,palletPromoNbr,palletModelnumber,palletUpc,palletPack,palletCost,palletMbrCost,palletSavings,palletPricing,palletVendorname} = this.props.location?.state;
    const {palletnumber}=this.props.match?.params;
    let discountCity={};
    discountCity={ palletDesc,PalletSKU,palletRelayNbr,palletPromoNbr,palletModelnumber,palletUpc,palletPack,palletMbrCost,palletCost,palletSavings,palletPricing,palletVendorname,palletnumber};
    return (
    
            this.state.serverError ?
                <InternalServerError />
                :
        <div className="page-wrap">
          {this.state.loading && (
                        <div>
                            <Loader active />
                        </div>
                    )}
          <div className='page-header'>
              {header}
          </div>
          <BreadcrumbComp path={breadcrumbPath}/>
          <AssortmentDetails palletDesc={palletDesc} discountCity={discountCity} multiStoreDetails={this.props.multiStoreDetails} />
      </div>
    );
  }
}

const AssortmentDetails = ({palletDesc,discountCity,multiStoreDetails}) => {
  let imageSrcs = [
    `https://images.truevalue.com/getimage.asp?id=104593&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2`
  ];
  return (
        <div className='displayFlex ui stackable sixteen wide two column grid'>
          <div id="pdpImageDiv" className={window.innerWidth > 600
                              ? 'five wide column'
                              : 'pdpImage column'
                            }
          >
            <ImageGallery images={imageSrcs} />
          </div>
          <div className={window.innerWidth > 768
            ? window.innerWidth > 1024
              ? ' eleven wide column'
              : 'ui stackable two column grid'
            : 'column'
          } id='prodDescription'
          >
              <div className='page-header'>
              {palletDesc}
              <b className='vendor-span'>
              {discountCity.palletVendorname}
            </b>
            </div>
            <div className='ui stackable three column grid'>
                <div className="five column">
                <div className="palletList">
                 <span> Pallet Location:</span>  <span className="disValue">C 6006</span>
                </div>
                <div className="palletList">
                <span>Pallet SKU:</span>  <span  className="disValue">266-058</span>
                </div>
                <div className="palletList">
                 <span>Model Number:</span>  <span  className="disValue">{discountCity.palletModelnumber}</span>
                </div>
                <div className="palletList">
                 <span>UPC:</span>  <span  className="disValue">{discountCity.palletUpc}</span>
                </div>
                <div className="palletList">
                 <span>Retail fine line:</span>  <span  className="disValue">{'HO765'}</span>
                </div>
                </div >
                <div className="five column">
                <div className="palletList">
                 <span> Member Cost:</span>  <span  className="disValue">{discountCity.palletCost}</span>
                </div>
                <div className="palletList">
                 <span>Market Cost:</span>  <span  className="disValue">{discountCity.palletMbrCost}</span>
                </div>
                <div className="palletList">
                 <span>Saving:</span>  <span  className="disValue">{discountCity.palletSavings}</span>
                </div>
                <div className="palletList">
                 <span>Dating:</span>  <span  className="disValue">{discountCity.palletMbrCost}</span>
                </div>
                <div className="palletList">
                 <span>Pack:</span>  <span  className="disValue">{discountCity.palletPack}</span>
                </div>
                  
                  </div>
                  <div className="five column">
                  <div className="palletList">
                <span class="discountWeek"> Ship Weeks</span>
                </div>
                <div className="palletList-Date">
                  <div class="column">
                    <span class="dc_card_specific">MM/DD</span>
                    <span class="dc_card_qty_space">
                      <input class="qty-box" type="text"/>
                    </span>
                  </div>
                  <div class="column">
                    <span class="dc_card_specific">MM/DD</span>
                    <span class="dc_card_qty_space">
                      <input class="qty-box" type="text"/>
                    </span>
                  </div>
                </div>
                <div className="palletList-Date">
                  <div class="column">
                    <span class="dc_card_specific">MM/DD</span>
                    <span class="dc_card_qty_space">
                      <input class="qty-box" type="text"/>
                    </span>
                  </div>
                  <div class="column">
                    <span class="dc_card_specific">MM/DD</span>
                    <span class="dc_card_qty_space">
                      <input class="qty-box" type="text"/>
                    </span>
                  </div>
                </div>
                <button class="red ui button pallet-itemlistcart">Add to Cart</button>
                  </div>
            </div>
        </div>
        <DiscountCityItemPalletListing palletVendorname={discountCity.palletVendorname} palletItem={true} palletPromoNbr={discountCity.palletPromoNbr} palletRelayNbr={discountCity.palletRelayNbr} />
      </div>
  );
};

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    getprefer: state.preference.listData,
    RaStore:state.RaReducer
  }
}

export default connect(mapStateToProps, null)(DiscountCitypalletListing);
