import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import React from 'react';
import LeftMenu from './LeftMenu';
import CategoryList from '../../views/common/CategoryList'

export const NavBarMobile = ({
  children,
  onPusherClick,
  onToggle,
  visible
}) => (
  <Sidebar.Pushable className = 'mobile-menu-bar'>
    <Sidebar
      as={Menu}
      animation='overlay'
      icon='labeled'
      inverted
      vertical
      visible={visible}
      id='sidebar-native'>
      <LeftMenu pushSidebar={onPusherClick} isMobile />
    </Sidebar>
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      className='sidebar-pusher'>
      <Menu inverted className='pusher-menu'>
        <Menu.Item onClick={onToggle}>
          <Icon name='large sidebar' />
        </Menu.Item>
        <Menu.Menu position='right'>
        <div className="searchDiv mobileSearchDiv" id="">
          <CategoryList />
        </div>
        </Menu.Menu>
      </Menu>
      <NavBarChildren>{children}</NavBarChildren>
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

const NavBarChildren = ({ children }) => <div>{children}</div>;
