import restUtils from "../../utils/restUtils";

export const GET_REUNION_OFFERINGS = 'GET_REUNION_OFFERINGS'
export const GET_MONTHLY_SPECIALS = 'GET_MONTHLY_SPECIALS'
export const SET_LOADER = 'SET_LOADER';

export const getReunionOfferings = (url,request)=> {
    return (dispatch) =>{
        restUtils
            .postData(url,request)
            .then(response=>{
                console.log(response);
                return dispatch({
                    type : GET_REUNION_OFFERINGS,
                    payload:{
                        events:response.data.Eventdetails,
                        filters:response.data.filters
                    }
                })
            }).catch(error=>{
                console.log(error)
            })

    }
}

export const getMonthlySpecial = (url,request)=> {
    return (dispatch) =>{
        dispatch({
            type:SET_LOADER,
            payload:false
        })
        restUtils
            .postData(url,request)
            .then(response=>{
                return dispatch({
                    type : GET_MONTHLY_SPECIALS,
                    payload:{
                        loaded:true,
                        events:response.data.MonthlySpclsEventsList,
                        filters:response.data.filters
                    }
                })
            }).catch(error=>{
                console.log(error);
                dispatch({
                    type:SET_LOADER,
                    payload:true
                })
                this.setState({ loading: false, serverError: true });
            })

    }
}