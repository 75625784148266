import { GET_ALL_PREFERENCE, UPDATE_PREFERENCE, UPDATE_PRODUCT_LIST_VIEW_PREFERENCE } from './action';

const initState = {};

const preference = (state = initState, { type, payload }) => {
  switch (type) {
    case GET_ALL_PREFERENCE:
      return { ...state, listData: payload,loadpreference:false };
    case UPDATE_PREFERENCE:
      return { ...state, listData: payload,loadpreference:false };
    case UPDATE_PRODUCT_LIST_VIEW_PREFERENCE:
      return {
        ...state,
        listData: { ...state.listData, prdctListlayoutFormat: payload}
      };
    default:
      return state;
  }
};

export default preference;
