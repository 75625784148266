import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import './styles.css';
import classnames from 'classnames';
import renderImages from '../common/RenderImages';
import search_img from '../images/Search.svg';
import {
  colDefs,
  breadcrumbLinks,
  sortOptions,
  searchOptions,
  orderOptions
} from './shipLaterModel';
import { SHIP_LATER_API } from '../../urlConstants';
import _ from 'lodash';
import GenericExcelExport from '../common/GenericExcelExport';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import { Grid, Loader,Confirm,Responsive } from 'semantic-ui-react';
import Table from '../common/Table/index';
import restUtils from '../../utils/restUtils';
import Filter from '../common/Filter';
import { SortAndOrder, Options } from '../common/SortAndOrder';
import { utilities } from '../../utils/utilities';
import { getShipLaterItems, setSortAndOrder } from './actions';
import ReactToPrint from 'react-to-print';

class ShipLaters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shipLaterData: [],
      clearFilters: false,
      appliedFilters: {},
      colDefs: this.getColDefs(),
      isLoading: true,
      deletionConfirmOpen:false,
      unselectAll: false,
      rowindexVal:'',
      showFilters:  (this.props.getprefer && this.props.getprefer.filterView && this.props.getprefer.filterView ==='E') ? true: false,
      itemsToDelete: [],
      selectAll: false
    };
    this.scrollPosition = 0;
    this.pathname = '';
    this.searchParams = 'item_no';
    this.searchValue = '';
    this.sortParam =  'item_no';
    this.sortType = 'asc';
    this.filterString = '';
  }

  closeConfirmation = () => {
    this.setState({
      deletionConfirmOpen: false
    });
  };

  getColDefs = () => {
    
    colDefs[0].cellRenderer = value => {
      return <Link onClick={this.setBackButtonPath} to={`/product/${value}`}>{value}</Link>;
    };

    colDefs[3].cellRenderer = value => {
      return (
        <> <p className='no_margin' style={{'textAlign': 'left'}}>
                {value}
              </p>
          </>);
    };

    colDefs[7].cellRenderer = value => {
      return parseFloat(value) > 0 ? `$${utilities.addSuffixZero(value)}` : '';
    };
    colDefs[8].cellRenderer = value => {
      return parseFloat(value) > 0 ? `$${utilities.addSuffixZero(value)}` : '';
    };
    
    colDefs[9].cellRenderer = value => {
      return parseFloat(value) > 0 ? `$${utilities.addSuffixZero(value)}` : '';
    };

    colDefs[10].cellRenderer = value => {
      return utilities.getDateFromTimeStamp(value).split('/')[2] == '1' ? '':  utilities.getDateFromTimeStamp(value)
    };
    if(this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID)
        && colDefs && colDefs[1].header !== 'Alternate SKU#')
    {
      colDefs.splice(1,0,
        {
          header: 'Alternate SKU#',
          key: 'aliasSku'
        });
    }
    return colDefs;
  };

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.getFilteredData({ sortParam: this.sortParam, sortType: this.sortType });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      filters,
      shipLaterItemList,
      excludesAmmunition,
      includesAmmunition
    } = nextProps;
    this.setState({
      filters,
      shipLaterItemList,
      excludesAmmunition,
      includesAmmunition
    });
  }
  
  handleShipLaters = () => {
    this.setState({
      isLoading: false
    });

  };

  handleBackButtonChanges = () => {
    window.scrollTo(0,this.scrollPosition);
    this.setBackButtonPath();  
    for (let key in this.state.appliedFilters) {          
      /*document.getElementById('fh-'+ key).className = 'filter-head applied-filter-head'; */                 
    }     
  };

  setBackButtonPath = () => {
    let pathName = ''; 
    this.scrollPosition = window.pageYOffset;
    if (this.searchValue) {
      pathName = '&searchBy='+this.searchParams+'='+this.searchValue;
    }
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam='+this.sortParam;
      if (this.sortType && this.sortType.length > 0) {
        pathName = pathName + '&SortType='+this.sortType;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    pathName = pathName + '&scrollPosition='+this.scrollPosition;
    if (!_.isEmpty(this.filterString)) {
      let obj = utilities.getQueryStrFromJson(this.filterString);
      pathName = pathName + '&filterString='+ utilities.getQueryStrForFilterString(obj);
    }    
    let pushUrl = this.pathname +'?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }

  getUrlFilters = (queryParam) => {
    let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
    let searchValue = ''
    if(searchParam !== ''){
      let search = searchParam.split('=');
      this.searchParams = search[0];
      this.searchValue = search[1].split('&')[0];
    }
    document.getElementById('search_ship_later').value = this.searchValue;
    document.getElementById('select_ship_later').value = this.searchParams;
    let sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    let sortType = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : '';
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    let appliedFilters = utilities.getFilterArrayAsObject(this.filterString);
    this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
    this.sortParam = sortParam;
    this.sortType = sortType;
    this.setState({
      appliedFilters : appliedFilters,
    }, () => {
      this.getFilteredData({ sortParam : sortParam, sortType : sortType});
    })
  }

  getFilteredData = (sortTyeAndOrder = {}) => {
    const reqObj = this.getRequestObject(sortTyeAndOrder);
    if (reqObj) {
      this.setState({
          isLoading: true
        },() => {
          this.props.getShipLaterItems(reqObj, this.handleShipLaters, this.handleBackButtonChanges);                  
        }
      );
    }
  };

  getRequestObject = ({ sortParam = '', sortType = '' }) => {
    const { storeId, UserId } = this.props;
    const { appliedFilters } = this.state;
    const filterBy = [];
    let reqObj = null;

    for (let key in appliedFilters) {
      filterBy.push({
        name: key,
        valueList: appliedFilters[key]
      });
    }

    if (storeId) {
      reqObj = {
        userId: UserId,
        storeId,
        filterBy,
        searchBy: {
          name: this.searchParams,
          valueList: this.searchValue ? [this.searchValue] : []
        },
        sortBy: { sortParam, sortType },
        aliasSKURetailerGrpID : this.props.multiStoreDetails && this.props.multiStoreDetails.aliasSKURetailerGrpID
      };
    }
    return reqObj;
  };

  handleAction = (event, { rowindex, action }) => {
    const item_no= rowindex;
    const itemsToDelete = this.state.itemsToDelete;
    let shipLaterItemList = [...this.state.shipLaterItemList];

    shipLaterItemList.forEach((s, i)=>{
      const itemNum = item_no ? parseInt(item_no.split('#')[0]) : ''
      const seqNum = item_no ? parseInt(item_no.split('#')[1]) : ''
      if(itemsToDelete.includes(item_no)) {        
        if(s.item_no === itemNum && s.seq_number === seqNum){
          shipLaterItemList[i].selection = false
        }
      } else if(s.item_no === itemNum && s.seq_number === seqNum) {
        shipLaterItemList[i].selection = true
      }
    })

    if(itemsToDelete.includes(item_no)) {
      itemsToDelete.splice(itemsToDelete.indexOf(item_no), 1);
    } else {
      itemsToDelete.push(item_no);
    }
    this.setState({
      itemsToDelete,
      unselectAll: itemsToDelete.length > 0 ? true : false,
      shipLaterItemList: shipLaterItemList
    })
  };
  handleMultiAction = (value) => {
    let itemsToDelete = this.state.shipLaterItemList.map(ele => ele.item_no+'#'+ele.seq_number)
    this.setState({
      itemsToDelete : value ? itemsToDelete: []
    })
  }
  handleActionConfirm = () => {
    const { itemsToDelete } = this.state;
      if(itemsToDelete && itemsToDelete.length){ 
        this.removeItems(itemsToDelete);
      }  
  };
  handleActionConfirmForMobile = (item, index) => {
    this.setState({itemsToDelete: [`${item}#${index}` ], deletionConfirmOpen:true})
  }

  removeSelected = () => {
    this.setState({
      deletionConfirmOpen:true
    })
  };

  removeAll = () => {
    let shipLaterItemList = [...this.state.shipLaterItemList];
    let removeSelectList = []
    shipLaterItemList.forEach((s, i)=>{
      const itemData = `${s.item_no}#${s.seq_number}`
      removeSelectList.push(itemData)
      if(!this.state.unselectAll){
        shipLaterItemList[i].selection = true
      } else {
        shipLaterItemList[i].selection = false
        removeSelectList = []
      }
      
    })
    this.setState(prevState => {
      return { unselectAll: !prevState.unselectAll };
    });
    this.setState({
      itemsToDelete: removeSelectList,
      shipLaterItemList: shipLaterItemList
    })
  };

  removeItems = (items = []) => {
    this.setState({
      deletionConfirmOpen:false,
      isLoading:true
    })
    this.scrollPosition = window.pageYOffset;

    let shipLaterItemList = [...this.state.shipLaterItemList];
    let removeItemsList = [];
    let newShipLaterItemList = []
    shipLaterItemList.map(item => {
      if(items.includes(String(item.item_no).concat('#').concat(item.seq_number)) ) {
        const { item_no, seq_number } = item;
        removeItemsList.push({ item_no, seq_number });
      } else {
        newShipLaterItemList.push(item);
      }
    });
    const { storeId, UserId, userName } = this.props;
    const reqObj = {
      userId: UserId,
      storeId,
      molUserName: userName,
      removeItemsList
    };
    restUtils
        .postData(
          `${SHIP_LATER_API}/PostRemoveShipLaterItems`,
          JSON.stringify(reqObj)
        )
        .then(res => {
          this.setState({
            shipLaterItemList: newShipLaterItemList,
            isLoading: false,
            unselectAll: false,
            itemsToDelete: []
          });
          this.setBackButtonPath();
        });
  };

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  filterCallback = (isAdd, filterKey, filterValue) => {
    this.scrollPosition = window.pageYOffset;
    let { appliedFilters } = this.state;
    if (isAdd) {
      if (appliedFilters[filterKey]) {
        appliedFilters[filterKey].push(filterValue);
      } else {
        appliedFilters[filterKey] = [filterValue];
      }
    } else {
      const itemPosition = appliedFilters[filterKey].indexOf(filterValue);
      appliedFilters[filterKey].splice(itemPosition, 1);
    }
    this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
    this.setState({
      appliedFilters,
      clearFilters: false
    });
    this.getFilteredData({ sortParam: this.sortParam, sortType: this.sortType });
  };

  handleSearchChange=(e)=>{
    this.scrollPosition = window.pageYOffset;
    this.searchValue = e.target.value;
  };

  changeSearchParam = e => {
    this.scrollPosition = window.pageYOffset;
    this.searchParams = e.currentTarget.value;
  };

  handleFieldChange = event => {
    this.scrollPosition = window.pageYOffset;
    if (event.keyCode === 13) {
      this.getFilteredData({ sortParam: this.sortParam, sortType: this.sortType });
      return;
    }
  };

  searchData = event => {
    this.setFieldValue(event, this.getFilteredData);
  };

  setFieldValue = (event, callback) => {
    const { dataset, value } = event.target;
    const { fieldName } = dataset;
    this.setState({
        [fieldName]: value
      },() => {
        if (callback) {
          callback({ ...this.props });
        }
      }
    );
  };

  handleSortAndOrder = (event, field) => {
    this.scrollPosition = window.pageYOffset;
    const { value } = event.target;
    switch (field) {
      case 'sort':
        this.sortParam = value;
        break;
      case 'order':
        this.sortType = value;
        break;
      default:
    }
    this.props.setSortAndOrder({ sortParam: this.sortParam, sortType: this.sortType });
    this.getFilteredData({ sortParam: this.sortParam, sortType: this.sortType });
  };

  render() {
    const {
      shipLaterItemList = [],
      filters,
      clearFilters,
      appliedFilters,
      showFilters,
      excludesAmmunition,
      includesAmmunition,
      colDefs,
      isLoading
    } = this.state;
    const reqObj = this.getRequestObject({ sortParam: this.sortParam, sortType: this.sortType });
    let exportCol = [...colDefs];
    exportCol.splice(3,1,{
      header: 'Item Description',
      key: 'short_desc'
    })
    exportCol.splice(4,0,{
      header: 'Vendor Name',
      key: 'vendor_name'
    })
    exportCol.splice(10,1,{
        header: 'Old Ext Cost',
        key: 'old_ext_cost'
      },
      {
        header: 'New Ext Cost',
        key: 'ext_cost'
      })
    return (
      <div className='page-wrap ship-later-section'  ref={node => (this.componentRef = node)}>
        <ReactToPrint
          trigger={() => <i className='print icon' id='printicon'></i>}
          content={() => this.componentRef}
        />
        <div className='page-header'>Ship Laters</div>
        <div>
          <span className='fav-select-all-span' style={{'marginTop': '10px', 'marginRight': '-17px'}} onClick={() => this.removeAll()}>
            {this.state.unselectAll ? 'Unselect All' : 'Select All'}
          </span>
        </div>
        <div className='ui small bread-crumb info-text'>
          <BreadcrumbComp path={breadcrumbLinks} />
        </div>
        {this.props.multiStoreDetails.access === 1 ? (
         <>
          <h4> You don't have the permission to view this page. Please contact the True Value Administrator. </h4>
         </>
        ) : (
          <>
        <Grid columns={2} stackable doubling>
          <Grid.Column className="shiplaterSearch" widescreen={6}>
            <div>
              <span className='search-label'>Search</span>
              <select
                id='select_ship_later'
                className='sort-criteria combo'
                onChange={this.changeSearchParam}
                data-field-name='searchParam'
              >
                {Options(searchOptions)}
              </select>
              <div className='ui input search-container'>
                <input
                  id='search_ship_later'
                  className='search-input'
                  onKeyDown={this.handleFieldChange}
                  onChange={this.handleSearchChange}
                  type='text'
                  data-field-name='searchValue'
                />
                {renderImages.getImage({
                  src: search_img,
                  className: 'search_icon_address cursor_p',
                  onClick: this.searchData
                })}
              </div>
            </div>
          </Grid.Column>

          <Grid.Column   className="shiplaterSort" widescreen={10} textAlign= {window.innerWidth> 768 ?'right':'left'}>
            <SortAndOrder
              onAction={this.handleSortAndOrder}
              sortOptions={sortOptions}
              sortValue={this.sortParam}
              orderOptions={orderOptions}
              orderValue={this.sortType}
            ></SortAndOrder>
            <span className='mr-9 inline-block'>
              Showing {shipLaterItemList.length} Products
            </span>
            <GenericExcelExport
              url={`${SHIP_LATER_API}/PostDisplayShipLaterItemsList`}
              fileName='ShipLater'
              columnDefs={{
                colDefs: exportCol,
                responseDataPath: 'data.shipLaterItemList'
              }}
              reqObject={reqObj}
            />
            {window.innerWidth > 768 && shipLaterItemList.length ? (
              <div className='relatively-position pb-10'>
                <button disabled={this.state.itemsToDelete.length > 0 ? false : true} onClick={this.removeSelected} 
                  className="btn-remove-selected">
                   Remove Selected
                  </button>
              </div>
            ) : null}
          </Grid.Column>
        </Grid>
        {isLoading ? <Loader active /> : null}
        <div
          className={classnames('ship-later-grid clr', {
            collapse: showFilters
          })}
        >
          <div className='fl filter-panel shiplaterfilters'>
            <Filter
              clearFilters={clearFilters}
              filtersApplied={!_.isEmpty(appliedFilters)}
              appliedFilters={appliedFilters}
              toggleShowFilter={this.toggleShowFilter}
              showFilters={showFilters}
              filters={filters || []}
              callback={this.filterCallback}
            />
          </div>
          {/*window.innerWidth <768 && shipLaterItemList.length ? (
              <div className='relatively-position pb-10'>
                <span onClick={this.removeAll}
                  className='action-cell remove-all'>
                  Remove Selected
                </span>
              </div>
          ) : null*/}

          <div className='fl grid'>
          <Responsive minWidth='1025'>
          {!isLoading && ( shipLaterItemList.length > 0 ? <Table
              data={shipLaterItemList}
              colDefs={colDefs}
              shipLaters = {'Y'}
              className = {'shiplater-products'}
              onAction={this.handleAction}
              onMultiSelectAction = {this.handleMultiAction}
            /> 
            : <div className='no_prdcts_display'>
               <h1>No Item to Display!</h1>
              </div>)}              
          </Responsive>
          <Responsive maxWidth='1024'>
          {!isLoading && ( shipLaterItemList.length > 0 ?
            shipLaterItemList.map( (list, index) => {
              return <OrderCard  order = {list} index= {index} onAction={this.handleActionConfirmForMobile}/>
            })            
            : <div className='no_prdcts_display'>
              <h1>No Item to Display!</h1>
          </div>)}  
          </Responsive>
            <Confirm
              className='cart-confirm'
              open={this.state.deletionConfirmOpen}
              onCancel={this.closeConfirmation}
              header='Delete Ship Later'
              content={`The selected ${this.state.itemsToDelete.length} ship later item(s) will be removed from the system. Do you want to continue?`}
              onConfirm={this.handleActionConfirm}
            />
            {shipLaterItemList.length ? (
              <div className='white-bg'>
                <Amunition
                  title='Includes Ammo'
                  {...includesAmmunition}
                ></Amunition>
                <Amunition
                  title='Excludes Ammo'
                  {...excludesAmmunition}
                ></Amunition>
                <div className='clr'></div>
              </div>
            ) : null}
          </div>
          <div className='clr'></div>
        </div>
        </>
        )}
      </div>
    );
  }
}
const OrderCard = ({order, index, onAction}) => {
  return (
    <div className='all-orders-card'>
      <div className='floatRight view-details-link shipLaterMbChkBox'>
        <label for="checkbox" onClick={()=>{onAction(order.item_no, order.seq_number)}}>Remove</label>
      </div>
      <table id='order-card-table'>
        <tr>
          <td className='order-card-field padding' >Item #</td>
          <td>{order.item_no}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >PO #</td>
          <td>{order.po_number}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Invoice #</td>
          <td>{order.invoice_number}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Item Description</td>
          <td>{order.short_desc}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Pack</td>
          <td>{order.pack}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >RDC</td>
          <td>{order.RDC}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Qty On Order</td>
          <td>{order.qty}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Old Cost</td>
          <td>{order.oldCost}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >New Cost</td>
          <td>{order.cost}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Ext. Cost</td>
          <td>{order.ext_cost}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Cost Change Date</td>
          <td>{order.costChangeDate}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Status</td>
          <td>{order.status}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Ship Later Age</td>
          <td>{order.shiplaterAgeDisplay}</td>
        </tr>
      </table>
    </div>
  )
}

const mapStateToProps = state => {
  const { SessionReducer = {}, ShiplatersReducer } = state;
  const { UserId, storeId, userName } = SessionReducer;
  return {
    storeId,
    userName,
    UserId,
    ...ShiplatersReducer,
    getprefer:state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
  };
};

const mapDispatchToProps = {
  getShipLaterItems,
  setSortAndOrder
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ShipLaters));

export const Amunition = props => {
  const { title, totalShippedDollars, percentShippedDollarsLT14Days } = props;
  return (
    <div className='fr mr-5 mt-5'>
      <h4 className='text-under-line'>{title}</h4>
      <p> Total Ship Later Dollars:
        <span className='total_amt_shplaters'>
          {utilities.changePriceToNumber(totalShippedDollars)}
        </span>
      </p>
      <p>Ship Later % &lt;= 14 Days: {utilities.addSuffixZero(percentShippedDollarsLT14Days)} %</p>
    </div>
  );
};
