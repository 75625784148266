import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Loader, Modal, Input } from "semantic-ui-react";
import { connect } from "react-redux";
import PreviewList from "./FinelineCatalogPreviewDepartment/List";
import {
  removeFromCatalog,
  selectDepartmentCatalog,
  storeCatalog,
} from "../actions";

class FinelineCatalogPreview extends React.Component {
  state = {
    isModalOpen: false,
    modalSize: "tiny",
    catalogName: "",
  };

  openModal = () => {
    const { catalogFilters, catalog } = this.props;
    const isCostsMasked =
      catalogFilters.memberCost === "Mask" ||
      catalogFilters.suggestedRetail === "Mask";
    const isCostsHidden =
      catalogFilters.memberCost === "Hide" ||
      catalogFilters.suggestedRetail === "Hide";
    const defaultName = `${catalog[0].name} ${
      isCostsHidden ? "Costs Hidden" : isCostsMasked ? "Costs Masked" : ""
    }`;

    this.setState({
      isModalOpen: true,
      catalogName: defaultName,
    });
  };

  handleOpenAdConfigurationModal = () => {
    this.setState({
      adConfigModalOpen: true,
    });
  };

  handleCloseAdConfigurationModal = () => {
    this.setState({
      adConfigModalOpen: false,
    });
  };

  handleOnModalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleCatalogNameChange = (e) => {
    const { value } = e.target;
    this.setState({
      catalogName: value,
    });
  };

  saveCatalog = () => {
    const { catalogName } = this.state;
    const { storeCatalog, viewType } = this.props;

    storeCatalog(catalogName, viewType);
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const {
      catalog,
      removeDepartmentFromCatalog,
      selectDepartmentCatalog,
      selectedDepartment,
      isLoading,
    } = this.props;
    const { isModalOpen, modalSize, catalogName } = this.state;

    return (
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column width={14}>
            <Header as="h4" className="title_bold">
              Fineline Catalog Preview
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-top">
          {isLoading ? (
            <div className="loader-fineline-preview">
              <Loader active />
            </div>
          ) : (
            <PreviewList
              departmentList={catalog}
              removeDepartment={removeDepartmentFromCatalog}
              selectDepartmentCatalog={selectDepartmentCatalog}
              selectedDepartmentCatalog={selectedDepartment}
            />
          )}
        </Grid.Row>
        <div className="export_catalog_container">
          <button
            className="fluid ui red button add-to-catalog-button"
            onClick={this.openModal}
            disabled={catalog.length < 1}
          >
            Export Catalog
          </button>
        </div>

        <Modal
          size={modalSize}
          open={isModalOpen}
          onClose={this.handleOnModalClose}
        >
          <Modal.Header>Save your Fineline Catalog</Modal.Header>
          <Modal.Content>
            <p>Name your Catalog: </p>
            <Input
              type="text"
              fluid
              focus
              defaultValue={catalogName}
              onChange={this.handleCatalogNameChange}
            />
          </Modal.Content>
          <Modal.Actions>
            <button
              onClick={this.handleOnModalClose}
              className="ui button add-to-catalog-button inversed"
            >
              Cancel
            </button>
            <button onClick={this.saveCatalog} className="ui red button add-to-catalog-button">Save</button>
          </Modal.Actions>
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { finelineCatalog } = state;
  const catalogState = finelineCatalog.catalog;

  return {
    catalog: catalogState.catalog,
    selectedDepartment: catalogState.selectedDepartmentCatalog,
    catalogFilters: catalogState.filters,
    isLoading: catalogState.isLoading,
  };
};

FinelineCatalogPreview.propTypes = {
  catalog: PropTypes.array,
  removeDepartmentFromCatalog: PropTypes.func.isRequired,
  selectDepartmentCatalog: PropTypes.func.isRequired,
  selectedDepartmentCatalog: PropTypes.any,
  isLoading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  removeDepartmentFromCatalog: (deptId, name) =>
    dispatch(removeFromCatalog(deptId, name)),
  selectDepartmentCatalog: (deptId) =>
    dispatch(selectDepartmentCatalog(deptId)),
  storeCatalog: (catalogName, viewType) =>
    dispatch(storeCatalog(catalogName, viewType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinelineCatalogPreview);
