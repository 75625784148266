import React from "react";
import utilities from "../../../utils/utilities";
import store from "../../../configureStore";
import {
  getDescription,
  getPack,
  getAssortmentCreditValue,
  getAssortmentSavings,
  showOfferendDateMsg,
  findTotal,
  getRAMessage,
} from "../../Cart/cartUtils";
import _ from "lodash";
import { MaskPrice } from "../../common/MaskPrice";
import { shoppingAreaFlowTypes } from "../../Cart/constants";
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

const getShipDates = (cart) => {
  let shipDates = [
    { label: "Qty1" },
    { label: "Qty2" },
    { label: "Qty3" },
    { label: "Qty4" }
  ];
  // if (!_.isEmpty(cart.items)) {
  //   const item = cart.items[0];
  //   if (!utilities.isEmptyOrNullString(item.ShipDate1)) {
  //     shipDates.push({ label: "Qty1" });
  //   }
  //   if (!utilities.isEmptyOrNullString(item.ShipDate2)) {
  //     shipDates.push({ label: "Qty2" });
  //   }
  //   if (!utilities.isEmptyOrNullString(item.ShipDate3)) {
  //     shipDates.push({ label: "Qty3" });
  //   }
  //   if (!utilities.isEmptyOrNullString(item.ShipDate4)) {
  //     shipDates.push({ label: "Qty4" });
  //   }
  // }
  return shipDates;
};
const getMaxQty = (product) => {
  return <>
  {product.maxQtyItemRestriction}
  {
    <span className='maxQtyLabel'>
        {'Max Qty'} 
    </span>}
  </>
}
const getViewCartHeaders = (flowType, cart,multiStoreDetails, viewType, getPrefer) => {
  const isMaxQty = cart?.items?.length > 0 && cart.items.some(ele => parseInt(ele.maxQtyItemRestriction) > 0)
  const isMaxQtyPerReunion = cart?.items?.length > 0 && cart.items.some(ele => parseInt(ele?.maxQtyPerReunion) > 0)
	const isPalletQty = cart?.items?.length > 0 && cart.items.some(ele => ele.isMultiples)
  const shipDates = getShipDates(cart);
  switch (flowType) {
    case 1:
      let labelNames = [
        { label: "" },
        { label: "" },
        { label: "Item #" },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ label: "Alternate SKU#" }]
          : []),
        { label: "UPC #" },
        { label: "Model #" },
        { label: "Item Description" },
        { label: "Pack" },
        isMaxQty && ({label: isPalletQty ? 'Pallet Qty': 'Max Qty'}),
        isMaxQtyPerReunion && ({label: 'Max Qty Per Ship Window'}),
      ]
      if(viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
        labelNames = [...labelNames,{label:""}]
      } else {
        labelNames = [...labelNames,{ label: utilities.getLabelsByType(viewType,"EVENT_COST", getPrefer) }]
      }
      labelNames = [...labelNames,
        { label: "Override Retail" },
        ...shipDates,
      ]
      if(viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
        labelNames = [...labelNames,{label:""}]
      } else {
        labelNames = [...labelNames,{ label: utilities.getLabelsByType(viewType,"EXTENDED_COST", getPrefer) }]
      }
      labelNames = [...labelNames,
        { label: "" },
      ];
      return labelNames;
    case 2:
      return [
        { label: "Department Name" },
        { label: "Model#" },
        { label: "Description" },
        { label: "Savings" },
        { label: "Credit Value" },
        { label: "Offer End Date" },
        { label: "Delivery" },
        { label: "Qty" },
        { label: "Ext. Cost" },
        { label: "" },
      ];
    case 3:
      return [
        { label: "Pallet SKU" },
        { label: "Model #" },
        { label: "Description" },
        { label: "Retail Fineline" },
        { label: "Savings" },
        { label: "Event Cost" },
        isMaxQtyPerReunion && ({label: 'Max Qty Per Ship Window'}),
        isMaxQty && ({label: 'Max Qty'}),
        ...shipDates,
        { label: "Ext. Cost" },
        { label: "" },
      ];
    case 4:
      return [
        { label: "" },
        { label: "" },
        { label: "Item #" },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ label: "Alternate SKU#" }]
          : []),
        { label: "UPC #" },
        { label: "Model #" },
        { label: "Item Description" },
        { label: "Pack" },
        isMaxQty && ({label: 'Max Qty'}),
        { label: "Promo Cost" },
        ...shipDates,
        { label: "Ext. Cost" },
        { label: "" },
      ];
    case 5:
      return [
        { label: "Pallet SKU" },
        { label: "Model #" },
        { label: "Description" },
        { label: "Retail Fineline" },
        { label: "Savings" },
        { label: "Event Cost" },
        { label: "Delivery" },
        { label: "Qty" },
        { label: "Ext. Cost" },
        { label: "" },
      ];
  }
};

export const getRow = (
  flowType,
  product,
  favIcon,
  or,
  getQuantity,
  removeItem,
  itemImg,
  index,
  products,
  getprefer,
  viewType,
  marketDate,
  marketFlag,
	getAssortmentLink,
	status,
  pageType,
  multiStoreDetails,
  isPalletQty,
  cartInfo
) => {
  switch (pageType) {
    case "view-cart":
      return getViewCartRow(
        flowType,
        product,
        favIcon,
        or,
        getQuantity,
        removeItem,
        itemImg,
        index,
        products,
        getprefer,
        viewType,
        marketDate,
        marketFlag,
        getAssortmentLink,
        multiStoreDetails,
        isPalletQty,
        cartInfo
      );
    case "checkout":
      return getCheckoutRow(
        flowType,
        product,
        products,
        getprefer,
        viewType,
        marketDate,
        marketFlag,
        getAssortmentLink,
        multiStoreDetails,
        cartInfo
			);
		case "confirmation":
			return getConfirmationRow(
				flowType,
				product,
				products,
				getprefer,
				viewType,
        marketDate,
        marketFlag,
				getAssortmentLink,
				status,
        multiStoreDetails,
        cartInfo);
  }
};
const getViewCartRow = (
  flowType,
  product,
  favIcon,
  or,
  getQuantity,
  removeItem,
  itemImg,
  index,
  products,
  getprefer,
  viewType,
  marketDate,
  marketFlag,
  getAssortmentLink,
  multiStoreDetails,
  isPalletQty,
  cartInfo
) => {
  switch (flowType) {
    case 1:
      let eventCost = utilities.zeroForNull(product.promoCost);
      return [
        { cellContent: favIcon },
        { cellContent: itemImg },
        { cellContent: product.item_nbr },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ cellContent: product.aliasSku }]
          : []),
        { cellContent: product.upcNum },
        { cellContent: product.model, cellClass: "t-left" },
        {
          cellContent: getDescription(product,false,false,false,true),
          cellClass: "title-column",
        },
        { cellContent: getPack(product) },
        product.maxQtyItemRestriction && ({cellContent: product.maxQtyItemRestriction ? isPalletQty ? product.maxQtyItemRestriction :getMaxQty(product): '',
                                         cellClass :isPalletQty ? "show-pallet-qty t-center":"show-max-qty t-center"}),
        product?.maxQtyPerReunion && ({cellClass:'t-center', cellContent: product?.maxQtyPerReunion ? product.maxQtyPerReunion: ''}),
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={eventCost}
            />
          ),
          isTiered: false,
        },
        { cellContent: or },
        ...(!utilities.isEmptyOrNullString(product.ShipDate1)
          ? [{ cellContent: getQuantity("1", product, index, 0) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate2)
          ? [{ cellContent: getQuantity("1", product, index, 1) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate3)
          ? [{ cellContent: getQuantity("1", product, index, 2) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate4)
          ? [{ cellContent: getQuantity("1", product, index, 3) }]
          : [{ cellContent: <></> }]),
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={
                (product.userQty +
                  product.userQty2 +
                  product.userQty3 +
                  product.userQty4) *
                eventCost
              }
            />
          ),
        },
        {
          cellContent: removeItem,
        },
      ];
    case 4:
      eventCost = utilities.zeroForNull(product.promoCost);
      return [
        { cellContent: favIcon },
        { cellContent: itemImg },
        { cellContent: product.item_nbr },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ cellContent: product.aliasSku }]
          : []),
        { cellContent: product.upcNum },
        { cellContent: product.model, cellClass: "t-left" },
        {
          cellContent: getDescription(product),
          cellClass: "title-column",
        },
        { cellContent: getPack(product) },
        product.maxQtyItemRestriction && ({cellClass: 't-center',cellContent: product.maxQtyItemRestriction ? getMaxQty(product): ''}),
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={eventCost}
            />
          ),
          isTiered: false,
        },
        ...(utilities.isEmptyOrNullString(product.ShipDate1)
          ? [{ cellContent: getQuantity("4", product, index, 0) }]
          : [{ cellContent: <></> }]),
        { cellContent: <></> },
        { cellContent: <></> },
        { cellContent: <></> },
        // ...(!utilities.isEmptyOrNullString(product.ShipDate2)
        //   ? [{ cellContent: getQuantity("4", product, index, 1) }]
        //   : [{ cellContent: <></> }]),
        // ...(!utilities.isEmptyOrNullString(product.ShipDate3)
        //   ? [{ cellContent: getQuantity("4", product, index, 2) }]
        //   : [{ cellContent: <></> }]),
        // ...(!utilities.isEmptyOrNullString(product.ShipDate4)
        //   ? [{ cellContent: getQuantity("4", product, index, 3) }]
        //   : [{ cellContent: <></> }]),      //commented for now since vendor dropShip has no ship date, may need in future  
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={
                (product.userQty +
                  product.userQty2 +
                  product.userQty3 +
                  product.userQty4) *
                eventCost
              }
            />
          ),
        },
        {
          cellContent: removeItem,
        },
      ];
    case 2:
      return [
        { cellContent: product.deptName || "" },
        {
          cellContent: (
            <span
              className="checkoutPointer"
              onClick={() => getAssortmentLink(product, flowType)}
            >
              {utilities.emptyForNull(product.model)}
            </span>
          ),
        },
        { cellContent: getDescription(product, true) },
        { cellContent: getAssortmentSavings(product, products) },
        { cellContent: getAssortmentCreditValue(product, products) },
        {
          cellContent:
          cartInfo && cartInfo.shoppingAreaId === '12'  && product && product.promoNbr === '541' ? '' : //Temporary validation till the required API changes are made
          product.deptID && product.deptID.trim() === "X"
              ? "While Supplies Last"
              : marketFlag
                ? showOfferendDateMsg(product, marketDate)
                : product.expireddate
                  ? utilities.getDateFromTimeStamp(product.expireddate)
                  : "On Going",
        },
        { cellContent: utilities.hyphenForNull(product.month) },
        { cellContent: getQuantity("2", product, index) },
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer && getprefer.customerView
                  ? getprefer.customerView
                  : "c1"
              }
              viewType={viewType}
              fieldVal={product.cost * product.userQty}
            />
          ),
        },
        { cellContent: removeItem },
      ];
    case 3:
      eventCost = utilities.zeroForNull(product.promoCost);
      let extCost = (product.userQty +
        product.userQty2 +
        product.userQty3 +
        product.userQty4) *
      eventCost;
      return [
        { cellContent: product.item_nbr },
        { cellContent: product.model },
        { cellContent: product.description },
        { cellContent: utilities.emptyForNull(product.retailFineLine) },
        { cellContent: utilities.PercentageSavings(product.savings)},
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={product.promoCost}
            />
          ),
        },
        product?.maxQtyPerReunion && ({cellContent: product?.maxQtyPerReunion ? product.maxQtyPerReunion: ''}),
        product?.maxQtyItemRestriction && ({cellClass: 't-center',cellContent: product?.maxQtyItemRestriction ? getMaxQty(product): ''}),
        ...(!utilities.isEmptyOrNullString(product.ShipDate1)
          ? [{ cellContent: getQuantity("3", product, index, 0) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate2)
          ? [{ cellContent: getQuantity("3", product, index, 1) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate3)
          ? [{ cellContent: getQuantity("3", product, index, 2) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate4)
          ? [{ cellContent: getQuantity("3", product, index, 3) }]
          : [{ cellContent: <></> }]),
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={
                extCost
              }
            />
          ),
        },
        { cellContent: removeItem },
      ];
    case 5:
      eventCost = utilities.zeroForNull(product.promoCost);
      extCost = (product.userQty +
        product.userQty2 +
        product.userQty3 +
        product.userQty4) *
      eventCost;
      return [
        { cellContent: product.item_nbr },
        { cellContent: product.model },
        { cellContent: product.description },
        { cellContent: utilities.emptyForNull(product.retailFineLine) },
        { cellContent: utilities.PercentageSavings(product.savings)},
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={product.promoCost}
            />
          ),
        },
        { cellContent: utilities.hyphenForNull(product.month) },
        { cellContent: getQuantity("5", product, index) },
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={
                extCost
              }
            />
          ),
        },
        { cellContent: removeItem },
      ];
    default:
      return [];
  }
};

const getOverRideDetails =(overrideRetail) => {
  const CostWithDigits = Number(overrideRetail)>0? Number(overrideRetail).toFixed(2) : "";
  return '$' + CostWithDigits;
}
const getConfirmationRow = (
	flowType,
	product,
	products,
	getprefer,
	viewType,
	marketDate,
	marketFlag,
	getAssortmentLink,
	status,
  multiStoreDetails,
  cartInfo) =>{

		const fieldEnum = [
			product.userQty,
			product.userQty2,
			product.userQty3,
			product.userQty4,
    ];
		switch(flowType){
			case 1:
				return [
					{ cellContent: product.item_nbr },
					...(utilities.isAliasSkuRequired(multiStoreDetails)
						? [{ cellContent: product.aliasSku }]
						: []),
					{ cellContent: product.upcNum },
					{ cellContent: product.model, cellClass: "t-left" },
					{
						cellContent: getDescription(product,false,false,false,true),
						cellClass: "title-column",
					},
					{ cellContent: getPack(product) },
					{
						cellContent: store?.SessionReducer?.multiStoreDetails?.access !==
							1 && (
							<MaskPrice
								field="Member Cost black"
								getprefer={
									getprefer?.customerView ? getprefer.customerView : "c1"
								}
								viewType={viewType}
								fieldVal={product.promoCost ? product.promoCost : 0}
							/>
						),
					},
					{ cellContent: getOverRideDetails( product.overrideRetail )},
					...(!utilities.isEmptyOrNullString(product.ShipDate1)
						? [{ cellContent: shipDate(product.userQty, product.ShipDate1) }]
						: [{cellContent:<></>}]),
					...(!utilities.isEmptyOrNullString(product.ShipDate2)
						? [{ cellContent: shipDate(product.userQty2, product.ShipDate2) }]
						: [{cellContent:<></>}]),
					...(!utilities.isEmptyOrNullString(product.ShipDate3)
						? [{ cellContent: shipDate(product.userQty3, product.ShipDate3) }]
						: [{cellContent:<></>}]),
					...(!utilities.isEmptyOrNullString(product.ShipDate4)
						? [{ cellContent: shipDate(product.userQty4, product.ShipDate4) }]
						: [{cellContent:<></>}]),
					{
						cellContent: store?.SessionReducer?.multiStoreDetails.access !==
							1 && (
							<MaskPrice
								field="Member Cost black"
								getprefer={
									getprefer?.customerView ? getprefer.customerView : "c1"
								}
								viewType={viewType}
								fieldVal={
									findTotal(fieldEnum) *
									(product.promoCost ? product.promoCost : 0)
								}
							/>
						),
					}
        ];
        case 4:
          return [
            { cellContent: product.item_nbr },
            ...(utilities.isAliasSkuRequired(multiStoreDetails)
              ? [{ cellContent: product.aliasSku }]
              : []),
            { cellContent: product.upcNum },
            { cellContent: product.model, cellClass: "t-left" },
            {
              cellContent: getDescription(product),
              cellClass: "title-column",
            },
            { cellContent: getPack(product) },
            {
              cellContent: store?.SessionReducer?.multiStoreDetails?.access !==
                1 && (
                <MaskPrice
                  field="Member Cost black"
                  getprefer={
                    getprefer?.customerView ? getprefer.customerView : "c1"
                  }
                  viewType={viewType}
                  fieldVal={product.promoCost ? product.promoCost : 0}
                />
              ),
            },
            ...(utilities.isEmptyOrNullString(product.ShipDate1)
              ? [{ cellContent: shipDateMessage(product.userQty, product.ShipDate1) }]
              : [{cellContent:<></>}]),
            {cellContent:<></>},
            {cellContent:<></>},
            {cellContent:<></>},            
            // ...(!utilities.isEmptyOrNullString(product.ShipDate2)
            //   ? [{ cellContent: shipDate(product.userQty2, product.ShipDate2) }]
            //   : [{cellContent:<></>}]),
            // ...(!utilities.isEmptyOrNullString(product.ShipDate3)
            //   ? [{ cellContent: shipDate(product.userQty3, product.ShipDate3) }]
            //   : [{cellContent:<></>}]),
            // ...(!utilities.isEmptyOrNullString(product.ShipDate4)
            //   ? [{ cellContent: shipDate(product.userQty4, product.ShipDate4) }]
            //   : [{cellContent:<></>}]), //commented for now, since no shipDates for dropShipVendor
            {
              cellContent: store?.SessionReducer?.multiStoreDetails.access !==
                1 && (
                <MaskPrice
                  field="Member Cost black"
                  getprefer={
                    getprefer?.customerView ? getprefer.customerView : "c1"
                  }
                  viewType={viewType}
                  fieldVal={
                    findTotal(fieldEnum) *
                    (product.promoCost ? product.promoCost : 0)
                  }
                />
              ),
            }
          ];
			case 2: 
				return [
          { cellContent: product.deptName || '' },
          {
            cellContent: (
              <span  className='checkoutPointer'  
                onClick={() => getAssortmentLink(product, flowType)}>
                {utilities.emptyForNull(product.model)}
              </span>
            )
          },
          { cellContent: getDescription(product,true) },
          { cellContent: getAssortmentSavings(product,products)},
          {cellContent: getAssortmentCreditValue(product,products)},
          {
            cellContent: 
                      cartInfo && cartInfo.shoppingAreaId === '12'  && product && product.promoNbr === '541' ? '' :
                      ((product.deptID && product.deptID.trim()==='X') || (product.deptName==='FLASH SALE')) ? 'While Supplies Last'
                      : marketFlag ? showOfferendDateMsg(product,marketDate)
                      : product.expireddate ? utilities.getDateFromTimeStamp(product.expireddate) : 'On Going'
          },
          { cellContent: product.month? product.month: '-' },
          { cellContent: product.userQty },
          { cellContent:  <MaskPrice 
                            field ='Member Cost black'
                            getprefer={getprefer?.customerView
                              ? getprefer.customerView
                              : 'c1'
                            }
                            viewType={viewType} 
                            fieldVal={ product.cost * product.userQty } /> },
          { cellContent: status || 'Success' }
				];
			case 3:
				return [
					{cellContent: product.item_nbr},
					{cellContent:product.model},
					{cellContent:getDescription(product)},
					{cellContent:utilities.emptyForNull(product.retailFineLine)},
					{cellContent:utilities.PercentageSavings(product.savings)},
					{
						cellContent: (
							<MaskPrice
								field="Member Cost black"
								getprefer={
									getprefer?.customerView ? getprefer.customerView : "c1"
								}
								viewType={viewType}
								fieldVal={product.promoCost}
							/>
						),
					},
					...(!utilities.isEmptyOrNullString(product.ShipDate1)
          ?[{ cellContent: shipDate(product.userQty, product.ShipDate1) }]
						: [{cellContent:<></>}]),
					...(!utilities.isEmptyOrNullString(product.ShipDate2)
          ?[{ cellContent: shipDate(product.userQty2, product.ShipDate2) }]
						: [{cellContent:<></>}]),
					...(!utilities.isEmptyOrNullString(product.ShipDate3)
          ?[{ cellContent: shipDate(product.userQty3, product.ShipDate3) }]
						: [{cellContent:<></>}]),
					...(!utilities.isEmptyOrNullString(product.ShipDate4)
          ?[{ cellContent: shipDate(product.userQty4, product.ShipDate4) }]
						: [{cellContent:<></>}]),
					{
						cellContent: store?.SessionReducer?.multiStoreDetails.access !==
							1 && (
							<MaskPrice
								field="Member Cost black"
								getprefer={
									getprefer?.customerView ? getprefer.customerView : "c1"
								}
								viewType={viewType}
								fieldVal={
									findTotal(fieldEnum) *
									(product.promoCost ? product.promoCost : 0)
								}
							/>
						),
					}
        ];
    case 5:
      return [
        {cellContent: product.item_nbr},
        {cellContent:product.model},
        {cellContent:getDescription(product)},
        {cellContent:utilities.emptyForNull(product.retailFineLine)},
        {cellContent:utilities.PercentageSavings(product.savings)},
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={product.promoCost}
            />
          ),
        },
        { cellContent: product.month? product.month: '-' },
        { cellContent: product.userQty },
        {
          cellContent: store?.SessionReducer?.multiStoreDetails.access !==
            1 && (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={
                findTotal(fieldEnum) *
                (product.promoCost ? product.promoCost : 0)
              }
            />
          ),
        }
      ]
		}

}
const getCheckoutHeaders = (flowType, cart,multiStoreDetails, viewType, getPrefer) => {
  const shipDates = getShipDates(cart);
  switch (flowType) {
    case 1:
      let labelNames = [
        { label: "Item #" },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ label: "Alternate SKU#" }]
          : []),
        { label: "UPC #" },
        { label: "Model #" },
        { label: "Item Description" },
        { label: "Pack" },
      ]
      if(viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
        labelNames = [...labelNames,{ label : ""}]
      } else {
        labelNames = [...labelNames,{ label: utilities.getLabelsByType(viewType,"EVENT_COST", getPrefer) }]
      }
      labelNames = [...labelNames,
        { label: "Override Retail" },
        ...shipDates,
      ]
      if(viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
        labelNames = [...labelNames,{ label : ""}]
      } else {
        labelNames = [...labelNames,{ label: utilities.getLabelsByType(viewType,"EXTENDED_COST", getPrefer) }]
      }
      return labelNames;
    case 2:
      return [
        { label: "Department Name" },
        { label: "Model #" },
        { label: "Description" },
        { label: "Savings" },
        { label: "Credit Value" },
        { label: "Offer end date" },
        { label: "Delivery" },
        { label: "Qty" },
        { label: "Ext" },
      ];
    case 3:
      return [
        { label: "Pallet SKU" },
        { label: "Model #" },
        { label: "Description" },
        { label: "Retail Fineline" },
        { label: "Savings" },
        { label: "Event Cost" },
        ...shipDates,
        { label: "Ext. Cost" }
      ];
    case 4:
      return [
        { label: "Item #" },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ label: "Alternate SKU#" }]
          : []),
        { label: "UPC #" },
        { label: "Model #" },
        { label: "Item Description" },
        { label: "Pack" },
        { label: "Cost" },
        ...shipDates,
        { label: "Ext. Cost" },
      ];
    case 5:
      return [
        { label: "Pallet SKU" },
        { label: "Model #" },
        { label: "Description" },
        { label: "Retail Fineline" },
        { label: "Savings" },
        { label: "Event Cost" },
        { label: "Delivery" },
        { label: "Qty" },
        { label: "Ext. Cost" },
        { label: "" },
      ];
    default:
      return [];
  }
};
export const shipDate = (quantity, date) => {
  return (
    <>
      <span>{quantity}</span>
      <span className="addedBy displayBlock">
        (Ship date: {utilities.getDateFromTimeStamp(date)})
      </span>
    </>
  );
};

export const shipDateMessage = (quantity, date) => {
  return (
    <>
      <span>{quantity}</span>
      <span className="addedBy displayBlock">
        (Contact Vendor<br/> for Ship Dates)
      </span>
    </>
  );
};

export const getCheckoutRow = (
  flowType,
  product,
  products,
  getprefer,
  viewType,
  marketDate,
  marketFlag,
  getAssortmentLink,
  multiStoreDetails,
  cartInfo
) => {
  const fieldEnum = [
    product.userQty,
    product.userQty2,
    product.userQty3,
    product.userQty4,
  ];
  switch (flowType) {
    case 1:
      return [
        { cellContent: product.item_nbr },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ cellContent: product.aliasSku }]
          : []),
        { cellContent: product.upcNum },
        { cellContent: product.model, cellClass: "t-left" },
        {
          cellContent: getDescription(product,false,false,false,true),
          cellClass: "title-column",
        },
        { cellContent: getPack(product) },
        {
          cellContent: store?.SessionReducer?.multiStoreDetails?.access !==
            1 && (
              <MaskPrice
                field="Member Cost black"
                getprefer={
                  getprefer?.customerView ? getprefer.customerView : "c1"
                }
                viewType={viewType}
                fieldVal={product.promoCost ? product.promoCost : 0}
              />
            ),
        },
        { cellContent: getOverRideDetails( product.overrideRetail ) },
        ...(!utilities.isEmptyOrNullString(product.ShipDate1)
          ? [{ cellContent: shipDate(product.userQty, product.ShipDate1)  }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate2)
          ? [{ cellContent: shipDate(product.userQty2, product.ShipDate2) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate3)
          ? [{ cellContent: shipDate(product.userQty3, product.ShipDate3) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate4)
          ? [{ cellContent: shipDate(product.userQty4, product.ShipDate4) }]
          : [{ cellContent: <></> }]),
        {
          cellContent: store?.SessionReducer?.multiStoreDetails.access !==
            1 && (
              <MaskPrice
                field="Member Cost black"
                getprefer={
                  getprefer?.customerView ? getprefer.customerView : "c1"
                }
                viewType={viewType}
                fieldVal={
                  findTotal(fieldEnum) *
                  (product.promoCost ? product.promoCost : 0)
                }
              />
            ),
        },
      ];
      case 4:
        return [
          { cellContent: product.item_nbr },
          ...(utilities.isAliasSkuRequired(multiStoreDetails)
            ? [{ cellContent: product.aliasSku }]
            : []),
          { cellContent: product.upcNum },
          { cellContent: product.model, cellClass: "t-left" },
          {
            cellContent: getDescription(product),
            cellClass: "title-column",
          },
          { cellContent: getPack(product) },
          {
            cellContent: store?.SessionReducer?.multiStoreDetails?.access !==
              1 && (
                <MaskPrice
                  field="Member Cost black"
                  getprefer={
                    getprefer?.customerView ? getprefer.customerView : "c1"
                  }
                  viewType={viewType}
                  fieldVal={product.promoCost ? product.promoCost : 0}
                />
              ),
          },
          ...(utilities.isEmptyOrNullString(product.ShipDate1)
            ? [{ cellContent: shipDateMessage(product.userQty, product.ShipDate1)  }]
            : [{ cellContent: <></> }]),
          { cellContent: <></> },
          { cellContent: <></> },
          { cellContent: <></> },
          // ...(!utilities.isEmptyOrNullString(product.ShipDate2)
          //   ? [{ cellContent: shipDate(product.userQty2, product.ShipDate2) }]
          //   : [{ cellContent: <></> }]),
          // ...(!utilities.isEmptyOrNullString(product.ShipDate3)
          //   ? [{ cellContent: shipDate(product.userQty3, product.ShipDate3) }]
          //   : [{ cellContent: <></> }]),
          // ...(!utilities.isEmptyOrNullString(product.ShipDate4)
          //   ? [{ cellContent: shipDate(product.userQty4, product.ShipDate4) }]
          //   : [{ cellContent: <></> }]),  //commented for now, because no shipDates for vendorDropShip
          {
            cellContent: store?.SessionReducer?.multiStoreDetails.access !==
              1 && (
                <MaskPrice
                  field="Member Cost black"
                  getprefer={
                    getprefer?.customerView ? getprefer.customerView : "c1"
                  }
                  viewType={viewType}
                  fieldVal={
                    findTotal(fieldEnum) *
                    (product.promoCost ? product.promoCost : 0)
                  }
                />
              ),
          },
        ];
    case 2:
      return [
        { cellContent: product.deptName || "" },
        {
          cellContent: (
            <span
              className="checkoutPointer"
              onClick={() => getAssortmentLink(product, flowType)}
            >
              {utilities.emptyForNull(product.model)}
            </span>
          ),
        },
        { cellContent: getDescription(product, true, true) },
        { cellContent: getAssortmentSavings(product, products) },
        { cellContent: getAssortmentCreditValue(product, products) },
        {
          cellContent:
            cartInfo && cartInfo.shoppingAreaId === '12'  && product && product.promoNbr === '541' ? '' :
            (product.deptID && product.deptID.trim() === "X") ||
              product.deptName === "FLASH SALE"
              ? "While Supplies Last"
              : marketFlag
                ? showOfferendDateMsg(product, marketDate)
                : product.expireddate
                  ? utilities.getDateFromTimeStamp(product.expireddate)
                  : "On Going",
        },
        { cellContent: product.month ? product.month : "-" },
        { cellContent: product.userQty },
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={product.cost * product.userQty}
            />
          ),
        },
      ];
    case 3:
      return [
        { cellContent: product.item_nbr },
        { cellContent: product.model },
        { cellContent: getDescription(product, false, true) },
        { cellContent: utilities.emptyForNull(product.retailFineLine) },
        { cellContent: utilities.PercentageSavings(product.savings)},
        {
          cellContent: (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={product.promoCost}
            />
          ),
        },
        ...(!utilities.isEmptyOrNullString(product.ShipDate1)
        ?[{ cellContent: shipDate(product.userQty, product.ShipDate1) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate2)
        ?[{ cellContent: shipDate(product.userQty2, product.ShipDate2) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate3)
        ?[{ cellContent: shipDate(product.userQty3, product.ShipDate3) }]
          : [{ cellContent: <></> }]),
        ...(!utilities.isEmptyOrNullString(product.ShipDate4)
        ?[{ cellContent: shipDate(product.userQty4, product.ShipDate4) }]
          : [{ cellContent: <></> }]),
        {
          cellContent: store?.SessionReducer?.multiStoreDetails.access !==
            1 && (
              <MaskPrice
                field="Member Cost black"
                getprefer={
                  getprefer?.customerView ? getprefer.customerView : "c1"
                }
                viewType={viewType}
                fieldVal={
                  findTotal(fieldEnum) *
                  (product.promoCost ? product.promoCost : 0)
                }
              />
            ),
        }
      ];
  case 5:
    return [
      { cellContent: product.item_nbr },
      { cellContent: product.model },
      { cellContent: getDescription(product, false, true) },
      { cellContent: utilities.emptyForNull(product.retailFineLine) },
      { cellContent: utilities.PercentageSavings(product.savings)},
      {
        cellContent: (
          <MaskPrice
            field="Member Cost black"
            getprefer={
              getprefer?.customerView ? getprefer.customerView : "c1"
            }
            viewType={viewType}
            fieldVal={product.promoCost}
          />
        ),
      },
      { cellContent: product.month ? product.month : "-" },
      { cellContent: product.userQty },
      {
        cellContent: store?.SessionReducer?.multiStoreDetails.access !==
          1 && (
            <MaskPrice
              field="Member Cost black"
              getprefer={
                getprefer?.customerView ? getprefer.customerView : "c1"
              }
              viewType={viewType}
              fieldVal={
                findTotal(fieldEnum) *
                (product.promoCost ? product.promoCost : 0)
              }
            />
          ),
      }
    ];
  }
};
export const getHeaders = (flowType, cart, pageType, multiStoreDetails, viewType, getPrefer) => {
  switch (pageType) {
    case "view-cart":
      return getViewCartHeaders(flowType, cart, multiStoreDetails, viewType, getPrefer);
      break;
    case "checkout":
      return getCheckoutHeaders(flowType, cart,  multiStoreDetails, viewType, getPrefer);
      break;
    case "confirmation":
      return getConfirmationHeaders(flowType, cart, multiStoreDetails, viewType, getPrefer);
  }
};

const getConfirmationHeaders = (flowType, cart, multiStoreDetails, viewType, getPrefer) => {
  const shipDates = getShipDates(cart);
  switch (flowType) {
    case 1:
      let labelNames = [
        { label: "Item #" },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ label: "Alternate SKU#" }]
          : []),
        { label: "UPC #" },
        { label: "Model #" },
        { label: "Item Description" },
        { label: "Pack" },
      ]
      if(viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
        labelNames = [...labelNames,{label: ""}]
      } else {
        labelNames = [...labelNames,{ label: utilities.getLabelsByType(viewType,"EVENT_COST", getPrefer) }]
      }
      labelNames = [...labelNames,
        { label: "Override Retail" },
        ...shipDates,
      ]
      if(viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
        labelNames = [...labelNames,{label: ""}]
      } else {
        labelNames = [...labelNames,{ label: utilities.getLabelsByType(viewType,"EXTENDED_COST", getPrefer) }]
      }
      return labelNames;
    case 2:
      return [
        { label: 'Department Name' },
        { label: 'Model #' },
        { label: 'Item Description' },
        { label: 'Savings' },
        { label: 'Credit Value' },
        { label: 'Offer end date' },
        { label: 'Delivery' },
        { label: 'Qty' },
        { label: 'Ext' },
        { label: 'Status' }
      ];
    case 3:
      return [
        { label: "Pallet SKU" },
        { label: "Model#" },
        { label: "Description" },
        { label: "Retail Fineline" },
        { label: "Savings" },
        { label: "Event Cost" },
        ...shipDates,
        { label: "Ext. Cost" }
      ];
    case 4:
      return [
        { label: "Item #" },
        ...(utilities.isAliasSkuRequired(multiStoreDetails)
          ? [{ label: "Alternate SKU#" }]
          : []),
        { label: "UPC #" },
        { label: "Model #" },
        { label: "Item Description" },
        { label: "Pack" },
        { label: "Cost" },
        ...shipDates,
        { label: "Ext. Cost" },
      ];
    case 5:
      return [
        { label: "Pallet SKU" },
        { label: "Model #" },
        { label: "Description" },
        { label: "Retail Fineline" },
        { label: "Savings" },
        { label: "Event Cost" },
        { label: "Delivery" },
        { label: "Qty" },
        { label: "Ext. Cost" },
        { label: "" },
      ];
  }
};

export const getExcelExport = (order, getPrefer, poNum, viewType , aliasSKURetailerGrpID,status) => {
  utilities.showToast("Exporting to Excel...");
  const flowType = shoppingAreaFlowTypes[order[0]?.cartInfoName];
  let fileType = `${order[0].DisplayName}`;
  const raDate=(item)=>{
    return item.month  ? showOfferendDateMsg(item)
           : item.expireddate ? 
             utilities.getDateFromTimeStamp(item.expireddate) : 'On Going'
   }

   const showOfferendDateMsg = (product) => {
     return utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="Y" ? 'On Going'
             : utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="N" ? product?.RAMarketEndDate
             : !utilities.isEmptyOrNullString(product.expireddate) && utilities.getDateFromTimeStamp(product.expireddate)
     }

  const  getRAMessage = (item) => {
     return (
         !utilities.isEmptyOrNullString(item.orderedModelNbr) ?                                   
           "Not Eligible for Credit, Previously Purchased" + item.orderedModelNbr
       : !utilities.isEmptyOrNullString(item.orderedDate) ?
           "Credit Redeemed" +item.orderedDate +' ('+ item.rebateAmount+')'
       :item.month  ? showMessagesForRAmkt(item)
       : showMsgsbasedonQty(item) 
     )
   }

   const showMessagesForRAmkt = (product) => {
    const savings = utilities.PercentageSavings(product.savings);
    return (product.userQty > 1 ) 
    ? "save" + savings + "with $" + product.rebateAmount
    : product.userQty <= 1 
    ? "save" + savings+"with $"+ product.rebateAmount+"credit" 
    : "save" + savings+"with $"+ product.rebateAmount
    }

 const showMsgsbasedonQty = (product) => {
     let savinCalc = parseInt(utilities.hyphenForZero(product && product.rebateAmount)) /  parseInt(utilities.emptyStringForZero(product.regCost));
     let rebateCredit = isNaN(savinCalc) ? 0 : parseFloat(savinCalc).toFixed(2);
     const  getSavings = utilities.PercentageSavings(rebateCredit);  
     let qtyMsg = '';
     if (product.userQty > 1) {
       if (product.deptID && product.deptID === 'X') {
         qtyMsg = "save" + getSavings + "with"+ product.rebateAmount+ "credit per bundle"
       } else {
         qtyMsg ="save" + getSavings + "with"+ product.rebateAmount+ "credit Credit and dating will only apply to one assortment per Retail Assortment category"
       }
     } else {
       if (product.deptID && product.deptID=== 'X') {
         qtyMsg = "save" + getSavings+ "with credit per bundle" 
       } else {
         qtyMsg =  "save" +getSavings+ "with" + product.rebateAmount+"credit"
       }
     }
     return qtyMsg;
   }
   let allOrdersToDownload = [];
   order.map( function(multiStoreItem) {
    let row1;
    row1 = {...row1,...{'Store #':multiStoreItem.storeId,
        'Order Date':utilities.getDateFromTimeStamp(multiStoreItem.lastModified),
        'PO #':poNum[multiStoreItem.storeId],
        'Source':'True Value Company',
        'Ship To':multiStoreItem.shipTo,
        'Shipping Method':multiStoreItem.shipByOrder?multiStoreItem.shipByOrder:'Regular Delivery'
      }
      };
      if (viewType !== MEMBER_VIEW) {
        if (getPrefer !== 'c3' && getPrefer !== 'c4') {
          row1 = {
            ...row1,
            'OT': utilities.getCostBasedOnnatvalue(viewType,multiStoreItem.totalCost,getPrefer),
          }
        } else {
          row1 = {
            ...row1
          }
        }
      } else {
        row1 = {
          ...row1,
           'Order Total':utilities.getCostBasedOnnatvalue(viewType,multiStoreItem.totalCost,getPrefer)
        }
        }
    multiStoreItem.items.map(function (item) {
    if (flowType === 1) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {};
      row = {...row, ...row1};
      if(!utilities.isEmptyOrNullString(item.item_nbr)){
        row = {...row, ...{'Item #': utilities.emptyForNull(item.item_nbr)}};
       }
      if(!utilities.isEmptyOrNullString(aliasSKURetailerGrpID)){
       row = {...row, ...{'Alternate SKU#': item.aliasSku}};
      }
      row = {
        ...row, ...{
          'UPC #': utilities.emptyForNull(item.upcNum),
          'Model#': utilities.emptyForNull(item.model),
          'Item Description': utilities.emptyForNull(item.product_title || item.description) + ' ' + utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          'Pack': utilities.emptyForNull(utilities.emptyForNull(item.s_pack_type) + utilities.emptyForNull(item.s_pack_qty)),                 
        }
      }; 
      if (viewType !== MEMBER_VIEW) {
        if (getPrefer !== 'c3' && getPrefer !== 'c4') {
          row = {
            ...row,
            'EVC': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
          }
        } else {
          row = {
            ...row
          }
        }
      } else {
        row = {
          ...row,
          'Event Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
        }
      }
      row = { ...row, ... {'Override Retail': utilities.emptyStringForZero(item.overrideRetail)} };
      if (!utilities.isEmptyOrNullString(item.ShipDate1)) {
        row = { ...row, ...{ "Qty1": utilities.emptyForNull(item.userQty) } };
      }
      else {
        row = { ...row, ...{ "Qty1": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.ShipDate2)) {
        row = { ...row, ...{ "Qty2": utilities.emptyForNull(item.userQty2) } };
      }
      else {
        row = { ...row, ...{ "Qty2": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.ShipDate3)) {
        row = { ...row, ...{ "Qty3": utilities.emptyForNull(item.userQty3) } };
      }
      else {
        row = { ...row, ...{ "Qty3": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.ShipDate4)) {
        row = { ...row, ...{ "Qty4": utilities.emptyForNull(item.userQty4) } };
      }
      else {
        row = { ...row, ...{ "Qty4": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.promoCost)) {
        if (viewType !== MEMBER_VIEW) {
          if (getPrefer !== 'c3' && getPrefer !== 'c4') {
            row = {
              ...row,
              'EC': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer),
            }
          } else {
            row = {
              ...row,
            }
          }
        } else {
          row = {
            ...row,
            'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer),
          }
        }       
      }
      allOrdersToDownload.push(row);
      return item;
    }

    if (flowType === 2) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {};
      row = {...row, ...row1};
      if(!utilities.isEmptyOrNullString(aliasSKURetailerGrpID)){
       row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
      }
      row = {
        ...row, ...{
          'Department Name': utilities.emptyForNull(item.deptName),
          'Model#': utilities.emptyForNull(item.model),
          'Item Description': utilities.emptyForNull(item.product_title || item.description) ,
          'Saving': utilities.emptyForPercentageSavings(item.savings),
          'Credit Value': getRAMessage(item),
          'Offer end date': multiStoreItem && multiStoreItem.shoppingAreaId === '12'  && item && item.promoNbr === '541' ? '' : raDate(item),
          'Delivery':  utilities.emptyForNull(item.month),
          'Qty': utilities.emptyForNull(item.userQty),
         'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, item.cost * item.userQty, getPrefer),
        }
      }; 
      allOrdersToDownload.push(row);
      return item;
    }
    else if (flowType === 3) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {};
      row = {...row, ...row1};
      row = {
        ...row, ...{
          'Pallet SKU': utilities.emptyForNull(item.item_nbr),
          'Model#': utilities.emptyForNull(item.model),
          'Description':  utilities.emptyForNull(item.product_title || item.description) + ' ' + utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          'Retail Fineline': utilities.emptyForNull(item.retailFineLine),
          'Savings': utilities.emptyForZeroPercentage(item.savings),
          'Event Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
        }
      };
      if (!utilities.isEmptyOrNullString(item.ShipDate1)) {
        row = { ...row, ...{ "Qty1": utilities.emptyForNull(item.userQty) } };
      }
      else {
        row = { ...row, ...{ "Qty1": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.ShipDate2)) {
        row = { ...row, ...{ "Qty2": utilities.emptyForNull(item.userQty2) } };
      }
      else {
        row = { ...row, ...{ "Qty2": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.ShipDate3)) {
        row = { ...row, ...{ "Qty3": utilities.emptyForNull(item.userQty3) } };
      }
      else {
        row = { ...row, ...{ "Qty3": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.ShipDate4)) {
        row = { ...row, ...{ "Qty4": utilities.emptyForNull(item.userQty4) } };
      }
      else {
        row = { ...row, ...{ "Qty4": '' } };
      }
      if (!utilities.isEmptyOrNullString(item.promoCost)) {
        row = { ...row, ...{ 'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer) } };
      }
      allOrdersToDownload.push(row);
      return item;
    }
    else if (flowType === 4) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {};
      row = {...row, ...row1};
	    row = { ...row, ...{
          'Pallet SKU': utilities.emptyForNull(item.item_nbr),
          'UPC #': utilities.emptyForNull(item.upcNum),
          'Model#': utilities.emptyForNull(item.model),
          'Item Description':  utilities.emptyForNull(item.product_title || item.description) + ' ' + utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          'Pack': utilities.emptyForNull(utilities.emptyForNull(item.s_pack_type) + utilities.emptyForNull(item.s_pack_qty)),
          'Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
          'Qty1':utilities.emptyForNull(item.userQty),
          'Qty2':utilities.emptyForNull(item.userQty2),
          'Qty3':utilities.emptyForNull(item.userQty3),
          'Qty4':utilities.emptyForNull(item.userQty4),
          'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer)
        }
      }
      allOrdersToDownload.push(row);
      return item;
    }
    else if (flowType === 5) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {};
      row = {...row, ...row1};
      row = {
        ...row, ...{
          'Pallet SKU': utilities.emptyForNull(item.item_nbr),
          'Model#': utilities.emptyForNull(item.model),
          'Description':  utilities.emptyForNull(item.product_title || item.description) + ' ' + utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          'Retail Fineline': utilities.emptyForNull(item.retailFineLine),
          'Savings': utilities.emptyForZeroPercentage(item.savings),
          'Event Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
          'Delivery':utilities.emptyForNull(item.month),
          'Qty':utilities.emptyForNull(item.itemQty),
        }
      };
      if (!utilities.isEmptyOrNullString(item.promoCost)) {
        row = { ...row, ...{ 'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer) } };
      }
      allOrdersToDownload.push(row);
      return item;
    }
  })
});
  utilities.exportToCsv(allOrdersToDownload, fileType);
}
export const cartExcelExport = (order, getPrefer, viewType , aliasSKURetailerGrpID,marketFlag, marketDate, storeId, storeName) => {
  utilities.showToast("Exporting to Excel...");
  const flowType = shoppingAreaFlowTypes[order[0]?.cartInfoName];
  let fileType = `${order[0].DisplayName}`;
  const getPreferView = getPrefer.customerView?getPrefer.customerView:getPrefer;
  const showOfferendDateMsg = (product,marketDate) => {
    return utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="Y" ? 'On Going'
            : utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="N" ? marketDate
            : !utilities.isEmptyOrNullString(product.expireddate) && utilities.getDateFromTimeStamp(product.expireddate)
  }
  const raDate=(product)=>{
    return  product.deptID && product.deptID.trim() === "X"
      ? "While Supplies Last"
      : marketFlag
        ? showOfferendDateMsg(product, marketDate)
        : product.expireddate
          ? utilities.getDateFromTimeStamp(product.expireddate)
          : "On Going"
   }
  
   let allOrdersToDownload = [];
   order.map( function(multiStoreItem) {
    multiStoreItem.items.map(function (item) {
    if (flowType === 1) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let maxQty = {}
      if(item.maxQtyItemRestriction > 0) {
        maxQty = {
          [item.isMultiples ? 'Pallet Qty': 'Max Qty'] : item.maxQtyItemRestriction
        }
        if(item.isMultiples) {
          maxQty = {
            ...maxQty,
            'Max Qty Per Ship Window': item.maxQtyItemRestriction
          }
        }
      }
      let row = {
      'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
      'Cart Description':utilities.emptyForNull( multiStoreItem.DisplayName),
      'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
      'Store Name': utilities.emptyForNull(storeName),
      'Added By':  item.userName ? item.userName : '',};
      if(!utilities.isEmptyOrNullString(item.item_nbr)){
        row = {...row, ...{'Item #': utilities.emptyForNull(item.item_nbr)}};
       }
      if(!utilities.isEmptyOrNullString(aliasSKURetailerGrpID)){
       row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
      }
      row = {
        ...row, ...{
          'UPC #': utilities.emptyForNull(item.upcNum)?.toString(),
          'Model#': utilities.emptyForNull(item.model),
          'Item Description': utilities.emptyForNull(item.product_title || item.description),
          'Manufacturer':utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          'Pack': utilities.emptyForNull(utilities.emptyForNull(item.s_pack_type) + utilities.emptyForNull(item.s_pack_qty)),
          ...maxQty,
        }
      }
      if (viewType !== MEMBER_VIEW) {
        if (getPreferView !== 'c3' && getPreferView !== 'c4') {
          row = {
            ...row,
            'EVC': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPreferView),
          }
        } else {
          row = {
            ...row
          }
        }
      } else {
        row = {
          ...row,
          'Event Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPreferView),
        }
      }
      row = {
        ...row, ...{              
          'Override Retail': utilities.emptyStringForZero(item.overrideRetail),
          'Ship Date 1': utilities.emptyForNull(item.ShipDate1),
          'Ship Date 1 Qty': utilities.emptyForNull(item.userQty),
          'Ship Date 2': utilities.emptyForNull(item.ShipDate2),
          'Ship Date 2 Qty': utilities.emptyForNull(item.userQty2),
          'Ship Date 3': utilities.emptyForNull(item.ShipDate3),
          'Ship Date 3 Qty': utilities.emptyForNull(item.userQty3),
          'Ship Date 4': utilities.emptyForNull(item.ShipDate4),
          'Ship Date 4 Qty': utilities.emptyForNull(item.userQty4),
        }
      }; 
      if (!utilities.isEmptyOrNullString(item.promoCost)) {
        if (viewType !== MEMBER_VIEW) {
          if (getPreferView !== 'c3' && getPreferView !== 'c4') {
            row = {
              ...row,
              'EC': utilities.getCostBasedOnnatvalue(viewType, extprice, getPreferView),
            }
          } else {
            row = {
              ...row,
            }
          }
        } else {
          row = {
            ...row,
            'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPreferView),
          }
        }
        if(fileType !== 'Bargain Burners' && fileType !== 'Warehouse Department Events'){
          row = { ...row, ...{ 
            'Tier Indicator': item.tiered ? `T${utilities.getTierLevelForQty(item, item?.userQty)}`: '',
            'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
          }};
        }
        
        row = { ...row, ...{ 
          'Error Message': utilities.emptyForNull(item.message),
        }};
      }
      allOrdersToDownload.push(row);
      return item;
    }

    if (flowType === 2) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {
      'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
      'Cart Description': utilities.emptyForNull(multiStoreItem.DisplayName),
      'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
      'Store Name': utilities.emptyForNull(storeName),
      'Added By':  item.userName ? item.userName : '',};
      if(!utilities.isEmptyOrNullString(aliasSKURetailerGrpID)){
       row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
      }
      row = {
        ...row, ...{
          'Department Name': utilities.emptyForNull(item.deptName),
          'Model#': utilities.emptyForNull(item.model),
          'Item Description': utilities.emptyForNull(item.product_title || item.description) ,
          'Savings': utilities.emptyForZeroPercentage(item.savings),
          'Credit Value':  getAssortmentCreditValue(item, multiStoreItem.items),
          'Offer end date': multiStoreItem && multiStoreItem.shoppingAreaId === '12'  && item && item.promoNbr === '541' ? '' : raDate(item),
          'Delivery':  utilities.emptyForNull(item.month),
          'Qty': utilities.emptyForNull(item.userQty),
          'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, item.cost * item.userQty, getPrefer),
          'Tier Indicator': item.tiered ? `T${utilities.getTierLevelForQty(item, item?.userQty)}`: '',
          'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
          'Error Message': utilities.emptyForNull(item.message),
        }
      }; 
      allOrdersToDownload.push(row);
      return item;
    }
    else if (flowType === 3) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {
        'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
        'Cart Description': utilities.emptyForNull(multiStoreItem.DisplayName),
         'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
         'Store Name': utilities.emptyForNull(storeName),
         'Added By':  item.userName ? item.userName : '',
      };
      row = {
        ...row, ...{
          'Pallet SKU': utilities.emptyForNull(item.item_nbr),
          'Model#': utilities.emptyForNull(item.model),
          'Description':  utilities.emptyForNull(item.product_title || item.description),
          'Manufacturer':utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          'Retail Fineline': utilities.emptyForNull(item.retailFineLine),
          'Savings': utilities.emptyForZeroPercentage(item.savings),
          'Event Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
          'Error Message': utilities.emptyForNull(item.message),
          'Ship Date 1': utilities.emptyForNull(item.ShipDate1),
          'Ship Date 1 Qty': utilities.emptyForNull(item.userQty),
          'Ship Date 2': utilities.emptyForNull(item.ShipDate2),
          'Ship Date 2 Qty': utilities.emptyForNull(item.userQty2),
          'Ship Date 3': utilities.emptyForNull(item.ShipDate3),
          'Ship Date 3 Qty': utilities.emptyForNull(item.userQty3),
          'Ship Date 4': utilities.emptyForNull(item.ShipDate4),
          'Ship Date 4 Qty': utilities.emptyForNull(item.userQty4),
        }
      };
      if (!utilities.isEmptyOrNullString(item.promoCost)) {
        row = { ...row, ...{ 
          'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer),
          'Tier Indicator': item.tiered ? `T${utilities.getTierLevelForQty(item, item?.userQty)}`: '',
          'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item) } };
      }
      allOrdersToDownload.push(row);
      return item;
    }
    else if (flowType === 4) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let maxQty = {}
      if(item.maxQtyItemRestriction > 0) {
        maxQty = {
          [item.isMultiples ? 'Pallet Qty': 'Max Qty'] : utilities.emptyForNull(item.maxQtyItemRestriction)
        }
        if(item.isMultiples) {
          maxQty = {
            ...maxQty,
            'Max Qty Per Ship Window': utilities.emptyForNull(item.maxQtyPerReunion)
          }
        }
      }
      let row = {
          'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
          'Cart Description': utilities.emptyForNull(multiStoreItem.DisplayName),
          'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
          'Store Name': utilities.emptyForNull(storeName),
          'Added By':  item.userName ? item.userName : '',
          'Item #': utilities.emptyForNull(item.item_nbr),
          'UPC #': utilities.emptyForNull(item.upcNum).toString(),
          'Model#': utilities.emptyForNull(item.model),
          'Item Description':  utilities.emptyForNull(item.product_title || item.description),
          'Manufacturer':utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          ...maxQty,
          'Pack': utilities.emptyForNull(utilities.emptyForNull(item.s_pack_type) + utilities.emptyForNull(item.s_pack_qty)),
          'Promo Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
          'Qty1':utilities.emptyForNull(item.userQty),
          'Qty2':utilities.emptyForNull(item.userQty2),
          'Qty3':utilities.emptyForNull(item.userQty3),
          'Qty4':utilities.emptyForNull(item.userQty4),
          'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer),
          'Tier Indicator': item.tiered ? `T${utilities.getTierLevelForQty(item, item?.userQty)}`: '',
          'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
          'Error Message': utilities.emptyForNull(item.message),
        }
      allOrdersToDownload.push(row);
      return item;
    }
    else if (flowType === 5) {
      let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4,
      ];
      let extprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
      let row = {
      'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
      'Cart Description': utilities.emptyForNull(multiStoreItem.DisplayName),
      'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
      'Store Name': utilities.emptyForNull(storeName),
      'Added By':  item.userName ? item.userName : '',};
      row = {
        ...row, ...{
          'Pallet SKU': utilities.emptyForNull(item.item_nbr),
          'Model#': utilities.emptyForNull(item.model),
          'Description':  utilities.emptyForNull(item.product_title || item.description),
          'Manufacturer':utilities.emptyForNull(item.vendorName.trim()) + ' ' + utilities.emptyForNull(item.hazMat && item.hazMat === "Y" ? "HAZMAT" : ' '),
          'Retail Fineline': utilities.emptyForNull(item.retailFineLine),
          'Savings': utilities.emptyForZeroPercentage(item.savings),
          'Event Cost': utilities.getCostBasedOnnatvalue(viewType, item.promoCost, getPrefer),
          'Tier Indicator': item.tiered ? `T${utilities.getTierLevelForQty(item, item?.userQty)}`: '',
          'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
          'Delivery':utilities.emptyForNull(item.month),
          'Qty':utilities.emptyForNull(item.itemQty),
          'Error Message': utilities.emptyForNull(item.message),
        }
      };
      if (!utilities.isEmptyOrNullString(item.promoCost)) {
        row = { ...row, ...{ 'Ext Cost': utilities.getCostBasedOnnatvalue(viewType, extprice, getPrefer) } };
      }
      allOrdersToDownload.push(row);
      return item;
    }
  })
});
  utilities.exportToCsv(allOrdersToDownload, fileType);
}