import React, { Component} from 'react';
import { getButton } from "./ComponentUtilities";
import { Checkbox} from 'semantic-ui-react';
class MenuOptionCheckbox extends Component{
    constructor(props){
      super(props);
    }
    
    render(){
        return(
            <>
 <div className="res-menupage">
      <div className='upload-link'>
        <div className='displayFlex page-header mb-2'>
            {this.props.headerName}
        </div>
      </div>
      <div className="uploadMenuOption">
        <div className="uploadRoot">
            <label style={{width:'210px'}}>{this.props.labelNameRA}</label>           
            <Checkbox
              checked={this.props.propsCheckedRA}
              style={{ cursor: 'pointer' }}
              className='compare_checkbox'
              onClick={()=>this.props.toggleCheckBoxRA()}
            />  
        </div>

        <div className="uploadRoot" style={{marginTop:'20px'}}>
            <label style={{width:'210px'}}>{this.props.labelNameCTB}</label>           
            <Checkbox
              checked={this.props.propsCheckedCTB}
              style={{ cursor: 'pointer' }}
              className='compare_checkbox'
              onClick={()=>this.props.toggleCheckBoxCTB()}
            />
        </div>
        <div className="uploadRoot" style={{marginTop:'20px'}}>
            <label style={{width:'210px'}}>{this.props.labelNameCOC}</label>           
            <Checkbox
              checked={this.props.propsCheckedCOC}
              style={{ cursor: 'pointer' }}
              className='compare_checkbox'
              onClick={()=>this.props.toggleCheckBoxCOC()}
            />  
          <div className="buttonMenuOption">
              {getButton({type:'button', className:'fluid ui red button submit', name:'retailAssortmentsubmit', onClick:this.props.submitButton}, 'Submit')}
            </div>  
        </div>
      </div>
    </div>
            </>
        );
    }
}

export default MenuOptionCheckbox;