import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Loader, Confirm, Pagination, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './AddressBook.css';
import search_img from '../images/Search.svg';
import GenericExcelExport from '../common/GenericExcelExport';
import renderImages from '../common/RenderImages';
import { searchAddress, updateAddressList, deleteAddressList } from './actions';
import { updateShipTo } from '../Cart/actions';
import { ADDRESS_BOOK_API, ADDRESS_BOOK_UPLOAD_API } from '../../urlConstants';
import { exportColumnDefs } from './AddressBookModels';
import { utilities } from '../../utils/utilities';
import restUtils from '../../utils/restUtils';
//import { Event } from '../../history';

const MAIN = 'main';

class AddressBook extends Component {
  constructor(props) {
    super(props);
   // Event('ADDRESSBOOK','Viewing Address Book');
    this.state = {
      fileName: '',
      view: false,
      addressList: [],
      searchBy: this.props.location && this.props.location.state 
      && this.props.location.state.searchValueEdit
      ? this.props.location.state.searchValueEdit :'',
      isLoading: true,
      deletionConfirmOpen: false,
      totalItems: 0,
      firstItem: 0,
      lastItem: 0,
      currentPage: 1,
      totalPages: 0
    };
    this.pageSize = 50;
    this.page = 1;
  }

  componentDidMount() {
    const { addressList, editAction, lastItem, totalPages, totalItems,
            firstItem, currentPage,successRecord,successRecordEdit,successUpload} = this.props.location.state || {};
    this.page = 1;
    this.pageSize =
      this.props.getprefer && this.props.getprefer.resultPerPage
        ? this.props.getprefer.resultPerPage
        : '50';
    if(this.state.searchBy){
      this.findAddress();
    }
    if (editAction !== 'cancelled') {
      this.onSearchAddress();
      if(successUpload){
        utilities.showToast('Successfully saved ' +successRecord + ' record(s)' );
        this.props.history.push({
          state: {
            ...(this.props.location.state || {}),
            successUpload: false,
          }
        });
      }
      if(successRecordEdit){
        utilities.showToast('Successfully saved ' + 1 + ' record(s)' );
        this.props.history.push({
          state: {
            ...(this.props.location.state || {}),
            successRecordEdit: false,
          }
        });
      }
    } else {
      this.setState({
        addressList,
        isLoading: false,
        lastItem,
        totalPages, 
        totalItems,
        firstItem, 
        currentPage
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.location.search && prevProps.location.search) {
      this.setState({
          searchBy: ''
        },() => {
          this.findAddress();
        }
      );
    }
  }

  onSearchAddress = () => {
    const { storeId, searchAddress } = this.props;
    const {
      searchBy = '',
      sortParam = '',
      sortType = 'asc'
    } = this.state;
    const pageNumber = this.page;
    const resultPerPage = this.pageSize;
    searchAddress({
      storeId,
      searchBy,
      pageNumber,
      sortParam,
      sortType,
      resultPerPage
    });
  };

  onShowQuantityChange = e => {
    this.setState({
      isLoading : true
    });
    this.pageSize = e.target.value;
    this.page = 1;
    this.onSearchAddress();
  }

  onPageChange = (e, { activePage }) => {
    this.setState({
      isLoading : true,
      currentPage: activePage
    });
    let queryPage = e.currentTarget.text;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage === this.state.totalPages - 1
          ? this.state.currentPage
          : parseInt(this.state.currentPage) + 1;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.page = queryPage;
    this.onSearchAddress();
  }

  findAddress = () => { 
    this.page=1;
    this.setState({
      isLoading : true
    });
    this.onSearchAddress();
    this.setState(  
      () => {        
        const { searchBy } = this.state;
        if (searchBy) {
          this.props.history.push({
            pathname: 'Addressbook',
            search: `?query=${searchBy}`,
            state: {
              ...(this.props.location.state || {})
            }
          });
        }
        else{
          this.props.history.push({
            pathname: 'Addressbook',
            search: '',
            state: {
              ...(this.props.location.state || {})
            }
          });
        }
      }
    );
  };

  handleSearch = event => {
    const { charCode } = event;
    if (charCode === 13) {
      this.findAddress();
    }
  };

  componentWillReceiveProps(nextProps) {
    const { isLoading, addressList, isLoadingAddressFailed, 
      lastItem, totalPages, totalItems,
      firstItem, currentPage } = nextProps;
    if (!isLoadingAddressFailed) {
      this.setState({
        isLoading,
        addressList: addressList,
        lastItem,
        totalPages, 
        totalItems,
        firstItem, 
        currentPage
      });
    } else {
      const cartName = _.get(this.props, 'location.state.cartName', null);
      if (cartName) {
        this.props.history.push({
          pathname: `/checkout/${cartName}`,
          cart: this.props.location.state.cart,
          state: {
            isLoadingAddressFailed
          }
        });
      }
    }
  }

  handleFieldChange = event => {
    const { dataset, value } = event.target;
    const { field } = dataset;
    this.setState({
      [field]: value
    });
  };

  deleteAddresses = () => {
    let { addressList = [], deleteAddressList, storeId } = this.props;
    const addressesTobeDeleted = [];
    addressList.forEach(address => {
      const { isChecked } = address;
      if (isChecked) {
        addressesTobeDeleted.push(address.locationId);
      }
    });

    const requestData = {
      storeId,
      locationIdList: addressesTobeDeleted,
      sortParam: 'consumerName',
      sortType: 'asc',
      pageNumber: 1
    };

    this.setState({
        isLoading: true,
        deletionConfirmOpen: false
      },
      () => {
        deleteAddressList(requestData, this.pageSize, this.state.searchBy);
      }
    );
  };

  routeChange = (e, value) => {
    let path = './AddressViewEdit';
    const { dataset } = e.target;
    const { addressAction } = dataset;
    const { addressList, lastItem, totalPages, totalItems,
      firstItem, currentPage } = this.state;
    const addressData =
      addressAction === 'PostAddNewAddress'
        ? {
            contactName: '',
            customerName: '',
            customerNbr: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip5: '',
            zip4: '',
            phoneArea: '',
            phoneExchange: '',
            phoneLine: '',
            faxArea: '',
            faxExchange: '',
            faxLine: '',
            email: ''
          }
        : value;
    this.props.history.push({
      pathname: path,
      state: {
        ...(this.props.location.state || {}),
        ...addressData,
        addressList,
        addressAction,
        lastItem, 
        totalPages, 
        totalItems,
        firstItem, 
        currentPage,
        searchVal:this.props.location && this.props.location.search
      }
    });
  };

  togglechange = () => {
    this.setState({ view: true });
  };

  handleChangeFile = e => {
    this.setState({ fileName: e.target.value });
  };

  addNew = address => {
    this.setState(prevState => {
      return {
        ...prevState,
        addressList: { ...prevState.addressList, address }
      };
    });
  };

  handleAddressCheck = index => {
    const { addressList, updateAddressList } = this.props;
    const { addressList: stateAddressList } = this.state;
    const addressSelected = stateAddressList[index];
    addressSelected.isChecked = !addressSelected.isChecked;
    const updatedAddressList = addressList.map(address => {
      const { locationId } = address;
      if (locationId === addressSelected.locationId) {
        address.isChecked = addressSelected.isChecked;
      }
      return address;
    });
    updateAddressList([...updatedAddressList]);
  };

  shipToSelect = chosenAddress => {
    const {
      contactName,
      customerName,
      customerNbr,
      addressLine1,
      addressLine2,
      state,
      city,
      zip5,
      zip4,
      locationId
    } = chosenAddress;

    const address = {
      name: contactName,
      customerName: customerName,
      streetAddress: addressLine1,
      addressLine2,
      state,
      city,
      zipCode: `${zip5}-${zip4}`,
      locationId,
      customerNbr: customerNbr
    };
    this.props.updateShipTo(this.props.location.state.cartId, { ...address });
    const selectedGroup = this.props.location.state.group;
    const arrayIndex = this.props.location.state.index;
    let itemGrouping = this.props.location.state.itemGrouping;
    itemGrouping[arrayIndex][selectedGroup] = {...itemGrouping[arrayIndex][selectedGroup]
                                    ,customerAddress:address,
                                    shipTo:'Customer'}
    this.props.history.push({
      pathname: `/checkout/${this.props.location.state.cartName}`,
      cart: this.props.location.state.cart,
      state: {
        cartProducts: this.props.location.state.cartProducts,
        itemGrouping,
        selectedGroup,
        poNum: this.props.location.state.poNum,
        Title: this.props.location.state.Title,
        groupVal:this.props.location.state.groupVal,
        indexselected:arrayIndex,
        isAddressSelect:true,
        customerAddress: { ...address },
        orderFromRetrofittedRDCs: this.props.location.state
          .orderFromRetrofittedRDCs,
        isMultiStore:this.props.location.state.isMultiStore,
        multiStoreCarts:this.props.location.state.multiStoreCarts
      }
    });
  };

  openConfirmation = event => {
    event.preventDefault();
    const { addressList } = this.props;
    const selectedAddress = addressList.filter(address => address.isChecked);
    if (selectedAddress && selectedAddress.length) {
      this.setState({
        deletionConfirmOpen: true,
        noOfAddressToDelete: selectedAddress.length
      });
    } else {
      utilities.showToast('Please select some addresses', true);
    }
  };

  closeConfirmation = () => {
    this.setState({
      deletionConfirmOpen: false
    });
  };

  onExportComplete = ({ payload, status }) => {
    if (status === 'Error') {
      utilities.showToast('Something went wrong. Please try again.', true);
    }
  };

  uploadFile = e => {
    const file = e.target.files[0] || {};
    const { name } = file;
    if (name) {
      const presignedUrlRequested = this.getpresignedUrl();
      const nameFragment = name.split('.');
      const extention = nameFragment[nameFragment.length-1];
      if (extention === 'xlsx') {
        this.setState({
          fileName: name,
          isLoading: true
        });
        presignedUrlRequested
          .then(res => {
            const { presignedUrl, keyName } = res.data;
            restUtils.putData(presignedUrl, file).then(() => {
              this.processFile(keyName);
            });
          })
          .catch(error => {
            console.error(error);
            this.setState({
              isLoading : false
            });
          });
      } else {
        utilities.showToast( 'Wrong file format. Only .xlsx is allowed',true);
        return;
      }
    }
  };

  processFile = s3keyName => {
    const { storeId, userName } = this.props;
    const requestUrl = `${ADDRESS_BOOK_UPLOAD_API}/PostProcessAddressBookFile`;
    const requestData = {
      storeId,
      molUserName: userName,
      s3keyName
    };
    restUtils.postData(requestUrl, requestData).then(res => {
      const { addressList } = res.data;
      if(addressList.length > 0){
        this.props.history.push({
          pathname: `/AddressBookUpload`,
          state: {
            addressList: addressList
          }
        })
      }else{
        this.setState({
          isLoading: false
        })
        utilities.showToast('Please upload a file with some addresses', true);
      }
    })
    .catch((error) => {
      console.error(error);
      this.setState({
        isLoading: false
      })
    })
  }

  getpresignedUrl = () => {
    const { storeId, userName } = this.props;
    const requestUrl = `${ADDRESS_BOOK_UPLOAD_API}/PostGeneratePresignedUrl`;
    const requestData = {
      storeId,
      molUserName: userName
    };
    return restUtils.postData(requestUrl, requestData);
  };

  downloadTemplate = () => {
    const requestUrl = `${ADDRESS_BOOK_UPLOAD_API}/PostDownloadAddressBookExcelTemplate`;
    const { storeId, userName } = this.props;
    restUtils
      .postData(requestUrl, {
        storeId,
        molUserName: userName
      })
      .then(res => {
        const { presignedUrl } = res.data;
        window.open(presignedUrl, '_blank');
      })
      .catch(err => {
        console.log(err)
      })
  };

  render() {
    const {
      searchBy = '',
      isLoading,
      sortParam = '',
      sortType = 'asc',
      deletionConfirmOpen,
      noOfAddressToDelete,
      addressList = [],
      totalPages,
      totalItems,
      firstItem,
      lastItem,
      currentPage
    } = this.state;

    const { storeId } = this.props;
    const exportUrl = `${ADDRESS_BOOK_API}/GetAddressesByStoreId?storeId=${storeId}&searchBy=${searchBy}&sortParam=${sortParam}&sortType=${sortType}&resultsperpage=${totalItems}`;
    const isCheckout =
      this.props.location.state && this.props.location.state.isCheckout;
    const sources =
      this.props.location.state && this.props.location.state.source;
    return (
      <div className='address-book page-wrap'>
        <div className='segment_address'>
          <div className='page-header displayInline'>Address Book</div>
        </div>
        <Grid stackable id='address_search'>
          <Grid.Column className='FindAddress'>
            <span>Find An Address</span>
            <div className='ui icon input' id='search_div_address'>
              <input
                type='text'
                className='search-box_address'
                value={searchBy}
                data-field='searchBy'
                onKeyPress={this.handleSearch}
                onChange={this.handleFieldChange}
              />
              <span onClick={this.findAddress} className='search_icon_address'>
                {renderImages.getImage({
                  src: search_img
                })}
              </span>
            </div>
          </Grid.Column>
          <Grid.Column className='addnewSegment'>
            <span> (or) </span>
            <div className='ui input add-new'>
              <input
                type='button'
                onClick={this.routeChange}
                name='Add-file'
                data-address-action='PostAddNewAddress'
                id='Add-file'
                className='custom-file-input'
              />
              <label
                htmlFor='Add-file'
                className='custom-input-btn'
                id='addNewBtn'
              >
                Add New
              </label>
            </div>
          </Grid.Column>
          <Grid.Column className='bulkuploadSegment'>
            <div className='bulkupload'><span>(or)</span>Bulk Upload</div>
            <div className='file_div'>
              <input
                type='file'
                name='upload-file'
                id='upload-file'
                onChange={this.uploadFile}
                className='custom-file-input'
              />
              <input
                type='text'
                className='choose-file-label'
                value={this.state.fileName}
              ></input>
              <label htmlFor='upload-file' className='custom-input-btn'>
                Choose File
              </label>
            </div>
            <div className='download_template'>
              <div className='op-clear-label' onClick={this.downloadTemplate}>Download Template</div>
            </div>
          </Grid.Column>
          <Grid.Column className='exportSegment' floated='right'>
            <div className='displayFlex export_div'>
              <GenericExcelExport
                url={exportUrl}
                columnDefs={exportColumnDefs}
                onExportComplete={this.onExportComplete}
              />
            </div>
          </Grid.Column>
        </Grid>
        <Grid id='pagination'>
          <Grid.Column computer={4} mobile={12} tablet={12}>
            {totalPages > 0 && (
              <Pagination
                className='pagination paginationMobile'
                boundaryRange={0}
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={this.onPageChange}
                ellipsisItem={null}
                siblingRange={1}
                firstItem={parseInt(currentPage) === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                lastItem={parseInt(currentPage) === parseInt(totalPages) ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                prevItem={parseInt(currentPage) === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                nextItem={parseInt(currentPage) === parseInt(totalPages) ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
              />
            )}
          </Grid.Column>
          <Grid.Column computer={4} mobile={12} tablet={12}>
            <p className='address_found'>
            {firstItem}-{lastItem} of{' '}
                {totalItems} addresses found
            </p>
          </Grid.Column>
          <Grid.Column computer={4} mobile={12} tablet={12} id='limit-dropdown'>
            <span className='page-dd-title'>Show</span>
            <select
              value={this.pageSize}
              className='select-range'
              onChange={this.onShowQuantityChange}
            >
              <option className='SelectOption' value={50}>
                50
              </option>
              <option className='SelectOption' value={100}>
                100
              </option>
            </select>
          </Grid.Column>
          <Grid.Column
            computer={4}
            mobile={8}
            tablet={4}
            className='delete-menu'
          >
            <Link
              to='#'
              className='delete_selected'
              onClick={this.openConfirmation}
            >
              <span>Delete selected</span>
            </Link>
          </Grid.Column>
        </Grid>

        {isLoading ? <Loader active /> : null}
        <div className='sixteen wide column AddressTableSegment'>
          <table className='stackable' id='Address_table'>
            <thead className='AddressTableHead'>
              <tr>
                <th rowSpan='1'>Name</th>
                <th rowSpan='1'>Attention To</th>
                <th rowSpan='1'>Address</th>
                {isCheckout && <th></th>}
                <th rowSpan='1'></th>
                <th rowSpan='1'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {addressList.map((address, index) => {
                return (
                  <Address
                    key={index}
                    {...address}
                    index={index}
                    checkoutData={isCheckout}
                    shipToSelect={this.shipToSelect}
                    routeChange={this.routeChange}
                    handleAddressCheck={this.handleAddressCheck}
                    sources={sources}
                  />
                );
              })}
            </tbody>
          </table>
          <Confirm
            className='cart-confirm'
            open={deletionConfirmOpen}
            onCancel={this.closeConfirmation}
            header='Delete Address'
            content={`Are you sure you want to delete (${noOfAddressToDelete}) addresses?`}
            onConfirm={this.deleteAddresses}
          />
        </div>
        <div id='bottom-pagination'>
          <Grid>
            <Grid.Column computer={4} mobile={12} tablet={12}>
            {totalPages > 0 && (
              <Pagination
                className='pagination paginationMobile'
                boundaryRange={0}
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={this.onPageChange}
                ellipsisItem={null}
                siblingRange={1}
                firstItem={parseInt(currentPage) === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                lastItem={parseInt(currentPage) === parseInt(totalPages) ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                prevItem={parseInt(currentPage) === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                nextItem={parseInt(currentPage) === parseInt(totalPages) ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
              />
            )}
            </Grid.Column>
            <Grid.Column computer={4} mobile={8} tablet={4}>
              <p className='address_found'>
              {firstItem}-{lastItem} of{' '}
                {totalItems} addresses found
              </p>
            </Grid.Column>
            <Grid.Column
              computer={8}
              mobile={8}
              tablet={8}
              className='delete-menu'
            >
              <Link
                to='#'
                className='delete_selected'
                onClick={this.openConfirmation}
              >
                <span>Delete selected</span>
              </Link>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { SessionReducer, AddressBookReducer = {} } = state;
  const { storeId, userName } = SessionReducer;
  const {
    addressList,
    isLoading,
    lastItem, 
    totalPages,
    totalItems,
    firstItem, 
    currentPage,
    isLoadingAddressFailed = false
  } = AddressBookReducer;

  return {
    storeId,
    userName,
    addressList,
    isLoading,
    lastItem, 
    totalPages,
    totalItems,
    firstItem, 
    currentPage,
    isLoadingAddressFailed,
    getprefer: state.preference.listData
  };
};

const mapDispatchToProps = {
  searchAddress,
  updateAddressList,
  deleteAddressList,
  updateShipTo
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressBook);

export const Address = props => {
  const {
    shipToSelect,
    routeChange,
    handleAddressCheck,
    sources,
    index,
    checkoutData,
    ...addressData
  } = props;

  const {
    contactName,
    customerName,
    addressLine1,
    city,
    state,
    zip5,
    isChecked = false
  } = addressData;

  const addressString = `${addressLine1}${city ? ', ' + city : ''}${
    state ? ', ' + state : ''
  }${zip5 ? ', ' + zip5 : ''}`;

  return (
    <tr>
      <td rowSpan='1' data-label='Name' width='25%'>
        {contactName}
      </td>
      <td rowSpan='1' data-label='Name' width='25%'>
        {customerName}
      </td>
      <td rowSpan='1' data-label='Address'>
        {addressString}
      </td>
      {(checkoutData && props.source === MAIN && sources === MAIN) ? (
        <td>
          <span
            className='select-address'
            onClick={() => shipToSelect(addressData)}
          >
            Select
          </span>
        </td>
      ) : checkoutData && props.source === 'temp' ? (
        <td>
          <span className='select-address' id='disabled'>
            Select
          </span>
        </td>
      ) : (
        ''
      )}
      <td>
        <div className='view_edit_place'>
          <span
            className='View_address'
            data-address-action='PostUpdateAddress'
            onClick={e => routeChange(e, addressData)}
          >
            View / Edit
          </span>
        </div>
      </td>
      <td>
        <div id='delete_address_check'>
          <label className='deletelabel'></label>
          <input
            type='checkbox'
            onChange={() => handleAddressCheck(index)}
            checked={isChecked}
          />
        </div>
      </td>
    </tr>
  );
};
