import React from 'react'
import { Button, Loader, Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux'
import InternalServerError from '../../ErrorPages/500_error_page';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import utilities from '../../../utils/utilities';
import {fetchDiscountCityProductsList, searchDiscountCityItems, getDicountCityFilterString} from './DiscountCityHandler';
import { showItemCount, getSortByDirection, getPagination } from '../../common/CustomComponents';
import { getSelect } from '../../common/ComponentUtilities';
import Filter from '../../common/Filter';
import _ from 'lodash';
import DcProductListingGrid from './DcProductListingGrid';
import DcProductListing from './DcProductListing';
import { handleChange, addMonthlySpecialToCart, validateProductQuantity } from '../../common/productHandler';
import { DISCOUNT_CITY } from '../../Categories/constants';
import ExcelExport from '../../common/ExcelExport';
import { isArray } from 'util';
import {addMonthlySpecialCart} from '../../Cart/actions'
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

 class DiscountCityItemListing extends React.Component{
    constructor(props){
        super(props);
        const eventId = props?.match?.params ? props.match.params.eventId : '';
        const event = props?.reunionEvents?.find(event=>{
            return parseInt(event.promo_nbr)===parseInt(eventId);
        })
        const{isMSSearch,isDiscountCity, isAllItems,vendorDetailsOffer,searchby,listType,searchvalue,pathname}= props.location && props.location.state
        this.state={
            isMSSearch,
            isAllItems,
            dealDetails:false,
            eventId,
            isDiscountCity,
            vendorDetails:vendorDetailsOffer,
            searchby:searchby,
            listType:listType,
            eventIdReunion: event?event.relay_nbr:'',
            promoNmbr: event?event.promo_nbr:'',
            promoName: event?event.PrmoName:'',
            searchvalue:searchvalue,
            serverError: false,
            pathname,
            categoryData:[],
            loading: false,
            isNoProducts: false,
            totalItems :0,
            firstItem:0,
            lastItem:0,
            totalPages:0,
            currentPage:0,
            filters : [],
            quantities :[],
            overrideRetails :[],
            clearFilters:false,
            appliedFilters: {},
            img_txt_to_display: utilities.getShowImg(this.props.getprefer),
            showFilters: utilities.getShowFilters(this.props.getprefer),
            viewType: utilities.getViewType(this.props.getprefer)
        }
        this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage
                        ? this.props.getprefer.resultPerPage
                        : '50';
        this.seachMonthlyItems = searchDiscountCityItems.bind(this);                        
        this.fetchDiscountCityProductsList = fetchDiscountCityProductsList.bind(this);
        this.handleChange = handleChange.bind(this);
        this.addMonthlySpecialToCart = addMonthlySpecialToCart.bind(this);
        this.validateProductQuantity = validateProductQuantity.bind(this);
        this.getDicountCityFilterString = getDicountCityFilterString.bind(this);
        this.pathname = '';
    }
    componentDidMount(){
        let queryParam = window.location.href.split('?');
        this.pathname = this.props.location?.pathname;
        this.fetchItems();
    }
    fetchItems = ()=>{
        if(this.state.isMSSearch){
            this.searchDiscountCityItems();
        }else{
            this.fetchDiscountCityProductsList();
        }
    }
    changePageSize = e => {
        this.pageSize = e.currentTarget.value;
        this.page = '1';
        this.scrollPosition = window.pageYOffset;
        this.fetchItems();
    };
    showDealDetails = () => this.setState({ dealDetails: true});
    
    handleSort = (e) => {
        this.setState({ viewType: e });
        return this.e;
    }
    setScrollPosition = () => {
        this.scrollPosition = window.pageYOffset;
        this.setBackButtonPath();
    }
    setBackButtonPath = () => {
      let pathName = '';
        if (this.sortParam && this.sortParam.length > 0) {
          pathName = pathName + '&SortParam=' + this.sortParam;
          if (this.sortDirection && this.sortDirection.length > 0) {
            pathName = pathName + '&SortType=' + this.sortDirection;
          } else {
            pathName = pathName + '&SortType=asc';
          }
        }
        pathName = this.scrollPosition > 0 ? pathName + '&scrollPosition=' + this.scrollPosition : pathName;
        if (this.filterString && this.filterString !== '') {
          pathName = pathName + '&filterString=' + utilities.getQueryStrForFilterString(this.appliedFilters);
        }
      if (pathName !== '') {
         let pushUrl = this.pathname + '?' + pathName;
        window.history.pushState(window.history.state, "", pushUrl);
      }
    }
  
    getListing = ()=>{
        const {viewType, img_txt_to_display, categoryData, overrideRetails, quantities} = this.state;
        const img_style = img_txt_to_display === 'Hide Images' ? 'block' : 'none';
        return (
            <>
            {
                viewType === 'list' && window.innerWidth >= 1024 ? (
                    <DcProductListing
                        access={this.props.multiStoreDetails.access}
                        img_style={img_style}
                        tableType={DISCOUNT_CITY}
                        viewType={this.props.viewType}
                        blurfun={this.blurfun}
                        categoryData={categoryData}
                        quantities={quantities}
                        overrideRetails={overrideRetails}
                        handleChange={this.handleChange}
                        handleChangeblur={this.handleChangeblur}
                        addToCart={this.addMonthlySpecialToCart}
                        validateProductQuantity={this.validateProductQuantity}
                        storeId={this.props.storeId}
                        setBackButtonPath={this.setScrollPosition} 
                    />
                ) : (
                    <DcProductListingGrid
                        access={this.props.multiStoreDetails.access}
                        img_style={img_style}
                        tableType={DISCOUNT_CITY}
                        viewType={this.props.viewType}
                        blurfun={this.blurfun}
                        categoryData={categoryData}
                        quantities={quantities}
                        overrideRetails={overrideRetails}
                        handleChange={this.handleChange}
                        handleChangeblur={this.handleChangeblur}
                        addToCart={this.addMonthlySpecialToCart}
                        validateProductQuantity={this.validateProductQuantity}
                        storeId={this.props.storeId}
                        setBackButtonPath={this.setScrollPosition}
                    />
                )
            }
            </>
        );
    }
    onPageChange = e => {
        let queryPage = e.currentTarget.text;
        if (e.currentTarget.text === 'Next') {
          queryPage =
            this.state.currentPage < this.state.totalPages
              ? this.state.currentPage + 1
              : this.state.currentPage;
        }
        if (e.currentTarget.text === 'Prev') {
          queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
        }
        if (e.currentTarget.text === '»') {
          queryPage = this.state.totalPages;
        }
        if (e.currentTarget.text === '«') {
          queryPage = 1;
        }
        this.scrollPosition = window.pageYOffset;
        this.page = this.state.isCloseouts ? queryPage : '&Page=' + queryPage;
        window.scrollTo(0, 0);
        this.fetchItems();
    }
    
  showHideImages = evt => {
    let img_txt_to_display =
      evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

    getListingHeader = () =>{
        return (
            <div className='listing-header'>
                <span>
                    {this.state.firstItem}-{this.state.lastItem} of {' '}
                    {this.state.totalItems} products
                </span>
                <span
                    onClick={this.showHideImages}
                    id='show_and_hide_images'
                >
                    {this.state.img_txt_to_display}
                </span>
                <span className='pagination_top'>
                    {this.state.totalPages > 0 && 
                        getPagination(0,this.state.currentPage,this.state.totalPages,this.onPageChange,null,1)
                    }
                </span>
                <ExcelExport
                    className='exportPDF exportPdfPLP'
                    itemsData={this.state.categoryData}
                    fileName={ DISCOUNT_CITY}
                    totalItems={this.state.totalItems}
                    viewType={this.props.viewType}
                    getprefer={
                        this.props.getprefer &&
                        this.props.getprefer.customerView
                        ? this.props.getprefer.customerView
                        : 'c1'
                    }
                />
            </div>
        )
    }
    changeSortParam = (e) =>{
        this.sortParam = e.currentTarget.value;
        this.fetchItems();
    }
    getSortByFields = () => {
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown'};
                    
        const options = [
            {props:{value:'Relevance',id:'Relevance', className:'SelectOption'},text:'Relevance'},
            {props:{value:'item_nbr', id: 'item_nbr', className:'SelectOption'},text:'Item #'}           
        ];
    
        {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) && 
        options.push({props:{value:'alternateSKU', id: 'alternateSKU', className:'SelectOption'},text:'Alternate SKU#'})}

        options.push( {props:{value:'model', id: 'model', className:'SelectOption'}, text:'Model #'},
        {props:{value:'Description', id: 'Description', className:'SelectOption'}, text: 'Description'},
        {props:{value:'VendorName', id: 'VendorName', className:'SelectOption'}, text:'Vendor Name'})
    
        return(      
            getSelect(selectProps, options)
        );
    }

    changeSortDirection = (e) =>{
        this.sortDirection = e.currentTarget.value;
        this.fetchItems();
    }
    DialogClose = () => this.setState({ dealDetails: false });
    optionsSection=()=>{
        const sortBy = this.getSortByFields();
        return (
            
            <div className='options-div'>
                <span className='dc_pallet_search'> Pallet Search</span>
              <select
                id='dom-search-by'
                //onChange={this.changeSearchParam}
                className='input-select'
                //value={this.state.domSearchBy}
              >
                <option value='itemnbr' className='SelectOption'>
                  Item#
                </option>
                {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) && ( <option
                  value={'alternateSKU'}
                  className='SelectOption'>
                  Alternate SKU#
                </option>)}
                <option value='model' className='SelectOption'>
                  Model#
                </option>
                <option value='description' className='SelectOption'>
                  Description
                </option>
              </select>
              <div
                id='dom-search'
                //onChange={this.changeDomSearchValue}
                //onKeyDown={this.handleEnterKey}
              >
                <input type='text'
                  // onKeyDown={this.handleEnterKeys} 
                  //onChange = {this.onFilterChange} 
                  //value={this.state.domSearchValue}  
                  //pattern={this.patterns[this.state.domSearchBy]}
                  />
                {renderImages.getImage({
                  src: search_img,
                  //onClick: this.searchDom,
                  style: { cursor: 'pointer' }
                })}
              </div>
                <div className='right-justified'>
                    <span>Show </span>
                    {
                        showItemCount(this.state.pageSize,this.changePageSize)
                    }
                </div>
                <div className='sortByCategories'>
                    <span>Sort By</span>
                    {   sortBy } 
                    {
                        getSortByDirection(this.changeSortDirection,['A','D'])
                    }
                </div>
                
                <Responsive minWidth={1024}>
                    <div id='icon_align'>
                        <i
                        className='list icon cursor_p'
                        value='List'
                        id='list_icon'
                        onClick={() => this.handleSort('list')}
                        ></i>{' '}
                        |
                        <i
                        className='th icon cursor_p'
                        value='Grid'
                        id='grid_icon'
                        onClick={() => this.handleSort('grid')}
                        ></i>
                    </div>
                </Responsive>
            </div>
        )
    }

    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };
    filterCallback = (isAdd, filterKey, filterValue) =>{
        let {appliedFilters, vendorDetails} = this.state;
        this.scrollPosition = window.pageYOffset;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
          appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
        } else if (keyExists) {
          appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
            ? appliedFilters[filterKey]
            : [appliedFilters[filterKey]];
    
          let value = appliedFilters[filterKey];
          let index = value.indexOf(filterValue);
          if (isArray(value) && index > -1) {
            value.splice(index, 1);   // to handle value Array with filter value pre-existing
          } else if (index > -1) {
            value = []         // to handle single string Value 
          } else {
            value.push(filterValue);
          }
          if (!value.length && keyExists) {
            delete appliedFilters[filterKey];
          } else {
            appliedFilters[filterKey] =  value;
          }
        }
        this.filterString = this.getDicountCityFilterString(
              appliedFilters,
              vendorDetails ? vendorDetails.vendorName : null
            );
        this.setState({
          currentPage: 1,
          appliedFilters,
          clearFilters: false
        });
        this.appliedFilters = appliedFilters;
        this.fetchDiscountCityProductsList();
    }
    render() {
        const{isMSSearch,isAllItems, vendorDetails, clearFilters, isNoProducts, appliedFilters, filters, showFilters, isDiscountCity, eventId, searchby,listType,searchvalue,eventIdReunion,promoNmbr,promoName}=this.state;
        let header = 'Discount City';
        header = isAllItems?
                    header
                    :header+"- #"+utilities.emptyForNull(vendorDetails.relayNbr)+" - "+utilities.emptyForNull(vendorDetails.promoName);
        let breadcrumbPath = [
            { link: '/home', text: 'HOME' },
            { link: '/discountCity', text: header}
        ];
        breadcrumbPath=[ 
            ...breadcrumbPath,
            {
                link:`/discountCityEventVendors/${eventId}`,
                text: promoNmbr + '-' + header
            },
            ...isAllItems?[{link:this.props.location.pathname,text:'ALL ITEMS'}]:[],
        ];
        
        return (
            
            this.state.serverError ?
                <InternalServerError />
                :
              <div className="page-wrap">
                   {this.state.loading && (
                        <div>
                            <Loader active />
                        </div>
                    )}
                <div className='page-header'>
                    {header}
                </div>
                <BreadcrumbComp path={breadcrumbPath}/>
                    {this.optionsSection()}
                    <div className='displayFlex allOrdersTable'>
                        {!isMSSearch &&
                            <div id='filter_width_ipad'>
                                <Filter
                                    clearFilters={clearFilters}
                                    filtersApplied={!_.isEmpty(appliedFilters)}
                                    appliedFilters={appliedFilters}
                                    toggleShowFilter={this.toggleShowFilter}
                                    showFilters={showFilters}
                                    filters={filters}
                                    callback={this.filterCallback}
                                    isNoProducts={isNoProducts}
                                    selectedVendor={vendorDetails && vendorDetails.vendorName}
                                    listType={6}
                                    
                                />
                            </div>
                        }
                        <div id='grid_width' className='full-width pl_data'>
                            {this.getListingHeader()}
                            {isNoProducts ? (
                                <div className='no_prdcts_display'>
                                <h1>No Products to Display!</h1>
                                </div>
                                ) : (
                                    <>
                                        {this.getListing()}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
        )
    }
 }

const mapStateToProps = (state) =>{
  const viewTypeStorage = localStorage.getItem('viewType');
    return {
        userId: state.SessionReducer.UserId,
        userName: state.SessionReducer.address.userName,
        storeId: state.SessionReducer.storeId,
        reunionEvents : state.ReunionReducer.events,
        favorites: state.FavoritesReducer.favItemNums,
        getprefer: state.preference.listData,
        multiStoreDetails: state.SessionReducer.multiStoreDetails,
        viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
        rdc: state.SessionReducer.rdc,
        aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
    }
}
export default connect(mapStateToProps,{addMonthlySpecialCart})(DiscountCityItemListing);