import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

class BreadcrumbComp extends React.Component {
  buildBreadcrumb = () => {
    const pathLength = this.props?.path?.length;
    return pathLength > 0
      ? this.props.path?.map((item, index) => {
          const isLastItem = index === pathLength - 1;
          const lastTwoItem = index === pathLength - 2 || index === pathLength - 1;
          const divider =
            !lastTwoItem? (
              <div className='divider'>
                <i className='chevron right icon'></i>
              </div>
            ) : null;

          return (
            <Fragment key={item.text}>
              <div className='section caps'>
                {
                  !isLastItem &&
                    <Link to={item.link} onClick={item.onClick}>{decodeURIComponent(item.text)}</Link>
                }
              </div>
              {divider}
            </Fragment>
          );
        })
      : null;
  };

  render() {
    const breadcrumb = this.buildBreadcrumb();
    return <div className='ui small breadcrumb'>{breadcrumb}</div>;
  }
}

export default BreadcrumbComp;
