import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, ItemMeta, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import renderImages from '../../common/RenderImages';
import { MaskPrice } from '../../common/MaskPrice';
import { TierIcon } from '../../common/TierIcon';
import utilities from '../../../utils/utilities';
import { closeModal, addimg, delimg } from '../../common/productHandler';
import { addProduct, createNAddCart, orderTypes } from '../../Cart/actions';
import { orderNames } from '../../Cart/constants';
import AddToCartModal from '../../Cart/AddToCartModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../../history';
import classnames from 'classnames';
import { DISCOUNT_CITY } from '../../Categories/constants';
import SpecTable from './SpecTable';

class DcProductListingGrid extends Component {
    constructor(props) {
        super(props);
        this.closeModal = closeModal.bind(this);
        this.addimg = addimg.bind(this);
        this.delimg = delimg.bind(this);

        this.state = {
            visible: false,
            showSpec: '',
            showToggle: false,
            item: '',
            click: '',
            productSelectedForModal: null,
            indexForModal: 0,
            isAddToCartModalOpen: false,
            toastMessage: ''
        };
    }

    openCartModal = (product, index) => {
        this.setState({
            productSelectedForModal: product,
            indexForModal: index,
            isAddToCartModalOpen: true
        });
    };
    closeCartModal = () => {
        this.setState({ isAddToCartModalOpen: false });
    };

    openModal(data) {
        this.setState({
            visible: true,
            item: data,
            click: 'clicked'
        });
        this.props.blurfun(true);
    }

    toggleDiv = divId => {
        this.setState(prevState => {
            return { [divId]: !prevState[divId] };
        });
    };

    handleAddToCart = (product, index) => {
        const { tableType, addToCart, isShowAllWarehouse, storeId } = this.props;
        const qty = this.props.quantities[index];
        const or = this.props.overrideRetails[index];
        const rdcNum = utilities.rdcBasedonCentralship(product.rdctype, product.CsRDC, this.props.rdc);
        if (isShowAllWarehouse && qty > product.Available) {
            history.push({
                pathname: `/all-warehouses/${product.Item_Number}`,
                state: { fromPLP: true }
            });
            return;
        }
        if (
            !this.props.validateProductQuantity(
                product.S_Pack_Type,
                product.S_Pack_Qty,
                this.props.quantities[index]
            )
        ) {
            return;
        }
        addToCart(
            product.Item_Number,
            index,
            product.relay_nbr,
            product.Vendor_Id,
            product.S_Pack_Type,
            product.S_Pack_Qty,
            storeId
        );
    };
    renderImage=(product)=>{
        return renderImages.getImage({
            src: product.Image_File_Name
                ? product.Image_File_Name
                : 'https://images.truevalue.com/getimage.asp?id=' +
                product.Item_Number +
                '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
            style: { display: this.props.img_style },
            alt: product.Image_File_Name,
            className: 'ui image image_lisitng_grid',
            onMouseEnter: () => {
                this.addimg(
                    'addimg',
                    product.Item_Number
                        ? product.Item_Number
                        : product.item_id
                );
            }
        })
    }

    render() {
        const link = '/discountCitypalletListing/';
        const linkPdp = '/product/';
        const {
            tableType,
            viewType,
            categoryData: products,
            quantities,
            overrideRetails,
            handleChange,
            handleChangeblur,
            addToCart,
            assortmentId,
            isShowAllWarehouse
        } = this.props;
        const isDiscountCity = tableType === DISCOUNT_CITY;
        return (
            <>
                <AddToCartModal
                    isAddtoCartModalopen={this.state.isAddToCartModalOpen}
                    closeCartModal={this.closeCartModal}
                    addToCart={addToCart}
                    product={this.state.productSelectedForModal}
                    index={this.state.indexForModal}
                    qty={quantities[this.state.indexForModal]}
                    or={overrideRetails[this.state.indexForModal]}
                />
                <div className='ui stackable column grid product-grid'>
                    {products &&
                        products.map((product, index) => {
                            const isDiscontinuedHideAddToCart = product.whStockStatus === 'DIS' ? true : false
                            return (
                                <>
                                    <div className='column dc-expand'>
                                        <div className=' ui segment card_item' id={this.props.multiStoreDetails.access !== 1
                                            ? 'grid_segment_assort'
                                            : 'grid_segment'}>
                                            <div
                                                onMouseLeave={() => {
                                                    this.delimg('delimg');
                                                }}
                                            >
                                               {this.props.palletItem && <div className="seq-right">
                                                    <span className='compare-n-fav padding_right'>
                                                        SEQ# :{product.seq_nbr}
                                                    </span>
                                                </div>
                                                }
                                                <div className='card_img' style={{ marginTop: '0.5rem' }}>
                                                {this.props.palletItem ?
                                                    <Link
                                                        onClick={this.props.setBackButtonPath}
                                                        to={{
                                                            pathname: linkPdp + '100024',
                                                            state: {
                                                                palletItem: this.props.palletItem
                                                            }
                                                        }}
                                                        className={
                                                            this.props.img_style === 'none'
                                                                ? 'disable_img_click'
                                                                : ''
                                                        }
                                                    >{this.renderImage(product)}</Link>
                                                    :
                                                    <Link
                                                        onClick={this.props.setBackButtonPath}
                                                        to={{
                                                            pathname:
                                                                link +
                                                                (product.Item_Number
                                                                    ? product.Item_Number
                                                                    : product.item_id),
                                                            state: {
                                                                palletDesc:product?.shortDesc || product.Product_Name || product.Product_Title,
                                                                PalletSKU:'266-058',
                                                                palletModelnumber:product.Model,
                                                                palletUpc:product.UpcNum,
                                                                palletCost:product.spc_cost,
                                                                palletMbrCost:product.Cost,
                                                                palletSavings:product.Savings,
                                                                palletPricing:product.pricing,
                                                                palletVendorname:product.Vendor_Name,
                                                                palletPack:product?.S_Pack_Type +product?.S_Pack_Qty,
                                                                palletRelayNbr:product.RelayNbr,
                                                                palletPromoNbr:product.PromoNbr,
                                                            }
                                                        }}
                                                        className={
                                                            this.props.img_style === 'none'
                                                                ? 'disable_img_click'
                                                                : ''
                                                        }
                                                    >{this.renderImage(product)}</Link>
                                                }
                                                </div>
                                            </div>
                                            <p
                                                style={{ marginBottom: '0' }}
                                                className='t-center vendor-span'
                                            >
                                                <Link
                                                    onClick={this.props.setBackButtonPath}
                                                    to={{
                                                        pathname: product.Vendor_Name
                                                            ? '/vendor/' +
                                                            utilities.replaceAll(
                                                                product.Vendor_Name,
                                                                '/',
                                                                '%2F'
                                                            ) + '/' + (product.Vendor_Id || product.vendorID || product.vendor_id)
                                                            : '#',
                                                        state: { vendorId: product.Vendor_Id }
                                                    }}
                                                >
                                                    {product.Vendor_Name}
                                                </Link>
                                            </p>
                                            <p
                                                className='t-center'
                                                style={{ fontFamily: 'HelveticaNeueBold' }}
                                            >
                                                {this.props.palletItem ?
                                                <Link
                                                    onClick={this.props.setBackButtonPath}
                                                    to={{
                                                        pathname: linkPdp + '100024',
                                                        state: {
                                                            palletItem: this.props.palletItem
                                                        }
                                                    }}
                                                >
                                                    {product.shortDesc || product.Product_Name || product.Product_Title}
                                                </Link>
                                                :
                                                <Link
                                                onClick={this.props.setBackButtonPath}
                                                to={{
                                                    pathname: link + product.Item_Number,
                                                    state: {
                                                        palletDesc:product?.shortDesc || product.Product_Name || product.Product_Title,
                                                        PalletSKU:'266-058',
                                                        palletModelnumber:product.Model,
                                                        palletUpc:product.UpcNum,
                                                        palletCost:product.spc_cost,
                                                        palletMbrCost:product.Cost,
                                                        palletSavings:product.Savings,
                                                        palletPricing:product.pricing,
                                                        palletVendorname:product.Vendor_Name,
                                                        palletPack:product?.S_Pack_Type +product?.S_Pack_Qty,
                                                        palletRelayNbr:product.RelayNbr,
                                                        palletPromoNbr:product.PromoNbr,
                                                    }
                                                }}
                                            >
                                                {product.shortDesc || product.Product_Name || product.Product_Title}
                                            </Link>
                                            }
                                            </p>
                                            <div
                                                className='card_wrap'
                                                style={{ paddingBottom: '0.2rem' }}
                                            >
                                                <div className='card_fields gridbold'>
                                                    <CustomTableGridView
                                                        viewPOG={this.props.multiStoreDetails.viewPOG}
                                                        PogMbr={this.props.multiStoreDetails.PogMbr}
                                                        CatMbr={this.props.multiStoreDetails.CatMbr}
                                                        setBackButtonPath={this.props.setBackButtonPath}
                                                        getprefer={
                                                            this.props.getprefer &&
                                                                this.props.getprefer.customerView
                                                                ? this.props.getprefer.customerView
                                                                : 'c1'
                                                        }
                                                        index={index}
                                                        palletItem={this.props.palletItem}
                                                        assortmentId={assortmentId}
                                                        handleQtyChange={this.props.handleChange}
                                                        handleQtyChangeblur={this.props.handleChangeblur}
                                                        product={product}
                                                        viewType={this.props.viewType}
                                                        tableType={tableType}
                                                        quantity={quantities[index]}
                                                        overrideRetail={overrideRetails[index]}
                                                        title={this.props.title}
                                                        listType={this.props.listType}
                                                        isShowAllWarehouse={isShowAllWarehouse}
                                                        access={this.props.multiStoreDetails.access}
                                                        multiStoreDetails={this.props.multiStoreDetails}
                                                        isCloseoutItems={this.props.isCloseoutItems}
                                                        RAMarketFlag={this.props.RAMarketFlag}
                                                    />
                                                </div>
                                                <div className={'restricted-product-planogram t-center'}>
                                                    {(product.Restricted || product.itemRestricted || product.restricted === 'True') ? 'Restricted Product' : '    '}
                                                </div>
                                            </div>
                                        </div>
                                        <>
                                        {this.props.palletItem? 
                                        <>
                                        </>
                                        :
                                        <>
                                            {this.props.multiStoreDetails.access !== 1 && !isDiscontinuedHideAddToCart && (
                                                <button
                                                    className='ui red button'
                                                    id='button_grid'
                                                    onClick={() => this.handleAddToCart(product, index)}
                                                >
                                                    Add to Cart
                                                </button>
                                            )}

                                            <span>
                                                <label className='dc_expand_label'>{this.state.showSpec === 'showSpec' + product.Item_Number && this.state.showToggle ? 'Collapse' : 'Expand'}</label>
                                                <i className={classnames(
                                                    `${this.state.showSpec === 'showSpec' + product.Item_Number && this.state.showToggle ? 'minus' : 'plus'}`,
                                                    'icon',
                                                    'fl-r'
                                                )} id='expand_discountcity'
                                                    onClick={() => { this.toggleDiv('showToggle'); this.setState({ showSpec: 'showSpec' + product.Item_Number }) }}
                                                ></i>
                                            </span>
                                        </>
                                        }

                                            {this.state.showToggle && this.state.showSpec === 'showSpec' + product.Item_Number
                                                ? (

                                                    <SpecTable 
                                                    product={this.props.categoryData}
                                                    multiStoreDetails={this.props.multiStoreDetails}
                                                    viewType='grid'/>

                                                )
                                                : (
                                                    <tbody />
                                                )
                                            }
                                        </>
                                    </div>
                                </>
                            );
                        })
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    const carts = state.cart.orders.allCarts;
    return {
        carts,
        currentCartType: state.cart.orders.currentCart.cartInfoName,
        whCarts: carts.filter(cart => cart.cartInfoName === 'REGULAR'),
        getprefer: state.preference.listData,
        multiStoreDetails: state.SessionReducer.multiStoreDetails,
    };
};

function mapDispatchToProps(dispatch) {
    return Object.assign(
        { dispatch },
        bindActionCreators(
            {
                addProduct,
                createNAddCart
            },
            dispatch
        )
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DcProductListingGrid);

const CustomTableGridView = ({
    product,
    viewType,
    tableType,
    handleQtyChange,
    handleQtyChangeblur,
    index,
    quantity,
    overrideRetail,
    title,
    palletItem,
    listType,
    isShowAllWarehouse,
    assortmentId,
    setBackButtonPath,
    getprefer,
    access,
    CatMbr,
    viewPOG,
    PogMbr,
    multiStoreDetails,
    RAMarketFlag
}) => {
    let tbl_data = [];
    if(!palletItem){
        tbl_data = [
            ...((access !== 1 || (access === 1 && CatMbr))
                ? [
                    { label: 'Pallet Location:', val: 'C 6006' },
                    { label: 'Pallet SKU:', val: '266-058' },
                    { label: 'Model Number:', val: product.Model },
                    { label: 'UPC :', val: product.UpcNum },
                    { label: 'SUGG. RETAIL:', val: product.SuggestedRetail },
                    {
                        label: 'Member Cost:',
                        val:
                            (product.future_cost && parseInt(product.future_cost)) !== 0
                                ? '$' + product.future_cost
                                : '$' + product.Cost
                    },
                    {
                        label: 'Market Cost:',
                        val: utilities.hyphenForNull('$' + product.spc_cost)
                    },
                    {
                        label: 'Savings:',
                        val: utilities.hyphenForNull(product.Savings)
                    },
                    {
                        label: 'Dating:',
                        val: '30 days'
                    },
                    {
                        label: 'Pack:',
                        val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                            product.S_Pack_Qty ? product.S_Pack_Qty : ''
                            }`
                    },
                    { label: 'Ship Weeks-:' },
    
                    {
                        label: 'MM/DD',
                        type: 'qty1',
                        val: quantity[0],
                        is_text_box: true
                    },
                    {
                        label: 'MM/DD',
                        type: 'qty2',
                        val: quantity[1],
                        is_text_box: true
                    },
                    {
                        label: 'MM/DD',
                        type: 'qty3',
                        val: quantity[2],
                        is_text_box: true,
                        class: 'dc_qty3'
                    },
                    {
                        label: 'MM/DD',
                        type: 'qty4',
                        val: quantity[3],
                        is_text_box: true,
                        class: 'dc_qty4'
                    },
                    {
                        label: 'RESTRICTED',
                        val: product.Restriced ? 'Restricted Product' : '',
                        className: 't-center restricted-product'
                    }
                ]
                :
                [
                    { label: 'Pallet Location:', val: 'C 6006' },
                    { label: 'Pallet SKU:', val: '266-058' },
                    { label: 'Model Number:', val: product.Model },
                    { label: 'UPC :', val: product.UpcNum },
                    {
                        label: 'Savings:',
                        val: utilities.hyphenForNull(product.Savings)
                    },
                    {
                        label: 'Dating:',
                        val: '30 days'
                    },
                    {
                        label: 'Pack:',
                        val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                            product.S_Pack_Qty ? product.S_Pack_Qty : ''
                            }`
                    },
                    { label: 'Ship Weeks-:' },
    
                    {
                        label: 'MM/DD',
                        type: 'qty1',
                        val: quantity[0],
                        is_text_box: true
                    },
                    {
                        label: 'MM/DD',
                        type: 'qty2',
                        val: quantity[1],
                        is_text_box: true
                    },
                    {
                        label: 'MM/DD',
                        type: 'qty3',
                        val: quantity[2],
                        is_text_box: true
                    },
                    {
                        label: 'MM/DD',
                        type: 'qty4',
                        val: quantity[3],
                        is_text_box: true
                    },
                    {
                        label: 'RESTRICTED',
                        val: product.Restriced ? 'Restricted Product' : '',
                        className: 't-center restricted-product'
                    }
                ])
        ];
}
else{
    tbl_data = [
        ...((access !== 1 || (access === 1 && CatMbr))
            ? [
                {label: 'Item SKU:', val: '266-058' },
                { label: 'Market cost ', val: '$100.72'},
                { label: 'Model#:', val: product.Model },
                { label: 'Member cost ', val: '$100.72'},
                { label: 'NoN Stocked:', val: 'N' },
                { label: 'Quantity ', val: '6' },
            ]
            :
            [
                {label: 'Item SKU:', val: '266-058' },
                { label: 'Market cost ', val: '$100.72'},
                { label: 'Model#:', val: product.Model },
                { label: 'Member cost ', val: '$100.72'},
                { label: 'NoN Stocked:', val: 'N' },
                { label: 'Quantity ', val: '6' },
            ]
        )
    ];
}

    return (
        <>
            {tbl_data.map(item => {
                const className = item.classname
                    ? item.classname
                    : 'First_level_details';
                return (
                        <Grid columns={2}>
                        {item.label === 'RESTRICTED' ? (
                            <div className={className}>{item.val}</div>
                        ) : item.label === 'Ship Weeks-:' ?
                                <GridRow>
                                    <span className='dc_ship_weeks'>{item.label}</span>
                                </GridRow>

                                : (
                                    <>
                                        <Grid.Column>
                                            {item.label !== 'MM/DD' && item.lable !== 'Ship Weeks-:' ? <span
                                                className={item.labelClass ? item.labelClass : 'dc_card_name'}
                                            >
                                                {item.label}
                                            </span>
                                                : item.label === 'MM/DD' && item.type === 'qty1' || item.type === 'qty2' ?
                                                    <GridColumn>
                                                        <span
                                                            className='dc_card_specific'
                                                        >
                                                            {item.label}
                                                        </span>
                                                        <span className='dc_card_qty_space'>
                                                            <input
                                                                className='qty-box'
                                                                id={item.type + product.Item_Number}
                                                                data-item-number={index}
                                                                data-field={item.type}
                                                                data-pack-type={product.S_Pack_Type}
                                                                data-pack-qty={product.S_Pack_Qty}
                                                                onChange={handleQtyChange}
                                                                value={item.val ? item.val : ''}
                                                                type='text'
                                                            />
                                                        </span>
                                                    </GridColumn>
                                                    : ''
                                            }
                                        </Grid.Column>
                                        {item.is_text_box ? (
                                            item.type === 'qty' ? (

                                                <Grid.Column>

                                                    <span>

                                                        <input
                                                            className='qty-box'
                                                            id={'qty-box' + product.Item_Number}
                                                            data-item-number={index}
                                                            data-field='qty'
                                                            data-pack-type={product.S_Pack_Type}
                                                            data-pack-qty={product.S_Pack_Qty}
                                                            onChange={handleQtyChange}
                                                            value={item.val ? item.val : ''}
                                                            type='text'
                                                        />
                                                    </span>
                                                </Grid.Column>

                                            ) : item.type === 'or' ? (
                                                <Grid.Column>
                                                    <span>
                                                        <input
                                                            className='qty-box'
                                                            value={item.val ? item.val : ''}
                                                            data-item-number={index}
                                                            data-field='or'
                                                            onChange={handleQtyChange}
                                                        />
                                                    </span>
                                                </Grid.Column>
                                            ) : item.type !== 'qty1' || item.type !== 'qty2' || item.type === 'qty3' || item.type === 'qty4' ? (
                                                item.label === 'MM/DD' && item.type === 'qty3' || item.type === 'qty4' ?
                                                    <GridColumn id='dc_qty_common'>
                                                        <span id={item.class} >
                                                        <span
                                                            className='dc_card_specific'
                                                        >
                                                            {item.label}
                                                        </span>
                                                        <span className='dc_card_qty_space' >
                                                            <input
                                                                className='qty-box'
                                                                id={item.type + product.Item_Number}
                                                                data-item-number={index}
                                                                data-field={item.type}
                                                                data-pack-type={product.S_Pack_Type}
                                                                data-pack-qty={product.S_Pack_Qty}
                                                                onChange={handleQtyChange}
                                                                value={item.val ? item.val : ''}
                                                                type='text'
                                                            />
                                                        </span>
                                                        </span>
                                                    </GridColumn>
                                                    : ''
                                            ) : (
                                                            <Grid.Column>

                                                                <span>
                                                                    <input
                                                                        className='qty-box'
                                                                        id={item.type + product.Item_Number}
                                                                        data-item-number={index}
                                                                        data-field={item.type}
                                                                        data-pack-type={product.S_Pack_Type}
                                                                        data-pack-qty={product.S_Pack_Qty}
                                                                        onChange={handleQtyChange}
                                                                        value={item.val ? item.val : ''}
                                                                        type='text'
                                                                    />
                                                                </span>
                                                            </Grid.Column>

                                                        )
                                        ) : (
                                                <Grid.Column>
                                                            
                                                    <b className={className}>
                                                        {item.is_number ? (
                                                            utilities.changePriceToNumber(item.val)
                                                        ) : (item.label === "SUBSTITUTE:" || item.label === "REFERRAL ITEM:" ) &&
                                                            item.val.trim() !== '-' ? (
                                                                    <Link
                                                                        onClick={setBackButtonPath}
                                                                        to={{
                                                                            pathname: '/product/' + item.val,
                                                                            state: {
                                                                                title: title,
                                                                                listType: listType
                                                                            }
                                                                        }}
                                                                    >
                                                                        {item.val}
                                                                    </Link>
                                                                ) :
                                                                (
                                                                    item.val
                                                                )}
                                                    </b>
                                                </Grid.Column>
                                            )}
                                    </>
                                )}
                    </Grid>
                );
            })}
            <div id='snackbar'>Product added to cart</div>
        </>
    );
};