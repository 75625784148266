import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {changeCamera} from './actions';
import {  BrowserMultiFormatReader } from "@zxing/library";
import { bindActionCreators } from 'redux';
import restUtils from '../../utils/restUtils'
import{ scanningLog, getEnv1 } from '../../urlConstants'

class ScanningDevice extends React.Component {
 constructor(props) {
   super(props);
   const code = props?.match?.params?.code ? props?.match.url : props.history?.location?.state
   this.codeReader =  new BrowserMultiFormatReader();
   this.state = {
      reader: {},
      selectedDevice: this.props.camera,
      videoInputDevices: [],
      showScan: code ? false : true
      //showScan: false
   };
   this.startButton = this.startButton.bind(this);
   this.getCode = this.getCode.bind(this);
 }
 componentDidMount() {
   if(this.state.showScan){
    this.getCode()
    this.startButton()
   }
 }
 componentDidUpdate(prevProps, prevState) {
  if(prevState.videoInputDevices[0]?.deviceId  === undefined){
    this.getCode()
   }
   
 }
  recallComponent=()=>{
    this.props.handleReset()
  }
 startButton() {
  const versionNumber = "1";
  const environment = getEnv1()
    this.codeReader && this.codeReader.reset()
    this.codeReader
    .decodeOnceFromVideoDevice(this.state.selectedDevice, "video")
    .then(result => {
      console.log(result) 
      this.props.handleResult(result.text)
      let payload = {
      "Environment": environment,
      "Function": "Scan",
      "Status": "Success",
      "version": versionNumber,
      "Store": this.props.storeId,
      "userName": this.props.userName,
      "user":this.props.userId,
      "level" : "debug",
      "payload":{"message": result}
      }
      restUtils.postData(scanningLog, payload).then((response) => {
        console.log(response)
      }).catch(err=> console.log(err))
     // history.replace({ pathname:'/Scan',state: result.text });    
    })
    .catch(err => {
      let payload = {
        "Environment": environment,
        "Function": "Scan",
        "Status": "Error",
        "version": versionNumber,
        "Store": this.props.storeId,
        "userName": this.props.userName,
        "user":this.props.userId,
        "level" : "debug",
        "payload":{"message": err.toString()}
        }
      restUtils.postData(scanningLog, payload).then((response) => {
        console.log(response)
      }).catch(err=> console.log(err))
      console.error(err.toString());
    });
  console.log(
    `Started continous decode from camera with id ${this.state.selectedDevice}`
  );
  
 }
 getCode() {  
   return this.codeReader.listVideoInputDevices().then(videoInputDevices => {
     this.setState({videoInputDevices})
     })
     .catch(err => {
       console.log(err);
     });
 }
 selectVideo = (e) => {
  this.props.changeCamera(e.target.value)
  this.setState({ selectedDevice: e.target.value },()=> {  this.startButton()})
 }
 componentWillUnmount() {
    this.codeReader && this.codeReader.reset();
 }

 render() {
   return (
     <>
       {Object.keys(this.codeReader).length > 0 && (
         <>
         
         {this.state.showScan && (
          <div className="scannerWrapper">
             <video  id="video"></video>
         </div>
         )}
         
         {this.state.showScan && this.state.videoInputDevices && this.state.videoInputDevices.length > 0 && (
           <div id="sourceSelectPanel" style={{marginTop: '10px'}}>
           <select id="sourceSelect" onChange={this.selectVideo}>
            <option value="" disabled selected>Switch Camera</option>
             {this.state.videoInputDevices.map((ele,i) =>  <option selected={this.state.selectedDevice === ele.deviceId} value={ele.deviceId}>{ele.label}</option>)}
           </select>
          </div>
         )}
        
         </>
       )}
     </>
   );
 }
}

const mapDispatchToProps = dispatch => { 
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {changeCamera},
      dispatch
    )
  );
};

const mapStateToProps = state => {
  return {
    camera: state.MatchingImage.changeCamera,
    userId: state.SessionReducer.UserId,
    userName: state.SessionReducer.userName,
    storeId: state.SessionReducer.storeId
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ScanningDevice);