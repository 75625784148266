import React from "react";
import { Loader, Dropdown } from 'semantic-ui-react';
import '../common/snackbar.css';
import './Scan.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import ItemListing from '../ReunionEvent/AllReunion/ItemListing'
import DropShipPalletListing from '../ReunionEvent/AllReunion/DropShipPalletListing'
import VendorDropShipItemListing from '../ReunionEvent/AllReunion/VendorDropShipItemListing'
import PalletListing from '../ReunionEvent/AllReunion/palletListing'
import AssortmentsList from '../ReunionEvent/AllReunion/AssortmentsList'
import RetailAssortments from '../RetailAssortment'
import CloseoutCornerScan from '../CloseoutCorner/CloseoutCornerScan'
import { shoppingAreaName } from '../Cart/constants'
import utilities from "../../utils/utilities";

class ScannedProduct extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          //url: 'https://nwa-uat.truevalue.com/Scan/10|5|2021|97|511|pallet_nbr|10055'
          url: props.code
      }
    }
    componentWillReceiveProps(nextProps) {
       if(this.props.code !== nextProps.code) {
        this.setState({url: nextProps.code})
       }
        
    }
    getComponent = () => {
        if(this.state.url !=='') {
            const code = decodeURIComponent(this.state.url.split('Scan/')[1]).split('|')
            if(code[0] === 'ctb') {
                let [name, subCat] = code
                return <RetailAssortments
                         fromScanning = {true}
                         subCat = {subCat}
                         />
            }
            if(code[0] === 'coc'){
                const itemnum = this.state.url?.split('Scan/')[1]?.split('|')[1] || '';
                return <CloseoutCornerScan
                    itemNumber = {itemnum}
                />
            }
            let [shoppingId,flowType,relayYear,relayNbr,promoNo,searchBy,searchValue] = this.state.url !== "" && code
            let filterBy = searchBy ==='vendor_id' ? utilities.getScanFilterValue(searchValue, flowType): false
            if(filterBy) {
                searchBy = searchValue = ''
            }
            switch(parseInt(flowType)) {
               case 1 :
                    return  <ItemListing 
                    shoppingId = {shoppingId}
                    flowType = {flowType}
                    relayYear = {relayYear}
                    relayNbr = {relayNbr}
                    eventId = {promoNo}
                    searchBy = {searchBy}
                    searchValue = {searchValue}
                    shoppingMetaDataId = {shoppingId}
                    shoppingAreaName = {shoppingAreaName[parseInt(shoppingId)]}
                    fromScanning = {true}
                    filterBy= {filterBy}
            />
                case 2 :
                    return  <AssortmentsList 
                    shoppingId = {shoppingId}
                    flowType = {flowType}
                    relayYear = {relayYear}
                    relayNbr = {relayNbr}
                    eventId = {promoNo}
                    searchBy = {searchBy}
                    searchValue = {searchValue}
                    shoppingMetaDataId = {shoppingId}
                    shoppingAreaName = {shoppingAreaName[parseInt(shoppingId)]}
                    fromScanning = {true}
                    filterBy= {filterBy}
            />
                case 3 :
                        return  <PalletListing 
                                shoppingId = {shoppingId}
                                flowType = {flowType}
                                relayYear = {relayYear}
                                relayNbr = {relayNbr}
                                eventId = {promoNo}
                                searchBy = {searchBy}
                                searchValue = {searchValue}
                                shoppingMetaDataId = {shoppingId}
                                shoppingAreaName = {shoppingAreaName[parseInt(shoppingId)]}
                                fromScanning = {true}
                                filterBy= {filterBy}
                        />
               case 4 :
                    return  <VendorDropShipItemListing 
                            shoppingId = {shoppingId}
                            flowType = {flowType}
                            relayYear = {relayYear}
                            relayNbr = {relayNbr}
                            eventId = {promoNo}
                            searchBy = {searchBy}
                            searchValue = {searchValue}
                            shoppingMetaDataId = {shoppingId}
                            shoppingAreaName = {shoppingAreaName[parseInt(shoppingId)]}
                            fromScanning = {true}
                            filterBy= {filterBy}
                    />
                case 5:
                    return <DropShipPalletListing
                            shoppingId = {shoppingId}
                            flowType = {flowType}
                            relayYear = {relayYear}
                            relayNbr = {relayNbr}
                            eventId = {promoNo}
                            searchBy = {searchBy}
                            searchValue = {searchValue}
                            shoppingMetaDataId = {shoppingId}
                            shoppingAreaName = {shoppingAreaName[parseInt(shoppingId)]}
                            fromScanning = {true}
                            filterBy= {filterBy}
                    />
                default :
                    return <p style={{textAlign: 'center'}}>Invalid Code , Please Scan Again</p>
           }
        }
    }
    render() {
        return(
            <div>
                {this.state.url !== '' && (
                    <>
                    {this.getComponent()}
                    </>
                    
                )}
            </div>
        )
    }
}
export default connect(null,null)(ScannedProduct);