import React from 'react';
import PropTypes from 'prop-types';
import RefineFilterItem from '../RefineFilterItem';
import { ALL_CLASSES } from '../../constants';

const RefineFilterList = ({
  departments,
  classes = [],
  subClasses = [],
  toggleFilterCategory,
  filtersToggled,
  selectDepartment,
  selectClass,
  departmentSelected,
  classSelected,
  isLoading,
  selectSubclass,
  subClassesSelected
}) => {
  const departmentsFilterSet = {
    id: "$fineline_department_filter",
    filterTitle: "Fineline Department",
    filterOptions: departments,
    optionTypes: 'radio',
    handleChange: selectDepartment,
    selectedOption: departmentSelected,
    isLoading: isLoading.departments
  }

  const classesFilterSet = {
    id: "$fineline_class_filter",
    filterTitle: "Fineline Class",
    filterOptions: classes.length > 0 ? [
      { id: ALL_CLASSES, value: ALL_CLASSES, name: ALL_CLASSES },
      ...classes,
    ] : [],
    optionTypes: 'radio',
    handleChange: selectClass,
    selectedOption: classSelected,
    isLoading: isLoading.classes
  }

  const subClassesFilterSet = {
    id: "$fineline_subclass_filter",
    filterTitle: "Fineline Sub-Class",
    filterOptions: subClasses,
    optionTypes: 'checkbox',
    isLoading: isLoading.subClasses,
    selectedOptions: subClassesSelected,
    handleChange: selectSubclass
  }

  const filtersSet = [
    departmentsFilterSet,
    classesFilterSet,
    subClassesFilterSet
  ];

  return filtersSet && filtersSet.map((filter, index) => (
    <RefineFilterItem
      key={filter.id}
      index={index}
      toggleFilterCategory={toggleFilterCategory(filter.id)}
      isToggled={filtersToggled[filter.id]}
      {...filter}
    />
  ));
};

RefineFilterList.propTypes = {
  departments: PropTypes.array,
  classes: PropTypes.array,
  subClasses: PropTypes.array,
  toggleFilterCategory: PropTypes.func.isRequired,
  filtersToggled: PropTypes.object,
  selectDepartment: PropTypes.func.isRequired,
  selectClass: PropTypes.func.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  departmentSelected: PropTypes.object,
  classSelected: PropTypes.object,
  suggestedRetailOptionSelected: PropTypes.object,
  memberCostOptionSelected: PropTypes.object,
  isLoading: PropTypes.object,
  selectSubclass: PropTypes.func,
  subClassesSelected: PropTypes.arrayOf(PropTypes.object)
}

export default RefineFilterList;
