import restUtils from "../../utils/restUtils";
import { Event } from '../../history';
import utilities from "../../utils/utilities";
import {addToFavoritesUrl,removeFromFavoritesUrl,getFavoritesUrl,getFavoritesItems} from '../../urlConstants';
import _ from "lodash";

export const GET_ALL_FAVORITES = "GET_ALL_FAVORITES";
export const START_FETCH_FAVORITES = "START_FETCH_FAVORITES";
export const ADD_TO_FAVORITES = 'ADD_TO_FAVORIES';
export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES';
export const GET_ALL_FAVORITES_ITEMS = 'GET_ALL_FAVORITES_ITEMS'

export const addToFavorites = (itemId) =>
{
  Event("PRODUCT","Favorited",itemId.toString());
  return (dispatch,getState) =>{
     let multiStoreDetails = getState().SessionReducer?.multiStoreDetails;
      let  postObj = {
        "UserId" : getState().SessionReducer.UserId,
        "ItemNum":itemId,
        "Source":"WH"
        }
      restUtils
        .postData(addToFavoritesUrl,postObj)
        .then(response =>{
            dispatch({
                type:ADD_TO_FAVORITES,
                payload:itemId
            })
        }).catch(error =>{
            console.log(error)
        });
  }
}
export const removeFromFavorites = (itemIds,page=1,pageSize=80001,selectedDepartment=[],sortBy='ItemNbr',sortOrder='ASC', isLoad = false) =>{
  Event("PRODUCT","Removed from Favorites",itemIds.toString());
  return (dispatch,getState)=>{
    let multiStoreDetails = getState().SessionReducer?.multiStoreDetails;
    let postObj = itemIds.map(function(itemId) {
        return {
            "UserId" : getState().SessionReducer.UserId,
            "ItemNum":itemId,
            "Source":"WH"
            };
      });
    restUtils
        .deleteData(removeFromFavoritesUrl,postObj)
        .then(response =>{
            dispatch({
                type:REMOVE_FROM_FAVORITES,
                payload:itemIds
            })
            if(isLoad) {
            const currentCart = getState().cart.orders.currentCart;
            const currentCartId = currentCart?currentCart.cartId:'';
            let  requestBody = {
                "filterBy": [{name: "Department", valueList: selectedDepartment}],
                "userId":getState().SessionReducer.UserId,
                "rdc": getState().SessionReducer.address.rdc,
                "resultPerPage": pageSize,
                "page":parseInt(page),
                "sortby":sortBy,
                "sortorder":sortOrder,
                "cartid" : utilities.emptyForNull(currentCartId),
                "storeId" : getState().SessionReducer.storeId
            }
     
            if(utilities.msExist(multiStoreDetails) > 1 && currentCartId){
                requestBody = {...requestBody,multiStoreCartId: utilities.emptyForNull(currentCartId)}
              }
            restUtils
            .postData(
                getFavoritesUrl,requestBody
            )
            .then(response => {
                return dispatch({
                    type:GET_ALL_FAVORITES,
                    payload:response.data
                })
            }).catch(error =>{
                console.log(error)
            })
        }
        }).catch(err=>{
            console.log(err)
        });
    };
}

export const getFavoriteAllDetails = (page,pageSize,selectedDepartment,sortBy,sortOrder,scrollPosition = 0, isMomentum)=>{
    return (dispatch,getState) => {
        const userId =getState().SessionReducer.UserId;
        const rdc = getState().SessionReducer.address.rdc;
        const multiStoreDetails = getState().SessionReducer?.multiStoreDetails;
        const currentCart = getState().cart.orders.currentCart;
        let currentCartId = currentCart ? currentCart.cartId : '';
        const currentCartDetails = getState().cart.orders.allCarts.filter((c) => c.cartId === currentCartId);
        if(currentCartDetails && currentCartDetails.length > 0 && currentCartDetails[0]?.cartInfoName && currentCartDetails[0].cartInfoName === 'MS'){
            const msCarts = getState().cart.orders.allCarts.filter((c) => c.cartInfoName === 'MS');
            currentCartId = msCarts.length > 0 ? msCarts[0]?.cartId : currentCartId
        }
        const storeId = getState()?.SessionReducer.storeId;
        const aliasSKURetailerGrpID = getState()?.SessionReducer?.address?.aliasSKURetailerGrpID;
        let url = getFavoritesUrl;
        let  requestBody = {
            "filterBy": [{name: "Department", valueList: selectedDepartment}],
            "userId":userId,
            "rdc": rdc,
            "resultPerPage": pageSize,
            "page":parseInt(page),
            "sortby":sortBy,
            "sortorder":sortOrder,
            "cartid" : utilities.emptyForNull(parseInt(currentCartId)),
            "storeId" : storeId,
            "aliasSKURetailerGrpID" : aliasSKURetailerGrpID,
            "momentum": isMomentum ? '1' : ''
        }
        if(utilities.msExist(multiStoreDetails) > 1 && currentCartId && currentCartId){
            requestBody = {...requestBody,multiStoreCartId: utilities.emptyForNull(parseInt(currentCartId))}
          }
        dispatch({
            type:START_FETCH_FAVORITES
        })
        let createPromise =  restUtils
            .postData(
                url,requestBody
            )
        .then(response => {
            return dispatch({
                type:GET_ALL_FAVORITES,
                payload:response.data
            })
        }).catch(error =>{
           
        });
        createPromise.then(() => {
            window.scrollTo(0,scrollPosition);
        });
    }
}

export const getFavoriteItems = (page,pageSize,selectedDepartment,sortBy,sortOrder,scrollPosition = 0)=>{
    return (dispatch,getState) => {
        if(!_.isEmpty(getState().FavoritesReducer.favItemNums)) {
            return
        }
        const userId =getState().SessionReducer.UserId;
        const multiStoreDetails = getState().SessionReducer?.multiStoreDetails;
        const currentCart = getState().cart.orders.currentCart;
        const currentCartId = currentCart ? currentCart.cartId : '';
        let url = getFavoritesItems;
        let  requestBody = {
            "userId":userId,
        }
        if(utilities.msExist(multiStoreDetails) > 1 && currentCartId && currentCartId){
            requestBody = {...requestBody,multiStoreCartId: utilities.emptyForNull(parseInt(currentCartId))}
          }
        dispatch({
            type:START_FETCH_FAVORITES
        })
        let createPromise =  restUtils
            .postData(
                url,requestBody
            )
        .then(response => {
            return dispatch({
                type:GET_ALL_FAVORITES_ITEMS,
                payload:response.data
            })
        }).catch(error =>{
           
        });
        createPromise.then(() => {
            window.scrollTo(0,scrollPosition);
        });
    }
}