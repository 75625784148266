import React from "react";
import { Container, Loader, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { finelinePDFUrl } from './../../../urlConstants';

import Header from "./Header";
import ContentList from "./ContentList";
import "./index.css";
import {
  fetchDepartments,
  getFinelineCatalogs,
  deleteFinelineCatalog,
} from "../actions";

class LandingPage extends React.Component {
  state = {
    isModalOpen: false,
    modalSize: "tiny",
    catalogSelected: null
  };
  componentDidMount() {
    const { getDepartments, getCatalogs } = this.props;
    getDepartments();
    getCatalogs();
  }

  handleGoBuildCatalog = () => {
    const { history, location } = this.props;
    history.push(`${location.pathname}/build-catalog`);
  };

  handleLoadFlipbookPreview = (url) => () => {
    const { history, location } = this.props;
    const mockUrl = url ? url : finelinePDFUrl;
    history.push(`${location.pathname}/flipbook-viewer?pdf=${mockUrl}`, {
      url: mockUrl,
    });
  };

  handleDownloadPdf = () => {
    console.log("download pdf");
  };

  handleDeleteCatalog = () => {
    const { deleteCatalog } = this.props;
    const { catalogSelected } = this.state;
    deleteCatalog(catalogSelected.id);
    this.setState({ isModalOpen: false, catalogSelected: null });
  };

  handleOnModalClose = () => {
    this.setState({
      isModalOpen: false,
      catalogSelected: null,
    });
  };

  handleOpenModal = (catalog) => () => {
    this.setState({
      isModalOpen: true,
      catalogSelected: catalog
    });
  };

  render() {
    const { departments, loading, catalogsLoading, savedCatalogs } = this.props;
    const { modalSize, isModalOpen } = this.state;

    return (
      <div className="catalog-landing-page-container">
        <Header />
        <Container className="catalog-landing-page-content-container">
          {(loading && loading.departments) || catalogsLoading ? (
            <div>
              <Loader active className="loader-fineline" />
            </div>
          ) : (
            <ContentList
              departments={departments}
              goBuildCatalog={this.handleGoBuildCatalog}
              loadFlipbookPreview={this.handleLoadFlipbookPreview}
              savedCatalogs={savedCatalogs}
              deleteCatalog={this.handleOpenModal}
            />
          )}
        </Container>
        <Modal
          size={modalSize}
          open={isModalOpen}
          onClose={this.handleOnModalClose}
        >
          <Modal.Header>Save your Fineline Catalog</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this catalog? </p>
          </Modal.Content>
          <Modal.Actions>
            <button
              onClick={this.handleOnModalClose}
              className="ui button add-to-catalog-button inversed"
            >
              Cancel
            </button>
            <button onClick={this.handleDeleteCatalog} className="ui red button add-to-catalog-button">Delete</button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  departments: state.finelineCatalog.departments.departments,
  loading: state.finelineCatalog.departments.loading,
  catalogsLoading: state.finelineCatalog.catalog.isCatalogsLoading,
  savedCatalogs: state.finelineCatalog.catalog.savedCatalogs,
});

const mapDispatchToProps = (dispatch) => ({
  getDepartments: () => dispatch(fetchDepartments()),
  getCatalogs: () => dispatch(getFinelineCatalogs()),
  deleteCatalog: (catalogId) => dispatch(deleteFinelineCatalog(catalogId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
