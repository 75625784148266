import React, { Component } from 'react';
import { Segment, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './styles.css';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import { MaskPrice } from '../../views/common/MaskPrice';
import utilities,{shouldHideCostForPreference} from '../../utils/utilities';
import { connect } from 'react-redux';
import Categories from '../Categories';
import _ from 'lodash';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class PlanogramItemListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalMemberCost : []
    }
  }
  handleMemberCost = (val) => {
    if(!_.isEmpty(val)) {
      const sumTotal = val.map((item)=>item.orderQty * item.memberCost).reduce((a,b) => a+b)
      this.setState({totalMemberCost: sumTotal})
    }
  }
  render() {
    const { planogram = {} } = this.props.PlanogramStore;
    let deptCatgLink = `/planograms/${planogram.departmentName}`;
    const{match} = this.props;
    const planogramSubnameparam = match && match.params && match.params.planogramSubName ? decodeURIComponent(match.params.planogramSubName) : planogram.planogramSubName;
    const planogramNumberparam = match && match.params && match.params.planogramId ? match.params.planogramId : planogram.planogramNumber;
    const { planogramSubName, planogramNumber, pdfUrl, departmentName = '', categoryName = '' } = planogram;
    const departmentNameparam = match && match.params && match.params.departmentName ? decodeURIComponent(match.params.departmentName) : departmentName.trim();
    const categoryNameparam = match && match.params && match.params.categoryName ? decodeURIComponent(match.params.categoryName) : categoryName.trim();
    const breadcrumbPath = [
      { link: '/home', text: 'HOME' },
      { link: planogram.backPath ? planogram.backPath : deptCatgLink, text: departmentNameparam.trim() },
      { link: `/planogram/${planogram.planogramNumber}`, text:planogramSubnameparam + ' - ' + planogramNumberparam }
    ];
    
    return (
      <div>
        <Segment id='planograms_listing'>
          <div className='page-header'>Planograms</div>
          <div id='page_navigation'><BreadcrumbComp path={breadcrumbPath} /></div>
          <div className='info-text'>
            <span className='pl-card-header'>
              <span>{planogramSubnameparam}</span>
              <span> - </span>
              <span>{planogramNumberparam}</span>
            </span>
            <span className='pdf_btn_styles'>
              View Assortment{' '}
              <i className='file pdf icon large' id='export_btns' onClick={() => utilities.openInNewTab(pdfUrl)} />
            </span>
            <span className='pl-item-links'>
              <Link to={planogram.backPath ? planogram.backPath : deptCatgLink}><u>Back to Planogram Listing</u> </Link>
              {/* <span className='pl-label'>
                Export <i className='file excel icon large' id='export_btns' />
              </span> */}
            </span>
          </div>

          <Responsive minWidth='1025'>
            <div className='card_fields'>
              <PlanogramDetails
                planogram={planogram}
                viewType={this.props.viewType}
                multiStoreDetails={this.props.multiStoreDetails}
                PogMbr={this.props.multiStoreDetails.PogMbr}
                getprefer={this.props.getprefer}
                totalMemberCost = {this.state.totalMemberCost}
              />
            </div>
          </Responsive>

          <Responsive maxWidth='1024'>
            <PlanogramDetailsMobile
              planogram={planogram}
              viewType={this.props.viewType}
              multiStoreDetails={this.props.multiStoreDetails}
              PogMbr={this.props.multiStoreDetails.PogMbr}
              getprefer={this.props.getprefer}
              totalMemberCost = {this.state.totalMemberCost}
            />
          </Responsive>

          <Categories
            assortmentType='Planograms'
            PlanogramDetails = {planogram}
            assortmentId={planogramNumberparam}
            seleDept={departmentNameparam.trim()}
            seleCatg={categoryNameparam.trim()}
            pathName={this.props.location.pathname}
            handleMemberCost = {this.handleMemberCost}
          />
        </Segment>
      </div>
    );
  }
}

const PlanogramDetailsMobile = ({ planogram, viewType, multiStoreDetails, PogMbr, getprefer,totalMemberCost }) => {
  const memberCost = (multiStoreDetails.access !== 1 || PogMbr) ?
      <MaskPrice viewType={viewType} fieldVal={totalMemberCost} 
      getprefer={getprefer && getprefer.customerView ? getprefer.customerView : 'c1'} field='Member Cost' /> : "";

  let tbl_data = [
    { label: 'Size:', val: planogram.sizeString },
    { label: 'SKUs:', val: planogram.nbrSkus },
    { label: 'Store Typical:', val: planogram.storeTypical },
    { label: 'Region:', val: planogram.region && planogram.region.replace('-', '') },
    { label: 'Type:', val: planogram.type },
    { label: 'Urbanicity:', val: planogram.urbanicity },
    { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+":", val: memberCost },
    { label: 'Last Updated:', val: utilities.getDateFromTimeStamp(planogram.lastUpdatedDate) }
  ];

  return (
    <div>
      <table className='ItemdetailsMobile'>
        {tbl_data.map(item => (
          <>
            <tr>
              <td className='order-card-field'>{item.label}</td>
              <td>{utilities.hyphenForNull(item.val)}</td>
            </tr>
          </>
        ))}
      </table>
    </div>
  );
};

const PlanogramDetails = ({ planogram, viewType, multiStoreDetails, PogMbr, getprefer,totalMemberCost }) => {
  const getPrefer = getprefer && getprefer.customerView ? getprefer.customerView : 'c1';
  const memberCost = (multiStoreDetails.access !== 1 || PogMbr) ?
      <MaskPrice viewType={viewType} fieldVal={totalMemberCost} 
      getprefer = {getPrefer} field='Member Cost' /> : "";
      const displayMemberCost = shouldHideCostForPreference(viewType ,getPrefer);

  return (
    <>
      <div className='pl-specs'>
        <div>
          <span className='pl-card-name'>Size:</span>
          <span className='pl-card-field'>{utilities.hyphenForNull(planogram.sizeString)}</span>
          <span className='pl-card-name pl-card-sku'>SKUs:</span>
          <span className='pl-card-field'>{utilities.hyphenForNull(planogram.nbrSkus)}</span>
          <span className='pl-right-card-name pl-store-typical'>Store Typical:</span>
          <span className='pl-card-field'>{utilities.hyphenForNull(planogram.storeTypical)}</span>
          <span className='pl-card-name pl-store-typical'>Region:</span>
          <span className='pl-card-field'>{utilities.hyphenForNull(planogram.region && planogram.region.replace('-',''))}</span>
        </div>
        <div>
          <span className='pl-card-name'>Type:</span>
          <span className='pl-card-field'>{utilities.hyphenForNull(planogram.type)}</span>
          <span className='pl-right-card-name pl-card-sku'>Urbanicity:</span>
          <span className='pl-card-field'>{utilities.hyphenForNull(planogram.urbanicity)}</span>
          {(multiStoreDetails.access !== 1 || PogMbr) && displayMemberCost &&
            <>
              <span className='pl-right-card-name pl-store-typical'>{utilities.getLabelsByType(viewType,"MEMBER_COST")}:</span>
              <span className='pl-card-field'>{utilities.hyphenForNull(memberCost)}</span>
            </>
          }

          <span className='pl-right-card-name pl-store-typical'>Last Updated:</span>
          <span className='pl-card-field'>{utilities.hyphenForNull(utilities.getDateFromTimeStamp(planogram.lastUpdatedDate))}</span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    PlanogramStore: state.PlanogramReducer,
    getprefer: state.preference.listData
  };
};

export default connect(mapStateToProps)(PlanogramItemListing);
