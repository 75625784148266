import React, { Component } from "react";
import { Segment, Table, Grid } from "semantic-ui-react";
import utilities from "../../../utils/utilities";
import { connect } from "react-redux";
import {
  getVendorLabel,
  getQtyField,
  getOverrideRetailField,
  getPalletImageElement,
} from "./itemListingUtilities";
import classnames from 'classnames';
import CollapsiblePalletTable from "./CollapsiblePalletTable";
import _ from 'lodash';
import history from '../../../history';
import { getEventRedirectUrl } from './eventRedirectUtilities';
import { getShipWeekQty, getPalletItems, getDropShipPalletItems, getDropShipWeekQty } from './palletUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import MultistoreToCartModal from '../../Cart/MultistoreToCartModal';

class PalletListView extends Component {
  constructor(props) {
    super(props);
    const { flowType, product } = props;
    this.state = {
      expandDescription: false,
      pallet: product,
      flowType,
      showSpec: false,
      palletItems: [],
      palletItemsLoading: false,
      totalItemLength: 0
    };
    this.palletPage = 1;
    this.getPalletItems = getPalletItems.bind(this);
    this.getDropShipPalletItems = getDropShipPalletItems.bind(this);
  }

  expand = () => {
    this.setState({
      expandDescription: true,
    });
  };

  closeModalexpand = () => {
    this.setState({
      expand: false,
    });
  };

  getItemField = (cell, index, pallet, itemListParams,flowType) => {
    const className = cell.className?cell.className:"First_level_details";
    const field = (
      <>
        {cell.label === "RESTRICTED:" ? (
          <Table.Cell colSpan="2" className={cell.className}>
            {cell.val}
          </Table.Cell>
        ) : (
            <>
            
              <Table.Cell className={cell.label ==='Max Qty Per Ship Window:'?"max_red_color First_level_details":"First_level_details"}>
                <span>
                  {cell.label}
                </span>
              </Table.Cell>
              {cell.is_text_box ? (
                <Table.Cell>
                  {cell.type === "or"
                    ? getOverrideRetailField(cell, pallet, this.handleQtyChange)
                    : getQtyField(cell, this.handleQtyChange, cell.type,pallet)}
                </Table.Cell>
              ) : (
                  <>
                    <Table.Cell
                      className={cell.label === 'Max Qty Per Ship Window:'? 'max_red_color First_level_details' :className}
                    >
                      {cell.is_number
                        ? utilities.changePriceToNumber(cell.val)
                        : cell.val}
                    </Table.Cell>
                  </>
                )}
            </>
          )}
      </>
    );
    return field;
  };

  toggleDiv = () => {
    this.setState(prevState => {
      return { showSpec: !prevState.showSpec };
    });
    if (_.isEmpty(this.state.palletItems)) {
      if(this.state.flowType === "5"){
        this.getDropShipPalletItems(this.state.pallet);
      } else{
        this.getPalletItems(this.state.pallet);
      }
    }
  };
 
  getPalletItemListing = (e) => {
    const { eventId, shoppingAreaName, promoName, shoppingAreaId,shoppingAreaMetaId, flowType, vendorName, fromReunionHome,allItems} = this.props;
    const { pallet } = this.state;
    this.props.setBackButtonPath();
    const keyParams = {
      eventId: eventId,
      shoppingAreaId,
      shoppingAreaMetaId,
      shoppingAreaName,
      flowType,
      vendorId:pallet.vendorId,
      relayNbr: pallet.relayNbr,
      relayYear: pallet.relayYear,
      fromReunionHome,
      promoName,
      palletNumber: pallet.palletNbr,
      vendorName: pallet.vendorName,
      pageName: "PalletItemListing"
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr: pallet.relayNbr,
        relayYear: pallet.relayYear,
        promoNbr: pallet.promoNbr,
        promoName,
        palletNumber: pallet.palletNbr,
        shoppingAreaName,
        shoppingAreaId,
        shoppingAreaMetaId,
        flowType,
        vendorId: pallet.vendorId,
        vendorName: vendorName ? vendorName : pallet.vendorName,
        fromReunionHome,
        allItems:allItems
      }
    });
  }

  getDropShipPalletItemListing = (e) => {
    const { eventId, shoppingAreaName, promoName, shoppingAreaId,shoppingAreaMetaId, flowType, vendorName, fromReunionHome, relayNbr, relayYear,allItems } = this.props;
    const { pallet } = this.state;
    this.props.setBackButtonPath();
    const keyParams = {
      eventId: eventId,
      shoppingAreaId,
      shoppingAreaMetaId,
      shoppingAreaName,
      flowType,
      vendorId:pallet.vendorId,
      relayNbr: relayNbr ? relayNbr : pallet.relayNbr,
      relayYear: relayYear ? relayYear : pallet.relayYear,
      fromReunionHome,
      promoName,
      palletNumber: pallet.palletNbr,
      vendorName: pallet.vendorName,
      palletId: pallet.dsPalletId,
      eventSupplierSelectionID: pallet.EventSupplierSelectionID,
      pageName: "DropShipPalletItemListing",
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr: relayNbr,
        relayYear: relayYear,
        promoNbr: pallet.promoNbr,
        promoName,
        palletNumber: pallet.palletNbr,
        shoppingAreaName,
        shoppingAreaId,
        shoppingAreaMetaId,
        flowType,
        vendorId: pallet.vendorId,
        vendorName: vendorName ? vendorName : pallet.vendorName,
        fromReunionHome,
        allItems:allItems
      }
    });
  }

  fetchMoreData = () => {
    this.palletPage++;
    if (this.state.flowType === "5"){
      this.getDropShipPalletItems();
    } else {
      this.getPalletItems()
    }
  };

  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
   this.props.closeMultistoreModal();
    this.setState({ isMultistoreModalOpen: false });
  }

  render() {
    const {
      cartParams,
      itemParams,
      itemListParams,
      imgStyle,
      product,
      flowType,
      multiStoreDetails,
      relayNbr
    } = this.props;
    const { pallet, showSpec, palletItems, totalItemLength, palletItemsLoading } = this.state;
    let msCheck = utilities.msExist(multiStoreDetails); 
    const itemlistingHandler = this.state.flowType === "5" ?this.getDropShipPalletItemListing:this.getPalletItemListing;
    const isPDPlinkReqd = !((flowType==="4" || flowType==="5") && product.palletNbr==="0");
    const imageId = flowType ==="5" ? product.imageId : product.palletNbr;
    return (
      <>
        <MultistoreToCartModal
        isMultistoreModalOpen={this.state.isMultistoreModalOpen}
        shoppingAreaName={this.props.shoppingAreaName}
        shoppingAreaMetaId={this.props.shoppingAreaMetaId}
        flowType={flowType}
        assortmentId={this.props.assortmentId}
        quantitiesMs={this.props.quantitiesMs}
        quantitiesMsDrop={this.props.quantitiesMsDrop}
        indexCheck={this.props.indexCheck}
        closeMultistoreModal={this.closeMultistoreModal}
        multiStoreCartavailable={this.props.multiStoreCartavailable}
        product={this.state.productSelectedForModal}
        relayNbr={relayNbr}
        />
        <Segment className="product-list-card" key={itemParams.itemNbr}>
          <div className="ui stackable two column grid">
            <div className="one wide column left-top-padding">
              <div className="card_list_wrap">
                {getPalletImageElement(imageId, itemlistingHandler, imgStyle, isPDPlinkReqd)}
              </div>
            </div>
            <div className="fifteen wide column">
              <Grid>
                <Grid.Column computer={16}>
                  <div>
                    <b className='First_level_details vendor-span Cursor' onClick={itemlistingHandler}>
                      {product.shortDesc}
                    </b>
                    <b className="vendor-span black_color no_decoration">
                      {product.vendorName}
                    </b>

                    <span className='expand_lable_list'>
                      <label className='dc_expand_label_list'>{showSpec ? 'Collapse' : 'Expand'}</label>
                      <i className={classnames(
                        `${showSpec ? 'minus' : 'plus'}`,
                        'icon',
                        'fl-r'
                      )} id='expand_discountcity_list'
                        onClick={this.toggleDiv}
                      ></i>
                    </span>
                  </div>
                </Grid.Column>
                <Table stackable className="very basic plp-field-table">
                  <Table.Body className="listingProduct">
                    {itemParams.fieldSet.map((item, i) => (
                      <Table.Row>
                        {item.map((cell, index) => {
                          return this.getItemField(
                            cell,
                            index,
                            pallet,
                            itemListParams,
                            this.state.flowType
                          );
                        })}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <div className="plp_add_to_cartList">
                  {this.state.flowType === "5" ?
                    <div className='shipweek-div'>
                      <span> Ship Months :</span>
                      {getDropShipWeekQty(product.shipDate1, this.props.qtyList?.[product.shipDate1], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship')}
                      {getDropShipWeekQty(product.shipDate2, this.props.qtyList?.[product.shipDate2], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship')}
                      {getDropShipWeekQty(product.shipDate3, this.props.qtyList?.[product.shipDate3], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship')}
                      {getDropShipWeekQty(product.shipDate4, this.props.qtyList?.[product.shipDate4], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship')}
                    </div>
                    : 
                    <div className='shipweek-div'>
                      <span> Ship Weeks :</span>
                      {getShipWeekQty(product.shipDate1, this.props.quantities[0], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1')}
                      {getShipWeekQty(product.shipDate2, this.props.quantities[1], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty2')}
                      {getShipWeekQty(product.shipDate3, this.props.quantities[2], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty3')}
                      {getShipWeekQty(product.shipDate4, this.props.quantities[3], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty4')}
                    </div>
                  }
                  <span>
                  <>
                  {msCheck && msCheck > 1 ?
                      <button
                      className='red ui button'
                      style={{ width: '-webkit-fill-available' }}
                      onClick={() =>
                        this.multistoreHandleCarts(product)
                      }
                    >
                      Add to Store
                    </button>
                    :
                    <button
                      className="red ui button"
                      style={{ width: "-webkit-fill-available" }}
                      onClick={() => cartParams.addToCart(product.palletNbr, itemParams.itemIndex,null,product.pack,product.maxQtyItemRestriction,product.vendorId, null, product.model,product.maxQtyPerReunion)}
                    >
                      Add to Cart
                    </button>
                  }
                  </>
                  </span>
                </div>
              </Grid>
            </div>
          </div>
          {showSpec &&
            <InfiniteScroll
              dataLength={palletItems.length <= totalItemLength ? palletItems.length : 0}
              next={this.fetchMoreData}
              hasMore={palletItems.length < totalItemLength}
              loader={<h4>Loading...</h4>}
              scrollThreshold="4%"
              className="collapsibleTableList"
              height="auto"
            >
              <CollapsiblePalletTable
                products={palletItems}
                palletItemsLoading={palletItemsLoading}
                shoppingAreaMetaId ={this.props.shoppingAreaMetaId}
              />
            </InfiniteScroll>
          }
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    rdc: state.SessionReducer.rdc
  };
};

export default connect(mapStateToProps, null)(PalletListView);
