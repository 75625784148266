import React from 'react'
import HeaderTop from './HeaderTop'
import NavBar from './NavBar'
import CategoryList from '../../views/common/CategoryList'
import "./Header.css";

const Header =(props)=> {
  return (
    <React.Fragment>
      <HeaderTop />
      <NavBar content={props.content} />
    </React.Fragment>
  );
}


export default Header;
