import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { getPOGs } from './actions';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import ImFilters from './ImFilters';
import { Loader, Responsive } from 'semantic-ui-react';
import history from '../../history';
import utilities from '../../utils/utilities';
import { isArray } from 'util';
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class POG extends React.Component {
  constructor(props) {
    super(props);
    const categoryId =
      props.match && props.match.params.imCatId
        ? props.match.params.imCatId
        : '';
    const POGs = props.ImStore[categoryId];
    this.state = {
      categoryId,
      showFilters:
        this.props.getprefer && this.props.getprefer.filterView
          ? this.props.getprefer.filterView === 'E'
            ? true
            : false
          : window.innerWidth > 768
          ? true
          : false,
      isLoading: POGs ? false : true,
      POGs,
      appliedFilters: { Area: categoryId, Type: null },
      isListView: window.innerWidth < 768 ? false : true
    };
    this.sortParam = this.sortParam ? this.sortParam : 'cost';
    this.pathname = '';
    this.sortDirection = this.sortDirection ? this.sortDirection : 'asc';
    this.filterImpulseparam = '&sortby='+this.sortParam +'&sortorder='+this.sortDirection;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.ImStore[this.state.categoryId] !== this.state.POGs) {
      this.setState({
        POGs: newProps.ImStore[this.state.categoryId],
        isLoading: false
      });
      this.setBackButtonPath();
    }
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFiltersImpulse(queryParam);
    }
    else{
      this.props.getPOGs(this.state.categoryId + this.filterImpulseparam);
    }
    
  }

  filterCallback = (filterKey, filterValue) => {
    let categoryList = filterValue;
    this.filterImpulseparam = '&sortby='+ this.sortParam +'&sortorder='+ this.sortDirection;
    if (filterKey === 'Area') {
      this.setState(prevState => {
        return {
          appliedFilters: { Area: filterValue, Type: null },
          categoryId: filterValue,
          clearFilters: false
        };
      });
      this.props.getPOGs(categoryList + this.filterImpulseparam );
    } else if (filterKey === 'Type' && filterValue === null) {
      this.setState({
        appliedFilters: { Area: 'ENDCAPS', Type: null },
        categoryId: 'ENDCAPS',
        clearFilters: false
      });
      this.props.getPOGs('ENDCAPS'+ this.filterImpulseparam );
    } else {
      const prevType = this.state.appliedFilters.Type;
      const newType =
        prevType && isArray(prevType)
          ? prevType.includes(filterValue)
            ? prevType.filter(type => type !== filterValue)
            : [...prevType, filterValue]
          : [filterValue];
      categoryList = isArray(newType) ? newType.join(',') : filterValue;
      this.setState(prevState => {
        return {
          appliedFilters: { ...prevState.appliedFilters, [filterKey]: newType },
          categoryId: categoryList?categoryList : 'ENDCAPS',
          clearFilters: false
        };
      });
        this.props.getPOGs(categoryList+ this.filterImpulseparam);
    }
    this.setBackButtonPath();
  };

  changeSortParam = e => {
    this.sortParam = e.target.value;
    this.filterImpulseparam = '&sortby='+this.sortParam +'&sortorder='+this.sortDirection;
    this.props.getPOGs(this.state.categoryId +this.filterImpulseparam  );
    this.setBackButtonPath();
  };

  changeSortDirection = e => {
    this.sortDirection = e.target.value;
    this.filterImpulseparam = '&sortby='+this.sortParam +'&sortorder='+this.sortDirection;
    this.props.getPOGs(this.state.categoryId + this.filterImpulseparam );
    this.setBackButtonPath();
  };

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  viewItems = (assortment,backPath) => {
    history.push({
      pathname:
        '/im-item-listing/' +
        this.state.categoryId +
        '/' +
        assortment.Ordering_FileName,
      state: { assortment,backPath }
    });
  };

  toggleView = viewType => {
    this.setState({ isListView: viewType === 'list' });
  };

  getUrlFiltersImpulse=(queryParam)=>{
    this.filterImpulseparamasc =queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[0] :this.filterImpulseparam;
    this.sortParamsplit = this.filterImpulseparamasc.split('&');
    this.sortParam = this.sortParamsplit[1].split('sortby=')[1];
    this.sortDirection = this.sortParamsplit[2].split('sortorder=')[1];
    this.filterImpulseparam = '&sortby='+this.sortParam +'&sortorder='+this.sortDirection;
    this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    this.appliedFilters = utilities.getFilterArray(this.filterString);
    for (let property in this.appliedFilters) {
      if (this.appliedFilters.hasOwnProperty(property)) {
        let filter = {};
        filter.name = property;
        if((property === 'Area')){
          let values = this.appliedFilters[property].split(',');
            this.setState({
              appliedFilters : this.appliedFilters,
              categoryId: this.appliedFilters.Type === 'null' ? values : this.appliedFilters.Type ,
              backPath : this.pathname,
            }, () => {
              this.props.getPOGs(this.state.categoryId + this.filterImpulseparam);
              this.setBackButtonPath();
            });
        }          
      }
    }
  }

  setBackButtonPath = () => {
    let pathName = '';
    pathName = pathName + this.filterImpulseparam;
    pathName = this.scrollPosition > 0 ? pathName + '&scrollPosition='+this.scrollPosition : pathName;
    if (!_.isEmpty(this.state.appliedFilters)) {
      pathName = pathName + '&filterString='+ utilities.getQueryStrForFilterString(this.state.appliedFilters);
    }
    let pushUrl = '';
    if(pathName !== ''){
      pushUrl = this.pathname +'?'+pathName;
      window.history.pushState(window.history.state, "", pushUrl);
    }else{
      pushUrl = this.pathname;
    }
    this.setState ({
      backPath : pushUrl
    })
  }

  render() {
    const breadcrumbPath = [
      { link: '/home', text: 'HOME' },
      { link: '/impulse-merchandising', text: 'IMPULSE MERCHANDISING' },
      { link: this.props.location && this.props.location.pathname, text: this.state.appliedFilters && this.state.appliedFilters.Area }
    ];
    const { POGs, showFilters, isLoading } = this.state;
    return (
      <div className='page-wrap'>
        {isLoading ? (
          <Loader active />
        ) : (
          <>
            <div className='page-header'>Impulse Merchandising</div>
            <BreadcrumbComp path={breadcrumbPath} /><br/>
            <div className="impulse-description">
              <div className='sorting-impulseland'>
                <span className='filter-label'>Sort by</span>
                <select className='sort-criteria' onChange={this.changeSortParam} id='impulse_dropdown' value={this.sortParam}>
                    <option value='cost'>Member Cost</option>
                    <option value='assortmentnbr'>Assortment Number</option>
                </select>
                <select className='sort-order combo' onChange={this.changeSortDirection} value={this.sortDirection}>
                  <option value='asc'>Ascending</option>
                  <option value='desc'>Descending</option>
                </select>
              </div>
              <Responsive minWidth={1024} id='toggle-view'>
                <div className='toggle-view-icon'>
                  <i
                    className='list icon cursor_p'
                    value='List'
                    id='list_icon'
                    onClick={() => this.toggleView('list')}
                  ></i>
                  |
                  <i
                    className='th icon cursor_p'
                    value='Grid'
                    id='grid_icon'
                    onClick={() => this.toggleView('grid')}
                  ></i>
                </div>
              </Responsive>
            </div>
            <div className='ui two column grid'>
              <div className={window.innerWidth < 768 ? 'ui sixteen wide column im-filter' : 'ui three wide column im-filter' }>
                <ImFilters
                  clearFilters={this.state.clearFilters}
                  showFilters={showFilters}
                  isNoProducts={false}
                  toggleShowFilter={this.toggleShowFilter}
                  filtersApplied={false}
                  callback={this.filterCallback}
                  appliedFilters={this.state.appliedFilters}
                />
              </div>
              <div className={window.innerWidth > 768 ?`ui ${showFilters ? 'thirteen':'sixteen'} wide column` : `ui sixteen wide column` } id='ImWidth'>
              {this.state.POGs && this.state.POGs.length === 0 ?
              (
                <div className='no_prdcts_display'>
                  <h1>No Products to Display!</h1>
                </div>
              ): 
              <div>
                {this.state.isListView ? (
                  <>
                    {POGs &&
                      POGs.map(pog => {
                        return (
                          <POGListCard
                            pog={pog}
                            viewItems={this.viewItems}
                            multiStoreDetails={this.props.multiStoreDetails}
                            getprefer={this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView : 'c1'}
                            viewType={this.props.viewType}
                            backPath = {this.state.backPath}
                          />
                        );
                      })}
                  </>
                ) : (
                  <div className='ui stackable three column grid'>
                    {POGs &&
                      POGs.map(pog => {
                        return (
                          <POGCard
                            pog={pog}
                            viewItems={this.viewItems}
                            multiStoreDetails={this.props.multiStoreDetails}
                            getprefer={this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView : 'c1'}
                            viewType={this.props.viewType}
                            backPath = {this.state.backPath}
                          />
                        );
                      })}
                  </div>
                )}
                </div>
              }
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    ImStore: state.ImReducer,
    getprefer: state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign({ dispatch }, bindActionCreators({ getPOGs }, dispatch));
};

export const computeGrossMargin = (selling, cost) => {
  let margin = 0;
  try {
    if (selling > 0) {
      margin = parseFloat(((selling - cost) / selling) * 100).toFixed(2);
    }
  } catch (e) {
    margin = 0;
  }
  return margin === 0 || margin === 0.0 ? '-' : margin + '%';
};

const POGCard = ({ pog, viewItems,multiStoreDetails,getprefer,viewType,backPath }) => {
  return (
    <div className='column'>
      <div className='pog-card'>
        <p className='pog-name'>{pog.Display_Name}</p>
        <p className='pog-id' onClick={() => viewItems(pog)}>
          {pog.Ordering_FileName}
        </p>
        <div className='field-layout'>
          <p>
            <span className='field-name'>SKUs</span>
            <span className='field-val'>{pog.nbrSkus}</span>
          </p>
          {(multiStoreDetails.access !== 1  || (multiStoreDetails.viewPOG && multiStoreDetails.PogMbr)) && (
            <p>
              <span className='field-name'>Member Cost</span>
              <span className='field-val'>
                <MaskPrice
                getprefer={getprefer}
                viewType={viewType}
                fieldVal={pog.Member_Cost}
                field='Member Cost'
                />
              </span>
            </p>
          )}
          <p>
            <span className='field-name'>Gross Margin</span>
            <span className='field-val'>
              {computeGrossMargin(pog.selling, pog.Member_Cost)}
            </span>
          </p>
        </div>
        <div className='pog-view-assortment'
              onClick={() =>
                utilities.openInNewTab(
                  'https://apps.membersonline.com/VisualMerchandising/catalog/VisMerch/' +
                    pog.PDF_Filename1
                )
              }
        >
          View Assortment
          <i className='file pdf icon'
            style={{ cursor: 'pointer', verticalAlign: 'initial' }}
          />
        </div>
      </div>
      <button className='red ui fluid button im-view-items-btn'
        onClick={() => viewItems(pog,backPath)}
      >
        View/Order Items
      </button>
    </div>
  );
};

const POGListCard = ({ pog, viewItems,multiStoreDetails,getprefer,viewType,backPath }) => {
  return (
    <div className='pog-list-card'>
      <div className='pog-list-head'>
        <p className='pog-list-name'>{pog.Display_Name}</p>
        <p className='pog-list-id' onClick={() => viewItems(pog)}>
          {pog.Ordering_FileName}
        </p>
        <div
          className='pog-list-view-assortment'
          onClick={() =>
            utilities.openInNewTab(
              'https://apps.membersonline.com/VisualMerchandising/catalog/VisMerch/' +
                pog.PDF_Filename1
            )
          }
        >
          View Assortment
          <i
            className='file pdf icon'
            style={{ cursor: 'pointer', verticalAlign: 'initial' }}
          />
        </div>
      </div>
      <div className='pog-list-field-layout'>
        <span className='pog-list-field'>
          <span className='list-field-key'>SKUs : </span>
          <span className='list-field-val'>{pog.nbrSkus}</span>
        </span>
        {(multiStoreDetails.access !== 1  || (multiStoreDetails.viewPOG && multiStoreDetails.PogMbr)) && (
          <>
            <span className='pog-list-field'>
              <span className='list-field-key'>Member Cost : </span>
              <span className='list-field-val'> 
                <MaskPrice
                getprefer={getprefer}
                viewType={viewType}
                fieldVal={pog.Member_Cost}
                field='Member Cost'
                />
              </span>
            </span>
          </>
        )}
        <span className='pog-list-field'>
          <span className='list-field-key'>Gross Margin : </span>
          <span className='list-field-val'>
            {computeGrossMargin(pog.selling, pog.Member_Cost)}
          </span>
        </span>
      </div>
      <button className='red ui button im-list-view-items-btn'
              onClick={() => viewItems(pog,backPath)}>
        View/Order Items
      </button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(POG);