import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import utilities from '../../utils/utilities';

export const BusinessRules = {
  suggestedRetailPriceForPdp: (product, variableRetail, viewType, multiStoreDetails, getpreferValue) => {
    const srCost = product.pricing ? product.pricing.srCost : '0';
    
    let label = 'Suggested Retail'  
    if (viewType === CUSTOMER_VIEW){
      if(getpreferValue === 'c2') {
        label = 'SR';
      }
      if(multiStoreDetails.access === 1){
          if( multiStoreDetails.CatMbr === true && product.pricing && 
            utilities.zeroForNull(variableRetail) >= product.pricing.srCost) {
            return [{
              label,
              price:utilities.zeroForNull(variableRetail)
            }];
          } else {
            return [{
              label,
              price:srCost
            }];
          }
      }
      else{
        return utilities.zeroForNull(variableRetail) >= srCost
                ?[{
                  label:`${label}(VR)`,
                  price:variableRetail
                }]:[{
                  label:`${label}`,
                  price:srCost
                }]
      }
    }

    if (viewType === MEMBER_VIEW) {
        if ((multiStoreDetails.access === 1 && multiStoreDetails.CatMbr === false) 
              && (product.pricing && utilities.zeroForNull(variableRetail) >= product.pricing.srCost)) {
                if(parseFloat(product.altsrCost) === 0 ){
                  return [{ label,
                            price : utilities.zeroForNull(variableRetail)>srCost
                                    ? ""
                                    : srCost
                          }];
                }else {
                  return [{
                            label:`${label} for Box `,
                            price : srCost
                          },{
                            label:`${label} for ${product.uom_code} `,
                            price: utilities.zeroForNull(variableRetail)>product.altsrCost
                                    ? ""
                                    : product.altsrCost
                          }];
                }
        } else {
          if(parseFloat(product.altsrCost) === 0){
            return [{
                label,
                price : product.pricing?product.pricing.srCost:''
              }];
          } else {
            return [{
                label:`${label} for Box `,
                price : srCost
              },{
                label:`${label} for ${product.uom_code} `,
                price: product.altsrCost
              }];
          }
        }
      }
  },

  suggestedRetailPrice : (product, variableRetail, viewType, multiStoreDetails) => {
    let price = 0;    
    if (viewType === CUSTOMER_VIEW){
      if(multiStoreDetails.access === 1){
        if( multiStoreDetails.CatMbr === true){
            if (product.pricing && utilities.emptyForNull(variableRetail) >= product.pricing.srCost) {
              price = utilities.emptyForNull(variableRetail);
            } else {
              price = product.pricing ? product.pricing.srCost : '0';
            }
        }
        else{
          price = product.pricing ? product.pricing.srCost : '0';
        }
      }
      else{
        if (utilities.emptyForNull(variableRetail) > 0) {
          price = utilities.emptyForNull(variableRetail);
        } else {
          price = product.pricing ? product.pricing.srCost : '0';
        }
      }
    }

    if (viewType === MEMBER_VIEW) {
      if ((multiStoreDetails.access === 1 && multiStoreDetails.CatMbr === false) 
            && (product.pricing && utilities.emptyForNull(variableRetail) >= product.pricing.srCost)) {
        price = '0';
      } else {
        price = product.pricing ? product.pricing.srCost : '0';
      }
    }
    return price;
  },

  suggestedRetailPriceForPlp : (product, variableRetail, viewType, multiStoreDetails) => {
    let price = 0;    
    if (viewType === CUSTOMER_VIEW){
      if(multiStoreDetails.access === 1){
          if( multiStoreDetails.CatMbr === true 
                && product && utilities.zeroForNull(variableRetail) >= product.SuggestedRetail) {
                price = utilities.zeroForNull(variableRetail);
          } else {
            price = product?.SuggestedRetail ? product?.SuggestedRetail : product?.srCost;
          }
      } else {
        if (utilities.zeroForNull(variableRetail) > 0){
          price = utilities.zeroForNull(variableRetail);
        } else {
          price =  product?.SuggestedRetail ? product?.SuggestedRetail : product?.srCost;
        }
      }
    }

    if (viewType === MEMBER_VIEW) {
      if ((multiStoreDetails.access === 1 && multiStoreDetails.CatMbr === false) 
            && (product && utilities.zeroForNull(variableRetail) >= product.SuggestedRetail)) {
        price = '';
      } else {
        price =  product?.SuggestedRetail ? product?.SuggestedRetail : product?.srCost;
      }
    }
    return price;
  }

    // suggestedRetailPriceForPlp : (product, variableRetail, viewType, multiStoreDetails) => {
    //   let price = 0;    
    //   if (viewType === CUSTOMER_VIEW ||
    //     (multiStoreDetails.access === 1 && multiStoreDetails.CatMbr === false)) {
    //     if (!(multiStoreDetails.access === 1 && multiStoreDetails.CatMbr === false)) {
    //       if (product && utilities.emptyForNull(variableRetail) >= product.SuggestedRetail) {
    //         price = utilities.emptyForNull(variableRetail);
    //       } else {
    //         price = product ? product.SuggestedRetail : "";
    //       }
    //     }
    //     if (product.altsrCost === 0) {
    //       if (utilities.emptyForNull(variableRetail) > 0 &&
    //         multiStoreDetails.access !== 1) {
    //         price = utilities.emptyForNull(variableRetail);
    //       }
    //     } else {
    //       if (multiStoreDetails.access !== 1) {
    //         price = product ? product.SuggestedRetail : "";
    //       }
    //       if (utilities.emptyForNull(variableRetail) > 0) {
    //         price = utilities.emptyForNull(variableRetail);
    //       } else {
    //         price = product.altsrCost;
    //       }
    //     }
    //   }
  
    //   if (viewType === MEMBER_VIEW) {
    //     if ((multiStoreDetails.access === 1 && multiStoreDetails.CatMbr === false)
    //           &&(product && utilities.emptyForNull(variableRetail) >= product.SuggestedRetail)) {
    //         price = "";
    //     } else {
    //       price = product ? product.SuggestedRetail : "";
    //     }
    //   }
    //   return price;
  
    // }
}

export default BusinessRules;
