import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Grid ,Loader} from 'semantic-ui-react';
import {departmentUrl} from '../../urlConstants';
import {restUtils} from '../../utils/restUtils';
import InternalServerError from '../ErrorPages/500_error_page';

class PlanogramLinks extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading : false,
      planogramData : [],
      serverError : false,
      gridBucketSize : 5
    }

  }

  componentDidMount() {
    this.setState({ loading: true });
    restUtils
    .getData(`${departmentUrl}?type=PLANO`)
    .then((response) => {
      const { data } = response;
      let departmentList = []
      data.length > 0 && data.forEach((d) => {
        const departmentName = `${d.Name} (${d.Id})`;
        departmentList.push(departmentName)
      })
      const buckets = this.createBucketForPlanogram(departmentList);
      this.setState({...this.state,
        planogramData:buckets||[],
        loading: false,
        serverError: false
      })
    })
    .catch((error) => {
      console.log(error);
      this.setState({ loading: false, serverError: true});
    });
  }

  createBucketForPlanogram = ( bucketData )=>{
    let i = 0;
    const bucket = [];
    while(i<bucketData.length)
    {
      if(i+this.state.gridBucketSize <= bucketData.length )
      {
           bucket.push(bucketData.slice(i,i+this.state.gridBucketSize));
         i = i+this.state.gridBucketSize
      }
      else{
        bucket.push(bucketData.slice(i));
        i = i+this.state.gridBucketSize;
      }
    }
    return bucket;
  }
  render() {
    return (
      <Segment className='carousel_main' vertical>
        { this.state.serverError ? <InternalServerError/> :
       <>
      <Grid verticalAlign='middle' stackable columns={3}>     
          <Grid.Row columns={5}>
            <Grid.Column  width={1}></Grid.Column>
            <Grid.Column className="planogram_link_title text-align-left">
               <h2>Planograms</h2>
              {this.state.loading && (
	               <div>
                 <Loader active />
               </div>
             )}        
                </Grid.Column >               
            </Grid.Row>
        </Grid>
        {
	         this.state.planogramData.map((bucket,index)=>{
           return (
        <Grid verticalAlign='middle' className="planogram_padding_top" stackable columns={3}>
             <Grid.Row columns={this.state.gridBucketSize} >
            <Grid.Column  width={1}></Grid.Column>
            <Grid.Column  width={14}>
            <Grid verticalAlign='middle' stackable columns={this.state.gridBucketSize}>
                    {
	                      bucket.map((bucketItem,index)=>{
                         return (
                <Grid.Column className="planogram_link_border_box">
                <Segment className='link-segment-height'>
                <Link to={`/planograms/${encodeURI(bucketItem.split(' (')[0] ?? bucketItem)}`} className="planogram_link_color">{bucketItem}</Link>
                  </Segment>
                </Grid.Column>
                  )
                })
            }
              </Grid>
              </Grid.Column>
            <Grid.Column  width={1}></Grid.Column>
            </Grid.Row>
            </Grid>
             );
            })
            } 
          </>
          }  
          
    </Segment>
    );
  }
}
export default PlanogramLinks
