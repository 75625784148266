
import React from 'react';
import utilities from '../../../utils/utilities';
import { CUSTOMER_VIEW } from '../../../components/NavBar/menuConstants';
import { MaskPrice } from '../../common/MaskPrice';
import {ASSORTMENT_UNAVAILABLE_TEXT, NOT_ELIGIBLE_TEXT} from '../../common/constants';

export const ReunionAssortmentList = ({ assortment, endDate, showAssortmentList, backPath, shoppingAreaName, eventId, shoppingAreaId, shoppingAreaMetaId,flowType, promoName, fromReunionHome }) => {
    return (
      <div className='column' id='retail_landing'>
        <div className='ui segment card_item col_height' id='grid_segmentRetail'>
          <div className='assortments_card'>
            <div>
              <div>
                <span className='assortmentHeadingList'>{assortment.shortDesc}</span>
                <span>Assortment Model#:</span>&emsp;
                <span className='model_name' onClick={() => showAssortmentList(assortment, endDate, backPath, shoppingAreaName, eventId, shoppingAreaId,shoppingAreaMetaId, flowType, promoName, fromReunionHome)}>
                  <u>{assortment.modelNbr}</u>
                </span>
                <span className='retail_model'> 
                  <span>Dating: &ensp;
                    {assortment.datingTerms > 0 ? assortment.datingTerms : 0 } Days 
                  </span>
                  <span className='assortment-model' >POG: &ensp;
                    {assortment.planoNum ? assortment.planoNum :'N/A'}
                  </span>
                  <span className='pogLayout '>
                  <span>View Assortment: </span>
                  {assortment.planoNum ? 
                  <i className='file pdf icon large planogramexportie' id='export_btns' 
                     onClick={() => utilities.openInNewTab(assortment.planoNum?parseInt(assortment.planoNum.length)>=9?
                      utilities.getplanogramPdf(assortment.planoNum):
                      utilities.getImpulsePdf(assortment.planoNum)
                    :'')} />
                  :'N/A'}
                </span>
                </span>
              </div>
            </div>
            <div className='dating_and_btnList'>
              <span>Savings:&ensp;
              {!utilities.isEmptyOrNullString(assortment.orderedModelNbr) 
                ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                     {utilities.notEligibleText(NOT_ELIGIBLE_TEXT, assortment.orderedModelNbr)}
                  </span> 
                  : !utilities.isEmptyOrNullString(assortment.orderedDate) 
                    ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                      {'Credit Redeemed '+ assortment.orderedDate}
                      </span>
                    : assortment.raMarketFlag === 'Y' ?
                      <span className='credit_msg_color red_color '>{'Save ' +assortment.savings + ' with $' + 
                        utilities.hyphenForNull(assortment.rebateAmt && assortment.rebateAmt != 'NaN' ?  parseFloat(assortment.rebateAmt).toFixed(2) : '')}</span>
                    : <span className='red_color'>{assortment.save}</span>
              }
              </span>
              {assortment.itemRestricted &&
                <span className='retail_model Assortmentfield credit_msg_color'>Assortment is Restricted.
                  {assortment.itemRestrictedAlternateNbr && assortment.itemRestrictedAlternateNbr!=='' && 
                    <>&ensp;{assortment.itemRestrictedAlternateNbr} is an alternate assortment.</>
                  }
                </span>            
              } 
              <button className='red fluid ui button' id='assortment-btn' 
                      onClick={() => showAssortmentList(assortment, endDate, backPath, shoppingAreaName, eventId, shoppingAreaId,shoppingAreaMetaId, flowType, promoName, fromReunionHome)}>
                  View / Order Assortment
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}

export const  ReunionAssortmentGrid = ({ assortment, endDate, showAssortmentList, backPath, shoppingAreaName, eventId, shoppingAreaId,shoppingAreaMetaId, flowType, promoName, fromReunionHome }) => {
    return (
      <div className='column' id='Assortment_landingGrid'>
        <div
          className='ui segment card_item col_height assortment_padding'
          id='Assortmentgrid_segment'
        >
          <div className='assortments_card'>
            <p className='assortment_heading'>
              {assortment.shortDesc &&
                (assortment.shortDesc.length < 45
                  ? assortment.shortDesc
                  : assortment.shortDesc.substr(0, 40) + ' ...')}
            </p>
            <div className='margin-leftRA'>
              <span>Assortment Model#:</span> &emsp;
              <span
                className='model_name'
                onClick={() => showAssortmentList(assortment, endDate, backPath, shoppingAreaName, eventId, shoppingAreaId,shoppingAreaMetaId, flowType, promoName, fromReunionHome)}
              >
                <u>
                  {assortment.modelNbr && (
                    <u>
                      {utilities.checkAssortmentMdlNumLength(assortment.modelNbr)}
                    </u>
                  )}
                </u>
              </span>
            </div>
            <div className='margin-leftRA'>
            <span>POG:</span> &emsp;
                <span>  {assortment.planoNum ? assortment.planoNum :'N/A'}
              </span>
            </div>
            <div className='margin-leftRA'>
            <span>View Assortment: </span> &emsp;
                  <span>
                  {assortment.planoNum ?
                  <i className='file pdf icon large planogramexportie' id='export_btns' 
                     onClick={() => utilities.openInNewTab(assortment.planoNum?parseInt(assortment.planoNum.length)>=9?
                      utilities.getplanogramPdf(assortment.planoNum):
                      utilities.getImpulsePdf(assortment.planoNum)
                    :'')}/>
                  :'N/A'}
                </span>
            </div>
            <div className='margin-leftRA'>
              <span>Dating: </span> &emsp;
              <span>{assortment.datingTerms > 0 ? assortment.datingTerms : 0 } Days</span>
            </div>
            <div className='margin-leftRA'>
              <span>Savings:</span> &emsp;
              {!utilities.isEmptyOrNullString(assortment.orderedModelNbr) 
                ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                    {utilities.notEligibleText( NOT_ELIGIBLE_TEXT, assortment.orderedModelNbr)}
                  </span> 
                  : !utilities.isEmptyOrNullString(assortment.orderedDate) 
                    ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                      {'Credit Redeemed '+ assortment.orderedDate}
                      </span>
                    : assortment.raMarketFlag === 'Y' ?
                      <span className='credit_msg_color red_color'>{'Save ' +assortment.savings + ' with $' + 
                      utilities.hyphenForNull(assortment.rebateAmt && assortment.rebateAmt != 'NaN' ? parseFloat(assortment.rebateAmt).toFixed(2) : '')}</span>
                    : <span className='red_color'>{assortment.save}</span>
              }
            </div>
            <br/>
            {assortment.itemRestricted && 
              <div className='margin-leftReunion'>
                <span className='Assortmentfield credit_msg_color'>Assortment is Restricted.
                  {assortment.itemRestrictedAlternateNbr!==0 && 
                    <>&ensp;{assortment.itemRestrictedAlternateNbr} is an alternate assortment.</>
                  }
                </span>
              </div>
            } 
          </div>
          <button
                className='fluid ui red button'
                onClick={() => showAssortmentList(assortment, endDate, backPath, shoppingAreaName, eventId, shoppingAreaId,shoppingAreaMetaId, flowType, promoName, fromReunionHome)}
              >
                View / Order Assortment
            </button>
        </div>
      </div>
    );
};

export const AssortmentDetails = ({assortment ,viewType,multiStoreDetails,getprefer,regCost, endDate, offerEndDateHide = false}) => {
  let savinCalc = parseInt(utilities.hyphenForZero(assortment && assortment.rebateAmt)) /  parseInt(utilities.emptyStringForZero(assortment.regCost));
  let rebateCreditFlash = isNaN(savinCalc) ? 0 : parseFloat(savinCalc).toFixed(2);
  const  getSavingsFlash = 
   !utilities.isEmptyOrNullString(rebateCreditFlash) ? (
      (parseFloat(rebateCreditFlash) * 100).toFixed(2) + ' %'
    ) : '';
  
  return (
    <>
      <div className='pl-specs'>
        <div>
          {(multiStoreDetails.access !== 1  || (multiStoreDetails.viewPOG && multiStoreDetails.PogMbr )) &&
          <>
            <span className="ralist-cost">
              <span className='pl-card-name'>Member Cost:</span>
              <span className='pl-card-field Assortmentfield'>&ensp;
                <MaskPrice
                  getprefer={getprefer}
                  viewType={viewType}
                  fieldVal={
                    viewType !== CUSTOMER_VIEW
                      ? assortment.raMarketFlag === 'Y'
                        ? utilities.emptyStringForZero(assortment.regCost)
                        : utilities.emptyStringForZero(regCost)
                      : utilities.emptyStringForZero(assortment.srpCost)
                  }
                  field='Member Cost'
                />
              </span>
            </span>
          </>
        }
        <span className="ralist-savings">
          <span className='pl-right-card-name SavingsPadding'>Savings:</span>
          {
            (!utilities.isEmptyOrNullString(assortment.orderedDate) || (assortment.overridedAssortmentFromCart > 0
              && assortment.overridedAssortmentFromCart !== assortment.number))
              ? <span className='pl-card-field Assortmentfield red_color'>&ensp;{'N/A'}</span>
              : <span className='pl-card-field Assortmentfield red_color'>&ensp;
                    {utilities.PercentageSavings(assortment.savings)}
              </span>
          }
        </span>
        <span className="ralist-delivery">
          {assortment.raMarketFlag !== 'Y'
            ? <>
              <span className='pl-card-name'>Delivery:</span>
              <span className='pl-card-field Assortmentfield'>&ensp;
                    {utilities.hyphenForNull(assortment.delivery)}
              </span>
            </>
            : ''
          }
        </span>
      </div>
      <div>
        <span className="ralist-creditamount">
          <span className='pl-card-name'>Credit Amount:</span>
          {!utilities.isEmptyOrNullString(assortment.orderedModelNbr)
            ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                {'Not Eligible for Credit, Previously Purchased ' + assortment.modelNbr + '.'}
            </span>
            : !utilities.isEmptyOrNullString(assortment.orderedDate)
              ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                  {'Credit Redeemed ' + assortment.orderedDate + '(' + assortment.rebateAmt + ')'}
              </span>
              : assortment.userQty > 0
                ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                    {'Credit and dating will only apply  to one assortment per retail assortment category.'}
                </span>
                : <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                    {'$' + utilities.hyphenForNull(assortment.rebateAmt && assortment.rebateAmt != 'NaN' ? parseFloat(assortment.rebateAmt).toFixed(2) : '')}
                </span>
          }
        </span>
        <span className="ralist-dating">
          <span className='pl-card-name'>Dating:</span>
          {(!utilities.isEmptyOrNullString(assortment.orderedDate) || (assortment.overridedAssortmentFromCart > 0
            && assortment.overridedAssortmentFromCart !== assortment.number))
            ? <span className='pl-card-field Assortmentfield'>&ensp;{'N/A'}</span>
            : <span className='pl-card-field Assortmentfield'>&ensp;
                  {utilities.hyphenForNull(assortment.datingTerms)} Days
                </span>
          }
        </span>
        { assortment?.categoryName !== 'Retail Assortments' ? 
        <span className="ralist-offerends">
          <span className='pl-card-name'>Offer Ends: </span>
          {offerEndDateHide ? '' : utilities.isEmptyOrNullString(assortment.discontinueDt) && assortment.lrCertified === 'Y'
            ? <span className='pl-card-field Assortmentfield'>&ensp;on Going</span>
            : utilities.isEmptyOrNullString(assortment.discontinueDt) && (assortment.lrCertified === 'N')
              ? <span className='pl-card-field Assortmentfield'>&ensp;{endDate}</span>
              : <span className='pl-card-field Assortmentfield'>&ensp;{assortment.discontinueDt}</span>
          }
        </span>
        :
        <></>
        }
      </div>
    </div>
  </>
);
};
