import React, { Component } from 'react';
import restUtils from "../../../utils/restUtils";
import { MONTHLY_SPECIALS } from "../../../urlConstants";
import _ from 'lodash';
import utilities from "../../../utils/utilities";
import config from '../../../config';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

export const getMonthlyFilterString = function( appliedFilters, selectedVendorname ) {
  let filterList = [];
  if(!appliedFilters.hasOwnProperty("Vendor Name") && selectedVendorname) {
    filterList.push({
      name:"Vendor Name",
      valueList:[selectedVendorname]
    })
  }
  for(let name in appliedFilters) {
    if (appliedFilters.hasOwnProperty(name)) {
      filterList.push({
        name,
        valueList: appliedFilters[name]
      })
    }
  }
  return filterList
}
export const getRequestPayload = function(isTotalList){
  const { relayNbr, 
    promoNbr, 
    vendorid
  } = this.state.vendorDetails?this.state.vendorDetails:{};
  const { page, pageSize, isAllItems,eventId,eventIdReunion,sortBy,sortOrder} = this.state;
  const filterList = !_.isEmpty(this.filterString)?this.filterString:null;
  const msCarts = this.props.currentOrders.allCarts.filter((c) => c.cartInfoName === 'MS');
  const currentCart = msCarts.length > 0 ? msCarts[0]?.cartId :  this.props.currentCartId;
  let requestPayload = {
      memNbr: this.props.storeId, 
      searchby: utilities.isEmptyOrNullString(this.searchvalue)?null:this.searchby,
      searchvalue:this.searchvalue,
      promoNbr: isAllItems?eventId:promoNbr,
      relayNbr: isAllItems?eventIdReunion:relayNbr,
      VendorID: isAllItems?null:vendorid,
      sortBy : utilities.isEmptyOrNullString(this.sortParam)
                ? this.state.isWow?'seqno': sortBy
                : this.sortParam ,
      sortOrder : utilities.isEmptyOrNullString(this.sortDirection)
                  ? sortOrder
                  : this.sortDirection,
      page: !isTotalList && this.page && this.page.length > 0 
                ? this.page.split('=')[1]
                : '1',
      resultPerPage: isTotalList?'*'
                        :this.pageSize && this.pageSize === '100' ? '100' : '50',
      rdcNbr: this.props.rdc,
      cartID : currentCart,
      isItemDtlFromWH: false,
      aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID,
      MouseOverBy: isAllItems?null: {
                                      VendorID : vendorid
                                    }
  }

  if(utilities.msExist(this.props.multiStoreDetails) > 1 && this.props.currentCartId){
    requestPayload = {...requestPayload,multiStoreCartId: currentCart}
  }

  if(filterList) {
    requestPayload = {
      ...requestPayload, 
      "FilterBy": filterList
    }
  }
  return requestPayload;
}
export const fetchMonthlyProductsList = function(newProps) {
  let url = `${MONTHLY_SPECIALS}/GetMSItems`;
  const requestPayload = this.getRequestPayload();
  const { favorites } = this.props;
  this.setState({loading:true});
  restUtils
    .postData(url, requestPayload)
    .then(
      response => {
        if(_.isEmpty(response.data) || !response?.data?.MSItemList?.length) {
            this.setState({
              isNoProducts:true,
              loading:false,
              totalItems:0,
              firstItem:0,
              lastItem:0,
              totalPages:0,
              currentPage:0,
              categoryData:[]
            })
            return;
        }
        let categoryData = response.data.MSItemList.map(productData => {
        const favorited = favorites.indexOf(parseInt(productData.Item_Number)) >-1;
          return {
            ...productData,
            selected: false,
            favorited
          };
        });
        const totalPages = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].totalPages):0;
        const currentPage = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].currentPage):0;
        const firstItem = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].firstItem):0;
        const lastItem = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].lastItem):0;
        const totalItems = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].totalItems):0;
        this.setState({
          categoryData,
          loading: false,
          isNoProducts: !categoryData || categoryData.length === 0,
          totalItems,
          firstItem,
          lastItem,
          totalPages,
          currentPage,
          filters : response.data.filters,
          quantities :categoryData.map((product) => { 
            return [
              utilities.emptyForNull(product.userQty),
              utilities.emptyForNull(product.userQty2),
              utilities.emptyForNull(product.userQty3),
              utilities.emptyForNull(product.userQty4)
            ]
          }),
        });
        this.setBackButtonPath();
      }
  ).catch(error=>{
    console.log(error);
    this.setState({loading:false,serverError:true});
  })
}
export const fetchCompleteList = function(){
  let url = `${MONTHLY_SPECIALS}/GetMSItems`;
  const payload = this.getRequestPayload(true);
  utilities.showToast("Exporting to Excel...")
  restUtils
    .postData(url,payload)
    .then(response =>{
      let itemsList = response?.data?.MSItemList;
      if(!itemsList||itemsList.length===0){
        utilities.showToast("No products available.",true);
        return;
      }
      const preference = this.props.getprefer &&this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
      itemsList = itemsList.map((product)=>{
        const futureCost = (product.future_cost && parseInt(product.future_cost)) !== 0 ? product.future_cost : product.Cost; 
        let row = {};
          row = {...row, ...{'Item Number': utilities.emptyForNull(product.Item_Number)}};
          if(!utilities.isEmptyOrNullString(this.props?.aliasSKURetailerGrpID)){
            row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(product.aliasSku)}};
           }
           row = {...row, ...{         
          'Short Description': utilities.emptyForNull(product.shortdesc),
          'Vendor Name': utilities.emptyForNull(product.Vendor_Name),
          'SEQ#': utilities.emptyForNull(product.seq_nbr),
          'Model': utilities.emptyForNull(product.Model),
          'Pack': `${product.S_Pack_Type ? product.S_Pack_Type : ''}${product.S_Pack_Qty ? product.S_Pack_Qty : ''}`,
          'UPC': utilities.emptyForNull(product.UpcNum),
          'Item Description': config.showMediumDesp?
           utilities.emptyForNull(product.Product_Title || product.Long_Description)
           : utilities.emptyForNull(product.Long_Description),
          Pack: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
            product.S_Pack_Qty ? product.S_Pack_Qty : ''
            }`,
            }
          }
          if(this.props.viewType !== MEMBER_VIEW){
            if(preference === 'c3' ||  preference === 'c4'){
              row = {...row}             
            }
            else {
              row = {...row, ...{ 
                'RC': utilities.getCostBasedOnnatvalue(
                  this.props.viewType, 
                  product.future_cost > 0 ? product.future_cost : product.userQty > 0 ? utilities.getCostForQtyPlpNew(product.pricing, product.product) : product.future_cost > 0 ? product.future_cost : product.pricing.Regular_Cost, 
                  preference),
                'EVC': utilities.getCostBasedOnnatvalue(this.props.viewType,product.spc_cost,preference)
                }
              }
            }
          } else {
            row = {...row, ...{ 
              'Regular Cost': utilities.getCostBasedOnnatvalue(
                this.props.viewType, 
                product.future_cost > 0 ? product.future_cost :product.userQty > 0 ? utilities.getCostForQtyPlpNew(product.pricing, product.product) :  product.pricing.Regular_Cost, 
                preference),
              'Regular Cost Tier Indicator': product.pricing.tiered ? 
                ( product.future_cost > 0 ? 'T2' : product.userQty > 0 ? utilities.excelTierFormat(utilities.getCostForQtyPlpNew(product.pricing, product.product, true)): 'T2' ): '',
              'Event Cost': utilities.getCostBasedOnnatvalue(this.props.viewType,product.spc_cost,preference)
              }
            }
          } 
          if(this.props.viewType === MEMBER_VIEW){
              const savingsValue = product.Savings ? product.Savings.split('%')[0] : 0;
              row = {...row, ...{           
                'Savings': utilities.emptyForNull(savingsValue && Number(savingsValue) > 0 ? Number(savingsValue).toFixed(2): '')
              }
            }
          }
          row = {...row, ...{           
          'Ship Week 1': product.shipDate1 && product.shipDate1 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipDate1) : '',
          'Quantity - Ship Week 1': utilities.emptyForNull(product.userQty),
          'Ship Week 2': product.shipDate2 && product.shipDate2 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipDate2) : '',
          'Quantity - Ship Week 2': utilities.emptyForNull(product.userQty2),
          'Ship Week 3': product.shipDate3 && product.shipDate3 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipDate3) : '',
          'Quantity - Ship Week 3': utilities.emptyForNull(product.userQty3),
          'Ship Week 4': product.shipDate4 && product.shipDate4 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipDate4) : '',
          'Quantity - Ship Week 4': utilities.emptyForNull(product.userQty4),
          'Non Stocked': product.stocked && product.stocked !== 'N/A' ? product.stocked : '',         
          'Restricted Product': utilities.itemRestricted(product?.Restrictions),
          'Restriction Message': utilities.itemRestrictionDetails(product?.Restrictions).replace('{@ShowSubscribeLink}','')
            }
          }
        return row;
      });
      utilities.exportToCsv(itemsList,this.state.isWow?'Wow':'MonthlySpecials');
    })
}
export const searchMonthlyItems = function(newProps){
    const { searchby, searchvalue } = newProps?newProps:this.props;
    const { favorites } = this.props;
    const { relayNbr, promoNbr} = this.state.vendorDetails
    const filterList = !_.isEmpty(this.filterString)?this.filterString:null;
    let url = `${MONTHLY_SPECIALS}/GetMSSearch`;
    let requestPayload = {
        searchType: searchby,
        query:searchvalue,
        mbrNbr: this.props.storeId,
        "promoNbr": promoNbr,
        "relayNbr" :relayNbr,
        rdcNbr : this.props.rdc,
        cartID:this.props.currentCartId,
        sortBy : utilities.isEmptyOrNullString(this.sortParam)? 
                                this.state.isWow?'seqno':'item_nbr'
                                :this.sortParam ,
        sortOrder : utilities.isEmptyOrNullString(this.sortDirection)
                    ? "A"
                    : this.sortDirection,
        "resultperpage":this.pageSize,
        "isItemDtlFromWH":false,
        "aliasSKURetailerGrpID" : this.props.aliasSKURetailerGrpID,
    }
    if(utilities.msExist(this.props.multiStoreDetails) > 1 && this.props.currentCartId){
      requestPayload = {...requestPayload,multiStoreCartId: this.props.currentCartId}
    }
    if(filterList){
      requestPayload = {...requestPayload, "FilterBy":filterList}
    }
    this.setState({loading:true})
    restUtils
      .postData(url,requestPayload)
      .then(
          response=>{
            if(_.isEmpty(response.data) || response.data==='[]') {
              this.setState({
                isNoProducts:true,
                loading:false
              })
              return;
            }
            let categoryData = response.data.MSSearchList.map(productData => {
            const favorited = favorites.indexOf(parseInt(productData.Item_Number)) >-1;
              return {
                  ...productData,
                  selected: false,
                  favorited
              };
            });
            const totalPages = parseInt(response.data && response.data.pagination && response.data.pagination[0].totalPages);
            const currentPage = parseInt(response.data && response.data.pagination && response.data.pagination[0].currentPage);
            const firstItem = parseInt(response.data && response.data.pagination && response.data.pagination[0].firstItem);
            const lastItem = parseInt(response.data && response.data.pagination && response.data.pagination[0].lastItem);
            const totalItems = parseInt(response.data && response.data.pagination && response.data.pagination[0].totalItems);
            this.setState({
              categoryData,
              loading: false,
              isNoProducts: !categoryData || categoryData.length === 0,
              totalItems,
              firstItem,
              lastItem,
              totalPages,
              currentPage,
              filters : response.data.filters,
              quantities :categoryData.map((product) => { 
                return [
                  utilities.emptyForNull(product.userQty),
                  utilities.emptyForNull(product.userQty2),
                  utilities.emptyForNull(product.userQty3),
                  utilities.emptyForNull(product.userQty4)
                ]
              }),
            });
            this.setBackButtonPath();
        }
      ).catch(error=>{
        console.log(error);
        this.setState({loading:false,serverError:true});
      })
}