import restUtils from '../../utils/restUtils';
export const CHECK_IMAGE = 'CHECK_IMAGE';
export const CHANGE_CAMERA = 'CHANGE_CAMERA';
export const checkImageScanner = (url,dataUri) => {
  return (dispatch) => {
    const postObj = 
      {
        image: dataUri 
      }
    restUtils
      .postData(
        url,
        postObj
      )
      .then(response => {
        return dispatch({
          type: CHECK_IMAGE,
          payload: response.data
          
        });
       
      });
  };
  };
  export const changeCamera = (id) => ({type: CHANGE_CAMERA,payload: id})

  

