import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Menu, { SubMenu, Item as MenuItem } from "rc-menu";
import _ from "lodash";
import { bindActionCreators } from 'redux';
import { setEntrieMenu } from '../../views/Login/actions';
import { setWpMenu, setBussinessToolMenu} from './actions'
import "rc-menu/assets/index.css";
import "./flyover.css";
import { restUtils } from "../../utils/restUtils";
import { downloadExcel } from '../../views/common/GenericExcelExport/exportUtils'
import {
  fetchDepartmentData,
  getPathArray,
  getPathFromHistory,
  getPlanogramsFlyout,
  getRetailAssortmentsFlyout,
  getCtbAssortmentsFlyout,
  getShoppingAreaFlyout,
  fetchWpMenuData,
  updateNnwhProductMenu,
  processBussinessMenu
} from "./navUtils";
import {
  SHOPPING_AREA_LIST,
  departmentUrl,
  planogramsUrl,
  GET_CART_INFO,
  getMRIUri,
  WORDPRESSMENU,
  GET_APP_LISTINFO,
  getRedirectUri
} from "../../urlConstants";
import {
  mainMenu,
  urlToMenuKeyMapper,
  VIEW_ACCESS,
  NOT_POG_VIEW,
  DEPARTMENT_ACCESS,
  VENDFAVACCESS,
  FINELINE_CATALOG_TITLE,
  videoLinks,
} from "./menuConstants";
import { RETAILASSORTMENT, CTBASSORTMENT, GET_SSO_GUID } from "../../urlConstants";
import utilities, { saleType } from "../../utils/utilities";
import config from "../../config";
import { CORPORATE_EBOOK } from "../../views/CorporateEbook/constants";
import { REUNION_APP, REUNION_ORDERING, ADMIN, BUSINESS_TOOLS, TRUE } from "../../views/common/constants";
import {  Modal} from 'semantic-ui-react';
import staticMenu from './menu.json'
class Flyout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: {},
      loading: false,
      selectedKeys: "",
      menu: props.getWpMenu,
      entireMenu: null,
      entireWpMenu: null,
      hasAccessPopUp : false,
      bussinessToolMenu: props.businessToolsMenu,
    };
  }
  componentWillMount() {
    if(_.isEmpty(this.props.getWpMenu)) {
      this.setState({menu: staticMenu})
    }
  }
  componentDidMount() {
    const flyoutMenuDataStorage = localStorage.getItem("flyoutMenuData");
    const flyoutMenuData = flyoutMenuDataStorage ? JSON.parse(flyoutMenuDataStorage) : '';
    if(flyoutMenuData){
      this.props.setWpMenu(flyoutMenuData)       
      this.setState({menu: flyoutMenuData})
    } else {
      if(_.isEmpty(this.props.getWpMenu)) {
        restUtils
        .getDataWithBearer(WORDPRESSMENU)
        .then((response) => {
          const data = response.data
          const updateProductMenu = updateNnwhProductMenu(data)
          localStorage.setItem("flyoutMenuData", JSON.stringify(updateProductMenu));
          this.props.setWpMenu(updateProductMenu)       
          this.setState({menu: updateProductMenu})
        })
        .catch((error) => {
          console.log(error);
          this.props.setWpMenu(staticMenu)       
          this.setState({menu: staticMenu})
          this.setState({ loading: false });
        }); 
      }
    }
      
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      bussinessToolMenu: nextProps.businessToolsMenu
    })
  }
  planogramFlyout = () => {
    restUtils
      .getData(`${departmentUrl}?type=PLANO`)
      .then((response) => {
        const planogramFlyoutMenu = fetchDepartmentData(response, 'planograms');
        this.updateState('Products','Planograms', planogramFlyoutMenu);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  retailAssortmentFlyout = () => {
    const url = `${RETAILASSORTMENT}`;
    restUtils
      .getData(url + `/flyout`)
      .then((response) => {
        const { data } = response;
        const retailAssortmentFlyoutMenu = getRetailAssortmentsFlyout(data);
        this.updateState('Products','Retail Assortments', retailAssortmentFlyoutMenu);

      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };
  uriMRI = () => {
    let url = `${getMRIUri()}`;
    let userName = this.props.userName;
    let storeId = this.props.storeId;
    url = url+`?sn=${storeId}&un=${userName}`;
    utilities.openInsameTab(url);
    }
  ctbAssortmentFlyout = () => {
    const url = `${RETAILASSORTMENT}`;
    restUtils
      .getData(CTBASSORTMENT)
      .then((response) => {
        const { data } = response;
        const ctbAssortmentsFlyoutMenu = getCtbAssortmentsFlyout(data);
        this.updateState('Products','CTB Assortments', ctbAssortmentsFlyoutMenu);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };
  getBussinessMenu = () =>{
  if(_.isEmpty(this.state.bussinessToolMenu)) {
    restUtils.postDataWithBearer(GET_APP_LISTINFO).then(res=>{
      const data = res.data;
     const bussinessToolMenu = processBussinessMenu(data)
      const { menu } = this.state;
      const  updateProductMenu = _.map(menu,(menu => {
      if(menu.title === BUSINESS_TOOLS) {
          menu.submenus  = bussinessToolMenu;
          return menu
      }
    return menu
    }))
      this.setState({menu: updateProductMenu})
     this.props.setBussinessToolMenu(data)
   }).catch(err=>{
     console.log(err)
   })
  } 
  else {
    const bussinessToolMenu = processBussinessMenu(this.state.bussinessToolMenu)
    const { menu } = this.state;
   
    const  updateProductMenu = _.map(menu,(menu => {
    if(menu.title === BUSINESS_TOOLS) {
        menu.submenus  = bussinessToolMenu;
        return menu
    }
  return menu
  }))
    this.setState({menu: updateProductMenu})
  }
  }
  ctbAssortmentOrders = () => {
    let userId = this.props.UserId;
    const multiStoreDetails = this.props.multiStoreDetails;
    if (multiStoreDetails?.childStoreList && Object.keys(multiStoreDetails?.childStoreList).length > 1) {
      let userIds = Object.keys(multiStoreDetails?.childStoreDetails).map(
        (key) => {
          return multiStoreDetails.childStoreDetails[key].userID;
        }
      );
      userId = userIds.join(",");
    }
    restUtils
      .getData(
        GET_CART_INFO + userId + '&getCtbOrdersReport=true'
      )
      .then((response) => {
        if(response?.data?.length >0){
          const fileName = `CTB NW Orders Report-Store-${this.props.storeId}-${utilities.getCurrDateYearFirst(new Date()).split('-').join('')}`
          downloadExcel(response.data, fileName);
          utilities.showToast("CTB NW orders exported.");
        } else {
          utilities.showToast("No CTB NW orders to export.");
        }
      })
      .catch((err) => {
        console.log(err);
        utilities.showToast("Unexpected error occured please try after some time.");
      });
  }
  renderMenu = (menuObj) => {
    const showFinelineCatalog = config.isFinelineCatalogActive;
    const ShowReunion = config.isReunion;
    const showCloseOutCorner = config.showCloseoutCorner;
    const showCorporateEBook = config.isCorporateEBook;
    const vendorfavoriteaccess =
      this.props.multiStoreDetails &&
      ((this?.props?.multiStoreDetails?.viewCat &&
        !this?.props?.multiStoreDetails?.viewPOG) ||
        this?.props?.multiStoreDetails?.access !== 1);
    const csrUser = utilities.checkCsrUser(this.props.multiStoreDetails);
    return(
      _.map(menuObj,(menu=>{
        const { id, title, submenus, isNnwhMenu = false, key = false,url,cms_page,  nnwhKey = false, isbusinessMenu = false, hasAccess = true, slug = '' } = menu;
        if (!showFinelineCatalog && title === FINELINE_CATALOG_TITLE) {
          return;
        }
        if (
          (!showCorporateEBook && title === CORPORATE_EBOOK) ||
          (!csrUser && title === CORPORATE_EBOOK)
        ) {
          return;
        }
        if (
          ((this.props.isReunionLinkEnable && title === REUNION_ORDERING) 
          || title !== REUNION_ORDERING) &&
          this.props.multiStoreDetails &&
          this.props.marketFlag !== undefined &&
          ((ShowReunion && title === REUNION_APP) || title !== REUNION_APP) &&
          ((this.props?.showAdminMenu && title === ADMIN) || title !== ADMIN) &&
          ((this?.props?.multiStoreDetails?.access !== 1 && title === "Orders") ||
            title !== "Orders") &&
          ((vendorfavoriteaccess && VENDFAVACCESS.includes(title)) ||
            !VENDFAVACCESS.includes(title)) &&
          (this?.props?.multiStoreDetails?.access !== 1 ||
            !VIEW_ACCESS.includes(title) ||
            ((this?.props?.multiStoreDetails?.viewPOG ||
              this?.props?.multiStoreDetails?.viewCat) &&
              !NOT_POG_VIEW.includes(title)) ||
            (this?.props?.multiStoreDetails?.viewCat &&
              !this?.props?.multiStoreDetails?.viewPOG &&
              !DEPARTMENT_ACCESS.includes(title))) &&           
          ((showCloseOutCorner && this.props.marketFlag !== undefined &&
            !this.props.marketFlag &&
            title === "Closeout Corner") ||
            title !== "Closeout Corner") &&
          ((this.props.isRetailAssortmentsEnable &&
            title === "Retail Assortments") ||
            title !== "Retail Assortments") &&
          ((this.props.isCtbAssortmentsEnable  &&
            title === "CTB Assortments") ||
            title !== "CTB Assortments") &&
            ((this.props.isMomentumEnable  &&
              title === "Momentum Items") ||
                title !== "Momentum Items") && 
          ((this.props.marketFlag !== undefined &&
            !this.props.marketFlag &&
            title === "Impulse Merchandising") ||
            title !== "Impulse Merchandising")
        ) {
        if ((isNnwhMenu || submenus?.length > 0) && !_.isEmpty(title)) {
          // keyNameSet is to redirect onClick for menus 
          let keyNameSet = title;
          if(cms_page === 'No' && url !== '#'){
            keyNameSet = `${title}/$${url}`
          } else if((cms_page === "" || cms_page !== 'No') && slug){
            keyNameSet = `${title}/$/content/${slug}`
          }
          return (
            <SubMenu
              title={<span className={title}>{title}</span>}
              key={nnwhKey ? key : keyNameSet}
              menuurl={url}
              onTitleClick={this.handleSelect}
              iscmspage ={cms_page}
              isbusinessMenu={isbusinessMenu}
              pageid = {id}
              slug ={slug}
              popupOffset={[0, 0]}
            >
              {this.renderMenu(submenus)}
            </SubMenu>
          );
        }
        if(!_.isEmpty(title)) {
          return (
            <MenuItem
            id={nnwhKey ? key.split("-") ? key.split("-")[0] : key: title}
              key={nnwhKey ? key : title}
              menuurl={url}
              iscmspage ={cms_page}
              pageid = {id}
              slug ={slug}
              hasAccess ={hasAccess}
              isbusinessMenu={isbusinessMenu}
              onClick={this.handleSelect}
            >
              {title}
            </MenuItem>
          );
        }
      }
        return null
       }))
    )
  }
  handleSelect = (info) => {
    const { key = "", domEvent, item={} } = info;
    let url = key.split("$")[1] || "";
    if(item?.props) {
      let  { menuurl, iscmspage, pageid, slug} = item.props
      const isNnwhPage = menuurl.includes(getRedirectUri())
        if(menuurl) {
          url = iscmspage !== 'No' ? `/content/${!_.isEmpty(slug) ? slug : pageid}`: menuurl
        }
        if(iscmspage === 'No' && isNnwhPage) {
          const getPath = menuurl.split(getRedirectUri())
          if(getPath.length > 1) {
            url = getPath[1]
          }
          else {
            url = getPath[0]
          }
        } 
    }
    if (key === "CTB NW Order Report") {
      utilities.showToast("Exporting to Excel...");
      this.ctbAssortmentOrders();
      return
    }

    if (url.startsWith("/")) {
      if (domEvent.target.tagName !== "I") {
        if (url.startsWith("/http")) {
          utilities.openInNewTab(url.slice(1));
        } else {
          if(item?.props?.isbusinessMenu) {
            if(!item?.props?.hasAccess) {
              this.setState({
                hasAccessPopUp: true
              })
              return
            }
            utilities.setLoader(true)
           let menuItem =  _.filter(this.props.businessToolsMenu, (ele=>{
              return ele.Name === decodeURIComponent(item?.props?.id)
            }))
            if(_.isEmpty(item?.props?.id)) {
              utilities.showToast('Data not found')
              return
            }
            const requestPayLoad =  {
              appGuid: menuItem[0].ComponentGuid
           }
          restUtils
            .postDataWithBearer(GET_SSO_GUID, requestPayLoad)
            .then(response => {
            if(response && response.data){
                let processUrl = menuItem[0].Parameters.split('{securityGuid}')
                let url = `${menuItem[0].RedirectUrl}${processUrl[0]}${response.data}${processUrl[1]}`;     
                utilities.openInsameTab(url);   
                utilities.setLoader(false)             
              } else {
                utilities.setLoader(false)
                utilities.showToast('Unable to Authenticate', true)
              }
            })
            .catch(error => {
              console.log(error);
              utilities.setLoader(false)
              utilities.showToast('Unable to Authenticate', true)
            });
          }
          else {
            this.props.history.push(url);
          }
          
          this.props.onAction();
        }
      }
    } else if (url.startsWith("http://help-")) {
      const helpKeyValue = url.split("http://help-")[1];
      this.getVideoModal(helpKeyValue);
    }
    else if(url.startsWith("http")){
      utilities.openInNewTab(url);
    }
    else if (key === "urlMRI") {
      this.uriMRI();
    }
  };

  getVideoModal = (Type) => {
    window.open(videoLinks[Type], "", "width=560,height=320");
  };
  getPopupContainer = (e) => document.getElementById('dropdownMenuWrapper')
  submenuEnter= ()=>{
    document.getElementById('dropdownMenuWrapper').classList.add('setVisiable')
  }
  submenuLeave =() =>{
    document.getElementById('dropdownMenuWrapper').classList.remove('setVisiable')
  }
  onOpenChange = (history) => {
    if (Array.isArray(history)) {
      const { menu } = this.state;
      // After first drill down to leaf node the history object adds extra paths traversed along with the old memory.
      // getPathFromHistory is used to extract current path from full history memorised by Menu.
      const { pathArray, currentHistory } = getPathFromHistory(history);
      const products = _.find(menu, ['title',pathArray[0]])
      const childMenu = _.find(products?.submenus, ['title',pathArray[1]])
      if(childMenu) {
        const { isNnwhMenu = false, submenus =[]} = childMenu
        if(isNnwhMenu && _.isEmpty(submenus)) {
          switch (pathArray[1]) {
            case "Departments":
                this.getDeptFromRestCall();
              break;
            case "Planograms":
              this.planogramFlyout();
              break;
            case "Retail Assortments":
              this.retailAssortmentFlyout();
              break;
            case "CTB Assortments":
              this.ctbAssortmentFlyout();
              break;
            case "urlMRI":
              this.uriMRI();
              break;
            // no default
          }
        }
      }
      else if(pathArray[1]) {
        switch (pathArray[1]) {
          case "Planograms/":
            this.planogramFlyout();
            break;
          case "Retail Assortments/":
            this.retailAssortmentFlyout();
            break;
          case "CTB Assortments/":
            this.ctbAssortmentFlyout();
            break;
          // no default
        }
      }
      /*else if(pathArray[0] === BUSINESS_TOOLS) {
        this.getBussinessMenu()
        return
      }*/

        
      
      
  }
  };
  getDeptFromRestCall(){
    restUtils
      .getData(departmentUrl)
      .then((response) => {
        this.setState({ loading: true });
        let items = {};
        items = fetchDepartmentData(response, 'categories');
        this.updateState('Products','Departments', items);
      }) .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }
  updateState = (parentKey, childKey, value) => {
    const { menu } = this.state;
   const  updateProductMenu = _.map(menu,(menu => {
      if(menu.title === parentKey) {
        const updateDeptMenu = menu.submenus.map((item)=>{
         if(item.title === childKey) {
           return {
             ...item,
             submenus: value
                
              }
            }
            return item
          })
          menu.submenus  = updateDeptMenu;
          return menu
      }
    return menu
    }))
    this.setState({menu: updateProductMenu})
    this.props.setWpMenu(updateProductMenu)
  };
  closePopUp = () =>{
    this.setState({
      hasAccessPopUp: false
    })
  }
  render() {
    const { menu } = this.state;
    const { isMobile, location, monthlySizzleSaleValue } = this.props;
    const urlKey = location.pathname.split("/")[1];
    const activeKey = urlToMenuKeyMapper[urlKey];
    const mode = isMobile ? "inline" : "horizontal";
    //menu.products.events.monthlySpecials.title = saleType(monthlySizzleSaleValue)
    return (
      <div className={urlKey} style={{width:'100%'}} onMouseEnter={this.handleMenuMouseEnter}>
        <Menu
          mode={mode}
          defaultActiveFirst
          onOpenChange={this.onOpenChange}
          activeKey={activeKey}
          getPopupContainer = {this.getPopupContainer}             
          onMouseEnter= {this.submenuEnter}
          onMouseLeave={this.submenuLeave}
        > 
         {this.renderMenu(menu)}         
        </Menu>
        <Modal closeIcon
         className='changeStorePopup'
         onClose = {this.closePopUp}
         closeOnDimmerClick={false}
            open={this.state.hasAccessPopUp}>
            <Modal.Content className=''>
               <p> You currently do not have permission to access this business tool and/or site.</p>
              <p>If you believe you should have access, please contact your Store Manager. For all other inquires please contact the Member Help Desk at 800-621-6025 option 3 or 
              <a href = "mailto: membersonlinehelp@truevalue.com"> membersonlinehelp@truevalue.com</a>.</p>
            </Modal.Content>
          </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { SessionReducer = {} } = state;
  const { UserId, storeId,userName } = SessionReducer;
  const { cart } = state;
  return {
    storeId,
    UserId,
    userName,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    viewOnly: state.SessionReducer.viewOnly,
    marketFlag: cart?.orders?.marketFlagEnabled,
    isReunionLinkEnable: cart?.orders?.isReunionLinkEnable,
    isRetailAssortmentsEnable: cart?.orders?.isRetailAssortmentsEnable,
    isCtbAssortmentsEnable: cart?.orders?.isCtbAssortmentsEnable,
    isMomentumEnable: cart?.orders?.isMomentumEnable,
    showAdminMenu: state.SessionReducer?.address?.showAdminMenu,
    monthlySizzleSaleValue: cart?.orders?.monthlySizzleSaleValue,
    entireMenu: state.SessionReducer.entireMenu,
    getWpMenu:state.navbar.wpMenu,
    businessToolsMenu:state.navbar.businessToolsMenu,
    userguid: state.SessionReducer.userguid,
    storeguid: state.SessionReducer.storeguid
  };
};
const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ setEntrieMenu, setWpMenu, setBussinessToolMenu }, dispatch)
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Flyout));
