import React from 'react';
import ReunionVendorDetailsPopup from './vendor-details-pop-up';
import { Popup } from 'semantic-ui-react';

export const getCard = ({ promoDetails }) => {
  const {flowType} = promoDetails;
  if (flowType === 1 || flowType === 3 || flowType === 4 || flowType === 5) {
    return (getItemCard(promoDetails,(flowType===4 || flowType===5)));
  } else if (promoDetails.flowType === 2) {
    return (getAssortmentCard(promoDetails));
  }
}

export const getTitleParams = (flowType, showVendors) => {
  if (flowType === 1 || flowType === 3 || flowType === 4 || flowType === 5) {
    return (
      { className: 'reunionCardTitle', onClick: showVendors }
    );
  }
  else if (flowType === 2) {
    return (
      { className: 'viewAssortment', onClick: showVendors }
    );
  }
}

export const getItemCard = (promoDetails,hidePromoNbr) => {
  const titleParams = getTitleParams(promoDetails.flowType, promoDetails.showVendors)
  return (
    <div className="reunionCard">
      <span {...titleParams}
          data-promo-nbr={promoDetails.promoNbr}
          data-promo-name={promoDetails.promoName}
          data-enddate={promoDetails.endDate}
          data-flow-type={promoDetails.flowType}
          data-relay-nbr = {promoDetails.eventType}
          data-relay-year = {promoDetails.relayYear}
      >
        {`${!hidePromoNbr ? promoDetails.promoNbr+" - ":""}${promoDetails.promoName}`}
      </span>
      <div className='reunionCardFooter'>
        <span className='linkText'
          data-promo-nbr={promoDetails.promoNbr}
          data-promo-name={promoDetails.promoName}
          data-flow-type={promoDetails.flowType}
          data-relay-nbr = {promoDetails.eventType}
          data-relay-year = {promoDetails.relayYear}
          data-allItems = {true}
          onClick={promoDetails.showItemList}> All Items </span>
        <span
          className='linkText'
          data-promo-nbr={promoDetails.promoNbr}
          data-promo-name={promoDetails.promoName}
          data-enddate={promoDetails.endDate}
          data-flow-type={promoDetails.flowType}
          data-relay-nbr = {promoDetails.eventType}
          data-relay-year = {promoDetails.relayYear}
          id={promoDetails.promoNbr}
          onClick={promoDetails.showVendors}> Vendors </span>
      </div>
    </div>
  );
}

export const getAssortmentCard = (promoDetails) => {
  const titleParams = getTitleParams(promoDetails.flowType, promoDetails.showAssortmentList)
  return (
    <>
      <div className="reunionDeptCard" >
        <div className="reunionDeptName"
          id={promoDetails.promoNbr}         
          data-dept-name={promoDetails.promoName}
          data-flow-type={promoDetails.flowType}>
          {promoDetails.promoNbr+ '-' +promoDetails.promoName}
        </div>
        <span  {...titleParams} 
          data-promonbr={promoDetails.promoNbr}
          data-promo-name={promoDetails.promoName}
          data-flow-type={promoDetails.flowType}
          id={promoDetails.promoNbr}> View Assortments </span>
      </div>
    </>
  );
}

export const isFilterRequired = (flowType) => {
  switch (flowType) {
    case "1":
    case "2":
    case "3":
    case "4":
    case "5" :
       return true;
  }
}

export const getVendorDialog = ({ vendorDetailsParams }) => {
  if (vendorDetailsParams.flowType === "3" || vendorDetailsParams.flowType === "5" || vendorDetailsParams.flowType === "1" || vendorDetailsParams.flowType === "4") {
    return (
      <Popup
        wide='very'
        offset='0, 50px'
        position='bottom right'
        hoverable
        header={vendorDetailsParams.vendorName}
        content={<ReunionVendorDetailsPopup vendorInfo= {vendorDetailsParams.vendorInfo}/>} 
        trigger={vendorDetailsParams.vendorTitle}
      />
    );
  } else {
    return (
      vendorDetailsParams.vendorTitle
    );
  }
}