import React, { Component } from "react";
import '../common/filters.css'
import { filterValues } from "./constants";
import { assortmentLevels } from './constants';
import utilities from '../../utils/utilities';
import _ from 'lodash';
import StickyBox from "react-sticky-box";


class Filter extends Component {
  constructor(props){
      super(props);
      this.state = {
          contentDivVis:props.filters && Array(props.filters.length).fill(false),
          filterTopOffset: 0
      }
  }

  componentDidMount = () => {
    this.getFilterTop();
  }
  
  clearAllFilter = () => {
    let storedUrl = window.location.href.split('?');
    window.location.href = storedUrl[0];
  }

  getFilterTop = () => {
    let filterTopOffset = document.getElementById("filterChanges").getBoundingClientRect().top; 
    this.setState({
        filterTopOffset
    })
  }

  handleFilterAction = (e) => {
    if(this.props.isLoading){
      return false;
    }
    const {filterValue, filterHead } = e.target.dataset;
    const filterHeadElement = document.getElementById('fh-' + filterHead);
    if (e.target.checked) {
      filterHeadElement.className = 'filter-head applied-filter-head'
    } else {
      const qSelect =  document.querySelector('#fs-' + e.target.name.replace(" ", "_"));
      let filterApplied = false;
      if(qSelect) {
        let filterCBs = qSelect.querySelectorAll('.filter-checkbox');
        if (!filterCBs) return;        
        filterCBs.forEach(cb => {
          if (cb.checked) {
            filterApplied = true;
            return;
          }
        })
      }
      if (!filterApplied)
        filterHeadElement.className = 'filter-head'
    }
    this.props.callback(e.target.checked, filterHead, filterValue)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clearFilters) {
      document.querySelectorAll('.filter-checkbox').forEach(cb => {
        cb.checked = false;
      });
      document.querySelectorAll('.filter-head').forEach(fh => {
        if(!fh.id.includes('Department'))
          fh.className = 'filter-head'
      })
      this.setState({contentDivVis:Array(newProps.filters.length).fill(false)})
    }
  }

  toggleDiv = (e) => {
    const index = e.target.dataset.divIndex;
    let {contentDivVis} = this.state;
    contentDivVis[index] = !contentDivVis[index]
    this.setState({contentDivVis});
  };
  render() {
    const {filters:categories, showFilters, selectedVendor, filtersApplied, appliedFilters, isNoProducts, selecDept, seleCatg, listType, screenName, loading} = this.props;
    const {contentDivVis} = this.state;
    let toggleFilter =
      isNoProducts && showFilters
        ? filtersApplied
          ? { display: 'block' }
          : { display: 'none' }
        : isNoProducts 
          ? filtersApplied
          ? { display: 'block' } 
          : { display: 'none' }
        : { display: 'block' };
    const FilterClassName = this.props.listType === 5 || this.props.listType === 6;
    return (
      <>
      <StickyBox offsetTop={this.state.filterTopOffset} className='stickFilterMobile'>
        <div style={toggleFilter} id= "filterChanges">
          {!showFilters && 
          <i
            className={(filtersApplied||selecDept||seleCatg|| selectedVendor)?'filter icon applied-filter':'filter icon'}
            id='filter_symbol'
            onClick={this.props.toggleShowFilter}
          >
            <span className='expand-arrow'>»</span>
          </i>
          }
          {
            showFilters &&
            <div className={FilterClassName ? 'FilterMonthly' : 'assortmentFilters filters'}>
              <div className='refine-filter-head filter-header'>
                <span>Refine Search</span>
              <i className="filter icon" onClick={this.clearAllFilter} />
                <span id="filter_icon" 
                  className = {(filtersApplied||selecDept||seleCatg|| selectedVendor)?'applied-filter filter-hide':'filter-hide'}
                  onClick={this.props.toggleShowFilter}>
                   <span className='shrink-arrow'> <>&lt;&lt;</> hide</span> 
                </span>
              </div>
              {categories &&
                categories.map((category, index) => {
                  let filterVals = category.valueList && (listType && listType != 'DOM'? Object.keys(category.valueList) : category.valueList);
                  if(_.isEmpty(filterVals)) return;
                  filterVals = (!Array.isArray(filterVals))?[filterVals]:filterVals;
                  let autoCheckHead = (selecDept && category.name==='Department') 
                                        || (seleCatg && category.name==='Category')
                                        || (selectedVendor && category.name==="Vendor Name");
                  let filterHeadSelected = false;
                  if(screenName === 'orderstatus' || screenName === 'promostatus' || screenName==='Planograms' || listType==='editOrders' || listType==='DOM' || 
                  this.props.listType ==='ImpulseMerchandising' || (filtersApplied && screenName==='FlashSales')
                  || screenName === 'MonthlySpecialsVendors' || screenName === 'MonthlySpecialLanding' || this.props.listType===6
                  || listType==='HotDeals' || (filtersApplied && screenName === 'RetailAssortments'))
                  {
                    let filterHeadSelect = false;
                    filterVals.forEach(item => {
                      if(appliedFilters && appliedFilters[category.name] && (appliedFilters[category.name].includes(item)
                      || (category.name === 'Assortment Level' && item.includes(appliedFilters[category.name])))){
                        filterHeadSelect = true;
                      }
                    });  
                    filterHeadSelected = filterHeadSelect;           
                  }                 
                  return (
                    <div className='filter-section' key={category.name} id={'fs-' + category.name.replace(" ", "_")}>
                      <span className={(autoCheckHead || filterHeadSelected || (selectedVendor && category.name==="Vendor Name"))?'filter-head applied-filter-head':'filter-head'} id={'fh-' + category.name}>
                        {screenName==='Planograms' && category.name==='Region'?'Region/Additional Attributes':category.name==='Assortment Level'? 'Assortment Item': category.name}
                        <i className={contentDivVis[index]?'minus icon toggleIcon':'plus icon toggleIcon'}
                          data-div-index={index}
                          onClick={this.toggleDiv}>
                        </i>
                      </span>
                      {contentDivVis[index] &&
                      <div id={'contents-' + index}>
                        {filterVals && filterVals.map(item => {
                          const checkboxId = `${category.name}_${item}`;
                          let orderTypeFilterVal = item;
                          if (category.valueList && screenName === 'orderstatus' && category.name === 'Order Type') {
                            if(!utilities.isEmptyOrNullString(item)) {
                              orderTypeFilterVal =  item.includes('-') ? item.split('-')[0]: item
                            }
                          }
                          const disableCb = (category.name==='Department'
                                              && selecDept && item===selecDept) 
                                              || (category.name==='Category' && seleCatg && item===seleCatg) ||
                                              (category.name==="Vendor Name" && selectedVendor 
                                              && item===selectedVendor)
                          const disableCheckBox = (screenName === 'promostatus' && loading === true) || (screenName === 'RetailAssortments' && loading === true)
                          return (
                            !utilities.isEmptyOrNullString(item) && (
                              <div className="ui checkbox filter-cb-wrap" key={item}>
                                <input type='checkbox'
                                  className='filter-checkbox'
                                  key={(listType==='DOM') ? item.includes('-') && item.split('-')[0] ? item.split('-')[0]:item:item}
                                  data-filter-head={category.name}
                                  data-filter-value={(listType==='DOM') ? item.includes('-') && item.split('-')[0] ? item.split('-')[0]:item:
                                  screenName === 'orderstatus' && category.name === 'Order Type'?orderTypeFilterVal:item}
                                  name={category.name}
                                  onChange={this.handleFilterAction}
                                  disabled = {disableCb || disableCheckBox}
                                  value={(listType==='DOM' ) ? item.includes('-') && item.split('-')[0] ? item.split('-')[0]:item:
                                  screenName === 'orderstatus' && category.name === 'Order Type'?orderTypeFilterVal:item}
                                  checked = {disableCb ||
                                    (appliedFilters && 
                                    appliedFilters[category.name] && 
                                    (typeof appliedFilters[category.name] === 'object' && appliedFilters[category.name].includes((listType==='DOM'||  listType==='HotDeals') ? item.includes('-') && item.split('-')[0] ? item.split('-')[0]:item:item) ||
                                    typeof appliedFilters[category.name] === 'string' && appliedFilters[category.name] === ((listType==='DOM'||  listType==='HotDeals') ? item.includes('-') && item.split('-')[0] ? item.split('-')[0]:item:item)) ?true:
                                    screenName === 'orderstatus' && category.name === 'Order Type'&& appliedFilters[category.name] && appliedFilters[category.name].split(',').includes(orderTypeFilterVal)?true:false)
                                  }
                                  id={checkboxId}
                                />
                              <label>
                                {
                                  listType == 'ImpulseMerchandising' ?
                                  category.valueList[item] : listType == 'CloseoutItems' && category.name == "Assortment Level" ?  assortmentLevels[item.replace(/\D/g,'')]
                                  : (screenName === 'RetailAssortments' && category.name==='Markdown') ?`${item}%`: 
                                listType === 'DOM' || listType==='HotDeals'? item.includes('-') && item.split('-')[1]? item.split('-')[1]:item
                                    :filterValues[item]?filterValues[item].displayName:
                                      screenName==='Planograms' && category.name==='Region' ? item.replace('-', ''):item}
                                    { category.valueList && listType && listType != 'DOM' && listType != 'ImpulseMerchandising'?  ' (' + category.valueList[item] + ')' : '' }</label>
                              </div>
                            )
                          )
                        })}
                      </div>
                      }
                    </div>
                  )
                })
            }
          </div>
          }
        </div>
      </StickyBox>
      </>
    )
  }
}

export default Filter;
