import React, { Component } from 'react';
import './styles.css';
import restUtils from '../../../utils/restUtils';
import { connect } from 'react-redux';
import { colDefs, notes, summaryConfig } from './modelData';
import { FILL_RATES_API } from '../../../urlConstants';
import { Grid, Loader } from 'semantic-ui-react';
import Table from '../../common/Table';

class FillRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fillRateWeek: {}
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    const { storeId } = this.props;
    if (storeId) {
      restUtils.getData(`${FILL_RATES_API}?membrnbr=${storeId}`).then(res => {
        const { data = [] } = res;
        const { fillRateWeek } = this.state;
        data.sort((a, b)=> new Date(a.FR_for_week) - new Date(b.FR_for_week))
        data.forEach(row => {
          const { FR_for_week, Dclass } = row;
          if (Dclass !== 'T') {
            if (fillRateWeek[FR_for_week]) {
              fillRateWeek[FR_for_week].data.push(row);
            } else {
              fillRateWeek[FR_for_week] = { data: [row] };
            }
          } else {
            fillRateWeek[FR_for_week].T = row;
          }
        });

        this.setState({
          fillRateWeek,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
    }
  }
  render() {
    const { fillRateWeek = [] } = this.state;

    return (
      <div className='page-wrap fill-rates-section'>
        {this.state.loading && (
          <div>
            <Loader active />
          </div>
        )}
        <div className='page-header'>Weekly Fill Rates Report</div>
        {this.props.multiStoreDetails.access === 1 ? (
         <>
          <h4> You don't have the permission to view this page. Please contact the True Value Administrator. </h4>
         </>
        ) : (
          <>
        <FillRateTable fillRateWeek={fillRateWeek} />
        </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: state.SessionReducer.storeId,
  multiStoreDetails : state.SessionReducer.multiStoreDetails,
});

export default connect(mapStateToProps, null)(FillRates);

export const FillSummary = props => {
  const { summary, config } = props;
  return (
    <Grid className='summary'>
      {config.map(summaryObj => {
        const { heading, key } = summaryObj;
        return (
          <Grid.Row columns={2} key={heading}>
            <Grid.Column width={8}>{heading}</Grid.Column>
            <Grid.Column width={8}>{summary[key]}</Grid.Column>
          </Grid.Row>
        );
      })}
    </Grid>
  );
};

export const Notes = props => {
  const { notes } = props;
  return (
    <div className='notes'>
      {notes.map(note => (
        <p key={note}>{note}</p>
      ))}
    </div>
  );
};

export const FillRateTable = props => {
  const fillRateTables = [];
  const { fillRateWeek } = props;
  for (let key in fillRateWeek) {
    fillRateTables.push(
      <div key={key}>
        <p className='info-text pb-0'>
        Fill Rates for Week Ending: {key.match(/(\d{1,2}([.\-/])\d{1,2}([.\-/])\d{1,2})/g)}
        </p>
        <div className='fill-rates-grid pb-10'>
          <Table data={fillRateWeek[key].data} colDefs={colDefs} />
        </div>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column computer={6} mobile={16} className='mb-25'>
              <h4 className='under-line'>Weekly Totals</h4>
              <Grid>
                <FillSummary
                  summary={fillRateWeek[key]['T']}
                  config={summaryConfig}
                />
              </Grid>
            </Grid.Column>
            <Grid.Column computer={10} mobile={16} className='mb-25'>
              <Notes notes={notes} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
  return fillRateTables;
};
