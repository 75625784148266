import React, { Component } from 'react';
import '../AddressBook.css';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import { colDefs } from '../AddressBookModels';
import Table from '../../common/Table';
import { ADDRESS_BOOK_UPLOAD_API } from '../../../urlConstants';
import { Grid } from 'semantic-ui-react';
import restUtils from '../../../utils/restUtils';
import {utilities} from '../../../utils/utilities';
import { connect } from 'react-redux';

class AddressBookUpload extends Component {
  constructor(props) {
    super(props);
    this.updateField = this.updateField.bind(this);
    this.validateKey = this.validateKey.bind(this);
    this.deleteitems = this.deleteitems.bind(this);
    this.updateBlurAb = this.updateBlurAb.bind(this);
    this.state = {
      addressList: this.props.location.state.addressList,
      colDefs: this.getColDefs(),
      deletionConfirmOpen: false,
      addressMessage:'',
      validationList:[]
    };
    this.defKeys = ['customerName','contactName','addressLine1','addressLine2','city','state','zip4','zip5','phoneLine','phoneArea','phoneExchange','faxArea','faxExchange','faxLine','email'];
    this.validationList =[];
  }

  componentDidMount(){
    const validatedAddressLis =  this.loadError();   
    this.setState({
      addressList:validatedAddressLis
    }, () => {
      this.showErrorMessage();
    });
  }

  handleCancel=()=>{
    this.props.history.push('/AddressBook');
  }

  loadError=()=>{
    let errorFlag = false;
    const { addressList } = this.state;
    let validatedAddr = [];
    let regex=/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    let regemail=/\S+@\S+\.\S+/;
    for (let index = 0; index < addressList.length; index++) {
      let errorArray = [];
      const address = addressList[index];
      const {
        contactName,
        addressLine1,
        customerName,
        city,
        state,
        zip5,
        zip4,
        phoneLine,
        phoneArea,
        phoneExchange,
        faxArea,
        faxExchange,
        faxLine,
        email
      } = address;
      if(zip5 && (zip5.match(regex))){
        address.zip5 = utilities.padWithZeros(zip5, 5);
      }
      if(zip4 && (!zip4.match(regex))){
        errorFlag = true;
        errorArray['zip4'] = 'Character not allowed';
      }
      if(zip5 && (!zip5.match(regex) || zip5.length > 5)){
        errorFlag = true;
        errorArray['zip5'] = 'Only 5 digits are allowed';
      }
      if(phoneLine && (!phoneLine.match(regex) || phoneLine.length !== 4)){
        errorFlag = true; 
        errorArray['phoneLine'] = 'Only 4 digits allowed for Phone line';
      }
      if(phoneArea && (!phoneArea.match(regex) || phoneArea.length !== 3)){
        errorFlag = true; 
        errorArray['phoneArea'] = 'Only 3 digits allowed for Phone area';
      }
      if(phoneExchange && (!phoneExchange.match(regex) || phoneExchange.length !== 3)){
        errorFlag = true; 
        errorArray['phoneExchange'] = 'Only 3 digits allowed for Phone exchange';
      }
      if(faxArea && (!faxArea.match(regex) || faxArea.length !== 3)){
        errorFlag = true; 
        errorArray['faxArea'] = 'Only 3 digits allowed for Fax area';
      }
      if(faxExchange && (!faxExchange.match(regex) || faxExchange.length !== 3)){
        errorFlag = true; 
        errorArray['faxExchange'] ='Only 3 digits allowed for Fax exchange';
      }
      if(faxLine && (!faxLine.match(regex) || faxLine.length !== 4)){
        errorFlag = true; 
        errorArray['faxLine'] ='Only 4 digits allowed for Fax line';
      }
      if(email && !regemail.test(email)) {
        errorFlag = true; 
        errorArray['email'] ='Invalid email id';
      }
      if (!customerName) {
        errorFlag = true; 
        errorArray['customerName'] = 'Please fill required fields';
      }
      if (!contactName ) {
        errorArray['contactName'] = 'Please fill required fields';
        errorFlag = true; 
      }
      if ( !addressLine1 ) {
        errorArray['addressLine1'] ='Please fill required fields';
        errorFlag = true; 
      }
      if (!city) {
        errorFlag = true; 
        errorArray['city'] ='Please fill required fields';
      }
      if ( !state) {
        errorFlag = true; 
        errorArray['state'] ='Please fill required fields';
      }
      if ( !zip5) {
        errorFlag = true; 
        errorArray['zip5'] = 'Please fill required fields';
      }
      if(errorFlag){
        this.setState({
          addressMessage:'Hover over on the highlighted text box to see the error message'
        })
      }
      validatedAddr.push({
        ...address
      });
      this.validationList[index]=errorArray;
    }
    return validatedAddr ;

  }

  showErrorMessage = () => {
    for (let index = 0; index < this.state.addressList.length; index++) {
    this.defKeys.map(key => {
        document.getElementById(key+''+index).className = '';
        this.setState({
          addressMessage:''
        });
      })
    }
    Object.keys(this.validationList).map(key => {
      let rowValue = this.validationList[key];
      Object.keys(rowValue).map(columnKey => {
        document.getElementById(columnKey+''+key).className = 'errorMessage';
        this.setState({
          addressMessage:'Hover over on the highlighted text box to see the error message'
        });
      })
    })
  }

  handleMouseIn=(key,rowIndex)=>{
    let rowValue = this.validationList[rowIndex][key];
    document.getElementById('toolTip' + key+''+rowIndex).className = 'hoverTipIn';
    this.setState({
      validationErrorMsg : rowValue
    });
  }

  handleMouseOut=(key,rowIndex)=>{
    document.getElementById('toolTip' + key+''+rowIndex).className = 'hoverTipOut';
    this.setState({ validationErrorMsg: '' })
  }

  getColDefs = () => {
    colDefs.forEach(def => {
        def.cellRenderer = (value, key, rowIndex) => {
          return (
            <>
            <input
              id={key+''+rowIndex}
              onMouseOver={() => this.handleMouseIn(key, rowIndex)}
              onMouseOut={() => this.handleMouseOut(key, rowIndex)}
              onKeyPress={e => this.validateKey(e, key,rowIndex)}
              onChange={e => this.updateField(e, key, rowIndex)}
              onBlur={e => this.updateBlurAb(e, key, rowIndex)}
              value={value ? value : ''}
            />
            <span className = 'hoverTipOut'  id={'toolTip'+ key+ ''+rowIndex}>{this.state.validationErrorMsg}</span>
            </>
          );
        };
        if (def.key === 'deleteitem') {
          def.cellRenderer = ( value, key, rowIndex) => {
            return (
            <span className='remove-all-span' onClick={() => this.deleteitems(rowIndex)}>Remove</span>
            );
            };
          }
    });
    return colDefs;
  };

  validateKey(e,key){
    switch (key) {
      case 'zip4':
      case 'zip5':
      case 'phoneLine':
      case 'phoneArea':
      case 'phoneExchange':
      case 'faxArea':
      case 'faxExchange':
      case 'faxLine':
        var unicode=e.charCode? e.charCode : e.keyCode;
        if (unicode!=8){ 
          if (unicode<48||unicode>57) {
            e.preventDefault();
          }
        }
        break;
      default:
    }
  }

  deleteitems =( rowIndex)=> {
    this.validationList.splice(rowIndex,1)
    const relocationid = parseInt(this.state.addressList[rowIndex].locationId);
    const addressList = this.state.addressList.filter(itemaddress => parseInt(itemaddress.locationId) !== relocationid);
    this.props.history.push({
      state : {
        addressList
      }
    })
    this.setState({
      addressList
    })
    this.showErrorMessage();
  }

  updateBlurAb=(e, key, rowIndex)=>{
    const{addressList} = this.state;
    let{value}=e.target;
    let regexss=/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    switch (key){
    case 'zip5':
      if(value && (value.match(regexss))){
        value=utilities.padWithZeros(value, 5);
      }
      break;
    }
    addressList[rowIndex][key] =value;
    this.setState({
      addressList
    })
  }

  updateField=(e, key, rowIndex)=>{
    const{value}=e.target;
    let updateErrorArray = this.validationList[rowIndex];
    let regexs=/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    let  regemails =/\S+@\S+\.\S+/;
    const { addressList } = this.state;
    let errorFlag = false;
    let message = '';
    switch (key) {
      case 'email':
        if(value && !regemails.test(value)) {
          message = 'Invalid email id';
          updateErrorArray[key] ='Invalid email id';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          updateErrorArray.splice(key,1);
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'phoneLine':
        if(value &&  (!value.match(regexs) || value.length !== 4)){
          updateErrorArray[key] ='Only 4 digits allowed for Phone line';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'phoneArea':
        if(value && (!value.match(regexs) || value.length !== 3)){
          updateErrorArray[key] ='Only 3 digits allowed for Phone area';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'phoneExchange':
        if(value && (!value.match(regexs) || value.length !== 3)){
          updateErrorArray[key] ='Only 3 digits allowed for Phone exchange';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'faxArea':
        if(value && (!value.match(regexs) ||  value.length !== 3)){
          message = 'Only 3 digits allowed for Fax area';
          updateErrorArray[key] ='Only 3 digits allowed for Phone exchange';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'faxExchange':
        if(value &&  (!value.match(regexs) || value.length !== 3)){
          message = 'Only 3 digits allowed for Fax exchange';
          updateErrorArray[key] ='Only 3 digits allowed for Phone exchange';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'faxLine':
        if(value && (!value.match(regexs) || value.length !== 4)){
          updateErrorArray[key] ='Only 4 digits allowed for Phone exchange';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'customerName':
        if (!value) {
          updateErrorArray[key] = 'Please fill required fields';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'contactName':
        if (!value) {
          updateErrorArray[key] = 'Please fill required fields';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'addressLine1':
        if (!value) {
          updateErrorArray[key] = 'Please fill required fields';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'city':
        if (!value) {
          updateErrorArray[key] = 'Please fill required fields';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'state':
        if (!value) {
          updateErrorArray[key] = 'Please fill required fields';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'zip5':
        if (!value) {
          updateErrorArray[key] = 'Please fill required fields';
          errorFlag = true;
        }
        else if (value && (!value.match(regexs) || value.length > 5)) {
          updateErrorArray[key] = 'Only 5 digits are allowed';
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      case 'zip4':
        if (value &&   (!value.match(regexs))){
          updateErrorArray[key] = 'Please fill required fields';     
          errorFlag = true;
        }
        else{
          delete  updateErrorArray[key];
          document.getElementById(key+''+rowIndex).className = '';
        }
        break;
      default:
    }
    addressList[rowIndex][key] = value;
    this.validationList[rowIndex]=updateErrorArray;
    this.showErrorMessage();
    this.props.history.push({
      state : {
        addressList
      }
    })
    this.setState({
      addressList
    });
  }

  submitAddressList = () => {
    const validatedAddressList = this.getValidatedAddress();
    const { userName, storeId } = this.props;
    if (validatedAddressList.length) {
      const requestObj = {
        storeId: storeId,
        molUserName: userName,
        addressList: validatedAddressList
      };

    const requestUrl = `${ADDRESS_BOOK_UPLOAD_API}/PostSaveAddressBookFile`;
    restUtils.postData(requestUrl, JSON.stringify(requestObj)).then(res => {
      if (res.data.success) {
        this.props.history.push({
          pathname: '/AddressBook',
          state: {
            ...(this.props.location.state || {}),
            editAction: '',
            successRecord: validatedAddressList.length,
            successUpload:res.data.success
          }
        });
      } else {
        this.setState({
          addressMessage: res.data.message,
          isLoading: false
        });
      }
    });
    } else {
      const { addressList } = this.state;
      this.setState({
        addressList: [...addressList]
      });
    }
  };

  getValidatedAddress = () => {
    let subregex=/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    let  regemail = /\S+@\S+\.\S+/;
    let errorFlag = false;
    let  validatedAddress = [];
    const { addressList } = this.state;
    for (let index = 0; index < addressList.length; index++) {
      let submiterrorArray =[];
      const address = addressList[index];
      const {
        contactName,
        addressLine1,
        customerName,
        city,
        state,
        zip5,
        zip4,
        phoneLine,
        phoneArea,
        phoneExchange,
        faxArea,
        faxExchange,
        faxLine,
        email
      } = address;
      if(zip5 && (zip5.match(subregex))){
        address.zip5 = utilities.padWithZeros(zip5, 5);
      }
      if(zip4 && (!zip4.match(subregex))){
        errorFlag = true;
        submiterrorArray['zip4'] = 'Character not allowed';
      }
      if(zip5 && (!zip5.match(subregex) || zip5.length !== 5)){
        errorFlag = true;
        submiterrorArray['zip5'] = 'Only 5 digits are allowed';
      }
      if(phoneLine && (!phoneLine.match(subregex) || phoneLine.length !== 4)){
        errorFlag = true; 
        submiterrorArray['phoneLine'] = 'Only 4 digits allowed for Phone line';
      }
      if(phoneArea && (!phoneArea.match(subregex) || phoneArea.length !== 3)){
        errorFlag = true; 
        submiterrorArray['phoneArea'] = 'Only 3 digits allowed for Phone area';
      }
      if(phoneExchange && (!phoneExchange.match(subregex) || phoneExchange.length !== 3)){
        errorFlag = true; 
        submiterrorArray['phoneExchange'] = 'Only 3 digits allowed for Phone exchange';
      }
      if(faxArea && (!faxArea.match(subregex) || faxArea.length !== 3)){
        errorFlag = true; 
        submiterrorArray['faxArea'] = 'Only 3 digits allowed for Fax area';
      }
      if(faxExchange && (!faxExchange.match(subregex) || faxExchange.length !== 3)){
        errorFlag = true; 
        submiterrorArray['faxExchange'] ='Only 3 digits allowed for Fax exchange';
      }
      if(faxLine && (!faxLine.match(subregex) || faxLine.length !== 4)){
        errorFlag = true; 
        submiterrorArray['faxLine'] ='Only 4 digits allowed for Fax line';
      }
      if(email && !regemail.test(email)) {
        errorFlag = true; 
        submiterrorArray['email'] ='Invalid email id';
      }
      if (!customerName) {
        errorFlag = true; 
        submiterrorArray['customerName'] = 'Please fill required fields';
      }
      if (!contactName ) {
        submiterrorArray['contactName'] = 'Please fill required fields';
        errorFlag = true; 
      }
      if ( !addressLine1 ) {
        submiterrorArray['addressLine1'] ='Please fill required fields';
        errorFlag = true; 
      }
      if (!city) {
        errorFlag = true; 
        submiterrorArray['city'] ='Please fill required fields';
      }
      if ( !state) {
        errorFlag = true; 
        submiterrorArray['state'] ='Please fill required fields';
      }
      if ( !zip5) {
        errorFlag = true; 
        submiterrorArray['zip5'] = 'Please fill required fields';
      }
      if(errorFlag){
        this.setState({
          addressMessage:'Hover over on the highlighted text box to see the error message'
        })
      }
      validatedAddress.push({
        ...address
      });
      this.validationList[index]=submiterrorArray;
    }
    return errorFlag ? [] : validatedAddress ;
  };

  render() {
    const { colDefs, addressList } = this.state;
    let breadcrumbLinks = [{ link: '/AddressBook', text: 'ADDRESSBOOK' }];
    return (
      <div className='page-wrap'>
        <div className='segment_address'>
          <div className='page-header displayInline'>Address Book Upload</div>
          <div id='page_navigation'>
            <BreadcrumbComp path={breadcrumbLinks} />
          </div>
        </div>

        <div className='sixteen wide column AddressTableSegment'>
          <div className='vertical-spaced'>
            <Grid>
              <Grid.Column>
                <div>
                  <span>{addressList.length} addresses found </span>
                  <span className="requiredField">*Required field</span>
                  <span className="borderAddressbook">{this.state.addressMessage}</span>
                </div>
              </Grid.Column>
            </Grid>
          </div>
          <Table className='address-upload' data={this.state.addressList} colDefs={colDefs}/>
          <div className='vertical-spaced'>
            <Grid columns={2}>
              <Grid.Column>
                <div>{addressList.length} addresses found</div>
              </Grid.Column>
              <Grid.Column>
                <div className='right-align'>
                <input
                    type='button'
                    onClick={this.handleCancel}
                    name='cancel'
                    id='cancel'
                    className='custom-file-input-cancel'
                  />
                  <label htmlFor='cancel' className='custom-input-btn-cancel-address'>
                  Cancel
                 </label>
                  <button className='custom-input-btn-save red button' onClick={this.submitAddressList}>
                    Submit
                  </button>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { SessionReducer } = state;
  const { storeId, userName } = SessionReducer;
  return {
    storeId,
    userName
  };
};

export default connect(mapStateToProps, null)(AddressBookUpload);
