import React from 'react';
 import { Link } from 'react-router-dom';
 import { connect } from 'react-redux';
 import Dialog from 'react-dialog';
 import { Segment, Table, Checkbox } from 'semantic-ui-react';
 import renderImages from '../common/RenderImages';
 import { MaskPrice } from '../common/MaskPrice';
 import { TierIcon, ProductIndicators } from '../common/TierIcon';
 import utilities, {getPrefernceType, shouldHideCostForPreference, shouldHideSUGG_RETAILForPreference} from '../../utils/utilities';
 import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
 import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
 import config from '../../config'; 
 import QuickViewDetails from '../common/QuickViewDetails';
 import { closeModal, addimg, delimg } from '../common/productHandler';
import { event } from 'react-ga';

 class ProductClassic extends React.Component {
   constructor(props){
     super(props);
     this.closeModal = closeModal.bind(this);
     this.addimg = addimg.bind(this);
     this.delimg = delimg.bind(this);
     this.state = {
       visible: false,
       item: '',
       quickviewdata: '',
       click: '',
       expand: '',
       isMultistoreModalOpen:false,
       quantity:utilities.emptyForNull(props.product.cart_available),
       overrideRetail:utilities.emptyForNull(props.product.cart_overrideretail),
       qtyError : false
     }
   }
   expand = num => {
     this.setState({
       expand: 'expanding',
       item: num
     });
   };
   closeModalexpand = () => {
     this.setState({
       expand: '',
       item: ''
     });
   };
   openModal(data) {
    this.setState({
      visible: true,
      item: data,
      click: 'clicked',
      expand: ''
    });
    this.props.blurfun && this.props.blurfun(true);
  };
   handleChange = (e) =>{
     const type = e.target.dataset.field;
     const value = e.target.value;
     if (type === 'qty' &&   parseFloat(value) <= 0) {
       utilities.showToast('Quantity should be positive number!', true);
       return;
     }
     if(type==='qty' && /^(\d+)$|^$/.test(value)){
       this.setState({quantity:value});      
 
     }else if(type === 'or' && /^(\d{0,4}\.\d{0,2}|\d{0,4})$/){
       this.setState({overrideRetail:value})
       this.props.overrideRetailChange(value)
     }
     this.props.handleChange(e);
   }
 
   multistoreHandleCarts= (product) => {
     this.setState({
       productSelectedForModal: product,
       isMultistoreModalOpen: true
     });
   };
 
   closeMultistoreModal=()=>{
     this.setState({ isMultistoreModalOpen: false });
   }

   getLongDesc = (product) => {
    let longDesc = '';
    if(config.showMediumDesp){
      longDesc = product.Product_Title || product.Description;
    }
     else{
      longDesc = product.Description;
    }
     
    let Item_num = product.Item_Number;
    return(
      longDesc && (
        <p className='listingDescription'>
          {longDesc.length < 50
            ? longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase() 
            : longDesc.substr(0,1).toUpperCase() + longDesc.substr(0, 42).substr(1,42).toLowerCase()  + '...'}
          <u className='cursor_p'
              onClick={() => this.expand(Item_num)}
          >
            {longDesc.length < 50
              ? ''
              : 'more'}
          </u>
        </p>
      )
    )
  }

  getLongDescription = (product) => {
    let longDesc = '';
    if(config.showMediumDesp){
      longDesc = product.Product_Title || product.Description;
    }
     else{
      longDesc = product.Description;
    }
    if(longDesc){
      longDesc =  longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase()
    }
    else{
      longDesc = "";
    }
    return longDesc;
  }
 
   render(){
     const { product, handleChangeblur } = this.props;
     const memberCost = product.pricing ?
                        utilities.getCostForQtyPlpNew(product.pricing, this.state.quantity)
                          :'';
    const getPrefer =  getPrefernceType(this.props); 
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const showSuggRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
     return (
       <>
           <Table id="classViewTable" className="favClassicTable">
             <Table.Body>
                 <Table.Row>
                     <Table.Cell style ={{width: "3%"}}>
                     {this.state.visible && this.state.item === product.itemNbr && (
                      <div className='QuickviewDialog'>
                        <Dialog width='1' height='1' position='absolute'>
                          <QuickViewDetails
                            getprefer={
                              this.props.getprefer &&
                              this.props.getprefer.customerView
                                ? this.props.getprefer.customerView
                                : 'c1'
                            }
                            Item_Number={product.itemNbr}
                            viewType={this.props.viewType}
                            QuickCost ={product.Member_Cost}
                          />
                        </Dialog>
                      </div>
                     )}
                     <div class="card_list_wrap">
                         <Link className='card_list_img'
                         onClick={this.props.setBackButtonPath} 
                         to={{
                             pathname:`/product/${product.itemNbr}`,
                             state:{
                             source:[{text:'FAVORITES',link:"/favorites"}]
                             }
                         }}>
                             {renderImages.getImage({
                             src: this.props.imageUrl,
                             style: { display: this.props.img_style },
                             className: 'ui image image_lisitng',
                             alt: 'image',
                             onMouseEnter: () => {
                              this.addimg('addimg', product.itemNbr);
                            }
                             })}
                         </Link>
                     </div>
                     </Table.Cell>
                     <Table.Cell style={{width: "10%" }}>
                     <>
                     <Link
                          onClick={this.props.setBackButtonPath}
                          to={{
                            pathname: '/product/' + product.itemNbr,
                          }}
                          >
                          {product.shortDescription}
                    </Link>
                    
                     {this.state.expand === 'expanding' &&
                     this.state.item === product.Item_Number && (
                         <div className='DescriptionDialog favourite-More' onMouseLeave={this.closeModalexpand}>
                         <Dialog width='1' height='1' position='absolute'>
                         {this.getLongDescription(product)}
                         </Dialog>
                         </div>
                     )}
                         {this.getLongDesc(product)}
                     </>
                     </Table.Cell>
                     <Table.Cell style={{width: "12%" }}>
                     <b className='vendor-span'>
                         <Link
                         onClick={this.props.setBackButtonPath} 
                         to={{pathname:product.vendorName
                             ? '/vendor/' +encodeURIComponent(product.vendorName)
                             : '#', state: { vendorId: product.vendorId }}}
                         >
                         {product.vendorName}
                         </Link>
                     </b>
                     
                     </Table.Cell>
                     {this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr) ? 
                   (
                     <>
                       <Table.Cell className='First_level_details' style={{width: "5%" }}>
                         {product.itemNbr}
                       </Table.Cell>
                       <Table.Cell className='First_level_details pl_product_model' style={{width: "4%" }}>
                         {product.Model}
                       </Table.Cell> 
                       { displayMemberCostAndLbl &&
                       <Table.Cell className='First_level_details' style={{width: "5%" }}> 
                       {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1 && this.props.multiStoreDetails.CatMbr))?   
                         <MaskPrice
                          getprefer = {(this.props.getprefer && this.props.getprefer.customerView) ? this.props.getprefer.customerView: 'c1'}
                           viewType={this.props.viewType}
                           fieldVal={memberCost}
                           field='Member Cost'
                         />
                         : "" }
                       {product.pricing && product.pricing.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW ? <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product.pricing, this.state.quantity, true)}
                          tierLetter={this.state.quantity && parseInt(this.state.quantity) > 0 ? true : false}/>:<></>}
                       </Table.Cell>
                        }
                       {showSuggRetailPrice && <Table.Cell className='First_level_details pl_product_model' style={{width: "4%" }}>
                         {this.props.getSuggestedRetail(product)}
                       </Table.Cell>
                        }
                      {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && 
                       <>
                       <Table.Cell className='First_level_details' style={{width: "5%" }}>  
                       {product.aliasSku} 
                       </Table.Cell> </>}                     
                     </>
                     )
                     :
                     (
                       <>
                           <Table.Cell className='First_level_details' style={{width: "5%" }}>
                             {product.itemNbr}
                           </Table.Cell>
                           <Table.Cell className='First_level_details pl_product_model' style={{width: "5%" }}>
                             {product.Model}
                           </Table.Cell>              
                         {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) &&            
                             <Table.Cell className='First_level_details' style={{width: "5%" }}>  
                               {product.aliasSku} 
                             </Table.Cell> 
                           }
                         </>
                        ) 
                     }               
                       <Table.Cell  style={{width: "5%" }}
                         className={
                           (product.available && parseInt(product.available)) > 0 
                                 ? 'QuickFirst_level_details'
                                 : 'First_level_details_red '}>
                         {utilities.checkForHubAndSpokeIndictor(product)}
                       </Table.Cell>
                       <Table.Cell className='First_level_details' style={{width: "4%" }}>
                         {product.sPackType}
                         {product.sPackQty}
                       </Table.Cell>
                       <Table.Cell className='First_level_details' style={{width: "5%" }}>
                         {product.maxQty ? product.maxQty : '-'}
                       </Table.Cell>
                       {this.props.isMomentum && <Table.Cell className='First_level_details' style={{width: "4%" }}>
                         {product.MomentumCode ? product.MomentumCode : '-'}
                       </Table.Cell>}
                       <Table.Cell style={{width: "5%" }}>
                         <input
                           id={'or-' + product.itemNbr}
                           className='qty-box pl_qty_box'
                           data-field='or'
                           data-item = {product.itemNbr}
                           maxLength={7}
                           value = {this.state.overrideRetail}
                           data-overrideRetail = {this.state.overrideRetail}
                           onChange={this.handleChange}
                           onBlur = {handleChangeblur}
                         />
                       </Table.Cell>
                       <Table.Cell style={{width: "5%" }}>
                         <input
                           id={'qty-box' + product.itemNbr}
                           data-field='qty'
                           maxLength={7}
                           data-overrideRetail = {this.state.overrideRetail}
                           value = {this.state.quantity}
                           onChange={this.handleChange}
                           className={this.props.inputFocus ? 'invalidBox qty-box pl_qty_box': 'qty-box pl_qty_box'}
                           type='text'
                           data-item-number={this.props.indexForModalMs}
                           data-item = {product.itemNbr}
                           data-pack-type={product.sPackType}
                           data-pack-qty={product.sPackQty}
                           data-product ={product}
                           data-maxQty={product.maxQty}
                           onBlur = {handleChangeblur}
                           key={this.props.itemRef}
                           ref = {this.props.itemRef}
                           onKeyDown = {this.props.handleKeyDown}
                         />
                       </Table.Cell>
                       {!this.props.isMomentum && <Table.Cell style={{width: "3%" }}>
                       <Checkbox className="favCheckBox"
                           checked={product.selected}
                           onClick={() =>
                             this.props.selectForRemoval(product.itemNbr)
                           }
                         />
                       </Table.Cell>}
                      
                 </Table.Row>
                 <Table.Row className="resProductDesc" onMouseLeave={() => {this.delimg('delimg');}}>
                      <Table.Cell style={{maxWidth:'2.8rem'}}>
                      {
                      window.innerWidth > 767 && (
                        <div onMouseLeave={this.closeModal} className="qucikViewDiv">
                          <button
                            type='button'
                            className={
                              this.state.item === product.itemNbr &&
                              this.state.quickviewdata === 'addimg'
                                ? 'buttonVisible'
                                : 'buttonNotVisible'
                            }
                            onMouseEnter={() => {
                              this.openModal(product.itemNbr);
                            }}
                          >
                            Quick View
                          </button>
                        </div>
                      )}
                      </Table.Cell>
                     <Table.Cell colSpan='4'>
                          <div className ='indicators-div'>
                         <ProductIndicators product = {product} isPLP='PLP'/>
                         {!utilities.isEmptyOrNullString(product?.StockStatusMessage) && (
                            <>
                             &nbsp;|&nbsp; <span>Stock Status:</span> {product?.StockStatusMessage}
                           </>
                           )}
                         </div>
                     </Table.Cell>
                     {product.alternateItem?.Number && product.alternateItem?.Number > 0 ? (
                                      <Table.Cell colSpan={'4'} className='boldText'>
                                      <span>{product.alternateItem?.Label} </span>
                                     <Link
                                     to={{
                                       pathname: '/product/' + product.alternateItem?.Number,
                                       state: {
                                         title: this.propstitle,
                                         listType: this.props.listType
                                       }
                                     }}>{product.alternateItem?.Number}</Link>
                                    </Table.Cell>
                                 ): <Table.Cell colSpan={'4'} className='boldText'></Table.Cell>}
                     <Table.Cell colSpan='2' className='center-text'>
                         <span className='color-red'>
                           {product?.Restrictions?.length > 0 ? utilities.restrictedDetail(product?.Restrictions) : ''}
                         </span>
                       </Table.Cell>
                   
                 </Table.Row>
             </Table.Body>
           </Table>
         <div id='snackbar'>Product added to cart</div>
       </>
     );
   };
 }
 
 const mapStateToProps = state => {
   return {
     getprefer:state.preference.listData,
     multiStoreDetails : state.SessionReducer.multiStoreDetails,
   };
 };
 
 export default connect( mapStateToProps, null)(ProductClassic);