import React, { Component } from 'react';
import './404_error_page.css';
import {Link} from 'react-router-dom';
import { getMappingJsonUrl, getfieldConnectUrl } from '../../urlConstants';
import _  from 'lodash'
import history from '../../history';
import restUtils from '../../utils/restUtils';
export default class NotFound extends Component {
  constructor(props){
    super(props);
    this.state = {
      loadMag: false
    }
  }
componentDidMount() {
  /*Aem redirect change is implemented only UAT and Prod. */
  restUtils.getDataWithoutToken(getMappingJsonUrl()).then((res)=>{
    const data = res.data
    const redirectUrl = _.find(data.mappedUrls,(url=> url.fromUrl === this.props.location.pathname))
    if(this.props.location.pathname.includes("/content/fieldconnect")|| this.props.location.pathname.includes("/content/fieldconnect/login.html")) {
      window.location.replace(getfieldConnectUrl())
    }
    else if(!_.isEmpty(redirectUrl)) {
      history.replace(redirectUrl.toUrl)
    }
    else {
      this.setState({loadMag: true})
    }
  }).catch(err=>{
    this.setState({loadMag: true})
  })
}
  render() {
    return (
      this.state.loadMag && (
        <div className="error_404_page"> 
          <h1 className="text_center">Sorry...</h1>
          <h2>The page you are looking for could not be found.</h2>
          <h3 className="text_center">Go back to <Link to="/home"><button>Home Page</button></Link></h3>
        </div>
      )        
    );
  }
}