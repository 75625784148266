import React from 'react';
import DatePicker from 'react-datepicker';
import utilities from '../../utils/utilities';
import "react-datepicker/dist/react-datepicker.css";
export const getTextNameField = (fieldName, id, value, onChange, labelClass, fieldClass, isLabelReq = true, keyPress, disabled = false, externalLink=false) => {
  const attr = {
    type:'text',
    className:fieldClass,
    value:value,
    id:id,
    name:id,
    disabled:disabled
  };

  if(onChange) {
    attr["onChange"] = onChange;
  }

  if(keyPress) {
    attr["onKeyDown"] = keyPress;
  }
  if (isLabelReq) {
    return (
      <div className='displayFlex'>
        <div className={labelClass}>
          {fieldName}
        </div>
        <div className={fieldClass}>
          <input {...attr} />
        </div>
      </div>
    );
  } else if(externalLink){
    return (
      <div className={fieldClass}>
        <div style={{width:"100%"}}>
          <input  style={{width:"93%"}} {...attr} />
          <a href={value} target="_blank"><i style={{marginLeft:"8px", color: 'black'}} class="fas fa-external-link-alt"></i></a>
          </div>
      </div>
    )
  } else {
    return (
      <div className={fieldClass}>
        <input {...attr} />
      </div>
    );
  }
};

export const getWidthImage = (data, onClick) => {
  return (
    <div style={{marginBottom:"1rem", marginTop:"1rem"}}>
      <div>
      <img src={data.imageUrl} alt="img" alturl="" height="40rem" width="40rem" style={{cursor: "pointer"}} onClick={onClick}/>
      </div>
      <label>{data.imageName}</label>
    </div>
  );
};

export const getTextAreaField = (fieldName, id, value, onChange, labelClass, fieldClass) => {
  return (

    <div className='displayFlex'>
      <div className={labelClass}>
        {fieldName}
      </div>
      <div className={fieldClass}>
        <textarea
          rows="4"
          cols="50"
          className={fieldClass}
          onChange={onChange}
          value={value}
          id={id}
          name={id}
        />
      </div>
    </div>
  );
};

export const getRadioField = (fieldName, id, options, value, onChange, labelClass, fieldClass, val) => {
  return (

    <div className='displayFlex'>
      <div className={labelClass}>
        {fieldName}
      </div>
      <div className={fieldClass}>
        {options.map(optionVal => {
          return (<>
            <input type="radio" id={optionVal} name={id} value={optionVal} onChange={onChange} />
            <label for={optionVal}>{optionVal}</label>
          </>)
        }
        )}
      </div>
    </div>
  );
};

export const getDatePicker = (fieldName, id, value, onChange, labelClass, fieldClass, isLabelReq = true, removeTime = false, isPastDate = false) => {
  if (isLabelReq) {
    
    return (
      <div className='displayFlex'>
        <div className={labelClass}>
          {fieldName} &nbsp;&nbsp;
        </div>
        <div className={fieldClass}>
          {removeTime?  <DatePicker selected={value} onChange={date => onChange(id,date)}/>:
          <DatePicker selected={value} onChange={date => onChange(id,date)} showTimeSelect timeFormat="p" timeIntervals={5} dateFormat="Pp"
          minDate={isPastDate ? new Date(utilities.getCurrentCstTime()) : '' }
          onChangeRaw={isPastDate ? (e) => e.preventDefault() : ''}
          />
          }
        </div>
      </div>
    );
  } else {
    return (
      <div className={fieldClass}>
        {
          removeTime?  <DatePicker id={id} selected={value} onChange={date => onChange(id,date)}/>:
        <DatePicker id={id} selected={value} onChange={date => onChange(id,date)} showTimeSelect timeFormat="p" timeIntervals={5} dateFormat="Pp"
        minDate={isPastDate ? new Date(utilities.getCurrentCstTime()) : '' }
        onChangeRaw={isPastDate ? (e) => e.preventDefault() : ''}
        />
        }      
      </div>
    );
  }
};

export const getSearchInput = (searchInput,searchIcon,searchLoad) => {
  return (
    <>
    <input
    type='text'
    {...searchInput}
    />
  <span {...searchIcon} >
    {searchLoad}
  </span>
  </>
  );
};

export const getSelectOptions = (option) => {
  return (
    <option {...option.props}>
      {option.text}
    </option>
  );
}

export const getSelect = (selectProps, options, isLabelReq, labelClass, fieldName) => {
  if(isLabelReq) {
    return (
      <div className='displayFlex'>
        <div className={labelClass}>
          {fieldName}
        </div>
        <select {...selectProps}>
        {
          options?.map(option => getSelectOptions(option))
        }
        </select>
      </div>
  
   );
  } else {
    return (
      <select {...selectProps}>
        {
          options?.map(option => getSelectOptions(option))
        }
      </select>
    );
  }
}

export const getTableRow = (rowProps, columns, isHeader,className) => {
  return (

    <tr {...rowProps} className={className? className:'wh-row'}>
      {!isHeader &&
        columns.map(column =>
          getTableColumn(column.columnProps, column.columnText)
        )
      }
      {isHeader &&
        columns.map(column =>
          getTableHdr(column.props, column.text)
        )
      }
    </tr>
  );
};

export const getTableColumn = (columnProps, columnText) => {
  return (
    <td {...columnProps}>
      {columnText}
    </td>
  );
};

export const getTableHdr = (headerProps, headerTxt) => {
  return (
    <th {...headerProps}>
      {headerTxt}
    </th>
  );
}

export const getTable = (tableProps, tableHeaders, tableData, getdataRows, dataRowProps, img_style) => {
  return (
    <table {...tableProps}>
      <thead>
        {tableHeaders}
      </thead>
      <tbody>
        {tableData?
          tableData.map((data,index) => {
            return (
              <>
                {img_style && getdataRows(dataRowProps, img_style, data,index)}
                {!img_style && getdataRows(dataRowProps, data,index)}
              </>
            );
          }) : "No Items to display!"
        }
      </tbody>
    </table>
  );
}

export const exportExcel = (props) =>{
  return (
    <span className={props.className}>
      <span>Export</span>
      <i
        className='file excel icon large'
        style={{ cursor: 'pointer', verticalAlign: 'initial' }}
        onClick = {props.onClick}
      ></i>
    </span>
  )
}

export const getButton = (props, text)  => {
  return (
    <button {...props}>
      {text}
    </button>
  );
}
export const getFirstCartId = (shoppingAreaId,promoNbr, carts, currentCartId) =>{
  let geReunionCarts = carts.filter(cart => cart.TypeId > 100)
  let getCarts  = geReunionCarts.filter(cart=>{
    let [shoppingId, eventId] = cart.cartName.split('-')[1].split('#')
    if(parseInt(shoppingId) === parseInt(shoppingAreaId) && parseInt(eventId) === parseInt(promoNbr)) {
      return cart
    }
  })
    let getCartsIds = getCarts.map(cart => cart.cartId)
    if(
      getCarts.length > 0 && !getCartsIds.includes(currentCartId)) {
      return getCarts[0].cartId      
    }
    else {
      return currentCartId
    }
}