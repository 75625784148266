export const USAStates = [
  {
    name: 'Select State',
    value: ''
  },
  {
    name: 'Alabama',
    value: 'AL'
  },
  {
    name: 'Alaska',
    value: 'AK'
  },
  {
    name: 'American Samoa',
    value: 'AS'
  },
  {
    name: 'Arizona',
    value: 'AZ'
  },
  {
    name: 'Arkansas',
    value: 'AR'
  },
  {
    name: 'California',
    value: 'CA'
  },
  {
    name: 'Colorado',
    value: 'CO'
  },
  {
    name: 'Connecticut',
    value: 'CT'
  },
  {
    name: 'Delaware',
    value: 'DE'
  },
  {
    name: 'District Of Columbia',
    value: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    name: 'Florida',
    value: 'FL'
  },
  {
    name: 'Georgia',
    value: 'GA'
  },
  {
    name: 'Guam',
    value: 'GU'
  },
  {
    name: 'Hawaii',
    value: 'HI'
  },
  {
    name: 'Idaho',
    value: 'ID'
  },
  {
    name: 'Illinois',
    value: 'IL'
  },
  {
    name: 'Indiana',
    value: 'IN'
  },
  {
    name: 'Iowa',
    value: 'IA'
  },
  {
    name: 'Kansas',
    value: 'KS'
  },
  {
    name: 'Kentucky',
    value: 'KY'
  },
  {
    name: 'Louisiana',
    value: 'LA'
  },
  {
    name: 'Maine',
    value: 'ME'
  },
  {
    name: 'Marshall Islands',
    value: 'MH'
  },
  {
    name: 'Maryland',
    value: 'MD'
  },
  {
    name: 'Massachusetts',
    value: 'MA'
  },
  {
    name: 'Michigan',
    value: 'MI'
  },
  {
    name: 'Minnesota',
    value: 'MN'
  },
  {
    name: 'Mississippi',
    value: 'MS'
  },
  {
    name: 'Missouri',
    value: 'MO'
  },
  {
    name: 'Montana',
    value: 'MT'
  },
  {
    name: 'Nebraska',
    value: 'NE'
  },
  {
    name: 'Nevada',
    value: 'NV'
  },
  {
    name: 'New Hampshire',
    value: 'NH'
  },
  {
    name: 'New Jersey',
    value: 'NJ'
  },
  {
    name: 'New Mexico',
    value: 'NM'
  },
  {
    name: 'New York',
    value: 'NY'
  },
  {
    name: 'North Carolina',
    value: 'NC'
  },
  {
    name: 'North Dakota',
    value: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    name: 'Ohio',
    value: 'OH'
  },
  {
    name: 'Oklahoma',
    value: 'OK'
  },
  {
    name: 'Oregon',
    value: 'OR'
  },
  {
    name: 'Palau',
    value: 'PW'
  },
  {
    name: 'Pennsylvania',
    value: 'PA'
  },
  {
    name: 'Puerto Rico',
    value: 'PR'
  },
  {
    name: 'Rhode Island',
    value: 'RI'
  },
  {
    name: 'South Carolina',
    value: 'SC'
  },
  {
    name: 'South Dakota',
    value: 'SD'
  },
  {
    name: 'Tennessee',
    value: 'TN'
  },
  {
    name: 'Texas',
    value: 'TX'
  },
  {
    name: 'Utah',
    value: 'UT'
  },
  {
    name: 'Vermont',
    value: 'VT'
  },
  {
    name: 'Virgin Islands',
    value: 'VI'
  },
  {
    name: 'Virginia',
    value: 'VA'
  },
  {
    name: 'Washington',
    value: 'WA'
  },
  {
    name: 'West Virginia',
    value: 'WV'
  },
  {
    name: 'Wisconsin',
    value: 'WI'
  },
  {
    name: 'Wyoming',
    value: 'WY'
  }
];

export const exportColumnDefs = {
  responseDataPath: ['data', 'addressList'],
  colDefs: [
    {
      header: 'Name',
      key: 'contactName'
    },
    {
      header: 'Attention To',
      key: 'customerName'
    },
    {
      header: 'Address',
      key: ['addressLine1', 'city', 'state', 'zip5']
    }
  ]
};


export const colDefs = [
  {
    heading: 'Name *',
    key: 'customerName'
  },
  {
    heading: 'Attention to*',
    key: 'contactName'
  },
  {
    heading: 'Address Line 1 *',
    key: 'addressLine1'
  },
  {
    heading: 'Address Line 2',
    key: 'addressLine2'
  },
  {
    heading: 'City *',
    key: 'city'
  },
  {
    heading: 'State *',
    key: 'state'
  },
  {
    heading: 'Zip *',
    key: 'zip5'
  },
  {
    heading: 'Area',
    key: 'zip4'
  },
  {
    heading: 'Phone Area',
    key: 'phoneArea'
  },
  {
    heading: 'Phone Exchange',
    key: 'phoneExchange'
  },
  {
    heading: 'Phone Line',
    key: 'phoneLine'
  },
  {
    heading: 'Fax Area',
    key: 'faxArea'
  },{
    heading: 'Fax Exchange',
    key: 'faxExchange'
  },
  {
    heading: 'Fax Line',
    key: 'faxLine'
  },
  {
    heading: 'Email',
    key: 'email'
  },
  {
    key: 'deleteitem'
  }
];