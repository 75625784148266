import React from 'react';
import { Loader } from 'semantic-ui-react';
import RefineFilterItemOption from './RefineFilterItemOption';

const RefineFilterItem = ({
  id,
  filterTitle,
  filterOptions,
  name,
  optionTypes,
  index,
  toggleFilterCategory,
  isToggled,
  handleChange,
  selectedOption,
  selectedOptions,
  isLoading
}) => {
  let isFilterApplied = false;

  if (optionTypes === 'checkbox') {
    isFilterApplied = selectedOptions.length > 0 && filterOptions && filterOptions.length > 0;
  } else {
    isFilterApplied = !selectedOption && filterOptions && filterOptions.length > 0;
  }

  return (
    <div className='filter-section' id={id}>
      <span className={isFilterApplied ? 'filter-head applied-filter-head' : 'filter-head'} id={'fh-' + filterTitle}>
        {filterTitle}
        <i className={isToggled ? 'minus icon toggleIcon' : 'plus icon toggleIcon'}
          data-div-index={index}
          onClick={toggleFilterCategory}
        >
        </i>
      </span>
      {isToggled && (
        <div id={'contents-' + index}>
          { isLoading && (
            <div>
              <Loader active className="loader-fineline"/>
            </div>
          )}
          {filterOptions && filterOptions.map(item => {
            let isOptionSelected = false;

            if (optionTypes === 'radio') {
              isOptionSelected = selectedOption && (selectedOption.id === item.id);
            } else if(optionTypes === 'checkbox') {
              isOptionSelected = selectedOptions.find((o) => o.id === item.id);
            }

            return (
              <RefineFilterItemOption
                key={item.id}
                filterTitle={filterTitle}
                optionTypes={optionTypes}
                item={item}
                handleChange={handleChange}
                selectedOption={isOptionSelected}
                filterName={name}
              />
            )
          })}
        </div>
      )}
    </div>
  )
};

export default RefineFilterItem;