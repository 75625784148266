import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import restUtils from '../../utils/restUtils';
import _ from 'lodash';
import { Loader,Form } from 'semantic-ui-react';
import utilities from "../../utils/utilities";
import {switchStore} from "../../views/Login/actions"
import { GET_STOREINFO, GET_BRANCH_LISTINFO} from "../../urlConstants"
class ChangeStore extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            storeNo:'',
            isError: false,
            childStore:[],
            isLoading: false,
        }
        
    }
    componentDidMount() {
        if(!utilities.checkCsrUser(this.props.multiStoreDetails)) {
            this.setState({isLoading: true})
                restUtils.postDataWithBearer(GET_BRANCH_LISTINFO).then(res=>{
                    this.setState({childStore: res?.data})
                    this.setState({isLoading: false})
                }).catch(err=>{
                    this.setState({isLoading: false})
                })
        }
    }
    handleChangeVal = (event) => {
        this.setState({ isError: false})
        const { value } = event.target;
        //const regularExpression = /^\d{0,6}$/
            if(isNaN(value)) { 
                utilities.showToast(`Store No must be numeric`,false);      
                return;
            }
    this.setState({
        storeNo: value
    })
    }
    handleSelectOpt = (event)=>{
        const { value } = event.target;
        this.setState({
            storeNo: value
        })
    }
    changeStoreRequest = () => {
        if(this.state.storeNo === '') {
            utilities.showToast(`Please Enter Store No`,false); 
            return
        }
        const payLoad =  {
            "storeNumber":this.state.storeNo,
        }
        restUtils.postDataWithBearer(GET_STOREINFO,payLoad).then(res=>{
            this.props.switchStore(this.state.storeNo, this.props.userName)
            this.setState({isError: false})
        }).catch(err=> {
            this.setState({isError: true})
        })
    }
    render() {
        const msCheck = this.state.childStore;
        return (
            <>
            <div className='changeStoreContainer' ref={node => (this.componentRef = node)}>
            <h3>Change Store</h3>
            <Form>
                <Form.Field>
                    <div>
                        { utilities.checkCsrUser(this.props.multiStoreDetails) ? <label>Enter a Store Number:</label>
                        : msCheck && msCheck.length > 0 ? <label>Select a Store Number:</label> :''}                       
                        <div className='changeStoreFields'>
                            {utilities.checkCsrUser(this.props.multiStoreDetails) ?
                              <input type='text' autoFocus={true} value={this.state.storeNo || ''} onChange={this.handleChangeVal}/>
                            : this.state.isLoading ? <Loader active /> :
                                msCheck.length > 0 ? (
                                <select onChange={this.handleSelectOpt}>
                                    <option value={this.state.storeNo || ''} disabled selected  >Select Store</option>
                                    {_.map(this.state.childStore, (store)=>{   
                                        return <option value={store.Number}>{store.Number}-{store.Name}</option>                                                                         
                                    })}
                                </select>
                            ):
                            <p style={{fontWeight:'normal'}}><strong className='boldFont'>Oops!</strong>You cannot change to another store because you have only one store associated with your profile</p>
                            }
                            
                       {(utilities.checkCsrUser(this.props.multiStoreDetails) || (msCheck && msCheck.length > 0 )) && <button className=''  onClick={this.changeStoreRequest}>GO</button>} 
                        </div>
                    </div>
                </Form.Field>
            </Form>
            <div className='closeBtnPopUp'>
                <button onClick={this.props.handleClose}>Close</button>
            </div>
            
            {this.state.isError&&<p style={{color:'#c6112d'}}>Please provide valid store number.</p>}
            </div>
         </>
        )
       
    }
}
const mapDispatchToProps = (dispatch)=>{ 
    return Object.assign({dispatch},
      bindActionCreators({switchStore},dispatch)) 
  } 
  const mapStateToProps = (state) => { 
    return {
        multiStoreDetails: state.SessionReducer.multiStoreDetails,
        userName: state.SessionReducer.userName,
        userguid: state.SessionReducer.userguid,
        storeguid: state.SessionReducer.storeguid
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(ChangeStore);