import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import jsPDF from "jspdf";
import tv_logo from '../images/Logo.svg.png'
import 'jspdf-autotable';
import utilities from '../../utils/utilities';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants'

export default class ExcelExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }
  shipNameAndAddress = (customerAddress, doc, index) =>{
    const addressKeys = Object.keys(customerAddress);
    let text = ''
    addressKeys.forEach((key, _)=>{
      text+=customerAddress[key] + ' ';
    });
    return text
    }
 
  exportToPdf = (order, storeId, poNum, orderTotal,shipByOrder, viewType, getprefer,cartProducts,flowType) => {
    this.setState({loading: true})
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = tv_logo;
    const rdcblocks = document.getElementsByClassName(this.props.tableId);
    doc.addImage(img, 'png', 15, 5, 60, 30);

    var specialElementHandlers = {
      '#pdf_img': function (element, renderer) {
        return true;
      }
    };

    doc.fromHTML(document.getElementById("confirmation_header"), 15, 15, {
      'width': 800, 'elementHandlers': specialElementHandlers
    });

    doc.setFont("HelveticaNeue", 'normal');
    doc.setFontSize(13);
    const totalPagesExp = "{total_pages_count_string}";
    let  styleY;
    Array.prototype.forEach.call(rdcblocks,(rdcblock,index) => { 
      if(index !== 0 && doc.lastAutoTable.finalY > 360){
        doc.addPage();
        styleY =  30;
      } else {
        styleY = doc.lastAutoTable.finalY
      }
        doc.text(`Store #:`, 15, index===0?165:styleY + 20)
        doc.text(`${storeId[index] ? storeId[index] : storeId[0]}`, 130, index===0?165:styleY + 20)
        doc.text(`Order Date:`, 15, index===0?180:styleY + 35)
        doc.text(`${utilities.getDateFromTimeStamp1().replace(/[\u200E]/g, '')}`, 130, index===0?180:styleY + 35)
        order && order[index]?.DisplayName !== 'Retail Assortments' &&
        (
        flowType !==2 && poNum[storeId[index]] &&
        doc.text(`PO #:`, 15, index===0?195:styleY + 50)
        )
        order && order[index]?.DisplayName !== 'Retail Assortments' &&
        (
        flowType !==2 && poNum[storeId[index]] &&
        doc.text(`${poNum[storeId[index]]}`, 130, index===0?195:styleY + 50)
        )
        doc.text("Source:", 15,  index===0?poNum[storeId[index]]?210:195:styleY + (poNum[storeId[index]]?65:50))
        doc.text("True Value Company", 130,  index===0?poNum[storeId[index]]?210:195:styleY + (poNum[storeId[index]]?65:50))
        doc.text(`Ship To:`, 15,  index===0?poNum[storeId[index]]?225:210:styleY + (poNum[storeId[index]]?80:65))
        doc.text(`${cartProducts[index]?.[0]?.customerAddress ? this.shipNameAndAddress(cartProducts[index][0].customerAddress): 'Store'}`, 130,  index===0?poNum[storeId[index]]?225:210:styleY + (poNum[storeId[index]]?80:65))

        if(viewType === MEMBER_VIEW || ( viewType !== MEMBER_VIEW && (getprefer !== "c3" && getprefer !== "c4"))){
          doc.text(`${cartProducts[index]?.[0]?.subTotal ?`${utilities.getLabelsByType(viewType,"SUB_TOTAL", getprefer)}:`:''}`, 15, index===0?poNum[storeId[index]]?240:225:styleY + (cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?95:80 : poNum[storeId[index]]?80:65)) 
          doc.text(`${cartProducts[index]?.[0]?.subTotal ? utilities.getCostBasedOnnatvalue(viewType,cartProducts[index]?.[0]?.subTotal,getprefer) : ''}`, 130, index===0?poNum[storeId[index]]?240:225:styleY + (cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?95:80 : poNum[storeId[index]]?80:65)) 
        }
        
        doc.text(`Shipping Method:`, 15, index===0?cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]] ? 255: 240 : poNum[storeId[index]]? 240: 225:styleY + (cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?110:95 : poNum[storeId[index]]?95:80))
        doc.text(`${cartProducts[index]?.[0]?.shipByOrder ? cartProducts[index][0].shipByOrder :'Regular Delivery'}`, 130, index===0?cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]] ? 255: 240 : poNum[storeId[index]]? 240: 225:styleY + (cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?110:95 : poNum[storeId[index]]?95:80))
        
        if(viewType === MEMBER_VIEW || ( viewType !== MEMBER_VIEW && (getprefer !== "c3" && getprefer !== "c4"))){
          doc.text(`${utilities.getLabelsByType(viewType,"ORDER_TOTAL", getprefer)}:`, 15, index===0? cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?270:255 : poNum[storeId[index]]?255:240 :styleY + (cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?125:115 : poNum[storeId[index]]?115:95))
          doc.text(`${utilities.getCostBasedOnnatvalue(viewType, orderTotal[index], getprefer)}`, 130, index===0? cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?270:255 : poNum[storeId[index]]?255:240 :styleY + (cartProducts[index]?.[0]?.subTotal ? poNum[storeId[index]]?125:115 : poNum[storeId[index]]?115:95))
        }
        
        var res = doc.autoTableHtmlToJson(rdcblock);
        // let columnStyles = {text:{cellWidth:'wrap'}};
        // columnStyles = this.props.isRA? columnStyles:{...columnStyles,4:{cellWidth:150}};
        doc.autoTable(res.columns, res.data, {
          margin: { left:15 }, startY: index>0?flowType===1 ? styleY + 220 :styleY + 165 :doc.pageCount > 1 ? doc.autoTableEndPosY() + 50 : cartProducts[0]?.[index]?.subTotal ?285 : 270,
          styles: { overflow: 'linebreak',columnWidth: 'wrap', cellWidth:(flowType && (flowType===1 || flowType===3 || flowType===4 || flowType===5))?70: 'wrap', font: 'HelveticaBold', fontSize:(flowType && (flowType===1 || flowType===3 || flowType===4 ))?8:9, textColor: 'black' },
          columnStyles:{
            2: {
              columnWidth: 'auto'
            },
            3: {
              columnWidth: 'auto'
            },
            4: {
              columnWidth: 'auto'
            }
          },
          headStyles: {
            fillColor: '#d6d8db',
            textColor: 'black'
          },
          didDrawPage: data => {
            let footerStr = "Page " + doc.internal.getNumberOfPages();
            if (typeof doc.putTotalPages === 'function') {
              footerStr = footerStr + " of " + totalPagesExp;
            }
            doc.setFontSize(11);
            doc.text(footerStr, 305, doc.internal.pageSize.height - 10);
          }
        });
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.setFontSize(11)
    doc.text("If you have any questions, please contact customer service at 800-621-6025", 240, doc.previousAutoTable.finalY + 20)
    doc.save("OrderConfirmation.pdf");
    this.setState({loading: false})
  };

  render() {
    return (
      <>
        {this.state.loading &&
          <div>
            <Loader active />
          </div>
        }

        <span style={{ right: '12px', position: 'absolute' }}>
          <i className="file pdf icon large" style={{ cursor: 'pointer', verticalAlign: 'initial' }} 
          onClick={() => this.exportToPdf(this.props.order,
                                          this.props.storeIds, 
                                          this.props.poNum, 
                                          this.props.orderTotal, 
                                          this.props.shipByOrder, 
                                          this.props.viewType,
                                          this.props.getprefer,
                                          this.props.cartProducts,this.props.flowType)}></i> 
        </span>
      </>
    )
  }
}
