import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Checkbox, Grid, Table } from 'semantic-ui-react';
import renderImages from '../common/RenderImages';
import Dialog from 'react-dialog';
import {MaskPrice} from '../common/MaskPrice';
import utilities, {getPrefernceType, shouldHideCostForPreference, shouldHideSUGG_RETAILForPreference} from '../../utils/utilities';
import { TierIcon } from '../common/TierIcon';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import QuickViewDetails from '../common/QuickViewDetails';
import { closeModal } from '../common/productHandler';

class ProductsCard extends React.Component{
  constructor(props){
    super(props);
    this.closeModal = closeModal.bind(this);
    this.state = {
      expand: '',
      visible: false,
      item: '',
      click: '',
      quantity:utilities.emptyForNull(props.product.cart_available),
      overrideRetail:utilities.emptyForNull(props.product.cart_overrideretail),
      isMultistoreModalOpen:false,
    }
  }

  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  handleChange = (e) =>{
    const type = e.target.dataset.field;
    const value = e.target.value;
    if (type === 'qty' &&   parseFloat(value) <= 0) {
      utilities.showToast('Quantity should be positive number!', true);
      return;
    }
    if(type === 'qty' && /^(\d+)$|^$/.test(value)){
      this.setState({quantity:value});

    }else if((type === 'or' || type ==='override') && /^\d*\.{0,1}(\d+)?$/){
      this.setState({overrideRetail:value})
    }
  }

  closeMultistoreModal=()=>{
    this.props.closeMultistoreModal();
    this.setState({ isMultistoreModalOpen: false });
  }
  handleKeyDown = (e, product)=>{
    const msCheck = utilities.msExist(this.props.multiStoreDetails);
    if(this.props.multiStoreDetails.access !== 1 && e.key === 'Enter' && !this.props.isReunionOnlyStore){
        if(msCheck && msCheck > 1){
          this.multistoreHandleCarts(product);
      }
      else{
        this.props.addToCart(product.itemNbr, product.memberCost);
      }
  }
  }
  openModal(data) {
    this.setState({
      visible: true,
      item: data,
      click: 'clicked',
      expand: ''
    });
    this.props.blurfun && this.props.blurfun(true);
  };

  render(){
    const { product, addToCart } = this.props;
    const isDiscontinuedHideAddToCart = product.StockStatus === 'DIS' ? true : false
    const memberCost = product.pricing ?
                        utilities.getCostForQtyPlp(product.pricing, this.state.quantity)
                          :'';
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const getPrefer =  getPrefernceType(this.props); 
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const showSuggRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
    return (
      <div className = 'grid-column card card_item favList'>
        {this.state.visible && this.state.item === product.itemNbr && (
          <div className='QuickviewDialoggrid' onMouseLeave={this.closeModal}>
            <Dialog width='1' height='1' position='absolute'>
              <QuickViewDetails  tableType={'favorites'} Item_Number={product.itemNbr} productPlp = {product} />
            </Dialog>
          </div>
        )}
        <MultistoreToCartModal
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          product={this.state.productSelectedForModal}
          closeMultistoreModal={this.closeMultistoreModal}
          indexCheck={this.props.indexForModalMs}
          tableType={'favorites'}
          quantitiesMs={this.state.quantity}
          handleFavItemChange={this.handleChange}
          overrideRetail = {this.state.overrideRetail}
        ></MultistoreToCartModal>
          <div   className="ui segment plp-grid zero-padding">
          <div className = 'grid-header'>
            <div></div>
            { !this.props.isMomentum && <div>
              <b style={{ paddingRight: '0.3rem' }}>Remove </b>
              <Checkbox
                checked={product.selected}
                onClick={() => this.props.selectForRemoval(product.itemNbr)}
              />
            </div>}
          </div>
          <div className='card_img grid-image'>
            <Link onClick={this.props.setBackButtonPath}  to={{
                      pathname:'/product/'+product.itemNbr,
                      state:{
                        source:[{text:'FAVORITES',link:"/favorites"}]
                      }
                    }}>
              {renderImages.getImage({
                src: this.props.imageUrl,
                className: 'ui small image image_lisitng_grid grid_image',
                alt: 'product',
                style: { paddingTop: '2rem',display: this.props.img_style}
              })}
            </Link>
          </div>
          <div className = 'header'>
          {
            window.innerWidth >= 1024 &&(
              <div
              style = {{textAlign:'center'}}
              className='view_button-compact-visible'
              onClick={() => {
                this.openModal(product.itemNbr);
              }}
            >
              Quick View
            </div>
            )
          }
            <div className = 'grid_title'>
          <div style={{ marginBottom: '0' }} className='t-center vendor-span-compact vendor-span'>
            <Link onClick={this.props.setBackButtonPath} 
             to={{pathname:product.vendorName?'/vendor/' + encodeURIComponent(product.vendorName):'#',
             state: { vendorId: product.vendorId }}}>
              {product.vendorName}
            </Link>{' '}
          </div>
          <div className='t-center' style={{ fontFamily: 'HelveticaNeueBold' }}>
          <Link
                onClick={this.props.setBackButtonPath}
                to={{
                  pathname: '/product/' + product.itemNbr,
                }}
                >
                {product.shortDescription}
          </Link>
          </div>        
          </div>
          </div>
          <div className='gridfavourite' style = {{lineHeight: '32px', minHeight: '32px'}}>
              {(product?.Restrictions?.length > 0)? utilities.restrictedDetail(product?.Restrictions) : ''}
            </div>
          <div className='card_description content' style={{ paddingBottom: '0.2rem' }}>
            <div className='card_fields'>
            <Table className = 'very padded unstackable table grid-table'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                  <span className='First_level_details-grid-compact'>Item#:</span>
                    </Table.Cell>
                    <Table.Cell>
                    <b className='First_level_details-grid-compact'>{product.itemNbr}</b>
                      </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                  <span className='First_level_details-grid-compact'>Model#:</span>
                  </Table.Cell>
                  <Table.Cell> <b className='First_level_details-grid-compact'>{product.Model}</b></Table.Cell>
                </Table.Row>
                {this.props.multiStoreDetails 
                  && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && 
                <Table.Row>
                  <Table.Cell>
                    <span className='First_level_details-grid-compact'>Alternate SKU#:</span>
                  </Table.Cell>
                  <Table.Cell>
                    <b className='First_level_details-grid-compact'>{product.aliasSku}</b>
                  </Table.Cell>
                </Table.Row>
                } 
                <Table.Row>
                  <Table.Cell>
                  <span className='First_level_details-grid-compact'>
                    Available:
                  </span>
                  </Table.Cell>
                  <Table.Cell>
                  <b className={
                    product.available && 
                      parseInt(product.available) > 0 
                        ? 'First_level_details-grid-compact'
                        : 'First_level_details_red First_level_details-grid-compact cost_red_color'
                      }> {utilities.checkForHubAndSpokeIndictor(product)}
                  </b>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
              <Table.Cell>
              <span className='First_level_details-grid-compact'>Pack:</span>
              </Table.Cell>
              <Table.Cell>
              <b className='First_level_details-grid-compact'>
                {product.sPackType}
                {product.sPackQty}
              </b>
              </Table.Cell>
            </Table.Row>
                { displayMemberCostAndLbl &&
              <Table.Row>
              <Table.Cell>
              <span className='First_level_details-grid-compact'>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}:</span>
              </Table.Cell>
              <Table.Cell>
                 <b className='First_level_details-grid-compact'>
                 {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1 && this.props.multiStoreDetails.CatMbr))?
                <MaskPrice  getprefer = {(this.props.getprefer && this.props.getprefer.customerView) ? this.props.getprefer.customerView: 'c1'} viewType={this.props.viewType} fieldVal={memberCost} field='Member Cost'/>
                :
                ""
                }
              {product.pricing && product.pricing.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW ? <TierIcon tierLevel={utilities.getTierLevelForQtyPlp(product.pricing, this.state.quantity)}
                tierLetter={this.state.quantity && parseInt(this.state.quantity) > 0 ? true : false}/>:<></>}
              </b>
                </Table.Cell>
              </Table.Row>
            }
            {showSuggRetailPrice &&
            <Table.Row>
              <Table.Cell>
              <span className='First_level_details-grid-compact'>{utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL",getPrefer)}:</span>
              </Table.Cell>
              <Table.Cell>
                 <b className='First_level_details-grid-compact'>
                 {this.props.getSuggestedRetail(product)}
              </b>
                </Table.Cell>
            </Table.Row>
            }
            {product.maxQty ? (
            <Table.Row>
                <Table.Cell>
                <span className='First_level_details-grid-compact'>Max Qty:</span>
                </Table.Cell>
                <Table.Cell className='First_level_details-grid-compact'>{product.maxQty}</Table.Cell>
              </Table.Row>): null}
              {!utilities.isEmptyOrNullString(product?.StockStatusMessage) ? <Table.Row>
                     <Table.Cell className='First_level_details-grid-compact'>Stock Status:</Table.Cell>
                     <Table.Cell colSpan= "3" className='First_level_details-grid-compact'><span className="stockStausMsg">{product.StockStatusMessage}</span></Table.Cell>
             </Table.Row> : null }
             {this.props.isMomentum ? <Table.Row>
                     <Table.Cell className='First_level_details-grid-compact'>Momentum Code:</Table.Cell>
                     <Table.Cell colSpan= "3" className='First_level_details-grid-compact'><span className="stockStausMsg">{product.MomentumCode}</span></Table.Cell>
             </Table.Row> : null }
            <Table.Row>
              <Table.Cell>
              <span className='First_level_details-grid-compact'>Override Retail:</span>
              </Table.Cell>
              <Table.Cell><b className='First_level_details-grid-compact'>
                <input
                  className='qty-box'
                  data-field='or'
                  maxLength={7}
                  onChange={this.handleChange}
                  value = {this.state.overrideRetail}
                  id={'or-' + product.itemNbr}/>
              </b></Table.Cell>
            </Table.Row>
            {product.alternateItem?.Number && product.alternateItem?.Number >0 ? (
              <Table.Row>
                <Table.Cell>
                  <span>{product.alternateItem?.Label}</span>
                </Table.Cell>
                <Table.Cell>
                  <Link class="altItemNo" to={{ pathname: '/product/' + product.alternateItem.Number}}>
                     {product.alternateItem.Number}
                  </Link>
                </Table.Cell>
              </Table.Row>
            ):null}
            <Table.Row>
              <Table.Cell>
              <span className='First_level_details-grid-compact'>Quantity:</span>
              </Table.Cell>
              <Table.Cell>
              <span>
                <input
                  className='qty-box'
                  data-field='qty'
                  maxLength={7}
                  onChange={this.handleChange}
                  value = {this.state.quantity}
                  onKeyDown={(e)=>this.handleKeyDown(e, product)}
                  id={'qty-box' + product.itemNbr}
                />
              </span>
              </Table.Cell>
            </Table.Row>
              </Table.Body>
            </Table>
            {/* <>
            <Grid.Column>
              <span className='card_name'>Sugg. Retail:</span>
              </Grid.Column>
              <Grid.Column>
              <b className='First_level_details'>
              {this.props.getSuggestedRetail(product)}   
              </b>
              </Grid.Column>
              </> */}
            </div>
          </div>
          </div>
          <div className = 'card_footer'>
        {this.props.multiStoreDetails.access !== 1 && !isDiscontinuedHideAddToCart &&
        <>
         {msCheck && msCheck > 1 ?
         !this.props.isReunionOnlyStore ?
          <button
          className='red ui button grid_button-compact'
          onClick={() =>
            this.multistoreHandleCarts(product)
          }
        >
          Add to Store
        </button>
        : <>
            <button className='red ui button grid_button-compact' disabled>Add to Store</button>
            <p class="disabledMessage">*Ordering restricted</p>
          </>
        :!this.props.isReunionOnlyStore ?
        <button
          className='ui red button grid_button-compact'
          id='button_grid'
          onClick={() =>
            addToCart(product.itemNbr, product.memberCost)
          }
        >
          Add to Cart
        </button>
        : <>
            <button className='red ui button grid_button-compact' disabled>Add to Cart</button>
            <p class="disabledMessage">*Ordering restricted</p>
          </>
        }
        </>
        }
        </div>
      </div>
    );
  };

}

const mapStateToProps = state => {
  return {
    getprefer:state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails
  };
};

export default connect(mapStateToProps, null)(ProductsCard);