import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import ImageCarousel from '../common/ImageCarousel';
import renderImages from '../common/RenderImages';
import bottom_img1 from '../images/Impulsemerch-tile3.jpg';
import redArrow from '../images/ArrowIcon.png'
import { restUtils } from '../../utils/restUtils';
import './styles.css';
import { newItemsCarouselUrl, statmentAndPayment } from '../../urlConstants';
import { connect } from 'react-redux';
import { Grid, Segment, Menu, Responsive, Container } from 'semantic-ui-react';
import Dialog from 'react-dialog';
import { getCartInfo, notifyCarts, MarketFLAG, ReunionOrdering } from '../Cart/actions';
import {onlyReunion} from '../Login/actions'
import { bindActionCreators } from 'redux';
import { switchView } from '../../components/NavBar/actions' ;
import { Event } from '../../history';
import utilities, {saleType} from '../../utils/utilities';
import _ from 'lodash';
import history from '../../history';
import { deals,  getWordpressCustomContent } from '../../../src/urlConstants';
import GetContent  from '../WordPress/GetContent';
import Slider from "react-slick";
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import WpHompageContent from './WpSection';
const NextArrow = (props) =>{
  const { className, style, onClick } = props;
  return (
    <p
    className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}>Next</p>
  )
}
const PrevArrow = (props) =>{
  const { className, style, onClick } = props;
  return (
    <p
    className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}>Prev</p>
  )
}
class Home extends Component {
  constructor(props){
    super(props);
    this.state={
        newProducts:[],
        slideshowImages:[],
        wpSliderContent:[],
        productShowcase:[],
        isPopupShow: this.props.onReunionPopup === undefined ? true : false,
        dartCartReportData: [],
        wpContentSection: null,
        yellowAlertMsg: null,
        wpSectionLoaded: false,
        sliderSettings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
          ]
        }
    }
  }

  triggerHomeEvents = (eventDescription,url)=>{
    Event("HOME",eventDescription);
    utilities.openInsameTab(url);
  }

  triggerHomeEventsNew = (eventDescription, url, openNewTab) => {
    Event("HOME",eventDescription);
    if(openNewTab == 1){
      utilities.openInNewTab(url);
    } else {
      utilities.openInsameTab(url);
    }
  }
  
  componentDidMount() {
    if(window.innerHeight >= 900) {
      this.setState({
        wpSectionLoaded: true
      })
    }
    window.addEventListener('scroll', this.handleScroll)
    restUtils.getDataWithBearer(`${getWordpressCustomContent()}yellow-bar-alerts`).then(res=>{
      const msg = res.data
      if((msg?.global_alerts && msg.global_alerts.length > 0) || (msg?.rdc_alerts && msg.rdc_alerts.length > 0)){
        this.setState({yellowAlertMsg: msg})
      }
    }).catch(err=> console.log(err))
    restUtils.getDataWithBearer(`${getWordpressCustomContent()}banner-rotator`).then(response=>{
    let slideshowImages = response.data.map(item=>{
        return {image:item.featured_image?.full, url:item?.banner_link?.url, isCmsPage: item?.cms_page,
          id:item?.banner_link?.slug
        }
      })
      this.setState({
        slideshowImages
      })
    })
    this.props.getCartInfo();
    this.props.notifyCarts();
    this.props.MarketFLAG();
    this.props.ReunionOrdering(this.props.storeId);
    let isItemDtlFromWH = this.props.multiStoreDetails && this.props.multiStoreDetails.isWHFromPendingCheck 
                            && this.props.multiStoreDetails.userids !== null && 
                              this.props.multiStoreDetails.userids[0].storeID !== this.props.storeId; 
    restUtils.getData(newItemsCarouselUrl+"?memberNumber="+this.props.storeId+
                            "&rdc="+this.props.rdc+"&isItemDtl="+isItemDtlFromWH)
    .then(response=>{
      let productShowcase = response.data.map(product=>{
        return {product:product,image:'https://images.truevalue.com/getimage.asp?id=' +
                                  product.ItemNumber +
                                  '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2'}
      })
        this.setState({
          newProducts:response.data,productShowcase
      })
    })
  }
  handleScroll = (event) => {
    if(Math.round((window.scrollY /(document.body.scrollHeight -window.innerHeight)*100) > 30) && !this.state.wpSectionLoaded) {
      this.setState({
        wpSectionLoaded: true
      })
    }
}
  closePopUp = () => {
    this.props.onlyReunion()
    this.setState({isPopupShow: this.props.onReunionPopup})
  }
  render() {
    const rdcMsg = this.state.yellowAlertMsg?.rdc_alerts && this.state.yellowAlertMsg.rdc_alerts.length > 0 ? 
      this.state.yellowAlertMsg.rdc_alerts.filter(ele=> parseInt(ele.number) === parseInt(this.props.rdc)) : [];
    return (
      <>
      {this.state.yellowAlertMsg && ((this.state.yellowAlertMsg.global_alerts && this.state.yellowAlertMsg.global_alerts.length > 0) || (rdcMsg && rdcMsg.length > 0)) && (
        <div className='yellowAlertMsg'>
          { this.state.yellowAlertMsg.global_alerts && this.state.yellowAlertMsg.global_alerts.length > 0 && this.state.yellowAlertMsg.global_alerts[0]?.page_title && <p><strong>{this.state.yellowAlertMsg.global_alerts[0]?.page_title}:</strong>{!_.isEmpty(this.state.yellowAlertMsg.global_alerts[0]?.global_alert_message) && <span dangerouslySetInnerHTML={{__html: this.state.yellowAlertMsg.global_alerts[0].global_alert_message}}></span>}</p>}
           {rdcMsg && rdcMsg.length >0 && (
            <>
              {this.state.yellowAlertMsg.global_alerts && this.state.yellowAlertMsg.global_alerts.length > 0 &&  this.state.yellowAlertMsg.global_alerts[0]?.page_title && <div className="dividerLine"></div>}
              { rdcMsg[0]?.page_title && <p style ={{marginTop: '4px'}}><strong>{rdcMsg[0].page_title}:</strong>{!_.isEmpty(rdcMsg[0]?.rdc_alert_message) && <span dangerouslySetInnerHTML={{__html: rdcMsg[0].rdc_alert_message}}></span>}</p>}
            </> 
           )}
        </div>
      )}
      <Segment className='carousel_main' vertical>
        <Responsive>
          <Grid stackable columns={3} style={{marginLeft:0, marginRight:0}}>
            <Grid.Row columns={5} className="homePageWrapper"> 
              <Grid.Column width={4}  className="qickLinkWrap">

                { this.props.multiStoreDetails && this.props.multiStoreDetails.access !==1 &&
                <>
                <Menu className="quick-links" vertical>
                  <Menu.Item className="quickLinkheader">ORDERING QUICK LINKS<span><img src={redArrow}/></span></Menu.Item>
                  <Menu.Item
                    name='allorders'
                  ><Link  to='/allorders' className="menu-items">Order Status</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='editOrders'
                  ><Link to='/editOrders' className="menu-items">Edit Orders</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='shipLaters'
                  ><Link to='/shipLaters' className="menu-items">Ship Laters</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='autoShip'
                  ><Link to='/autoShip' className="menu-items">Auto-Ship</Link>
                  </Menu.Item>
                  <Menu.Item
                    name={deals}
                  > <Link to='/listing/HotDeals' className="menu-items">{deals}</Link>
                  </Menu.Item>
                    <Menu.Item
                    name='CloseoutItems'
                  ><Link to='/listing/CloseoutItems' className="menu-items">Closeout Items</Link>
                    </Menu.Item>
                    {this.props.isRetailAssortmentsEnable && (<Menu.Item
                    name='Retail Assortments'
                  ><Link to='/retailAssortmentsLinks' className="menu-items">Retail Assortments</Link>
                  </Menu.Item>)}
                  {this.props.isCtbAssortmentsEnable && (<Menu.Item
                    name='CTB Assortments'
                  ><Link to='/ctbAssortments' className="menu-items">CTB Assortments</Link>
                  </Menu.Item>)}
                  <Menu.Item
                    name='orderpad'
                  ><Link to='/orderpad' className="menu-items">Order Pad</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='orderupload'
                  ><Link to='/orderupload' className="menu-items">Order Upload</Link>
                  </Menu.Item>                     
                </Menu>
                <Menu className="quick-links" vertical>
                  <Menu.Item className="quickLinkheader">BUSINESS TOOL QUICK LINKS<span><img src={redArrow}/></span></Menu.Item>
                  <Menu.Item
                    name='AddressBook'
                  ><Link to='/AddressBook' className="menu-items">Address Book</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='favorites'
                  ><Link to='/favorites' className="menu-items">Favorite Items</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='Discontinued'
                  ><Link to='/listing/Discontinued' className="menu-items">Discontinued Items</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='Planograms'
                  ><Link to='/planogramLinks' className="menu-items">Planograms</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='Order Status'
                  ><Link to='/fillRates' className="menu-items">Fill Rates Report</Link>
                  </Menu.Item>
                  <Menu.Item
                    name='Restricted Items'
                  ><Link to='/appRedirect?appName=Restricted Items Report' className="menu-items">Restricted Items</Link>
                  </Menu.Item>
                </Menu>
                <Menu className="quick-links" vertical>
                  <Menu.Item className="quickLinkheader">INFORMATION QUICK LINKS<span><img src={redArrow}/></span></Menu.Item>
                  {/*<Menu.Item
                    name='Statement/Payment'
                  ><a onClick={()=> utilities.openInNewTab(statmentAndPayment) } className="menu-items">Statement/Payment</a>
                  </Menu.Item>*/}
                  <Menu.Item
                    name='RetailerUpdates'
                  ><Link to='/retailAlerts' className="menu-items">Retailer Alerts</Link>
                  </Menu.Item>
                </Menu>
                </>
                }
              </Grid.Column>
              <Grid.Column width={12} only='computer mobile tablet' className="banner1-slide-show">
                <div className='sliderImageWrapper'>
                <div style={{textAlign: 'center', background:'#515151'}} className='sliderImg' >
              <ImageCarousel>
                {this.state.slideshowImages}
                </ImageCarousel>
                </div>
                </div>
                </Grid.Column>
    </Grid.Row>
          </Grid>
        </Responsive>
        </Segment>
        {this.props.multiStoreDetails && this.props.multiStoreDetails.access !==1 &&
        <Segment className='productShowcaseWrapper'>
          <div>
          <h2>Merchant Must-Haves</h2>
          
          <p>Check out these new great products that will help maximize margin in your store.</p>
          {!this.state.productShowcase.length > 0 ? <Loader active/> : null }
          <Slider {...this.state.sliderSettings}>
          {this.state.productShowcase.length > 0 && this.state.productShowcase.map((product,index)=>{
            const pricingDetails = product.product && product.product.pricing;
            const tierValue = pricingDetails.Member_Cost_Tier ? pricingDetails.Member_Cost_Tier.split('T')[1] : '';
            if(index <= 8) {
           return(<div key={product.image}>
              <figure>
                <div>
                <Link to={'/product/'+product.product.ItemNumber}>
                  <img src={product.image}/>
                </Link>
                  </div>
                <figcaption>
                  <h4>{product.product.Description}</h4>
                  <p>{product.product.ShortDescription}</p>
                  <p>Item Number#: {product.product.ItemNumber}</p>
                  <p>Member Cost: {(this.props.multiStoreDetails?.access !== 1 || (this.props.multiStoreDetails?.access === 1 
                    && this.props.multiStoreDetails.CatMbr)) && 
                    <MaskPrice
                      viewType={this.props.viewType}
                      getprefer = {this.props.getprefer}
                      fieldVal={utilities.getCostForQtyPlp(
                        pricingDetails,pricingDetails.Tiers[tierValue -1]?.Quantity || '',pricingDetails.tiered
                                                  )}
                      field='Member Cost'
                    />
                  }</p>
                  <Link to={'/product/'+product.product.ItemNumber}>
                    <button className="orderNowBtn">Order Now</button>
                  </Link>
                </figcaption>
              </figure>
            </div>)
            }
          })}
          </Slider>
          </div>
        </Segment>}
        <Segment style ={{margin:0,padding:0 }} className='wpHompageContent'>
        {this.state.wpSectionLoaded ? <WpHompageContent /> : <div className='offlineWpSection'><Loader active /></div>}
        </Segment>
        
        {this.props.feedbackLink && !_.isEmpty(this.props.feedbackLink) && this.props.feedbackLink !== null &&
          <div className="feedbackLink">
            <a href={this.props.feedbackLink} target="_blank"> Feedback </a>
          </div>
        }
        {this.props.isReunionOnlyStore && this.state.isPopupShow && <OnlyReunionPopUp closePopUp={this.closePopUp} />}
       
      </>
    );
  }
}
const OnlyReunionPopUp = ({closePopUp}) => {
  return(
    <div className="onlyReunionPopupWrapper">
      <div className="onlyReunionPopup">
        <Dialog width='1' height='1' position='absolute'>
            <span className='onlyReunionPopup_close' onClick={closePopUp}>X</span>
              <p>You are logged in as a Reunion Only Customer and will have access to order Reunion Events only</p>
        </Dialog>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  const { cart } = state;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer: state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    storeId: state.SessionReducer.storeId,
    rdc: state.SessionReducer.rdc,
    isReunionOnlyStore: state?.SessionReducer?.address?.isReunionOnlyStore,
    onReunionPopup: state.SessionReducer?.reunionPop,
    feedbackLink: state.SessionReducer?.address?.feedbackLink,
    isRetailAssortmentsEnable: cart?.orders?.isRetailAssortmentsEnable,
    isCtbAssortmentsEnable: cart?.orders?.isCtbAssortmentsEnable,
    monthlySizzleSaleValue: cart?.orders?.monthlySizzleSaleValue,
    marketFlag: cart?.orders?.marketFlagEnabled,
    userguid: state.SessionReducer.userguid,
    storeguid: state.SessionReducer.storeguid
  }
}

const mapDispatchToProps = dispatch => {
  return Object.assign(
      { dispatch },
    bindActionCreators({  getCartInfo, notifyCarts,switchView, MarketFLAG, ReunionOrdering, onlyReunion }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);