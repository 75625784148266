import React from 'react';
import Categories from '../Categories';
import { Link } from 'react-router-dom';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import { computeGrossMargin } from './POG';
import utilities from '../../utils/utilities';
import { connect } from 'react-redux';
import { MaskPrice } from '../common/MaskPrice';
import { IMPULSE_MERCHANDISING } from '../Categories/constants';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class ImItemListing extends React.Component {
  render(){
  const assortment = this.props.location.state
                      ? this.props.location.state.assortment
                      : this.props.ImStore[this.props.match.params.categoryId]
                          .find(astmnt => astmnt.Ordering_FileName === this.props.match.params.assortmentId);
  const assortmentId = assortment
                        ? assortment.Ordering_FileName
                        : this.props.match.params.assortmentId
                          ? this.props.match.params.assortmentId
                          : '';
  
  const isNonAssortment = assortment.Category_Name === 'CLIPSTRIPS' || assortment.Category_Name === 'COUNTERTOP';
  const assortmentLink =  
    this.props.location &&  this.props.location.state && this.props.location.state.backPath 
      ? this.props.location.state.backPath 
      : '/impulse-merchandising/'+ (this.props.match && this.props.match.params && this.props.match.params.categoryId);
  const breadcrumbPath = [{ link: '/home', text: 'HOME' },
  { link:'/impulse-merchandising', text:'IMPULSE MERCHANDISING'},
    ...isNonAssortment?[]:[{   link:assortmentLink,text:assortment.Department_Name}],
  { link:this.props.match && this.props.match.url, text: assortment.Display_Name }]

  const {viewType} = this.props;
  let getPrefer = this.props.getprefer && this.props.getprefer.customerView 
                    ? this.props.getprefer.customerView 
                    : 'c1'
  return (
      <div>
          {assortment && 
              <div className='page-wrap'>
              <div className='page-header'>Impulse Merchandising</div>
              <BreadcrumbComp path={breadcrumbPath}/>
              {!isNonAssortment && 
                  <>
                    <div className='info-text'>
                        <span className='pl-card-header'>
                            <span>{assortment.Display_Name}</span>&nbsp;
                            <span className='im-asstId'>- {assortment.Ordering_FileName}</span>
                            <span className='im-label'>
                                <span >View Assortment</span>
                                <i  className='file pdf icon large'
                                    style={{ cursor: 'pointer', verticalAlign: 'initial' }}
                                    onClick={()=>utilities.openInNewTab('https://apps.membersonline.com/VisualMerchandising/catalog/VisMerch/'+assortment.PDF_Filename1)}
                                />
                            </span>
                        </span>
                        <span>
                          <Link to={assortmentLink} className='assortments_link'>
                              <u>Back to Assortment Listing</u>
                          </Link>
                        </span>
                    </div>
                    <div className='card_fields'>
                      <AssortmentDetails  
                        getprefer={getPrefer}  
                        multiStoreDetails={this.props.multiStoreDetails} 
                        viewType = {viewType} 
                        assortment={assortment} 
                        access = {this.props.multiStoreDetails.access} 
                      />
                    </div>
                  </>
              }
              <br />
              <Categories
                assortmentType={IMPULSE_MERCHANDISING}
                assortmentId={assortmentId}
                DisplayName={assortment.Display_Name}
              />
          </div>
          }
      </div>
      );
  }
}

const AssortmentDetails = ({ viewType, assortment,multiStoreDetails,getprefer }) => {
    return (
      <>
        <div className={window.innerWidth > 768 ?'pl-specs displayFlex' :'impulse-specs impulseMobileDetail'} >
            <div className='im-fields'>
                <span className='im-card-name'>SKUs :</span>
                <span className='im-card-field'>{assortment.nbrSkus}</span>
                {(multiStoreDetails.access !== 1  || (multiStoreDetails.viewPOG && multiStoreDetails.PogMbr)) &&
                <>
                <span className='im-card-name'>Member Cost :</span>
                <span className='im-card-field'>
                    <MaskPrice
                        viewType={viewType}
                        fieldVal={assortment.Member_Cost}
                        getprefer={getprefer}
                        field='Member Cost'
                    />
                </span>
                </>
                }
                <span className='im-card-name'>Gross Margin :</span>
                <span className='im-card-field'>{computeGrossMargin(assortment.selling,assortment.Member_Cost)}</span>
                <br />
            </div>
        </div>
        
      </>
    );
  };

  const mapStateToProps = (state)=>{
    const viewTypeStorage = localStorage.getItem('viewType');
    return {
        ImStore:state.ImReducer,
        viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
        multiStoreDetails : state.SessionReducer.multiStoreDetails,
        getprefer: state.preference.listData
    }
}

export default connect(mapStateToProps, null)(ImItemListing);