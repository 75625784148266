import { GET_PLANOGRAMS } from './actions'
const initState = {};

const PlanogramReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case GET_PLANOGRAMS:
            return { ...state, planogram: payload };
            default: return state;
    }
}
export default PlanogramReducer;