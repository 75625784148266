import restUtils from "../../utils/restUtils"
import { REUNION_PRODUCT_LIST } from "../../urlConstants"
import _ from 'lodash';
import utilities from "../../utils/utilities";

export const getReunionFilterString = function(appliedFilters,selectedVendorname){
    let filterList = [];
    if(!appliedFilters.hasOwnProperty("Vendor Name") && selectedVendorname){
        filterList.push({
            name:"Vendor Name",
            valueList:[selectedVendorname]
        })
    }
    for(let name in appliedFilters){
        if (appliedFilters.hasOwnProperty(name)){
            filterList.push({
                name,
                valueList:appliedFilters[name]
            })
        }
    }
    return filterList
}
export const fetchReunionProductsList = function(newProps){
    if (this.props.isSearch){
        this.searchReunionItems(newProps);
        return;
    }
    const { relayNbr, promoNbr, vendorid, shipdate1, shipdate2, shipdate3, shipdate4} = (newProps && newProps.vendorDetails)?
                                                                                        newProps.vendorDetails:
                                                                                        this.props.vendorDetails;
    const { favorites, searchby, searchvalue } = newProps? newProps : this.props;
    const filterList = !_.isEmpty(this.filterString)?this.filterString:null;
    let requestPayload = {
            relayNbr,
            promoNbr,
            vendorid,
            memNbr: this.props.storeId,
            rdc : this.props.rdc,
            sortby :utilities.isEmptyOrNullString(this.sortParam)?"item_nbr":this.sortParam ,
            sortorder : utilities.isEmptyOrNullString(this.sortDirection)?"asc":this.sortDirection,
            searchby,
            searchvalue
        }
    if(filterList){
        requestPayload = {...requestPayload, "filterBy":filterList}
    }
    this.setState({loading:true})
    restUtils
        .postData(REUNION_PRODUCT_LIST,requestPayload)
        .then(
            response=>{
                if(_.isEmpty(response.data) || response.data==="[]"){
                    this.setState({
                        isNoProducts:true,
                        loading:false
                    })
                    return;
                }
                let categoryData = response.data.Eventitemdetails.map(productData => {
                const favorited =
                    favorites.indexOf(parseInt(productData.Item_Number)) >-1;
                    return {
                            ...productData,
                            selected: false,
                            favorited,
                            shipdate1,
                            shipdate2,
                            shipdate3,
                            shipdate4
                        };
                });
                this.setState({
                    categoryData,
                    loading: false,
                    isNoProducts: !categoryData || categoryData.length === 0,
                    totalItems :categoryData.length,
                    firstItem : categoryData.length>0 ?1:0,
                    lastItem : categoryData.length>0 ?categoryData.length:0,
                    filters : response.data.filters,
                    quantities :Array(categoryData.length).fill().map(()=>{ return new Array(4).fill('')})
                })
            }
        ).catch(error=>{
            console.log(error);
            this.setState({loading:false,serverError:true});
        })
}
export const searchReunionItems = function(newProps){
    const { searchby, searchvalue } = newProps?newProps:this.props;
    const { favorites } = newProps? newProps : this.props;
    const filterList = !_.isEmpty(this.filterString)?this.filterString:null;
    let requestPayload = {
            searchby,
            searchvalue,
            memNbr: this.props.storeId,
            rdc : this.props.rdc,
            sortby :utilities.isEmptyOrNullString(this.sortParam)?"item_nbr":this.sortParam ,
            sortorder : utilities.isEmptyOrNullString(this.sortDirection)?"asc":this.sortDirection
        }
    if(filterList){
        requestPayload = {...requestPayload, "filterBy":filterList}
    }
    this.setState({loading:true})
    restUtils
        .postData(REUNION_PRODUCT_LIST,requestPayload)
        .then(
            response=>{
                if(_.isEmpty(response.data) || response.data==='[]'){
                    this.setState({
                        isNoProducts:true,
                        loading:false
                    })
                    return;
                }
                let categoryData = response.data.Eventitemdetails.map(productData => {
                const favorited =
                    favorites.indexOf(parseInt(productData.Item_Number)) >-1;
                    return {
                            ...productData,
                            selected: false,
                            favorited
                        };
                });
                this.setState({
                    categoryData,
                    loading: false,
                    isNoProducts: !categoryData || categoryData.length === 0,
                    totalItems :categoryData.length,
                    firstItem : categoryData.length>0 ?1:0,
                    lastItem : categoryData.length>0 ?categoryData.length:0,
                    filters : response.data.filters,
                    quantities :Array(categoryData.length).fill().map(()=>{ return new Array(4).fill('')})
                })
            }
        ).catch(error=>{
            console.log(error);
            this.setState({loading:false,serverError:true});
        })
}