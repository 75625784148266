import React from 'react'
import Categories from '../Categories'
import Dialog from 'react-dialog';
import { Button } from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux'
import InternalServerError from '../ErrorPages/500_error_page';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import utilities, { saleType } from '../../utils/utilities';
import { DealsDialog } from './MonthlySpecials/DealsDialog';
 class ReunionEventitemListingVendors extends React.Component{
        constructor(props){
            super(props);
            const eventId = props.match && props.match.params  && props.match.params.eventId ? props.match.params.eventId : '';
            const event = props.reunionEvents && props.reunionEvents.find(event=>{
                return parseInt(event.promo_nbr)===parseInt(eventId);
            })
            const{isWow,vendorDetailsOffer,searchby,listType,searchvalue,pathname}= props.location && props.location.state
            this.state={
                dealDetails:false,
                eventId,
                isWow,
                vendorDetails:vendorDetailsOffer,
                searchby:searchby,
                listType:listType,
                eventIdReunion: event?event.relay_nbr:'',
                promoNmbr: event?event.promo_nbr:'',
                promoName: event?event.PrmoName:'',
                searchvalue:searchvalue,
                serverError: false,
                isLoading:false,
                pathname
            }
        }
        showDealDetails = () => this.setState({ dealDetails: true});

        DialogClose = () => this.setState({ dealDetails: false });

        render() {
            const{vendorDetails, isWow, searchby,listType,searchvalue,eventIdReunion,promoNmbr,promoName}=this.state;
            const saleLabel = saleType(this.props.monthlySizzleSaleValue)
            const header = `${isWow?'WOW':saleLabel}`;
            let breadcrumbPathMonthlySpecial = [
                { link: '/home', text: 'HOME' },
                { link: '/monthlySpecials', text: header}
            ];
            breadcrumbPathMonthlySpecial=[ ...breadcrumbPathMonthlySpecial,
                {
                    link:`/monthlyEventVendors/${vendorDetails.promoNbr}`,
                    text: 'Events '+ eventIdReunion +': '+promoNmbr + '-' + promoName
                },
                {link:'#',text:`${vendorDetails.vendorName}`},
            ]
        return (
            
            this.state.serverError ?
                <InternalServerError />
                :
              <div className="page-wrap">
                   {this.state.isLoading && (
                        <div>
                            <Loader active />
                        </div>
                    )}
                <div className='page-header'>
                    {`${header} - #`+utilities.emptyForNull(vendorDetails.relayNbr)+" - "+utilities.emptyForNull(vendorDetails.promoName)}
                </div>
                <BreadcrumbComp path={breadcrumbPathMonthlySpecial}/>
                <div className='monthlyFields'>
                        {vendorDetails.vendorName}&nbsp;&nbsp;&nbsp;
                        {`Order Deadline: ${vendorDetails.deadline}`}&nbsp;&nbsp;&nbsp;
                            <Button 
                                onMouseEnter={this.showDealDetails} 
                                onMouseLeave={this.DialogClose}
                                size='mini'
                                className='view-details-btn'
                                content=' Deal Details' 
                            />
                            {this.state.dealDetails && (
                                <div id='dealDetailsDialog'>
                                    <DealsDialog 
                                        vendorName={vendorDetails.vendorName}
                                        spiff={vendorDetails.spiff}
                                        member_terms={vendorDetails.member_terms}
                                        shipdate1 ={vendorDetails.shipdate1}
                                        shipdate2 ={vendorDetails.shipdate2}
                                        shipdate3 ={vendorDetails.shipdate3}
                                        shipdate4 ={vendorDetails.shipdate4} 
                                        DialogClose={this.DialogClose}
                                    />
                                </div>      
                            )}
                    </div>
                    <Categories 
                        pathName ={this.props.location  && this.props.location.pathname}
                        vendorDetails={vendorDetails} 
                        className='margin-top' 
                        isMonthlyListing={true}
                        searchby={searchby}
                        searchvalue={searchvalue}
                        listType={listType}
                                    />
                </div>
        )
    }
 }

const mapStateToProps = (state) =>{
    return {
        reunionEvents : state.ReunionReducer.events,
        monthlySizzleSaleValue: state.cart?.orders?.monthlySizzleSaleValue
    }
}
export default connect(mapStateToProps, null)(ReunionEventitemListingVendors);