import React, { Component } from 'react';
import './404_error_page.css';
import { Link } from 'react-router-dom';
import oops from '../images/208_oops-face-emoji-emoticon-sad-512.png'
import renderImages from '../common/RenderImages';
import { Icon } from 'semantic-ui-react';

export default class InternalServerError extends Component {

  render() {
    return (
        <div className="text_center">
          <span className='oops-img'>{renderImages.getImage({src:oops,className:'oops-img'})}ops!</span>
          <h2>Sorry, we couldn’t retrieve the data!</h2>
          <h3>Go back to <Link to="/home"><Icon name='home'/></Link></h3>
        </div>

    );
  }
}