import React, { Component } from 'react';
import restUtils from "../../../utils/restUtils";
import { MONTHLY_SPECIALS } from "../../../urlConstants";
import _ from 'lodash';
import utilities from "../../../utils/utilities";

export const getDicountCityFilterString = function( appliedFilters, selectedVendorname ) {
  let filterList = [];
  if(!appliedFilters.hasOwnProperty("Vendor Name") && selectedVendorname) {
    filterList.push({
      name:"Vendor Name",
      valueList:[selectedVendorname]
    })
  }
  for(let name in appliedFilters) {
    if (appliedFilters.hasOwnProperty(name)) {
      filterList.push({
        name,
        valueList: appliedFilters[name]
      })
    }
  }
  return filterList
}
export const fetchDiscountCityProductsList = function(newProps) {
  const { relayNbr, 
          promoNbr, 
          vendorid, 
          shipdate1, 
          shipdate2, 
          shipdate3, 
          shipdate4 
        } = this.state.vendorDetails?this.state.vendorDetails:{};
  const { page, pageSize, isAllItems,eventId,eventIdReunion,palletItem} = this.state;
  const { favorites } = this.props;
  const filterList = !_.isEmpty(this.filterString)?this.filterString:null;
  let url = `${MONTHLY_SPECIALS}/GetMSItems`;
  let requestPayload = {
          memNbr: this.props.storeId, 
          searchby: utilities.isEmptyOrNullString(this.searchvalue)?null:this.searchby,
          searchvalue:this.searchvalue,
          promoNbr: isAllItems || palletItem?eventId:promoNbr,
          relayNbr: isAllItems || palletItem?eventIdReunion:relayNbr,
          VendorID: isAllItems || palletItem?null:vendorid,
          sortBy : utilities.isEmptyOrNullString(this.sortParam)
                    ? "item_nbr"
                    : this.sortParam ,
          sortOrder : utilities.isEmptyOrNullString(this.sortDirection)
                      ? "asc"
                      : this.sortDirection,
          page: this.page && this.page.length > 0 
                    ? this.page.split('=')[1]
                    : '1',
          resultPerPage: this.pageSize && this.pageSize === '100' ? '100' : '50',
          rdcNbr: this.props.rdc,
          cartID : this.props.currentCartId,
          isItemDtlFromWH: false,
          MouseOverBy: isAllItems || palletItem ?null: {
                                          VendorID : vendorid
                                        }
  }

  if(filterList) {
    requestPayload = {
        ...requestPayload, 
        "FilterBy": filterList
      }
  }
  this.setState({loading:true})
  restUtils
    .postData(url, requestPayload)
    .then(
      response => {
        if(_.isEmpty(response.data) || response.data.length === 0) {
            this.setState({
              isNoProducts:true,
              loading:false
            })
            return;
        }
        let categoryData = response.data.MSItemList.map(productData => {
        const favorited = favorites.indexOf(parseInt(productData.Item_Number)) >-1;
          return {
            ...productData,
            selected: false,
            favorited,
            shipdate1,
            shipdate2,
            shipdate3,
            shipdate4
          };
        });
        const totalPages = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].totalPages):0;
        const currentPage = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].currentPage):0;
        const firstItem = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].firstItem):0;
        const lastItem = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].lastItem):0;
        const totalItems = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].totalItems):0;
        this.setState({
          categoryData,
          loading: false,
          isNoProducts: !categoryData || categoryData.length === 0,
          totalItems,
          firstItem,
          lastItem,
          totalPages,
          currentPage,
          filters : response.data.filters,
          quantities :categoryData.map((product) => { 
            return [
              utilities.emptyForNull(product.userQty),
              utilities.emptyForNull(product.userQty2),
              utilities.emptyForNull(product.userQty3),
              utilities.emptyForNull(product.userQty4)
            ]
          }),
        })
      }
  ).catch(error=>{
    console.log(error);
    this.setState({loading:false,serverError:true});
  })
}

export const searchDiscountCityItems = function(newProps){
    const { searchby, searchvalue } = newProps?newProps:this.props;
    const { favorites } = newProps? newProps : this.props;
    const { relayNbr, promoNbr} = this.state.vendorDetails
    const filterList = !_.isEmpty(this.filterString)?this.filterString:null;
    let url = `${MONTHLY_SPECIALS}/GetMSSearch`;
    let requestPayload = {
        searchType: "item",
        searchby,
        itemNbr:searchvalue,
        query:searchvalue,
        mbrNbr: this.props.storeId,
        "promoNbr": promoNbr,
        "relayNbr" :relayNbr,
        rdcNbr : this.props.rdc,
        cartID:this.props.currentCartId,
        sortBy : utilities.isEmptyOrNullString(this.sortParam)
                  ? "item_nbr"
                  : this.sortParam ,
        sortOrder : utilities.isEmptyOrNullString(this.sortDirection)
                    ? "A"
                    : this.sortDirection,
        "resultperpage":this.pageSize,
        "isItemDtlFromWH":false
    }
    if(filterList){
      requestPayload = {...requestPayload, "FilterBy":filterList}
    }
    this.setState({loading:true})
    restUtils
      .postData(url,requestPayload)
      .then(
          response=>{
            if(_.isEmpty(response.data) || response.data==='[]') {
              this.setState({
                isNoProducts:true,
                loading:false
              })
              return;
            }
            let categoryData = response.data.MSSearchList.map(productData => {
            const favorited = favorites.indexOf(parseInt(productData.Item_Number)) >-1;
              return {
                  ...productData,
                  selected: false,
                  favorited
              };
            });
            const totalPages = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].totalPages):0;
            const currentPage = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].currentPage):0;
            const firstItem = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].firstItem):0;
            const lastItem = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].lastItem):0;
            const totalItems = response?.data?.pagination?.length ?parseInt(response.data.pagination[0].totalItems):0;
            this.setState({
              categoryData,
              loading: false,
              isNoProducts: !categoryData || categoryData.length === 0,
              totalItems,
              firstItem,
              lastItem,
              totalPages,
              currentPage,
              filters : response.data.filters,
              quantities :categoryData.map((product) => { 
                return [
                  utilities.emptyForNull(product.userQty),
                  utilities.emptyForNull(product.userQty2),
                  utilities.emptyForNull(product.userQty3),
                  utilities.emptyForNull(product.userQty4)
                ]
              }),
            })
        }
      ).catch(error=>{
        console.log(error);
        this.setState({loading:false,serverError:true});
      })
}