import { combineReducers } from 'redux';

import departmentReducer from './departmentReducer';
import catalogReducer from './catalogReducer';
import adReducer from './adReducer';

export default combineReducers({
  catalog: catalogReducer,
  departments: departmentReducer,
  catalogAds: adReducer,
});
