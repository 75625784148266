import React, { Component } from 'react';
import BreadcrumbComp from "../../../components/BreadcrumbComp";
import OptionsSection from "./OptionsSection";
import { exportExcel } from "../../common/ComponentUtilities";
import { connect } from 'react-redux';
import { MaskPrice } from '../../common/MaskPrice';
import { Loader } from "semantic-ui-react";
import Filter from "./Filter";
import history from "../../../history";
import { getPalletItemListingShipWeek } from './palletUtils';
import utilities from '../../../utils/utilities';
import MultistoreToCartModal from '../../Cart/MultistoreToCartModal';
import { getPagination } from '../../common/CustomComponents';
import { DROPSHIP_PALLET_ITEMLISTING, MARKET_ADD_TO_CART } from "../../../urlConstants";
import restUtils from "../../../utils/restUtils";
import ImageGallery from '../../common/ImageGallery';
import { isArray } from "util";
import _ from "lodash";
import ItemListView from "./ItemListView";
import ItemGridView from "./ItemGridView";
import { viewAllCarts } from "../../Cart/actions";
import {
  isSearchRequired,
  isSortRequired,
  getSearchByOptions,
  getSortByOptions,
  isShowByRequired,
  getBreadcrumbLinks,
  isAddToCartRequired,
  isSeqRequried,
  getPageNumber,
  getPdpParams,
  getPalletImageLink
} from "./itemListingUtilities";
import { quantityValidator } from '../../common/productHandler';
import UnderConstruction from "../../UnderConstruction";
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

class DropShipPalletItemListing extends Component {
  constructor(props) {
    super(props);
    const {
      eventId,
      flowType,
      fromReunionHome,
      palletNumber,
      promoName,
      relayNbr,
      relayYear,
      shoppingAreaName,
      shoppingId,
      vendorName,
      vendorId,
      palletId,
      eventSupplierSelectionID
    } = props.match?.params;
    const shoppingAreaMetaId = decodeURIComponent(props?.location?.state?.shoppingAreaMetaId);
    this.state = {
      fromReunionHome: fromReunionHome,
      flowType: flowType,
      relayNbr: relayNbr,
      relayYear: relayYear,
      palletNbr: palletNumber,
      promoName: promoName,
      shoppingAreaName: shoppingAreaName,
      shoppingAreaId: shoppingId,
      shoppingAreaMetaId:shoppingAreaMetaId,
      promoNbr: eventId,
      vendorId: vendorId,
      vendorName: vendorName,
      palletId: palletId,
      eventSupplierSelectionID: eventSupplierSelectionID,
      filters: [],
      qtyListDr:[],
      isLoading: false,
      isNoProducts: false,
      paginationParams: {},
      palletData: [],
      PalletItems: [],
      quantities: [],
      eventEnableAfter: '',
      eventEnableBefore:'',
      eventMessage: '',
      searchValue: "",
      searchPattern: "[0-9]*",
      isAddToCartModalOpen: false,
      appliedFilters: {},
      imgTextToDisplay: "Hide Images",
      isGridView:
        props.getprefer &&
        props.getprefer.prdctListlayoutFormat &&
        props.getprefer.prdctListlayoutFormat !== "L",
      pageHeader: props.pageHeader,
      breadcrumbLinks: props.breadcrumbLinks
    }
    this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage
      ? this.props.getprefer.resultPerPage
      : "50";
    this.sortParam = "item_nbr";
    this.sortDirection = "asc";
    this.searchBy = "item_nbr";
    this.page = '1';
    this.filterList = "";
    this.pathname = "";
    this.defaultChecked = utilities.isEmptyOrNullString(vendorId)
      ? {}
      : {
        name: "Vendor",
        value: vendorId,
      };
    this.defaultCheckedFilterValue = utilities.isEmptyOrNullString(vendorId)
      ? {}
      : {
        name: "Vendor",
        valueList: [vendorId],
      };
    this.searchByOptions = getSearchByOptions(flowType, this.props.aliasSKURetailerGrpID);
    this.historyLength=1;
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else {
      this.fetchPalletItemListing();
    }

  }

  addToCart = () => {
    const { vendorId, palletNbr, relayNbr, promoNbr, flowType, shoppingAreaName, qtyListDr } = this.state;
    let validMsg = false;
    const isValidQuantity = Object.values(qtyListDr).every(qty=>{
      return utilities.isValidQuantity(qty)
    })
    if( !qtyListDr
        || !isValidQuantity
        || _.isEmpty(qtyListDr)
        || qtyListDr.length === 0){
      utilities.showToast('Please enter a valid quantity',true)
      validMsg=true;
      return;
    }
    let resObject =!_.isEmpty(qtyListDr) &&  Object.keys(qtyListDr).map(key=>{
      return {
        'month':key,
        'quantity':parseInt(qtyListDr[key]) ?parseInt(qtyListDr[key]):0
      }
  });
    const payload = {
      userID: this.props.userId.toString(),
      itemNum: palletNbr,
      quantityList: resObject,
      vendorID: vendorId,
      type:promoNbr,
      relayNbr: relayNbr,
      modelNum:this.state.palletData.model,
      storeId:this.props.storeId,
      promoNbr,
      rdcNbr: this.props.rdc,
      molUserName: this.props.userName,
      flowType: parseInt(flowType),
      shoppingName: shoppingAreaName,
    };
    utilities.setLoader(true);
   !validMsg &&  restUtils
      .postData(MARKET_ADD_TO_CART, payload)
      .then(response => {
        utilities.setLoader(false);
        utilities.showToast("Product added to cart!");
        const cartId = response?.data?.cartID;
        this.props.viewAllCarts(cartId);// to set current cart
      }).catch(error => {
        utilities.setLoader(false);
        utilities.showToast("Couldn't add product to cart", true);
      })
  };

  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.setState({ isMultistoreModalOpen: false });
  }
  getPalletItemListingPayload = (isCompleteList) => {
    const { relayNbr, relayYear, paginationParams, promoNbr, palletNbr, searchValue,shoppingAreaId, palletId,
        eventSupplierSelectionID } = this.state;
    const resultPerPage = !isCompleteList ?
      this.pageSize
      : paginationParams.totalItems;
    let currentCart = this.props?.carts && this.props.carts.find(cart => parseInt(cart.cartId) === parseInt(this.props.currentCartId));      
    return {
      vendorId: eventSupplierSelectionID,
      promoNbr: parseInt(promoNbr),
      palletId: palletId,
      page: '1',
      resultPerPage,
      shoppingId : shoppingAreaId,
      cartId: this.props.currentCartId,
      multiStoreCartId : utilities.msExist(this.props.multiStoreDetails) > 1 && currentCart?.parentId ? currentCart.parentId : '',
      aliasSKURetailerGrpID: utilities.isAliasSkuRequired(
        this.props.multiStoreDetails
      )
        ? 1
        : 0,
      sortBy: this.sortParam,
      sortOrder: this.sortDirection,
      ...!utilities.isEmptyOrNullString(searchValue) ?
        {
          searchBy: this.searchBy,
          searchValue: searchValue
        } : {},
      ...(!_.isEmpty(this.filterList) && !_.isEmpty(this.defaultCheckedFilterValue)
        ? { filterBy: [...[this.defaultCheckedFilterValue], ...this.filterList] }
        : !_.isEmpty(this.filterList)
          ? { filterBy: this.filterList }
          : this.defaultCheckedFilterValue
            ? { filterBy: [this.defaultCheckedFilterValue] }
            : {})
    }
  }

  fetchPalletItemListing = () => {
    this.setState({ isLoading: true });
    const payload = this.getPalletItemListingPayload();
    restUtils.postData(DROPSHIP_PALLET_ITEMLISTING, payload)
      .then((response) => {
        if(response?.data && (response.data?.evtStsActiveBefore === false || response.data?.evtStsActiveAfter === false)) {
          this.setState({
            eventEnableAfter: response.data?.evtStsActiveAfter,
            eventEnableBefore: response.data?.evtStsActiveBefore,
            eventMessage: response.data?.Message
          })
          return;
        } else if (!response?.data || response?.data?.palletItems === null ||response?.data?.palletItems?.length === 0) {
          this.setState((prevState) => {
            return {
              isLoading: false,
              palletData: response?.data?.pallet,
              PalletItems: [],
              isNoProducts: true,
              paginationParams: {
                ...prevState.paginationParams,
                totalPages: "0",
                currentPage: "0",
                totalItems: "0",
                firstItem: "0",
                lastItem: "0",
              }
            }
          });
          return;
        }
        const { data } = response;
        let qtyList = {};
        data?.pallet && data.pallet.ShipOptions.forEach(shiping => {
          qtyList = {...qtyList , [shiping.Month] : shiping.ItemQty}
        });
        this.setState({
          isLoading: false,
          palletData: data.pallet,
          PalletItems: data.palletItems,
          isNoProducts: false,
          qtyListDr: qtyList,
          quantities: [
            utilities.emptyForNull(data?.pallet?.userQty),
            utilities.emptyForNull(data?.pallet?.userQty2),
            utilities.emptyForNull(data?.pallet?.userQty3),
            utilities.emptyForNull(data?.pallet?.userQty4),
          ],
          paginationParams: data.pagination,
          filters: data.filters
        });
        this.setBackButtonPath();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error)
      })

  }

  handleViewChange = (isGridView) => {
    this.setState({ isGridView });
  };

  showHideImages = (evt) => {
    let imgTextToDisplay =
      evt.target.innerHTML === "Show Images" ? "Hide Images" : "Show Images";
    this.setState({ imgTextToDisplay });
  };

  setBackButtonPath = (newAppliedFilters) => {
    const appliedFilters = newAppliedFilters
      ? newAppliedFilters
      : this.state.appliedFilters;
    let pathName = "";
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    pathName =
      this.scrollPosition > 0
        ? pathName + "&scrollPosition=" + this.scrollPosition
        : pathName;
    this.historyLength = this.historyLength +1;
    if (!_.isEmpty(this.filterList)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(appliedFilters);
    }
    if (pathName !== "") {
      let pushUrl = this.pathname + "?" + pathName;
      window.history.pushState(window.history.state, "", pushUrl);
    }
  };

  getFieldSet = (product) => {
    const { access, CatMbr } = this.props?.multiStoreDetails;
    const { getprefer, memberViewType } = this.props;
    const memberCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={product.mbrCost}
        field={"Member Cost black"}
      />
    );
    const marketCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={product.specWhseCost}
        field={"Member Cost black"}
      />
    );

    const srCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={product.regSr}
        field={"Member Cost black"}
      />
    );
    return !this.state.isGridView && window.innerWidth >= 1024
      ? [
        ...(access !== 1 || (access === 1 && CatMbr)
          ? [
            [
              { label: "ITEM SKU#:", val: product.itemNbr },
              { label: "DS EVT COST:", val: marketCost },
              { label: "SUGG. RETAIL:", val: srCost },
            ],
            [
              { label: "MODEL #:", val: product.model },
              { label: "DS COST:", val: memberCost },
              { label: "NON STOCKED:", val: product.stocked },
            ],
            [
              { label: "UPC:", val: product.upc },
              { label: "QUANTITY:", val: product.quantity },
              this.props?.multiStoreDetails && !utilities.isEmptyOrNullString(this.props?.multiStoreDetails?.aliasSKURetailerGrpID)
              && ({ label: 'Alternate SKU#:', val: product?.aliasSku }
              )
            ]
          ]
          : [
            [
              { label: "ITEM SKU#:", val: product.itemNbr },
              { label: "DS EVT COST:", val: marketCost },
              { label: "SUGG. RETAIL:", val: srCost },
            ],
            [
              { label: "MODEL #:", val: product.model },
              { label: "DS COST:", val: memberCost },
              { label: "NON STOCKED:", val: product.stocked },
            ],
            [
              { label: "UPC:", val: product.upc },
              { label: "QUANTITY:", val: product.quantity },
              this.props?.multiStoreDetails && !utilities.isEmptyOrNullString(this.props?.multiStoreDetails?.aliasSKURetailerGrpID)
              && ({ label: 'Alternate SKU#:', val: product?.aliasSku }
              )
            ],
          ]),
      ]
      : [
        ...(access !== 1 || (access === 1 && CatMbr)
          ? [
            { label: "ITEM SKU#:", val: product.itemNbr },
            { label: "MODEL #:", val: product.model },
            { label: "QUANTITY:", val: product.quantity },
            { label: "DS EVT COST:", val: marketCost },
            { label: "DS COST:", val: memberCost },
            { label: "SUGG. RETAIL:", val: srCost },
            { label: "NON-STOCKED:", val: product.stocked },
            this.props?.multiStoreDetails && !utilities.isEmptyOrNullString(this.props?.multiStoreDetails?.aliasSKURetailerGrpID)
            && ({ label: 'Alternate SKU#:', val: product?.aliasSku })
          ]
          : [
            { label: "ITEM SKU#:", val: product.itemNbr },
            { label: "MODEL #:", val: product.model },
            { label: "QUANTITY:", val: product.quantity },
            { label: "DS EVT COST:", val: marketCost },
            { label: "DS COST:", val: memberCost },
            { label: "SUGG. RETAIL:", val: srCost },
            { label: "NON-STOCKED:", val: product.stocked },
            this.props?.multiStoreDetails && !utilities.isEmptyOrNullString(this.props?.multiStoreDetails?.aliasSKURetailerGrpID)
            && ({ label: 'Alternate SKU#:', val: product?.aliasSku }
            )
          ]),
      ]
  }
  getItemParams = (product, index) => {
    let itemParams = { product };
    const fieldSet = this.getFieldSet(product, index);
    itemParams = { ...itemParams, fieldSet, pdpParams: getPdpParams(this.state.flowType, product) };
    return itemParams;
  };
  handleQtyChange = (e) => {
    const { value, dataset } = e.target;
    const { index } = dataset;
    if (!quantityValidator(value)) {
      return false;
    }
    let { quantities,qtyListDr } = this.state;
    quantities[index] = value;
    qtyListDr = {...qtyListDr, [index]:value};
    this.setState({ quantities,qtyListDr });
  };
  getItemListParams = () => {
    const { flowType } = this.state;
    return {
      handleQtyChange: this.handleQtyChange,
      isAddToCartRequired: isAddToCartRequired(flowType),
      isScanListing: false,
      isSeqRequired: isSeqRequried(flowType),
    };
  };

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.page = getParams[0].split('=')[1];
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : ''; 
    let filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';    
    let appliedFilters = utilities.getFilterArrayAsMultiSelectObject(filterString);
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState({
      appliedFilters
    },() => {
      this.fetchPalletItemListing();
    });
  }

  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState({
      currentPage: 1,
      appliedFilters,
      clearFilters: false,
      backPath: this.pathname,
    },
      () => {
        this.page = '1';
        this.fetchPalletItemListing();
      }
    );
  };

  changePageSize = (e) => {
    this.pageSize = e.currentTarget.value;
    this.page = "1";
    this.scrollPosition = window.pageYOffset;
    this.fetchPalletItemListing();
  };

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    this.scrollPosition = window.pageYOffset;
    this.page = getPageNumber(e.currentTarget.text, paginationParams);
    window.scrollTo(0, 0);
    this.fetchPalletItemListing();
  };

  getListingHeader = () => {
    const { paginationParams, imgTextToDisplay } = this.state;
    return (
      <div className="listing-header">
        <span>
          {paginationParams.firstItem}-{paginationParams.lastItem} of{" "}
          {paginationParams.totalItems} products
        </span>
        <span onClick={this.showHideImages} id="show_and_hide_images">
          {imgTextToDisplay}
        </span>
        {paginationParams.totalPages > 0 && (
          <span className="pagination_top">
            {getPagination(
              0,
              parseInt(paginationParams.currentPage),
              parseInt(paginationParams.totalPages),
              this.onPageChange,
              null,
              1
            )}
          </span>
        )}
        {exportExcel({
          className: "exportPDF exportPdfPLP",
          onClick: this.handleExport
        })
        }
      </div>
    );
  };
  handleExport = () => {
    const payload = this.getPalletItemListingPayload(true);
    utilities.showToast("Exporting to Excel...");
    restUtils.postData(DROPSHIP_PALLET_ITEMLISTING, payload).then((response) => {
      if (!response?.data || response?.data?.palletItems?.length === 0) {
        utilities.showToast("No items available.", true);
        return;
      }
      const preference = utilities.getPreferenceHideMask(this.props.getprefer)
      const itemsList = response?.data?.palletItems?.map((product) => {
        return {
          "ITEM SKU#": utilities.emptyForNull(product.itemNbr),
          "Pallet Title": utilities.emptyForNull(product.shortDesc),
          "PALLET SKU#": utilities.emptyForNull(this.state?.palletData?.palletNbr),
          "MODEL ": utilities.emptyForNull(product.model),
          "UPC": utilities.emptyForNull(product.upc),
          "DS COST": utilities.getCostBasedOnViewType(
            this.props.memberViewType,
            product.mbrCost,
            "Member Cost",
            preference
          ),
          "DS EVT COST": utilities.getCostBasedOnViewType(
            this.props.memberViewType,
            product.specWhseCost,
            "Member Cost",
            preference
          ),
          "QUANTITY": utilities.emptyForNull(product.quantity),
          "SUGGESTED RETAIL": utilities.getCostBasedOnViewType(
            this.props.memberViewType,
            product.regSr,
            "Member Cost",
            preference
          ),
          "NON STOCKED": product.stocked && product.stocked !== 'N/A' ? product.stocked : '', //always non stocked for pallet items
          ...utilities.isAliasSkuRequired(this.props.multiStoreDetails)?{"ALTERNATE SKU#": utilities.emptyForNull(product?.aliasSku)}:{},
        };
      });
      utilities.exportToCsv(itemsList, this.state.shoppingAreaName);
    });
  }
  getListing = () => {
    const {
      isAddToCartModalOpen,
      productSelectedForModal,
      indexForModal,
    } = this.state;
    const cartParams = {
      isAddToCartModalOpen,
      closeCartModal: this.closeCartModal,
      addToCart: this.addToCart,
      modalProduct: productSelectedForModal,
      indexForModal,
    };
    const imgStyle =
      this.state.imgTextToDisplay === "Hide Images"
        ? "displayBlock"
        : "displayNone";
    const { isGridView } = this.state;
    const itemListParams = this.getItemListParams();
    return (
      <div className={
        isGridView ? "ui stackable four column grid product-grid" : ""
      }
      >
        {this.state.PalletItems.map((product, index) => {
          const itemParams = this.getItemParams(product, index);
          return (
            <>
              {!isGridView && window.innerWidth >= 1024 ? (
                <ItemListView
                  quantitiesMs={this.state.quantities}
                  indexCheck ={index}
                  flowType={this.state.flowType}
                  cartParams={cartParams}
                  itemParams={itemParams}
                  imgStyle={imgStyle}
                  product={itemParams.product}
                  itemListParams={itemListParams}
                  setBackButtonPath={this.props.setScrollPosition}
                  shoppingAreaName = {this.state.shoppingAreaName}
                  shoppingAreaId={this.state.shoppingAreaId}
                  shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                />
              ) : (
                  <ItemGridView
                    quantitiesMs={this.state.quantities}
                    indexCheck ={index}
                    flowType={this.state.flowType}
                    cartParams={cartParams}
                    itemParams={itemParams}
                    imgStyle={imgStyle}
                    product={itemParams.product}
                    itemListParams={itemListParams}
                    setBackButtonPath={this.props.setScrollPosition}
                    shoppingAreaName = {this.state.shoppingAreaName}
                    shoppingAreaId={this.state.shoppingAreaId}
                    shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                  />
                )}
            </>
          );
        })}
      </div>
    );
  };

  searchItems = () => {
    this.page = '1';
    this.fetchPalletItemListing();
  }

  changeSearchBy = (e) => {
    this.searchBy = e.target.value;
    const option = this.searchByOptions.find(option => option?.props?.value === e.target.value);
    const pattern = option?.pattern;
    if (!utilities.isEmptyOrNullString(pattern)) {
      this.setState({ searchPattern: pattern, searchValue: "" });
    }
  }

  changeSortParam = (e) => {
    this.sortParam = e.target.value;
    this.page = '1';
    this.fetchPalletItemListing();
  };
  changeSortDirection = (e) => {
    this.sortDirection = e.target.value;
    this.fetchPalletItemListing();
    this.page = '1';
  };
  changeSearchValue = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  getPalletItems = () => {
    const {
      isNoProducts,
      filters,
      flowType,
      searchPattern,
      searchValue
    } = this.state;
    return (
      <div>
        <OptionsSection
          isSortByRequired={isSortRequired(flowType)}
          sortByList={getSortByOptions(flowType, utilities.isAliasSkuRequired(this.props.multiStoreDetails))}
          changeSortParam={this.changeSortParam}
          isSearchRequired={isSearchRequired(flowType)}
          searchByList={this.searchByOptions}
          isShowByRequired={isShowByRequired(flowType)}
          changeSortDirection={this.changeSortDirection}
          searchItems={this.searchItems}
          pageSize={this.pageSize}
          changePageSize={this.changePageSize}
          changeSearchBy={this.changeSearchBy}
          changeSearchValue={this.changeSearchValue}
          isGridViewIconRequired={true}
          handleViewChange={this.handleViewChange}
          searchPattern={searchPattern}
          searchValue={searchValue}
          sortOrder={this.sortDirection}
        />
        <div className="displayFlex allOrdersTable">
          <Filter
            filters={filters}
            isNoProducts={isNoProducts}
            appliedFilters={this.state.appliedFilters}
            defaultChecked={this.defaultChecked}
            clearFilters={false}
            filterCallback={this.filterCallback}
          />
          <div id="grid_width" className="full-width pl_data">
            {this.getListingHeader()}
            {isNoProducts ? (
              <div className="no_prdcts_display">
                <h1>No Pallet Items to Display!</h1>
              </div>
            ) : (
                <>
                   {this.state.shoppingAreaMetaId && parseInt(this.state.shoppingAreaMetaId)===10 && 
                    <p className='disclaimer-span reunion impalleylist'>Discount City Pallets and Displayers should be ordered as a quantity of 1 to receive 1 pallet.</p>
              }
                {this.getListing()}
                </>
              )}
          </div>
        </div>
      </div>
    )
  }

  goToPreviousPage= () => {
    history.go(-parseInt(this.historyLength));
  }

  render() {
    const {
      isLoading,
      palletData,
      shoppingAreaName,
      shoppingAreaId,
      shoppingAreaMetaId,
      promoNbr,
      relayNbr,
      relayYear,
      flowType,
      vendorId,
      vendorName,
      quantities,
      qtyListDr,
      fromReunionHome,
      promoName,
      palletNbr,
      eventEnableAfter,
      eventEnableBefore,
      eventMessage,
    } = this.state;
    const palletName = palletData?.shortDesc
    const { memberViewType, getprefer, multiStoreDetails } = this.props;
    let AllitemCheck = this.props?.location?.state?.allItems ? this.props?.location?.state?.allItems : false;
    if((eventEnableAfter === false || eventEnableBefore === false ) || palletData ) {
      return (
        <>
          {(eventEnableAfter === false || eventEnableBefore === false ) ? 
            <UnderConstruction  eventMessage={eventMessage} shoppingAreaName={shoppingAreaName}/>
        :
        <div className="page-wrap">
          <MultistoreToCartModal
          quantitiesMsDrop={this.state.qtyListDr} 
          indexCheck ={''}
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          flowType={this.state.flowType}
          assortmentId={this.props.assortmentId}
          closeMultistoreModal={this.closeMultistoreModal}
          multiStoreCartavailable={this.props.multiStoreCartavailable}
          product={this.state.palletData}
          relayNbr={relayNbr}
          shoppingAreaName={this.state.shoppingAreaName}
          />
          <div className="page-header">{shoppingAreaName} - {promoName}</div>
          <div className='palletitemBack'>
            <BreadcrumbComp
              path={getBreadcrumbLinks(
                fromReunionHome,
                flowType,
                promoNbr,
                vendorName,
                vendorId,
                shoppingAreaName,
                shoppingAreaId,
                shoppingAreaMetaId,
                relayYear,
                relayNbr,
                '',
                palletName,
                promoName,
                '',
                palletNbr,
                'DropShipPalletItemListing',
                AllitemCheck
              )}
            />
            <span class="back-buttonlisting" onClick={this.goToPreviousPage}>Return to Previous Page</span>
          </div>
          <br /><br />
          {isLoading && <Loader active />}
          <SelectedPalletItemDetails
            palletData={palletData}
            handleQtyChange={this.handleQtyChange}
            flowType={this.state.flowType}
            quantities={quantities}
            qtyListDr={qtyListDr}
            isLoading = {isLoading}
            addToCart={this.addToCart}
            multistoreHandleCarts={this.multistoreHandleCarts}
            memberViewType={memberViewType}
            getprefer={getprefer}
            multiStoreDetails={multiStoreDetails}
          />
          <br />
          {this.getPalletItems()}
          <span className="paginationButtom">
            {this.state?.paginationParams?.totalPages > 0 && (
              getPagination(
                0,
                parseInt(this.state?.paginationParams?.currentPage),
                parseInt(this.state?.paginationParams?.totalPages),
                this.onPageChange,
                null,
                1
              ))}
          </span>
        </div>
        }
      </>
      )
    } else {
      return(
        <>
          {isLoading && <Loader active />}
        </>
      )
    }
  }
}

const SelectedPalletItemDetails = ({ palletData, isLoading, handleQtyChange, multistoreHandleCarts, qtyListDr, addToCart, memberViewType, getprefer, multiStoreDetails,flowType }) => {
  if(palletData?.palletNbr) {
    const imageSrcs = [
      getPalletImageLink(palletData.imageId)
    ];
    let msCheck = utilities.msExist(multiStoreDetails); 
    return (
      <>
        <div className='displayFlex ui stackable sixteen wide two column grid'>
          <div id="pdpImageDiv" className={window.innerWidth > 600
            ? 'five wide column'
            : 'pdpImage column'
          }
          >
            <ImageGallery images={imageSrcs}/>
          </div>
          <div className={window.innerWidth > 768
            ? window.innerWidth >= 1024
              ? ' eleven wide column'
              : 'ui stackable two column grid'
            : 'column'
          } id='prodDescription'
          >
            <div className='page-header'>
              {palletData.shortDesc}
              <span className='vendor-span black_color no_decoration'>{palletData.vendorName}</span>
            </div>
            <div className='ui stackable three column grid'>
              <div className="five column">
                <div className="palletList">
                  <span>Pallet SKU:</span>
                  <span className="disValue">{palletData.palletNbr}</span>
                </div>
                <div className="palletList">
                  <span>Model Number:</span>
                  <span className="disValue">{palletData.model}</span>
                </div>
                <div className="palletList">
                  <span>UPC:</span>
                  <span className="disValue">{palletData.upc}</span>
                </div>
                <div className="palletList">
                  <span>Retail fineline:</span>
                  <span className="disValue">{
                    utilities.emptyForNull(palletData.deptId) +
                    utilities.emptyForNull(palletData.classId) +
                    " - " +
                    utilities.emptyForNull(palletData.subClassId)}</span>
                </div>
              </div >
              <div className="five column">
                <div className="palletList">
                <span>{flowType ==='5' ? 'DS EVT COST:' : 'EVT Cost:'}</span>
                  <span className="disValue">
                    <MaskPrice
                      viewType={memberViewType}
                      getprefer={utilities.getPreferenceHideMask(getprefer)}
                      fieldVal={palletData.specWhseCost}
                      field={"Member Cost"}
                    />
                  </span>
                </div>
                <div className="palletList">
                <span>{flowType ==='5' ? 'DS COST:': 'Member Cost:'}</span>
                  <span className="disValue">
                    <MaskPrice
                      viewType={memberViewType}
                      getprefer={utilities.getPreferenceHideMask(getprefer)}
                      fieldVal={palletData.mbrCost}
                      field={"Member Cost black"}
                    />
                  </span>
                </div>
                <div className="palletList">
                  <span>Savings:</span>
                  <span className="disValue red_color">{utilities.isEmptyOrNullString(palletData.savings)
                    ? "-"
                    : utilities.PercentageSavings(palletData.savings * 100)}</span>
                </div>
                <div className="palletList">
                  <span>Dating:</span>
                  <span className="disValue">{palletData.terms} Days</span>
                </div>
                <div className="palletList">
                  <span>Pack:</span>
                  <span className="disValue">{palletData.pack}</span>
                </div>
              </div>
              <div className="five column">
                <div className="palletList">
                  <span className="discountWeek"> Ship Months</span>   {/* Need to impement ship weeks and Add to cart after integrating API*/}
                </div>
                <div className="palletList-Date dropshipDate">
                  {
                    !utilities.isEmptyOrNullString(palletData.shipDate1) &&
                    getPalletItemListingShipWeek(palletData.shipDate1, qtyListDr[palletData.shipDate1], handleQtyChange, 0,flowType,getprefer && (getprefer.cursorPlacement === '' || getprefer.cursorPlacement ==='S') ? false : true)
                  }
                  {
                    !utilities.isEmptyOrNullString(palletData.shipDate2) &&
                    getPalletItemListingShipWeek(palletData.shipDate2,  qtyListDr[palletData.shipDate2], handleQtyChange, 1,flowType,false)
                  }
                </div>
                <div className="palletList-Date">
                  {
                    !utilities.isEmptyOrNullString(palletData.shipDate3) &&
                    getPalletItemListingShipWeek(palletData.shipDate3, qtyListDr[palletData.shipDate3], handleQtyChange, 2,flowType,false)
                  }
                  {
                    !utilities.isEmptyOrNullString(palletData.shipDate4) &&
                    getPalletItemListingShipWeek(palletData.shipDate4, qtyListDr[palletData.shipDate4], handleQtyChange, 3,flowType,false)
                  }
                </div>
                {msCheck && msCheck > 1 ?
                <button
                className="red ui button pallet-itemlistcart"
                onClick={multistoreHandleCarts}
              >
                Add to Store
              </button>
                      :
                <button
                  className="red ui button pallet-itemlistcart"
                  onClick={addToCart}
                >
                  Add to Cart
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {isLoading &&<Loader active />}
     </>
    )
  }
}

const mapStateToProps = (state) => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    memberViewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    rdc: state.SessionReducer.rdc,
    carts: state.cart.orders.allCarts,
    userId: state.SessionReducer.UserId,
    userName: state.SessionReducer.address.userName,
    aliasSKURetailerGrpID: state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
    currentCartId: state?.cart?.orders?.currentCart?.cartId
  };
};

export default connect(mapStateToProps, { viewAllCarts })(DropShipPalletItemListing);