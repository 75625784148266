import { SHIP_LATER_API } from '../../urlConstants';
import restUtils from '../../utils/restUtils';
export const SHIP_LATER_RECEIVED = 'SHIP_LATER_RECEIVED';
export const UPDATE_SHIP_LATER_SORT_ORDER = 'UPDATE_SHIP_LATER_SORT_ORDER';

const receiveShipLaterItems = response => ({
  type: SHIP_LATER_RECEIVED,
  payload: { ...response }
});

export const setSortAndOrder = SortAndOrder => dispatch =>
  dispatch({
    type: UPDATE_SHIP_LATER_SORT_ORDER,
    payload: { ...SortAndOrder }
  });

export const getShipLaterItems = (reqObj, handleShipLaters,handleBackButtonChanges) => dispatch => {
  let createPromise = restUtils
    .postData(
      `${SHIP_LATER_API}/PostDisplayShipLaterItemsList`,
      JSON.stringify(reqObj)
    )
    .then(res => {
      const { data = {} } = res;
      const {
        filters,
        shipLaterItemList,
        excludesAmmunition,
        includesAmmunition
      } = data;
      if (handleShipLaters) {
        handleShipLaters(!(shipLaterItemList && shipLaterItemList.length));
      }
     return  dispatch(
        receiveShipLaterItems({
          filters,
          shipLaterItemList,
          excludesAmmunition,
          includesAmmunition
        })
      );
    })
    .catch(() => {
      return dispatch(receiveShipLaterItems({}));
    });
    createPromise.then(() => {
      handleBackButtonChanges();
  });
};
