import React from 'react';

const PreferenceCoption = (props)=>{
    const{header,description,fieldname,valuelist, currentChoice}=props;
    return (
        <div className="prefereceSingle">
            <h3>{header}</h3>
            <p>{description}</p>
            <div className="prederenceCheck"> 
            <select  name={fieldname} onChange={props.onSelectChange} >
                {valuelist.map(item=>
                    <>
                        <option value={item.val} selected = {item.val === currentChoice} >{item.label}</option>
                    </>
                )} 
                </select>
            </div>
        </div> 
    );
}

export default PreferenceCoption;
