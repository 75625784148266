import React from 'react';
import restUtils from '../../utils/restUtils';
import { Loader, Responsive, Pagination } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import "./AllOrders.css";
import utilities, { shouldHideSUGG_RETAILForPreference, shouldHideCostForPreference }  from '../../utils/utilities';
import ExcelExport from '../common/ExcelExport';
import renderImages from '../../views/common/RenderImages';
import { Link } from 'react-router-dom';
import { ORDER_ITEM_DETAILS, UPS_URL, FEDEX_URL  } from '../../../src/urlConstants';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import history from '../../history';
import ReactToPrint from 'react-to-print';
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { getTableRow, getSelect, getTable } from '../common/ComponentUtilities';
import { showItemCount, getPagination } from '../common/CustomComponents';
import  {TierIcon} from '../common/TierIcon';
import _ from 'lodash';

class orderItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: [],
      loading: false,
      serverError: false,
      poNum: '',
      isMultiPo: false,
      img_txt_to_display:(this.props.getprefer && this.props.getprefer.thumbnailImage && this.props.getprefer.thumbnailImage !=='Y') ? 'Show Images': 'Hide Images',
      orderDetails: this.props.location.state ? this.props.location.state.orderDetails : null,
      firstItem: 0,
      lastItem: 0,
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      isHovered : false,
      isChecked: false
    }
    this.page = 1;
    this.pageSize = 50;
    this.sortParam = 'itemNbr';
    this.sortDirection = 'asc';
    this.orderStatusPath = window.history?.state?.state?.pushUrl || '';
    this.scrollPosition = 0;
    this.handleChecked = this.handleChecked.bind(this);
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage ? 
    this.props.getprefer.resultPerPage : '50';
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.getItemDetails();
    }
  }

  roundNull = (data, field) => {
    const getPrefer = this.props.getprefer &&
    this.props.getprefer.customerView
    ? this.props.getprefer.customerView
    : 'c1';
    if(data){
      return <MaskPrice
        field = {field}
        getprefer={getPrefer}
        viewType={this.props.viewType}
        fieldVal={Number(data).toFixed(2)}
      />
    } else return "-"; 
  }

  showHideImages = evt => {
    let img_txt_to_display =
      evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

  changeSortParam = e => {
    this.page = 1;
    this.sortParam = e.currentTarget.value;
    this.scrollPosition = 0;
    this.getItemDetails();
  };

  changeSortDirection = e => {
    this.page = 1;
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = 0;
    this.getItemDetails();
  };

  handleTrackingMouseOver = trackingNums => {
    return <ul className={"mouseHover"}>
        {trackingNums && trackingNums.map((num, index)=> {
        return <li className={"mouseHoverText"} key={index}>{num}</li>
      })}
    </ul>
  }

  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');    
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : '';   
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
   let scrollPosition = this.scrollPosition;
    this.setState({
      scrollPosition : scrollPosition
    },() => {
      this.getItemDetails();
    });
  }

  setBackButtonPath = () => {
    let pathName = ''; 
    this.scrollPosition = window.pageYOffset;
    pathName = 'scrollPosition='+this.scrollPosition;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam='+this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType='+this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    let pushUrl  = '?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }

  goToPreviousPage= () => {
    if(this.orderStatusPath){
      history.push(this.orderStatusPath)
    } else {
      history.goBack();
    }
   }

  renderTrackingDetails =(retailerTrackingNumber) => {
    const uniqTrackingNum = _.uniq(retailerTrackingNumber.split(','))
    if(uniqTrackingNum.length > 1 ) {
      return <span className='order_details' onMouseOver={()=>this.setState({isHovered :true})} onMouseOut={()=>this.setState({isHovered :false})}>
        <a  href="#"  onClick={() => this.openTrackingSite(uniqTrackingNum)}> {this.state.isHovered && this.handleTrackingMouseOver(uniqTrackingNum)}{`${uniqTrackingNum[0]}...>>`}</a>
      </span>
    } else {
      return <span className='order_details'><a href="#"  onClick={() => this.openTrackingSite(uniqTrackingNum)}>{uniqTrackingNum[0]}</a></span>
    }
  }

   onOrderpagesize=e=>{
    this.pageSize = e.target.value;
    this.page = 1;
    this.getItemDetails();
  }

   onPageChange = e => {
    let queryPage = e.currentTarget.text;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? this.state.currentPage + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.page = queryPage;
    this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
    this.getItemDetails();
  };

  showLess = (itemNumber) => {
    let itemDetails = this.state.itemDetails;
    this.state.itemDetails.forEach((order, index) => {     
       if(order.itemNbr === itemNumber)
       itemDetails[index].showLess = true ;        
    });
    this.setState({ itemDetails});
  }

  showMore = (itemNumber) => {
    let itemDetails = this.state.itemDetails;
    this.state.itemDetails.forEach((order, index) => {     
       if(order.itemNbr === itemNumber)
       itemDetails[index].showLess = false ;        
    });
    this.setState({ itemDetails});
  }
  handleChecked(e){
    this.setState(prevState => {
      return { isChecked: !prevState.isChecked };
    });
    this.getItemDetails(!this.state.isChecked);
  }

  getItemDetails = (checked = this.state.isChecked ) => {
    const sortArr = { sortParam: this.sortParam, sortType: this.sortDirection };
    const orderDetails = this.state.orderDetails;
    let requestPayLoad = '';
    let  includeAllItems = checked ? 1 : 0;
    orderDetails.orderNumber ? 
      requestPayLoad = {
        "storeId": this.props.storeId, "userId": this.props.userId, 
        "orderNumber": orderDetails.orderNumber,
        "orderType": orderDetails.orderType, "status": orderDetails.status, sortBy: sortArr,
        "pageNumber": this.page,
        "rdcNumber": orderDetails.rdcNumber,
        "aliasSKURetailerGrpID": this.props.aliasSKURetailerGrpID,
        "includeAllItems": includeAllItems,
        "samNumber": orderDetails.samNumber,
        "memberOrderRef": orderDetails.memberOrderRef
      } :
      requestPayLoad = 
      {
        "userId": this.props.userId,
        "storeId": this.props.storeId,
        "status": orderDetails.status,
        "sortBy": sortArr,
        "rdcNumber": orderDetails.rdcNumber,
        "invoiceNumber": orderDetails.invoiceNumber,
        "invoiceDateString": orderDetails.invoiceDateString,
        "memberOrderRef": orderDetails.memberOrderRef,
        "orderRegisterNumber": orderDetails.orderRegisterNumber,
        "pageNumber": this.page,
        "includeAllItems": includeAllItems,
        "aliasSKURetailerGrpID": this.props.aliasSKURetailerGrpID,
        "orderNumber": orderDetails.orderNumber,
        "samNumber": orderDetails.samNumber
      }
    
    const url = `${ORDER_ITEM_DETAILS}`
    this.setState({ loading: true });
    restUtils
      .postData(url, requestPayLoad)
      .then(response => {
        this.setState({ itemDetails: response.data.itemList,  firstItem: response.data.firstItem, lastItem: response.data.lastItem,
          currentPage: response.data.pageNumber, totalPages: response.data.pageCount,  totalItems: response.data.totalItems,
           orderTotal: response.data.orderTotal, totalWeight: response.data.totalWeight, 
          loading: false, serverError: false, poNum:response.data.memberOrderRef, isMultiPo: response.data.isMultiPOs });
          window.scrollTo(0,this.scrollPosition);
          this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  checkFillRateAndQtyCanShow = () => {
    const orderDetails = this.state.orderDetails;
    return ((orderDetails.displayedStatus === 'CANCELED' && utilities.isEmptyOrNullString(orderDetails.orderNumber)) ||
    orderDetails.displayedStatus === 'ORDER CREATED' || orderDetails.displayedStatus === 'ORDER FILLING'
    || orderDetails.displayedStatus === 'DC ORDER COMPLETE');
  }

  getWarehouseImage = (imageUrl, img_style) => {
    return renderImages.getImage({ src: imageUrl, className: 'orderImage', style: { display: img_style } })
  }

  getWareHouseRows = (rowProps, img_style, order) => {
    const warehouseCols = this.getWareHouseColumns(order, img_style);
    return (
      <>
      {getTableRow(rowProps, warehouseCols)}
      </>
    )
  }

  getWarehouseHeaders = () => {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType, getPrefer);
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(this.props.viewType, getPrefer); 
    const tableHeader = [
      {props:{className:'allOdrHeader', id:'no_border'}, text: ''},
      {props:{className:'OderItemHeader', id:'no_border'}, text: 'Item #'}
    ];

    if(!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID)) {
      tableHeader.push({props:{className:'allOdrHeader', id:'no_border'}, text: 'Alternate SKU#'});
    }
    
    tableHeader.push(
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'ADV Code #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'UPC #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Model #'},
      {props:{className:'OderItemHeader', id:'no_border'}, text: 'Item Description/MFR'},
      //{props:{className:'allOdrHeader', id:'no_border'}, text: 'Program'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Seq #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'RDC'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Weight'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Pack'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Ord Qty'}
    );

    tableHeader.push({props:{className:'allOdrHeader', id:'no_border'}, text: 'Fill Qty'});
    
    showMemberCostAndLbl && tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"COST", getPrefer)},
      {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer)},
    );
      

    tableHeader.push(
        {props:{className:'allOdrHeader', id:'no_border'}, text: 'Fill Code'}        
    );

    displaySRPCost && tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL", getPrefer)},
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'Reg'},
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'Promo'},
    );
    if(this.props.onVariablePricing){
      displaySRPCost && tableHeader.push(
        {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"VAR_RETAIL", getPrefer)},
      );
      tableHeader.push(
        {props:{className:'allOdrHeader', id:'no_border'}, text: 'Var. Method'}
      );
    }

    if (this.checkFillRateAndQtyCanShow()) {
      tableHeader.push(
        {props:{className:'allOdrHeader', id:'no_border'}, text: 'Margin'}        
      );
    }
    
    tableHeader.push(
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Source'},
      //{props:{className:'allOdrHeader', id:'no_border'}, text: 'Cancel Code'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Status'}
    );   
    
    return(getTableRow({},tableHeader,true))
  }
 
  openTrackingSite = (trackingNumber) => {    
    const upsUrl =  UPS_URL + trackingNumber.map((num, index) =>{
      return `&InquiryNumber${index + 1}=${num}`
    })
    window.open(trackingNumber[0].startsWith('1Z') ?  upsUrl.replaceAll(',', '') : FEDEX_URL.replace('trackingNumber', trackingNumber[0]), '_blank');  
  }

  getWareHouseColumns = (order, img_style) => {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType, getPrefer);
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(this.props.viewType, getPrefer); 
    const tierIcon = order.tieredCostInd ? <TierIcon /> : "";
    const tableDataCols = [
      {columnProps:{ className: 't-center', id: 'field_width' }, columnText: this.getWarehouseImage(order.imageUrl, img_style)},
      {columnProps:{ className: 't-left', id: 'field_width' }, columnText: <Link  id='item_field' onClick={this.setScrollPosition} to={'/product/' + order.itemNbr}>{utilities.hyphenForNull(order.itemNbr)}</Link>},
    ];

    if (!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID)) {
      tableDataCols.push({columnProps:{ className: 't-center', id: 'field_width' }, columnText: order.aliasSku});
    }
    tableDataCols.push(
      // { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.advCode) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.upc) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.model) },
      { columnProps: { className: 't-left', id: 'field_Desc_width' }, columnText: utilities.hyphenForNull(order.shortDesc) + "\n" +(order.vendorName? order.vendorName:'') },
      //{ columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.noteProgram) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.seqNumber) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.rdcNbr) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.weight) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.pack) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: order.qty },
    );

      tableDataCols.push(
        {columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.fillQty)}
      );
    showMemberCostAndLbl && tableDataCols.push(
      {columnProps:{ className: 't-center', id: 'field_width' }, columnText: <>{utilities.isBinLabel(order.qty) ? '-': this.roundNull(order.cost, 'Member Cost black')}{this.props.viewType !== MEMBER_VIEW ? '' : tierIcon}</>
      },
    );
    if(order.qty == 0 && order.fillQty < 0 && order.fillCode == 1){
      showMemberCostAndLbl && tableDataCols.push(
        {columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.isBinLabel(order.qty) ? '-': `(${utilities.hyphenForNull(utilities.getCostBasedOnViewType(this.props.viewType, order.extCost, 'Member Cost black', getPrefer).replaceAll('-', ''))})`}
      );
    } else {
      showMemberCostAndLbl && tableDataCols.push(
        {columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.isBinLabel(order.qty) ? '-': this.roundNull(order.extCost, 'Member Cost black')}
      );
    }
    
      tableDataCols.push(
        {columnProps: { className: 't-center', id: 'field_width' }, columnText: order.fillCode ? ('0' + order.fillCode).slice(-2) :utilities.hyphenForNull(order.fillCode)}
      );
    
    displaySRPCost && tableDataCols.push(
      {columnProps:{ className: 't-center', id: 'field_width' }, columnText: order.retail_price > 0 ? `* ${utilities.hyphenForNull(utilities.getCostBasedOnViewType(this.props.viewType, order.retail_price, 'Suggested Retail', getPrefer))}`:
      utilities.hyphenForNull(utilities.getCostBasedOnViewType(this.props.viewType, order.retailPrice,'Suggested Retail', getPrefer))},
      // {columnProps:{ className: 't-center', id: 'field_width' }, columnText: order.retailPrice > 0 ? utilities.hyphenForNull(utilities.changePriceToNumber(order.retailPrice)):
      // utilities.hyphenForNull(utilities.changePriceToNumber(order.retail_Price))},
      // {columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(utilities.changePriceToNumber(order.promoRetail))},
    );
    if(this.props.onVariablePricing){
      displaySRPCost && tableDataCols.push(
        {columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(utilities.getCostBasedOnViewType(this.props.viewType, order.variablePrice, 'Suggested Retail', getPrefer))},
      );
      tableDataCols.push(
        {columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.variableMethod)}
      );
    }
    if(this.checkFillRateAndQtyCanShow()) {
      tableDataCols.push(
        {columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.zeroForNull((order.margin*100).toFixed(1))+'%'}
      );
    }
    tableDataCols.push(
      {columnProps:{ className: 't-center', id: 'field_width' }, columnText:utilities.hyphenForNull(order.source)},
      //{columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.cancelCode)},
      {columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.status)}
    )
    return tableDataCols;    
  }
 
  
  getSortByFields = (sortParams) => {
    const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, value: sortParams, id: 'pl_dropdown'};
              
    const options = [
      {props:{value:'itemNbr', id: 'itemNbr'},text:'Item #'},
      {props:{value:'model', id: 'modelNbr'}, text:'Model #'},
      {props:{value:'shortDesc', id: 'shortDesc'}, text: 'Description'},
      {props:{value:'upc', id: 'upc'}, text:'UPC'}
    ];

    return(      
      getSelect(selectProps, options)
    );
  }

  render() {
    const orderDetails = this.state.orderDetails;
    const itemDetails = this.state.itemDetails;
    const isChecked = this.state.isChecked;
    const multiPoSelectMsg = isChecked ? 'Show All Items for the PO' : 'Show All Items for the Invoice';
    const img_style = this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType, getPrefer);
    return (
      this.state.serverError ? <InternalServerError /> :
        <div className='page-wrap'  ref={node => (this.componentRef = node)}>
          <div className='editOrderHeaderTop'>
          <span  className='page-header'>Order Status</span>
          <span id='print_img'>
              <ReactToPrint
                pageStyle='@page{size:landscape;}'
                trigger={() => (
                  <i className='print icon' id='printicon'></i>
                )}
                content={() => this.componentRef}
              /> 
          </span>
          </div>
          {this.state.loading &&
            <div>
              <Loader active />
            </div>
          }
          <div className='editOrderHeaderTwo'>
          <div className='sort_by'>
            <span className='filter-label'>Sort By</span>
            {this.getSortByFields(this.sortParam)}
            <select className='sort-order combo' onChange={this.changeSortDirection} defaultValue='asc' value={this.sortDirection}>
             <option value='asc'>Ascending</option>
             <option value='desc'>Descending</option>
            </select>           
            <span className='order_count'>
            <ExcelExport 
              className='excel_left excelHide' 
              fileName={'Order Item Details'}
              sortParam = {this.sortParam}
              sortDirection = {this.sortDirection}
              orderDetails = {this.state.orderDetails}
              storeId = {this.props.storeId}
              userId = {this.props.userId}
              viewType={this.props.viewType}
              getprefer={
                this.props.getprefer &&
                this.props.getprefer.customerView
                ? this.props.getprefer.customerView
                : 'c1'
              }
              page = {this.page}
              orderResults={itemDetails} 
              aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
              onVariablePricing={this.props.onVariablePricing}
              checkFillRateAndQtyCanShow={this.checkFillRateAndQtyCanShow}/>
            </span>
          </div>
          <br />

          <div className='font-style'>
            <p>Warehouse Order</p>
                        <p className='order_count allorderCount'>
              <span onClick={this.showHideImages} id='hideShowImages'>{this.state.img_txt_to_display}</span>
              <span id='back_button' onClick={this.goToPreviousPage}>Back to Orders</span>
            </p>
          </div>
          <div className='font-style'>
            <p className='itemDetails itemDetailsMobile'>PO #:<span className='order_details'>{utilities.naForNull(this.state.poNum)}</span></p>
            <p className='itemDetails itemDetailsMobileInvoice'>Invoice #:<span className='order_details'>{utilities.hyphenForNull(orderDetails.displayedInvoiceNumber)}</span></p>
            {showMemberCostAndLbl && <p className='itemDetails itemDetailsMobileInvoice'>{utilities.getLabelsByType(this.props.viewType,"ORDER_TOTAL", getPrefer)}:<span className='order_details'>{this.roundNull(this.state.orderTotal, 'Member Cost black')}</span></p>}
            <p className='itemDetails itemDetailsMobileInvoice'>Total Weight:<span className='order_details'>{this.state.totalWeight ? `${utilities.roundForNotNull(this.state.totalWeight)} LBS` : '-'}</span></p>
            <p className='itemDetails itemDetailsMobileInvoice'>**Tracking #:<span className='order_details_tracking'>{this.renderTrackingDetails(orderDetails.retailerTrackingNumber)}</span></p>
            <p className='itemDetails itemDetailsMobileInvoice'>Order Status:<span className='order_details'>{utilities.hyphenForNull(orderDetails.displayedStatus)}</span></p>
            <p className='itemDetails itemDetailsMobileInvoice'>Order Created Date:<span className='order_details'>{utilities.hyphenForNull(orderDetails.orderDateString)}</span></p>
             
          </div>
          </div>
          <div className={window.innerWidth > 1024 ? "displayFlex allOrdersTable orderstatusMargin" : "allOrdersTableItem"}>
            <Responsive minWidth='1025'>            
              <div class="eighteen wide column">
              <div style={{position:'sticky', top:'15rem', background:'#f2f2f2',paddingBottom:'10px'}}>
                <div className='order-pagination pagination-padding'>
                  <span className='OrderTotalitem'>
                    {/* {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.totalItems} orders */}
                  </span>
                  {/* <span className='paginationAllorders'>        
                  {this.state.totalPages > 0 && (     
                    getPagination(0, this.state.currentPage, this.state.totalPages, this.onPageChange, null, 1)    
                  )}
                  </span> */}
                 
                  <div className='order_count'>
                  {this.state.isMultiPo  && <span onClick={this.handleChecked} id='hideShowImages'>{multiPoSelectMsg}</span>}             
                    <span>Showing {itemDetails.length} Lines</span>                    
                </div>
                </div>
                </div>
                {getTable({class:"ui celled table all-wh-table stickyTableOrderLevelDetails", id:'font-size-orders'},this.getWarehouseHeaders(), itemDetails, this.getWareHouseRows, {className:'wh-row'}, img_style)}                
              </div>
            </Responsive>
            <Responsive maxWidth='1024'>
            <div className='font-style'>
            <p>Warehouse Order</p>
            <p>PO #:<span className='order_details'>{utilities.naForNull(this.state.poNum)}</span></p>
            <p>Invoice #:<span className='order_details'>{utilities.hyphenForNull(orderDetails.displayedInvoiceNumber)}</span></p>
            <p>*Tracking #:<span className='order_details_tracking'>{this.renderTrackingDetails(orderDetails.retailerTrackingNumber)}</span></p>
            <p>Order Created Date:<span className='order_details'>{utilities.hyphenForNull(orderDetails.orderDateString)}</span></p>
            <p className='order_count allorderCount'>
              <span onClick={this.showHideImages} id='hideShowImages'>{this.state.img_txt_to_display}</span>
              <span id='back_button' onClick={this.goToPreviousPage}>Back to Orders</span>
            </p>
          </div>
          <div className='portrait-pagination'>
                <div className='OrderTotalitem'>
                  {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.totalItems} orders
                </div>
                {/* <div className='paginationAllorders'>                
                {this.state.totalPages > 0 && ( 
                  getPagination(0, this.state.currentPage, this.state.totalPages, this.onPageChange, null, 1)                  
                )}
                </div> */}
                {this.state.isMultiPo  && <span onClick={this.handleChecked} id='hideShowImages'>{multiPoSelectMsg}</span>} 
                <div className='order_count'>Showing {itemDetails.length} Lines
                </div>
            </div>
              {itemDetails && itemDetails.map(order => {
                return <OrderCard 
                        order={order} 
                        img_style={this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none'}
                        showLess={this.showLess}
                        showMore={this.showMore}
                        checkFillRateAndQtyCanShow = {this.checkFillRateAndQtyCanShow}
                        aliasSKURetailerGrpID = {this.props.aliasSKURetailerGrpID} 
                        getPrefer={this.props.getprefer &&
                          this.props.getprefer.customerView
                          ? this.props.getprefer.customerView
                          : 'c1'}
                        viewType={this.props.viewType}/>
              })}
            </Responsive>
          </div>
          {!this.state.loading &&
          <><div className='t-right'>
              <i className = 'addedBy'>
                  *Price reflects override retail entered when order was submitted.
              </i>
          </div>
          <div className='t-right'>
              <i className = 'addedBy'>
                  **Harvard, Atlanta, Woodland and Central Ship are the preferred Ship to customer RDCs. Orders placed via these RDCs will have tracking number information available.
              </i>
          </div></>
        }
        </div>
    )
  }
}

const OrderCard = ({ order, img_style, showLess, showMore, checkFillRateAndQtyCanShow, aliasSKURetailerGrpID, getPrefer, viewType }) => {
  const tierIcon = order.tieredCostInd ? <TierIcon /> :"";
  const showMemberCostAndLbl =  shouldHideCostForPreference(viewType, getPrefer);
  const displaySRPCost = shouldHideSUGG_RETAILForPreference(viewType, getPrefer); 
  const  roundNull = (data, field) => {
    if(data){
      return <MaskPrice
        field = {field}
        getprefer={getPrefer}
        viewType={viewType}
        fieldVal={Number(data).toFixed(2)}
      />
    } else return "-"; 
  }

  return (
    <div className='all-orders-card'>
      <table id='order-card-table'>
        <td className='t-center' id='field_width'>{renderImages.getImage({ src: order.imageUrl, className: 'orderImage', style: { display: img_style } })}</td>
        <tr>
          <td className='order-card-field padding'>Item #</td>
          <td>{utilities.hyphenForNull(order.itemNbr)}</td>
        </tr>
        {!utilities.isEmptyOrNullString(aliasSKURetailerGrpID) && (
        <tr>
          <td className='order-card-field padding'>Alternate SKU#</td>
          <td>{order.aliasSku}</td>
        </tr>
        )}
        {/* <tr>
          <td className='order-card-field padding'>ADV Code #</td>
          <td>{utilities.hyphenForNull(order.advCode)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field padding'>UPC #</td>
          <td>{utilities.hyphenForNull(order.upc)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Model #</td>
          <td>{utilities.hyphenForNull(order.model)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Item Description</td>
          <td >{utilities.hyphenForNull(order.shortDesc)}</td>
        </tr>
        {order.showLess === false && <> <tr>
          <td className='order-card-field padding'>Program</td>
          <td >{utilities.hyphenForNull(order.noteProgram)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Seq #</td>
          <td >{utilities.hyphenForNull(order.seqNumber)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>RDC</td>
          <td >{utilities.hyphenForNull(order.rdcNbr)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Weight</td>
          <td >{utilities.hyphenForNull(order.weight)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Pack</td>
          <td>{utilities.hyphenForNull(order.pack)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Quantity</td>
          <td>{order.qty}</td>
        </tr>
        {
          <tr>
            <td className='order-card-field padding'>Fill Qty</td>
            <td>{utilities.hyphenForNull(order.fillQty)}</td>
          </tr>
         } 
        {showMemberCostAndLbl && <tr>
          <td className='order-card-field padding'>{utilities.getLabelsByType(viewType,"COST", getPrefer)}</td>
          <td>{utilities.isBinLabel(order.qty) ? '-': roundNull(order.cost)}<>{viewType !== MEMBER_VIEW && tierIcon}</></td>
        </tr>}
        {showMemberCostAndLbl && <tr>
          <td className='order-card-field padding'>{utilities.getLabelsByType(viewType,"EXTENDED_COST", getPrefer)}</td>
          <td>{order.qty == 0 && order.fillQty < 0 && order.fillCode == 1 ? utilities.isBinLabel(order.qty) ? '-': `(${utilities.hyphenForNull(utilities.getCostBasedOnViewType(viewType, order.extCost, 'Member Cost black', getPrefer).replaceAll('-', ''))})` : utilities.isBinLabel(order.qty) ? '-': this.roundNull(order.extCost, 'Member Cost black')}</td>
        </tr>}
        {
          <tr>
            <td className='order-card-field padding'>Fill Code</td>
            <td>{utilities.hyphenForNull(order.fillCode)}</td>
          </tr>
         } 
        {displaySRPCost && <tr>
          <td className='order-card-field padding'>Sugg. Retail</td>
          <td>{ order.retail_price > 0 ? `* ${utilities.hyphenForNull(utilities.getCostBasedOnViewType(viewType, order.retail_price, 'Suggested Retail', getPrefer))}`:
            utilities.hyphenForNull(utilities.getCostBasedOnViewType(viewType, order.retailPrice, 'Suggested Retail', getPrefer))}</td>
        </tr>}
        { displaySRPCost && this.props.onVariablePricing &&
        <tr>
          <td className='order-card-field padding'>{utilities.getLabelsByType(viewType,"VAR_RETAIL", getPrefer)}</td>
          <td>{utilities.hyphenForNull(utilities.getCostBasedOnViewType(viewType, order.variablePrice, 'Suggested Retail', getPrefer))}</td>
        </tr>
        }
        { this.props.onVariablePricing &&
        <tr>
          <td className='order-card-field padding'>Var. Method</td>
          <td>{utilities.hyphenForNull(order.variableMethod)}</td>
        </tr>
        }
        {checkFillRateAndQtyCanShow() &&
          <tr>
            <td className='order-card-field padding'>Margin</td>
            <td>{utilities.zeroForNull(order.margin*100).toFixed(1)}%</td>
          </tr>
         } 
        <tr>
          <td className='order-card-field padding'>Source</td>
          <td>{utilities.hyphenForNull(order.source)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Cancel Code</td>
          <td>{utilities.hyphenForNull(order.cancel_code)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding' >Status</td>
          <td>{utilities.hyphenForNull(order.status)}</td>
        </tr> 
        </>}
        <tr>
          <td colSpan = {2} className='t-right'>
            {order.showLess !== false && <p>
              <a className="btn btn-primary" onClick={() => showMore(order.itemNbr)}>...show more</a>
            </p>}
            {order.showLess === false && <p>
              <a className="btn btn-primary" onClick={() =>showLess(order.itemNbr)}>...show less</a>
            </p>}
          </td>
        </tr>
      </table>
    </div>
  )
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId, userName, userId, getprefer:state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer:state.preference.listData,
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
    onVariablePricing: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.onVariablePricing : false,
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({}, dispatch)
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(orderItemDetails));
