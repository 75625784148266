import React from "react";
import BreadcrumbComp from "../../../components/BreadcrumbComp";
import OptionsSection from "./OptionsSection";
import { getPagination } from "../../common/CustomComponents";
import ItemListView from "./ItemListView";
import ItemGridView from "./ItemGridView";
import Filter from "./Filter";
import restUtils from "../../../utils/restUtils";
import { VENDOR_DROPSHIP_ITEM_LIST } from "../../../urlConstants";
import { connect } from "react-redux";
import utilities from "../../../utils/utilities";
import { exportExcel, getFirstCartId } from "../../common/ComponentUtilities";
import { isFilterRequired } from "./ReunionComponentUtilities";
import { MaskPrice } from "../../common/MaskPrice";
import { viewAllCarts, updateQty  } from "../../Cart/actions";
import {
  isSearchRequired,
  isSortRequired,
  getSortByOptions,
  getSearchByOptions,
  isShowByRequired,
  getBreadcrumbLinks,
  isAddToCartRequired,
  isSeqRequried,
  getPdpParams,
  getPageNumber,
  handleQtyChange,
  addToCart,
  getVendorDetails,
  setScrollPosition,
} from "./itemListingUtilities";
import { Loader } from "semantic-ui-react";
import { isArray } from "util";
import _ from "lodash";
import UnderConstruction from "../../UnderConstruction";
import config from "../../../config";
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

class VendorDropShipItemListing extends React.Component {
  constructor(props) {
    super(props);
    const {
      eventId,
      flowType,
      fromReunionHome,
      relayNbr,
      relayYear,
      shoppingId,
      vendorId,
      vendorName,
      eventSupplierSelectionID,
    } = props.match?.params ? props.match?.params: props ;
    const shoppingAreaName = props?.match?.params?.shoppingAreaName ? decodeURIComponent(props?.match?.params?.shoppingAreaName): props?.shoppingAreaName;
    const promoName = decodeURIComponent(props?.match?.params?.promoName);
    const shoppingAreaMetaId = props?.location?.state?.shoppingAreaMetaId ? decodeURIComponent(props?.location?.state?.shoppingAreaMetaId): props?.shoppingMetaDataId;
    this.state = {
      fromReunionHome: fromReunionHome,
      flowType: flowType,
      relayNbr: relayNbr,
      promoName: promoName,
      relayYear: relayYear,
      shoppingAreaName: shoppingAreaName,
      shoppingAreaId: shoppingId,
      shoppingAreaMetaId: shoppingAreaMetaId !== 'undefined' ? shoppingAreaMetaId : shoppingId,
      eventId: eventId,
      eventName: promoName,
      vendorId: vendorId ? vendorId : "",
      vendorName: vendorName ? vendorName : "",
      dealDetails: false,
      isFilterRequired: isFilterRequired(flowType),
      filters: [],
      pageHeader: props.pageHeader,
      breadcrumbLinks: props.breadcrumbLinks,
      isLoading: false,
      eventSupplierSelectionID: eventSupplierSelectionID,
      productsData: [],
      quantities: [],
      overrideRetails: [],
      eventEnableAfter: "",
      eventEnableBefore: "",
      eventMessage: "",
      isNoProducts: false,
      imgTextToDisplay:
        props.getprefer &&
        props.getprefer.thumbnailImage &&
        props.getprefer.thumbnailImage !== "Y"
          ? "Show Images"
          : "Hide Images",
      isGridView:
        props.getprefer &&
        props.getprefer.prdctListlayoutFormat &&
        props.getprefer.prdctListlayoutFormat !== "L",
      paginationParams: {},
      productSelectedForModal: null,
      indexForModal: null,
      appliedFilters: {},
      searchPattern: "[0-9]*",
      searchValue: this.props?.searchValue ? this.props?.searchValue: "",
      orderDeadline: "",
    };
    this.pageSize =
      this.props.getprefer && this.props.getprefer.resultPerPage
        ? this.props.getprefer.resultPerPage
        : "50";
    this.scrollPosition = 0;
    this.sortParam = this.state.shoppingAreaId === '9' ? 'seqno' : '';
    this.sortDirection = "asc";
    this.searchby = this.props?.searchBy ? this.props?.searchBy:"item";
    this.searchValue = this.props?.searchValue ? this.props?.searchValue: "";
    this.page = 1;
    this.defaultChecked = utilities.isEmptyOrNullString(vendorId)
      ? null
      : {
          name: "Vendor Name",
          value: vendorId,
        };
    this.filterList = this.props.fromScanning && this.props.filterBy ? this.props.filterBy : '';
    this.pathname = "";
    this.handleQtyChange = handleQtyChange.bind(this);
    this.addToCart = addToCart.bind(this);
    this.searchByOptions = getSearchByOptions(
      flowType,
      utilities.isAliasSkuRequired(this.props.multiStoreDetails)
    );
    this.getVendorDetails = getVendorDetails.bind(this);
    this.setScrollPosition = setScrollPosition.bind(this);
    this.hideFilter = this.props.fromScanning  && true
  }

  componentDidMount() {
    this.props.updateQty(false)
    let queryParam = window.location.href.split("?");
    this.pathname = this.props.location && this.props.location.pathname;
    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    } else {
      this.fetchItems();
    }
  }

  componentDidUpdate() {
    if(this.props.qtyUpdate) {
      this.scrollPosition = window.pageYOffset;
      this.fetchItems();
      this.props.updateQty(false)
    }
  }

  handleViewChange = (isGridView) => {
    this.setState({ isGridView });
  };

  showHideImages = (evt) => {
    let imgTextToDisplay =
      evt.target.innerHTML === "Show Images" ? "Hide Images" : "Show Images";
    this.setState({ imgTextToDisplay });
  };

  showDealDetails() {
    this.setState({
      dealDetails: true,
    });
  }
  DialogClose = () => this.setState({ dealDetails: false });

  getPayloadVendorDropShip = (isCompleteList) => {
    const {
      eventId,
      relayNbr,
      relayYear,
      vendorId,
      paginationParams,
      searchValue,
      shoppingAreaId,
      eventSupplierSelectionID,
    } = this.state;
    const resultPerPage = !isCompleteList
      ? this.pageSize
      : paginationParams.totalItems;
    let currentCart =
      this.props?.carts &&
      this.props.carts.find(
        (cart) => parseInt(cart.cartId) === parseInt(this.props.cartId)
      );
    let getCartId = getFirstCartId(shoppingAreaId,eventId,this.props.carts, this.props.currentCartId)
    return {
      userId: this.props.userId,
      memNbr: this.props.storeId,
      searchby: this.props?.searchBy ? this.props?.searchBy:this.searchby,
      searchvalue: decodeURIComponent(searchValue),
      promoNbr: eventId,
      relayNbr: relayNbr,
      relayYear: relayYear,
      eventSupplierSelectionId: !eventSupplierSelectionID
        ? null
        : eventSupplierSelectionID,
      sortBy: this.sortParam,
      sortOrder: this.sortDirection,
      page: isCompleteList ? "1" : this.page,
      resultPerPage: resultPerPage,
      rdcNbr: this.props.rdc,
      shoppingId: shoppingAreaId,
      cartId: getCartId,
      multiStoreCartId:
        utilities.msExist(this.props.multiStoreDetails) > 1 &&
        currentCart?.parentId
          ? currentCart.parentId
          : "",
      aliasSKURetailerGrpID: utilities.isAliasSkuRequired(
        this.props.multiStoreDetails
      )
        ? 1
        : 0,
      ...(!_.isEmpty(this.filterList) ? { FilterBy: this.filterList } : {}),
    };
  };
  fetchItems = () => {
    this.setState({ isLoading: true });
    const payloadVendorDrop = this.getPayloadVendorDropShip();
    restUtils
      .postData(VENDOR_DROPSHIP_ITEM_LIST, payloadVendorDrop)
      .then((response) => {
        if (
          response.data &&
          (response.data?.evtStsActiveBefore === false ||
            response.data?.evtStsActiveAfter === false)
        ) {
          this.setState({
            eventEnableAfter: response.data?.evtStsActiveAfter,
            eventEnableBefore: response.data?.evtStsActiveBefore,
            eventMessage: response.data?.Message,
          });
          return;
        } else if (
          !response?.data?.dropShipItemsList ||
          response.data.dropShipItemsList.length === 0
        ) {
          this.setState((prevState) => {
            return {
              isLoading: false,
              productsData: [],
              isNoProducts: true,
              paginationParams: {
                ...prevState.paginationParams,
                totalPages: "0",
                currentPage: "0",
                totalItems: "0",
                firstItem: "0",
                lastItem: "0",
              },
            };
          });
          return;
        }
        const { data } = response;
        let filters = data?.filters;
        filters.forEach((filter) => {
          if (filter.name === "Vendor Name") {
            filter.valueList = utilities.objSortValues(filter.valueList);
          }
        });
        this.setState({
          isLoading: false,
          isNoProducts: false,
          orderDeadline: response.data?.OrderDeadline
            ? utilities.getDateFromTimeStamp(response.data.OrderDeadline)
            : "",
          productsData: data.dropShipItemsList,
          paginationParams: data.pagination[0],
          filters,
          quantities: data?.dropShipItemsList?.map((product) => {
            return [
              utilities.emptyForNull(product.userQty),
              utilities.emptyForNull(product.userQty2),
              utilities.emptyForNull(product.userQty3),
              utilities.emptyForNull(product.userQty4),
            ];
          }),
          overrideRetails: data?.dropShipItemsList.map((product) =>
            utilities.emptyForNull(product.overrideExisting)
          ),
        },()=>{
          window.scrollTo(0,this.scrollPosition);
        });
      });
  };
  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        currentPage: 1,
        appliedFilters,
        clearFilters: false,
        backPath: this.pathname,
      },
      () => {
        this.page = 1;
        this.fetchItems();
        this.setBackButtonPath(appliedFilters);
      }
    );
  };

  closeMultistoreModal = () =>{
    //this.fetchItems();
  }

  changePageSize = (e) => {
    this.pageSize = e.currentTarget.value;
    this.page = "1";
    this.scrollPosition = window.pageYOffset;
    this.fetchItems();
    this.setBackButtonPath();
  };

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    this.page = getPageNumber(e.currentTarget.text, paginationParams);
    this.scrollPosition = 0;
    window.scrollTo(0, 0);
    this.fetchItems();
    this.setBackButtonPath();
  };
  getListingHeader = () => {
    const { paginationParams, imgTextToDisplay } = this.state;
    return (
      <div className="listing-header">
        <span>
          {paginationParams.firstItem}-{paginationParams.lastItem} of{" "}
          {paginationParams.totalItems} products
        </span>
        <span onClick={this.showHideImages} id="show_and_hide_images">
          {imgTextToDisplay}
        </span>
        {paginationParams.totalPages > 0 && (
          <span className="pagination_top">
            {getPagination(
              0,
              parseInt(paginationParams.currentPage),
              parseInt(paginationParams.totalPages),
              this.onPageChange,
              null,
              1
            )}
          </span>
        )}
        {!this.props?.fromScanning &&
         exportExcel({
          className: "exportPDF exportPdfPLP",
          onClick: this.handleExport,
        })}
      </div>
    );
  };
  getFieldSet = (product, index) => {
    let quantity = this.state.quantities[index];
    let overrideRetail = this.state.overrideRetails[index];
    const { access, CatMbr } = this.props?.multiStoreDetails;
    const { getprefer, memberViewType } = this.props;
    const memberCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={utilities.getCostForQtyHotDeals(
          product?.pricing,
          quantity,
          true
        )}
        field={"Member Cost black"}
      />
    );
    const costWithTier2 = utilities.getCostWithTier2(
      product,
      memberCost,
      quantity,
      memberViewType
    );
    const evtCost = utilities.getEventCost(
      product,
      utilities.getPreferenceHideMask(getprefer),
      memberViewType
    );
    const evtCostAtReunion =
      parseFloat(product.spcCost) === 0.0 || product.savings === 100;
    const savings = evtCostAtReunion
      ? "N/A"
      : utilities.hyphenForZeroPercentage(product.savings);
    const maxQtyExists = product?.maxQtyItemRestriction > 0;
    return !this.state.isGridView && window.innerWidth >= 1024
      ? [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                [
                  { label: "ITEM#:", val: product.itemNbr },
                  {
                    label: "NON-STOCKED:",
                    val: product.stocked,
                  },
                  {
                    label: "QUANTITY:",
                    type: "qty1",
                    index,
                    isTextBox: true,
                    val: quantity[0],
                  },
                ],
                [
                  { label: "MODEL#:", val: product.model },
                  {},
                  {
                    label: "Contact Vendor for Ship Dates",
                    val: "",
                  },
                ],
                [
                  { label: "UPC:", val: product.upcNum },
                  {
                    label: "SAVINGS:",
                    val: savings,
                    labelClass: "red_color First_level_details",
                  },
                ],
                [
                  ...(access === 1 && access !== 1 && !CatMbr
                    ? []
                    : [
                        {
                          label: "REG DS COST:",
                          val: costWithTier2,
                        },
                      ]),
                  {
                    label: "PACK:",
                    val: `${product.sPackType ? product.sPackType : ""}${
                      product.sPackQty ? product.sPackQty : ""
                    }`,
                  },
                ],
                [
                  ...(access === 1 && access !== 1 && !CatMbr
                    ? []
                    : [
                        {
                          label: "DS EVT COST:",
                          val: evtCost,
                          labelClass: "red_color First_level_details",
                        },
                      ]),
                  ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                    ? [
                        {
                          label: "Alternate SKU# :",
                          val: utilities.hyphenForNull(product.aliasSku),
                        },
                      ]
                    : [{}]),
                ],
                [
                  ...(maxQtyExists
                    ? [
                        {
                          label: "Max Qty :",
                          val: product.maxQtyItemRestriction,
                        },
                      ]
                    : []),
                  ...(evtCostAtReunion
                    ? [
                        {
                          label: "RESTRICTED:",
                          val: "Pricing Avail @ Reunion",
                          className: "color-red t-center First_level_details",
                        },
                      ]
                    : []),
                  ...(utilities.isEmptyOrNullString(product.restricted)
                    ? [
                        {
                          label: "RESTRICTED:",
                          val: product.restricted ? "Restricted Product" : "",
                          className: "t-center restricted-product",
                        },
                      ]
                    : []),
                ],
              ]
            : [
                [
                  { label: "ITEM#:", val: product.itemNbr },
                  { label: "MODEL#:", val: product.model },
                  {
                    label: "QUANTITY:",
                    type: "qty1",
                    index,
                    isTextBox: true,
                    val: quantity[0],
                  },
                ],
                [
                  { label: "UPC:", val: product.upcNum },
                  {},
                  {
                    label: "Contact Vendor for Ship Dates",
                    val: "",
                  },
                ],
                [
                  {
                    label: "PACK:",
                    val: `${product.sPackType ? product.sPackType : ""}${
                      product.sPackQty ? product.sPackQty : ""
                    }`,
                  },
                  {
                    label: "SAVINGS:",
                    val: savings,
                    labelClass: "red_color First_level_details",
                  },
                ],
                [
                  {
                    label: "NON-STOCKED:",
                    val: product.stocked,
                  },
                ],
                [
                  ...(maxQtyExists
                    ? [
                        {
                          label: "Max Qty :",
                          val: product.maxQtyItemRestriction,
                        },
                      ]
                    : []),

                  ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                    ? [
                        {
                          label: "Alternate SKU# :",
                          val: utilities.hyphenForNull(product.aliasSku),
                        },
                      ]
                    : []),
                ],
              ]),
        ]
      : [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                { label: "ITEM#:", val: product.itemNbr },
                { label: "MODEL#:", val: product.model },
                { label: "UPC:", val: product.upcNum },
                {
                  label: "REG DS COST:",
                  val: costWithTier2,
                },
                {
                  label: "DS EVT COST:",
                  val: evtCost,
                  classname: "red_color First_level_details",
                },
                {},
                {
                  label: "SAVINGS:",
                  val: savings,
                  classname: "red_color First_level_details",
                },
                {
                  label: "PACK:",
                  val: `${product.sPackType ? product.sPackType : ""}${
                    product.sPackQty ? product.sPackQty : ""
                  }`,
                },
                {
                  label: "NON-STOCKED:",
                  val: product.stocked,
                },
                ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                  ? [
                      {
                        label: "Alternate SKU# :",
                        val: utilities.hyphenForNull(product.aliasSku),
                      },
                    ]
                  : []),
                ...(maxQtyExists
                  ? [
                      {
                        label: "MAX QTY : ",
                        val: product.maxQtyItemRestriction,
                      },
                    ]
                  : []),
                {
                  label: "QUANTITY:",
                  type: "qty1",
                  index,
                  isTextBox: true,
                  val: quantity[0],
                },
                {
                  label: "Contact Vendor for Ship Dates",
                  val: "",
                },
                {
                  label: "RESTRICTED:",
                  val: product.restricted ? "Restricted Product" : "",
                  className: "t-center restricted-product",
                },
              ]
            : [
                { label: "ITEM#:", val: product.itemNbr },
                { label: "MODEL#:", val: product.model },
                { label: "UPC :", val: product.upcNum },
                {
                  label: "SAVINGS:",
                  val: savings,
                  classname: "red_color First_level_details",
                },
                {
                  label: "PACK:",
                  val: `${utilities.emptyForNull(
                    product.sPackType
                  )}${utilities.emptyForNull(product.sPackQty)}`,
                },
                {
                  label: "NON-STOCKED:",
                  val: product.stocked,
                },
                ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails)
                  ? [
                      {
                        label: "Alternate SKU# :",
                        val: utilities.hyphenForNull(product.aliasSku),
                      },
                    ]
                  : []),
                {
                  label: "QUANTITY:",
                  type: "qty1",
                  index,
                  isTextBox: true,
                  val: quantity[0],
                },
                {
                  label: "Contact Vendor for Ship Dates",
                  val: "",
                },
                {
                  label: "RESTRICTED:",
                  val: product.restricted ? "Restricted Product" : "",
                  className: "t-center restricted-product",
                },
              ]),
        ];
  };
  getItemParams = (product, index) => {
    let itemParams = { product };
    const fieldSet = this.getFieldSet(product, index);
    itemParams = {
      ...itemParams,
      fieldSet,
      pdpParams: getPdpParams(this.state.flowType, product),
      itemIndex: index,
    };
    return itemParams;
  };
  getItemListParams = () => {
    const { flowType } = this.state;
    return {
      handleQtyChange: this.handleQtyChange,
      isAddToCartRequired: isAddToCartRequired(flowType),
      isScanListing: false,
      isSeqRequired: isSeqRequried(flowType),
    };
  };

  getUrlFilters = (queryParam) => {
    // to reinitialise fields from url
    const queryString = queryParam[1];
    let getParams = queryParam[1].split("&");
    this.page = getParams[0].split("=")[1];
    this.pageSize = getParams[1].split("=")[1];
    this.sortParam = queryString.includes("&SortParam=")
      ? this.getFieldFromUrl(queryString, "SortParam=")
      : this.sortParam;
    this.sortDirection = queryString.includes("SortType=asc")
      ? "asc"
      : queryString.includes("SortType=desc")
      ? "desc"
      : this.sortDirection;
    if (queryString.includes("&SearchBy=")) {
      this.searchby = this.getFieldFromUrl(queryString, "SearchBy=");
    }
    if (queryString.includes("&SearchValue=")) {
      this.searchValue = this.getFieldFromUrl(queryString, "SearchValue=");
    }
    this.scrollPosition = queryString.includes("scrollPosition=")
      ? this.getFieldFromUrl(queryString, "scrollPosition=")
      : 0;
    let filterString = queryString.includes("filterString")
      ? queryString.split("filterString=")[1]
      : "";
    let appliedFilters = utilities.getFilterArrayAsMultiSelectObject(
      filterString
    );
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        searchValue: this.searchValue,
        appliedFilters: appliedFilters,
      },
      () => {
        this.fetchItems();
      }
    );
  };
  getFieldFromUrl = (queryString, fieldName) => {
    return queryString?.split(fieldName)[1]?.split("&")[0];
  };
  setBackButtonPath = (newAppliedFilters) => {
    const appliedFilters = newAppliedFilters
      ? newAppliedFilters
      : this.state.appliedFilters;
    let pathName = "";
    pathName = utilities.isEmptyOrNullString(this.page)
      ? "Page=1"
      : "Page=" + this.page;
    pathName = pathName + "&ResultsPerPage=" + this.pageSize;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    pathName = pathName + "&scrollPosition=" + this.scrollPosition;
    if (!utilities.isEmptyOrNullString(this.state.searchValue)) {
      pathName =
        pathName +
        "&SearchBy=" +
        this.searchby +
        "&SearchValue=" +
        this.state.searchValue;
    }
    pathName =
      this.scrollPosition > 0
        ? pathName + "&scrollPosition=" + this.scrollPosition
        : pathName;
    if (!_.isEmpty(this.filterList)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(appliedFilters);
    }
    if (pathName !== "") {
      let pushUrl = this.pathname + "?" + pathName;
      if(!this.props.fromScanning) {
      window.history.replaceState(window.history.state, "", pushUrl);
      }
    }
  };
  getListing = () => {
    const cartParams = {
      addToCart: this.addToCart,
    };
    const imgStyle =
      this.state.imgTextToDisplay === "Hide Images"
        ? "displayBlock"
        : "displayNone";
    const { isGridView } = this.state;
    const itemListParams = this.getItemListParams();
    return (
      <div
        className={
          isGridView ? "ui stackable four column grid product-grid" : ""
        }
      >
        {this.state?.productsData?.map((product, index) => {
          const itemParams = this.getItemParams(product, index);
          return (
            <>
              {!isGridView && window.innerWidth >= 1024 ? (
                <ItemListView
                  flowType={this.state.flowType}
                  quantitiesMs={this.state.quantities}
                  indexCheck={index}
                  cartParams={cartParams}
                  itemParams={itemParams}
                  imgStyle={imgStyle}
                  product={itemParams.product}
                  shoppingAreaName={this.state.shoppingAreaName}
                  shoppingAreaId={this.state.shoppingAreaId}
                  shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                  itemListParams={itemListParams}
                  setBackButtonPath={this.setScrollPosition}
                  relayNbr={this.state.relayNbr}
                  closeMultistoreModal={this.closeMultistoreModal}
                />
              ) : (
                <ItemGridView
                  flowType={this.state.flowType}
                  indexCheck={index}
                  quantitiesMs={this.state.quantities}
                  cartParams={cartParams}
                  itemParams={itemParams}
                  shoppingAreaId={this.state.shoppingAreaId}
                  shoppingAreaMetaId={this.state.shoppingAreaMetaId}
                  shoppingAreaName={this.state.shoppingAreaName}
                  imgStyle={imgStyle}
                  product={itemParams.product}
                  itemListParams={itemListParams}
                  setBackButtonPath={this.setScrollPosition}
                  relayNbr={this.state.relayNbr}
                  closeMultistoreModal={this.closeMultistoreModal}
                />
              )}
            </>
          );
        })}
      </div>
    );
  };
  searchItems = (e) => {
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  changeSearchValue = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  changeSearchBy = (e) => {
    const option = this.searchByOptions.find(
      (option) => option?.props?.value === e.target.value
    );
    const pattern = option?.pattern;
    this.searchby = e.target.value;
    this.setState({ searchPattern: pattern, searchValue: "" });
  };
  changeSortParam = (e) => {
    this.sortParam = e.target.value;
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  changeSortDirection = (e) => {
    this.sortDirection = e.target.value;
    this.sortParam = utilities.isEmptyOrNullString(this.sortParam)
      ? "vendorname"
      : this.sortParam;
    this.page = 1;
    this.fetchItems();
    this.setBackButtonPath();
  };
  handleExport = () => {
    const payloadVendorShip = this.getPayloadVendorDropShip(true);
    utilities.showToast("Exporting to Excel...");
    restUtils
      .postData(VENDOR_DROPSHIP_ITEM_LIST, payloadVendorShip)
      .then((response) => {
        if (
          !response?.data?.dropShipItemsList ||
          response.data.dropShipItemsList.length === 0
        ) {
          utilities.showToast("No products available.", true);
          return;
        }
        const itemsList = response?.data?.dropShipItemsList?.map((product) => {
          const preference = utilities.getPreferenceHideMask(
            this.props.getprefer
          );
          const savings =
            utilities.isEmptyOrNullString(product.spcCost) ||
            parseFloat(product.spcCost) === 0.0
              ? ""
              : utilities.emptyForZeroPercentage(product.savings);
          let row = {};
          row = { ...row, ...{ "Item Number": utilities.emptyForNull(product.itemNbr) } };
          if (utilities.isAliasSkuRequired(this.props.multiStoreDetails)) {
            row = {
              ...row,
              ...{
                "Alternate SKU#": utilities.emptyForNull(product.aliasSku),
              },
            };
          }
          row = {
            ...row,
            ...{
              "Short Description": utilities.emptyForNull(product.shortDesc),
              "Vendor Name": utilities.emptyForNull(product.vendorName),
              "Sequence #": utilities.emptyForNull(product.seqNbr),
              Model: utilities.emptyForNull(product.model),
              Pack: `${product.sPackType ? product.sPackType : ""}${
                product.sPackQty ? product.sPackQty : ""
              }`,
              UPC: utilities.emptyForNull(product.upcNum),
              "Item Description": config.showMediumDesp ?
              utilities.emptyForNull(
                product.productTitle || product.longDescription
              ) : utilities.emptyForNull(
                product.longDescription
              ),
              Pack: `${product.sPackType ? product.sPackType : ""}${
                product.sPackQty ? product.sPackQty : ""
              }`,
              "REG DS COST": utilities.emptyForNull(
                utilities.getCostBasedOnnatvalue(
                  this.props.memberViewType,
                  product.cost,
                  preference
                )
              ),
              "DS EVT COST": utilities.emptyForNull(
                utilities.getCostBasedOnnatvalue(
                  this.props.memberViewType,
                  product.spcCost,
                  preference
                )
              ),
              Savings: savings,
              "Non Stocked": product.stocked && product.stocked !== 'N/A' ? product.stocked : '',
            },
          };
          return row;
        });
        utilities.exportToCsv(itemsList, this.state.shoppingAreaName);
      });
  };

  render() {
    const {
      isLoading,
      isNoProducts,
      shoppingAreaId,
      shoppingAreaMetaId,
      shoppingAreaName,
      promoName,
      eventId,
      eventName,
      isFilterRequired,
      flowType,
      vendorId,
      vendorName,
      filters,
      fromReunionHome,
      relayNbr,
      relayYear,
      searchPattern,
      searchValue,
      orderDeadline,
      eventEnableAfter,
      eventEnableBefore,
      eventMessage,
      productsData,
    } = this.state;
    if (
      eventEnableAfter === false ||
      eventEnableBefore === false ||
      productsData
    ) {
      return (
        <>
          {eventEnableAfter === false || eventEnableBefore === false ? (
            <UnderConstruction
              eventMessage={eventMessage}
              shoppingAreaName={shoppingAreaName}
            />
          ) : (
            <div className="page-wrap">
              {!this.props?.fromScanning ? 
              <>
              <div className="page-header">
                {shoppingAreaName} {promoName && `- ${promoName}`}
              </div>
              <BreadcrumbComp
                path={getBreadcrumbLinks(
                  fromReunionHome,
                  flowType,
                  eventId,
                  vendorName,
                  vendorId,
                  shoppingAreaName,
                  shoppingAreaId,
                  shoppingAreaMetaId,
                  relayYear,
                  relayNbr,
                  promoName,
                  eventName,
                  "",
                  "",
                  "",
                  vendorId ? "DropShipVendorItems" : "AllVendorDropShipItems"
                )}
              />
                </>:<h2>{shoppingAreaName}</h2>}
                
              <br />
              {vendorId &&
                this.getVendorDetails(vendorName, orderDeadline, vendorId)}
                {!this.hideFilter &&(
                <OptionsSection
                  isSortByRequired={isSortRequired(flowType)}
                  sortByList={getSortByOptions(
                    flowType,
                    utilities.isAliasSkuRequired(this.props.multiStoreDetails),
                    null,
                    shoppingAreaId
                  )}
                  changeSortParam={this.changeSortParam}
                  isSearchRequired={isSearchRequired(flowType)}
                  searchByList={this.searchByOptions}
                  isShowByRequired={isShowByRequired(flowType)}
                  searchItems={this.searchItems}
                  pageSize={this.pageSize}
                  changePageSize={this.changePageSize}
                  changeSearchBy={this.changeSearchBy}
                  changeSearchValue={this.changeSearchValue}
                  isGridViewIconRequired={true}
                  handleViewChange={this.handleViewChange}
                  changeSortDirection={this.changeSortDirection}
                  searchPattern={searchPattern}
                  searchValue={searchValue}
                  searchParam={this.searchby}
                  sortParam={this.sortParam}
                  sortOrder={this.sortDirection}
                />)}
              <div className="displayFlex allOrdersTable">
                {isLoading && <Loader active />}
                {!this.hideFilter && isFilterRequired && (
                  <Filter
                    filters={filters}
                    isNoProducts={isNoProducts}
                    appliedFilters={this.state.appliedFilters}
                    defaultChecked={this.defaultChecked}
                    clearFilters={false}
                    filterCallback={this.filterCallback}
                  />
                )}
                <div id="grid_width" className="full-width pl_data">
                  {this.getListingHeader()}
                  {isNoProducts ? (
                    <div className="no_prdcts_display">
                      <h1>No Products to Display!</h1>
                    </div>
                  ) : (
                    <>
                      {this.getListing()}
                      <span className="paginationButtom">
                        {this.state.paginationParams.totalPages > 0 &&
                          getPagination(
                            0,
                            parseInt(this.state?.paginationParams?.currentPage),
                            parseInt(this.state?.paginationParams?.totalPages),
                            this.onPageChange,
                            null,
                            1
                          )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      );
    } else {
      return <>{isLoading && <Loader active />}</>;
    }
  }
}
const mapStateToProps = (state) => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    userId: state.SessionReducer.UserId,
    userName: state.SessionReducer.address.userName,
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    memberViewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    cartId: state.cart.orders?.currentCart?.cartId,
    carts: state.cart.orders.allCarts,
    rdc: state.SessionReducer.rdc,
    marketDate: state.cart.orders.eventDate,
    qtyUpdate: state.cart.orders?.updateQty
  };
};

export default connect(mapStateToProps, { viewAllCarts, updateQty })(
  VendorDropShipItemListing
);
