import React from 'react';
import Flyout from './Flyout';

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false
    };
  }

  componentDidUpdate() {
    const { refresh } = this.state;
    if (refresh) {
      this.setState({ refresh: false });
    }
  }
  
  refresh = () => {
    if (this.props.pushSidebar) {
      this.props.pushSidebar();
    }
    this.setState({
      refresh: true
    });
  };

  render() {
    const { refresh } = this.state;
    const { isMobile } = this.props;
    if (refresh) {
      return null;
    }
    return <Flyout onAction={this.refresh} isMobile={isMobile}></Flyout>;
  }
}

export default LeftMenu;
