import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import restUtils from '../../utils/restUtils';
import { PASSPORT_API_URL, MOL_COC_URL, PASSPORT_API_GUID } from '../../urlConstants';
import { utilities } from '../../utils/utilities';
import _ from 'lodash';

class CloseoutCornerScan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAuthenticationFail: false,
      isCocEnable: this.props.isCloseoutCornerEnable,
      IsStoreReunionAttendant: this.props.IsStoreReunionAttendant
    }
  }

  componentDidMount() {
      if(this.props?.IsStoreReunionAttendant && this.props?.isCloseoutCornerEnable) {
        this.getScannedData()
      }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.IsStoreReunionAttendant && nextProps.isCloseoutCornerEnable) {
      this.getScannedData()
    }
    if(this.props.isCloseoutCornerEnable !== nextProps.isCloseoutCornerEnable) {
      this.setState({isCocEnable: nextProps.isCloseoutCornerEnable})
    }
    if(this.props.IsStoreReunionAttendant !== nextProps.IsStoreReunionAttendant) {
      this.setState({IsStoreReunionAttendant: nextProps.IsStoreReunionAttendant})
    }
  }

  getScannedData = () => {
    console.log('getScannedData API called');
    this.setState({ loading: true });
    const url = `/appRedirect?appName=Auctions&itemnumber=${this.props?.itemNumber}`;
    utilities.openInsameTab(url);
  }
  render() {
    return (
      <div >
        {this.state.loading &&
          <div>
            <Loader active />
          </div>
        }
        { (this.state.isAuthenticationFail || !this.state.IsStoreReunionAttendant || ( this.state.isCocEnable != undefined && !this.state.isCocEnable)) &&
          <p style={{textAlign:'center'}} id = 'unableAuth'>Unable to Authenticate or Unauthorized.</p>
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { cart } = state;
  return {
    storeId: state.SessionReducer.storeId,
    storeGuid: state.SessionReducer?.access_token?.storeguid || '',
    userGuid: state.SessionReducer?.access_token?.userguid || '',
    IsStoreReunionAttendant: state.SessionReducer?.address?.IsStoreReunionAttendant || false,
    isCloseoutCornerEnable: cart?.orders?.isCloseoutCornerEnable
  };
}

export default connect(mapStateToProps, null)(CloseoutCornerScan);