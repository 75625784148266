import React, { Component } from "react";
import { connect } from 'react-redux';
import "./style.css";
import utilities from "../../utils/utilities";
import { withRouter } from 'react-router-dom';
import renderImages from "../common/RenderImages";
import { getDealNavigator, SHOPPING_AREA_LIST } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import Countdown from 'react-countdown';
import history from '../../history';
import { Loader } from 'semantic-ui-react';
import { CURRENT, UPCOMING } from '../common/constants';

class ReunionOrdering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoppingAreaDetails: undefined,
      loading: false
    }
  }

  componentDidMount() {
    this.getShoppingEvents();

  }
  getShoppingEvents = () => {
    this.setState({ loading: true });
    restUtils.getData(`${SHOPPING_AREA_LIST}?storeId=${this.props.storeId}`)
      .then(response => this.setState({ shoppingAreaDetails: response.data, loading: false }))
      .catch(error => {
        this.setState({ loading: false });
        console.log(error);
      });
  }

  getShoppingCardList = (shoppingAreaDetails) => {
    const { currentshoppingList, upcomingshoppingList } = shoppingAreaDetails;
    const filterShowTimer = upcomingshoppingList.filter(ele=>ele.showTimer)
    const newShoppingList = currentshoppingList?.concat(filterShowTimer)
      .sort((a, b) => a.shoppingName.localeCompare(b.shoppingName));
    return newShoppingList && newShoppingList.map(shopping => this.getShoppingCard(shopping));
  }

  clickShoppingArea = (shopping) => {
    const shoppingName = shopping.shoppingName.replace(/ /g, "");
    const shoppingId = shopping.shoppingId
    history.push({
      pathname: "/reunionOrdering/" + shoppingName + "/" + shoppingId + "/" + true,
      state:  {fromReunionHome:true, shoppingMetatDataId: shopping.shoppingMetatDataId}
    });
  }

  handleTime=(dFormat,labelText)=>{
    return(
    <div className='daysalign'>
      <span className='days'>{utilities.padWithZeros(dFormat, 2)}</span>
      <span>{labelText}</span> 
    </div>
  );
  }

  renderer=(days,hours, minutes, seconds)=>{
    return (
      <div className='timer-setting'>
          {this.handleTime(days,'Days')}
          {this.handleTime(hours,'Hours')}
          {this.handleTime(minutes,'Minutes')}
          {this.handleTime(seconds,'Seconds')}
      </div>
      );
  }

  countDown = (date) => {
    const evtDate = new Date(date);
    const renderer = ({ days,hours, minutes, seconds }) => {
     return  this.renderer(days,hours, minutes, seconds);
    };
    const currentDate = () => {
      return  new Date(utilities.getCurrentCstTime());
    };
    return (
      <Countdown 
        date={evtDate}
        now={currentDate} 
        renderer={renderer}
        />
    )
  }

  getShoppingAreaDetails = (shoppingAreaDetails) => {
    if(typeof(shoppingAreaDetails) === "undefined") {
      return <Loader active />
    } else {
      return (
        <>
          {typeof (shoppingAreaDetails) === "object" && shoppingAreaDetails
            ? <>
              {this.props.isCtbAssortmentsEnable && this.showCtbAssortments()}
              {this.props.isCloseoutCornerEnable && this.props.IsStoreReunionAttendant &&  this.showCloseoutCorner()}
              {this.getShoppingCardList(shoppingAreaDetails)}
              {this.showDealNavigatorLinks()}
              {/* {this.showOrderingLinks()} */}
            </>
            : <div className='noShoppingAreas'>No Shopping Areas are Available</div>
          }
        </>
      )
    }
  }

  showOrderingLinks = () => {
    return (
      <div className='shopping_area_content--item'>
        <div className='orderWows'>
          <span className='instructionLinksWow'>
            {/* <a href="https://aem.membersonline.com/content/MOL/true-value-company/reunion/fr20-wow-event.html.html"
            target="_blank"><u>How to Order WOW</u></a> */}
          </span>
        </div>
        <div className='generalOrdering'>
          <span className='instructionLinksNW'><a href="https://aem.membersonline.com/content/MOL/true-value-company/story/netwarehouse-2020.html"
            target="_blank"><u>How to Navigate New Netwarehouse Tutorials</u></a></span>
        </div>
      </div>
    )
  }
  
  showCtbAssortments = () => {
    const imgComponent = "./shoppingLogos/ctb_assortments.png"
    return (
      <div className="shopping_area_content--item ctbTile">
        <div className="tileDiv">
          {renderImages.getImage({ src: imgComponent, className: "deal-navigator-img" })}
        </div>
        <div className="item_footer deal-navigator-footer"
          onClick={() =>
            history.push({
              pathname: "/ctbAssortments",
            })}>
            Click Here for CTB Assortments
          </div>
      </div>
    );
  }

  showCloseoutCorner = () => {
    const imgComponent = "./shoppingLogos/closeout_corner.png";
    const navigateCloseoutCorner = () => {
      this.setState({ loading: true });
      const url = `/appRedirect?appName=Auctions`;
      utilities.openInsameTab(url);
    }
    return (
      <div className="shopping_area_content--item ctbTile">
        <div className="tileDiv">
          {renderImages.getImage({ src: imgComponent, className: "deal-navigator-img" })}
        </div>
        <div className="item_footer deal-navigator-footer"
            onClick={() => navigateCloseoutCorner()}>
            Click Here for Closeout Corner
          </div>
      </div>
    );
  }

  showDealNavigatorLinks = () => {
    const imgComponent = "./shoppingLogos/Reunion_Deal_Navigator.jpg"
    const dealNavigatorLinks = () => {
      this.setState({ loading: true });
     // const url = `/appRedirect?appName=Reunion Deal Navigator`;
      const url = `/appRedirect?appGuid=40F42F3C-2845-4CDB-92A6-8FEACC8DB335`
      utilities.openInsameTab(url);
    }
    return (
      <div className="shopping_area_content--item">
        <div>
          {renderImages.getImage({ src: imgComponent, className: "deal-navigator-img" })}
        </div>
        <div className="item_footer deal-navigator-footer"
          onClick={() => dealNavigatorLinks()}>
          Click Here for Deal Navigator
          </div>
      </div>
    );
  }

  getTimer = (startDate, endDate, status, showTimer) => {
    return (
      <div className="timer">
        {status === UPCOMING && showTimer &&
          <>
            Start Shopping in &nbsp;
            {this.countDown(startDate)}
          </>
        }
        {status === CURRENT && showTimer &&
          <>
            Event ends in &nbsp;
            {this.countDown(endDate)}
          </>
        }
      </div>
    );
  }

  getShoppingCard = (shopping) => {
    const { shoppingId, shoppingName, imgUrl, status, startDate, endDate, showTimer } = shopping;
    //const imgComponent = images(""./reunionLogos/"+imgUrl); to do dynamic image loading
    const imgComponent = "./shoppingLogos/"+imgUrl; //to do dynamic image loading
    const footerClassName = status === CURRENT;
    return (
      <div key={"area" + shoppingId} className="shopping_area_content--item">
        <div className="imagelayout"
          key={shoppingId}
        >
          {imgUrl && imgUrl.length > 0 ?
            renderImages.getImage({ src: imgComponent, className: "imagelayout" }) :
            <div className="shoppingNameText">
              {shoppingName}
            </div>
          }
        </div>
        {this.getTimer(startDate, endDate, status, showTimer)}
        <div className={footerClassName ? "item_footer" : "item_footer_upcoming"} 
            onClick={() => status === CURRENT && this.clickShoppingArea(shopping)}>
          Click Here to Start Shopping
        </div>
      </div>
    );
  }

  render() {
    const { shoppingAreaDetails } = this.state;
    return (
      <div className="shopping-landing-page-container">
        <div className="shopping-landing-page-content-container">
          <div className="landing_page_content--container">
            {this.state.loading && (
              <div>
                <Loader active />
              </div>
            )}
            {this.getShoppingAreaDetails(shoppingAreaDetails)}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const { cart } = state;
  return {
    isCtbAssortmentsEnable: cart?.orders?.isCtbAssortmentsEnable,
    isCloseoutCornerEnable: cart?.orders?.isCloseoutCornerEnable,
    storeId,
    userGuid: state.SessionReducer?.access_token?.userguid || '',
    IsStoreReunionAttendant: state.SessionReducer?.address?.IsStoreReunionAttendant || ''
  };
};

export default withRouter(connect(mapStateToProps)(ReunionOrdering));