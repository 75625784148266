import React from 'react'
import utilities from '../../utils/utilities';


export const ShowPromoCostExpDetails=({pricing})=>{
    const filteredPromoCost = pricing && pricing.Tiers && pricing.Tiers.length > 0 && pricing.Tiers.filter((t) => t.PromoCost > 0);
    return (
        filteredPromoCost && filteredPromoCost.length > 0 ?
            <table className="stackable" id="Quicktier_table">
                <thead className="QuickTierTableHead">
                <tr>
                    <>
                        {filteredPromoCost[0].PromoCost && <th rowSpan="1">Promotional Cost</th>}
                        {filteredPromoCost[0].PromoExpDate && <th rowSpan="1">Promotional Expiration</th>}
                    </>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <>
                        {filteredPromoCost[0].PromoCost && 
                            <td rowSpan="1" data-label="Promo Price">{utilities.changePriceToNumber(filteredPromoCost[0].PromoCost)}</td>
                        }
                        {filteredPromoCost[0].PromoExpDate &&
                            <td rowSpan="1" data-label="Exp Date">{filteredPromoCost[0].PromoExpDate}</td>
                        }
                    </>
                </tr>
                </tbody>
            </table>
        :''            
    );
}

export const TierTable=({pricing})=>{
    return (
      <table className="stackable" id="Quicktier_table">
        <thead className="QuickTierTableHead">
        <tr>
            <th rowSpan="1">Tier</th>
            <th rowSpan="1">Qty</th>
            <th rowSpan="1">Cost</th>
            {pricing.showTieredPromotionCosts &&
            <>
                <th rowSpan="1">Promotional Cost</th>
                <th rowSpan="1">Promotional Expiration</th>
            </>
            }
        </tr>
        </thead>
        <tbody>
        {pricing.ME_Cost!==0 &&
            <tr>
                <td rowSpan="1" data-label="Tier">Tier 1 - Each Unit Cost</td>
                <td rowSpan="1" data-label="Qty">{pricing.ME_qty}</td>                            
                {pricing.promotionalCost > 0 ?
                    <>
                        <td rowSpan="1" data-label="Promo Price" className='cost_red_color'>{utilities.changePriceToNumber(pricing.ME_Original_Cost)}</td>
                        <td rowSpan="1" data-label="Cost" >{utilities.changePriceToNumber(pricing.ME_Cost)}</td>
                        <td rowSpan="1" data-label="Exp Date">{pricing.expirationDate}</td>
                    </>
                    :
                    <>
                        <td rowSpan="1" data-label="Cost" className='cost_red_color'>{utilities.changePriceToNumber(pricing.ME_Cost)}</td>
                    </>
                }
            </tr>
        }
        {pricing.Cost !==0 &&
            <tr>
                <td data-label="Tier">Tier 2 - Full Carton Cost</td>
                <td data-label="Qty">{pricing.MM_qty}</td>                           
                {pricing.promotionalCost > 0 ?
                    <>
                        <td rowSpan="1" data-label="Promo Price" className='cost_red_color'>{utilities.changePriceToNumber(pricing.original_Cost)}</td>
                        <td data-label="Cost" >{utilities.changePriceToNumber(pricing.Cost)}</td>
                        <td rowSpan="1" data-label="Exp Date">{pricing.expirationDate}</td>
                    </>
                        :
                        <>
                            <td data-label="Cost" className='cost_red_color'>{utilities.changePriceToNumber(pricing.Cost)}</td>
                        </>
                }
            </tr>
        }
        {pricing.MC_Cost!==0 &&
            <tr>
                <td data-label="Tier">Tier 3 - Volume Discount</td>
                <td data-label="Qty">{pricing.MC_qty}</td>                           
                {pricing.promotionalCost > 0 ?
                    <>
                        <td rowSpan="1" data-label="Promo Price" className='cost_red_color'>{utilities.changePriceToNumber(pricing.MC_Original_Cost)}</td>
                        <td data-label="Cost">{utilities.changePriceToNumber(pricing.MC_Cost)}</td>
                        <td rowSpan="1" data-label="Exp Date">{pricing.expirationDate}</td>
                    </>
                    :
                    <>
                            <td data-label="Cost" className='cost_red_color'>{utilities.changePriceToNumber(pricing.MC_Cost)}</td>
                    </>
                }
            </tr>
        }
        {pricing.MP_Cost !== 0 &&
            <tr>
            <td data-label="Tier">Tier 4 - Volume Discount</td>
            <td data-label="Qty">{pricing.MP_qty}</td>
            {pricing.promotionalCost > 0 ?
                    <>
                        <td rowSpan="1" data-label="Promo Price" className='cost_red_color'>{utilities.changePriceToNumber(pricing.MP_Original_Cost)}</td>
                        <td data-label="Cost" >{utilities.changePriceToNumber(pricing.MP_Cost)}</td>
                        <td rowSpan="1" data-label="Exp Date">{pricing.expirationDate}</td>
                    </>
                        :
                        <>
                        <td data-label="Cost" className='cost_red_color'>{utilities.changePriceToNumber(pricing.MP_Cost)}</td>
                        </>
                }
            </tr>
        }
        </tbody>
    </table>
    );
  }

const tierIndicatorName = {
    1: 'Tier 1 - Each Unit Cost',
    2: 'Tier 2 - Full Carton Cost',
    3: 'Tier 3 - Volume Discount',
    4: 'Tier 4 - Volume Discount'
};

export const TierTableNew=({pricing,monthly_special_t2 = 0 ,monthly_special_t1 = 0})=>{
    const tierTablePricing = () => {
        const tierPricing = pricing.Tiers && pricing.Tiers.length > 0 && pricing.Tiers.map((t) => {
        if(t.Cost > 0 && t.Tier > 0 && t.Quantity > 0) {
                return <tr>
                    <td rowSpan="1" data-label="Tier">{tierIndicatorName[t.Tier]}</td>
                    <td rowSpan="1" data-label="Qty">{t.Quantity}</td>                        
                    {t.PromoCost > 0 ?
                        <>
                            <td rowSpan="1" data-label="Promo Price" className='cost_red_color'>{utilities.changePriceToNumber(t.Cost)}</td>
                            <td rowSpan="1" data-label="Cost" >{utilities.changePriceToNumber(t.PromoCost)}</td>
                            <td rowSpan="1" data-label="Exp Date">{t.PromoExpDate}</td>
                        </>
                        : t.Tier === 1  && monthly_special_t1 > 0 ? 
                        <td rowSpan="1" data-label="Cost" className='cost_red_color'>{utilities.changePriceToNumber(monthly_special_t1)}</td>
                        : t.Tier === 2  && monthly_special_t2 > 0 ? 
                        <td rowSpan="1" data-label="Cost" className='cost_red_color'>{utilities.changePriceToNumber(monthly_special_t2)}</td>
                        :<td rowSpan="1" data-label="Cost" className='cost_red_color'>
                               {utilities.changePriceToNumber(t.Cost)}
                              </td>
                    }
                </tr> }
            
            
        })
        return tierPricing;
        
    }
    let showTieredPromotionCosts = false;
    pricing.Tiers && pricing.Tiers.length > 0 && pricing.Tiers.map((t) => {
        if(t.PromoCost > 0){
            showTieredPromotionCosts = true;
        }
    })

    return (
        <table className="stackable" id="Quicktier_table">
        <thead className="QuickTierTableHead">
        <tr>
            <th rowSpan="1">Tier</th>
            <th rowSpan="1">Qty</th>
            <th rowSpan="1">Cost</th>
            {showTieredPromotionCosts &&
            <>
                <th rowSpan="1">Promotional Cost</th>
                <th rowSpan="1">Promotional Expiration</th>
            </>
            }
        </tr>
        </thead>
        <tbody>
            {tierTablePricing()}
        </tbody>
    </table>
    );
}