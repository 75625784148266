import React from 'react'
import renderImages from '../common/RenderImages'
import {connect} from 'react-redux'
import utilities from '../../utils/utilities'
import { getCartUrl, getImageURl, PREVIEW_CIRCULAR, ZONE_INFO } from '../../urlConstants'
import { Loader } from 'semantic-ui-react'
import { RegionDetails } from '.'
import { isArray } from 'util';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import restUtils from '../../utils/restUtils'

class PreviewCircular extends React.Component{

    constructor(props){
        super(props);
        const {zoneCode} = props;
        const region=zoneCode?
                    isArray(zoneCode)&& zoneCode.length>0
                        ?zoneCode[0]
                        :zoneCode
                    :"";
        this.state = {
            isLoading:true,
            promoNbr : utilities.padWithZeros(props.match.params.promoNbr,3),
            sectionPages:[],
            sectionPage : "",
            region,
            isLoading:true
        }
    }
    componentDidMount(){
        this.loadSectionPages();
    }
    loadSectionPages = (newZoneCode)=>{
        const zoneCode = newZoneCode?newZoneCode:this.state.region;
        this.setState({isLoading:true})
        restUtils
            .getData(ZONE_INFO+this.props.storeId+"&promonbr="+this.state.promoNbr+"&zonecode="+zoneCode)
            .then(response =>{
                const sectionPages = response.data.sectionpages;
                this.setState({
                                isLoading:false,
                                region:zoneCode,
                                sectionPages,
                                sectionPage:sectionPages && sectionPages.length>0?sectionPages[0]:""
                            })
            }).catch(err=>{
                this.setState({isLoading:false})
            })
    }
    imgOnLoad=()=>{
        this.setState({isLoading:false})
    }
    domSectionChange = (e)=>{
        this.setState({sectionPage:e.target.value})
    }
    changeRegion = (e) =>{
        if(utilities.isAllRegionStore(this.props.storeId)){
            this.loadSectionPages(e.target.value);
        }
    }
    downloadCircular = () =>{
        const input = document.getElementById('circular');
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF("1");
            pdf.addImage(imgData, 'JPEG', 15, 5);
            pdf.save("Circular-"+this.state.promoNbr+".pdf");
          })
        ;
      }
      
    render(){
        // const circularUrl = PREVIEW_CIRCULAR
        //                     +this.state.promoNbr+"/"
        //                     +this.state.region+this.state.sectionPage+".gif";
        const circularUrl =`${getImageURl()}/promotions/${this.state.promoNbr}/${this.state.region}${this.state.sectionPage}.gif`
        const { sectionPages, isLoading }  = this.state;
        return(
            <div className='page-wrap'>
                <div className='page-header'>Retail Events</div>
                {
                    isLoading?
                        <Loader active/>
                        :
                        <>
                            <div className='region-label-dom'>
                                <span> Region : {this.props.region} <RegionDetails 
                                                                            region={this.state.region}
                                                                            storeId={this.props.storeId}
                                                                            zoneDesc={this.props.zoneDesc} 
                                                                            zoneCode={this.props.zoneCode}
                                                                            changeRegion={this.changeRegion}/> </span> &emsp;&emsp;
                                
                                    <b className='options-label'>Choose Page </b>
                                    <select
                                        id='dom-section-combo'
                                        onChange={this.domSectionChange}
                                        className='input-select'>
                                        {
                                            sectionPages && sectionPages.map(sectionPage=>{
                                            return <option value={sectionPage}>{sectionPage}</option>
                                            })
                                        }
                                    </select>
                                    <span className='pdf_btn_styles'>
                                        <i className='file pdf icon large'
                                            id='export_btns'
                                            onClick={this.downloadCircular}
                                        />
                                    </span>
                                </div>                
                                <br/>
                                {this.state.isLoading &&
                                <Loader active/>
                                }
                                <div id="circular">
                                 {renderImages.getImage({src:circularUrl,
                                                            onLoad:this.imgOnLoad,
                                                            className:'preview-img'
                                                            })

                                    } 
                                </div>
                        </>
                }
                
            </div>
        )
    }
}
const mapStateToProps = (state) =>{
    return {
        zoneCode:state.DomReducer.zoneCode,
        zoneDesc:state.DomReducer.zoneDesc,
        storeId:state.SessionReducer.storeId
    }
}
export default connect(mapStateToProps)(PreviewCircular);