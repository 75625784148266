export const colDefs = [
  {
    header: 'Order Created Date',
    key: 'dateSubmitted'
  },
  {
    header: 'PO#',
    key: 'memberRefNum'
  },
  {
    header: 'Order Type',
    key: 'invoiceType'
  },
  {
    header: 'Item Count',
    key: 'numofItems'
  },
  {
    header: 'RDC',
    key: 'rdcNum'
  },
  {
    header: 'Ship To',
    key: 'consumerName'
  },
  {
    header: 'Shipping Method',
    key: 'shippingMethod'
  },
  {
    header: 'Total Cost',
    key: 'subcost'
  },
  {
    header: 'Status',
    key: 'status'
  }
];

