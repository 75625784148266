import React, { Component } from 'react';

class AuthLoader extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className='authLoader'>
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
        
    }
}

export default AuthLoader