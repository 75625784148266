import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import './AddressBook.css';
import { ADDRESS_BOOK_API } from '../../urlConstants';
import { USAStates } from './AddressBookModels';
import restUtils from '../../utils/restUtils';
import { Loader } from 'semantic-ui-react';
import {utilities} from '../../utils/utilities';

class AddressViewEdit extends Component {
  constructor(props) {
    super(props);
    const { location = {} } = this.props;
    const { state: locationState = {} } = location;
    const {
      contactName = '',
      customerName = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      zip5 = '',
      zip4 = '',
      phoneArea = '',
      phoneExchange = '',
      phoneLine = '',
      faxArea = '',
      faxExchange = '',
      faxLine = '',
      email = '',
      locationId,
      addressAction
    } = locationState;

    this.state = {
      contactName,
      customerName,
      addressLine1,
      addressLine2,
      city,
      state,
      zipcode: zip5 + zip4,
      phoneArea,
      phoneExchange,
      phoneLine,
      faxArea,
      faxExchange,
      faxLine,
      email,
      emailErrorMessage: '',
      saveFailedMessage: '',
      isLoading: false,
      locationId,
      addressAction,
      disableSaveBtn: false
    };
  }

  editField = e => {
    const { value, validity = {}, name } = e.target;
    if (!validity.valid) {
      e.preventDefault();
      return;
    }
    this.setState({
      [name]: value,
      emailErrorMessage: '',
      saveFailedMessage: ''
    });
  };

  handleZipChange = e => {
    const { value, validity, id } = e.target;
    if (!validity.valid) {
      e.preventDefault();
      return;
    }
    if (id === 'zip1') {
      let zip2 = document.getElementById('zip2').value;
      this.setState({ zipcode: value + zip2 });
      this.tabAt(e, 5, 'zip2');
    } else {
      let zip1 = document.getElementById('zip1').value;
      this.setState({ zipcode: zip1 + value });
      this.tabAt(e, 4, 'phone1');
    }
  };
  
  handleBlur=e=>{
    if(e.target.value.length > 0){
      this.setState({ zipcode: utilities.padWithZeros( e.target.value, 5) });
    }
  }

  routeChange = e => {
    const { name } = e.target;
    if (name === 'cancel') {
      this.props.history.push({
        pathname: '/AddressBook',
        state: {
          ...(this.props.location.state || {}),
          editAction: 'cancelled',
          addressAction: null,
          searchValueEdit: this.props.location && this.props.location.state && 
          this.props.location.state.searchVal && 
          this.props.location.state.searchVal.includes('=') ? 
          this.props.location.state.searchVal.split('=')[1]:''
        }
      });
      return;
    }
    const { storeId, userName } = this.props;
    const {
      customerName,
      contactName,
      state,
      addressLine1,
      addressLine2,
      city,
      zipcode,
      phoneArea,
      phoneExchange,
      phoneLine,
      faxArea,
      faxExchange,
      faxLine,
      email,
      locationId
    } = this.state;

    const reg = /\S+@\S+\.\S+/;

    if(contactName && contactName.toLowerCase() === "store") {
      this.setState({
        saveFailedMessage: 'Store is not a valid Consumer name'
      });
      return;
    }

    if (email && !reg.test(email)) {
      this.setState({
        emailErrorMessage: 'Invalid email id'
      });
      return;
    }

    if (!customerName || !contactName || !addressLine1 || !city || !state || !zipcode ) {
      this.setState({
        saveFailedMessage: 'Please fill all required fields'
      });
      return;
    }
   
    if(phoneArea && phoneArea.length !== 3){
      this.setState({saveFailedMessage: 'Only 3 digits allowed for Phone area'});
      return;
    }
    if(phoneExchange && phoneExchange.length !== 3){
      this.setState({saveFailedMessage: 'Only 3 digits allowed for Phone exchange'});
      return;
    }
    if(phoneLine && phoneLine.length !== 4){
      this.setState({saveFailedMessage: 'Only 4 digits allowed for Phone line'});
      return;
    }
    if(faxArea && faxArea.length !== 3){
      this.setState({saveFailedMessage: 'Only 3 digits allowed for Fax area'});      
      return;
    }
    if(faxExchange && faxExchange.length !== 3){
      this.setState({saveFailedMessage: 'Only 3 digits allowed for Fax exchange'});      
      return;
    }
    if(faxLine && faxLine.length !== 4){
      this.setState({saveFailedMessage: 'Only 4 digits allowed for Fax line'});      
      return;
    }

    this.setState({
      isLoading: true
    });

    const requestObj = {
      storeId: storeId,
      molUserName: userName,
      pageNumber: 1,
      newAddress: {
        customerName,
        contactName,
        addressLine1,
        addressLine2,
        city,
        state,
        zip5: zipcode.substring(0, 5),
        zip4: zipcode.substring(5, 10),
        country: 'US',
        postalCode: '',
        phone: `${phoneArea}${phoneExchange}${phoneLine}`,
        phoneArea,
        phoneExchange,
        phoneLine,
        fax: `${faxArea}${faxExchange}${faxLine}`,
        faxArea,
        faxExchange,
        faxLine,
        email,
        locationId
      }
    };

    const addressAction =
      _.get(this.props, 'location.state.addressAction', 'PostAddNewAddress') ||
      'PostAddNewAddress';

    const requestUrl = `${ADDRESS_BOOK_API}/${addressAction}`;
    this.setState({saveFailedMessage: '', disableSaveBtn: true})
    restUtils.postData(requestUrl, JSON.stringify(requestObj)).then(res => {
      if (res.data.success) {
        this.props.history.push({
          pathname: '/AddressBook',
          state: {
            ...(this.props.location.state || {}),
            editAction: '',
            successRecordEdit: res.data.success,
            disableSaveBtn: false
          }
        });
      } else {
        this.setState({
          saveFailedMessage: res.data.message,
          isLoading: false,
          disableSaveBtn: false
        });
      }
    });
  };

  tabAt = (e, limit, fieldId, currentField) => {
    if (!e.target.validity.valid) {
      e.preventDefault();
      return;
    }

    if (currentField) {
      this.setState({
        [currentField]: e.target.value
      });
    }

    if (e.target.value.length >= limit) {
      document.getElementById(fieldId).focus();
      return;
    }
  };

  render() {
    return (
      <div className='add-address page-wrap'>
        {this.state.isLoading ? <Loader active /> : null}
        <div className='segment_address'>
          <div className='page-header displayInline'>Address Book</div>
        </div>
        <div className='ui stackable four column grid AddEditSegment'>
          <div className='six wide column '>
            <p className='AddEditTitile'>Add / Edit Address</p>
            <span className='error'>{this.state.saveFailedMessage}</span>
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>Name * </p>
          </div>
          <div className='three wide column AddViewTab'>
            <input
              className='viewNamesInput'
              name='contactName'
              value={this.state.contactName}
              onChange={this.editField}
              type='text'
              maxLength={30}
              disabled={this.state.addressAction==="PostUpdateAddress"}
            />
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewNames'> Attention To * </p>
          </div>
          <div className='three wide column AddViewTab'>
            <input
              type='text'
              className='viewNamesInput'
              name='customerName'
              value={this.state.customerName}
              onChange={this.editField}
              maxLength={30}
            />
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>Address Line 1 * </p>
          </div>
          <div className='three wide column AddViewTab'>
            <input
              type='text'
              value={this.state.addressLine1}
              name='addressLine1'
              onChange={this.editField}
              className='viewNamesInput'
              maxLength={35}
            />
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>Address Line 2</p>
          </div>
          <div className='three wide column AddViewTab'>
            <input
              type='text'
              onChange={this.editField}
              name='addressLine2'
              value={this.state.addressLine2}
              className='viewNamesInput'
              maxLength={35}
            />
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>City * </p>
          </div>
          <div className='three wide column AddViewTab'>
            <input
              type='text'
              value={this.state.city}
              name='city'
              onChange={this.editField}
              className='viewNamesInput'
              maxLength={28}
            />
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>State * </p>
          </div>
          <div className='three wide column AddViewTab'>
            <select
              value={this.state.state}
              className='viewNamesInput'
              name='state'
              onChange={this.editField}
            >
              <Options options={USAStates}></Options>
            </select>
          </div>
        </div>
        <div
          id='zip-field'
          className='ui stackable two column grid AddViewSegment'
        >
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>Zip * </p>
          </div>
          <div className='one wide column AddViewTab'>
            <input
              type='text'
              value={this.state.zipcode.substring(0, 5)}
              pattern='[0-9]+'
              id='zip1'
              name='zipcode'
              onChange={this.handleZipChange}
              onBlur={this.handleBlur}
              className='ZipField'
              maxLength={5}
            />
          </div>
          <p>-</p>
          <div className='one wide column AddViewTabZip2'>
            <input
              type='text'
              value={this.state.zipcode.substring(5, 10)}
              pattern='[0-9]+'
              id='zip2'
              name='zipcode'
              onChange={this.handleZipChange}
              className='ZipField'
              maxLength={4}
            />
          </div>
        </div>
        <div
          className='ui stackable two column grid AddViewSegment'
          id='phone-field'
        >
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>Phone</p>
          </div>
          <div className='column AddViewTabphone1' id='phone'>
            <input
              type='text'
              id='phone1'
              pattern='[0-9]+'
              maxLength={3}
              value={this.state.phoneArea}
              onChange={e => this.tabAt(e, 3, 'phone2', 'phoneArea')}
              className='phoneField'
            />
          </div>
          <p className='separator'>-</p>
          <div className='column AddViewTabphone2'>
            <input
              type='text'
              id='phone2'
              pattern='[0-9]+'
              maxLength={3}
              value={this.state.phoneExchange}
              onChange={e => this.tabAt(e, 3, 'phone3', 'phoneExchange')}
              className='phoneField'
            />
          </div>
          <p className='separator'>-</p>
          <div className=' column AddViewTabphone2'>
            <input
              type='text'
              id='phone3'
              pattern='[0-9]+'
              maxLength={4}
              value={this.state.phoneLine}
              onChange={e => this.tabAt(e, 4, 'fax1', 'phoneLine')}
              className='phoneField'
            />
          </div>
        </div>
        <div
          className='ui stackable two column grid AddViewSegment'
          id='fax-field'
        >
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>Fax</p>
          </div>
          <div className='column AddViewTabFax1' id='fax'>
            <input
              type='text'
              id='fax1'
              pattern='[0-9]+'
              maxLength={3}
              value={this.state.faxArea}
              onChange={e => this.tabAt(e, 3, 'fax2', 'faxArea')}
              className='faxField'
            />
          </div>
          <p className='separator'>-</p>
          <div className='column AddViewTabFax2'>
            <input
              type='text'
              id='fax2'
              pattern='[0-9]+'
              maxLength={3}
              value={this.state.faxExchange}
              onChange={e => this.tabAt(e, 3, 'fax3', 'faxExchange')}
              className='faxField'
            />
          </div>
          <p className='separator'>-</p>
          <div className=' column AddViewTabFax2'>
            <input
              type='text'
              id='fax3'
              pattern='[0-9]+'
              maxLength={4}
              value={this.state.faxLine}
              onChange={e => this.tabAt(e, 4, 'email', 'faxLine')}
              className='faxField'
            />
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewNames'>Email Address</p>
          </div>
          <div className='three wide column AddViewTab'>
            <input
              type='text'
              id='email'
              name='email'
              className='viewNamesInput'
              value={this.state.email}
              onChange={this.editField}
              maxLength={100}
            />
            <span className='error'>{this.state.emailErrorMessage}</span>
          </div>
        </div>
        <div className='ui stackable two column grid AddViewSegment'>
          <div className='two wide column AddViewTab'>
            <p className='viewRequiredFields'>* Required Field </p>
          </div>
          <div className='column AddViewTab'>
            <input
              type='button'
              onClick={this.routeChange}
              name='cancel'
              id='cancel'
              className='custom-file-input-cancel'
            />
            <label htmlFor='cancel' className='custom-input-btn-cancel'>
              Cancel
            </label>
            <button
              className='custom-input-btn-save red button' 
              onClick={this.routeChange} 
              disabled={this.state.disableSaveBtn}
              name='save'
              id={this.state.disableSaveBtn && 'greyoutBtn'}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export const Options = props => {
  const { options } = props;
  return options.map(option => {
    const { name, value } = option;
    return (
      <option key={value} value={value}>
        {name}
      </option>
    );
  });
};

const mapStateToProps = state => ({
  storeId: state.SessionReducer.storeId,
  userName: state.SessionReducer.userName
});

export default connect(mapStateToProps, null)(AddressViewEdit);
