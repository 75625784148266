import {
  GET_ALL_FAVORITES,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  START_FETCH_FAVORITES,
  GET_ALL_FAVORITES_ITEMS
} from './actions';
import {
  GET_FAVORITE_VENDORS,
  ADD_VENDOR_TO_FAVORITES,
  REMOVE_VENDOR_FROM_FAVORITES,
  FETCHING_FAV_VENDORS
} from './vendors/actions';
const initState = { favItemNums: [], favorites: [], favVendors: [],pagination:[] ,filtersDepartment:[]};
const FavoritesReducer = (state = initState,{type,payload}) => {
  switch (type) {
    case GET_ALL_FAVORITES:
      return {
        ...state,
        // favItemNums: payload.favoriteList.map(({ itemNbr }) => {
        //   return itemNbr;
        // }),
        isFetchComplete:true,
        favorites: payload.favoriteList,
        pagination:payload.pagination,
        filtersDepartment:payload.filters
      };
      case GET_ALL_FAVORITES_ITEMS:
      return {
        ...state,
        favItemNums: payload,
        isFetchComplete:true,
      };
      case START_FETCH_FAVORITES:
          return {...state, isFetchComplete:false}
    case ADD_TO_FAVORITES:
      return { ...state, favItemNums: [...state.favItemNums, parseInt(payload)] };
    case REMOVE_FROM_FAVORITES:
      let favorites = state.favorites.filter(
        ({ itemNbr }) =>  !payload.includes(itemNbr)
      );
      let favItemNums = state.favItemNums.filter(
        itemNum => {
          return itemNum !==  parseInt(payload)
          }
      );
      return { ...state, favorites, favItemNums };
    case GET_FAVORITE_VENDORS : 
      return {
        ...state,
        favVendors:payload,
        fetchingFavVendors:false
      }
    case ADD_VENDOR_TO_FAVORITES:
      return {
        ...state,
        favVendors: [...state.favVendors, payload]
      };
    case REMOVE_VENDOR_FROM_FAVORITES:
      const favVendorRemoval = state.favVendors.filter(vendorIndex =>{
      return vendorIndex.VendorId !==  parseInt(payload)
      });
      return {
      ...state,
      favVendors: favVendorRemoval,
      fetchingFavVendors:false
      };
    case FETCHING_FAV_VENDORS:
      return {...state, fetchingFavVendors:true}
    default:
      return state;
  }
};

export default FavoritesReducer;
