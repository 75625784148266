import React from "react";
import { Form } from "semantic-ui-react";

import { utilities } from "../../../utils/utilities";

const initialState = {
  selectedDepartment: "",
  selectedClass: "",
  selectedSubClass: "",
  adTitle: "",
  adUrl: "",
  imageUrl: "",
  imagePath: "",
  editting: false,
};
class AddAdForm extends React.Component {
  state = {
    ...initialState,
  };

  componentDidMount() {
    const { editAd, getClasses, getSubClasses } = this.props;
    if (editAd) {
      getClasses(editAd.department.id);
      getSubClasses(editAd.department.id, editAd.departmentClass.id);

      this.setState({
        selectedDepartment: editAd.department.id,
        selectedClass: editAd.departmentClass.id,
        selectedSubClass: editAd.departmentSubClass.id,
        adTitle: editAd.title,
        adUrl: editAd.url,
        editting: true,
      });
    }
  }

  onSelectChange = (e) => {
    const { getClasses, getSubClasses } = this.props;
    const { selectedDepartment } = this.state;
    const { value, name } = e.target;

    switch (name) {
      case "department":
        this.setState({
          selectedDepartment: value,
          selectedClass: "",
          selectedSubClass: "",
        });
        getClasses(value);
        break;
      case "class":
        this.setState({ selectedClass: value, selectedSubClass: "" });
        getSubClasses(selectedDepartment, value);
        break;
      case "subClass":
        this.setState({ selectedSubClass: value });
        break;
      default:
        break;
    }
  };

  onTitleChange = (e) => {
    const { value } = e.target;
    this.setState({ adTitle: value });
  };

  onUrlChange = (e) => {
    const { value } = e.target;
    this.setState({ adUrl: value });
  };

  onSubmitForm = () => {
    const {
      adTitle,
      adUrl,
      selectedDepartment,
      selectedClass,
      selectedSubClass,
      imageUrl,
      editting,
    } = this.state;
    const {
      departments,
      classes,
      subClasses,
      addAd,
      updateAd,
      clearEdit,
      editAd
    } = this.props;

    const payload = {
      id: editAd ? editAd.id : `${new Date().getTime()}-${Math.random().toString(16)}`,
      title: adTitle,
      url: adUrl,
      department: departments.find((d) => d.id === selectedDepartment),
      departmentClass: classes.find((c) => c.id === selectedClass),
      departmentSubClass: subClasses.find((sc) => sc.id === selectedSubClass),
    };

    if (imageUrl) {
      payload.imageUrl = imageUrl;
    }

    const toastMessage = editting
      ? "Ad edited successfully"
      : "Ad added successfully";

    if (editting) {
      updateAd(payload);
    } else {
      addAd(payload);
    }

    utilities.showToast(toastMessage);
    this.setState(initialState);

    if (editting) {
      clearEdit();
    }

  };

  onChangeImage = (e) => {
    const { value } = e.target;
    const file = e.target.files[0];
    const objectUrl = URL.createObjectURL(file);

    this.setState({ imageUrl: objectUrl, imagePath: value });
  };

  render() {
    const { departments, classes, subClasses } = this.props;
    const {
      selectedDepartment,
      selectedClass,
      selectedSubClass,
      adTitle,
      adUrl,
      imagePath,
      editting,
    } = this.state;

    return (
      <Form onSubmit={this.onSubmitForm}>
        <Form.Field>
          <label>Title</label>
          <input
            placeholder="Ad Title"
            required
            value={adTitle}
            onChange={this.onTitleChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Url</label>
          <input
            placeholder="Ad Url"
            value={adUrl}
            onChange={this.onUrlChange}
            pattern="https?://.+"
            title="Include http://"
            // required
          />
        </Form.Field>
        <Form.Field>
          <label>Department</label>
          <select
            onChange={this.onSelectChange}
            value={selectedDepartment}
            name="department"
            required
          >
            <option value="">Select Department</option>
            {departments.map((d) => (
              <option key={`${d.id}-${d.name}`} value={d.id}>
                {d.value}
              </option>
            ))}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Class</label>
          <select
            onChange={this.onSelectChange}
            value={selectedClass}
            name="class"
            required
          >
            <option value="">Select Class</option>
            {classes.map((c) => (
              <option key={`${c.id}-${c.name}`} value={c.id}>
                {c.value}
              </option>
            ))}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Sub-Class</label>
          <select
            onChange={this.onSelectChange}
            value={selectedSubClass}
            name="subClass"
          >
            <option value="">Select Sub-Class</option>
            {subClasses.map((sc) => (
              <option key={`${sc.id}-${sc.name}`} value={sc.id}>
                {sc.value}
              </option>
            ))}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Ad Image</label>
          <input
            value={imagePath}
            type="file"
            accept="image/*"
            onChange={this.onChangeImage}
            required={!editting}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <button type="submit" className="ui red button">
            {editting ? "Edit" : "Submit"}
          </button>
        </Form.Field>
      </Form>
    );
  }
}

AddAdForm.defaultProps = {
  catalog: [],
};

export default AddAdForm;
