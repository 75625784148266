export const CORPORATE_EBOOK = 'E-Books';
export const E_BOOK_TYPES = ['Shopping Area', 'Vendor Name'];
export const SHOPPING_AREA = E_BOOK_TYPES[0];
export const VENDOR_NAME = E_BOOK_TYPES[1];
export const SHOW_PROMOS = ['6', '4', '9']; // Warehouse events, Reunion Retail assortments, Vendor Drop Ship Events
export const NO_SHOPPING_AREAS = 'No Shopping Areas to display';
export const NO_VENDOR_NAMES = 'No Vendor Names to display';
export const NO_PROMOS_EVENTS = 'No Promo Events to display';
export const NO_ACCESS_MSG = 'Sorry, the page you are looking for is not accessible';
export const SELECT_EBOOK_TYPE_MSG = 'Please Select E-Book Type';
export const SELECT_SHOPPINGAREA_MSG = 'Please Select Shopping Area';
export const SELECT_PROMO_EVENTS_MSG = 'Please Select Promo Events';
export const NO_PDF_AVAILABLE = 'PDF is not available';
export const SELECT_VENDOR_MSG = 'Please Select Vendor Name';