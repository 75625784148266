import restUtils from "../../utils/restUtils"
import { ZONE_INFO } from "../../urlConstants";
import utilities from "../../utils/utilities";

export const GET_REGION_DATA = 'GET_REGION_DATA';

export const getRegionData = ()=>{
    return (dispatch, getState) => {
        const isAllRegionStore = utilities.isAllRegionStore(getState().SessionReducer.storeId);
        const storeId = isAllRegionStore?"":getState().SessionReducer.storeId;;
        
        restUtils
            .getData(ZONE_INFO+storeId)
            .then(response =>{
                let zoneCode = [], zoneDesc=[];
                if(isAllRegionStore && response.data.zone_details.length>0){
                    const {zone_details} = response.data;
                    zone_details.forEach(zone => {
                        zoneCode.push(zone.zone_code);
                        zoneDesc.push(zone.zone_desc);
                    }); 
                }else{
                    zoneCode = response.data.zn_cd;
                    zoneDesc = response.data.zn_desc;
                }
                return dispatch({
                    type:GET_REGION_DATA,
                    payload:{
                        zoneCode,
                        zoneDesc
                    }
                })
            })
    }
}