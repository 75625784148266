import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const downloadExcel = (data, fileName) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName);
};

export const getRowData = (data, colDefs) => {
  const rowData = {};
  colDefs.forEach(colDef => {
    const { header, key } = colDef;
    if (key instanceof Array) {
      let value = '';
      key.forEach((dataKey, index) => {
        const dataValue = data[dataKey];
        value += `${dataValue || ''}${
          dataValue && data[key[index + 1]] ? ', ' : ''
        }`;
      });
      rowData[header] = value;
    } else if (typeof key === 'string') {
      const newLineKeys = key.split('/');
      if (newLineKeys.length > 1) {
        rowData[header] = newLineKeys.map(key => data[key]).join('/\n');
      } else {
        const concateKeys = key.split('+');
        const combineDataKeys = key.split(',');
        if (concateKeys.length > 1) {
          rowData[header] = concateKeys.map(key => data[key]).join(' ');
        }else if (combineDataKeys && combineDataKeys.length > 1){
          rowData[header] = combineDataKeys.map(key => data[key]).join(' ');
        } else {
          rowData[header] = data[key];
        }
      }
    }
  });

  return rowData;
};

export const readExcelData = (result, props) => {
  const wb = XLSX.read(result, {type:'binary'});
  /* Get first worksheet */
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  
  const data = XLSX.utils.sheet_to_json(ws, props);  
  return data;
}
function s2ab(s) {
  let buf = new ArrayBuffer(s.length); 
  let view = new Uint8Array(buf);
  for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

export const downloadBinary = (data, fileName)=>{
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blobData = new Blob([s2ab(atob(data))], { type:fileType });
  FileSaver.saveAs(blobData, fileName);
} 
