import utilities from "../../utils/utilities";
import { domListingUrl } from "../../urlConstants";
import restUtils from "../../utils/restUtils";

  export const domSectionChange = function(e){
    this.sectionPage = e.target.value;
    this.fetchProductsForCategory();
  };
  export const changeDomSearchBy = function(e){
    this.domSearchBy = e.target.value;
  };
  export const changeDomSearchValue = function(e){
    this.domSearchValue = e.target.value;
  };

  export const handleEnterKey = function(e){
    if (e.keyCode === 13) {
      this.searchDom()
    }
  };

  export const searchDom = function(){
    this.fetchProductsForCategory();
  }
  export const fetchItemsForDOM = function(newProps){
    const currentProps = newProps?newProps:this.props;
    if(this.domSearchBy === 'itemnbr' && parseInt(this.domSearchValue) === 0){
      utilities.showToast('Invalid item number');
      return;
    }
    let  filterlistBOM = "";
    let  filterBy = [{name: "adv", valueList: []},{name: "bom", valueList: []}];
    const domFilter =  this.filterString.split(',');
    domFilter.forEach(element => {
      if(element === "BOM"){
        filterBy[1].valueList= ['BOM'];
      }
      else{
        filterBy[0].valueList.push(element);
      }
    });
    let payload = {
      "memNbr":currentProps.storeId,
      "promoNbr":currentProps.promoNbr,
      "rdc":currentProps.rdc,
      "sectionPage":this.sectionPage,
      "zoneCode":currentProps.zoneCode,
      "searchtype":this.domSearchBy,
      "searchby":this.domSearchValue,
      "SortParam":this.sortParam,
      "SortType":this.sortDirection,
      "page": this.page && this.page.length > 0 
                    ? this.page.split('=')[1]
                    : '1',
      "resultPerPage": parseInt(this.pageSize) === 100 ? '100' : '50',
      "cartid":currentProps.currentCartId,
      'aliasSKURetailerGrpID':currentProps.aliasSKURetailerGrpID,
      filterBy:filterBy
    }
    if(utilities.msExist(this.props.multiStoreDetails) > 1 && currentProps.currentCartId){
      payload = {...payload,multiStoreCartId:currentProps.currentCartId}
    }
    this.setState({ loading: true });
    restUtils.postData(domListingUrl,payload)
      .then(({data})=>{
        if(!data || !data.listitem || data.listitem.length===0){
          this.setState({
            loading:false,
            isNoProducts:true,
            totalItems:0
          });
          return;
        }
        let categoryData = data.listitem;
        const pagination = data.pagination && data.pagination.length>0?data.pagination[0]:{};
        const quantities = categoryData && categoryData.map(product => {
          return utilities.isEmptyOrNullString(product.cart_available)
            ? []
            : product.cart_available
        
        })
       
        this.setState((prevState)=>{
          return{
            categoryData,
            filters:data.filters,
            sectionPages:(!prevState.sectionPages || prevState.sectionPages.length===0)?
                          data.sectionpages
                          :prevState.sectionPages,
            loading: false,
            isNoProducts: !categoryData || categoryData.length === 0,
            totalPages:pagination.totalPages,
            currentPage :pagination.currentPage,
            totalItems : pagination.totalItems,
            firstItem:pagination.firstItem,
            lastItem:pagination.lastItem,
            overrideRetails:categoryData && Array(categoryData.length).fill(''), 
            quantities
          }
      })
      }).catch(error=>{
        console.log(error)
      })
  }
  