import React from 'react';
import { Link } from 'react-router-dom';
import renderImages from '../common/RenderImages';

export const SelectedProductView = props => {
  return (
    <div className='selected-products-div' id='selectedProducts'>
      <div
        style={{
          display: props.selectedProductsVisible ? 'none' : 'flex',
          flexDirection: 'row-reverse'
        }}
      >
        <div
          style={{ cursor: 'pointer', paddingRight: '1rem' }}
          onClick={props.updateSelectedProductsVisible}
        >
          <span style={{ fontWeight: 'bold' }}>Unhide</span>
          <i className='angle up icon' />
        </div>
      </div>
      <div
        className='displayFlex'
        style={{
          display: props.selectedProductsVisible ? 'flex' : 'none',
          flexDirection: 'row'
        }}
      >
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          {props.products.map(product => {
            return (
              <div
                key={product.Item_Number}
                className='ui card_item grid_segment_compare_list'
              >
                <p
                  className='t-center'
                  style={{ fontFamily: 'HelveticaNeueBold', marginBottom: '0' }}
                >
                  {product.shortDesc || product.Product_Name}{' '}
                </p>
                <div className='card_img'>
                  <Link to={'/product/' + product.Item_Number}>
                    {renderImages.getImage({
                      src: product.Image_File_Name
                      ? product.Image_File_Name
                      : 'https://images.truevalue.com/getimage.asp?id=' + product.Item_Number +
                        '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
                      className: 'ui image image_compare_panel',
                      alt: 'product'
                    })}
                  </Link>
                </div>
                <p
                  style={{ fontWeight: 'bold' }}
                  onClick={() => {
                    props.removeFromCompare(product.Item_Number);
                  }}
                  className='t-center compare-remove-link'
                >
                  Remove
                </p>
              </div>
            );
          })}
        </div>
        <RightOptions {...props} />
      </div>
    </div>
  );
};

const RightOptions = props => {
  return (
    <div className='selected-div-right-options'>
      <p
        className='t-center compare-hide-link'
        onClick={props.updateSelectedProductsVisible}
      >
        Hide
        <i className='caret down icon' />
      </p>
      <p>
        <button className='compare-btn' onClick={props.toggleCompare}>
          Compare
        </button>
      </p>
      <p
        className='t-center compare-remove-link'
        onClick={() => {
          props.removeFromCompare('*');
        }}
      >
        Remove All
      </p>
    </div>
  );
};
