import React, { Component } from 'react';
import { Responsive } from 'semantic-ui-react';
import { NavBarMobile } from './NavBarMobile';
import NavBarDesktop from './NavBarDesktop';
import { connect } from 'react-redux';
import { BIGDESKTOP} from '../../views/common/constants'

class NavBar extends Component {
  state = {
    visible: false
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { visible } = this.state;

    return (
      <div>
        <Responsive {...Responsive.onlyMobile} minWidth={320} maxWidth={767}>
          <NavBarMobile
            multiStoreDetails={this.props.multiStoreDetails}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            visible={visible}>
            {this.props.content}
          </NavBarMobile>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}  minWidth={768} maxWidth={1240}>
          <NavBarMobile
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            visible={visible}>
            {this.props.content}
          </NavBarMobile>
        </Responsive>
        <Responsive {...Responsive.onlyComputer} minWidth={1241} maxWidth={BIGDESKTOP}>
          <div className='desktop_menu'>
          <NavBarDesktop multiStoreDetails={this.props.multiStoreDetails} className='menu_bar' />
            {this.props.content}
          </div>
        </Responsive>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
  
  };
};

export default connect(mapStateToProps)(NavBar);
