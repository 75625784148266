import React from 'react'
import { assortmentLevels } from './constants';
import usFlag from '../images/US-Flag-icon.png';
import renderImages from '../common/RenderImages';
import './ProductListingGrid.css';
import utilities from '../../utils/utilities';
import warning from '../images/warning.png';
import { Link } from 'react-router-dom';
import MVP_Assortment_items from '../../views/images/TV_Icons_MVP-Assortment-Items.png';
import Assortment_items from '../../views/images/TV_Icons_assortment_item.png';
import { Icon, Popup } from 'semantic-ui-react';

export const TierIcon = ({ tierLevel, tierLetter=true }) => {
    return (
        <span className='tier_icon' style = {{fontSize: '0.6rem'}}>{tierLetter ? `T${utilities.emptyForNull(tierLevel)}` : `${utilities.emptyForNull(tierLevel)}`}</span>
    )
}

export const DOMIndicator = ({view}) => {
    return (
        <span className={view === 'compact'?'indicator promo_indicator indicator-compact':'indicator promo_indicator'}>DOM |</span>
    )
}
const BOMIndicator = ({view}) => {
    return (
        <span className={view === 'compact'?'indicator promo_indicator indicator-compact':'indicator promo_indicator'}>| BOM </span>
    )
}
const AdvCode = ({advCode, view}) => {
    return (
        <span className={view === 'compact'? 'indicator promo_indicator indicator-compact':'indicator promo_indicator'}>| {advCode}</span>
    )
}
const MadeinUsaInidicator = ({view}) => {
    return (
        <span className='miu-wrap'>
            {renderImages.getImage({ src: usFlag, className:view==='compact'? 'madeinusa_indicator compact_image':'madeinusa_indicator' })}
            <span className={view === 'compact'?'indicator indicator_icon-compact':'indicator'}>&nbsp;Made In USA</span>
        </span>
    )
}
const Prop65Indicator = ({view}) => {
    return (
        <span className = 'miu-wrap'>
            {renderImages.getImage({ src: warning, className: view === 'compact'?'warning_icon compact_image':'warning_icon', alt: 'warn' })}
            <span id='no_padding' className={view === 'compact'? 'warning prop65_color indicator indicator_icon-compact':'indicator warning prop65_color'}>&nbsp;Prop65</span>
        </span>
    )
}

export const ProductIndicators = ({ product, isPLP, isPdp, view }) => {
    const isNew = product.Newitem === '1' || product.Newitem === 'True';
    const isCore = product.DtvInd === '1' || product.DtvInd === 'True';
    const isBom = product.bom_ind === 'BOM';
    const hasAssortmentLevel = product.AssortmentLevel && product.AssortmentLevel.trim() !== ''
    const isMadeInUsa = (product.MadeinUSA && product.MadeinUSA !== 'N') || (product.MadeInUSAInd && product.MadeInUSAInd !== 'N') || (product.madInUSAInd && product.madInUSAInd !== 'N');
    const isDiscontinued = product.DisconInd === 'D';
    const isToBeDiscontinued = product.DisconInd === 'T';
    const isGreenerOption = product.GreenerOptionInd === '1' || product.GreenerOptionInd === 'True';
    const isProp65 = product.prop65 === 'Y' || product.prop65indicator  === 'Y' || product.prop65Indicator  === 'Y';
    const isHazMat = product.HazMat === 'Y' || product.hazmat === 'True';
    const advCode = product.adv_code;
    const importInd = product.import_cd;
    const featureOfMonth = product.fom_ind;
    const whiteSupplies = product.wsl_ind;
    const nonReturnable = product.nr_ind;
    const rebateInd = product.Rebate_IND;
    return (
        <>
            <div  className = 'icon-display'>
                <div className = 'image-icon-display'>
            {isMadeInUsa && <MadeinUsaInidicator view = {view}/>}
            {isPLP === 'PLP' && isProp65 && <Prop65Indicator view = {view} />}
            {isNew && <span className='indicator new-item'>New </span>}
            </div>
            {isCore && <span className='indicator core-item'>{isNew ? '| Core Item' : 'Core Item'}</span>}
            {isHazMat && <span className='indicator hazmat-item'>
                <span className='seprator-color hazmat-color'>{isNew || isCore ? '| Hazmat Item' : 'Hazmat Item'}</span>
            </span>}
            {isDiscontinued && <span className='indicator discon-ind'>
                {isNew || isCore || isHazMat ? <span className='seperator-color'><span>|</span>DISCONTINUED</span>:'DISCONTINUED'}
            </span>}
            {isToBeDiscontinued && <span className='indicator discon-ind'>
                {isNew || isCore || isHazMat ? <span className='seperator-color'><span>|</span>TO BE DISCONTINUED</span>:'TO BE DISCONTINUED'}
            </span>}
            {isGreenerOption && <span className='indicator greener-ind'>
                {isNew || isCore || isHazMat ? <span className='seperator-color'><span>|</span>GREENER OPTIONS</span> :'GREENER OPTIONS'}
            </span>}
            {isBom && <BOMIndicator view = {view}/>}
            {hasAssortmentLevel &&
                (<>
                    {isNew || isCore || isHazMat ?
                        <span >
                            <span className='seprator-color'> | </span>
                            <span className='seprator-color indicator pdp-size'>
                            {parseInt(product.AssortmentLevel || product.assortmentLevel) == 1 ? <><img  className='img-mvp-size' src={MVP_Assortment_items}/></> :
                                parseInt(product.AssortmentLevel || product.assortmentLevel) == 2 || parseInt(product.AssortmentLevel || product.assortmentLevel) == 3 ? <><img className='img-assort-size' src={Assortment_items}/></> : <Popup content="Rebate eligible in current calendar year" trigger={ <p>Expiring Assortment Item<Icon
                                    className='info circle icon'
                                    style={{ cursor: 'pointer', verticalAlign: 'initial' }}></Icon></p>} basic/>  }
                            </span>
                        </span> : 
                        <span>
                        <span className='seprator-color indicator pdp-size'>
                        {parseInt(product.AssortmentLevel || product.assortmentLevel) == 1 ? <><img className='img-mvp-size' src={MVP_Assortment_items}/></> :
                                parseInt(product.AssortmentLevel || product.assortmentLevel) == 2 || parseInt(product.AssortmentLevel || product.assortmentLevel) == 3 ? <><img className='img-assort-size' src={Assortment_items}/></> : <Popup content="Rebate eligible in current calendar year" trigger={<p>Expiring Assortment Item<Icon
                                    className='info circle icon'
                                    style={{ cursor: 'pointer', verticalAlign: 'initial' }}></Icon></p>} basic/> }
                        </span>
                        </span>}
                </>
                )
            }
            {
                advCode && <AdvCode advCode={advCode} view = {view}/>
            }
            {importInd && importInd === 'True' && <span className='indicator new-item'>|{'IM'}</span>}
            {featureOfMonth && featureOfMonth === '1' && <span className='indicator fom-ind'>|{'FOM'}</span>}
            {whiteSupplies && whiteSupplies === '1'  && <span className='indicator wsl-ind'>|{'WSL'}</span>}
            {nonReturnable && nonReturnable === 'True' &&  <span className='indicator promo_indicator'>|{'NR'}</span>}
            {rebateInd && rebateInd === 'True' &&  <span className='indicator rebate'>|{'REB'}</span>}
            </div>
        </>
            )
    }