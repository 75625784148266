import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import MultistoreToCartModal from "../../Cart/MultistoreToCartModal";
import "./styles.css";
import BreadcrumbComp from "../../../components/BreadcrumbComp";
import utilities from "../../../utils/utilities";
import { ASSORTMENT_ITEMS } from "../../../urlConstants";
import Filter from "./Filter";
import { exportExcel, getFirstCartId } from "../../common/ComponentUtilities";
import _ from "lodash";
import restUtils from "../../../utils/restUtils";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { MaskPrice } from "../../common/MaskPrice";
import { bindActionCreators } from "redux";
import OptionsSection from "./OptionsSection";
import ItemListView from "./ItemListView";
import ItemGridView from "./ItemGridView";
import { AssortmentDetails } from "./AssortmentUtilities";
import { isArray } from "util";
import { isFilterRequired } from "./ReunionComponentUtilities";
import { viewAllCarts, updateQty } from "../../Cart/actions";
import {
  isSearchRequired,
  isSortRequired,
  getSortByOptions,
  getSearchByOptions,
  isShowByRequired,
  getBreadcrumbLinks,
  isAddToCartRequired,
  isSeqRequried,
  getPdpParams,
  getPageNumber,
} from "./itemListingUtilities";
import {
  DETAIL_TEXT_ASSORTMENT,
  RESTRICTED_COUNTRY,
  ASSORTMENT_ADJUSTMENT,
  ASSORTMENT_RESTRICTED,
  ASSORTMENT_ALTERNATE,
} from "../../common/constants";
import history from "../../../history";
import { MARKET_ADD_TO_CART, ASSORTMENT_LISTS } from "../../../urlConstants";
import {
  quantityValidator,
  changeStateForValue,
} from "../../common/productHandler";
import UnderConstruction from "../../UnderConstruction";
import { getEventRedirectUrl } from "./eventRedirectUtilities";
import config from '../../../config';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

class AssortmentItemListing extends Component {
  constructor(props) {
    super(props);
    const {
      assortmentId,
      assortmentMdlNbr,
      eventId,
      eventName,
      flowType,
      fromReunionHome,
      relayNbr,
      shoppingId
    } = props.match?.params ? props.match?.params: props;
    const shoppingAreaName = props?.match?.params?.shoppingAreaName ? decodeURIComponent(props?.match?.params?.shoppingAreaName): props?.shoppingAreaName;
    const shoppingAreaMetaId = props?.location?.state?.shoppingAreaMetaId ? decodeURIComponent(props?.location?.state?.shoppingAreaMetaId): props?.shoppingMetaDataId;
    this.changeStateForValue = changeStateForValue.bind(this);
    this.state = {
      regCost: "",
      restrictedItems: [],
      isFilterRequired: isFilterRequired(flowType),
      shoppingAreaName: shoppingAreaName,
      shoppingAreaId: shoppingId,
      shoppingAreaMetaId: shoppingAreaMetaId,
      flowType: flowType,
      isMultistoreModalOpen: false,
      eventId: eventId,
      pageHeader:
        shoppingAreaName === "ReunionRetailAssortments"
          ? "Reunion Retail Assortments"
          : shoppingAreaName,
      promoName: eventName,
      relayNbr: relayNbr,
      AssortmentMDlNbr: assortmentMdlNbr,
      fromReunionHome: fromReunionHome,
      isNoProducts: false,
      imgTextToDisplay:
        props.getprefer &&
        props.getprefer.thumbnailImage &&
        props.getprefer.thumbnailImage !== "Y"
          ? "Show Images"
          : "Hide Images",
      isGridView:
        props.getprefer &&
        props.getprefer.prdctListlayoutFormat &&
        props.getprefer.prdctListlayoutFormat !== "L",
      isLoading: false,
      assortmentNum: assortmentId,
      AssortmentDetails: [],
      filters: [],
      paginationParams: {},
      productsData: [],
      eventEnableAfter: "",
      eventEnableBefore: "",
      eventMessage: "",
      appliedFilters: {},
      promoName: eventName,
      assortmentId: assortmentId,
      retailAssortmentQty: [],
      shipObj: [],
      userQty: "",
    };
    this.sortParam = "itemNbr";
    this.sortDirection = "asc";
    this.page = "1";
    this.appliedFilters = {};
    this.filterList = "";
    this.historyLength = 1;
  }

  componentDidMount() {
    this.props.updateQty(false)
    this.getAssortmentData();
  }
  componentDidUpdate() {
    if(this.props.qtyUpdate) {
      this.scrollPosition = window.pageYOffset;
      this.getAssortmentData();
      this.props.updateQty(false)
    }
  }

  componentDidUpdate() {
    if(this.props.qtyUpdate) {
      this.scrollPosition = window.pageYOffset;
      this.getAssortmentData();
      this.props.updateQty(false)
    }
  }
  getAssortmentData = () => {
    this.setState({ isLoading: true });
    const { eventId, relayNbr, AssortmentMDlNbr, shoppingAreaId, shoppingAreaMetaId } = this.state;
    const {userAffiliate } = this.props;
    let url = `${ASSORTMENT_LISTS}`;
    const requestPayload = {
      rdcNbr: this.props.rdc,
      userAffiliate,
      shoppingId: shoppingAreaId,
      promoNbr: eventId,
      relayNbr: relayNbr,
      searchBy: "model",
      shoppingAreaMetaId: shoppingAreaMetaId,
      searchValue: AssortmentMDlNbr,
      memberNbr: this.props.storeId,
      sortBy: 'model',
      sortOrder: 'asc',
      page: 1,
      resultPerPage: 50,
    };
    restUtils.postData(url, requestPayload).then((response) => {
      if (
        response.data &&
        (response.data?.evtStsActiveBefore === false ||
          response.data?.evtStsActiveAfter === false)
      ) {
        this.setState({
          eventEnableAfter: response.data?.evtStsActiveAfter,
          eventEnableBefore: response.data?.evtStsActiveBefore,
          eventMessage: response.data?.Message,
        });
        return;
      } else if (
        !response?.data?.assortmentResponseList ||
        response.data.assortmentResponseList.length === 0
      ) {
        this.setState((prevState) => {
          return {
            isLoading: false,
            AssortmentDetails: [],
          };
        });
        return;
      }
      const { data } = response;
      this.setState({
        isLoading: false,
        AssortmentDetails: data.assortmentResponseList,
      });
    });
    let queryParam = window.location.href.split("?");
    this.pathname = this.props.location && this.props.location.pathname;
    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    } else {
      this.fetchItems();
    }

    if (this.props.location.state !== undefined) {
      this.setState(this.props.location.state);
    }
  };

  multistoreHandleCarts = (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true,
    });
  };

  closeMultistoreModal = () => {
    //this.fetchItems();
    this.setState({ isMultistoreModalOpen: false });
  };

  handleViewChange = (isGridView) => {
    this.setState({ isGridView });
  };

  showHideImages = (evt) => {
    let imgTextToDisplay =
      evt.target.innerHTML === "Show Images" ? "Hide Images" : "Show Images";
    this.setState({ imgTextToDisplay });
  };

  setRestrictedItems = (restrictedItems) => {
    this.setState({ restrictedItems: restrictedItems });
  };

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    this.scrollPosition = window.pageYOffset;
    this.page = getPageNumber(e.currentTarget.text, paginationParams);
    window.scrollTo(0, 0);
    this.fetchItems();
  };

  getUrlFilters = (queryParam) => {
    // to renitilise fields from url
    this.sortParam = queryParam[1].includes("&SortParam=")
      ? queryParam[1].split("SortParam=")[1].split("&")[0]
      : "";
    this.sortDirection = queryParam[1].includes("SortType=asc")
      ? "asc"
      : queryParam[1].includes("SortType=desc")
      ? "desc"
      : "";
    this.scrollPosition = queryParam[1].includes("scrollPosition=")
      ? queryParam[1].split("scrollPosition=")[1].split("&")[0]
      : 0;
    this.filterList = queryParam[1].includes("filterString")
      ? queryParam[1].split("filterString=")[1]
      : "";
    this.appliedFilters = utilities.getFilterArrayDecode(this.filterList);
    this.filterList = utilities.getQueryJsonAssortment(this.appliedFilters);
    this.setState(
      {
        appliedFilters: this.appliedFilters,
      },
      () => {
        this.fetchItems();
      }
    );
  };

  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
        ? appliedFilters[filterKey]
        : appliedFilters[filterKey].split(",");
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.appliedFilters = appliedFilters;
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.filterList = { filterBy: this.filterList };
    this.setState(
      {
        currentPage: 1,
        appliedFilters,
        clearFilters: false,
        backPath: this.pathname,
      },
      () => {
        this.page = 1;
        this.fetchItems();
        this.setBackButtonPath();
      }
    );
  };

  setBackButtonPath = () => {
    let pathName = "";
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    pathName =
      this.scrollPosition > 0
        ? pathName + "&scrollPosition=" + this.scrollPosition
        : pathName;
    if (!_.isEmpty(this.appliedFilters)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(this.appliedFilters);
    }
    let pushUrl = "";
    this.historyLength = this.historyLength + 1;
    if (pathName !== "") {
      pushUrl = this.pathname + "?" + pathName;
      if(!this.props.fromScanning) {
        window.history.replaceState(window.history.state, "", pushUrl);
      }
    } else {
      pushUrl = this.pathname;
    }
  };

  handleExport = () => {
    const payload = this.getRequestPayload(true);
    utilities.showToast("Exporting to Excel...");
    restUtils.postData(ASSORTMENT_ITEMS, payload).then((response) => {
      if (
        !response?.data?.assortmentsItemsFlow ||
        response.data.assortmentsItemsFlow.length === 0
      ) {
        utilities.showToast("No products available.", true);
        return;
      }
      const preference =
        this.props.getprefer && this.props.getprefer.customerView
          ? this.props.getprefer.customerView
          : "c1";
      const itemsList = response?.data?.assortmentsItemsFlow?.map((product) => {
        let row = {};
        row = {
          ...row,
          ...{ "Item#": utilities.emptyForNull(product.itemNbr) },
        };
        row = {
          ...row,
          ...{
            'Short Description': utilities.emptyForNull(product.shortDesc),
            "Vendor Name": utilities.emptyForNull(product.vendorName),
            "Item Description": config.showMediumDesp ?
            utilities.emptyForNull(
              product.productTitle || product.longDescription
            ) : utilities.emptyForNull(
              product.longDescription
            ),
            Quantity: utilities.emptyForNull(product.quantity),
            Pack: `${product.sPackType ? product.sPackType : ""}${
              product.sPackQty ? product.sPackQty : ""
            }`,
            "Member Cost": utilities.getCostBasedOnnatvalue(
              this.props.viewType,
              product.memberCost,
              preference
            ),
            Model: utilities.emptyForNull(product.model),
            UPC: utilities.emptyForNull(product.upc),
          },
        };
        return row;
      });
      utilities.exportToCsv(itemsList, this.state.pageHeader);
    });
  };

  changeStateForRAValue = function (event, month, index) {
    const { dataset, value } = event.target;
    const { field } = dataset;
    const fieldValue = parseFloat(value);
    if (field && field === "qty" && !quantityValidator(value)) {
      return false;
    }
    let { retailAssortmentQty } = this.state;
    let updateflag = false;
    let i = 0;
    for (i = 0; i < retailAssortmentQty.length; i++) {
      if (retailAssortmentQty[i]?.month === month) {
        retailAssortmentQty[i].quantity = fieldValue || 0;
        updateflag = true;
      }
    }
    if (!updateflag) {
      retailAssortmentQty[index] = {
        ...retailAssortmentQty[index],
        month: month,
        quantity: fieldValue || 0,
      };
    }
    retailAssortmentQty = retailAssortmentQty.filter((assortItem) => {
      return assortItem != null && assortItem != "";
    });
    this.setState({ retailAssortmentQty });
  };

  addAssortmentToCart = () => {
    const {
      eventId,
      retailAssortmentQty,
      pageHeader,
      assortmentId,
      userQty,
      flowType,
      shoppingAreaMetaId
    } = this.state;
    if (
      ((typeof qty === "number" || typeof qty === "string") &&
        !utilities.isValidQuantity(retailAssortmentQty)) ||
      (_.isEmpty(this.state.userQty) && retailAssortmentQty.length === 0) ||
      (_.isEmpty(this.state.userQty) &&
        isArray(retailAssortmentQty) &&
        retailAssortmentQty.every(
          (ele) => ele.quantity === "" || ele.quantity === 0
        ))
    ) {
      utilities.showToast("Please enter a valid quantity", true);
      return;
    }
    let payload = {
      storeId: this.props.storeId,
      rdcNbr: this.props.rdc,
      itemNum: parseInt(assortmentId),
      promoNbr: parseInt(eventId),
      type: "LR",
      quantityList: retailAssortmentQty,
      shoppingIdMeta: parseInt(shoppingAreaMetaId) ? shoppingAreaMetaId : '',
      userId: this.props.userId,
      molUserName: this.props.userName,
      userAffiliate: true,
      shoppingName: pageHeader,
      flowType: parseInt(flowType),
    };
    if (!utilities.isEmptyOrNullString(this.state.userQty)) {
      payload = {
        ...payload,
        userQty: parseInt(this.state.userQty),
      };
    }
    utilities.setLoader(true);
    restUtils
      .postData(MARKET_ADD_TO_CART, payload)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Product added to cart!", true);
        const cartId = response?.data?.cartID;
        this.props.viewAllCarts(cartId);
      })
      .catch((err) => {
        utilities.setLoader(false);
        utilities.showToast("Couldn't add product to cart", true);
        console.log(err);
      });
  };

  getListingHeader = () => {
    const { paginationParams, imgTextToDisplay } = this.state;
    let { shipObj, retailAssortmentQty } = this.state;
    const assortment = this.state?.AssortmentDetails?.[0];
    let shipOptions = assortment.shipOptions;
    let currentRaqty =
      shipOptions &&
      shipOptions.map((res) => {
        const monthsSplit =
          res.month?.length > 3 ? res.month?.slice(0, 3) : res.month;
        shipObj = { ...shipObj, month: monthsSplit, qty: "" };
        retailAssortmentQty &&
          retailAssortmentQty.map((qty, index) => {
            if (qty.month === monthsSplit) {
              shipObj = {
                ...shipObj,
                month: qty.month,
                qty: qty.quantity || "",
              };
            }
            return shipObj;
          });
        return shipObj;
      });
    let msCheck = utilities.msExist(this.props.multiStoreDetails);
    return (
      <div className="listingReunion">
        <div className="listing-header">
          <span>{paginationParams.totalItems} Items</span>
          <span onClick={this.showHideImages} id="show_and_hide_images">
            {imgTextToDisplay}
          </span>
          <span className="final_sales"> ALL SALES ARE FINAL!</span>
        </div>
        {!this.state.isNoProducts && !assortment.asstmtRestricted && (
          <div className="listingHeaderDelivery">
            {!_.isEmpty(currentRaqty) && currentRaqty !== "" ? (
              <span className="qty-field">
                Delivery: &nbsp;
                {currentRaqty &&
                  currentRaqty.map((ship, index) => (
                    <>
                      <span class="ship_options">
                        <span
                          class={
                            index > 0
                              ? "delivery_options"
                              : "delivery_options_month"
                          }
                        >
                          {ship.month?.length > 3
                            ? ship.month?.slice(0, 3)
                            : ship.month}
                        </span>
                        <input
                          type="text"
                          className="delivery-qty-box"
                          id={"qty-box" + ship.month}
                          data-field="qty"
                          value={ship.qty}
                          name={"retailAssortmentQty" + ship.month}
                          onChange={(e) =>
                            this.changeStateForRAValue(
                              e,
                              ship.month,
                              index,
                              this.props.RAMarketFlag
                            )
                          }
                        />
                      </span>
                    </>
                  ))}
              </span>
            ) : (
              <span className="qty-field">
                Quantity: &nbsp;
                <input
                  type="text"
                  className="qty-box"
                  id="qty-box"
                  data-field="qty"
                  name="userQty"
                  value={this.state.userQty}
                  onChange={this.changeStateForValue}
                />
                &ensp;
              </span>
            )}
            {utilities.msExist(this.props.multiStoreDetails) > 1 ? (
              <button
                className="red ui button addToCartBtn"
                onClick={() => this.multistoreHandleCarts()}
              >
                Add to Store
              </button>
            ) : (
              <button
                className="red ui button addToCartBtn"
                onClick={() => this.addAssortmentToCart()}
              >
                Add to Cart
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  getItemListParams = () => {
    const { flowType } = this.state;
    return {
      handleQtyChange: this.handleQtyChange,
      isAddToCartRequired: isAddToCartRequired(flowType),
      isScanListing: false,
      isSeqRequired: isSeqRequried(flowType),
    };
  };

  goToPreviousPage = () => {
    //history.go(-parseInt(this.historyLength));
    history.goBack();
  };

  getFieldSet = (product, index) => {
    let quantity = this.state.quantities[index];
    let overrideRetail = this.state.overrideRetails[index];
    const { access, CatMbr } = this.props?.multiStoreDetails;
    let { getprefer, viewType } = this.props;
    let preference = utilities.getPreferenceHideMask(getprefer);
    const memberCost = (
      <MaskPrice
        viewType={viewType}
        getprefer={getprefer}
        fieldVal={product.memberCost}
        field={"Member Cost"}
      />
    );

    return !this.state.isGridView && window.innerWidth >= 1024
      ? [
          ...(access !== 1
            ? [
                [
                  { label: "ITEM#:", val: product.itemNbr },
                  { label: utilities.getLabelsByType(this.props.viewType,"MEMBER_COST"), val: memberCost },
                ],
                [
                  { label: "MODEL#:", val: product.model },
                  { label: "UPC:", val: product.upc },
                ],
                [{ label: "QUANTITY:", val: product.quantity }],
              ]
            : [
                [
                  { label: "ITEM#:", val: product.itemNbr },
                  { label: "MODEL#:", val: product.model },
                ],
                [
                  { label: "UPC:", val: product.upc },
                  {
                    label: "QUANTITY:",
                    val: utilities.checkForHubAndSpokeIndictor(product),
                  },
                ],
              ]),
        ]
      : [
          ...(access !== 1
            ? [
                { label: "ITEM#:", val: product.itemNbr },
                { label: "MODEL#:", val: product.model },
                { label: utilities.getLabelsByType(this.props.viewType,"MEMBER COST"), val: memberCost },
                { label: "QUANTITY:", val: product.quantity },
                { label: "UPC:", val: product.upc },
              ]
            : [
                { label: "ITEM#:", val: product.itemNbr },
                { label: "MODEL#:", val: product.model },
                { label: "UPC:", val: product.upc },
                {
                  label: "QUANTITY:",
                  val: utilities.checkForHubAndSpokeIndictor(product),
                },
              ]),
        ];
  };

  getItemParams = (product, index) => {
    let itemParams = { product };
    const fieldSet = this.getFieldSet(product, index);
    itemParams = {
      ...itemParams,
      fieldSet,
      pdpParams: getPdpParams(this.state.flowType, product),
    };
    return itemParams;
  };

  getItemListParams = () => {
    const { flowType } = this.state;
    return {
      handleQtyChange: this.handleQtyChange,
      isAddToCartRequired: isAddToCartRequired(flowType),
      isScanListing: false,
      isSeqRequired: isSeqRequried(flowType),
    };
  };

  getListing = () => {
    const {
      isAddToCartModalOpen,
      productSelectedForModal,
      indexForModal,
      AssortmentDetails
    } = this.state;
    const assortment = AssortmentDetails?.[0];
    const cartParams = {
      isAddToCartModalOpen,
      closeCartModal: this.closeCartModal,
      addToCart: this.addToCart,
      modalProduct: productSelectedForModal,
      indexForModal,
    };
    const imgStyle =
      this.state.imgTextToDisplay === "Hide Images"
        ? "displayBlock"
        : "displayNone";
    const { isGridView } = this.state;
    const itemListParams = this.getItemListParams();
    return (
      <>
        <div
          className={
            isGridView ? "ui stackable three column grid product-grid assortmentGridWidth" : ""
          }
        >
          {this.state.productsData.map((product, index) => {
            const itemParams = this.getItemParams(product, index);
            return (
              <>
                {!isGridView && window.innerWidth >= 1024 ? (
                  <ItemListView
                    flowType={this.state.flowType}
                    cartParams={cartParams}
                    itemParams={itemParams}
                    imgStyle={imgStyle}
                    product={itemParams.product}
                    itemListParams={itemListParams}
                    setBackButtonPath={this.props.setScrollPosition}
                    shoppingAreaMetaId = {this.state.shoppingAreaMetaId}
                    shoppingAreaName ={this.state.shoppingAreaName}
                  />
                ) : (
                  <ItemGridView
                    flowType={this.state.flowType}
                    cartParams={cartParams}
                    itemParams={itemParams}
                    imgStyle={imgStyle}
                    product={itemParams.product}
                    itemListParams={itemListParams}
                    setBackButtonPath={this.props.setScrollPosition}
                    shoppingAreaMetaId = {this.state.shoppingAreaMetaId}
                    shoppingAreaName ={this.state.shoppingAreaName}
                  />
                )}
              </>
            );
          })}
        </div>
        {!assortment.asstmtRestricted && (
          utilities.msExist(this.props.multiStoreDetails) > 1 ? (
            <button
              className="red ui button pl-item-list-addtocart-btn"
              onClick={() => this.multistoreHandleCarts()}
            >
              Add to Store
            </button>
          ) : (
            <button
              className="red ui button pl-item-list-addtocart-btn"
              onClick={() => this.addAssortmentToCart()}
            >
              Add to Cart
            </button>
          )
        )}
      </>
    );
  };

  changeSortParam = (e) => {
    this.page = 1;
    this.sortParam = e.target.value;
    this.scrollPosition = window.pageYOffset;
    this.fetchItems();
  };

  changeSortDirection = (e) => {
    this.page = 1;
    this.sortDirection = e.target.value;
    this.scrollPosition = window.pageYOffset;
    this.fetchItems();
  };

  getRequestPayload = (isCompleteList) => {
    const { assortmentNum, paginationParams, shoppingAreaId } = this.state;
    const resultPerPage = this?.props?.getprefer?.resultPerPage
      ? this.props.getprefer.resultPerPage
      : paginationParams.totalItems;
    let currentCart = this.props?.carts && this.props.carts.find(cart => parseInt(cart.cartId) === parseInt(this.props.currentCartId)); 
    let getCartId = getFirstCartId(shoppingAreaId,this.state.eventId,this.props.carts, this.props.currentCartId)
    return {
      memberNbr: this?.props?.storeId,
      rdcNbr: this.props.rdc,
      shoppingId: shoppingAreaId,
      sortBy: this.sortParam,
      sortOrder: this.sortDirection,
      page: this.page,
      promoNbr: this.state.eventId,
      resultPerPage: resultPerPage,
      assmtNbr: assortmentNum,
      cartId: getCartId,
      multiStoreCartId : utilities.msExist(this.props.multiStoreDetails) > 1 && currentCart?.parentId ? currentCart.parentId : '',
      ...this.filterList,
    };
  };

  fetchItems = () => {
    this.setState({ isLoading: true });
    const payload = this.getRequestPayload();
    restUtils.postData(ASSORTMENT_ITEMS, payload).then((response) => {
      if (
        response.data &&
        (response.data?.evtStsActiveBefore === false ||
          response.data?.evtStsActiveAfter === false)
      ) {
        this.setState({
          eventEnableAfter: response.data?.evtStsActiveAfter,
          eventEnableBefore: response.data?.evtStsActiveBefore,
          eventMessage: response.data?.Message,
        });
        return;
      } else if (
        !response?.data?.assortmentsItemsFlow ||
        response.data.assortmentsItemsFlow.length === 0
      ) {
        this.setState((prevState) => {
          return {
            isLoading: false,
            productsData: [],
            isNoProducts: true,
            paginationParams: {
              ...prevState.paginationParams,
              totalPages: "0",
              currentPage: "0",
              totalItems: "0",
              firstItem: "0",
              lastItem: "0",
            },
          };
        });
        return;
      }
      const { data } = response;
      const productsData = data?.assortmentsItemsFlow;
      let restrictedItems = productsData
        .filter((product) => product.itemRestricted)
        .map(({ itemNbr }) => itemNbr);
      this.setState(
        {
          isLoading: false,
          isNoProducts: false,
          userQty: data?.cart_available[0]?.quantity
            ? data?.cart_available[0]?.quantity
            : "",
          retailAssortmentQty: data?.cart_available,
          multiStoreCartavailable: data?.multiStoreCartavailable,
          productsData,
          paginationParams: data.pagination[0],
          filters: data.filters,
          quantities: productsData.map((product) => {
            return [
              utilities.emptyForNull(product.userQty),
              utilities.emptyForNull(product.userQty2),
              utilities.emptyForNull(product.userQty3),
              utilities.emptyForNull(product.userQty4),
            ];
          }),
          restrictedItems,
          overrideRetails: productsData?.map(() => ""),
        },
        () => {
          window.scrollTo(0, this.scrollPosition);
          this.setBackButtonPath();
        }
      );
    });
  };
  getAssortmentLink = (modelNbr) => {
    const {
      shoppingAreaName,
      shoppingAreaId,
      shoppingAreaMetaId,
      flowType,
      eventId,
      promoName,
      relayNbr,
      fromReunionHome,
    } = this.state;
    const keyParams = {
      pageName: "AssortmentListing",
      shoppingAreaName,
      shoppingAreaId,
      shoppingAreaMetaId,
      flowType,
      eventId,
      eventName: promoName,
      relayNbr,
      fromReunionHome,
    };
    let backurl = getEventRedirectUrl({ keyParams });
    return backurl + "?searchBy=model=" + modelNbr;
  };

  getAssortmentByModel = (modelNbr) => {
    history.push(this.getAssortmentLink(modelNbr.trim()));
  };
  render() {
    const {
      shoppingAreaName,
      pageHeader,
      shoppingAreaId,
      shoppingAreaMetaId,
      flowType,
      eventId,
      promoName,
      isLoading,
      assortmentNum,
      isNoProducts,
      filters,
      isFilterRequired,
      relayNbr,
      assortmentId,
      fromReunionHome,
      AssortmentMDlNbr,
      eventEnableAfter,
      eventEnableBefore,
      eventMessage,
    } = this.state;
    const assortment = this.state.AssortmentDetails && this.state.AssortmentDetails[0];
    const raModel = assortment?.modelNbr;
    const shortDesc = assortment?.shortDesc;
    const endDate = assortment?.RAMarketEndDate;
    const regCost = assortment?.regCost;
    const assortmentRestrictionMsg = `${assortment?.asstmtRestrictedMessage}${assortment?.asstmtRestAlternateNbr==='N/A'? " (No recommended assortments for this restriction type.)":""}`
    const assortmentAlternates = (!utilities.isEmptyOrNullString(assortment?.asstmtRestAlternateNbr) && assortment?.asstmtRestAlternateNbr!=='N/A')
      ? assortment?.asstmtRestAlternateNbr?.split(",")
      : [];
    let assortments = assortment;
    return (
      <>
        {eventEnableAfter === false || eventEnableBefore === false ? (
          <UnderConstruction
            eventMessage={eventMessage}
            shoppingAreaName={shoppingAreaName}
          />
        ) : (
          <div>
            <MultistoreToCartModal
              isMultistoreModalOpen={this.state.isMultistoreModalOpen}
              retailAssortmentQty={this.state.retailAssortmentQty}
              eventId={this.state.eventId}
              shipOptions={assortment?.shipOptions}
              assortmentId={this.props.assortmentId}
              flowType={this.state.flowType}
              closeMultistoreModal={this.closeMultistoreModal}
              shoppingAreaName={this.state.shoppingAreaName}
              multiStoreCartavailable={this.state.multiStoreCartavailable}
              product={assortment}
              shoppingAreaMetaId = {this.state.shoppingAreaMetaId}
            />
            {!assortments ? (
              <div>
                <Loader active />
              </div>
            ) : (
              <Segment id="productDetailInfo">
                <div className="page-header">
                  {pageHeader} - {eventId} - {decodeURIComponent(promoName)}
                </div>
                <div id="page_navigation">
                  <BreadcrumbComp
                    path={getBreadcrumbLinks(
                      fromReunionHome,
                      flowType,
                      eventId,
                      promoName,
                      "",
                      shoppingAreaName,
                      shoppingAreaId,
                      shoppingAreaMetaId,
                      assortmentId,
                      relayNbr,
                      "",
                      shortDesc + "-" + raModel,
                      "",
                      "",
                      "",
                      "AssortmentItemListing"
                    )}
                  />
                </div>
                <br />
                <div className="info-text">
                  <span>{assortments.shortDesc}</span>
                  <span className="assortment_itemname">{raModel}</span>
                  <span className="assortmentLink">
                    {window.innerWidth > 767 && (
                      <>
                        <span
                          className="assortmentLinkRight"
                          onClick={this.goToPreviousPage}
                        >
                          <u>Back to Assortment Listing</u>
                        </span>
                        <span>
                          {exportExcel({
                            className: "exportPDF exportPdfPLP",
                            onClick: this.handleExport,
                          })}
                        </span>
                        <br/>
                        <span className='assortmentreunionRight' >POG: &ensp;
                        {assortment.planoNum ? assortment.planoNum :'N/A'}
                      </span>
                      <span >
                      <span>View Assortment: </span>
                      {assortment.planoNum ? 
                      <i className='file pdf icon large planogramexportie' id='export_btns' 
                          onClick={() => utilities.openInNewTab(assortment.planoNum?parseInt(assortment.planoNum.length)>=9?
                            utilities.getplanogramPdf(assortment.planoNum):
                            utilities.getImpulsePdf(assortment.planoNum)
                          :'')} />
                       :'N/A'}
                    </span>
                      </>
                    )}
                  </span>
                </div>
                <div className="card_fields">
                  <AssortmentDetails
                    regCost={regCost}
                    multiStoreDetails={this.props.multiStoreDetails}
                    endDate={endDate}
                    assortment={assortments}
                    viewType={this.props.viewType}
                    getprefer={
                      this.props.getprefer && this.props.getprefer.customerView
                        ? this.props.getprefer.customerView
                        : "c1"
                    }
                    offerEndDateHide={shoppingAreaId === 12 && eventId === '541' ? true : false}                   
                  />
                </div>
                {window.innerWidth < 768 && (
                  <>
                    <span
                      className="assortmentLinkRight"
                      onClick={this.goToPreviousPage}
                    >
                      <u>Back to Assortment Listing</u>
                    </span>
                    <span>
                      {exportExcel({
                        className: "exportPDF exportPdfPLP",
                        onClick: this.handleExport,
                      })}
                    </span>
                    <br/>
                    <span className='assortmentreunionRight' >POG: &ensp;
                        {assortment.planoNum ? assortment.planoNum :'N/A'}
                    </span>
                    <span >
                    <span>View Assortment: </span>
                    {assortment.planoNum ? 
                    <i className='file pdf icon large planogramexportie' id='export_btns' 
                      onClick={() => utilities.openInNewTab(assortment.planoNum?parseInt(assortment.planoNum.length)>=9?
                        utilities.getplanogramPdf(assortment.planoNum):
                        utilities.getImpulsePdf(assortment.planoNum)
                      :'')} />
                    :'N/A'}
                  </span>
                  </>
                )}
                <br />
                <div className="detail_text">
                  {<>{DETAIL_TEXT_ASSORTMENT}</>}
                  {assortment.asstmtRestricted && (
                    <span className="red_text_details">
                      <br />
                      <br />
                      {assortmentRestrictionMsg}
                      {assortmentAlternates && assortmentAlternates.length > 0 && (
                        <>
                          {assortmentAlternates?.map((altModel, index) => {
                            return (
                              <span
                                className="model_name"
                                onClick={() =>
                                  this.getAssortmentByModel(altModel)
                                }
                              >
                                {altModel}
                                {index < assortmentAlternates.length - 1
                                  ? " , "
                                  : ""}
                              </span>
                            );
                          })}
                        </>
                      )}
                    </span>
                  )}
                  
                  {!assortment.asstmtRestricted && this.state.restrictedItems.length > 0 && (
                    <>
                      <p className="no_margin">
                        <span className="red_text_details">
                          {RESTRICTED_COUNTRY}
                        </span>
                        {this.state.restrictedItems.map((itemNum, index) => {
                          return (
                            <>
                              {index > 0 && ","} &nbsp;
                              <span className="restricted_Item">
                                <Link to={"/product/" + itemNum}>
                                  {itemNum}
                                </Link>
                              </span>
                            </>
                          );
                        })}
                      </p>
                      <span className="red_text_details">
                        {ASSORTMENT_ADJUSTMENT}
                      </span>
                      <br />
                    </>
                  )}
                </div>
                <br />
                <OptionsSection
                  isSortByRequired={isSortRequired(flowType)}
                  sortByList={getSortByOptions(flowType)}
                  changeSortParam={this.changeSortParam}
                  isSearchRequired={isSearchRequired(flowType)}
                  searchByList={getSearchByOptions(flowType)}
                  isShowByRequired={isShowByRequired(flowType)}
                  pageSize={this.pageSize}
                  changePageSize={this.changePageSize}
                  isGridViewIconRequired={true}
                  handleViewChange={this.handleViewChange}
                  changeSortDirection={this.changeSortDirection}
                  sortOrder={this.sortDirection}
                />
                <div className="displayFlex allOrdersTable">
                  {isLoading && <Loader active />}
                  {isFilterRequired && (
                    <Filter
                      filters={filters}
                      isNoProducts={isNoProducts}
                      appliedFilters={this.state.appliedFilters}
                      defaultChecked={this.defaultChecked}
                      clearFilters={false}
                      filterCallback={this.filterCallback}
                    />
                  )}
                  <div id="grid_width" className="full-width pl_data">
                    {this.getListingHeader()}
                    {isNoProducts ? (
                      <div className="no_prdcts_display">
                        <h1>No Products to Display!</h1>
                      </div>
                    ) : (
                      <>{this.getListing()}</>
                    )}
                  </div>
                </div>
              </Segment>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const storeId = state.SessionReducer.storeId;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    userAffiliate:state.SessionReducer?.address?.affiliate,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    getprefer: state.preference.listData,
    rdc: state?.SessionReducer?.rdc,
    carts: state.cart.orders.allCarts,
    storeId,
    userId: state.SessionReducer.UserId,
    userName: state.SessionReducer.address.userName,
    currentCartId: state.cart.orders.currentCart.cartId,
    qtyUpdate: state.cart.orders?.updateQty,
  };
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ viewAllCarts,updateQty }, dispatch)
  );
};

export default connect(mapStateToProps,mapDispatchToProps)(AssortmentItemListing);
