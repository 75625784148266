export const memberCostFilter = {
  id: "$member_cost_filter",
  filterTitle: "Member Cost",
  filterOptions: [
    { value: "Show", id: "show", checked: true },
    { value: "Mask", id: "mask" },
    { value: "Hide", id: "hide" },
  ],
};

export const suggestedRetailFilter = {
  id: "$suggested_retail_filter",
  filterTitle: "Suggested Retail",
  filterOptions: [
    { value: "Show", id: "show", checked: true },
    { value: "Mask", id: "mask" },
    { value: "Hide", id: "hide" },
  ],
};
