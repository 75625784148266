import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addProduct, createNAddCart } from '../Cart/actions';
import { getFavoriteItems } from '../Favorites/actions'
import renderImages from '../common/RenderImages';
import { MaskPrice } from '../common/MaskPrice';
import { addToCart, quantityValidator, isMaxQtyValid } from '../common/productHandler'
import utilities from '../../utils/utilities';
import AddToCartModal from '../Cart/AddToCartModal';
import { ProductIndicators, TierIcon } from '../common/TierIcon';
import BusinessRules from '../common/BusinessRules';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { Divider } from 'semantic-ui-react';

class CompareCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: props.product,
      quantity:utilities.emptyForNull(props.product.cart_available),
      overrideRetail:utilities.emptyForNull(props.product.cart_overrideretail),
      isAddToCartModalOpen: false,
      isFavorite:
        props.favorites.indexOf(parseInt(props.product.Item_Number)) > -1,
      isMultistoreModalOpen:false
    };
    this.addToCart = addToCart.bind(this);
  }


  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.setState({ isMultistoreModalOpen: false });
  }

  favorite = (e, Item_Number) => {
    this.props.toggleFavorite(Item_Number);
    this.setState(prevState => {
      return { isFavorite: !prevState.isFavorite };
    });
  };

  getSuggestedRetail = (product) => {
    const { viewType, multiStoreDetails } = this.props;

    let variableRetail = 0
    if(this.props.onVariablePricing){
      variableRetail = product.variableRetail
    }
    let price = BusinessRules.suggestedRetailPriceForPlp(product,variableRetail,viewType, multiStoreDetails);

    return (
        <MaskPrice
          getprefer={this.props.getprefer &&
            this.props.getprefer.customerView
            ? this.props.getprefer.customerView
            : 'c1'
          }
          viewType={this.props.viewType}
          fieldVal={price}
        />)
  }

  handleChange = event =>{
    const { dataset, value } = event.target;
    const fieldValue = parseFloat(value);
    if(dataset.field === 'qty'){
      if(!quantityValidator(value)){
        return;
      }
      this.setState({quantity:value})
    }else{
      if (value.trim() === '' || fieldValue < 0) {
        utilities.showToast('Override Retail should be positive number!',true);
        this.setState({overrideRetail:value})
        return
      }
      else if(!utilities.isValidOr(value)){
        return;
      }
      this.setState({overrideRetail:value})
    }
  }

  openCartModal = () => {
    this.setState({
      isAddToCartModalOpen: true
    });
  };

  closeCartModal = () => {
    this.setState({ isAddToCartModalOpen: false });
  };

  handleAddToCart = () => {
    const {quantity,overrideRetail,product} = this.state;
    const storeId = this.props.storeId;
    const rdcNum = utilities.rdcBasedonCentralship(product.rdctype, product.CsRdc, this.props.rdc);
    if (!isMaxQtyValid(quantity, product.maxQty, false)) {
      return;
    }
    if (this.props.currentCartType !== 'REGULAR' && this.props.whCarts.length > 0) {
      this.openCartModal();
    } else if(
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length === 0){
        this.addToCart(
          product.Item_Number,
          product.Member_Cost,
          quantity,
          overrideRetail,
          product.S_Pack_Type,
          product.S_Pack_Qty,
          "new",
          rdcNum,
          storeId
        );
    }else {
      this.addToCart(
        product.Item_Number,
        product.Member_Cost,
        quantity,
        overrideRetail,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        null,
        rdcNum,
        storeId
      );
    }
  };

  render() {
    const { product, imageUrl, removeFromCompare, index, isCustomerView } = this.props;
    const { quantity, overrideRetail } = this.state;
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    return (
      <>
      {window.innerWidth > 768
      ?
      <div className='fields-div three wide column'>
        <AddToCartModal
            isAddtoCartModalopen={this.state.isAddToCartModalOpen}
            closeCartModal={this.closeCartModal}
            addToCart={this.addToCart}
            product={this.state.product}
            index={0}
            qty={quantity}
            or={overrideRetail}></AddToCartModal>
        <MultistoreToCartModal
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          product={this.state.productSelectedForModal}
          closeMultistoreModal={this.closeMultistoreModal}
        ></MultistoreToCartModal>
        <div className='row1'>
          <p>
            <i
              style={{ float: 'left', cursor: 'pointer' }}
              onClick={e => {
                this.favorite(e, product.Item_Number);
              }}
              className={
                this.state.isFavorite ? 'heart icon' : 'heart outline icon'
              }
            />
            <span
              style={{ float: 'right' }}
              onClick={() => {
                removeFromCompare(product.Item_Number);
              }}
              id='ProductRemove'
              className='t-center compare-remove-link'>
              Remove
            </span>
          </p>
          <div className='compareImage'>
            <Link to={'/product/' + product.Item_Number}>
              {renderImages.getImage({ src: imageUrl })}
            </Link>
          </div>
          <p className='vendor-span'>
          <Link
          to={{
            pathname: product.Vendor_Name
              ? '/vendor/' +
                utilities.replaceAll(
                  product.Vendor_Name,
                  '/',
                  '%2F'
                )+ '/' +(product.Vendor_Id || product.vendorID || product.vendor_id)
              : '#',
            state: { vendorId: product.Vendor_Id }
          }}>
            {product.Vendor_Name}
          </Link>
          </p>
          <Link to={'/product/' + product.Item_Number}>
            <b className='compareProductName'>
              {product.Product_Name}
            </b>
           </Link>
        </div>
        <div className='product-field-bold row2'>{product.Item_Number}</div>
        <div className='product-field-bold row3'>{product.Model}</div>
        {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && 
        <div className='product-field-bold row3'>{product.aliasSku}</div>}
        <div className='product-field-bold row4' style={product.Available &&
                          parseInt(product.Available) > 0
                            ?{ color: 'rgba(0,0,0,0.87)' } : { color: 'red' } }>
          {product.Available}
        </div>

        <div className='product-field-bold field-value row5'>         
          <div className='indicators-div'>
              <ProductIndicators product={product} />
          </div>
        </div>
        <br/><br/>
        {(this.props.multiStoreDetails.access !== 1  
          || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) && this.props.displayMemberCost &&
          <div className='product-field-bold row6'>        
          <MaskPrice
            getprefer = {(this.props.getprefer && this.props.getprefer.customerView) ? this.props.getprefer.customerView: 'c1'}
            viewType={this.props.viewType}
            fieldVal={utilities.getCostForQtyPlpNew(
                          product,
                          quantity
                        )}
            field='Member Cost'
          />        
          {(product.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW)
              && <TierIcon 
                tierLevel={utilities.getCostForQtyPlpNew(product ,quantity, true)}
                tierLetter={quantity && parseInt(quantity) > 0 ? true : false} />
          }
        </div>
        }
        <div className='product-field-bold row7'>
          {product.S_Pack_Type}
          {product.S_Pack_Qty}
        </div>
        
        {this.props.displaySRPCost &&<div className='product-field-bold row8'>        
          {this.getSuggestedRetail(product)}  
        </div>
        }
        { this.props.onVariablePricing && this.props.viewType === MEMBER_VIEW &&<div className='product-field-bold row9'>        
        {
        product.variableRetail ? `$${utilities.emptyForNull(parseFloat(product.variableRetail).toFixed(2))}`: ''}
        </div>
        }
        <div className='product-field-bold'>        
          {product.maxQty ?  product.maxQty: '-'}  
        </div>
        <div className='product-field-bold'>
          <input
            className='qty-box'
            id={'qty-box' + product.Item_Number}
            onChange = {this.handleChange}
            data-field='qty'
            data-item-number={index}
            data-pack-type={product.S_Pack_Type}
            data-pack-qty={product.S_Pack_Qty}
            value = {quantity}
          />
          </div>
        {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) && 
        <div className='product-field-bold row10'>
          {' '}
          <div>
              {"$ "}
              <input
                id={'or-' + product.Item_Number}
                className='qty-box'
                label='$'
                name='overrideRetail'
                onChange = {this.handleChange}
                data-field='or'
                data-item-number={index}
                data-pack-type={product.S_Pack_Type}
                data-pack-qty={product.S_Pack_Qty}
                value = {overrideRetail}
              />
          </div>
        </div>
      }
        <div className='product-field-bold row3 restricted-product'>{product.Restrictions && product.Restrictions.length > 0 ? utilities.restrictedDetail(product?.Restrictions) : ''}</div>
        {this.props.multiStoreDetails.access !== 1  &&<div className='product-field-bold row11'>
          <>
          {msCheck && msCheck > 1 ?
          !this.props.isReunionOnlyStore ?
            <button
            className='fluid ui red button'
            id='rounded-add-to-cart'
            onClick={() =>
              this.multistoreHandleCarts(product)
            }
          >
            Add to Store
          </button>
          : <>
            <button className='fluid red ui button' disabled>Add to Store</button>
            <p class="disabledMessage">*Ordering restricted</p>
            </>
          :!this.props.isReunionOnlyStore ?
          <button
            className='fluid ui red button'
            id='rounded-add-to-cart'
            onClick={() =>
              this.handleAddToCart(product)
            }>
            Add to Cart
          </button>
          :<div>
            <button className='fluid red ui button' disabled>Add to Cart</button>
            <p class="disabledMessage">*Ordering restricted</p>
          </div>
          }
          </>
     
        </div>
         }
        <div className='product-field-bold long-desc'>
          {product.Product_Title || product.Long_Description || product.Product_Name}
        </div>
        <div className='product-field-bold field-value row13'>
          {product.Length} IN
        </div>
        <div className='product-field-bold field-value row14'>
          {product.Width} IN
        </div>
        <div className='product-field-bold field-value row15'>
          {product.Height} IN
        </div>
        <div className='product-field-bold field-value row16'>
          {product.weight} lbs
        </div>
        {!isCustomerView &&
        <>
          <div className='product-field-bold field-value row17'>{product.UpcNum}</div>
          <div className='product-field-bold field-value row18'>
            {utilities.hyphenForNull(product.SecondaryUpcNumber)}
          </div>
          <div className='product-field-bold field-value row19'>
            {product.MasterPack}
          </div>
          <div className='product-field-bold field-value row20'>
            {product.InnerPack}
          </div>
          <div className='product-field-bold field-value row21'>{product.Hazmat}</div>
          <div className='product-field-bold field-value row22'>{product.FedEx}</div>
        </>
        }
        <div className='product-field-bold field-value row23'>
          {product.CountryOfOrigin}
        </div>
        {!isCustomerView &&
        <>
          <div className='product-field-bold field-value row24'>
            {product.Perishable}
          </div>
          <div className='product-field-bold field-value row25'>
            {product.Freezable}
          </div>
        </>
        }
        {product.CategoryAttributes && 
          Object.keys(product.CategoryAttributes).map(function(AttName, index) {
            return( 
              <>
                {index == 0 &&  <Divider section />}
                <div className={'product-field-bold field-value catatt'+index}>
                  {product.CategoryAttributes[AttName] ? product.CategoryAttributes[AttName] : "-"}
                </div>
              </>)
          })
        }
      </div>
      :
      <>
      <div className='fields-div seven wide column mobilepacks'>
        <AddToCartModal
            isAddtoCartModalopen={this.state.isAddToCartModalOpen}
            closeCartModal={this.closeCartModal}
            addToCart={this.addToCart}
            product={this.state.product}
            index={0}
            qty={quantity}
            or={overrideRetail}></AddToCartModal>
        <div className='row1'>
          <p>
            <i
              style={{ float: 'left', cursor: 'pointer' }}
              onClick={e => {
                this.favorite(e, product.Item_Number);
              }}
              className={
                this.state.isFavorite ? 'heart icon' : 'heart outline icon'
              }
            />
            <span
              style={{ float: 'right' }}
              onClick={() => {
                removeFromCompare(product.Item_Number);
              }}
              id='ProductRemove'
              className='t-center compare-remove-link'>
              Remove
            </span>
          </p>
          <div className='compareImage'>
            <Link to={'/product/' + product.Item_Number}>
              {renderImages.getImage({ src: imageUrl })}
            </Link>
          </div>
          <p className='vendor-span'> 
          <Link
          to={{
            pathname: product.Vendor_Name
              ? '/vendor/' +
                utilities.replaceAll(
                  product.Vendor_Name,
                  '/',
                  '%2F'
                )+ '/' +(product.Vendor_Id || product.vendorID || product.vendor_id)
              : '#',
            state: { vendorId: product.Vendor_Id }
          }}>
            {product.Vendor_Name}
          </Link>
          </p>
          <Link to={'/product/' + product.Item_Number}>
            <b className='compareProductName'>
              {product.Product_Name || product.Product_Title}
            </b>
          </Link>
        </div>
        <div className='comparison-field-mobile'><span>Item#</span></div>
        <div className='product-field-bold row2'>{product.Item_Number}</div>
        <div className='comparison-field-mobile'><span>Model#</span></div>
        <div className='product-field-bold row3'>{product.Model}</div>
        {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && 
         <><div className='comparison-field-mobile'><span>Alternate SKU#</span></div>
         <div className='product-field-bold row3'>{product.aliasSku}</div></>}
        <div className='comparison-field-mobile'><span>Available</span></div>
        <div className='product-field-bold row4' style={product.Available &&
                          parseInt(product.Available) > 0
                            ?{ color: '#000000' } : { color: 'red' } }>
          {product.Available}
        </div>
        <div className='comparison-field-mobile'><span>Product Indicators</span></div>
        <div className='product-field-bold field-value row5 mobindicator'>         
          <div className='indicators-div'>
              <ProductIndicators product={product} />
          </div>
        </div>
        <div className='comparison-field-mobile'><span>Member Cost</span></div>
        {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) && 
        <div className='product-field-bold row6 mobcompprice'>        
          <MaskPrice
            getprefer = {(this.props.getprefer && this.props.getprefer.customerView) ? this.props.getprefer.customerView: 'c1'}
            viewType={this.props.viewType}
            fieldVal={utilities.getCostForQtyPlpNew(
                  product,
                  quantity
                )}
            field='Member Cost'
          />        
          {(product.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW)
              && <TierIcon 
                tierLevel={utilities.getCostForQtyPlpNew(product ,quantity, true)}
                tierLetter={quantity && parseInt(quantity) > 0 ? true : false} />
          }
        </div>
        }
         <div className='comparison-field-mobile'><span>Pack</span></div>
        <div className='product-field-bold row7'>
          {product.S_Pack_Type}
          {product.S_Pack_Qty}
        </div>
        <div className='comparison-field-mobile'><span>Suggested Retail</span></div>
        <div className='product-field-bold row8'>        
          {this.getSuggestedRetail(product)}  
        </div>
        <div className='comparison-field-mobile'><span>Override Retail</span></div>
        {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) && 
        <div className='product-field-bold row10'>
          {' '}
          <div>
              {"$ "}
              <input
                id={'or-' + product.Item_Number}
                className='qty-box'
                label='$'
                name='overrideRetail'
                onChange = {this.handleChange}
                data-field='or'
                data-item-number={index}
                data-pack-type={product.S_Pack_Type}
                data-pack-qty={product.S_Pack_Qty}
                value = {overrideRetail}
              />
          </div>
        </div>
      }
      <div className='comparison-field-mobile'><span>Quantity</span></div>
        <div className='product-field-bold row9'>
          <input
            className='qty-box'
            id={'qty-box' + product.Item_Number}
            onChange = {this.handleChange}
            data-field='qty'
            data-item-number={index}
            data-pack-type={product.S_Pack_Type}
            data-pack-qty={product.S_Pack_Qty}
            value = {quantity}
          />
        </div>
        <div className='product-field-bold row3 restricted-product'>{product.Restrictions && product.Restrictions.length > 0 ? utilities.restrictedDetail(product?.Restrictions) : ''}</div>
        {this.props.multiStoreDetails.access !== 1  && 
        <>
        {!this.props.isReunionOnlyStore ?
        <div className='product-field-bold'>
          <button
            className='fluid ui red button'
            id='rounded-add-to-cart'
            onClick={() =>
              this.handleAddToCart(product)
            }>
            Add to Cart
          </button>
     
        </div>
        :<>
          <button className='fluid red ui button' disabled>Add to Cart</button>
          <p class="disabledMessage">*Ordering restricted</p>
        </>
        }
        </>
         }
        <div className='comparison-field-mobile'><span>Description</span></div>
        <div className='product-field-bold long-desc'>
        {product.Product_Title || product.Long_Description || product.Product_Name}
        </div>
        <div className='comparison-field-mobile'><span>Length</span></div>
        <div className='product-field-bold field-value row13'>
          {product.Length} IN
        </div>
        <div className='comparison-field-mobile'><span>Width</span></div>
        <div className='product-field-bold field-value row14'>
          {product.Width} IN
        </div>
        <div className='comparison-field-mobile'><span>Height</span></div>
        <div className='product-field-bold field-value row15'>
          {product.Height} IN
        </div>
        <div className='comparison-field-mobile'><span>Weight</span></div>
        <div className='product-field-bold field-value row16'>
          {product.weight} lbs
        </div>
        {!isCustomerView &&
        <>
          <div className='comparison-field-mobile'><span>UPC #</span></div>
          <div className='product-field-bold field-value row17'>{product.UpcNum}</div>
          <div className='comparison-field-mobile'><span>Secondary UPC#</span></div>
          <div className='product-field-bold field-value row18'>
            {utilities.hyphenForNull(product.SecondaryUpcNumber)}
          </div>
          <div className='comparison-field-mobile'><span>Master Pack</span></div>
          <div className='product-field-bold field-value row19'>
            {product.MasterPack}
          </div>
          <div className='comparison-field-mobile'><span>Inner Pack</span></div>
          <div className='product-field-bold field-value row20'>
            {product.InnerPack}
          </div>
          <div className='comparison-field-mobile'><span>Hazmat</span></div>
          <div className='product-field-bold field-value row21'>{product.Hazmat}</div>
          <div className='comparison-field-mobile'><span>Small Parcel</span></div>
          <div className='product-field-bold field-value row22'>{product.FedEx}</div>
        </>
        }
        <div className='comparison-field-mobile'><span>Country Of Origin</span></div>
        <div className='product-field-bold field-value row23'>
          {product.CountryOfOrigin}
        </div>
        {!isCustomerView &&
        <>
         <div className='comparison-field-mobile'><span>Perishable</span></div>
          <div className='product-field-bold field-value row24'>
            {product.Perishable}
          </div>
          <div className='comparison-field-mobile'><span>Freezable</span></div>
          <div className='product-field-bold field-value row25'>
            {product.Freezable}
          </div>
        </>
        }
        {product.CategoryAttributes && 
          Object.keys(product.CategoryAttributes).map(function(AttName, index) {
            return(
              <>
                {index == 0 &&  <Divider section />}
                <div className='comparison-field-mobile'><span>{AttName}</span></div>
                <div className={'product-field-bold field-value catatt'+index}>
                  {product.CategoryAttributes[AttName] ? product.CategoryAttributes[AttName] : "-"}
                </div>
              </>
            )
          })
        }
      </div>
      </>
      }
      </>
    );
  }
}

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    favorites: state.FavoritesReducer.favItemNums,
    currentCartId: state.cart.orders.currentCart.cartId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    currentCartType: state.cart.orders.currentCart.cartInfoName,
    whCarts: state.cart.orders.allCarts.filter(cart => cart.cartInfoName === 'REGULAR'),
    getprefer:state.preference.listData,
    rdc:state.SessionReducer.rdc,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    isReunionOnlyStore: state.SessionReducer.address.isReunionOnlyStore,
    onVariablePricing: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.onVariablePricing : false,
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ addProduct, createNAddCart, getFavoriteItems }, dispatch)
  );
};

export default connect( mapStateToProps,mapDispatchToProps)(CompareCard);
