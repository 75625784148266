import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import ScannedProduct from './ScannedProduct'
import BarcodeProduct from "./BarcodeProduct";
import history from '../../history';
import {changeCamera} from './actions';
import { bindActionCreators } from 'redux';
import ScanningDevice from "./ScanningDevice";

class Scanner extends React.Component {
 constructor(props) {
   super(props);
   const code = props?.match?.params?.code ? props?.match.url : false
   this.state = {
      reader: {},
      selectedDevice: this.props.camera,
      videoInputDevices: [],
      result: code ? code: '',
      showScan: code ? false : true
      //result: '24964208906',
      //result: 'https://nwa-uat.truevalue.com/Scan/4|2|2021|93|515|model|RA-T543',
      //result:'http://localhost:3000/Scan/ctb|QUlSIFBVUklGSUVSUw==|N16140000'
      //result:'http://localhost:3000/Scan/ctb|QUlSIFBVUklGSUVSUw=='
      //result:'http://localhost:3000/Scan/ctb|APPLIANCES & ELECTRONICS|AIR PURIFIERS'
      //result:'http://localhost:3000/Scan/ctb|982'
      //showScan: false
   };
 }
 componentDidMount() {
  console.log(this.state.result)
 }
  handleResult=(result)=>{
    this.setState({result, showScan: false})
  }
  recallComponent = () => {
    this.setState({showScan:true})
    history.replace({ pathname:'/Scan' });
  }
 render() {
  {console.log(this.state.result.split('|'))}
   return (
     <>
         {(this.state.showScan)  && <ScanningDevice handleResult ={this.handleResult}/>}
         <div className="scanBtn">{!this.state.showScan && <button className="red ui button" onClick={this.recallComponent}> Scan Next Item </button>}</div>         
         {!this.state.showScan && this.state.result !== "" && (
              this.state.result.split('|').length > 1 ?
               <ScannedProduct code = {this.state.result} />
               : !isNaN(this.state.result) ? <BarcodeProduct code ={this.state.result}/> :
                <p style={{textAlign:'center'}}>Invalid Code, Please Scan Again</p>
          )}
     </>
   );
 }
}

const mapDispatchToProps = dispatch => { 
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {changeCamera},
      dispatch
    )
  );
};

const mapStateToProps = state => {
  return {
    camera: state.MatchingImage.changeCamera,
    userId: state.SessionReducer.UserId,
    storeId: state.SessionReducer.storeId
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Scanner);