import React from "react";
import { Pagination } from "semantic-ui-react";
import { connect } from "react-redux";

import { getCatalogItems, setFilterValue } from "../actions";
import { ALL_WAREHOUSE, MY_WAREHOUSE, finelineCatalogFiltersName } from "../../common/constants";
import { memberCostFilter, suggestedRetailFilter } from '../filters';


const PagesCountComponent = ({
  pagesCount,
  currentPage,
  totalItems,
  firstItem,
  lastItem,
  showHideImages,
  children,
  getCatalogItems,
  isLoading,
  imageState
}) => {
  const onPageChange = (e, { activePage }) => {
    getCatalogItems(activePage);
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="listing-header">
            <span>
              {firstItem}-{lastItem} of {totalItems} products
            </span>
            <span onClick={showHideImages} id="show_and_hide_images">
              {imageState}
            </span>
            <span className="pagination_top">
              {pagesCount > 0 && (
                <Pagination
                  className="pagination"
                  boundaryRange={0}
                  activePage={currentPage}
                  totalPages={pagesCount}
                  onPageChange={onPageChange}
                  ellipsisItem={null}
                  siblingRange={1}
                  firstItem={currentPage === 1 ? null : { content: "«" }}
                  lastItem={
                    currentPage === pagesCount ? null : { content: "»" }
                  }
                  prevItem={currentPage === 1 ? null : { content: "Prev" }}
                  nextItem={
                    currentPage === pagesCount ? null : { content: "Next" }
                  }
                />
              )}
            </span>
          </div>
          {children}
          <span className="pagination_bottom">
            {pagesCount > 0 && (
              <Pagination
                className="pagination"
                boundaryRange={0}
                activePage={currentPage}
                totalPages={pagesCount}
                ellipsisItem={null}
                siblingRange={1}
                firstItem={currentPage === 1 ? null : { content: "«" }}
                lastItem={currentPage === pagesCount ? null : { content: "»" }}
                prevItem={currentPage === 1 ? null : { content: "Prev" }}
                nextItem={
                  currentPage === pagesCount ? null : { content: "Next" }
                }
              />
            )}
          </span>
        </>
      )}
    </>
  );
};

const ViewSelectionComponent = ({
  setFilterValue,
  filters
}) => {
  const handleChangeFilterValue = (name) => (e) => {
    const { value } = e.target;
    setFilterValue(name, value)
  }

  return (
    <>
      <span className={"products_padding"}>
        <span>Show </span>
        {/* <select
          text="My Warehouse"
          className="input-select"
          value={filters[finelineCatalogFiltersName.warehouse]}
          onChange={handleChangeFilterValue(finelineCatalogFiltersName.warehouse)}
        >
          <option className="SelectOption" value={MY_WAREHOUSE}>
            My Warehouse
          </option>
          <option className="SelectOption" value={ALL_WAREHOUSE}>
            All Warehouses
          </option>
        </select> */}
        <select
          onChange={handleChangeFilterValue(finelineCatalogFiltersName.pageSize)}
          value={filters[finelineCatalogFiltersName.pageSize]}
          className="input-select"
        >
          <option className="SelectOption" value={50}>
            50
          </option>
          <option className="SelectOption" value={100}>
            100
          </option>
        </select>
      </span>
      <span className={"products_padding"}>
        <span>{memberCostFilter.filterTitle} </span>
        <select
          text={memberCostFilter.filterTitle}
          className="input-select"
          value={filters[finelineCatalogFiltersName.memberCost]}
          onChange={handleChangeFilterValue(finelineCatalogFiltersName.memberCost)}
        >
          {memberCostFilter.filterOptions.map((opt) => (
            <option className="SelectOption" value={opt.value} key={opt.id}>
              {opt.value}
            </option>
          ))}
        </select>
      </span>
      <span className={"products_padding"}>
        <span>{suggestedRetailFilter.filterTitle} </span>
        <select
          text={suggestedRetailFilter.filterTitle}
          className="input-select"
          value={filters[finelineCatalogFiltersName.suggestedRetail]}
          onChange={handleChangeFilterValue(finelineCatalogFiltersName.suggestedRetail)}
        >
          {suggestedRetailFilter.filterOptions.map((opt) => (
            <option className="SelectOption" value={opt.value} key={opt.id}>
              {opt.value}
            </option>
          ))}
        </select>
      </span>
    </>
  );
}

const mapStateToProps = (state) => {
  const { finelineCatalog } = state;
  const {
    catalog: { catalogItemDescription },
  } = finelineCatalog;

  return {
    isLoading: finelineCatalog.catalog.isLoading,
    pagesCount: catalogItemDescription ? catalogItemDescription.pageCount : 0,
    currentPage: catalogItemDescription
      ? catalogItemDescription.currentPage
      : 0,
    totalItems: catalogItemDescription ? catalogItemDescription.totalItems : 0,
    resultsPerPage: catalogItemDescription
      ? catalogItemDescription.resultsPerPage
      : 0,
    firstItem: catalogItemDescription ? catalogItemDescription.firstItem : 0,
    lastItem: catalogItemDescription ? catalogItemDescription.lastItem : 0,
    filters: finelineCatalog.catalog.filters
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCatalogItems: (pageNumber) => dispatch(getCatalogItems({ pageNumber })),
  setFilterValue: (filterName, filterValue) => dispatch(setFilterValue(filterName, filterValue))
});

const FinelineCategoryComponentsRenderer = {
  pagesCount: connect(mapStateToProps, mapDispatchToProps)(PagesCountComponent),
  selectionView: connect(
    mapStateToProps,
    mapDispatchToProps
  )(ViewSelectionComponent),
};

export default FinelineCategoryComponentsRenderer;
