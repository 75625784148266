import { CHECK_IMAGE,CHANGE_CAMERA} from './actions';

const initState = {
  scannerImg:[],
  checkimage:[],
  scannerSuccess:false,
  changeCamera: ''
}
const checked = (state=initState,{type,payload}) => {
  switch (type) {
    case CHECK_IMAGE:
      if(payload === "No UPC found"){
        return {...state,checkimage:payload,scannerImg:"Please retake not a valid upc",scannerSuccess:false};
      }
      else{
        return {...state,checkimage:JSON.parse(payload),scannerSuccess:true};
      }
    case CHANGE_CAMERA :
      return{...state,changeCamera: payload}
  default :
      return state;
  }
}

export default checked;