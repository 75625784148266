import React from 'react';
import './ImageGallery.css';
import left from '../images/left.png';
import right from '../images/right.png';
import zoom from '../images/zoom.png';
import renderImages from '../common/RenderImages';
import ReactImageMagnify from 'react-image-magnify';
class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      currentSlide: 0,
      showModal: false,
      dimensions: {}
    };
  }

  componentDidMount() {
    this.setState({ total: this.props.images.length });
  }
  chooseThumbnail = e => {
    this.setState({ currentSlide: parseInt(e.target.id) });
  };

  showNext = () => {
    this.setState(prevState => {
      return {
        currentSlide:
          prevState.currentSlide + 1 === prevState.total
            ? 0
            : prevState.currentSlide + 1
      };
    });
  };

  showPrev = () => {
    this.setState(prevState => {
      return {
        currentSlide:
          prevState.currentSlide === 0
            ? prevState.total - 1
            : prevState.currentSlide - 1
      };
    });
  };

  toggleModal = () => {
    this.setState(prevState => {
      return { showModal: !prevState.showModal };
    });
    window.addEventListener('keydown', (e) => {
      if(e.keyCode === 27) {
          if(this.state.showModal) {
            this.setState(prevState => {
              return { showModal: !prevState.showModal };
            });
          }
      }
      else if(e.keyCode === 37) {this.showPrev()}
      else if(e.keyCode === 39) {this.showNext()}
    })
  };

  render() {
    const { images, altUrl } = this.props;
    const getMeta = (url, cb) => {
      const img = new Image();    
      img.src = url;
      return img;
    };
   
    if(!images || images.length === 0){
      return <></>;
    }
    let currentSlide = parseInt(this.state.currentSlide);
    const img = getMeta(images[currentSlide].toString().replace("ColorImageSize=2","ColorImageSize=3"));
    const ModalView = (
      <div className='image-modal'>
        <div className='close cursor' onClick={this.toggleModal} >
          &times;
        </div>
        <div className='modal-content'>
          <div className='image-wrap-modal' style={{margin:"0 auto",maxWidth:400}}>
            <ReactImageMagnify {...{
              smallImage: {
                  alt: this.state.currentSlide,
                  src: images[currentSlide].toString().replace("ColorImageSize=2","ColorImageSize=3"),
                  width: img.naturalWidth? img.naturalWidth + 70:400,
                  height: img.naturalHeight?img.naturalHeight + 70:400
              },
              largeImage: {
                  src: images[currentSlide].toString().replace("ColorImageSize=2","ColorImageSize=3"),
                  width: img.naturalWidth?img.naturalWidth* 3 + 70:900,
                  height: img.naturalHeight?img.naturalWidth* 3 + 70:900
              },
              enlargedImagePosition: 'over'                             
            }}/>
          </div>
          <div className={(this.state.total > 1) ? 'prev': 'prev hideArrow'} onClick={this.showPrev} style={{ left: '0' }} >
            &#10094;
          </div>
          <div className={(this.state.total > 1) ? 'next': 'next hideArrow'} onClick={this.showNext} style={{ right: '0' }} >
            &#10095;
          </div>
        </div>
        <div className='modal-footer'>
          <div className='caption-container'></div>

          <div className='thumbnail-img-wrap'>
            {images.map((imgUrl, i) => {
              let className = (i === currentSlide ? 'thumbnail-img current' : 'thumbnail-img');
              return renderImages.getImage({
                src: imgUrl,
                id: i,
                className,
                alt: 'thumbnail',
                onClick: this.chooseThumbnail,
                altUrl
              });
            })}
          </div>
        </div>
      </div>
    );
    return (
      <div
        className='image-gallery-wrap two column row d-inline-center'
        style={{ height: 'auto', width: 'auto' }}
      >
        {this.state.showModal ? ModalView : <></>}
        <div className='one wide column thumbnail-holder'>
          {images.map((image, i) => {
            let className = i === currentSlide ? 'thumbnail-img current' : 'thumbnail-img';
            return renderImages.getImage({
              altUrl,
              src: image,
              id: i,
              className,
              alt: 'thumbnail',
              onClick: this.chooseThumbnail
            });
          })}
        </div>
        <div className='image-wrap d-inline-center'>
          <span>
            {renderImages.getImage({
              src: left,
              altUrl,
              className: (this.state.total > 1) ? 'left-nav': 'left-nav hideArrow',
              alt: 'left',
              onClick: this.showPrev
            })}
          </span>
          <div className='img-span' >
            <img
              className='zoom-img'
              src={zoom}
              alt='zoom'
              onClick={this.toggleModal}
            />
            {renderImages.getImage({
              altUrl,
              src: images[currentSlide].toString().replace("ColorImageSize=2","ColorImageSize=3"),
              style: { width: '21.7rem', height: '24rem' },
              id: 'selected-img',
              alt: this.state.currentSlide,
              onClick: this.toggleModal
            })}
            
          </div>
          <span>
            {renderImages.getImage({
              altUrl,
              src: right,
              className: (this.state.total > 1) ? 'right-nav': 'right-nav hideArrow',
              alt: 'right',
              onClick: this.showNext
            })}
          </span>
        </div>
      </div>
    );
  }
}

export default ImageGallery;
