export const breadcrumbLinks = [{ link: '/home', text: 'Home' }];

export const colDefs = [
  {
    header: 'Item #',
    key: 'item_no'
  },
  {
    header: 'PO #',
    key: 'po_number'
  },
  {
    header: 'Invoice #',
    key: 'invoice_number'
  },
  {
    header: 'Item Description',
    key: 'short_desc'
  },
  {
    header: 'Pack',
    key: 'pack'
  },
  {
    header: 'RDC',
    key: 'RDC'
  },
  {
    header: 'Qty On Order',
    key: 'qty'
  },
  {
    header: 'Old Cost',
    key: 'oldCost'
  },
  {
    header: 'New Cost',
    key: 'cost'
  },
  {
    header: 'Ext. Cost',
    key: 'ext_cost'
  },
  {
    header: 'Cost Change Date',
    key: 'costChangeDate'
  },
  {
    header: 'Status',
    key: 'status'
  },
  {
    header: 'Ship Later Age',
    key: 'shiplaterAgeDisplay'
  },
  {
    heading: 'Select All',
    key: 'action:Remove'
  }
];

export const sortOptions = [
  {
    text: 'Item #',
    value: 'item_no'
  },
  {
    text: 'PO #',
    value: 'po_number'
  },
  {
    text: 'Invoice #',
    value: 'invoice_number'
  },
  {
    text: 'Cost Change Date',
    value: 'costChangeDate'
  },
  {
    text: 'Ship Later Age',
    value: 'shiplaterage'
  },
  {
    text: 'Cost Change Indicator',
    value: 'costchangeindicator'
  },
  {
    text: 'Status',
    value: 'status'
  },
];


export const searchOptions = [
  {
    text: 'Item #',
    value: 'item_no'
  },
  {
    text: 'Model #',
    value: 'model'
  },
  {
    text: 'PO #',
    value: 'po_number'
  },
  {
    text: 'Invoice #',
    value: 'invoice_number'
  }
];

export const orderOptions = [
  {
    text: 'Ascending',
    value: 'asc'
  },
  {
    text: 'Descending',
    value: 'desc'
  }
];
