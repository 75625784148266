import utilities from '../../../utils/utilities';

export const getEventRedirectUrl = ({ keyParams }) => {
  let urlParams = {};
  for(let property in keyParams){
    urlParams = {...urlParams,[property]:encodeURIComponent(keyParams[property])}
  }

  switch (urlParams.pageName) {
    case 'PromoListing':
      return `$/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.fromReunionHome}`;
    case 'PromoListingBackUrl':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.fromReunionHome}`
    case 'VendorListing':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendors/${urlParams.promoName}`;
    case 'PalletListing':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/${urlParams.promoName}/vendor/${urlParams.vendorId}/${urlParams.vendorName}/palletListing/`;
    case 'PalletItemListing':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendor/${urlParams.vendorId}/${urlParams.vendorName}/palletItemListing/${urlParams.palletNumber}/${urlParams.promoName}`;
    case 'AssortmentListing':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/eventName/${encodeURIComponent(urlParams.eventName)}/${urlParams.relayNbr}/${urlParams.fromReunionHome}/assortments`;
    case 'AssortmentItemListing':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/eventName/${urlParams.eventName}/${urlParams.relayNbr}/${urlParams.fromReunionHome}/assortments/${urlParams.assortmentId}/${urlParams.assortmentMdlNbr}`;
    case 'AllItems':
      return `/reunionOrdering/items/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.promoName}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendor/`;
    case 'PalletAllItems':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/${urlParams.promoName}/palletListing`;
    case 'VendorItems':
      return `/reunionOrdering/items/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.promoName}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendor/${urlParams.vendorId}/${urlParams.vendorName}`;
    case 'ItemListing':
      return `/reunionOrdering/items/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.promoName}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendor/${urlParams.vendorId}/${urlParams.vendorName}`;
    case 'AllVendorDropShipItems':
      return `/reunionOrdering/dropShipItems/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.promoName}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendor/`;
    case 'VendorDropShipVendorListing':
      return `/reunionOrdering/dropShipVendors/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendors/${urlParams.promoName}`;
    case 'DropShipVendorItems':
      return `/reunionOrdering/dropShipItems/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.promoName}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendor/${urlParams.vendorId}/${urlParams.vendorName}/${urlParams.eventSupplierSelectionID}`;
    case 'DropShipPalletAllItems':
      return `/reunionOrdering/dropShipPalletItems/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/${urlParams.promoName}/dropShipPalletListing`;  
    case 'DropShipPalletItemListing':
      return `/reunionOrdering/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendor/${urlParams.vendorId}/${urlParams.vendorName}/dropShipPalletItemListing/${urlParams.palletNumber}/${urlParams.promoName}/${urlParams.palletId}/${urlParams.eventSupplierSelectionID}`;
    case 'VendorListingDropShipDiscountCity':
      return `/reunionOrdering/dropShipVendorsDC/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/vendors/${urlParams.promoName}`; 
    case 'PalletDropShipDCVendorItems':
      return `/reunionOrdering/dropShipVendorItems/${getShoppingNameKey(urlParams.shoppingAreaName)}/${urlParams.shoppingAreaId}/${urlParams.flowType}/events/${urlParams.eventId}/${urlParams.relayNbr}/${urlParams.relayYear}/${urlParams.fromReunionHome}/${urlParams.promoName}/vendor/${urlParams.vendorId}/${urlParams.vendorName}/dropShipPalletListing/`;
           
  }
}

export const getShoppingNameKey = (shoppingArea) => {
  return (
    utilities.replaceAll(shoppingArea, ' ', '')
  );
}

export const getPageName = (flowType) =>{
  switch(flowType){
    case "1": return "VendorItems";
    case "3": return "PalletListing";
    case "4": return "DropShipVendorItems";
    case "5": return "PalletDropShipDCVendorItems";
  }
}