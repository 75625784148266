import React from 'react';
import { UNMAPPED_PROMOS, UPDATE_UNMAPPED_PROMOS } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { getSelect, getTextNameField, getDatePicker, getTableRow, getTable, getButton } from "../common/ComponentUtilities";
import "../Orders/PromoShip/styles.css";
import { Loader, Responsive} from "semantic-ui-react";
import Bin from '../../images/bin.svg';
import renderImages from '../common/RenderImages';
import "./admin.css"
import _ from 'lodash'
import utilities from '../../utils/utilities';
import {getExcelUploadField} from '../common/CustomComponents';
import { downloadExcel, readExcelData } from '../common/GenericExcelExport/exportUtils';
export const PROMO_LINKING_REQ_FIELDS = ['relayNbr','relayYear','promoNbr','promoName','shoppingId','flowTypeId'];
export const PROMO_LINKING_TEMPLATE_NAME = 'PromoLinkingTemplate';
export const PROMO_LINKING_TEMPLATE = [{'Relay No':'','Relay Year':'', 'Promo No':'', 'Promo Name':'', 'Start Date':'','End Date':'','Shopping Area':'','Flow Type':''}];
export const columnDefs = {0:'relayNbr', 1:'relayYear', 2:'promoNbr', 3: 'promoName', 4:'startDate', 5:'endDate', 6:'shoppingId', 7:'flowTypeId'};
class PromoLinking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      loading: false,
      shoppingArea : {},
      flowType : {},
      fileName:''
    };
    this.file = '';
  }

  componentDidMount() {
    this.getPromoData();
  }

  getPromoData = () => {
    this.setState({ loading: true, disableSave: true});
    restUtils.getData(UNMAPPED_PROMOS)
      .then(response => {
        // Get unampped promos  
        const rows = response.data.nnwPromosList;

        //Get shopping areas
        const shoppingArea = response.data.shoppingAreaList?.map(item => ({ text: item.shoppingName, props: { value: item.shoppingId, id: item.shoppingId } }));
        shoppingArea.unshift({ text: "Select...", props: { value: undefined, id: "default" } });

        //Get flow types
        const flowType = response.data.flowTypeList?.map(item => ({ text: item.flowName, props: { value: item.flowId, id: item.flowId } }));
        flowType.unshift({ text: "Select...", props: { value: undefined, id: "default" } });

        rows.forEach(element => {
          element["shoppingArea"] = shoppingArea;
          element["flowType"] = flowType;
        });

        this.setState({ rows ,shoppingArea, flowType,loading: false, disableSave: false});
      })
      .catch(error => console.log(error))
      .finally(this.setState({ loading: false }));
  }

  getShoppingAreaHeaders = () => {
    const tableHeader = [           
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Shopping Area' },
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Flow Type' },
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Promo No.' },      
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Promo Name' },  
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Start Date' },
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'End Date' },  
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Relay No.' },
      { props: { className: 'rowHeader', id: 'no_border' }, text: 'Relay Year' },       
      { props: { className: 'rowHeader', id: 'title_width' }, text: '' }
    ];
    return (getTableRow({}, tableHeader, true))
  }

  getShopingAreaRow = (clas, row, index) => {
    if(row.status != "D"){
      const tableDataCols = this.buildRowData(row, index);
      //let promoClassname = (promoShipOrderList?.orderList?.length === index+1) ? '' : 'promoRowdisable';
      return (
        <>
          {getTableRow({}, tableDataCols, false, 'tableRow')}
        </>
      )
    }
  }

  textOnChange = (e) => {
    const dat = e?.target?.id.split("-");
    if((dat?.includes('relayNbr') || dat?.includes('relayYear') || dat?.includes('promoNbr')) && isNaN(e?.target?.value)){
      return;
    }
    this.updateRowState(e.target);
  }

  updateRowState = (target) => {
    const dat = target.id.split("-");
    const { rows } = this.state;
    let value =  target.value;
    if(dat[1] === 'startDate' || dat[1] === 'endDate')
    {
      value = new Date(target.value).toLocaleString().replace(/[^ -~]/g,'');
    }
    rows[parseInt(dat[0])][dat[1]] = value;
    if (rows[parseInt(dat[0])]["status"] !== "I") {
      rows[parseInt(dat[0])]["status"] = "U";
    }
    this.setState({ rows });
  }

  datePickerOnChange = (id,value) => {
    if(!value || value.length === 0) {
      return;
    } 
      this.updateRowState({id,value});
  }

  rowTextComp = (id, value, fieldClass, loadMore, disabled = false) => {
    return getTextNameField(id, id, value, this.textOnChange, undefined, fieldClass, false, loadMore, disabled);
  }

  rowDatePickerComp = (id, value,fieldClass, isLabel,fieldName) => {   
    return getDatePicker(fieldName, id, value ? new Date(value) : null, this.datePickerOnChange, undefined, fieldClass, isLabel);
  }

  downloadTemplate = () => {
    downloadExcel(PROMO_LINKING_TEMPLATE, `${PROMO_LINKING_TEMPLATE_NAME}.xlsx`);
  }

  chooseFile = (e)=>{
    const files = e.target.files[0];
    let fileReader;
    const reader = new FileReader();
    reader.onload = (e) => { 
      this.file = reader.result;
    };
    reader.readAsBinaryString(files);
    this.setState({
      fileName: files.name
    });
  }

  uploadFile = (e) => {
    let fileType = this.state.fileName.split(/\.(?=[^.]+$)/);
    let {rows, metadata,shoppingArea, flowType} = this.state;
    if(fileType[1] !== 'xlsx') {
      utilities.showToast('Kindly upload .xlsx file');
    }else {      
      this.setState({disableSave: true, loading: true});
      let data = readExcelData(this.file,{header:1,blankrows:false,raw:false,dateNF:'MM/dd/yy hh:mm:ss'});
      data.forEach((row,index) => {
        let newRow = {};
        if(index !== 0){
          Object.keys(row).forEach(key => {
            let value = row[key].toString().trim();
            if(columnDefs[key] === 'shoppingId'){
              let shoppingMetaData = shoppingArea.filter( meta => { return meta.text.toUpperCase() === value.toUpperCase()})
              value = shoppingMetaData.length > 0 ? shoppingMetaData[0].props.value : '';
            }
            else if(columnDefs[key] === 'flowTypeId'){
              let flowTypeData = flowType.filter( meta => { return meta.text.toUpperCase() === value.toUpperCase()})
              value = flowTypeData.length > 0 ? flowTypeData[0].props.value : '';
            }else if((columnDefs[key] === 'relayNbr' || columnDefs[key] === 'relayYear' 
                                  || columnDefs[key] === 'promoNbr') && isNaN(value)){
              value = '';
            }
            newRow = {...newRow, ...{[columnDefs[key]] : value}};
          });
          rows.push({...newRow, ...{shoppingArea,flowType,metadata, status:'I'}});
        }
      });
      this.setState({
        rows,
        disableSave: false, 
        loading: false
      });
    }
  };

  buildRowData = (row, index) => {
    return (
      [ 
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.getMetadataFields(`${index}-shoppingId`, row.shoppingId, row.shoppingArea, row.status === 'I' ? false : 'disabled') },
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.getMetadataFields(`${index}-flowTypeId`, row.flowTypeId, row.flowType, row.status === 'I' ? false : 'disabled') },
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.rowTextComp(`${index}-promoNbr`, row.promoNbr, 't-center date-admin', undefined, row.status === 'I' ? false : 'disabled') },
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.rowTextComp(`${index}-promoName`, row.promoName, 't-center date-admin') }, 
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.rowDatePickerComp(`${index}-startDate`,row.startDate,'t-center date-admin')},
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.rowDatePickerComp(`${index}-endDate`,row.endDate,'t-center date-admin')},
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.rowTextComp(`${index}-relayNbr`, row.relayNbr, 't-center date-admin') },         
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.rowTextComp(`${index}-relayYear`, row.relayYear, 't-center date-admin', this.loadMore) },       
        { columnProps: { className: 'tableCell', id: 'field_width' }, columnText: this.getDeleteIcon(`${index}-del`) }
      ]
    );
  }

  deleteShopping = (e) => {
    const index = parseInt(e.target.id.split("-")[0]);
    const { rows } = this.state;
    if (rows[index]["status"] === "I") {
      rows.splice(index, 1);
    } else {
      rows[index]["status"] = "D";
    }
    this.setState({ rows });
  }

  getDeleteIcon = (id) => {
    return renderImages.getImage({
      id,
      src: Bin,
      alt: 'bin',
      className: 'delete-icon',
      onClick: this.deleteShopping
    });
  }
  
  getMetadataFields = (id, value, metadata, disabled = false, loadMore) => {
    const selectProps = { className: 'sort-criteria combo select-admin', onChange: this.changeMetadata, id: id, value: value,onKeyDown : loadMore, disabled : disabled };
    return getSelect(selectProps, metadata);
    
  }

  changeMetadata = (e) => {
    this.updateRowState(e.target);
  }

  loadMore = (e) => {
    const dat = e.target.id.split("-");
    const { rows, metadata,shoppingArea, flowType} = this.state;
    if (parseInt(dat[0]) === rows.length - 1 && e.keyCode === 9) {      
      rows.push({relayNbr:'',relayYear:'',promoNbr:'',metadata, shoppingArea, flowType, status:'I'});
      this.setState({ rows });
    }
  };

  validateRequiredField = (row) => {
    return PROMO_LINKING_REQ_FIELDS.every(field=> _.has(row, field) &&
                  !utilities.isEmptyOrNullString(row[field]) && row[field] !== 'Select...');
  }

  saveShopping = () => {
    const { rows } = this.state;
    let isValid = true;
    rows.forEach(row => {
      if((row.status === "I" || row.status === "U") &&
          !this.validateRequiredField(row)) {
        isValid = false;
        return;
      }       
    })
    if(!isValid){
      utilities.showToast(`Please enter all required fields`, true);
      return;
    }
    const saveData = rows.filter(row => {
      return row.status === "I" || row.status === "D" || row.status === "U"
    });

    this.setState({ loading: true });
    restUtils.postData(UPDATE_UNMAPPED_PROMOS, saveData)
      .then(response => {
        utilities.showToast(`Promo link data updated successfully`);
        this.getPromoData();
      })
      .catch(error => console.log(error))
      .finally(this.setState({ loading: false }));
  }

  uploadPromoLinkingList = () => {
    return (
    <div className='upload-link'>
      <div className='displayFlex page-header mb-2'>
          Promo Linking
      </div>
      <div className='displayFlex'>
        {getExcelUploadField("Choose File", "upload-file", this.state.fileName , this.chooseFile, 'custom-input-btn', 'custom-file-input',this.downloadTemplate)}       
      </div>
      <div className='displayFlex pt-3'>
        <div className='pl-15'>
          {getButton({type:'button', className:'fluid ui red button submit', name:'uploadFile', onClick:this.uploadFile, disabled:this.state.disableSave}, 'Submit')}
        </div>
      </div>
    </div>
    );
  }

  render() {
    const { rows, loading, reunion } = this.state;

    return (
      <>
      {loading ? (
          <div style={{ "padding": "5rem" }}>
            <Loader active />
          </div>
        ): (
          <>
          <div className='displayFlex'> 
            <div>
                {this.uploadPromoLinkingList()}
            </div>
          </div>
          {this.state?.rows?.length > 0 &&
          <div className='overFlow'>
          {getTable({ className: "ui celled table all-wh-table promo-table", id: 'font-size-orders' }, this.getShoppingAreaHeaders(), rows, this.getShopingAreaRow, { className: 'wh-row' })}
          {getButton({type:'button', className:'ui red button place-order-btn savePromoLinking', name:'saveShp', onClick:this.saveShopping, disabled:this.state.disableSave}, 'Save')} 
          </div>  
          }        
        </>
      )}
    </>
    );
  } 
}

export default PromoLinking;