import React, { Component } from 'react';
import desktopImg from '../images/Desktop.jpg';
import mobileImg from '../images/Mobile.jpg';
import './styles.css';

var imageStyle = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${mobileImg})`,
    height: '768px'
}

var imageStyleMobile = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${desktopImg})`,
    height: '768px'
}

class UnderConstruction extends Component {
  render() {
    return (
      <div style={window.innerWidth<=768 ? imageStyle : imageStyleMobile }>
        <div className='UnderDevelopment'>
          <p>{this.props.shoppingAreaName}</p>
          <p>{this.props.eventMessage}</p>
        </div> 
      </div>
    );
  }
}
 
export default UnderConstruction;