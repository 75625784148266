import React from 'react'
import history from '../../history';
import Categories from '../Categories'
import { connect } from 'react-redux'
import { RegionDetails } from '.';
import { isArray } from 'util';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import utilities from '../../utils/utilities'


class DOMProductListing extends React.Component{
    constructor(props){
        super(props);
        const {zoneCode} = props;
        this.state = {
            region:zoneCode?
                        isArray(zoneCode)&& zoneCode.length>0
                            ?zoneCode[0]
                            :zoneCode
                        :"",
            promoNbr : props.match?props.match.params.promoNbr:"",
            promo_name : ( this.props.location && 
                            this.props.location.state && 
                            this.props.location.state.promoName &&
                            this.props.location.state.promoName != null &&
                            this.props.location.state.promoName != '') ? 
                                this.props.location.state.promoName.trim()
                                    :'',
            order_DeadLine: ( this.props.location && 
                                this.props.location.state && 
                                this.props.location.state.endDate &&
                                this.props.location.state.endDate != null &&
                                this.props.location.state.endDate != '') ? 
                                    this.props.location.state.endDate.trim()
                                        :'',
        }
    }
    showCircular=()=>{
        history.push({
            pathname:'/preview-circular/'+this.state.promoNbr,
            state:{
                zoneCode:this.state.region
            }
        })
    }

    changeRegion=(e)=>{
        this.setState({region:e.target.value});
    }

    changeRegion=(e)=>{
        this.setState({region:e.target.value});
    }

    render(){
        const breadcrumbPath = [{ link: '/home', text: 'HOME' },
                                { link:'/retail-events',text:'RETAIL EVENTS'},
                                { link:this.props.location && this.props.location.pathname,text:'#'+this.state.promoNbr+' '+this.state.promo_name}
                            ];
        return(
            <div className='page-wrap'>
                <div className='page-header'>Retail Events - #{this.state.promoNbr} {this.state.promo_name}</div>
                <BreadcrumbComp path={breadcrumbPath}/>
                <div className='monthlyFields'>#{this.state.promoNbr} {this.state.promo_name}  &nbsp;&nbsp;Order Deadline: {utilities.getDateStringFromTimeStamp(this.state.order_DeadLine)} </div>
                <br/>
                <div>
                    <span className='region-label-dom'>Region : <RegionDetails 
                                                                        region={this.state.region}
                                                                        storeId={this.props.storeId}
                                                                        zoneCode={this.props.zoneCode} 
                                                                        zoneDesc={this.props.zoneDesc}
                                                                        changeRegion={this.changeRegion}/>
                    </span>
                    <span className='preview-circular-link'
                            onClick={this.showCircular}>Preview Circular</span>
                </div>
                <Categories 
                    zoneCode={this.state.region}
                    assortmentType='DOM' 
                    promoNbr = {this.state.promoNbr} 
                    assortmentId={this.state.promoNbr}
                    aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}/>
                <br/>
                <div className="dom-footer thirteen wide computer twelve wide tablet column compact_padding">
                    <table className="dom-table">
                        <tbody>
                            <tr height="150">
                            <td colspan="2" width="100%" bgcolor="#ffffff">
                            <div className='icon-space'>                               
                                <span className='indicator-text'>WSL</span> = While Supplies Last
                                <br/>
                                <span className='indicator-text'>IM</span> = Import
                                <br/>
                                <span className='indicator-text'>BOM</span> = Bargain of the Month.
                                <br/>
                                <span className='indicator-text'>REB</span> = Rebate Item. TV Rewards customers will receive an Instant Rebate with their TV Rewards card.
                                <br/>  
                                <span className='indicator-text'>FE</span> = Featured 
                                <span className="bold-text">SH</span> = Shown 
                                <span className="bold-text">LL</span> = Line Listed 
                                <span className="bold-text">MC</span> = Mentioned in Copy 
                                <span className="bold-text">OF</span> = Order Form 
                                <span className="bold-text">NS</span> = Not Shown
                            </div>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const domStorage = state.DomReducer;
    return{
        storeId:state.SessionReducer.storeId,
        zoneCode :domStorage.zoneCode,
        zoneDesc:domStorage.zoneDesc,
        aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
    }
}

export default connect(mapStateToProps,null)(DOMProductListing);