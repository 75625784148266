import React, { Component } from 'react';
import './styles.css';
import { logout } from '../../views/Login/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRedirectReunionApp } from '../../urlConstants';

class ReunionApp extends Component {

  render() {
    const iframeSrc = getRedirectReunionApp()+'MemberNumber='+this.props.storeId+'&EmailAddress='+this.props.primaryEmail+'&ZipCountry='+this.props.zipCode+'&isInternational=0&NetwarehouseID='+this.props.userId;
    return <iframe id="myIframe" src={iframeSrc}  className='reunion-app' title='reunion-app'></iframe>;  
  }
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const userId = state.SessionReducer.UserId;
  const zipCode = state.SessionReducer.address.postalCode.split('-')[0];
  const primaryEmail = state.SessionReducer.address.primaryEmail; 

  return {
    storeId,
    userId,
    zipCode,
    primaryEmail
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ logout }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReunionApp);
