export const GET_RA_CATEGORIES = "GET_RA_CATEGORIES";
export const GET_RA_REG_COST = "GET_RA_REG_COST";
export const getRaCategories = assortment => ({
    type: GET_RA_CATEGORIES,
    payload: assortment
});

export const saveRegCost = regCost => ({
    type: GET_RA_REG_COST,
    payload: regCost
});

