import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Table, Checkbox, Grid } from 'semantic-ui-react';
import QuickViewDetails from './QuickViewDetails';
import Dialog from 'react-dialog';
import _, { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import renderImages from '../common/RenderImages';
import { TierIcon, ProductIndicators } from './TierIcon';
import { MaskPrice } from './MaskPrice';
import { computeGrossMargin } from '../ImpulseMerchandising/POG';
import { closeModal, addimg, delimg, validateQty } from './productHandler';
import utilities,{maskPrice,getPrefernceType,shouldHideCostForPreference,shouldHideOrigAndCurrCostForPreference,
  shouldHideSUGG_RETAILForPreference,showSRPMaskPricePref,getCloseoutRegularCost,getCloseoutCurrentCost} from '../../utils/utilities';
import AddToCartModalClassicView from '../Cart/AddToCartModalClassicView'
import history from '../../history';
import { nonStockMessage } from './constants';
import { orderTypes, addProduct, createNAddCart, classicAddProduct, classicCreateNAddCart } from '../Cart/actions';
import { orderNames, infoTypes } from '../Cart/constants';
import BusinessRules from './BusinessRules';
import './ClassicView.css';
import { 
  DISCONTINUED, 
  CLOSEOUTS, 
  IMPULSE_MERCHANDISING, 
  RETAIL_ASSORTMENTS, 
  HOTDEALS, 
  MONTHLY_SPECIALS,
  HIDE_QUICKVIEW_PAGE,
  TV_PRODUCT_SCAN,
  COMPETITOR_PRODUCTS,
  PLANOGRAMS
} from '../Categories/constants';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import config from '../../config';

export const scanListing = [TV_PRODUCT_SCAN, COMPETITOR_PRODUCTS];
export const assortmentListing = [RETAIL_ASSORTMENTS,PLANOGRAMS,'DOM',IMPULSE_MERCHANDISING];

class ProductClassicView extends Component {
    constructor(props) {
      super(props);
      this.closeModal = closeModal.bind(this);
      this.addimg = addimg.bind(this);
      this.delimg = delimg.bind(this);
  
      this.state = {
        visible: false,
        show: false,
        productFav:'',
        productComp:'',
        item: '',
        quickviewdata: '',
        click: '',
        expand: '',
        productSelectedForModal: null,
        indexForModal: 0,
        indexForModalMs:0,
        isMultistoreModalOpen:false,
        isAddToCartModalOpen: false,
        viewOnly: this.props.viewType,
        customerViewHeader: [],
        visibleAddCart: utilities.isQuantityAvaliable(this.props.quantities),
        isMaxQty: this.props.categoryData.some(ele => parseInt(ele.maxQty) > 0),
        toastMessage: '', //  Product added to cart..
      }
      this.itemRef = {};
    }
    componentWillReceiveProps(nextProps){
      if(this.props.quantities !== nextProps.quantities){
      this.setState({visibleAddCart: utilities.isQuantityAvaliable(nextProps.quantities)})
      }
      if(this.props.categoryData !== nextProps.categoryData){
        this.setState({isMaxQty: nextProps.categoryData.some(ele => parseInt(ele.maxQty) > 0)})
      }
    }
    
    getLongDesc = (product,isDOM) => {
      let Item_num = product.Item_Number;
      let longDesc = '';
      if(isDOM){
        if(config.showMediumDesp){
          longDesc = product.Product_Title  || product.short_desc ||  product.Product_Name;
        }
        else{
          longDesc = product.short_desc ||  product.Product_Name;
        }
      }
      else{
        if(config.showMediumDesp){
          longDesc = product.Product_Title || product.Long_Description ||  product.Product_Name;
        }
        else{
          longDesc = product.Long_Description;
        }
      }
      return(
        longDesc && (
          <p className='listingDescription'>
            {longDesc.length < 50
              ? this.highlightText(longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase()) 
              : this.highlightText(longDesc.substr(0,1).toUpperCase() + longDesc.substr(0, 42).substr(1,42).toLowerCase()  + '...')}
            <u className='cursor_p'
                onClick={() => this.expand(Item_num)}
            >
              {longDesc.length < 50
                ? ''
                : 'more'}
            </u>
          </p>
        )
      )
    }

    highlightText = (longDesc) => {
      let searchData = (this.props.searchValue && this.props.searchKey === 'Description') ? 
      decodeURIComponent(this.props.searchValue).toLowerCase() : '';
      let index = searchData ? longDesc.toLowerCase().indexOf(searchData) : -1;
      return(
        <React.Fragment>{index!=-1 ? longDesc.substring(0,index):longDesc}<b style={{color: '#CC0000'}}> {index!=-1 ? longDesc.substring(index,index+searchData.length):''}</b>{index!=-1 ? longDesc.substring(index + searchData.length): ''}</React.Fragment>
      )
    }
    
    getLongDescription = (product,isDOM) => {
      let longDesc =  '';
      if(isDOM){
        if(config.showMediumDesp){
          longDesc = product.Product_Title  || product.short_desc ||  product.Product_Name;
        }
        else{
          longDesc = product.short_desc ||  product.Product_Name;
        }
      }
      else{
        if(config.showMediumDesp){
          longDesc = product.Product_Title || product.Long_Description ||  product.Product_Name;
        }
        else{
          longDesc = product.Long_Description;
        }
      }
      
      if(longDesc){
        longDesc =  longDesc.substr(0,1).toUpperCase() + longDesc.substr(1,longDesc.length).toLowerCase()
      }
      else{
        longDesc = "";
      }

      return longDesc;
    }

    closeModalexpand = () => {
      this.setState({
        expand: '',
        item: ''
      });
    };
    enterCompare = (Item_num) => {
      this.setState({
        showCompare: true,
        productComp:Item_num
      })

    }
    leaveCompare = (Item_num) => {
      this.setState({
        showCompare: false,
        productComp:''
      })
    }
    enterFav = (Item_num) =>{
      this.setState({
        show:true,
        productFav:Item_num
      })
    }
    leaveFav  = (product) =>{
      this.setState({
        show:false,
        productFav:''
      })
    }
    expand = num => {
      this.setState({
        expand: 'expan',
        item: num
      });
    };
    openModal(data) {
      this.setState({
        visible: true,
        item: data,
        click: 'clicked',
        expand: ''
      });
      this.props.blurfun && this.props.blurfun(true);
    };
    handleAddToCart = () => {
      const { tableType, classicAddToCart, isShowAllWarehouse, storeId, dataSetValue, userName, rdc, categoryData } = this.props;
      const isAssortmentListing =
        tableType === RETAIL_ASSORTMENTS ||
        tableType === 'Planograms' ||
        tableType === 'DOM' ||
        tableType === IMPULSE_MERCHANDISING ||
        tableType === 'Reunion' ||
        tableType === MONTHLY_SPECIALS;
        let error = false;
        let loop = true
        let isAllEmpty = this.props.quantities.some(ele => ele !== '')
        let isItemNonStock = false;
        this.props.quantities.forEach((element, index) => {
          if(utilities.checkEmptyStringAndArray(element) && loop) {
           const product = categoryData[index]
           if(product && product.whStockStatusMessage && product.whStockStatusMessage.toLowerCase() == 'not stocked'){
            isItemNonStock = true
           }
           if (!this.props.validateProductQuantity(
            product.S_Pack_Type, product.S_Pack_Qty, element, product.maxQty)
            ) {
              this.itemRef[categoryData[index].Item_Number].current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
              this.itemRef[categoryData[index].Item_Number].current.focus();
              error = true;
              loop = false
            }
          }
        });

        if(error) {
          return
        }

      if (
        !isAssortmentListing &&
        this.props.currentCartType !== 'REGULAR' &&
        this.props.whCarts.length > 0
      ) {
        const payLoads = this.props.getClassicViewPayload()
        this.openCartModal(payLoads);
      } else if (
        !isAssortmentListing &&
        this.props.currentCartType !== 'REGULAR' &&
        this.props.whCarts.length === 0
      ) {
        const payLoads = this.props.getClassicViewPayload()
        classicAddToCart(payLoads, 'new')
      } else if (tableType === 'Reunion') {
      } else if (tableType === MONTHLY_SPECIALS) {
        const  carts = this.props.msCarts;
        const eventId = carts && carts.filter(cart => cart.cartName === `EVENT#${this.props.eventId}`)
        let newCart = (carts.length === 0 || eventId.length === 0) ? true : false
        /*addToCart(
          product.Item_Number,
          index,
          product.RelayNbr,
          product.PromoNbr,
          product.vendorID,
          product.S_Pack_Type,
          product.S_Pack_Qty,
          storeId,
          newCart
        );*/
      }else if (tableType === IMPULSE_MERCHANDISING) {
        const imInfoType = orderNames[IMPULSE_MERCHANDISING].infoType;
        const existingCart = this.props.carts.find(
          cart => cart.TypeId === imInfoType
        );
        const payLoads = this.props.getClassicViewPayload()
        if (!existingCart) {
          this.props.classicCreateNAddCart(payLoads, 'REGULAR', imInfoType, orderTypes[imInfoType])
        } else {
          this.props.classicAddProduct(payLoads,existingCart.cartId, existingCart.cartName)
        }
      } else if(tableType === 'DOM'){
        const payLoads = this.props.getClassicViewPayload('DOM')
        classicAddToCart(payLoads,null,null,'DOM')
      }else {
        if (isItemNonStock) {
          utilities.showToast(nonStockMessage, true);
          return;
        } else{
          const payLoads = this.props.getClassicViewPayload()
          classicAddToCart(payLoads)
        }
      }
      
    }
    openCartModal = (product, index) => {
      this.setState({
        productSelectedForModal: product,
        isAddToCartModalOpen: true
      });
    };
    closeCartModal = () => {
      this.setState({ isAddToCartModalOpen: false});
    };
    handleKeyDown = (e) =>{
      const msCheck = utilities.msExist(this.props.multiStoreDetails);
      if(this.props.multiStoreDetails.access !== 1 && e.key == 'Enter' && !this.state.visibleAddCart){
        if(!this.props.isReunionOnlyStore){
          this.handleAddToCart();
        }
      }
    }
    getTableHeader = (products,displayMemberCostAndLbl) => {
      const {
        tableType,
        isShowAllWarehouse
      } = this.props;
      const isDiscontinued = tableType === DISCONTINUED;
      const isReunionListing = tableType === 'Reunion';
      const isMonthlyListing = tableType === MONTHLY_SPECIALS;
      const isDefaultListing = tableType === 'default';
      const isNewItemstListing = tableType === 'NewItems';
      const isCloseoutItems = tableType === CLOSEOUTS;
      const isHotDeals = tableType === HOTDEALS;
      const isImListing = tableType === IMPULSE_MERCHANDISING;
      const isDOMListing = this.props.assortmentType && this.props.assortmentType === 'DOM';
      const getPrefer =  getPrefernceType(this.props);
      const dipslayOrigAndCurrCost = shouldHideOrigAndCurrCostForPreference(this.props.viewType,tableType,getPrefer);
      const showSuggestedRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType , getPrefer);
      return(
      <Table.Header>  
        <Table.Cell></Table.Cell> 
        <Table.Cell><button value = 'Description' className="titleCell" onClick={this.props.changeSortOrder}>Description</button></Table.Cell>
        <Table.Cell><button value = 'VendorName' className="titleCell" onClick={this.props.changeSortOrder}>Vendor</button></Table.Cell>
          {(this.props.multiStoreDetails.access !== 1  || 
          (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) 
          ? (
          <>
              <Table.Cell>
              <button  value='ItemNo' className="titleCell" onClick={this.props.changeSortOrder}>ITEM</button>
              </Table.Cell>
              <Table.Cell>
              <button value={isDOMListing || isReunionListing ||isMonthlyListing ? 'model' : 'ModelNo'} className="titleCell" onClick={this.props.changeSortOrder}>MODEL</button>
              </Table.Cell>
              {
              displayMemberCostAndLbl &&<Table.Cell>
              <button value = 'MBRCost' className="titleCell" onClick={this.props.changeSortOrder}>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}</button>
              </Table.Cell>
              }
              {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
              <Table.Cell>
                <button value = 'alternateSKU' className="titleCell" onClick={this.props.changeSortOrder}>Alternate SKU</button>
              </Table.Cell>  
              )}                                     
          </>
          ) 
          : (
          <>
            <Table.Cell>
              <button  value={(isDefaultListing ||isReunionListing || isMonthlyListing)
                      ? 'item_nbr'
                      : this.props.assortmentType === 'RetailAssortments'
                      ? 'Item_Number'
                      : 'ItemNo'} className="titleCell" onClick={this.props.changeSortOrder}>ITEM</button>
              </Table.Cell>
            
              <Table.Cell>
              <button value={isDOMListing || isReunionListing ||isMonthlyListing ? 'model' : 'ModelNo'} className="titleCell" onClick={this.props.changeSortOrder}>MODEL</button>
              </Table.Cell>
              {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
              <Table.Cell>
                <button value = 'alternateSKU' className="titleCell" onClick={this.props.changeSortOrder}>Alternate SKU</button>
              </Table.Cell>  
              )}                                                    
            
          </>
          )} 
          { showSuggestedRetailPrice && <Table.Cell>
           <button value = 'suggestedRetail' className="titleCell" onClick={this.props.changeSortOrder}>{utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL",getPrefer)}</button>
                                  </Table.Cell>
                                  }

            {(!isShowAllWarehouse || (isShowAllWarehouse && tableType === HOTDEALS)) ? (
              <>
                <Table.Cell>
                <button value = 'Qty' className="titleCell" onClick={this.props.changeSortOrder}>AVAILABLE</button>                                     
                </Table.Cell>
              
              </>
            ) :  (
              <>
                <Table.Cell className=''>
                  PACK
                </Table.Cell>
                </>
            )}
              {!isShowAllWarehouse && (
                <Table.Cell className=''>
                  PACK
                </Table.Cell>
            )}
            {this.state.isMaxQty && (<Table.Cell className=''>
                <span>Max Qty</span>
              </Table.Cell>
            )}
              <Table.Cell className=''>
              <span>OVERRIDE RETAIL</span>
            </Table.Cell>
            
            <Table.Cell className=''>
              QUANTITY
            </Table.Cell>       
      </Table.Header>
      )
    }
  
    getCustomTableViewHeader = (
      product,
      viewType,
      tableType,
      isShowAllWarehouse,
      access,
      CatMbr,
      viewPOG,
      PogMbr,
      multiStoreDetails,
      changeSortOrder,

    ) => {
      const getPrefer =  getPrefernceType(this.props);
      const dipslayOrigAndCurrCost = shouldHideOrigAndCurrCostForPreference(viewType,tableType,getPrefer);
      const showSuggestedRetailPrice  = shouldHideSUGG_RETAILForPreference(viewType,getPrefer);
      const showMemberCostAndLbl =  shouldHideCostForPreference(viewType,getPrefer);
      let tbl_vals = [];
      if (tableType === 'NewItems') {
        tbl_vals = [
          ...((access !== 1 || (access === 1 && CatMbr))  
            ? 
             [
                [
                  { label: 'ITEM', sort: true, value: 'ItemNo' },
                  { label: 'MODEL', sort: true, value: 'ModelNo'  },
                  showMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), sort: true, value: 'MBRCost'  },

                
                  /*multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) && (
                    {label: 'Alternate SKU#' },
                  ),*/
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) && (
                    {label: 'Alternate SKU', sort: true, value: 'alternateSKU' }
                  ),
                  { label: 'DATE ADDED', sort: true, value: 'creationDate'  },
                  showSuggestedRetailPrice && { label: utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer), sort: true, value: 'suggestedRetail' },
                
                  ...(isShowAllWarehouse
                    ? []
                    : [
                        {
                          label: 'AVAILABLE' ,
                          sort: true,
                          value: 'Qty'
                        }
                      ]),
                  {
                    label: 'PACK',

                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'OVERRIDE RETAIL' 
                  },
                  {
                    label: 'QUANTITY' 
                  },
                  
                ]
              ]
              :
              [
                [
                  { label: 'ITEM', sort: true, value: 'ItemNo' },
                  { label: 'MODEL', sort: true, value: 'ModelNo'  },
                  { label: 'DATE ADDED', sort: true, value: 'creationDate'  },
  
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) && (
                    {label: 'Alternate SKU', sort: true, value: 'alternateSKU' }
                  ),
                  {
                    label: 'OVERRIDE RETAIL' 
                  },
                  ...(isShowAllWarehouse
                    ? []
                    : [
                        {
                          label: 'AVAILABLE',
                          sort: true,
                          value: 'Qty'
                        }
                      ]),
  
                  {
                    label: 'PACK' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'QUANTITY' 
                  },
                  showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)  }
                ]
              ])
        ];
      } else if (tableType === DISCONTINUED || tableType === CLOSEOUTS) {
        tbl_vals = [
          ...((access !== 1 || (access === 1 && CatMbr))
            ? [
                [
                  { label: 'ITEM', sort: true, value: 'ItemNo'  },
                  { label: 'MODEL', sort: true, value: 'ModelNo' },
                   dipslayOrigAndCurrCost &&
                   {
                    label: utilities.getLabelsByType(viewType,"REGULAR_COST"), sort: true, value: 'OriginalCost' 
                  },
                  
                
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU#', sort: true, value: 'alternateSKU' }
                 ),
                 showMemberCostAndLbl && {
                  label: utilities.getLabelsByType(viewType,"MEMBER_COST") , sort: true, value: 'MemberCost'
                  }, 
                  
                  showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer), sort: true, value: 'suggestedRetail' },
                
                  ...(isShowAllWarehouse
                    ? [
                        {
                          label: 'DISC. DATE' , sort: true, value: 'DiscontinuedDate'
                        }
                      ]
                    : [
                        {
                          label: 'AVAILABLE' , sort: true, value: 'Qty'
                        }
                      ]),
                  ( viewType === MEMBER_VIEW && {
                    label: '% OFF' , sort: true, value: 'Savings'
                  }),
                 
                 
                  ...(isShowAllWarehouse
                    ? []
                    : [
                        {
                          label: 'DISC. DATE' , sort: true, value: 'DiscontinuedDate'
                        }
                      ]),
                  {
                    label: 'PACK' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'OVERRIDE RETAIL' 
                  },
                  {
                    label: 'QUANTITY' 
                  },
  
                ]
              ]
              :
              [
                [
                  { label: 'ITEM', sort: true, value: 'ItemNo'  },
                  { label: 'MODEL', sort: true, value: 'ModelNo' },
  
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU', sort: true, value: 'alternateSKU' }
                 ),
                  ...(isShowAllWarehouse
                    ? [
                        {
                          label: 'DISC. DATE' , sort: true, value: 'DiscontinuedDate'
                        }
                      ]
                    : [
                        {
                          label: 'AVAILABLE' , sort: true, value: 'Qty'
                        }
                      ]),
                  {
                    label: '% OFF' , sort: true, value: 'Savings'
                  },
  
                  ...(isShowAllWarehouse
                    ? []
                    : [
                        {
                          label: 'DISC. DATE' , sort: true, value: 'DiscontinuedDate'
                        }
                      ]),
                  {
                    label: 'PACK' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'SUBSTITUTE' 
                  },
                 
                     ...(isShowAllWarehouse
                       ? []
                       : [
                     showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)  }
                       ]),
                  {
                    label: 'OVERRIDE RETAIL' 
                  },
                  {
                    label: 'QUANTITY' 
                  } ,
                 ]
              ])
        ];
      } else if (tableType === HOTDEALS) {
        tbl_vals = [
          ...((access !== 1 || (access === 1 && CatMbr))
            ? [
                [
                  { label: 'ITEM' ,sort: true, value: 'ItemNo'},
                  { label: 'MODEL', sort: true, value: 'ModelNo' },
                  dipslayOrigAndCurrCost && {
                    label: utilities.getLabelsByType(viewType,"REGULAR_COST"), sort: true, value: 'OriginalCost' 
                  },
                   multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU' }
                 ),
                  showMemberCostAndLbl && {
                    label: utilities.getLabelsByType(viewType,"MEMBER_COST") , sort: true, value: 'MemberCost'
                  }, 
                  {
                    label: 'AVAILABLE' , sort: true, value: 'Qty'
                  },
                  {
                    label: '% OFF' ,
                    sort: true,
                    value: 'percOff'
                  },
                  
                  {
                    label: 'DISC. DATE' , sort: true, value: 'discontinuedDate'
                  },
                  {
                    label: 'PACK' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                 showSuggestedRetailPrice&& { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer) },
                  {
                    label: 'OVERRIDE RETAIL' 
                  },
                  {
                    label: 'QUANTITY' 
                  },
                ]
              ]
              :
              [
                [
                  { label: 'ITEM', sort: true, value: 'ItemNo' },
                  { label: 'MODEL', sort: true, value: 'ModelNo' },
                  {
                    label: 'AVAILABLE' ,
                    sort: true,
                    value: 'Qty'
                  },
                  {
                    label: '% OFF' ,
                    sort: true,
                    value: 'percOff'
                  },
                  
                  {
                    label: 'DISC. DATE' , sort: true, value: 'discontinuedDate'
                  },
                  {
                    label: 'PACK' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'SUBSTITUTE' 
                  },
                
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU' }) ,
                {
                  label: 'OVERRIDE RETAIL' 
                },
                {
                  label: 'QUANTITY' 
                },
              ],          
              ])
        ];
      }
      else if (tableType === 'Planograms') {
        tbl_vals = [
          ...((access !== 1 || PogMbr)
            ? [
                [
                  { label: 'ITEM', sort: true, value: 'ItemNo' },
                  { label: 'MODEL', sort: true, value: 'ModelNo' },
                  showMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), sort: true, value: 'MBRCost'  },                
                  
                  { label: 'SUGG. QTY'  },
                  {
                    label: 'AVAILABLE'
                  },
                  {
                    label: 'ORDER QTY' 
                  },
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU', sort: true, value: 'alternateSKU' }),
                  { label: 'UPC'  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                
                  {
                    label: 'PACK' 
                  },
                  showSuggestedRetailPrice&& { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)  }
    
                ]
              ]
              :
              [
                [
                  { label: 'ITEM', sort: true, value: 'ItemNo'  },
                  { label: 'MODEL', sort: true, value: 'ModelNo' },
                  {
                    label: 'AVAILABLE' 
                  },
                  {
                    label: 'ORDER QTY' 
                  },
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#' , sort: true, value: 'alternateSKU'}),
                  { label: 'UPC'  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                
                  {
                    label: 'PACK' 
                  },
                  showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer) }
                ]
              ])
        ];
      } else if (tableType === 'RetailAssortments') {
        tbl_vals = [
          ...((access !== 1 ||  (viewPOG && PogMbr))
            ? [
                [
                  { label: 'ITEM', sort: true, value:'Item_Number' },
                  { label: 'MODEL', sort: true, value:'ModelNo'  },
                  { label: 'UPC', sort: false, value:'UpcNum'  },
                  showMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), sort: true, value:'MBRCost'  },
                  
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU', sort: true, value:'alternateSKU' }),               
                  showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)},
                { label: 'QUANTITY' },
              ]
              ]
              :
              [
                [
                  { label: 'ITEM', sort: true, value:'Item_Number' },
                  { label: 'MODEL', sort: true, value:'ModelNo'  },
                
                 multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                 && ({label: 'Alternate SKU', sort: true, value:'alternateSKU' }), 
                 showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)  },
                 { label: 'QUANTITY' },
                ]
                 
              ])
        ];
      } else if (tableType === 'DOM') {
        tbl_vals = [
          ...((access === 1 && (access !== 1 && CatMbr))
            ? [
                [
                  { label: 'ITEM', sort: true, value:'item_nbr' },
                  { label: 'MODEL', sort: true, value:'model' },
               
                  !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                    && ({label: 'Alternate SKU'}),
               
                  {
                    label: 'PACK' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'AVAILABLE' 
                  },
                  {
                    label: 'QUANTITY' 
                  }
                ]
              ]
            : [
                [
                  { label: 'ITEM', sort: true, value:'item_nbr' },
                  { label: utilities.getLabelsByType(viewType,"MEMBER_COST")  },
                
                
                  { label: 'MODEL', sort: true, value:'model' },
                  {
                    label: 'PACK' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU' }),
                 
                  {
                    label: 'AVAILABLE' 
                  },
                  {
                    label: 'PROMO RETAIL' 
                  },
                  {
                    label: 'PROMO COST' 
                  },
                  showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)  },
                  {
                    label: 'QUANTITY' 
                  },
               
                ]
              ])
        ];
      } else if (tableType === IMPULSE_MERCHANDISING) {
        tbl_vals = [
          ...((access !== 1 || (viewPOG && PogMbr))
            ? [
                [
                  { label: 'ITEM', sort: true, value:'ItemNo' },
                  { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), sort: true, value:'MBRCost' },
                  { label: 'MODEL', sort: true, value:'ModelNo'  }   , 
                
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU', sort: true, value:'alternateSKU'}),
                          
                
                  {
                    label: 'AVAILABLE' 
                  },
                  { label: 'SUGGESTED QTY' },
                
                  showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer) },
                  {
                    label: 'PACK'
                  },               
                  {
                    label: 'GROSS MARGIN' 
                  },
                  {
                    label: 'QUANTITY' 
                  },
                ]
              ]
              :
              [
                [
                  { label: 'ITEM' , sort: true, value:'ItemNo' },
                  { label: 'MODEL', sort: true, value:'ModelNo' },
               
                  multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU', sort: true, value:'alternateSKU' }),              
                  {
                    label: 'AVAILABLE' 
                  },
                  { label: 'SUGGESTED QTY' },
                 
                  {
                    label: 'PACK' 
                  },
                  {
                    label: 'GROSS MARGIN' 
                  },
                  showSuggestedRetailPrice &&   { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)  },
                   {
                    label: 'QUANTITY' 
                  },
               
                ]
              ])
        ];
      } else if (tableType === 'Reunion') {
        tbl_vals = [
          ...((access !== 1 || (access === 1 && CatMbr))
            ? [
                [
                  { label: 'ITEM#:' },
                  showSuggestedRetailPrice&& { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getPrefer)  },
                  { label: 'SHIP WEEK:' },
                
                  { label: 'MODEL#:'  },
                  {
                    label: 'OVERRIDE RETAIL:' 
                  },
                  ...(utilities.isEmptyOrNullString(product.shipdate1)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate1) 
                        }
                      ]),
                  { label: 'UPC:'  },
                  {
                    label: 'SAVINGS:' 
                  },
                  ...(utilities.isEmptyOrNullString(product.shipdate2)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate2) 
                        }
                      ]),
                  ...((access === 1 && (access !== 1 && !CatMbr))
                    ? []
                    : [
                        {
                          label: 'WH COST:' 
                        }
                      ]),
                  {
                    label: 'PACK:' 
                  },
                  ...(utilities.isEmptyOrNullString(product.shipdate3)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate3) 
                        }
                      ]),
                  ...((access === 1 && (access !== 1 && !CatMbr))
                    ? []
                    : [
                        {
                          label: 'EVT COST:' 
                        }
                      ]),
                  {
                    label: 'NON-STOCKED:' 
                  },
                  ...(utilities.isEmptyOrNullString(product.shipdate4)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate4) 
                        }
                      ]),
                  ...(utilities.isEmptyOrNullString(product.future_cost)
                    ? [
                        {
                          label: 'RESTRICTED' 
                        }
                      ]
                    : []),
                  ...(utilities.isEmptyOrNullString(product.Restricted)
                    ? [
                        {
                          label: 'RESTRICTED' 
                        }
                      ]
                    : [])
                ]
              ]
              :
              [
                [
                  { label: 'ITEM#:' },
                  { label: 'MODEL#:'  },
                  { label: 'SHIP WEEK:'  },
               
                  { label: 'UPC:'  },
                  {
                    label: 'OVERRIDE RETAIL:' 
                  },
                  ...(utilities.isEmptyOrNullString(product.shipdate1)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate1) 
                        }
                      ]),
                  {
                    label: 'PACK:' 
                  },
                  {
                    label: 'SAVINGS:' 
                  },
                  ...(utilities.isEmptyOrNullString(product.shipdate2)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate2) 
                        }
                      ]),
                  {
                    label: 'NON-STOCKED:' 
                  },
                  
                  ...(utilities.isEmptyOrNullString(product.shipdate3)
                    ? []
                    : [
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate3) 
                        }
                      ]),
               
    
                  ...(utilities.isEmptyOrNullString(product.shipdate4)
                    ? ''
                    : 
                        {
                          label: utilities.getDateFromTimeStamp(product.shipdate4) 
                        }
                      )
                ]
              ])
        ];
      } else if (tableType === MONTHLY_SPECIALS) {
        tbl_vals = [
          ...((access !== 1 || (access === 1 && CatMbr))
            ? [
                [
                  { label: 'ITEM#:' },                
                  { label: 'MODEL#'  },
                  { label: 'UPC:' },
                  {
                    label: 'NON-STOCKED:' 
                  },

                  {
                    label: 'SAVINGS:' 
                  },
                  !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU#:' }),             
                  {
                    label: 'PACK:' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  ((access !== 1 && (access !== 1 && !CatMbr))
                    && 
                        {
                          label: 'WH COST:' 
                        }
                  ),
                  ((access !== 1 && (access !== 1 && !CatMbr))
                    &&
                        {
                          label: 'EVT COST:' 
                        }
                      ),

                      {
                        label: 'OVERRIDE RETAIL' 
                      },    
                      {
                        label: 'SHIP BY WEEK' 
                      }

                ]
              ]
              :
              [
                [
                  { label: 'ITEM#:'  },
                  { label: 'MODEL#:'  },
               
                  { label: 'UPC:'  },
                  {
                    label: 'NON-STOCKED:' 
                  },
                  {
                    label: 'SAVINGS:' 
                  },
                  {
                    label: 'OVERRIDE RETAIL:'
                  },

                  !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU#:' }),

                  {
                    label: 'PACK:' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'OVERRIDE RETAIL' 
                  },
                  {
                    label: 'SHIP BY WEEK' 
                  }  
                ]
              ])
        ];
      } else if (tableType === TV_PRODUCT_SCAN) {
        tbl_vals = [
          ...((access !== 1 || (access === 1 && CatMbr))
            ?  [
                [
                  { label: 'ITEM#:'  },
                  { label: utilities.getLabelsByType(viewType,"MEMBER_COST") },
                
                  { label: 'MODEL#:'  },
                  { label: 'SUGG. RETAIL'  },
                
                  {
                    label: 'AVAILABLE:' 
                  },
                  {
                    label: 'OVERRIDE RETAIL:' 
                  },
                  {
                    label: 'PACK:' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'QUANTITY:' 
                  }
                ]
              ]
              :
              [
                [
                  { label: 'ITEM#:'  },
                  { label: 'MODEL#:'  },
                
                  {
                    label: 'AVAILABLE:' 
                  },
                  {
                    label: 'OVERRIDE RETAIL:' 
                  },
                  {
                    label: 'PACK:' 
                  },
                  this.state.isMaxQty && ({
                    label: 'max qty'
                  }),
                  {
                    label: 'QUANTITY:' 
                  }
                ]
              ])
        ];
      } else if (tableType === COMPETITOR_PRODUCTS) {
        tbl_vals = [
          [
            { label: 'COMPETITOR NAME:' },
            { label: 'ITEM#:' },
          
            {
              label: 'MODEL#:' 
            },
            {
              label: 'NAME:' 
            },
            (access !== 1 && (access !== 1 && !CatMbr)) && {
              label: 'SALE PRICE:'
            },
            {
              label: 'PACK:'
            },
            this.state.isMaxQty && ({
              label: 'max qty'
            }),
          ]
        ];
      }
      return (
         <Table.Header>
           <Table.Cell></Table.Cell>
           <Table.Cell style={{width: tableType === DISCONTINUED && '10rem'}}><button  value = 'Description' className={tableType === 'DOM'? "removeEvent titleCell" : "titleCell"} onClick={changeSortOrder} >DESCRIPTION</button></Table.Cell>
           <Table.Cell style={{width: tableType === DISCONTINUED && '7rem'}}><button  value = 'VendorName' className={tableType === 'DOM' || tableType === IMPULSE_MERCHANDISING ? "removeEvent titleCell" : "titleCell"} onClick={changeSortOrder} >Vendor</button></Table.Cell>
           {tbl_vals[0].map((item, i) => (
               item?.sort ?  <Table.Cell><button  value={item.value} className="titleCell" onClick={changeSortOrder} >{item.label}</button></Table.Cell> :
               <Table.Cell colSpan={item.label === 'SHIP BY WEEK' && 4}>{item.label}</Table.Cell>
           ))}
         </Table.Header>
      );
    };
    render() {
      const {
        quantities,
        overrideRetails,
        handleChange,
        handleChangeblur,
        tableType,
        //categoryData: products,
        addToCart,
        isShowAllWarehouse,
        assortmentId
      } = this.props;
      let { categoryData: products} =  this.props
      if(this.props?.isCtbAssortment && [0, 2].includes(parseInt(this.props?.missing))) {
        products = products.filter(product=>{
          if(parseInt(product.IsMissingItem)=== 0) {
            return product
          }
          else {
            return
          }
        })
      }
      const isDiscontinued = tableType === DISCONTINUED;
      const isReunionListing = tableType === 'Reunion';
      const isMonthlyListing = tableType === MONTHLY_SPECIALS;
      const isDefaultListing = tableType === 'default';
      const isNewItemstListing = tableType === 'NewItems';
      const isCloseoutItems = tableType === CLOSEOUTS;
      const isHotDeals = tableType === HOTDEALS;
      const isImListing = tableType === IMPULSE_MERCHANDISING;
      const isScanListing =  scanListing.indexOf(tableType) !== -1 ? true : false;
      const isAssortmentListing = assortmentListing.indexOf(tableType) !== -1 ? true : false;      
      const link = '/product/';
      const tooltipStyle = {
        display: this.state.show || this.state.showCompare ? 'block' : 'none'
      }
      let msCheck = utilities.msExist(this.props.multiStoreDetails); 
      const getPrefer =  getPrefernceType(this.props);
      const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
      const showSuggestedRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
      const showSRPMaskPrice = showSRPMaskPricePref(this.props.viewType,getPrefer);
        return(
          products && (
            <>
            <AddToCartModalClassicView
            isAddtoCartModalopen={this.state.isAddToCartModalOpen}
            closeCartModal={this.closeCartModal}
            addToCart={this.props.classicAddToCart}
            product={this.state.productSelectedForModal}
          ></AddToCartModalClassicView>
             <div className="classicAddBtn" style={{marginTop:0}}>
               
             {tableType !== COMPETITOR_PRODUCTS &&
                          (!isAssortmentListing ||
                            tableType === 'DOM' ||
                            (isImListing &&
                              (assortmentId === 'CLIP01' ||
                                assortmentId === 'CTOP01'))) && (
                            <div id='plp_add_to_cart'>
                              {this.props.multiStoreDetails.access !== 1  && (
                                <>
                                 {msCheck && msCheck > 1 ?
                                 !this.props.isReunionOnlyStore ?
                                  <button
                                  disabled = {this.state.visibleAddCart}
                                  className='red ui button'
                                  style={{ width: '-webkit-fill-available' }}
                                  onClick={() =>
                                    this.handleAddToCart()
                                  }
                                >
                                    Add to Store
                                  </button>
                                  : <>
                                      <button className='red ui button' disabled>Add to Store</button>
                                      <p class="disabledMessage">*Ordering restricted</p>
                                    </>
                                :!this.props.isReunionOnlyStore ?
                                <button
                                disabled = {this.state.visibleAddCart}
                                  className='red ui button'
                                  style={{ width: '-webkit-fill-available' }}
                                  onClick={() =>
                                    this.handleAddToCart()
                                  }
                                >
                                  Add to Cart
                                </button>
                                : <>
                                    <button className='red ui button' disabled>Add to Cart</button>
                                    <p class="disabledMessage">*Ordering restricted</p>
                                  </>
                              }
                              </>
                              )}
                            </div>
                          )}
             </div>
            <Table id="classViewTable" className={tableType === 'ImpulseMerchandising' ? 'impulseTable' : (tableType === 'RetailAssortments' ||  tableType === 'Planograms') && 'retailTable'}>
                {!isDefaultListing ? (
                  //<></>
                this.getCustomTableViewHeader(
                  [],
                  this.props.viewType,
                  tableType,
                  isShowAllWarehouse,
                  this.props.multiStoreDetails.access,
                  this.props.multiStoreDetails.CatMbr,
                  this.props.multiStoreDetails.viewPOG,
                  this.props.multiStoreDetails.PogMbr,
                  this.props.multiStoreDetails,
                  this.props.changeSortOrder ,
                  displayMemberCostAndLbl                 
                )
                ): (
                  this.getTableHeader(products,displayMemberCostAndLbl)
                )}
                <Table.Body>
                 
                {products.map((product, index) => {
                  this.itemRef[product.Item_Number] = React.createRef()
                  const isHighlight = (tableType===IMPULSE_MERCHANDISING || tableType==='Planograms') 
                  && parseInt(product.recQty) !== parseInt(quantities[index]);
                  return (
                  <>
                  <Table.Row  className={isHighlight?'highlight':''}>
                  <Table.Cell style ={{maxWidth: "2.8rem"}}>
                    {this.state.visible && this.state.item === product.Item_Number && (
                    <div className='QuickviewDialog'>
                      <Dialog width='1' height='1' position='absolute'>
                        <QuickViewDetails
                          getprefer={
                            this.props.getprefer &&
                            this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                          }
                          tableType={tableType}
                          Item_Number={product.Item_Number}
                          viewType={this.props.viewType}
                          QuickCost ={product.Member_Cost}
                        />
                      </Dialog>
                    </div>
                     )}
                      {this.state.expand === 'expan' &&
                      this.state.item === product.Item_Number && (
                        <div className='DescriptionDialog' onMouseLeave={this.closeModalexpand}>
                          <Dialog width='1' height='1' position='absolute'>
                            {tableType === 'DOM' ?
                              this.getLongDescription(product,true) :
                              this.getLongDescription(product, false)
                            }
                          </Dialog>
                        </div>
                      )}
                     <div className='card_list_wrap'>
                      <Link
                       onClick={this.props.setBackButtonPath} 
                        to={{
                          pathname: link + product.Item_Number,
                          state: {
                            title: this.props.title,
                            listType: this.props.listType,
                            assortmentType: this.props.assortmentType,
                            muzak:product.muzak,
                            savingsOutOfWarehouse:product.savingsOutOfWarehouse,
                            promoHistory :product.promoHistory ,
                            futurePromo:product.futurePromo,
                            showAvailableField: !isShowAllWarehouse,
                            isReunionListing,
                            isHotDeals,
                            isMonthlyListing,
                            isWow:this.props.isWow,
                            MonthlySpecialSaving: product.Savings,
                            MonthlyEventCost: product.spc_cost,
                            MonthlyRegularCost: product.reg_cost,
                            MonthlyWHCost: product.Cost,
                            monthlyStock: product.stocked,
                            monthlyRelayNbr:product.RelayNbr,
                            monthlyPromoNbr:product.PromoNbr,
                            monthlyAssmentNbr:product.AsstmtID
                          }
                        }}
                        className={
                          this.props.img_style === 'none'
                            ? 'disable_img_click'
                            : 'card_list_img'
                        }
                      >
                        {renderImages.getImage({
                          src: product.Image_File_Name
                            ? product.Image_File_Name
                            : 'https://images.truevalue.com/getimage.asp?id=' +
                              product.Item_Number +
                              '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
                          style: { display: this.props.img_style },
                          alt: 'product',
                          className: 'ui image image_lisitng',
                          onMouseEnter: () => {
                            this.addimg('addimg', product.Item_Number);
                          }
                        })}
                      </Link>
                    </div>
                    
                    </Table.Cell>
                    <Table.Cell style={{maxWidth: "13rem" }}>
                    <div>
                          <Link
                           onClick={this.props.setBackButtonPath} 
                            to={{
                              pathname: link + product.Item_Number,
                              state: {
                                title: this.props.title,
                                listType: this.props.listType,
                                assortmentType: this.props.assortmentType,
                                muzak:product.muzak,
                                savingsOutOfWarehouse:product.savingsOutOfWarehouse,
                                promoHistory :product.promoHistory ,
                                futurePromo:product.futurePromo,
                                showAvailableField: !isShowAllWarehouse,
                                isReunionListing,
                                isMonthlyListing,
                                isWow:this.props.isWow,
                                MonthlySpecialSaving: product.Savings,
                                MonthlyEventCost: product.spc_cost,
                                MonthlyRegularCost: product.reg_cost,
                                MonthlyWHCost: product.Cost,
                                monthlyStock: product.stocked,
                                monthlyRelayNbr:product.RelayNbr,
                                monthlyPromoNbr:product.PromoNbr,
                                monthlyAssmentNbr:product.AsstmtID
                              }
                            }}
                          >
                            <b className='classicDesc'>
                            {product.shortDesc || product.short_desc || product.Product_Name || product.Product_Title}
                            </b>
                          </Link>
                        </div>
                        {tableType === 'DOM' ?
                          this.getLongDesc(product,true)
                        :
                        this.getLongDesc(product,false)
                        }
                        {tableType === 'DOM' && product.promo_comment && <p>{product.promo_comment}</p> }
                    </Table.Cell>
                    <Table.Cell style={{maxWidth: "6rem"}}>
                    <b className='vendor-span'>
                            <Link
                             onClick={this.props.setBackButtonPath} 
                              to={{
                                pathname: product.Vendor_Name
                                  ? '/vendor/' +
                                    utilities.replaceAll(
                                      product.Vendor_Name,
                                      '/',
                                      '%2F'
                                    )+ '/' +(product.Vendor_Id || product.vendorID || product.vendor_id)
                                  : '#',
                                state: { vendorId: product.Vendor_Id }
                              }}
                            >
                              {product.Vendor_Name}
                            </Link>
                          </b>
                    </Table.Cell>
                    
                   
                {!isDefaultListing ? (
                <>                                      
                <CustomTableView
                viewPOG={this.props.multiStoreDetails.viewPOG}
                PogMbr={this.props.multiStoreDetails.PogMbr}
                CatMbr={this.props.multiStoreDetails.CatMbr}
                getprefer={
                    this.props.getprefer &&
                    this.props.getprefer.customerView
                      ? this.props.getprefer.customerView
                      : 'c1'
                }
                multiStoreDetails = {this.props.multiStoreDetails}
                tableType={tableType}
                assortmentId={assortmentId}
                product={product}
                viewType={this.props.viewType}
                handleQtyChange={handleChange}
                handleQtyChangeblur={handleChangeblur}
                index={index}
                quantities={quantities}
                overrideRetail={overrideRetails[index]}
                title={this.props.title}
                listType={this.props.listType}
                isShowAllWarehouse={isShowAllWarehouse}
                access = {this.props.multiStoreDetails.access}
                isCloseoutItems={this.props.isCloseoutItems}
                RAMarketFlag={this.props.RAMarketFlag}
                itemRef={this.itemRef}
                inputFocus= {this.props.inputFocus}
                displayMemberCostAndLbl ={displayMemberCostAndLbl}
                isMaxQty = {this.state.isMaxQty}
              />
              </>
                ): (
                  <>
                    {(this.props.multiStoreDetails.access !== 1  || 
                                  (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) 
                                  ? (
                                    <>
                                      
                                        
                                        <Table.Cell className='First_level_details'>
                                          {product.Item_Number}
                                        </Table.Cell>
                                        <Table.Cell className='First_level_details' style={{ width: '6rem' }}>
                                          {product.Model}
                                        </Table.Cell>
                                        {displayMemberCostAndLbl && <Table.Cell className='First_level_details'>
                                        {(this.props.multiStoreDetails.access !== 1  
                                          || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr))
                                            ? <MaskPrice
                                                getprefer={
                                                  this.props.getprefer &&
                                                  this.props.getprefer.customerView
                                                    ? this.props.getprefer.customerView
                                                    : 'c1'
                                                }
                                                viewType={this.props.viewType}
                                                fieldVal={utilities.getCostForQtyPlpNew(
                                                  product,
                                                  quantities[index] || ''
                                                )}
                                                field='Member Cost'
                                              />
                                            :''
                                        }
                                        {product?.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW && (
                                          <TierIcon
                                            tierLevel={utilities.getCostForQtyPlpNew(
                                              product,
                                              quantities[index],
                                              true
                                            )}
                                            tierLetter={quantities[index] && parseInt(quantities[index]) > 0 ? true : false}
                                          />
                                        )}
                                        </Table.Cell>
                                           }
                                      
                                        {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
                                          <>
                                          
                                                      <Table.Cell className='First_level_details' style={{ width: '6rem' }}>
                                                        {product.Xref_ItemNbr !== '~' ? product.Xref_ItemNbr: '-' }
                                                      </Table.Cell>
                                          </>
                                        )}                                      
                                         
                                      
                                    </>
                                    ) 
                                  : (
                                  <>
                                   
                                      <Table.Cell className='First_level_details'>
                                        {product.Item_Number}
                                      </Table.Cell>
                                     
                                      <Table.Cell
                                        className='First_level_details'
                                        style={{ width: '6rem' }}
                                      >
                                        {product.Model}
                                      </Table.Cell>
                                                                                                   
                                    { this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
                                   
                                      <Table.Cell className='First_level_details' style={{ width: '6rem' }}>
                                          {product.Xref_ItemNbr !== '~' ? product.Xref_ItemNbr : '-' }
                                      </Table.Cell>
                                    )}  
                                  </>
                                )}
                               {showSuggestedRetailPrice&& <Table.Cell className='First_level_details'>
                               {this.props.viewType===MEMBER_VIEW?"$"+utilities.toIntlNumberFormat(product.SuggestedRetail):showSRPMaskPrice?maskPrice(product.SuggestedRetail):"$"+utilities.toIntlNumberFormat(product.SuggestedRetail)}
                                </Table.Cell>}
                                 {(!isShowAllWarehouse || (isShowAllWarehouse && tableType === HOTDEALS)) ? (
                                    <>
                                    
                                      <Table.Cell
                                        className={
                                          product.Available &&
                                          parseInt(product.Available) > 0
                                            ? 'First_level_details'
                                            : 'First_level_details_red cost_red_color '
                                        }
                                      >
                                        {utilities.checkForHubAndSpokeIndictor(product)}
                                      </Table.Cell>
                                    </>
                                  ) :  (
                                    <>
                                  
                                      <Table.Cell className='First_level_details'>
                                        {product.S_Pack_Type}
                                        {product.S_Pack_Qty}
                                      </Table.Cell>
                                    </>
                                  )}
                                  {!isShowAllWarehouse && (
                                    <>
                                      <Table.Cell className='First_level_details'>
                                        {product.S_Pack_Type}
                                        {product.S_Pack_Qty}
                                      </Table.Cell>
                                    </>
                                  )}
                                  {this.state.isMaxQty && <Table.Cell className='First_level_details'>
                                    <span>{product.maxQty ? product.maxQty: '-'}</span>
                                  </Table.Cell>}
                                  
                                  <Table.Cell className='First_level_details'>
                                    <input
                                      className='qty-box'
                                      id={'or-box' + product.Item_Number}
                                      data-item-number={index}
                                      data-field='or'
                                      data-item = {product.Item_Number}
                                      data-maxQty={product.maxQty}
                                      maxLength={7}
                                      data-label = 'overRetail'
                                      onChange={handleChange}
                                      onBlur = {handleChangeblur}
                                      value={overrideRetails[index] || ''}
                                    />
                                  </Table.Cell>
                                 
                                  <Table.Cell>
                                    <input key={this.itemRef[product.Item_Number]}
                                      ref = {this.itemRef[product.Item_Number]}
                                      className={this.props.inputFocus.includes(parseInt(product.Item_Number)) || validateQty(product.S_Pack_Type, product.S_Pack_Qty,parseInt(quantities[index]), product.maxQty)? 'invalidBox qty-box': 'qty-box'}
                                      id={'qty-box' + product.Item_Number}
                                      type='text'
                                      data-item-number={index}
                                      data-field='qty'
                                      data-item = {product.Item_Number}
                                      data-maxQty={product.maxQty}
                                      data-pack-type={product.S_Pack_Type}
                                      data-pack-qty={product.S_Pack_Qty}
                                      onChange={handleChange}
                                      onBlur = {handleChangeblur}
                                      onKeyDown = {(e)=>this.handleKeyDown(e)}
                                      value={quantities[index] || ''}
                                    />
                                  </Table.Cell>
                                  
                    </>
                    )}
                    
                            
                    </Table.Row>
                    <Table.Row className="resProductDesc" onMouseLeave={() => {this.delimg('delimg');}}>
                      <Table.Cell style={{maxWidth:'2.8rem'}}>
                      {HIDE_QUICKVIEW_PAGE.indexOf(tableType) === -1 &&
                      window.innerWidth > 767 && (
                        <div onMouseLeave={this.closeModal} className="qucikViewDiv">
                          <button
                            type='button'
                            className={
                              this.state.item === product.Item_Number &&
                              this.state.quickviewdata === 'addimg'
                                ? 'buttonVisible'
                                : 'buttonNotVisible'
                            }
                            onMouseEnter={() => {
                              this.openModal(product.Item_Number);
                            }}
                          >
                            Quick View
                          </button>
                        </div>
                      )}
                      </Table.Cell>
                       <Table.Cell colSpan={tableType !== 'RetailAssortments' && '5'}>
                       <div className='indicators-div'>
                          <ProductIndicators product={product} isPLP='PLP' />
                          {!utilities.isEmptyOrNullString(product?.whStockStatusMessage) && (
                            <>
                             &nbsp;|&nbsp; <span>Stock Status:</span> {product?.whStockStatusMessage}
                           </>
                           )}
                          </div>
                       </Table.Cell>
                       {tableType === MONTHLY_SPECIALS && utilities.isEmptyOrNullString(product.future_cost) && (
                         <Table.Cell><span>RESTRICTED:</span> Pricing available at Monthly</Table.Cell>
                       )}
                       {product?.Restrictions?.length > 0 && (
                            <Table.Cell colSpan={'3'} className='t-center'>
                            <span className='restricted-product'> {utilities.restrictedDetail(product?.Restrictions)} </span>
                          </Table.Cell>
                       )}                   
                               {/* product.altNbr && !utilities.isEmptyOrNullString(product.altNbr) && (
                                <>
                                <Table.Cell colSpan={'3'}className='First_level_details boldText'>
                                    <span>Rec. Alt Sku: <u><Link to={'/product/' + product.altNbr}>{product.altNbr}</Link></u></span>
                                </Table.Cell>
                                 </>
                               )*/}
                                 {product.alternateItem?.Number && product.alternateItem?.Number > 0 ? (
                                      <Table.Cell colSpan={'4'} className='boldText'>
                                      <span>{product.alternateItem?.Label} </span>
                                     <Link
                                     to={{
                                       pathname: '/product/' + product.alternateItem?.Number,
                                       state: {
                                         title: this.propstitle,
                                         listType: this.props.listType
                                       }
                                     }}>{product.alternateItem?.Number}</Link>
                                    </Table.Cell>
                                 ): null}
                                  
                                {/*(tableType === DISCONTINUED || tableType === CLOSEOUTS) && (utilities.hyphenForZero(product.substitute_item).trim() !== '-') && (
                                   <Table.Cell colSpan={'4'} className='boldText'>
                                     <span>{product.sub_type_cd ==='3' || (product.substistuteorreferal && utilities.checkRef(product.substistuteorreferal))? 'REFERRAL ITEM: ':'SUBSTITUTE: '}</span>
                                    <Link
                                    to={{
                                      pathname: '/product/' + utilities.refInclude(product.substitute_item),
                                      state: {
                                        title: this.propstitle,
                                        listType: this.props.listType
                                      }
                                    }}>{product.sub_type_cd? utilities.hyphenForZero(product.sub_type_cd) ==='3'? utilities.hyphenForNullsub(product.substitute_item) : utilities.hyphenForNullref(product.substitute_item)
                                    :product.substistuteorreferal? product.substistuteorreferal: utilities.hyphenForNullsub(product.substistuteorreferal)}</Link>
                                   </Table.Cell>
                                  )*/}
                                 <Table.Cell  className="favCompWrap" colSpan={'1'} style={{textAlign:'right'}}>
                                  {!isAssortmentListing && 
                          !isScanListing && 
                          ((!isReunionListing && !isMonthlyListing)  ? (
                            <div className="favDivWrap">
                              <span className='ui checkbox compare-n-fav'>
                              <div className='fav_icon_hover' style={tooltipStyle}>{this.state.show===true && this.state.productFav === product.Item_Number?'Favorites':''}</div>
                              <i
                                className={
                                  product.favorited
                                    ? 'heart icon'
                                    : 'heart outline icon'
                                }
                                style={{ cursor: 'pointer' }}
                               onMouseEnter = {() =>this.enterFav(product.Item_Number)}
                               onMouseLeave = {() =>this.leaveFav(product.Item_Number)}
                                onClick={() =>
                                  this.props.toggleFavorite(product.Item_Number)
                                }
                              />
                             
                            </span>
                            {!isScanListing && (
                              <div className="compareWrap">
                              <div className='comp_icon_hover' style={tooltipStyle}>{this.state.showCompare===true && this.state.productComp === product.Item_Number?'Compare':''}</div>
                              
                                  <Checkbox
                                  onMouseEnter = {() =>this.enterCompare(product.Item_Number)}
                                  onMouseLeave = {() =>this.leaveCompare(product.Item_Number)}
                                    checked={product.selected}
                                    style={{ cursor: 'pointer' }}
                                    className='compare_checkbox'
                                    onClick={() =>
                                        this.props.selectForCompare(
                                          product.Item_Number,
                                          !product.selected
                                      )
                                    }
                                  />
                                  </div>
                              )}
                            </div>
                            
                          ) : (
                            <span className='compare-n-fav padding_right'>
                              SEQ :{product.seq_nbr}
                            </span>
                          ))}
                                  </Table.Cell>

                            </Table.Row>
                            
                    </>
                  )
                })}
                </Table.Body>
            </Table>
            
              
            </>
          )
        )
    }
}

const mapStateToProps = state => {
    const carts = state.cart.orders.allCarts;
    return {
      carts,
      currentCartType: state?.cart?.orders?.currentCart?.cartInfoName,
      rdc: state.SessionReducer.rdc,
      whCarts: carts && carts.filter(cart => cart.cartInfoName === 'REGULAR'),
      msCarts: carts && carts.filter(cart => cart.cartInfoName === 'MS'),
      getprefer: state.preference.listData,
      multiStoreDetails : state.SessionReducer.multiStoreDetails,
      userName:  state.SessionReducer.address.userName,
  
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return Object.assign(
      { dispatch },
      bindActionCreators(
        {
          addProduct,
          createNAddCart,
          classicAddProduct,
          classicCreateNAddCart
        },
        dispatch
      )
    );
  }

  const CustomTableView = ({
    product,
    viewType,
    tableType,
    handleQtyChange,
    handleQtyChangeblur,
    index,
    quantities,
    overrideRetail,
    title,
    listType,
    isShowAllWarehouse,
    getprefer,
    assortmentId,
    access,
    CatMbr,
    viewPOG,
    PogMbr,
    multiStoreDetails,
    RAMarketFlag,
    itemRef,
    inputFocus,
    displayMemberCostAndLbl,
    isMaxQty
  }) => {
    const quantity = quantities[index];
    const dipslayOrigAndCurrCost = shouldHideOrigAndCurrCostForPreference(viewType,tableType,getprefer);
    const showSuggestedRetailPrice =shouldHideSUGG_RETAILForPreference(viewType ,getprefer);
    const memberCost = 
      <MaskPrice
        viewType={viewType}
        getprefer={getprefer}
        fieldVal={
          tableType === CLOSEOUTS ||
          tableType === DISCONTINUED || tableType === 'NewItems' ? utilities.getCostForQtyPlpNew(product, quantity || '')
          : tableType === IMPULSE_MERCHANDISING  
          ? utilities.getCostForQty(product, quantity || '', true) 
          : tableType === HOTDEALS ? utilities.getCostForQtyPlpNew(product, quantity || '')
          : tableType === MONTHLY_SPECIALS
          ? utilities.getCostForQtyPlpNew(product.pricing , quantity || '')
          : tableType === 'DOM'
          ? utilities.getCostForQtyHotDeals((product || (product && product.pricing)), quantity || '', true)
          : tableType === 'Planograms' 
          ? utilities.getCostForQtyPlpNew(product && product.pricing, quantity || '')
          : product.Member_Cost
        }
        field={(tableType === "DOM" || tableType===MONTHLY_SPECIALS) ? "Member Cost black" :'Member Cost'}
      />
      const currentcost = <MaskPrice
      viewType={viewType}
      getprefer={getprefer}
      fieldVal={
        tableType === CLOSEOUTS         
          ? utilities.getCostForQty(product, quantity || '', true)
          :tableType === DISCONTINUED
            ? utilities.getCostForQty(product, quantity || '', true)
            : tableType === 'Planograms'
              ? utilities.planogramGetCostForQty(product, quantity || '', true)
              : tableType === HOTDEALS 
              ? utilities.getCostForQtyHotDeals(product, quantity || '', true)
              : product.Member_Cost
      }
      field='Member Cost'
    />
    let price = BusinessRules.suggestedRetailPriceForPlp(product,product.variableRetail,viewType, multiStoreDetails);
    const srpCost = 
              <MaskPrice
                viewType={viewType}
                getprefer={getprefer}
                fieldVal={price || '0'}
                field='Suggested Retail'
            />
      const costWithTier = product?.Member_Cost_Tier
        ? <>
            {memberCost}
            {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
          </>
        : memberCost;

      const hotDealsCostWithTier = product?.Member_Cost_Tier
      ? <>
          {memberCost}
          {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
        </>
      : memberCost;

      const costWithTierImpulse = product.tiered && product.tiered !== 'False'
      ? <>
          {memberCost}
          <TierIcon tierLevel={utilities.getTierLevelForQty(product, quantity,tableType)} />
        </>
      : memberCost;

      const regularcost = <MaskPrice
        viewType={viewType}
        getprefer={getprefer}
        fieldVal={product.Regular_Cost}
        field='Regular Cost'
      />

      const regularCostTier = <>
        {regularcost}
        {viewType === MEMBER_VIEW && product.tiered && <TierIcon tierLevel={'2'} tierLetter={true}/>}
      </>

      const costWithTierPlanograms = product && product.pricing && product.pricing?.Member_Cost_Tier
      ? <>
          {memberCost}
          {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product && product.pricing, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
        </>
      : memberCost;
  
      const costWithTierHotDeals =(
        (product.tiered && product.tiered !== 'False') 
          || (product && product.pricing && product.pricing.tiered)) ?
       <>
         {memberCost}
         <TierIcon tierLevel={utilities.getTierLevelForQtyHotDeals((product || (product && product.pricing)), quantity)} />
       </>
     : memberCost;
  
    const RetailMemCost = 
                        RAMarketFlag==='Y' && product.Reunion_Cost && parseInt(product.Reunion_Cost) === 0 ? '-' :
                        <MaskPrice
                          getprefer={getprefer}
                          viewType={viewType}
                          fieldVal={RAMarketFlag==='Y' ? product.Reunion_Cost : product.pricing ? product.pricing.Cost : ''}
                          field={'Member Cost'}
                        />
                        
    let price_evt = product && product.spc_cost
    const evtCost = <MaskPrice
                      getprefer={getprefer}
                      viewType={viewType}
                      fieldVal={price_evt}
                    />
    let tbl_vals = [];
    if (tableType === 'NewItems') {
      tbl_vals = [
        ...((access !== 1 || (access === 1 && CatMbr))  
          ? 
           [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), val: utilities.emptyPriceCheck(costWithTier) },

              
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) && (
                  {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' ? product.Xref_ItemNbr: '-'}
                ),
                { label: 'DATE ADDED:', val: product.creation_date },
                showSuggestedRetailPrice && { label: utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
              
                ...(isShowAllWarehouse
                  ? []
                  : [
                      {
                        label: 'AVAILABLE:',
                        val: utilities.checkForHubAndSpokeIndictor(product),
                        labelClass:
                          product.Available && parseInt(product.Available) > 0
                            ? ''
                            : 'First_level_details_red cost_red_color '
                      }
                    ]),
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : '' }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
                
              ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                { label: 'DATE ADDED:', val: product.creation_date },

                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) && (
                  {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}
                ),
                {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                ...(isShowAllWarehouse
                  ? []
                  : [
                      {
                        label: 'AVAILABLE:',
                        val: utilities.checkForHubAndSpokeIndictor(product),
                        labelClass:
                          product.Available && parseInt(product.Available) > 0
                            ? ''
                            : 'First_level_details_red cost_red_color '
                      }
                    ]),

                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
                showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost }
              ]
            ])
      ];
    } else if (tableType === DISCONTINUED || tableType === CLOSEOUTS) {
      tbl_vals = [
        ...((access !== 1 || (access === 1 && CatMbr))
          ? [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                dipslayOrigAndCurrCost && {
                  label: utilities.getLabelsByType(viewType,"REGULAR_COST")+':',
                  val: utilities.emptyPriceCheck(regularCostTier),
                },
                
              
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' ? product.Xref_ItemNbr : '-'}
               ),              
               displayMemberCostAndLbl && {label: utilities.getLabelsByType(viewType,"MEMBER_COST",getprefer), val:<>{costWithTier}</>, },
            
               showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
              
                ...(isShowAllWarehouse
                  ? [
                      {
                        label: 'DISC. DATE:',
                        val: product.discontinued_date
                          ? utilities.getDateFromTimeStamp(
                              new Date(product.discontinued_date)
                            )
                          : '-'
                      }
                    ]
                  : [
                      {
                        label: 'AVAILABLE:',
                        val:utilities.checkForHubAndSpokeIndictor(product),
                        labelClass:
                          product.Available && parseInt(product.Available) > 0
                            ? ''
                            : 'First_level_details_red cost_red_color '
                      }
                    ]),
                ( viewType === MEMBER_VIEW ? 
                  { label: '% Off:',
                    val: utilities.hyphenForZero(product.savings && Number(product.savings) > 0 ? `${Number(product.savings).toFixed(2)}`: '-')
                  } : {}
                ),                            
                ...(isShowAllWarehouse
                  ? []
                  : [
                      {
                        label: 'DISC. DATE:',
                        val: product.discontinued_date
                          ? utilities.getDateFromTimeStamp(
                              new Date(product.discontinued_date)
                            )
                          : '-'
                      }
                    ]),
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                  {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
              ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                

                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' ? product.Xref_ItemNbr: '-'}
               ),
                ...(isShowAllWarehouse
                  ? [
                      {
                        label: 'DISC. DATE:',
                        val: product.discontinued_date
                          ? utilities.getDateFromTimeStamp(
                              new Date(product.discontinued_date)
                            )
                          : '-'
                      }
                    ]
                  : [
                      {
                        label: 'AVAILABLE:',
                        val:utilities.checkForHubAndSpokeIndictor(product),
                        labelClass:
                          product.Available && parseInt(product.Available) > 0
                            ? ''
                            : 'First_level_details_red cost_red_color '
                      }
                    ]),
                ( viewType === MEMBER_VIEW ? 
                  { label: '% Off:',
                    val: utilities.hyphenForZero(product.savings && Number(product.savings) > 0 ? `${Number(product.savings).toFixed(2)}`: '-')
                  } : {}
                ),
                ...(isShowAllWarehouse
                  ? []
                  : [
                      {
                        label: 'DISC. DATE:',
                        val: product.discontinued_date
                          ? utilities.getDateFromTimeStamp(
                              new Date(product.discontinued_date)
                            )
                          : '-'
                      }
                    ]),
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: product.sub_type_cd ==='3' || (product.substistuteorreferal && utilities.checkRef(product.substistuteorreferal))? 'REFERRAL ITEM':'SUBSTITUTE' ,
                  val:product.sub_type_cd? utilities.hyphenForZero(product.sub_type_cd) ==='3'? utilities.hyphenForNullsub(product.substitute_item) : utilities.hyphenForNullref(product.substitute_item)
                  :product.substistuteorreferal && product.substistuteorreferal
                },
                
                   ...(isShowAllWarehouse
                     ? []
                     : [
                      showSuggestedRetailPrice&& { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost }
                     ]),
                {
                label: 'OVERRIDE RETAIL:',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              },
              {
                label: 'QUANTITY:',
                val: quantity,
                type: 'qty',
                is_text_box: true
              } 
               ]
            ])
      ];
    } else if (tableType === HOTDEALS) {
      tbl_vals = [
        ...((access !== 1 || (access === 1 && CatMbr))
          ? [
              [
                { label: 'ITEM#', val: product.Item_Number },
                { label: 'MODEL', val: product.Model },
                dipslayOrigAndCurrCost && {
                  label: utilities.getLabelsByType(viewType,"REGULAR_COST")+':',
                  val: utilities.emptyPriceCheck(regularCostTier),
                },
                              
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU', val: product.aliasSku}
               ),
               displayMemberCostAndLbl && {label: utilities.getLabelsByType(viewType,"MEMBER_COST",getprefer), val:<>{hotDealsCostWithTier}</>, },             
                {
                  label: 'AVAILABLE',
                  val:utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.available && parseInt(product.available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                {
                  label: '% OFF',
                  val: utilities.hyphenForZero(product.Savings && Number(product.Savings) > 0 ? `${Number(product.Savings).toFixed(2)}`: '-')
                },
                               {
                  label: 'DISC. DATE',
                  val: product.tbdDate
                    ? utilities.getDateFromTimeStamp(
                        new Date(product.tbdDate)
                      )
                    : '-'
                }, 
                {
                  label: 'PACK',
                  val: `${product.packType ? product.packType : ''}${
                    product.packQty ? product.packQty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },                
                {
                  label: 'OVERRIDE RETAIL',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                {
                  label: 'QUANTITY',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
              ]
            ]
            :
            [
              [
                { label: 'ITEM#', val: product.Item_Number },
                { label: 'MODEL', val: product.Model },
                
                {
                  label: 'AVAILABLE',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.available && parseInt(product.available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                {
                  label: '% OFF',
                  val: utilities.hyphenForZero(product.Savings && Number(product.Savings) > 0 ? `${Number(product.Savings).toFixed(2)}`: '-')
                },
               
                {
                  label: 'DISC. DATE',
                  val: product.discontinued_date
                    ? utilities.getDateFromTimeStamp(
                        new Date(product.discontinued_date)
                      )
                    : '-'
                },
                {
                  label: 'PACK',
                  val: `${product.packType ? product.packType : ''}${
                    product.packQty ? product.packQty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: 'SUBSTITUTE',
                  val: utilities.hyphenForZero(
                    utilities.hyphenForNullsub(product.subitemNum)
                  )
                },
              
              multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              && ({label: 'Alternate SKU', val: product.aliasSku}),
              {
                label: 'OVERRIDE RETAIL',
                val: overrideRetail,
                type: 'or',
                is_text_box: true
              },
              {
                label: 'QUANTITY',
                val: quantity,
                type: 'qty',
                is_text_box: true
              }, 
            ],          
            ])
      ];
    }
    else if (tableType === 'Planograms') {
      tbl_vals = [
        ...((access !== 1 || PogMbr)
          ? [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), val: costWithTierPlanograms },               
                { label: 'SUGG. QTY:', val: product.recQty },
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                {
                  label: 'ORDER QTY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                { label: 'UPC:', val: product.UpcNum },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
              
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost }
  
              ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                {
                  label: 'ORDER QTY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                { label: 'UPC:', val: product.UpcNum },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),              
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost }
              ]
            ])
      ];
    } else if (tableType === 'RetailAssortments') {
      tbl_vals = [
        ...((access !== 1 ||  (viewPOG && PogMbr))
          ? [
              [
                { label: 'ITEM#:', val: product.Item_Number },           
                { label: 'MODEL#:', val: product.model },
                {label: 'UPC:', val: product.UpcNum},
                displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), val: RetailMemCost },
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID),
                 { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
               { label: 'QUANTITY:', val: product.Available },
            ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.model },
              
               multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                 { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
               { label: 'QUANTITY:', val: utilities.checkForHubAndSpokeIndictor(product) },
              ]
               
            ])
      ];
    } else if (tableType === 'DOM') {
      tbl_vals = [
        ...((access === 1 && (access !== 1 && CatMbr))
          ? [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
             
                !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                  && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                 
             
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
              ]
            ]
          : [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), val: costWithTierHotDeals },
              
              
                { label: 'MODEL#:', val: product.Model },
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                {
                  label: 'PROMO RETAIL:',
                  val:
                    !utilities.isEmptyOrNullString(product.promo_retail) ? '$' + Number(product.promo_retail).toFixed(2) : '-'
                },
                {
                  label: 'PROMO COST:',
                  val: !utilities.isEmptyOrNullString(product.spc_cost) ? '$' + Number(product.spc_cost).toFixed(2) : '-',
                  labelClass:'First_level_details_red'
                },
                showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
              ]
            ])
      ];
    } else if (tableType === IMPULSE_MERCHANDISING) {
      tbl_vals = [
        ...((access !== 1 || (viewPOG && PogMbr))
          ? [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model } , 
              
                displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), val: costWithTierImpulse },
               
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                         
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                { label: 'SUGGESTED QTY:', val: product.recQty },
              
                 showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },

              
                {
                  label: 'GROSS MARGIN:',
                  val: computeGrossMargin(product.Srp_Cost, product.Member_Cost)
                },
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },

              ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
             
                multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              && ({label: 'Alternate SKU#:', val: product.aliasSku}),              
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                { label: 'SUGGESTED QTY:', val: product.recQty },

                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                {
                  label: 'GROSS MARGIN:',
                  val: computeGrossMargin(product.Srp_Cost, product.Member_Cost)
                },
                 showSuggestedRetailPrice &&{ label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
                 {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                },
              ]
            ])
      ];
    } else if (tableType === 'Reunion') {
      tbl_vals = [
        ...((access !== 1 || (access === 1 && CatMbr))
          ? [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                 showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer), val: srpCost },
                { label: 'SHIP WEEK:', val: 'QUANTITY' },
              
                { label: 'MODEL#:', val: product.Model },
                {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                ...(utilities.isEmptyOrNullString(product.shipdate1)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate1),
                        type: 'qty1',
                        is_text_box: true,
                        val: quantity[0]
                      }
                    ]),
                { label: 'UPC:', val: product.UpcNum },
                {
                  label: 'SAVINGS:',
                  val: utilities.hyphenForNull(product.Savings) + ' %'
                },
                ...(utilities.isEmptyOrNullString(product.shipdate2)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate2),
                        type: 'qty2',
                        is_text_box: true,
                        val: quantity[1]
                      }
                    ]),
                ...((access === 1 && (access !== 1 && !CatMbr))
                  ? []
                  : [
                      {
                        label: 'WH COST:',
                        val:
                          (product.future_cost &&
                            parseInt(product.future_cost)) !== 0
                            ? '$' + product.future_cost
                            : '$' + product.Cost
                      }
                    ]),
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                ...(utilities.isEmptyOrNullString(product.shipdate3)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate3),
                        type: 'qty3',
                        is_text_box: true,
                        val: quantity[2]
                      }
                    ]),
                ...((access === 1 && (access !== 1 && !CatMbr))
                  ? []
                  : [
                      {
                        label: 'EVT COST:',
                        val: utilities.hyphenForNull('$' + product.spc_cost),
                        labelClass:'First_level_details_red'
                      }
                    ]),
                {
                  label: 'NON-STOCKED:',
                  val: !product.stocked || product.stocked !== '1' ? 'Y' : 'N'
                },
                ...(utilities.isEmptyOrNullString(product.shipdate4)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate4),
                        type: 'qty4',
                        is_text_box: true,
                        val: quantity[3]
                      }
                    ]),
                ...(utilities.isEmptyOrNullString(product.future_cost)
                  ? [
                      {
                        label: 'RESTRICTED',
                        val: 'Pricing available at Reunion',
                        className: 't-center'
                      }
                    ]
                  : []),
                ...(utilities.isEmptyOrNullString(product.Restricted)
                  ? [
                      {
                        label: 'RESTRICTED',
                        val: product.Restricted ? 'Restricted Product' : '',
                        className: 't-center restricted-product'
                      }
                    ]
                  : [])
              ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                { label: 'SHIP WEEK:', val: 'QUANTITY' },
             
                { label: 'UPC:', val: product.UpcNum },
                {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                ...(utilities.isEmptyOrNullString(product.shipdate1)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate1),
                        type: 'qty1',
                        is_text_box: true,
                        val: quantity[0]
                      }
                    ]),
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                {
                  label: 'SAVINGS:',
                  val: utilities.hyphenForNull(product.Savings) + ' %'
                },
                ...(utilities.isEmptyOrNullString(product.shipdate2)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate2),
                        type: 'qty2',
                        is_text_box: true,
                        val: quantity[1]
                      }
                    ]),
                {
                  label: 'NON-STOCKED:',
                  val: !product.stocked || product.stocked !== '1' ? 'Y' : 'N'
                },
                
                ...(utilities.isEmptyOrNullString(product.shipdate3)
                  ? []
                  : [
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate3),
                        type: 'qty3',
                        is_text_box: true,
                        val: quantity[2]
                      }
                    ]),
             
  
                ...(utilities.isEmptyOrNullString(product.shipdate4)
                  ? ''
                  : 
                      {
                        label: utilities.getDateFromTimeStamp(product.shipdate4),
                        type: 'qty4',
                        is_text_box: true,
                        val: quantity[3]
                      }
                    )
              ]
            ])
      ];
    } else if (tableType === MONTHLY_SPECIALS) {
      tbl_vals = [
        ...((access !== 1 || (access === 1 && CatMbr))
          ? [
              [
                { label: 'ITEM#:', val: product.Item_Number },
              
                { label: 'MODEL#:', val: product.Model },
               
                { label: 'UPC:', val: product.UpcNum },
                {
                  label: 'NON-STOCKED:',
                  val: product.stocked
                },
                {
                  label: 'SAVINGS:',
                  val: utilities.hyphenForNull(product.Savings)
                },
                !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#:', val: product.aliasSku}),             
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                ((access !== 1 && (access !== 1 && !CatMbr))
                &&
                    {
                      label: 'WH COST:',
                      val : costWithTierHotDeals
                    }
                  ),
                ((access !== 1 && (access !== 1 && !CatMbr))
                &&
                    {
                      label: 'EVT COST:',
                      val: evtCost,
                      labelClass:'First_level_details_red'
                    }
                  ),
                  {
                    label: 'OVERRIDE RETAIL:',
                    val: overrideRetail,
                    type: 'or',
                    is_text_box: true
                  },
                  (!utilities.isEmptyOrNullString(product.shipDate1)
                    &&
                        {
                          label: utilities.getDateFromTimeStamp(product.shipDate1),
                          type: 'qty1',
                          is_text_box: true,
                          week: true,
                          val: quantity[0]
                        }
                      ),
                  (!utilities.isEmptyOrNullString(product.shipDate2)
                   &&
                      {
                        label: utilities.getDateFromTimeStamp(product.shipDate2),
                        type: 'qty2',
                        is_text_box: true,
                        week: true,
                        val: quantity[1]
                      }
                    ),
             
                (!utilities.isEmptyOrNullString(product.shipDate3)
                &&
                      {
                        label: utilities.getDateFromTimeStamp(product.shipDate3),
                        type: 'qty3',
                        is_text_box: true,
                        week: true,
                        val: quantity[2]
                      }
                ),             
  
                (!utilities.isEmptyOrNullString(product.shipDate4) && 
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate4),
                    type: 'qty4',
                    is_text_box: true,
                    week: true,
                    val: quantity[3]
                  }
                ),

              ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
                { label: 'UPC:', val: product.UpcNum },
                {
                  label: 'NON-STOCKED:',
                  val: product.stocked
                },
                {
                  label: 'SAVINGS:',
                  val: utilities.hyphenForNull(product.Savings)
                },
                !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
                && ({label: 'Alternate SKU#:', val: product.aliasSku}),
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                (!utilities.isEmptyOrNullString(product.shipDate1)
                  && 
                      {
                        label: utilities.getDateFromTimeStamp(product.shipDate1),
                        type: 'qty1',
                        is_text_box: true,
                        val: quantity[0]
                      }
                    ),
                (!utilities.isEmptyOrNullString(product.shipDate2)
                  &&
                      {
                        label: utilities.getDateFromTimeStamp(product.shipDate2),
                        type: 'qty2',
                        is_text_box: true,
                        val: quantity[1]
                      }
                    ),
              
                (!utilities.isEmptyOrNullString(product.shipDate3)
                  &&  {
                        label: utilities.getDateFromTimeStamp(product.shipDate3),
                        type: 'qty3',
                        is_text_box: true,
                        val: quantity[2]
                      }
                    ),
                (!utilities.isEmptyOrNullString(product.shipDate4)
                  && {
                        label: utilities.getDateFromTimeStamp(product.shipDate4),
                        type: 'qty4',
                        is_text_box: true,
                        val: quantity[3]
                      }
                    )
              ]
            ])
      ];
    } else if (tableType === TV_PRODUCT_SCAN) {
      tbl_vals = [
        ...((access !== 1 || (access === 1 && CatMbr))
          ?  [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST"), val: memberCost },
              
                { label: 'MODEL#:', val: product.Model },
                { label: 'SUGG. RETAIL', val: srpCost },
              
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color  '
                },
                {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                }
              ]
            ]
            :
            [
              [
                { label: 'ITEM#:', val: product.Item_Number },
                { label: 'MODEL#:', val: product.Model },
              
                {
                  label: 'AVAILABLE:',
                  val: utilities.checkForHubAndSpokeIndictor(product),
                  labelClass:
                    product.Available && parseInt(product.Available) > 0
                      ? ''
                      : 'First_level_details_red cost_red_color '
                },
                {
                  label: 'OVERRIDE RETAIL:',
                  val: overrideRetail,
                  type: 'or',
                  is_text_box: true
                },
                {
                  label: 'PACK:',
                  val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                  }`
                },
                isMaxQty &&(
                  {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
                ),
                {
                  label: 'QUANTITY:',
                  val: quantity,
                  type: 'qty',
                  is_text_box: true
                }
              ]
            ])
      ];
    } else if (tableType === COMPETITOR_PRODUCTS) {
      tbl_vals = [
        [
          { label: 'COMPETITOR NAME:', val: product.competitor_name },
          { label: 'ITEM#:', val: product.item_id },
        
          {
            label: 'MODEL#:',
            val: utilities.hyphenForNull(product.model_number)
          },
          {
            label: 'NAME:',
            val: product.item_name
          },
          (access !== 1 && (access !== 1 && !CatMbr))
            && { label: 'SALE PRICE:', val: product.sale_price },
          {
            label: 'PACK:',
            val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
              product.S_Pack_Qty ? product.S_Pack_Qty : ''
            }`
          },
          isMaxQty &&(
            {label: 'max qty', val: product.maxQty ? product.maxQty : '-'}
          ),
        ]
      ];
    }
    return (
      <>
        {tbl_vals.map((item, i) => (
          <>
              {item.map((cell, i) => (
                <>
                  {cell.label === 'RESTRICTED' ? (
                    <Table.Cell colSpan='2' className={cell.className}>{cell.val}</Table.Cell>
                  ) : (
                    <>
                      
                      {cell.is_text_box ? (
                        cell.type === 'qty' ? (
                          <Table.Cell>
                          {tableType === 'Planograms' || (tableType === 'ImpulseMerchandising' &&
                                !(assortmentId === 'CLIP01' || assortmentId === 'CTOP01')) 
                          ?
                            <input
                              type='text'
                              id={'qty-box' + product.Item_Number}
                              data-field='qty'
                              data-item-number={index}
                              data-item = {product.Item_Number}
                              data-pack-type={product.S_Pack_Type}
                              data-pack-qty={product.S_Pack_Qty}
                              data-recQty={product.recQty}
                              data-maxQty={product.maxQty}
                              onChange={handleQtyChange}
                              onBlur={handleQtyChangeblur}
                              value={cell.val ? cell.val : ''}
                              key={itemRef[product.Item_Number]}
                              ref= {itemRef[product.Item_Number]}
                              className={inputFocus.includes(parseInt(product.Item_Number)) || validateQty(product.S_Pack_Type, product.S_Pack_Qty,parseInt(quantities[index]), product.maxQty)? 'invalidBox qty-box': 'qty-box'}
                            />
                            :
                            <div>
                            <input  
                            key={itemRef[product.Item_Number]}
                            ref= {itemRef[product.Item_Number]}
                            className={inputFocus.includes(parseInt(product.Item_Number)) || validateQty(product.S_Pack_Type, product.S_Pack_Qty,parseInt(quantities[index]), product.maxQty)? 'invalidBox qty-box': 'qty-box'}                           
                            type='text'
                            id={'qty-box' + product.Item_Number}
                            data-field='qty'
                            data-item-number={index}
                            data-pack-type={product.S_Pack_Type}
                            data-pack-qty={product.S_Pack_Qty}
                            data-maxQty={product.maxQty}
                            data-item = {product.Item_Number}
                            onChange={handleQtyChange}
                            onBlur={handleQtyChangeblur}
                            placeholder=''
                            value={cell.val ? cell.val : ''}
                          />
                            
                          </div>
                          }
                          </Table.Cell>
                        ) : cell.type === 'or' ? (
                          <>
                            <Table.Cell>
                              <input
                                data-field='or'
                                data-item-number={index}
                                data-item = {product.Item_Number}
                                data-pack-type={product.S_Pack_Type}
                                data-pack-qty={product.S_Pack_Qty}
                                onChange={handleQtyChange}
                                onBlur={handleQtyChangeblur}
                                value={cell.val ? cell.val : ''}
                                className='qty-box'

                              />
                            </Table.Cell>
                          </>
                        ) : (
                          <Table.Cell>
                            <input
                              type='text'
                              id={cell.type + product.Item_Number}
                              data-field='or'
                              data-item-number={index}
                              data-pack-type={product.S_Pack_Type}
                              data-pack-qty={product.S_Pack_Qty}
                              onChange={handleQtyChange}
                              onBlur={handleQtyChangeblur}
                              value={cell.val ? cell.val : ''}
                              className='qty-box'
                            />
                          </Table.Cell>
                        )
                      ) : (
                        <>
                          <Table.Cell
                            className={
                              cell.labelClass
                                ? cell.labelClass
                                : 'First_level_details'
                            }
                          >
                            {cell.is_number ? (
                              utilities.changePriceToNumber(cell.val)
                              ) : (cell.label === 'SUBSTITUTE' || cell.label === "REFERRAL ITEM" ) &&
                              cell.val.trim() !== '-' ? (
                              <Link
                                to={{
                                  pathname: '/product/' + utilities.refInclude(cell.val),
                                  state: {
                                    title: title,
                                    listType: listType
                                  }
                                }}
                              >
                                {cell.val}
                              </Link>
                            ) : cell.val
                            }
                          </Table.Cell>
                        </>
                      )}
                    </>
                  )}
                </>
              ))}
            </>
        ))}
      </>
    );
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(ProductClassicView);