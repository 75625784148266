import React, { Component } from "react";
import history from '../../history';
import { Table } from 'semantic-ui-react';
import { MaskPrice } from "../../views/common/MaskPrice";
import utilities from '../../utils/utilities';
import { connect } from 'react-redux';
import {getPlanograms} from './actions';
import { bindActionCreators } from 'redux';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class PlanogramLandingListing extends Component {
    showItemList = (planogram, backPath) => {
        this.props.setBackButtonPath(planogram);
        planogram = { ...planogram, backPath: backPath }
        history.push({
            pathname: '/planogram/' + planogram.planogramNumber,
            state: {
                planogram: planogram,
                seleDept: planogram.departmentName,
                seleCatg: planogram.categoryName,
                backPath: planogram.backPath
            }
        });
    };

    render() {
        const {viewType} = this.props
        return (
            <div className={window.innerWidth > 1024 ? 'ui stackable three column grid' : 'ui stackable two column grid'} id="listing_grid">
                {this.props.isNoProducts ? <div className='no_prdcts_display'><h1>No Planograms to display!</h1></div> :
                    this.props.planograms.map(pl => {
                        return (
                            <div className='column'>
                                <PlanogramCard planogram={pl} showItemList={this.showItemList} viewType={viewType}
                                    multiStoreDetails={this.props.multiStoreDetails}
                                    PogMbr={this.props.multiStoreDetails.PogMbr}
                                    backPath={this.props.backPath}
                                    getprefer={this.props.getprefer} />
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

const PlanogramCard = ({ planogram, showItemList, viewType, multiStoreDetails, PogMbr, backPath, getprefer }) => {
    let tbl_vals = []
    const memberCost = (multiStoreDetails.access !== 1 || PogMbr) ?
        <MaskPrice viewType={viewType} fieldVal={planogram.memberCost}
            getprefer={getprefer && getprefer.customerView ? getprefer.customerView : 'c1'} field='Member Cost' /> : ""
    tbl_vals = [
        ...multiStoreDetails.access !== 1 || PogMbr ?
            [
                { label: "Size:", val: utilities.hyphenForNull(planogram.sizeString) },
                { label: "Type:", val: utilities.hyphenForNull(planogram.type) },
                { label: "SKUs:", val: utilities.hyphenForNull(planogram.nbrSkus) },
                { label: "Region:", val: utilities.hyphenForNull(planogram.region && planogram.region.replace('-', '')) },
                { label: "Urbanicity:", val: utilities.hyphenForNull(planogram.urbanicity) },
                { label: "Store Typical:", val: utilities.hyphenForNull(planogram.storeTypical) },
                { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':', val: memberCost },
                { label: "Last Updated:", val: utilities.getDateFromTimeStamp(utilities.hyphenForNull(planogram.lastUpdatedDate)) }
            ]
            :
            [
                { label: "Size:", val: utilities.hyphenForNull(planogram.sizeString) },
                { label: "Type:", val: utilities.hyphenForNull(planogram.type) },
                { label: "SKUs:", val: utilities.hyphenForNull(planogram.nbrSkus) },
                { label: "Region:", val: utilities.hyphenForNull(planogram.region && planogram.region.replace('-', '')) },
                { label: "Urbanicity:", val: utilities.hyphenForNull(planogram.urbanicity) },
                { label: "Store Typical:", val: utilities.hyphenForNull(planogram.storeTypical) },
                { label: "Last Updated:", val: utilities.getDateFromTimeStamp(utilities.hyphenForNull(planogram.lastUpdatedDate)) }
            ]
    ]
    return (
        <>
            <div className='pl-card'>
                <div className='pl-card-wrap mobile-pl'>
                    <div className="text_center">{planogram.planogramSubName}</div>
                    <div className="text_center pl-asstId" onClick={() => showItemList(planogram, backPath)}>{planogram.planogramNumber}</div>

                    {window.innerWidth < 768 ?
                        <Table>
                            <Table.Body>
                                <>
                                    {tbl_vals.map((cell, i) => (
                                        <>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <span className="planogram_field_widths display_block">{cell.label}</span>
                                                    <span className="planogram_field_widths">{cell.val}</span>
                                                </Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}
                                </>
                            </Table.Body>
                        </Table>
                        :
                        <Table>
                            <Table.Body>
                                <>
                                    {tbl_vals.map((cell, i) => (
                                        <>
                                            <Table.Row>
                                                <Table.Cell><span>{cell.label}</span></Table.Cell>
                                                <Table.Cell>{cell.val}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}
                                </>
                            </Table.Body>
                        </Table>
                    }

                    <div className='text_center'>
                        <span>View Assortment: </span>
                        <i className="file pdf icon large planogramexportie" id="export_btns" onClick={() => utilities.openInNewTab(planogram.pdfUrl)} />
                    </div>
                </div>
                <button className='red fluid ui button' onClick={() => showItemList(planogram, backPath)}>View/Order Items</button>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const viewTypeStorage = localStorage.getItem('viewType');
    return {
        multiStoreDetails: state.SessionReducer.multiStoreDetails,
        getprefer: state.preference.listData,
        viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW
    };
};

const mapDispatchToProps = (dispatch)=>{
    return Object.assign({ dispatch }, bindActionCreators({getPlanograms}, dispatch));
  }

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramLandingListing);
