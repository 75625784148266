import React from 'react';

const LandingPageHeader = ({
  title
}) => (
  <div className='displayFlex bread-crumb'>
    <div className='page-header' id='Warehouse_products'>
      {title || 'Fineline Catalog'}
    </div>
  </div>
);

export default LandingPageHeader;
