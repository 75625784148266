import React from 'react'
import InternalServerError from '../../ErrorPages/500_error_page';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Filter from '../../common/Filter';
import { MONTHLY_SPECIALS } from '../../../urlConstants';
import { isArray } from 'util';
import utilities, { saleType } from '../../../utils/utilities';
import { getMonthlySpecial } from '../actions'
import _ from 'lodash';
import history from '../../../history';
import restUtils from '../../../utils/restUtils';
import { ReunionCard } from '../ReunionCard';
import { getSortByDirection } from '../../common/CustomComponents';
import { getSelect } from '../../common/ComponentUtilities';

class MonthlySpecialsEventListing extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            clearFilters: false,
            appliedFilters: {},
            showFilters: (this.props.getprefer && this.props.getprefer.filterView) ? this.props.getprefer.filterView==='E'?true:false:window.innerWidth > 768?true:false,
            eventId: '',
            orderDeadline: '',
            serverError: false,
            loading:true
        }
        this.filterString = {};
        this.pathname = '';
        this.searchBy = '';
        this.sortParam = 'promonbr';
        this.sortOrder = 'asc';
    }

    componentDidMount() {
        let queryParam = window.location.href.split('?');
        this.pathname = this.props.pathname;
        if(queryParam[1]) {
            this.getUrlFiltersMonthly(queryParam);
          }
          else{
            this.getMonthlySpecials();
          }        
    }

    componentWillReceiveProps(newProps){
        if(this.state.loading && newProps.loaded){
            this.setState({loading:false})
        }
        if(this.props.searchby!==newProps.searchby || this.props.searchvalue!==newProps.searchvalue){
            this.pathname = newProps.pathname;
            this.getMonthlySpecials(newProps);
            this.setBackButtonPath()
        }
    }
    
    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };

    filterCallback = (isAdd, filterKey, filterValue) => {
        let appliedFilters = this.state.appliedFilters;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
            appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
        } else if (keyExists) {
            appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
                ? appliedFilters[filterKey]
                : appliedFilters[filterKey].split(',');
            let value = appliedFilters[filterKey];
            let index = value.indexOf(filterValue);
            if (index > -1) {
                value.splice(index, 1);
            } else {
                value.push(filterValue);
            }
            if (!value.length && keyExists) delete appliedFilters[filterKey];
            else appliedFilters[filterKey] = value;
        }
        this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
        this.setState({
            appliedFilters,
            clearFilters: false,
            backPath : this.pathname
        },()=>{
            this.getMonthlySpecials();
        })
        
    };

    getMonthlySpecials = (newProps) => {
        const {searchby, searchvalue } = newProps?newProps:this.props;
        this.setState({ loading: true });
        let url = utilities.isEmptyOrNullString(searchvalue)
                                ?`${MONTHLY_SPECIALS}/GetMSEventNames`
                                :`${MONTHLY_SPECIALS}/GetMSSearch`;                 
        let requestPayLoad = {
            "isReqFromWow":this.props.isWow,
            "sortBy":this.sortParam,
            "sortOrder":this.sortOrder
        };
        if(! searchvalue){
            requestPayLoad = this.filterString ? { ...this.filterString, ...requestPayLoad } : requestPayLoad;
                            
            this.props.getMonthlySpecial(url,requestPayLoad);
        }
        this.setBackButtonPath()
    }
    showVendors = (e) =>{
        const eventId = e.target.dataset?e.target.dataset.promonbr:'';
        const link=`/${this.props.isWow?'wow':'monthlyEventVendors'}/`;
        history.push({
            pathname:link+eventId,
            state:{isWow:this.props.isWow,eventId},
        })
    }
    getUrlFiltersMonthly=(queryParam)=>{
        this.scrollPosition = queryParam[1].includes('scrollPosition=') ? queryParam[1].split('scrollPosition=')[1].split('&')[0] : 0;
        this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
        this.appliedFilters = utilities.getFilterArrayDecode(this.filterString);
        this.filterString = utilities.getQueryJsonAssortment(this.appliedFilters);
        this.setState({
        searchNumber: this.searchNumber,
        appliedFilters: this.appliedFilters,
        backPath: this.pathname
        }, () => {
            this.getMonthlySpecials()
        });
    }
    showItemList = (e) =>{
        const promoNbr = e.target.dataset?e.target.dataset.promonbr:'';
        const orderDeadline = e.target.dataset?e.target.dataset.orderdeadline:'';
        const { isWow } = this.props;
        const pathname = isWow?'/wowItems/'+ promoNbr:'/MonthlySpecialsItems/'+ promoNbr;
        history.push({
            pathname,
            state:{
                isAllItems:true,
                isWow:isWow,
                orderDeadline:orderDeadline
            }
        });
    }
    setBackButtonPath = () => {
        let pathName = '';
        if (this.state.appliedFilters && !_.isEmpty(this.state.appliedFilters)) {
            pathName = '&filterString=' + utilities.getQueryStrForFilterString(this.state.appliedFilters);
          }
          let pushUrl = '';
          if (pathName !== '') {
            pushUrl = this.pathname + '?' + pathName;
            window.history.pushState(window.history.state, "", pushUrl);
          } else {
            pushUrl = this.pathname;
          }
          this.setState({
            backPath: pushUrl
          })
      }
    getWowSortByFields = () => {
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown'};
        const options = [
                            {props:{value:'promonbr', id: 'PromoNbr', className:'SelectOption'}, text: 'Promo Number'},
                        ];
    
        return(      
            getSelect(selectProps, options)
        );
    }
    changeSortDirection = (e) =>{
        this.sortOrder = e.currentTarget.value;
        this.getMonthlySpecials();
    }
    changeSortParam = (e) =>{
        this.sortParam = e.currentTarget.value;
        this.getMonthlySpecials();
    }
    render(){
        const gridClassName = window.innerWidth < 1024 ? 'ui stackable two column grid':window.innerWidth<1240?'ui stackable three column grid': 'ui stackable five column grid';
        const { MSEvents,filters, isWow } = this.props;
        const sortBy = this.getWowSortByFields();
        return (
            this.state.serverError ?
                <InternalServerError />
                :
                <>
                    {this.state.loading ? 
                        <div>
                            <Loader active />
                        </div>
                        :
                        <>
                             <div className="promoNumber1">
                             <span>Event ID: #{MSEvents[0]?.relay_nbr} </span>
                             <span className='PromoDate'>Order Deadline: {MSEvents[0]?.endDate}</span>
                             </div>
                            {
                                isWow &&
                                <div className='options-div'>
                                    <div className='sortByCategories'>
                                        <span>Sort By</span>
                                        {   sortBy }
                                        {
                                            getSortByDirection(this.changeSortDirection,null,this.sortOrder)
                                        }
                                    </div>
                                </div>
                            }
                            {MSEvents &&  MSEvents.length === 0 ?
                                 <div className='no_prdcts_display'>
                                     <h1>No {saleType(this.props.monthlySizzleSaleValue)} are available at this time.</h1>
                                 </div>
	                        :
                            <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable mnthlyLanding'}>
                                { !isWow && filters && 
                                    <Filter
                                        clearFilters={this.state.clearFilters}
                                        filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                                        appliedFilters={this.state.appliedFilters}
                                        toggleShowFilter={this.toggleShowFilter}
                                        showFilters={this.state.showFilters}
                                        filters={this.props.filters}
                                        callback={this.filterCallback}
                                        isNoProducts={this.state.isNoProducts}
                                        listType = {4}
                                        screenName = {'MonthlySpecialLanding'}
                                    />
                                }
                                <div className='page_details'> 
                                    <div className={gridClassName} id="reunion_items">
                                        {MSEvents && MSEvents.map(event => {
                                            const promo_name = event.PrmoName.trim();
                                            return (
                                                <div className='column promos'>
                                                    <ReunionCard
                                                        isReunion = {isWow}
                                                        promo_nbr={event.promo_nbr}
                                                        promo_name={promo_name}
                                                        promo_end_date={event.endDate}
                                                        showVendors={this.showVendors}
                                                        showItemList = {this.showItemList}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            }
                        </>
                    }
                </>
        )
    }
}


const mapStateToProps = (state) =>{
    return{
        MSEvents : state.ReunionReducer.MSEvents,
        filters : state.ReunionReducer.filters,
        loaded : state.ReunionReducer.loaded,
        getprefer:state.preference.listData,
        monthlySizzleSaleValue: state.cart?.orders?.monthlySizzleSaleValue
    }
}

const mapDispatchToProps = dispatch =>{
    return Object.assign({dispatch},
        bindActionCreators({
            getMonthlySpecial
        },dispatch)
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(MonthlySpecialsEventListing)