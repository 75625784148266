import React, { Component } from 'react';
import utilities from '../../../utils/utilities';
import './styles.css';
import {ASSORTMENT_UNAVAILABLE_TEXT, NOT_ELIGIBLE_TEXT} from '../../common/constants';
import {ReunionAssortmentList} from './AssortmentUtilities'

class AssortmentListView extends Component {
  noProducts = () => { 
    return(
      <div className='no_items'>
        <h1>{ ASSORTMENT_UNAVAILABLE_TEXT }</h1>
      </div>
    );
  }

  render() {
    const { isNoProducts, assortment, backPath, endDate, shoppingAreaName, eventId, shoppingAreaId,shoppingAreaMetaId, flowType, promoName, fromReunionHome } = this.props;
    return (
      <div className='ui stackable one column grid' id="listing_grid">
        {isNoProducts && this.noProducts()}
        {assortment.map((ItemList) => {
          return (
            <ReunionAssortmentList 
              assortment={ItemList} 
              endDate={endDate}
              showAssortmentList={this.props.showAssortmentList}
              backPath = {backPath}
              shoppingAreaName = {shoppingAreaName}
              eventId = {eventId}
              shoppingAreaId = {shoppingAreaId}
              shoppingAreaMetaId = {shoppingAreaMetaId}
              flowType = {flowType}
              promoName = {promoName}
              fromReunionHome = {fromReunionHome}
            />
          )
        })}
      </div>
    );
  }
}

export default AssortmentListView;
