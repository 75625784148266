import React from 'react';

import LandingPageContentItem from './ContentItem';

const LandingPageContentList = ({
  departments,
  goBuildCatalog,
  loadFlipbookPreview,
  savedCatalogs,
  deleteCatalog,
}) => {
  const savedCatalogsMapped = (savedCatalogs !=='No record found' || savedCatalogs.lenght>0) && savedCatalogs.map((c) => ({
    id: c.CatalogId,
    name: c.CatalogName,
    status: c.Status,
    pdfUrl: c.Location
  }));

  const savedCatalogSection = (
    <div className="landing_page_saved_items">
      <div className="saved_title">
        SAVED CATALOGS
      </div>
      <div className="landing_page_content--container">
        {savedCatalogsMapped && savedCatalogsMapped.map((i) => (
          <LandingPageContentItem
            key={i.id}
            {...i}
            isSaved
            loadFlipbookPreview={loadFlipbookPreview}
            deleteCatalog={deleteCatalog(i)}
          />
        ))}
      </div>
    </div>
  );

  return (
    <>
      <div className="landing_page_content--container">
        <div className="landing_page_content--item">
          <div className="item_content build_button" onClick={goBuildCatalog}>
            Build your Catalog
          </div>
        </div>
        {departments.map((d, index) => (
          <LandingPageContentItem
            key={`${d.id}-${d.name}-${index}`}
            {...d}
            footerText="Download Catalog"
            loadFlipbookPreview={loadFlipbookPreview}
          />
        ))}
      </div>
      {savedCatalogSection}
    </>
  )
}

export default LandingPageContentList;