import React, { Component } from 'react';
import history from '../../history';
import {Table} from 'semantic-ui-react';
import utilities, { showSavingValue, getPrefernceType}  from '../../utils/utilities';
import {getRaCategories} from './actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { MaskPrice } from '../common/MaskPrice';
import _ from 'lodash';

class RetailAssortmentLandingGrid extends Component {
  showAssortmentList = (assortment, backPath,isCtbAssortment,fromScanning,subCat) => {
    this.props.setBackButtonPath();
    assortment = {...assortment , backPath : backPath, isFlashsales: this.props.isFlashsales, isReunionAssortment:this.props.isReunionAssortment,
      ...(isCtbAssortment) ? {isCtbAssortment: isCtbAssortment,fromScanning: fromScanning, EnforceMissingAll:assortment?.EnforceMissingAll,
      ...(fromScanning && !_.isEmpty(subCat)? {subCat:subCat}: null )}: null
    }
    this.props.getRaCategories(assortment);
    history.push({
      pathname: '/assortment/' + assortment.number,
      state: {
        assortment: assortment,
        seleDept: assortment.department.name,
        seleCatg: assortment.categoryName,
        ...(isCtbAssortment) ? {missing: assortment?.EnforceMissingAll} : null
      }
    });
  };

  render() {
    const getPrefer = getPrefernceType(this.props);
    return (
      <div className='ui stackable three doubling cards' id='listing_grid'>
        {this.props.isNoProducts ? (
          <div className='no_prdcts_display'>
            <h1>{this.props.isFlashsales ? 'No Flash Sales to display!' : this.props.isCtbAssortment? 'No CTB Assortments to display!':'No Retail Assortments to display!'}</h1>
          </div>
        ) : (
          this.props.assortment.map(Ra => {
            return (
              <RetailAssortmentGrid
                assortment={Ra}
                showAssortmentList={this.showAssortmentList}
                backPath = {this.props.backPath}
                isReunionAssortment = {this.props.isReunionAssortment}
                viewType ={this.props.viewType}
                getprefer = {getPrefer}
                isCtbAssortment = {this.props.isCtbAssortment}
                fromScanning = {this.props.fromScanning}
                subCat = {this.props.subCat}
              />
            );
          })
        )}
      </div>
    );
  }
}

const RetailAssortmentGrid = ({ assortment, showAssortmentList, backPath, isReunionAssortment,viewType,getprefer,isCtbAssortment,fromScanning,subCat }) => {
  const showSavingLblAndVal = showSavingValue(viewType, getprefer);
  return (
      <div
        className='ui card_item col_height assortment_padding card'
        id='Assortmentgrid_segment'
      >
          <p className='assortment_heading'>
            {assortment.description &&
              (assortment.description.length < 45
                ? assortment.description
                : assortment.description.substr(0, 40) + ' ...')}
          </p>
          <div className = 'content'>
          <Table className = 'very basic unstackable table'>
            <Table.Body>
              {!isCtbAssortment&& (
                <Table.Row>
                <Table.Cell>
                <span>{isCtbAssortment ? 'Serial Number#: ':'Assortment Model#: '}</span>
                </Table.Cell>
                <Table.Cell>
                <span
              className='model_name'
              onClick={() => showAssortmentList(assortment, backPath,isCtbAssortment,fromScanning,subCat)}
            >
              <u>
                {assortment.modelNbr && (
                  <u>
                    {' '}
                    {assortment.modelNbr.length < 17
                      ? assortment.modelNbr
                      : assortment.modelNbr.substr(0, 15) + ' ...'}
                  </u>
                )}
              </u>
            </span>
                  </Table.Cell>
              </Table.Row>
              )}              
              <Table.Row>
                   <Table.Cell>POG: &ensp;</Table.Cell>
                   <Table.Cell>{assortment.planoNum ? assortment.planoNum :'N/A'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                   <Table.Cell>View Assortment: </Table.Cell>
                   <Table.Cell>{assortment.planoNum ? 
                     <i className='file pdf icon large planogramexportie' id='export_btns'
                     onClick={() => utilities.openInNewTab(assortment.planoNum?parseInt(assortment.planoNum.length)>=9?
                     utilities.getplanogramPdf(assortment.planoNum):
                     utilities.getImpulsePdf(assortment.planoNum)
                     :'')} />
                     :'N/A'}
                   </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                <span>Dating: </span>
                  </Table.Cell>
                  <Table.Cell>
                  <span>{assortment.billingDateTerms > 0 ? assortment.billingDateTerms : 0 } Days</span>
                  </Table.Cell>
              </Table.Row>
              { showSavingLblAndVal && <Table.Row>
                <Table.Cell>
                <span>{utilities.getLabelsByType(viewType,isCtbAssortment ? "MARKDOWN": 'SAVING')+':'}</span>
                </Table.Cell>
                <Table.Cell>
                {isCtbAssortment ? 
                <span>
              {viewType === MEMBER_VIEW ?<span>
                    {assortment.savings}
                    </span>:
                    <MaskPrice
                     getPrefer = {getprefer}
                     viewType = {viewType}
                     fieldVal ={assortment.savings ? assortment.savings.split('%')[0] : ''}
                     field = "Saving Cost"
                    />}
                </span>:!utilities.isEmptyOrNullString(assortment.orderedModelNbr) 
              ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                  {'Not Eligible for Credit, Previously Purchased ' + assortment.orderedModelNbr + '.'}
                </span> 
                : !utilities.isEmptyOrNullString(assortment.orderedDate) 
                  ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                    {'Credit Redeemed '+ assortment.orderedDate}
                    </span>
                  : assortment.RAMarketFlag === 'Y' ?
                    <span className='credit_msg_color'>{'Save ' +assortment.savings + ' with $' + assortment.rebateAmount}</span>
                    : isCtbAssortment ? <span>{assortment.savings }</span>
                  :  viewType === MEMBER_VIEW ?<span>
                  {assortment.savings}
                  </span>:
                   <>Save&nbsp;
                  <MaskPrice
                   getPrefer = {getprefer}
                   viewType = {viewType}
                   fieldVal ={assortment.savings ? assortment.savings.split('%')[0] : ''}
                   field = "Saving Cost"
                  />
                  </>
            }
                </Table.Cell>
              </Table.Row>
            }
              {isCtbAssortment && (
                  <>
                 <Table.Row>
                  <Table.Cell><span>Region:</span></Table.Cell>
                  <Table.Cell>{utilities.hyphenForNull(assortment.Region)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><span>RDC:</span></Table.Cell>
                    <Table.Cell><span>{utilities.hyphenForNull(assortment.rdc_nbr)}</span></Table.Cell>
                  </Table.Row>
                  </>
                )}
            {isCtbAssortment && (
              <Table.Row>
                {/*<Table.Cell>
                <span></span>
                  </Table.Cell>
                  <Table.Cell>
                  <span> {assortment?.IsMissingItem && parseInt(assortment?.IsMissingItem) === 0 ? 'Missing': 'All Items'}</span>
            </Table.Cell>*/}
                  <Table.Cell>
                <span></span>
                  </Table.Cell>
                  <Table.Cell>
                  <span> {assortment?.last_chance && (assortment.last_chance)}</span>
                  </Table.Cell>
              </Table.Row>
            )}
            
            </Table.Body>
          </Table>
          {assortment.itemRestricted && isReunionAssortment &&
            <div className='margin-leftReunion'>
              <span className='Assortmentfield credit_msg_color'>Assortment is Restricted.
                {assortment.itemRestrictedAlternateNbr!==0 && 
                  <>&ensp;{assortment.itemRestrictedAlternateNbr} is an alternate assortment.</>
                }
              </span>
            </div>
          } 
           {/* Removed as per the business instruction */}
          {/* <div className='margin-leftRA'>
            <span>Market First Offered:</span> &emsp;
            <span className='retail_Market'>
              {assortment.Mkt_first_offered}
            </span>
          </div> */}
           </div>
        <button
              className='ui fluid red button'
              onClick={() => showAssortmentList(assortment, backPath,isCtbAssortment,fromScanning,subCat)}
            >
              View / Order Assortment
            </button>
      </div>
  );
};
const mapStateToProps = (state,ownProps) =>{
  const viewTypeStorage = localStorage.getItem('viewType');
  const getprefer = state.preference.listData;
  return {
    ...ownProps,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer
  }
} 
const mapDispatchToProps = (dispatch)=>{
  return Object.assign(
      { dispatch },
      bindActionCreators({getRaCategories},dispatch));
}

export default connect(mapStateToProps,mapDispatchToProps)(RetailAssortmentLandingGrid);
