import React, { Component } from 'react';
import CartProductsTable from './CartProductsTable';
import { connect } from 'react-redux';
import history from '../../history';
import { bindActionCreators } from 'redux';
import { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts } from './actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Loader, Grid, Responsive } from 'semantic-ui-react';
import { MaskPrice } from '../common/MaskPrice';
import { PLANOGRAM_PLACE_ORDER, MONTHLY_SPECIALS_PLACE_ORDERS, DROPSHIP_SUBMIT_EMAIL } from '../../urlConstants';
import { restUtils } from '../../utils/restUtils';
import { utilities, shouldHideCostForPreference } from '../../utils/utilities';
import { isShoppingAreaCart } from './constants';
import UnderConstruction from "../UnderConstruction";
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class CheckoutImpulsemerchandising extends Component {
  constructor(props) {
    super(props);
    const checkedoutCart = props.carts.find(cart => {
      return parseInt(cart.cartId) === parseInt(props.match.params.cartName);
    });
    let isWow = checkedoutCart?.items?.length && 
                  checkedoutCart.items.length>0 && 
                  checkedoutCart.items[0].isReqFromWow;
    const {isReunionShoppingAreaCart,flowType,isMultiStore,multiStoreCarts} = props?.location?.state;
    let poNum = {};
    multiStoreCarts.forEach(cart => {
      if(cart.TypeId !==10) {
        poNum[cart.storeId] = checkedoutCart && checkedoutCart.cartInfoName;
      }
    })
    this.state = {
      cartName: _.get(props, 'match.params.cartName', ''),
      cart: checkedoutCart,
      isLoading: false,
      isError: false,
      errorMsg: '',
      tstmsg: '',
      disablePlaceorderbtn: this.props.address.viewOrders,
      isReunionShoppingAreaCart,
      flowType,
      isWow,
      poNum: isWow || isReunionShoppingAreaCart?{}: poNum,
      cartSelected:[],
      isMultiStore : isMultiStore,
      multiStoreCarts : multiStoreCarts,
      isOrderWindowClosed : false
    };
  }
  componentDidMount(){
    if(this.props.address.viewOrders === true){
      utilities.showToast(`Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.`, true);
    }
  }
  totalImpulseQty = () => {
    const sumofArray = (sum, num) => {
      return sum + num;
    }
    let impulseCost = this?.state?.cart?.items?.map(item => {
      return item.itemQty;
    });
    return impulseCost.reduce(sumofArray);
  }
  totalQtyForMultipleShipWeeks = () =>{
    const {items=[]} = this.state?.cart;
    return items.reduce((qty1,item2)=>{
      const qty = utilities.zeroForNull(item2.userQty) + utilities.zeroForNull(item2.userQty2) + utilities.zeroForNull(item2.userQty3) + utilities.zeroForNull(item2.userQty4);
      return qty1+qty;
    },0)
  }
  cancelOrder = (cartId) => {
    history.push('/cart');
    this.props.deleteCart(cartId);
    this.props.notifyCarts();
  };

  toastDelay = (centretoast) => {
    this.setState({ tstmsg: centretoast });
    setTimeout(() => {
      this.setState({ tstmsg: '' });
    }, 5000);
  }

  placeOrdermonthlySpecials = (multiStoreCarts = [],isMultiStore) => {
    const { cart } = this.state;
    var alphanumeric = /[^A-Za-z0-9]/g;
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }
    let centretoast = '';
    multiStoreCarts.forEach(cart => {   
      if(cart.items.length > 0){ 
      let poNumber = this.state.poNum[cart.storeId];
      if (utilities.isEmptyOrNullString(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`Please enter Purchase Order#`, true);
        return;
      } else if (poNumber.toLowerCase() === 'stockup') {
        centretoast = utilities.centreshowToast(`Stockup is reserved word`, true);
        return;
      } else if (poNumber.length > 10) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast`Purchase Order# must be less than 10 digits`;
        return;
      } else if ((alphanumeric).test(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`PO#: Special characters are not allowed`, true);
        return;
      }
    }
    });
    if(centretoast !== ''){
      this.toastDelay(centretoast)
      return;
    }   
    this.setState({ isLoading: true});    
    const { userName, storeId, checkDigit, address } = this.props;
    const url = MONTHLY_SPECIALS_PLACE_ORDERS;
    let reqPayload = multiStoreCarts.map(cart => {
      const itemsDetails = cart.items.map((item) => {

      return {
        asstmtID: item.AsstmtID,
        vendorID: item.vendorId,
        itemNum: item.item_nbr,
        userQty: item.userQty,
        userQty2: item.userQty2,
        userQty3: item.userQty3,
        userQty4: item.userQty4,
        promoCost: item.promoCost,
        overrideRetail: item.overrideRetail,
        packType: item.s_pack_type,
        packQty: item.s_pack_qty,
        source: item.itemSource,
        relay_nbr: item.relay_nbr,
        promoNbr:item.promoNbr,
        relayNbr:item.relay_nbr,
        relayYear:item.relay_year,
      }
      });
      return {
        poNbr: this.state.poNum[cart.storeId],
        cartName:cart.cartName,
        cartInfoId:cart.TypeId,
        user: {
          userID: cart.userId,
          storeId: cart?.storeId ? cart.storeId :storeId,
          primaryWarehouseNum: address.rdc,
          checkDigit: checkDigit,
          molUserName: userName,
          cartID: cart.cartId,
        },
        items: itemsDetails
      }
    });

    restUtils.postData(url, reqPayload).then((response) => {
      if (response) {
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
        if (response.error && (response.error !== null || response.error !== "")) {
          utilities.showToast(response.error, true)
        } else {
          history.push({
            pathname: `/orderConfirmation/${this.state.isWow?"WOW":this.state.cart.cartInfoName}`,
            state: {
              cart : multiStoreCarts.filter(ele=> ele.items.length > 0),
              ...this.state.cart,
              poNum: this.state.poNum,
              orderTotal: cart.totalCost,
              flowType:this.state.flowType
            }
          });
        }
      }
    }).catch((err) => {
      console.log(err);
      if(err?.response?.status === 412){
        this.setState({ isOrderWindowClosed : true, disablePlaceorderbtn: false, isLoading: false });
      }else{
        utilities.showToast(`Failed to Submit order. Please try later`, true);
      }
      this.setState({ tstmsg: '', disablePlaceorderbtn: false, isLoading: false });
    });
  }

  placeOrderVendorDropShip = (multiStoreCarts = [],isMultiStore) => {
    const { cart } = this.state;
    let promoNumber = cart?.items[0]?.promoNbr;
    var alphanumeric = /[^A-Za-z0-9]/g;
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }
    let centretoast = '';
    multiStoreCarts.forEach(cart => {  
      if(cart.items.length > 0){  
      let poNumber = this.state?.poNum[cart.storeId];
      if (utilities.isEmptyOrNullString(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`Please enter Purchase Order#`, true);
        return;
      } else if (poNumber.toLowerCase() === 'stockup') {
        centretoast = utilities.centreshowToast(`Stockup is reserved word`, true);
        return;
      } else if (poNumber.length > 10) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast`Purchase Order# must be less than 10 digits`;
        return;
      } else if ((alphanumeric).test(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`PO#: Special characters are not allowed`, true);
        return;
      }
    }
    });
    if(centretoast !== ''){
      this.toastDelay(centretoast)
      return;
    }   
    this.setState({ isLoading: true, disablePlaceorderbtn: true });    
    const { userName, storeId, address, multiStoreDetails, checkDigit } = this.props;
    const url = DROPSHIP_SUBMIT_EMAIL;
    let reqPayload = multiStoreCarts?.map(cart => {
      const itemsDetails = cart?.items?.map((item) => {

      return {
        itemNbr: item.item_nbr,
        modelNumber: item.model,
        vendorId: item.vendorId,
        vendorName: item.vendorName,
        qty: item.userQty,
        monthQty: []
      }
      });

      const store_id = cart?.storeId? cart.storeId :storeId;
      const childStoreDetails = multiStoreDetails?.childStoreDetails[store_id] ? multiStoreDetails.childStoreDetails[store_id] : address;    
      return {
        poNum:this.state?.poNum[cart.storeId],
        cartName: cart.cartName,
        molUserName: userName,
        checkDigit: checkDigit,
        cartID: cart.cartId,
        promoNbr: promoNumber,
        storeID: store_id,
        storeName: childStoreDetails ? childStoreDetails.storeName : '', 
        addressLane1: childStoreDetails ? childStoreDetails.addressLine1 : '',
        city: childStoreDetails ? childStoreDetails.city : '',
        state : childStoreDetails ? childStoreDetails.state : '',
        country: childStoreDetails ? childStoreDetails.country : '',
        zipCode: childStoreDetails ? childStoreDetails.zipCode : '',
        emailaddress : childStoreDetails ? childStoreDetails.emailAddress : '',
        flowType : this.state.flowType,
        items: itemsDetails
      }
    });

    restUtils.postData(url, reqPayload).then((response) => {
      if (response) {
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
        if (response.error && (response.error !== null || response.error !== "")) {
          utilities.showToast(response.error, true)
        } else {
          history.push({
            pathname: `/orderConfirmation/${this.state?.isWow?"WOW":this.state?.cart?.cartInfoName}`,
            state: {
              cart : multiStoreCarts.filter(ele=> ele.items.length > 0),
              ...this.state.cart,
              poNum: this.state.poNum,
              orderTotal: cart.totalCost,
              flowType:this.state.flowType
            }
          });
        }
      }
    }).catch((err) => {
      console.log(err);
      if(err?.response?.status === 412){
        this.setState({ isOrderWindowClosed : true, disablePlaceorderbtn: false, isLoading: false });
      }else{
        utilities.showToast(`Failed to Submit order. Please try later`, true);
      }
      this.setState({ tstmsg: '', disablePlaceorderbtn: false, isLoading: false });
    });
  }

  placeOrderDropShipDiscountCity = (multiStoreCarts = [],isMultiStore) => {
    const { cart } = this.state;
    let promoNumber = cart?.items[0]?.promoNbr;
    var alphanumeric = /[^A-Za-z0-9]/g;
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }
    let centretoast = '';
    multiStoreCarts.forEach(cart => {    
      if(cart.items.length > 0){ 
      let poNumber = this.state?.poNum[cart.storeId];
      if (utilities.isEmptyOrNullString(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`Please enter Purchase Order#`, true);
        return;
      } else if (poNumber.toLowerCase() === 'stockup') {
        centretoast = utilities.centreshowToast(`Stockup is reserved word`, true);
        return;
      } else if (poNumber.length > 10) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast`Purchase Order# must be less than 10 digits`;
        return;
      } else if ((alphanumeric).test(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`PO#: Special characters are not allowed`, true);
        return;
      }
    }
    });
    if(centretoast !== ''){
      this.toastDelay(centretoast)
      return;
    }   
    this.setState({ isLoading: true, disablePlaceorderbtn: true });    
    const { userName, storeId, address, multiStoreDetails, checkDigit } = this.props;
    const url = DROPSHIP_SUBMIT_EMAIL;
    let reqPayload ={};
    let  existDripshop = [];
    let  itemDropship = [];
    let  itemDropshipObjwct = {};
    reqPayload = multiStoreCarts?.map(cart => {
      let itemsDetails = cart?.items?.map((item) => {
        return {
          itemNbr: item.item_nbr,
          modelNumber: item.model,
          vendorId: item.vendorId,
          vendorName: item.vendorName,
          qty:0,
          monthQty: [{month:item.month,
                      qty:item.itemQty,
                      extCost: parseFloat(parseInt(item.itemQty)*parseFloat(item.promoCost ? item.promoCost: 0).toFixed(2)).toFixed(2)
                    }]
        }
      });
      let itemDropships= _.uniqBy(itemsDetails, 'itemNbr')
      let extractDropShipItem = itemDropships.map(filterItem=>{
        let month = []
         itemsDetails.map(ele=>{
            if(filterItem.itemNbr === ele.itemNbr) {
              month.push(ele.monthQty[0])
            }
          })
          filterItem.monthQty = month
        return filterItem
      })
      const store_id = cart?.storeId? cart.storeId :storeId;
      const childStoreDetails = multiStoreDetails?.childStoreDetails[store_id] ? multiStoreDetails.childStoreDetails[store_id] : address;    
      return {
        poNum: this.state?.poNum[cart.storeId],
        molUserName: userName,
        cartName: cart.cartName,
        checkDigit: checkDigit, 
        cartId: cart.cartId,
        promoNbr: promoNumber,
        storeID: store_id,
        storeName: childStoreDetails ? childStoreDetails.storeName : '', 
        addressLane1: childStoreDetails ? childStoreDetails.addressLine1 : '',
        city: childStoreDetails ? childStoreDetails.city : '',
        state : childStoreDetails ? childStoreDetails.state : '',
        country: childStoreDetails ? childStoreDetails.country : '',
        zipCode: childStoreDetails ? childStoreDetails.zipCode : '',
        emailaddress : childStoreDetails ? childStoreDetails.emailAddress : '',
        flowType : this.state.flowType,
        items: extractDropShipItem
      }
    });  

    restUtils.postData(url, reqPayload).then((response) => {
      if (response) {
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
        if (response.error && (response.error !== null || response.error !== "")) {
          utilities.showToast(response.error, true)
        } else {
          
          history.push({
            pathname: `/orderConfirmation/${this.state?.isWow?"WOW":this.state?.cart?.cartInfoName}`,
            state: {
              cart : multiStoreCarts.filter(ele=> ele.items.length > 0),
              ...this.state.cart,
              poNum: this.state.poNum,
              orderTotal: cart.totalCost,
              flowType:this.state.flowType
            }
          });
        }
      }
    }).catch((err) => {
      console.log(err);
      if(err?.response?.status === 412){
        this.setState({ isOrderWindowClosed : true, disablePlaceorderbtn: false, isLoading: false });
      }else{
        utilities.showToast(`Failed to Submit order. Please try later`, true);
      }
      this.setState({ tstmsg: '', disablePlaceorderbtn: false, isLoading: false });
    });
  }

  placeOrderImpulse = (multiStoreCarts = [],isMultiStore) => {
    if((this.state?.cart?.TypeId === 10 || this.state?.isReunionShoppingAreaCart) && this.state?.flowType !== 4 && this.state?.flowType !== 5 ){
      this.placeOrdermonthlySpecials(multiStoreCarts,isMultiStore);
      return;
    }
    if(this.state?.isReunionShoppingAreaCart && this.state?.flowType === 4){
      this.placeOrderVendorDropShip(multiStoreCarts,isMultiStore);
      return;
    }
    if(this.state?.isReunionShoppingAreaCart && this.state?.flowType === 5){
      this.placeOrderDropShipDiscountCity(multiStoreCarts,isMultiStore);
      return;
    }
    const { cart, poNum, isReunionShoppingAreaCart,flowType } = this.state;
    const { userName, storeId, checkDigit, fullMemberNbr } = this.props;
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }
    var alphanumeric = /[^A-Za-z0-9]/g;
    let centretoast = '';
    multiStoreCarts.forEach(cart => {   
      if(cart.items.length > 0){
      let poNumber = this.state.poNum[cart.storeId];
      if (utilities.isEmptyOrNullString(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`Please enter Purchase Order#`, true);
        return;
      } else if (poNumber.toLowerCase() === 'stockup') {
        centretoast = utilities.centreshowToast(`Stockup is reserved word`, true);
        return;
      } else if (poNumber.length > 8) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast`Purchase Order# must be less than 8 digits`;
        return;
      } else if ((alphanumeric).test(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`PO#: Special characters are not allowed`, true);
        return;
      }        
    } 
    });
    if(centretoast !== ''){
      this.toastDelay(centretoast)
      return;
    }   
    this.setState({ isLoading: true, disablePlaceorderbtn: true });
    
    const url = PLANOGRAM_PLACE_ORDER;
    let reqPayload = [];
    reqPayload = multiStoreCarts.map(cart => {
      const itemsDetails = cart.items.map((item) => {
        return { itemNbr: item.item_nbr, overrideRetail: item.overrideRetail, qty: item.userQty.toString() }
      });
      return {
        memberNbr: cart.storeId, applicationType: 'NW', checkDigit: checkDigit, poNum: this.state.poNum[cart.cartId],
        molUserName: userName, datingTerms: '', shoppingCartId: cart.cartId, fullMemberNbr: fullMemberNbr,
        type: '', sqsItems: itemsDetails
      }
     })

    restUtils.postData(url, reqPayload).then((response) => {
      if (response) {
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
        if (response.error && (response.error !== null || response.error !== "")) {
          utilities.showToast(response.error, true)
        } else {
          history.push({
            pathname: `/orderConfirmation/${this.state.cart.cartInfoName}`,
            state: {
              cart : multiStoreCarts.filter(ele=> ele.items.length > 0),
              ...this.state.cart,
              poNum: poNum,
              orderTotal: cart.totalCost,
              isReunionShoppingAreaCart,
              flowType
            }
          });
        }
      }
    }).catch((err) => {
      console.log(err);
      if(err?.response?.status === 412){
        this.setState({ isOrderWindowClosed : true, disablePlaceorderbtn: false, isLoading: false });
      }else{
        utilities.showToast(`Failed to Submit order. Please try later`, true);
      }
      this.setState({ tstmsg: '', disablePlaceorderbtn: false, isLoading: false });
    });
  }

  placeOrderAll = () => {
    let { multiStoreCarts } = this.state;
    this.placeOrderImpulse(multiStoreCarts,true)
  }

  placeOrderSelected = () => {
    let { multiStoreCarts } = this.state;
    let cartSelected = this.state.cartSelected;
    if(cartSelected && cartSelected.length > 0){
      multiStoreCarts = multiStoreCarts && multiStoreCarts.filter(cart =>
                                      cartSelected.includes(cart.storeId))
      this.placeOrderImpulse(multiStoreCarts,true);
    }else{
      this.toastDelay(utilities.centreshowToast('Please select atleast one order to submit',true));
      return;
    }  
  }

  handleStoreCheck= (storeId) => {
    let {cartSelected} = this.state
    if(cartSelected.includes(storeId)){
      cartSelected = cartSelected.filter(item => item !== storeId)
    }else{
      cartSelected.push(storeId)
    }
     this.setState({
      cartSelected
     })
  }


  handlePONumChange = (e, storeId) => {
    let {poNum} = this.state;
    poNum[storeId] = e.target.value;
    this.setState({ poNum});
  };

  render() {
    const { cart, poNum, isMultiStore, isOrderWindowClosed} = this.state;
    let {multiStoreCarts} = this.state;
    if(!isMultiStore){  
      multiStoreCarts = [cart];
    }  
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const  hideTotalExtendCost = shouldHideCostForPreference(this.props.viewType,getPrefer);

    return (
      <>
      {isOrderWindowClosed  ? (
        <UnderConstruction
        eventMessage={'Ordering Window has Ended'}
        shoppingAreaName={cart?.DisplayName}
        />
      ) :( 
      <div className='page-wrap'>
        <div className='page-header'>Checkout {cart ? `${cart.cartName==='M4Success'?'VISMERCH':cart.cartName}-${cart.cartId}` : ''}</div>
        { this.state.disablePlaceorderbtn && <div className='Note-Message'>Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.</div>}
        {!cart ? (
          <h3>
            Order already placed!{' '}
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </h3>
        ) : this.state.isError ? (
          <div className='checkoutErrorBacttoCart'>
            <h3>
              {this.state.errorMsg ? this.state.errorMsg : 'Checkout failed!'}
            </h3>
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </div>
        ) : (
              <>
                <div className='checkout-table'>
                  {this.state.isLoading && (
                    <div class="loaderWrapper">
                    <Loader active />
                    <p>Please wait while we process your order... <br/> Please don’t press the Back Button while your order is processing </p>
                    </div>
                  )}
                  {isMultiStore &&
                  <>
                    <div className='t-right'> 
                    {/* {!isMultiStore &&  */}
                    <Link to='/cart' className='multi-store-back-to-cart'>
                        Back to Cart
                      </Link>
                      {/* } */}
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderSelected()} disabled={this.state.disablePlaceorderbtn}>
                        Place Selected Orders
                      </button>
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderAll()} disabled={this.state.disablePlaceorderbtn}>
                        Place All Orders
                      </button>
                    </div>    
                    <br/>                  
                  </>}
                  {multiStoreCarts.map(cart => 
                  <>
                  {isMultiStore &&  cart?.items?.length > 0 &&
                  <>
                    <div className='cart-accordion accordion-header t-center'>
                      <span>
                        {'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, cart.userId)}
                      </span>
                      <span className='Multi-cart-accordion-store-select'>
                      {'Select Store '} <input
                        type='checkbox'
                        name = {'storeCheck'+cart.userId}
                        onChange={() => this.handleStoreCheck(cart.storeId)}
                      />
                      </span>
                    </div>
                  </>
                  }
                           
                  {cart?.items?.length > 0 &&
                  <>
                  <Responsive minWidth='1025'>
                    <div className='place-orderRA'>
                      <div>
                      {!isMultiStore && 
                        <Link to='/cart' className='back-to-cart'>
                          Back to Cart
                        </Link>
                      }
                        <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}>
                          Cancel Order
                        </button>
                        {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                          || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                          || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                          || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                          || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                          || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                          && !this.props.hq && this.props.allowedorder)
                          ? !this.props.isProspectStore ? 
                          <button className='ui red button place-order-btn' onClick={()=>this.placeOrderImpulse([multiStoreCarts.find(carts=> parseInt(carts.cartId)===parseInt(cart.cartId))],true)} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                         :<> 
                         <button className='ui red button place-order-btn' disabled> Place Order </button>
                         <p className="disabledMessage">*Ordering restricted</p>
                         </>
                          : ''
                        }
                      </div>
                    </div>
                  </Responsive>
                  <Responsive maxWidth='1024'>
                    <div className='place-orderRA'>
                      {/* <Link to='/cart' className='back-to-cart'> Back to Cart </Link> */}
                      <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}> Cancel Order </button>
                      {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                        || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                        || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                        || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                        || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                        || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                        && !this.props.hq && this.props.allowedorder)
                        ? !this.props.isProspectStore ?
                         <button className='ui red button place-order-btn' onClick={()=>this.placeOrderImpulse([multiStoreCarts.find(carts=> parseInt(carts.cartId)===parseInt(cart.cartId))],true)} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                         :<> 
                           <button className='ui red button place-order-btn' disabled> Place Order </button>
                           <p className="disabledMessage">*Ordering restricted</p>
	                         </>
                        : ''
                      }
                    </div>
                  </Responsive>
                  </>
                  }
                  <>
                  {cart?.items?.length > 0 &&

                    <div className='po-div'>
                      <label className='po-label'>Purchase Order # *</label>
                      <input
                        type='text'
                        className='cart-po-input'
                        value={poNum[cart.storeId]}
                        onChange={(e) => this.handlePONumChange(e,cart.storeId)}
                        placeholder='Enter PO#...'
                        maxLength={this.state.cart.TypeId === 10 ? 8 : 10}
                      ></input>
                      <span className='disclaimer-span'>
                        ({this.state.cart && this.state.cart.TypeId === 10 ? 'Max 8 characters' : 'Max 10 characters'})
                      </span>
                      <p style={{ fontSize: '10px' }}>( PO number is mandatory, Special characters and spaces are not allowed. )</p>
                    </div>
                  }
                    <CartProductsTable
                      cart={cart}
                      products={cart && cart.items}
                      isCheckout={true}
                      userId = {cart.userId}
                    />
                  {cart?.items?.length > 0 &&
                  <>
                    <Responsive minWidth='1025'>
                      <Grid columns={3} id='shippingBlock'>
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                          <div>
                            <span>Ship to:</span>
                            <span className='RAshipping'>Store</span>
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Regular Delivery </span>
                          </div>
                        </Grid.Column>

                        <Grid.Column id='RA'>
                          <div className='column RAmessage t-right'>
                            <p >
                              {' '}
                                  Prices are subject to change. Items will be priced
                                  at the time the order is fulfilled, not at the
                                  time of order placement.
                                </p>
                          </div>
                          <br />

                          <div className="extended">
                            <div className='store-combo-div'>
                              <span> TOTAL QUANTITY{': '}</span>
                              <span className='cost_red_color'>
                                { isShoppingAreaCart?
                                    utilities.totalQtyForMultipleShipWeeks(cart)
                                    :this.totalImpulseQty()
                                } 
                              </span>
                            </div>
                            <br />
                            { hideTotalExtendCost ? <div className='store-combo-div totalCost'>
                              {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                              {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                                getprefer={this.props.getprefer &&
                                  this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                                }
                                viewType={this.props.viewType}
                                fieldVal={cart?.totalCost}
                                field='cart-totals'
                              />}
                            </div> : ""}
                          </div>
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                    <Responsive maxWidth='1024'>
                      <Grid id='shippingBlock'>
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                          <div>
                            <span>Ship to:</span>
                            <span className='RAshipping'>Store</span>
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Regular Delivery </span>
                          </div>
                        </Grid.Column>

                        <Grid.Column className='Ramobile_checkout'>
                          <div>
                            <p> {' '}
                                Prices are subject to change. Items will be priced
                                at the time the order is fulfilled, not at the
                                time of order placement.
                              </p>
                          </div>
                          <br />
                          <div className="extended">
                            <div className='store-combo-div'>
                              <span> TOTAL QUANTITY{': '}</span>
                              <span className='cost_red_color'>
                                { isShoppingAreaCart?
                                    utilities.totalQtyForMultipleShipWeeks(cart)
                                    :this.totalImpulseQty()
                                }   
                              </span>
                            </div>
                            <br />
                            <div className='store-combo-div totalCost'>
                              TOTAL EXTENDED COST{': '}
                              {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                                getprefer={this.props.getprefer &&
                                  this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                                }
                                viewType={'Member View'}
                                fieldVal={cart?.totalCost}
                                field='cart-totals'
                              />}
                            </div>
                          </div>
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                  </>
                    }
                  </>
                  </>
                )}
                  <div id="centresnackbar" className={this.state.tstmsg && `show`} >{this.state.tstmsg}</div>
                </div>
              </>
            )}
      </div>
      )}
      </>
    );
  }
}

const mapStateToProps = state => {
  let carts = state.cart.orders.allCarts;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    carts,
    userName: state.SessionReducer.userName,
    getprefer: state.preference.listData,
    address: state.SessionReducer.address,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    storeRDC: state.SessionReducer.rdc,
    allowedorder: state.SessionReducer.address.allowedorder,
    hq: state.SessionReducer.address.hq,
    checkDigit: state.SessionReducer.address.checkDigit,
    fullMemberNbr: state.SessionReducer.address.fullMemberNbr,
    isProspectStore : state.SessionReducer.address.isProspectStore
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts },
      dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutImpulsemerchandising);
