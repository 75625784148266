import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Divider, Input, Checkbox, Grid } from 'semantic-ui-react';
import Bin from '../../images/bin.svg';
import restUtils from '../../utils/restUtils';
import './orderPad.css';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import renderImages from '../common/RenderImages';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { getEmptyItems } from './defaultOrdersEntity';
import { createNAddCartOrderPad , addProductOrderPad } from '../../views/Cart/actions';
import AddToCartModal from '../../views/Cart/AddToCartModal';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { orderNames } from '../Cart/constants';
import { ORDERPAD, MULTIPLE_ORDER_PAD_ITEMS, getImageURl } from '../../urlConstants';
import utilities,{shouldHideOrderPadCost,getPrefernceType, shouldHideCostForPreference, shouldHideSUGG_RETAILForPreference} from '../../utils/utilities';
import {  isRestrictedQuantityOrderPad, isMaxQtyValid, closeModal, addimg } from '../common/productHandler';
import {MaskPrice} from '../common/MaskPrice';
import { Loader } from 'semantic-ui-react';
import QuickViewDetails from '../common/QuickViewDetails';
import Dialog from 'react-dialog';
import { TierIcon, ProductIndicators } from '../common/TierIcon';
import { getTieredCostNew } from '../../views/Cart/cartUtils';


class OrderPad extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();

    this.state = {
      orders: (this.props.location.state && this.props.location.state.uploadData) ? 
                this.props.location.state.uploadData : getEmptyItems(9),
      poNumber: (this.props.location.state && this.props.location.state?.poNumber) ? 
      this.props.location.state?.poNumber : null,
      totalQuantity: '-',
      totalCost: '',
      hideOverrideRetail: this.props.getprefer && this.props.getprefer.overrideRetail 
                            && this.props.getprefer.overrideRetail !== 'Y' ? true : false,
      serverError: false,
      currentCart: this.props.currentCart,
      cartProducts: null,
      postObjOrderPad : [],
      shiftToQty: false,
      itemValue:'',
      isError: false,
      settimer: 0,
      itemValuetimer: 0,
      upcOrderData:[],
      itemOrderData:[],
      disableAddToCartBtn: false,
      productSelectedForModal: null,
      isAddToCartModalOpen: false,
      loading: false,
      binCart:{},
      fromCipher: (this.props.location.state && this.props.location.state?.fromCipher) ? 
      this.props.location.state?.fromCipher : null,
      visible: false,
      item: '',
      click: '',
      expand: '',
      fromOrderUpload: (this.props.location.state && this.props.location.state?.orderUpload) ? 
      this.props.location.state?.orderUpload : null,
    };    
    this.closeModal = closeModal.bind(this);
  }

  componentDidMount() {   
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    this.props.multiStoreDetails.access !== 1  && document.getElementById("itemno0").focus();
    let itemsList = {};
    this.props.location.state && this.props.location.state.uploadData && this.state.orders.map((item, index) => {
      let itemValueInput = item.itemno.toString();    
      if (itemValueInput.length === 6 || (itemValueInput.length > 0 && aliasSKURetailerGrpID !== '')) {
        itemsList[index] = item;  
      }
      return item;
    });
    if(Object.keys(itemsList).length > 0){
      if(this.state.fromCipher) {
      const getItems = this.state.orders.map(ele => ele.itemno).filter(ele=> ele !=='');
      let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    let url = `${MULTIPLE_ORDER_PAD_ITEMS}`;  
    let search = `?itemId=`+getItems.join(',');
    this.setState({loading: true}); 
      restUtils
        .getData( url + search
          +'&storeId=' + this.props.storeId 
          +'&rdc=' + this.props.rdc
          +'&isVariablePricingReq=N'
          + '&aliasSKURetailerGrpID='+aliasSKURetailerGrpID
          + '&requestFromOrderPad=Y'
        )
        .then(response => {
          this.setState({loading: false}); 
          let itemList = response.data
          let items = [];
          let  { orders } = this.state
          let populateData = orders          

          this.setState({orders:populateData},()=>{
            let itemsList = {};
              this.props.location.state && this.props.location.state.uploadData && this.state.orders.map((item, index) => {
                let itemValueInput = item.itemno.toString();    
                if (itemValueInput.length === 6 || (itemValueInput.length > 0 && aliasSKURetailerGrpID !== '')) {
                  itemsList[index] = item;  
                }
                return item;
              });
              this.getOrderUploadData(itemsList); 
          })
        })
        .catch(error => {
          console.log(error);
          this.setState({ serverError: true });
        });
      } else {
        this.getOrderUploadData(itemsList);
      }
    }else{
      this.populateOrderUploadData();
    }
    const totalOrder = this.state.orders.filter(item=> item.itemno !== '')
    this.setState({totalOrder})
  }

  componentDidUpdate(prevProps,prevState){
    if(prevState.orders[prevState.orders.length-1]['quantity']){
      this.setState({
        orders:[...prevState.orders,{
          itemno:'',quantity:'',overrideRetail:'',retailCost:'',suggestedRetail: '',memberCost:'', memberCostTier: '', imgName:'',extCost:'',productName:'',errorMsg:''
        }]
      })
    }
   /* if(prevState.orders !== this.state.orders) {
      const totalOrder = this.state.orders.filter(item=> item.itemno !== '')
      this.setState({totalOrder})
    }*/
  }
  computeExtCost = (orderpad) => {
    const tieredCostNew = getTieredCostNew(orderpad);
    return tieredCostNew ? tieredCostNew * (utilities.isBinLabel(orderpad.quantity) ? 0 : orderpad.quantity):''
  }
  handleItemNumChange = (e, index) => {
    clearTimeout(this.state.itemValuetimer);
    if (!e.target.validity.valid) return;
    let itemValueInput = '';
    let itemno = '';
    itemno = e.target.name;
    itemValueInput = e.target.value;
    this.getOrderPadData(itemno, itemValueInput, '', index)
  }; 

  getOrderPadData = (itemno, itemValueInput, errorMsg, index) => {
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    let orders = this.state.orders;
    const url  = `${ORDERPAD}`;    
    orders[index][itemno] =itemValueInput;
    orders[index].imgName =itemValueInput;
    orders[index].productName = '';
    orders[index].errorMsg = errorMsg;
    orders[index].duplicateItem = '';
    orders[index].retailCost = '';
    orders[index].suggestedRetail = '';
    orders[index].memberCost = '';
    orders[index].memberCostTier = '';
    orders[index].extCost = '';
    orders[index].maxQty = '';
    orders[index].whStockStatusMessage ='';
    orders[index].restrictions = [];
    if(this.state.fromCipher || this.state.fromOrderUpload) {
      let itemList = this.state.orders.map(item=> parseInt(item.itemno) || parseInt(item.Item_Number))
      itemList.splice(parseInt(index), 1)
      if(orders[index].errorItem && itemList.includes(parseInt(itemValueInput))) {
         orders[index].duplicateItem = '[Duplicate Item]';
      }
    }
    let totals = {
      totalCost: this.state.totalCost,
      totalQuantity: this.state.totalQuantity
    };
    const timer = setTimeout(() => {
    if (itemValueInput.length > 6 && itemValueInput.length < 11) {
      orders[index].errorMsg = '[Item Not Found]';
    } else if (itemValueInput.length >= 11) {
      restUtils
        .getData( url+`upc=`+ itemValueInput
                  +'&storeId=' + this.props.storeId 
                  +'&rdc=' + this.props.rdc
                  +'&grpCollectionId=' + this.props.groupCollectionId)
        .then(response => {
          const OrderPadData = response.data;
          if (OrderPadData.Product_Title === null || OrderPadData.Item_Number === null) {
            orders[index].errorMsg = '[Item Not Found]';
          }  else if(OrderPadData.rdcDoesNotStockItem) {
            orders[index].errorMsg = '[Item NOT STOCKED, please remove]';
          } else {
            let currentItem = orders[index];
            orders[index] = {...currentItem, ...OrderPadData.pricing,S_Pack_Type:OrderPadData.S_Pack_Type,S_Pack_Qty:OrderPadData.S_Pack_Qty}
            orders[index].errorMsg = '';
            orders[index].itemno = OrderPadData.Item_Number;
            orders[index].imgName =  OrderPadData.Item_Number
            const rdc = (OrderPadData.quantity && OrderPadData.quantity.length === 1) ? OrderPadData.quantity[0].Type === 'C'  
                        ? OrderPadData.quantity[0].CsRdc : OrderPadData.quantity[0].Rdc : '-';
            orders[index].rdc = rdc;
            const available = OrderPadData.Available ? OrderPadData.Available : '0';
            orders[index].productName = `${OrderPadData.Product_Title || OrderPadData.shortdesc || OrderPadData.Product_Name}, Available Qty: ${available},  ${OrderPadData.maxQty ? `Max Qty: ${OrderPadData.maxQty}`:''} Pack: ${OrderPadData.S_Pack_Type}${OrderPadData.S_Pack_Qty}, RDC: ${rdc}${OrderPadData.Sub_Item_Nbr ? (`, ${OrderPadData.Sub_Type_Desc} Item Number: ${OrderPadData.Sub_Item_Nbr}`):''}`;
            orders[index].retailCost = utilities.getCostForQtyOrderPad(OrderPadData.pricing,orders[index].quantity);
            orders[index].suggestedRetail = OrderPadData.SuggestedRetail;
            orders[index].memberCost = utilities.getCostForQtyPlpNew(
              OrderPadData.pricing,
              orders[index].quantity || ''
            );
            orders[index].memberCostTier = utilities.getCostForQtyPlpNew(
              OrderPadData.pricing,
              orders[index].quantity || '',
              true
            );
            orders[index].quantity =
              orders[index].quantity === '' ? '' : +orders[index].quantity;
              //orders[index].cost = this.computeCost(orders[index]);
              orders[index].extCost = utilities.isBinLabel(orders[index].quantity) ? 0 : this.computeExtCost(orders[index]);
            orders[index].maxQty = OrderPadData.maxQty
            orders[index].whStockStatusMessage = OrderPadData.whStockStatusMessage
            orders[index].restrictions = OrderPadData?.Restrictions || []
            orders[index].available = available
            document.getElementById('quantity' + index).focus();
            totals = this.getTotals(orders);
          }
          this.setState({
            orders,
            totalCost: totals.totalCost,
            totalQuantity: totals.totalQuantity,
            serverError: false
          });
          return;
        })
        .catch(error => {
          console.log(error);
          this.setState({ serverError: true });
        });
    } else if (itemValueInput.length === 6 || (itemValueInput.length > 2 && aliasSKURetailerGrpID !== '')) {      
      restUtils
        .getData( url+`itemId=`+ itemValueInput
                + '&storeId=' +this.props.storeId 
                + '&rdc=' +this.props.rdc
                + '&grpCollectionId=' +this.props.groupCollectionId
                + '&aliasSKURetailerGrpID='+aliasSKURetailerGrpID
                + '&requestFromOrderPad=Y')
        .then(response => {
          const OrderPadData = response.data;
          if (OrderPadData.Product_Title === null || OrderPadData.Item_Number === null) {
            orders[index].errorMsg = '[Item Not Found]';
          } else if(OrderPadData.rdcDoesNotStockItem) {
            orders[index].errorMsg = '[Item NOT STOCKED, please remove]';
          } else {
            let currentItem = orders[index];
            orders[index] = {...currentItem,...OrderPadData.pricing,S_Pack_Type:OrderPadData.S_Pack_Type,S_Pack_Qty:OrderPadData.S_Pack_Qty};
            orders[index].errorMsg = '';
            orders[index].itemno = OrderPadData.Item_Number;
            orders[index].imgName =  OrderPadData.Item_Number
            const rdc = (OrderPadData.quantity && OrderPadData.quantity.length === 1) ? OrderPadData.quantity[0].Type === 'C'  
                        ? OrderPadData.quantity[0].CsRdc : OrderPadData.quantity[0].Rdc : '-';
            orders[index].rdc = rdc;
            const available = OrderPadData.Available ? OrderPadData.Available : '0';
            orders[index].productName = `${OrderPadData.Product_Title || OrderPadData.shortdesc || OrderPadData.Product_Name}, Available Qty: ${available}, ${OrderPadData.maxQty ? `Max Qty: ${OrderPadData.maxQty}`:''} Pack: ${OrderPadData.S_Pack_Type}${OrderPadData.S_Pack_Qty}, RDC: ${rdc}${OrderPadData.Sub_Item_Nbr ? (`, ${OrderPadData.Sub_Type_Desc} Item Number: ${OrderPadData.Sub_Item_Nbr}`):''}`;
            orders[index].retailCost = utilities.getCostForQtyOrderPad(OrderPadData.pricing,orders[index].quantity);
            orders[index].suggestedRetail = OrderPadData.SuggestedRetail || OrderPadData?.pricing?.srCost;
            orders[index].memberCost = utilities.getCostForQtyPlpNew(
              OrderPadData.pricing,
              orders[index].quantity || ''
            );
            orders[index].memberCostTier = utilities.getCostForQtyPlpNew(
              OrderPadData.pricing,
              orders[index].quantity || '',
              true
            );
            orders[index].quantity =
            orders[index].quantity === '' ? '' : +orders[index].quantity;
            //orders[index].cost = this.computeCost(orders[index]);
            orders[index].extCost = utilities.isBinLabel(orders[index].quantity) ? 0 : this.computeExtCost(orders[index]);
            orders[index].maxQty = OrderPadData.maxQty
            orders[index].whStockStatusMessage = OrderPadData.whStockStatusMessage
            orders[index].restrictions = OrderPadData?.Restrictions || []
            orders[index].available = available
            document.getElementById('quantity' + index).focus();
            totals = this.getTotals(orders);
          }
          this.setState({
            orders,
            totalCost: totals.totalCost,
            totalQuantity: totals.totalQuantity,
            serverError: false
          });
          return;
        })
        .catch(error => {
          console.log(error);
          this.setState({ serverError: true });
        });
    }},
     500);
    this.setState({
      itemValuetimer : timer
    })
    this.setState({ orders });
  }
    
  getDiscOrdePadData = (itemValueList) => {
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    const badErrList = (this.props.location.state && this.props.location.state.badItemErrorMap) ? 
                        this.props.location.state.badItemErrorMap : '';
    let itemValueInput = Object.keys(itemValueList).map(function(key) {
      if(badErrList && badErrList[itemValueList[key].rowNbr] && (badErrList[itemValueList[key].rowNbr].includes("SKU Item Number"))){}
      else{
        return itemValueList[key].itemno;
      }
    });
    let url = `${MULTIPLE_ORDER_PAD_ITEMS}`;  
    let search = `?itemId=`+itemValueInput.filter(item => item !== undefined).join(',');
    this.setState({loading: true}); 
    const timer = setTimeout(() => {
      restUtils
        .getData( url + search
          +'&storeId=' + this.props.storeId 
          +'&rdc=' + this.props.rdc
          +'&isVariablePricingReq=N'
          + '&aliasSKURetailerGrpID='+aliasSKURetailerGrpID
          + '&requestFromOrderPad=Y'
        )
        .then(response => {
          this.setState({loading: false}); 
          this.populateOrderUploadData(response.data);
        })
        .catch(error => {
          console.log(error);
          this.setState({ serverError: true });
        });
    },
     500);
    this.setState({
      itemValuetimer : timer
    });
  }
  
  getOrderUploadData = (itemValueList) => {
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    const badErrList = (this.props.location.state && this.props.location.state.badItemErrorMap) ? 
                        this.props.location.state.badItemErrorMap : '';
    let itemValueInput = Object.keys(itemValueList).map(function(key) {
      if(badErrList && badErrList[itemValueList[key].rowNbr] && (badErrList[itemValueList[key].rowNbr].includes("SKU Item Number"))){}
      else{
        return itemValueList[key].itemno;
      }
    });
    let url = `${MULTIPLE_ORDER_PAD_ITEMS}`;  
    let search = `?itemId=`+itemValueInput.filter(item => item !== undefined).join(',');
    this.setState({loading: true}); 
    const timer = setTimeout(() => {
      restUtils
        .getData( url + search
          +'&storeId=' + this.props.storeId 
          +'&rdc=' + this.props.rdc
          +'&isVariablePricingReq=N'
          + '&aliasSKURetailerGrpID='+aliasSKURetailerGrpID
          + '&requestFromOrderPad=Y'
        )
        .then(response => {
          this.setState({loading: false}); 
          this.populateOrderUploadData(response.data);
        })
        .catch(error => {
          console.log(error);
          this.setState({ serverError: true });
        });
    },
     500);
    this.setState({
      itemValuetimer : timer
    });
  }

  populateOrderUploadData(orderData) {
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    let orders = this.state.orders;
    let OrderPadData = {};
    let totalCost;
    let totalQuantity;
    //!_.isEmpty(item.Sub_Item_Nbr)

    this.state.orders.map((order, index) => {  
      OrderPadData = {};
      orderData && orderData.map((item) => { 
        if(parseInt(item.Item_Number) === parseInt(order.itemno) || (aliasSKURetailerGrpID !== '' && parseInt(item.aliasSku) === order.itemno)) {
          OrderPadData = item;          
        }
        return item;
      });
      orders[index]['itemno'] =this.state.orders[index].itemno;
      orders[index].productName = '';
      orders[index].imgName =  this.state.orders[index].itemno;
      orders[index].errorMsg = orders.errorMsg;
      orders[index].retailCost = '';
      orders[index].suggestedRetail = '';
      orders[index].memberCost = '';
      orders[index].memberCostTier = '';
      orders[index].extCost = '';
      orders[index].maxQty = '';
      orders[index].whStockStatusMessage ='';
      let totals = {
        totalCost: totalCost,
        totalQuantity: totalQuantity
      };
      if (!_.isEmpty(OrderPadData) && !OrderPadData.rdcDoesNotStockItem) {
        let currentItem = orders[index];
        orders[index] = {...currentItem,...OrderPadData,S_Pack_Type:OrderPadData.S_Pack_Type,S_Pack_Qty:OrderPadData.S_Pack_Qty}
        orders[index].errorMsg = '';
        orders[index].itemno = OrderPadData.Item_Number;
        orders[index].extCost = this.computeExtCost(orders[index]);
        orders[index].imgName =  OrderPadData.Item_Number
        const rdc = utilities.rdcBasedonCentralship(OrderPadData.rdctype, OrderPadData.CsRdc, OrderPadData.Rdc);
        const available = OrderPadData.Available ? OrderPadData.Available : '0';
        orders[index].productName = `${OrderPadData.Product_Title || OrderPadData.shortdesc || OrderPadData.Product_Name}, Available Qty: ${available},${OrderPadData.maxQty ? `Max Qty: ${OrderPadData.maxQty}`:''} Pack: ${OrderPadData.S_Pack_Type}${OrderPadData.S_Pack_Qty}, RDC: ${rdc}${OrderPadData.Sub_Item_Nbr ? (`, ${OrderPadData.Sub_Type_Desc} Item Number: ${OrderPadData.Sub_Item_Nbr}`):''}`;
        orders[index].retailCost = this.getCostForQtyUpload(OrderPadData,orders[index].quantity);
        orders[index].suggestedRetail = OrderPadData.SuggestedRetail;
        orders[index].memberCost = utilities.getCostForQtyPlpNew(
          OrderPadData,
          orders[index].quantity || ''
        );
        orders[index].memberCostTier = utilities.getCostForQtyPlpNew(
          OrderPadData,
          orders[index].quantity || '',
          true
        );
        orders[index].rdc = rdc;
        orders[index].quantity =
          orders[index].quantity === '' ? '' : +orders[index].quantity;
          //orders[index].cost = this.computeCost(orders[index]);
        document.getElementById('quantity' + index).focus();
        totals = this.getTotals(orders);
        orders[index].restrictions = OrderPadData?.Restrictions || [];
      }
      else{
        if(order.itemno === 0 || order.quantity === 0) {
          orders[index].errorMsg = '[Item# or Quantity is not a valid integer]';
          orders['errorItem'] = true          
        }else if(OrderPadData.rdcDoesNotStockItem){
          orders[index].errorMsg = '[Item NOT STOCKED, please remove]';
          orders['errorItem'] = true
        }else if(!utilities.isEmptyOrNullString(order.itemno)){
          orders[index].errorMsg = '[Item Not Found]';
          orders['errorItem'] = true
        }       
      }
      if(order.itemno && (!order.quantity || parseInt(order.quantity) === 0 || parseInt(order.quantity) < 0)) {
        orders[index].errorMsg = 'Item order qty cannot be blank or zero';
        orders['errorItem'] = true
      }
      totalCost = totals.totalCost;
      totalQuantity = totals.totalQuantity;

      return order;
    });

    if(!_.isEmpty(orderData)) {
      const ids = orders.map(o => parseInt(o.itemno))
    let duplicateItem = []
    let ordersLists = []
    orders.filter((item, index) => {
      if(!ids.includes(parseInt(item.itemno), index + 1)) {
        ordersLists.push(item)
      }
      else {
        if(!_.isEmpty(item.itemno)) {
          item['errorItem'] = true
          item['duplicateItem'] = '[Duplicate Item]'
          duplicateItem.push(item)
          }                
       
      }
    })
    let fpackItem = []
    ordersLists = ordersLists.filter(item=> {
      if(parseInt(item.quantity) !== 888 && item.S_Pack_Type === 'F' && item.quantity % item.S_Pack_Qty) {
        item['errorItem'] = true
        fpackItem.push(item)
      }
      else {
        return item
      }
    })
    let errorItem = ordersLists.filter(item=> {
      if(!_.isEmpty(item?.errorMsg)) {
        item['errorItem'] = true
        return item
      }
    })
    let discountinuedItem = ordersLists.filter(item=> !_.isEmpty(item.Sub_Item_Nbr)&& item)
    let items = ordersLists.filter(item=> (item?.errorMsg ==='' && _.isEmpty(item.Sub_Item_Nbr))  && item)
    let binItem = []
    let restrictedItem = []
    let nonErrorItem = []
    
    items.map(item=>{      
      if(item.quantity=== 888) {
        binItem.push(item)
      }
      else if(item?.restrictions?.length > 0) {
        restrictedItem.push(item)
      }
      else {
        nonErrorItem.push(item)
      }
    })
      orders = [...errorItem,...duplicateItem,...fpackItem,...discountinuedItem, ...binItem,...restrictedItem,...nonErrorItem, ...[{
        itemno:'',quantity:'',overrideRetail:'',retailCost:'',suggestedRetail: '',memberCost:'', memberCostTier: '', imgName:'',extCost:'',productName:'',errorMsg:undefined      
      }]]
      orders.map((orderItem,index)=>{
        orderData.forEach(item=>{
          if(parseInt(item.Item_Number) === parseInt(orderItem.itemno)) {
            if(!_.isEmpty(item.Sub_Item_Nbr)) {
              orderItem['background'] = true
              orders.splice(index+1,0, {
                itemno:item.Sub_Item_Nbr,quantity:0,overrideRetail:'',retailCost:'',suggestedRetail: '',memberCost: '', memberCostTier: '', imgName:item.Sub_Item_Nbr,extCost:'',productName:'',errorMsg:'',description:item.Sub_Type_Desc,borderStyle:true
              })
            }
          }
        })
      })
    }
    // Sort order based on error message
    orders.sort((a,b) => {
      if(!a.errorMsg && !b.errorMsg){
        return 0;
      } else if(!a.errorMsg){
        return 1;
      } else if(!b.errorMsg){
        return -1;
      } else {
       return a.errorMsg.localeCompare(b.errorMsg)
      }  
    });
    this.setState({
      orders,
      totalCost: totalCost,
      totalQuantity: totalQuantity,
      serverError: false
    });
  }

  getCostForQtyUpload = (product, qty) => {
    if (!product.tiered || product.tiered === "False") {
      return product.cost;
    }
    qty = parseInt(qty);
    let cost = product.ME_Cost || product.Member_Cost;
    if (isNaN(qty)) {
      return cost;
    }
    if (product.cost && parseFloat(product.cost) !== 0 && qty >= product.MM_qty) {
      cost = product.cost;
    }
    if (product.MC_Cost && parseFloat(product.MC_Cost) !== 0 && qty >= product.MC_qty) {
      cost = product.MC_Cost;
    }
    if (product.MP_Cost && parseFloat(product.MP_Cost) !== 0 && qty >= product.MP_qty) {
      cost = product.MP_Cost;
    }
    return parseFloat(cost).toFixed(2);
  }

  handleEditField = (e, index) => {
    let totals = {
      totalCost: this.state.totalCost,
      totalQuantity: this.state.totalQuantity
    };
    if (!e.target.validity.valid) return;
    let orders = this.state.orders;
    orders[index][e.target.name] = e.target.value;
    orders[index].errorMsg = '';
    if (e.target.name !== 'overrideRetail') {
      orders[index].retailCost = utilities.getCostForQtyOrderPad(orders[index],e.target.value);
      //orders[index].cost = this.computeCost(orders[index]);
      orders[index].extCost = utilities.isBinLabel(e.target.value) ? 0 : this.computeExtCost(orders[index]);
      totals = this.getTotals(orders);
      orders[index].memberCost = utilities.getCostForQtyPlpNew(
        orders[index],
        orders[index].quantity || ''
      );
      orders[index].memberCostTier = utilities.getCostForQtyPlpNew(
        orders[index],
        orders[index].quantity || '',
        true
      );     
      if(this.state.fromCipher || this.state.fromOrderUpload) {
      if(orders[index].S_Pack_Type === 'F') {
        if(parseInt(orders[index].quantity) !== 888 && orders[index].S_Pack_Type === 'F' && orders[index].quantity % orders[index].S_Pack_Qty) {
          orders[index].errorItem = true
        } else {
          if(!orders[index].duplicateItem && _.isEmpty(orders[index].errorMsg)) {
            orders[index].errorItem = false
          }
        }
      }
      if(utilities.isValidQuantity(orders[index].quantity)){
        orders[index].errorItem = false
      }
    }
    }
    this.setState({
      orders,
      totalCost: totals.totalCost,
      totalQuantity: totals.totalQuantity
    });
    let i = parseInt(index) +1
    clearTimeout (this.state.settimer);
    const timer = setTimeout(() => {
      if(document.getElementById('itemno' +i)) {
        document.getElementById('itemno' +i).focus();
      }      
    }, 5000);
    this.setState({
      settimer : timer
    })
  };

  computeCost = order => {
    if (order.productName === '' && order.retailCost === undefined) {
      order.errorMsg= '[Item Not Found / Item NOT STOCKED]';
      return ''
    }
    return utilities.isEmptyOrNullString(order.quantity)?'':parseFloat(order.retailCost*order.quantity).toFixed(2);
  };

  getTotals = orders => { 
    return orders.reduce(
      (a, b) => {
        let extCost = b.extCost === '' ? 0 : b.extCost;
        let qty = b.quantity === '' ? 0 : b.quantity;
        return {
          totalCost: (parseFloat(a.totalCost) + parseFloat(extCost)).toFixed(2),
          totalQuantity: (
            parseFloat(a.totalQuantity) + parseFloat(qty)
          )
        };
      },
      { totalCost: 0, totalQuantity: 0 }
    );
  };
  deleteOrder = index => {
    let orders = this.state.orders;
    let totalOrder = this.state.totalOrder
    orders.splice(index, 1);
    totalOrder.splice(index, 1);
    this.setState({ orders,totalOrder },()=>{
      let totals = {
        totalCost: this.state.totalCost,
        totalQuantity: this.state.totalQuantity
      };
      totals = this.getTotals(orders);
      this.setState({
        orders,
        totalCost: totals.totalCost,
        totalQuantity: totals.totalQuantity
      });
      clearTimeout (this.state.settimer);
    });
  };

  clearData = () => {
    this.setState({
      orders: getEmptyItems(9),
      serverError: false,
      totalQuantity: '',
      totalCost: '',
      postObjOrderPad: [],
      disableAddToCartBtn : false
    });
  };

  toggleOverrideRetailField = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        hideOverrideRetail: !prevState.hideOverrideRetail
      };
    });
  };

  loadMore = (e, index) => {
    if (index === this.state.orders.length - 1 && e.keyCode === 9) {
      const moreRows = getEmptyItems(3);
      this.setState(prevState => {
        return { ...prevState, orders: [...prevState.orders, ...moreRows] };
      });
    }
  };

  createPostObj=()=>{
    let orderPad = [];
    let costs = [];
    let i =0;
    let toastError = false;
    let toastMessage = '';
    const postObjOrderPad = [];
    let orders = this.state.orders;
    for(let index in this.state.orders) {
      let itemList = this.state.orders[index]
      if(itemList.itemno === '')
        continue;
      else if(itemList.itemno.length<6){
        toastMessage = `${toastMessage}\n ${itemList.itemno}: Invalid Item number`;
        orders[index].errorMsg = 'Invalid Item number';
        toastError = true;
      }else if (parseInt(itemList.quantity) !== 888 && itemList.S_Pack_Type === 'F' && itemList.quantity % itemList.S_Pack_Qty) {
        toastMessage = `${toastMessage}\n ${itemList.itemno}: Quantity should be multiple of ${itemList.S_Pack_Qty}`
        //orders[index].errorMsg = `Quantity should be multiple of ${itemList.S_Pack_Qty}!`;
        toastError = true;
      }
      else if (itemList.maxQty && (itemList.quantity > itemList.maxQty)) {
        toastMessage = `${toastMessage}\n ${itemList.itemno}: Maximum Order quantity is  ${itemList.maxQty}!`
        orders[index].errorMsg = `Maximum Order quantity is ${itemList.maxQty}!`;
        toastError = true;
      } else if (!utilities.isValidQuantity(itemList.quantity)) {
        toastMessage = `${toastMessage}\n ${itemList.itemno}: Enter a valid quantity`;
        orders[index].errorMsg = 'Enter a valid quantity';
        toastError = true;
      }else if (isRestrictedQuantityOrderPad(itemList.quantity)) {
        toastMessage = `${toastMessage}\n ${itemList.itemno}: Quantity should not be ${itemList.quantity}!`;
        orders[index].errorMsg = `Quantity should not be ${itemList.quantity}!`;
        toastError = true;
      }else if (itemList.quantity > 9999) {
        toastMessage = `${toastMessage}\n ${itemList.itemno}: Quantity should not exceed 9999!`;
        orders[index].errorMsg = 'Quantity should not exceed 9999!';
        toastError = true;
      } else if(itemList.errorMsg==='[Item Not Found]') {
        utilities.showToast('Few ITEMS NOT FOUND please check', true)
        return;
      } else if(itemList.errorMsg === '[Item NOT STOCKED, please remove]') {
        utilities.showToast('Please remove NOT STOCKED ITEMS', true)
        return;
      } else if(itemList.errorMsg === '[Item Not Found / Item NOT STOCKED]') {
        utilities.showToast('Please remove ITEM NOT FOUND or NOT STOCKED ITEMS', true)
        return;
      } else if(itemList.errorMsg === '[Item currently Out of Stock]') {
        utilities.showToast('Please remove Out of Stock Items', true)
        return;
      } else if(itemList.itemno !== '' && itemList.retailCost !== '') {
        costs.push(parseFloat(itemList.retailCost))
        postObjOrderPad[i] = itemList;

        postObjOrderPad.map((e) => {
          e.storeId = parseInt(this.props.storeId);
          e.CartId=this.props.currentCart.cartId;
          e.ItemSource=orderNames['REGULAR'].key;
          e.userName = this.props.userName;
          return e;
        });
       orderPad = Object.keys(itemList).reduce((obj, key) => {
        if(key === "storeId") {
          obj["storeId"] = itemList["storeId"];
        }
        if(key === "CartId") {
          obj["CartId"] = itemList["CartId"];
        }
        if(key === "itemno") {
          obj["ItemNumber"] =  itemList["itemno"];
        }
        if(key === "quantity") {
          obj["ItemQty"] =  itemList["quantity"];
        }
        if(key === "overrideRetail") {
          obj["OverrideRetail"] =  itemList["overrideRetail"] === ''?'':itemList["overrideRetail"]; 
        }
        if(key === "rdc") {
          obj["RDC"] =  itemList["rdc"];
        }
        if(key === "ItemSource") {
          obj["ItemSource"] = itemList["ItemSource"];
        }
        if(key === "quantity") {
          obj["TotalQtyInOrder"] = itemList["quantity"];
        }
        if(key === "userName") {
          obj["userName"] =  itemList["userName"];
        }
        if(key === "retailCost"){
          obj["retailCost"] =  itemList["retailCost"];
        }
        return obj;
      }, {})
        postObjOrderPad[i] = orderPad;
        i++;
      } 
    }

    this.setState({
      postObjOrderPad,
      orders,
      disableAddToCartBtn : true
    });
    if (toastError) {
      utilities.showToast(toastMessage,true);
      this.setState({disableAddToCartBtn:false});
      return;
    }
    if (postObjOrderPad.length === 0) {
      utilities.showToast('Please add items',true)
      this.setState({disableAddToCartBtn:false});
      return;
    } 
    else {
      return {postObjOrderPad,costs};
    }
  }
  openCartModal = (product, index) => {
    this.setState({
      productSelectedForModal: product,
      isAddToCartModalOpen: true
    });
  };
  
  closeCartModal = () => {
    this.setState({ isAddToCartModalOpen: false});
    if (this.state.postObjOrderPad.length > 0) {
      this.setState({disableAddToCartBtn:false});
      return;
    } 
  };

  postObjOrderpad = (postObjOrderPad,costs) => {
    postObjOrderPad.forEach((e) => {
      e.CartId = this.props.currentCart.cartId;
    });
    const currentProductcount = postObjOrderPad.map(product=>{
      return product.length
    });
    let cartCounts =this.props.cartCount+currentProductcount.length;
    if(cartCounts > 990){
      utilities.showToast('Cart items should not exceed 990');
      this.setState({disableAddToCartBtn:false});
      return;
    }
    const costResult = costs.reduce((item1, item2) =>(item1) +(item2),0)
    this.props.addProductOrderPad(postObjOrderPad,costResult);
    this.clearData();
  }

  orderPadCart = () => {
    let items = this.state.orders.filter(ele => ele.Item_Number || ele.ItemNumber).map(ele=> ele.Item_Number || ele.ItemNumber).map(ele=> parseInt(ele))
    const hasDuplicate = utilities.checkDuplicate(items)
    if(!_.isEmpty(hasDuplicate)) {
      utilities.showToast(`Please remove ${hasDuplicate[0]} duplicate product`,true);
      return
    }
    if(this.state.totalQuantity === undefined || this.state.totalCost === '0.00') {
      utilities.showToast('Please add valid items', true);
      return;
    } else {
      let postObj = this.createPostObj();
      if(!postObj) return;
      let binCartPostObj = {}
      let removedBinCart = postObj.postObjOrderPad.filter(item=> parseInt(item.ItemQty) !== 888)
      let filterBinLabelCart = postObj.postObjOrderPad.filter(item=> parseInt(item.ItemQty) === 888)
      postObj['cost'] = removedBinCart.map(item => parseInt(item.retailCost))
      postObj['postObjOrderPad']  = removedBinCart 
      if(filterBinLabelCart && filterBinLabelCart.length > 0) {
          binCartPostObj['costs'] = filterBinLabelCart.map(item => parseInt(item.retailCost))
          binCartPostObj['postObjOrderPad']  = filterBinLabelCart 
          this.setState({binCart: binCartPostObj})
          this.props.createNAddCartOrderPad('REGULAR','01','BinLabel').then(res=> this.postObjOrderpad(binCartPostObj.postObjOrderPad,binCartPostObj.costs));
      }
      if(removedBinCart.length===0) {
        return
      }
      const cartNames = this.props.orderPadCart.length > 0 && this.props.orderPadCart.map(item => item.cartName)
      if(!_.isEmpty(this.props.currentCart) && (this.props.currentCart.cartName !== 'OrderPad' && this.props.currentCart.cartName !== 'BinLabel') || (this.props.currentCart.cartName !== 'OrderPad' && cartNames && cartNames.includes('OrderPad')) && this.props.orderPadCart.length > 0 && !this.state.poNumber) {
        this.openCartModal(postObj, null)
      } 
      else {
         if(this.state.poNumber && this.state.poNumber !=='') {
          this.props.createNAddCartOrderPad('REGULAR','01',this.state.poNumber).then(res=> this.postObjOrderpad(postObj.postObjOrderPad,postObj.costs));
        }      
        else if(this.props.orderPadCart.length === 0 || this.props.currentCart.cartName === 'BinLabel') {
          this.props.createNAddCartOrderPad().then(res=> this.postObjOrderpad(postObj.postObjOrderPad,postObj.costs));
        }        
        else {
        this.postObjOrderpad(postObj.postObjOrderPad,postObj.costs);
        }
      }
    }
  }
  closeModalexpand = () => {
    this.setState({
      expand: '',
      item: ''
    });
  };
  openModal(data) {
    this.setState({
      visible: true,
      item: data,
      click: 'clicked',
      expand: ''
    });
    this.props.blurfun && this.props.blurfun(true);
  };
  render(){ 
    const { 
      serverError, 
      orders, 
      totalQuantity, 
      hideOverrideRetail, 
      totalCost,
      fromCipher,
      fromOrderUpload
    } = this.state;
    const getPrefer = getPrefernceType(this.props);
    const showOrderPadCost = shouldHideOrderPadCost(this.props.viewType,getPrefer);
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const showSuggRetailPrice = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
    const totalOrder = this.state.orders.filter(item=> item.itemno !== '')
    return ( 
      <div className='order-pad-container'> 
        {this.state.loading && (
          <div>
            <Loader active />
          </div>
        )}
        {serverError ? ( 
          <InternalServerError /> 
        ) : ( 
          <> 
            <div className='displayFlex page-header mr-b-3 oredrpad-text'>Order Pad - 
            <span className='order-message'>Please Note: order entry should be limited to 990 items. 
                      This will assist in processing your orders without errors.</span>
            </div>
            {this.state.poNumber && this.state.poNumber !== '' && (
              <div className='orderPadPo'>Purchase Order # : {this.state.poNumber} </div>
            )} 
              <span id="print_img" className='hideTab'>
               <ReactToPrint
                 pageStyle="@page{size:landscape;}"
                 trigger={() => <i className="print icon" id="printicon"></i>}
                 content={() => this.componentRef}
               />
             </span>
            {this.props.multiStoreDetails && this.props.multiStoreDetails.access === 1  ?
             <h4>You don't have the permission to view this page.Please contact the True Value Administrator.</h4>
             :
              <>
                <div className='ui stackable sixteen grid baseline-flex'>
                  <div className='op-show-hide-label'> 
                  <h4 className='red_color'>This tool should not be used to create an event order. All event orders (i.e. Reunion, Retail, Monthly) need to be entered utilizing the event portals.</h4>
                    <Checkbox 
                      label='Hide Override Retail (For scanning)' 
                      onClick={this.toggleOverrideRetailField} 
                      className='op-checkbox column' 
                      checked={hideOverrideRetail} 
                    /> 
                  </div> 
                </div> 
                <div className='row order-pad' ref={(node) => (this.componentRef = node)}> 
                  <div className='order-pad-header'> 
                    <Grid> 
                      <Grid.Column 
                        computer={3} 
                        mobile={3} 
                        tablet={3}> 
                        <div className='mg-l-1'>Item#</div> 
                      </Grid.Column> 
                      <Grid.Column 
                        computer={2} 
                        mobile={2} 
                        tablet={2}> 
                        Quantity 
                      </Grid.Column> 
                      {!hideOverrideRetail && ( 
                        <Grid.Column computer={2} mobile={3} tablet={3}> 
                          Override Retail 
                        </Grid.Column> 
                      )} 
                      { showSuggRetailPrice && <Grid.Column 
                        computer={2} 
                        tablet={2}> 
                        { utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL",getPrefer)}
                      </Grid.Column> }
                      { displayMemberCostAndLbl && <Grid.Column 
                        computer={2} 
                        tablet={2}> 
                        {utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}
                      </Grid.Column> }
                      { displayMemberCostAndLbl && <Grid.Column 
                        computer={2} 
                        mobile={2} 
                        tablet={2}> 
                        {utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST_ORDER_PAD",getPrefer)}
                      </Grid.Column> }
                      
                      <Grid.Column 
                        computer={2} 
                        mobile={16} 
                        tablet={2} 
                        className='header-message'> 
                        Messages 
                      </Grid.Column> 
                    </Grid> 
                  </div> 
    
                  <Divider></Divider> 
                  { 
                    orders.map((orderpad, index) => { 
                    let paddingLength = 0.65
                    paddingLength = !orderpad.errorMsg && parseInt(orderpad.quantity) !== 888 &&  orderpad.S_Pack_Type === 'F' && orderpad.quantity % orderpad.S_Pack_Qty ? 2.0 : paddingLength
                    orderpad?.restrictions?.length > 0 && orderpad.restrictions.forEach((t)=>{
                      paddingLength = paddingLength + 1
                    })
                    return ( 
                      <Grid id={ orderpad?.errorItem || ((orderpad?.errorItem && orderpad.errorMsg === '[Item NOT STOCKED, please remove]') || (orderpad?.errorItem && orderpad.errorMsg === '[Item Not Found]') )  ? 'errorItem': ''} className={orderpad?.background ? 'splitGrid':orderpad?.borderStyle ? 'gridBroderStyle':''}> 
                      {orderpad?.description && (<p className='referalText'>{orderpad?.description}</p>)}
                        <Grid.Column 
                          computer={3} 
                          mobile={3} 
                          tablet={3}>  
                          <div className='itemDetailWrap'>                       
                          {renderImages.getImage({ 
                            src: Bin, 
                            alt: 'bin', 
                            className: 'delete-icon', 
                            onClick: () => this.deleteOrder(index) 
                          })}
                          {window.innerWidth > 767 && orderpad.itemno !== '' &&
                          <Link to= {{pathname:'/product/' + parseInt(orderpad.imgName)}}>
                          {renderImages.getImage({
                          src: orderpad.Image_File_Name
                            ? orderpad.Image_File_Name
                            : orderpad.altImg && orderpad.altImg === 1 ?  getImageURl() + `/nsimages/${ parseInt(orderpad.imgName)}` + ".jpg"
                            :'https://images.truevalue.com/getimage.asp?id=' +
                            parseInt(orderpad.imgName) +
                              '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
                          style: { display: this.props.img_style },
                          alt: 'product',
                          className: 'ui image image_lisitng',
                          })}
                        </Link>}
                        <div>
                          <Input 
                            name='itemno' 
                            id= {'itemno' + index} 
                            className='op-input item-number-box' 
                            onChange={e => this.handleItemNumChange(e, index)}
                            pattern='[0-9]*'
                            maxLength={11}
                            value={orderpad.imgName}></Input> 
                            {(window.innerWidth > 767 &&  orderpad.itemno !== '' ) && (
                            <div>
                              <span
                                className='view_button-compact-visible'
                                onClick={() => {
                                  this.openModal(orderpad.imgName);
                                }}
                              >
                                Quick View
                              </span>
                            </div>
                            )}
                            </div>
                            </div>
                            {this.state.visible && (this.state.item === orderpad.Item_Number || this.state.item === orderpad.itemno)&& (
                            <div className='QuickviewDialog' onMouseLeave={this.closeModal}>
                              <Dialog width='1' height='1' position='absolute'>
                                <QuickViewDetails
                                  getprefer={
                                    this.props.getprefer &&
                                    this.props.getprefer.customerView
                                      ? this.props.getprefer.customerView
                                      : 'c1'
                                  }
                                  tableType={'DOM'}
                                  Item_Number={orderpad.imgName}
                                  viewType={this.props.viewType}
                                  QuickCost ={orderpad.Member_Cost}
                                  productPlp = {orderpad}
                                />
                              </Dialog>
                            </div>
                          )}
                          {this.state.expand === 'expan' &&
                            (this.state.item === orderpad.Item_Number || this.state.item === orderpad.itemno) && (
                              <div className='DescriptionDialog' onMouseLeave={this.closeModalexpand}>
                                <Dialog width='1' height='1' position='absolute'>
                                {this.getLongDescription(orderpad,true)}
                                </Dialog>
                              </div>
                          )}
                          <p className='bold-font' style={{width:'max-content', paddingTop:`${paddingLength}rem`}}>{orderpad.productName}</p> 
                        </Grid.Column> 
                        <Grid.Column 
                          computer={2} 
                          mobile={2} 
                          tablet={2}> 
                          <Input 
                            name='quantity' 
                            id={'quantity' + index}
                            maxLength={4}
                            className='op-input qty-box' 
                            onKeyDown={e => { 
                              if (hideOverrideRetail) { 
                                this.loadMore(e, index); 
                              } 
                            }} 
                            onChange={e => this.handleEditField(e, index)} 
                            pattern='[0-9]*' 
                            value={orderpad.quantity}></Input> 
                        </Grid.Column> 
                        {!hideOverrideRetail && ( 
                          <Grid.Column computer={2} mobile={3} tablet={3}> 
                            <Input 
                              className='op-labeled-input' 
                              label='$' 
                              value={orderpad.overrideRetail} 
                              id={'overrideRetail' + index} 
                              name='overrideRetail' 
                              pattern='[0-9]*([\.?][0-9]*)?' 
                              onKeyDown={e => this.loadMore(e, index)} 
                              onChange={e => this.handleEditField(e, index)} 
                            /> 
                          </Grid.Column> 
                        )} 
                         { showSuggRetailPrice && <Grid.Column 
                          computer={2} 
                          mobile={2} 
                          tablet={2}
                          > 
                          <b>{ !_.isEmpty(utilities.toIntlNumberFormat(orderpad.SuggestedRetail || orderpad.suggestedRetail)) ? 
                          <MaskPrice
                            getprefer={this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                            viewType={this.props.viewType}
                            fieldVal={utilities.toIntlNumberFormat(orderpad.SuggestedRetail || orderpad.suggestedRetail)}
                            field="Suggested Retail"
                          />
                          : ''}
                          </b>
                          </Grid.Column>}
                          { displayMemberCostAndLbl && <Grid.Column 
                          computer={2} 
                          mobile={2} 
                          tablet={2}
                          > 
                          <b>{ !_.isEmpty(utilities.toIntlNumberFormat(orderpad.memberCost)) ? 
                          <MaskPrice
                            viewType={this.props.viewType}
                            getprefer={this.props.getprefer}
                            fieldVal={orderpad.memberCost}
                            field='Mask Price'
                          /> : ''}</b>
                          { !_.isEmpty(utilities.toIntlNumberFormat(orderpad.memberCost)) && 
                          !_.isEmpty(utilities.toIntlNumberFormat(orderpad.memberCostTier)) && 
                          orderpad.tiered && this.props.viewType === MEMBER_VIEW && 
                          <TierIcon
                            tierLevel={orderpad.memberCostTier}
                            tierLetter={ orderpad.quantity && parseInt( orderpad.quantity) > 0 ? true : false}
                          />}
                          </Grid.Column>}
                       {displayMemberCostAndLbl && <Grid.Column 
                          computer={2} 
                          mobile={2} 
                          tablet={2}
                          id="costGrid"> 
                          <b>
                          {utilities.isBinLabel(orderpad.quantity) ?  '-' :
                          <MaskPrice
                           getPrefer ={this.props.getprefer}
                           viewType = {this.props.viewType}
                           fieldVal ={orderpad.extCost}
                           field   ="Mask Price"
                          />}</b>
                        </Grid.Column> 
                      }                     
                        <Grid.Column 
                          computer={3} 
                          mobile={16} 
                          tablet={16} 
                          className={ 
                            orderpad.errorMsg ? 'error-message' : 'header-message' 
                          }> 
                          {!_.isEmpty(orderpad.duplicateItem) && <span className='op-error-span'><span>[Duplicate Item]</span></span> }
                          {!_.isEmpty(orderpad.errorMsg) && <span className='op-error-span'><span>{orderpad.errorMsg}</span></span> }
                          {!orderpad.errorMsg && parseInt(orderpad.quantity) !== 888 &&  orderpad.S_Pack_Type === 'F' && orderpad.quantity % orderpad.S_Pack_Qty  ? (<span className='op-error-span'><span>Quantity should be multiple of {orderpad.S_Pack_Qty}</span></span> ):''}
                          {!orderpad.errorMsg && (parseInt(orderpad.quantity) === 0 || parseInt(orderpad.quantity) < 0)  ? (<span className='op-error-span'><span>Enter a valid quantity</span></span> ):''}
                          {!orderpad.errorMsg && parseInt(orderpad.quantity) === 888 && (<span className='op-error-span'><span> Bin and Price Ticket Request</span></span> )}
                          {orderpad?.restrictions?.length > 0 && 
                              orderpad.restrictions.map((t)=>{
                                return <><span className='op-error-span'><span>{t.Message.replace('{@ShowSubscribeLink}','')}</span></span><br/></>
                              })
                          }
                          <p className='stockMsg'>{orderpad.whStockStatusMessage}</p> 
                        </Grid.Column> 
                      </Grid> 
                    ); 
                  })} 
                  <Divider className='nine wide column'></Divider> 
                  <Grid> 
                    <Grid.Column computer={2} mobile={2} tablet={2}> 
                      <b>Total Order Quantity</b> 
                    </Grid.Column> 
                    <Grid.Column computer={2} mobile={2} tablet={2}> 
                      <b>{totalQuantity}</b> 
                    </Grid.Column> 
    
                     
                      <Grid.Column computer={hideOverrideRetail ? 4 : 6}> 
                        <b></b> 
                      </Grid.Column> 
                    
    
                    {showOrderPadCost &&<Grid.Column computer={1} mobile={2} tablet={1}> 
                      <b> 
                        {parseInt(totalCost) === 0 ? '-' :
                        <MaskPrice
                           getPrefer ='c1'
                           viewType = {this.props.viewType}
                           fieldVal ={totalCost}
                           field   ="Mask Price"
                          />}
                          </b> 
                    </Grid.Column> 
                  }
                    <Grid.Column computer={2} mobile={16} tablet={2}></Grid.Column> 
                  </Grid> 
                  <Grid>
                    <Grid.Column computer={2} mobile={4} tablet={2}> 
                      <b>Total Lines</b> 
                    </Grid.Column> 
                    <Grid.Column computer={2} mobile={3} tablet={2}> 
                      <b>{totalOrder.length}</b> 
                    </Grid.Column>
                  </Grid>
                  <Grid> 
                    <Grid.Column computer={6} mobile={9} tablet={9}> 
                      <div className='op-clear-label' onClick={this.clearData}> 
                        <u>Clear Order</u> 
                      </div> 
                    </Grid.Column> 
                    <Grid.Column computer={3} mobile={7} tablet={7}> 
                    {this.props.multiStoreDetails && this.props.multiStoreDetails.access !== 1 ?
                      !this.props.isReunionOnlyStore ?              
                        <button 
                          className='fluid ui red button' 
                          onClick={this.orderPadCart} 
                          disabled={this.state.disableAddToCartBtn}
                          id='button_grid'> 
                          Add to Cart 
                        </button>
                        :<>
                          <button className='fluid ui red button' disabled>Add to Cart</button>
                          <p class="disabledMessage">*Ordering restricted</p>
                        </>
                      :''
                    }
                    </Grid.Column> 
                  </Grid> 
                </div> 
              </>
            }
          </> 
        )} 
        <AddToCartModal
          isAddtoCartModalopen={this.state.isAddToCartModalOpen}
          closeCartModal={this.closeCartModal}
          addToCart={this.postObjOrderpad}
          product={this.state.productSelectedForModal}
          orderPad = {true}
        ></AddToCartModal>
      </div> 
    ); 
  } 
} 
 
const mapDispatchToProps = (dispatch)=>{ 
  return Object.assign({dispatch},
    bindActionCreators({createNAddCartOrderPad,addProductOrderPad},dispatch)) 
} 
 
const mapStateToProps = (state) => { 
  let currentCart =
  state.cart.orders.allCarts &&
  state.cart.orders.allCarts.length &&
  state.cart.orders.allCarts.find(cart => {
    return parseInt(state.cart.orders.currentCart.cartId) === parseInt(cart.cartId);
  });
  let cartCount = currentCart ? currentCart.cartCount : 0; 
  let orders = state.cart.orders; 
  const viewTypeStorage = localStorage.getItem('viewType');
  return { 
    currentCart: orders.currentCart,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW, 
    cartCount,
    carts: orders.allCarts, 
    userId: state.SessionReducer.UserId,
    rdc : state.SessionReducer.address ? state.SessionReducer.address.rdc : '',
    storeId: state.SessionReducer.storeId,
    getprefer: state.preference.listData,
    userName : state.SessionReducer.address.userName,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    isReunionOnlyStore: state.SessionReducer.address.isReunionOnlyStore,
    groupCollectionId: state.SessionReducer.address ? state.SessionReducer.address.groupCollectionId : '',
    orderPadCart: orders.allCarts && orders.allCarts.filter(cart => cart.cartInfoName === 'REGULAR')
  }; 
} 
 
export default connect(mapStateToProps, mapDispatchToProps)(OrderPad);
