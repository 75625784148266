import React, { useState, useCallback, useMemo } from "react";
import { Checkbox, Radio } from "semantic-ui-react";

const FixtureLegendWrapperStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const CTB_FREE_FIXTURE_COLOR = "rgba(33, 189, 69, 0.7)";
const CTB_NON_FREE_FIXTURE_COLOR = "rgba(255, 193, 7,0.7)";

const FixtureLegendStyle = (color=CTB_FREE_FIXTURE_COLOR) => ({
  marginLeft: "5px",
  marginRight: "5px",
  borderRadius: "2px",
  padding: "5px",
  width: "15px",
  height: "15px",
  backgroundColor: `${color}`,
});

const TableRow = React.memo(
  ({
    isReadOnly = false,
    showAll = false,
    noIndicators,
    uniqId,
    selected,
    fixture = {},
    handleSelect,
  }) => {
    const isFree = fixture?.ctbFree || false;

    const handleSelection = (event, data) => {
      handleSelect(fixture, event, data);
    };

    return (
      <tr
        style={{
          backgroundColor:
          !noIndicators ? isFree ? CTB_FREE_FIXTURE_COLOR : CTB_NON_FREE_FIXTURE_COLOR : "",
        }}
      >
        {!isReadOnly ? (
          <td style={{ textAlign: "center", paddingTop: "2px" }}>
            {!fixture?.limitless && (
              <Checkbox
                id={fixture?.id}
                checked={selected}
                onChange={handleSelection}
                style={{ paddingTop: "3px" }}
              />
            )}
            {fixture?.limitless && (
              <Radio
                id={`${fixture?.pogNumber}-${uniqId}`}
                name={fixture?.pogNumber}
                checked={selected}
                onChange={handleSelection}
                style={{ paddingTop: "3px" }}
              />
            )}
          </td>
        ) : (
          !isReadOnly && (
            <td style={{ textAlign: "center", paddingTop: "2px" }}></td>
          )
        )}
        <td>{fixture?.pogNumber}</td>
        <td>{fixture?.fixtureName || "-"}</td>
        <td>{fixture?.sku}</td>
        <td>{fixture?.qty}</td>
      </tr>
    );
  }
);

function FixtureTable({
  fixtures = [],
  isReadOnly = false,
  noIndicators = false,
  showAll = false,
  selectedFixtureIds = [],
  updateFixtureSelection,
}) {
  const [state, setState] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  //If read only then - CTB Free and selected then render.
  //If show all - then show all fixtures.
  const fixtureList = useMemo(() => {
    const _fixtureList = [...fixtures];
    // item fixture listing page
    if (showAll) {
      return _fixtureList;
    }

    // checkout page show only needed prompt fixtures
    if (!isReadOnly) {
      return _fixtureList.filter((f) => f?.needed_prompt);
    }

    // order confirmation page show only selected + needed prompt false.
    return _fixtureList.filter(
      (f) => selectedFixtureIds?.includes(String(f?.id)) || !f?.needed_prompt
    );
  }, [isReadOnly, showAll, selectedFixtureIds, fixtures]);

  const handleSelect = useCallback((fixture, _e, { id, checked }) => {
    setState((state) => {
      const selected = { ...state };
      if (fixture?.limitless) {
        const _id = id.split("-");
        const group = _id[0];
        const keys = Object.keys(selected) || [];
        for (let i = 0, len = keys.length; i < len; i++) {
          const key = keys[i];
          if (key.includes(group)) {
            delete selected[key];
          }
        }
      }
      if (checked) {
        selected[id] = checked;
      } else {
        delete selected[id];
      }
      updateFixtureSelection(selected);
      const selectionLength = Object.keys(selected)?.length || 0;
      setSelectAll(selectionLength === fixtureList.length);
      return selected;
    });
    if (!checked) {
      setSelectAll(false);
    }
  }, []);

  const handleAllSelection = useCallback(() => {
    const isAllSelected = !selectAll;
    const selections = {};
    setSelectAll(isAllSelected);
    if (isAllSelected) {
      for (let i = 0, len = fixtureList?.length; i < len; i++) {
        const _id = fixtureList[i]?.id;
        selections[_id] = isAllSelected;
      }
    }
    setState(selections);
    updateFixtureSelection(selections);
  });

  const showSelectAllControl = !isReadOnly && fixtures?.length > 1;

  return (
    <>
      {showSelectAllControl && (
        <div style={{ padding: "5px" }}>
          Select All{" "}
          <Checkbox
            checked={selectAll}
            onChange={handleAllSelection}
            style={{ paddingTop: "3px" }}
          />
        </div>
      )}
      {isReadOnly && !noIndicators && (
        <div style={FixtureLegendWrapperStyle}>
          <span>Member Needs To Review/Order</span>
          <span style={FixtureLegendStyle(CTB_NON_FREE_FIXTURE_COLOR)}></span>
          &nbsp;&nbsp;
          <span>Free With CTB</span>
          <span style={FixtureLegendStyle()}></span>
        </div>
      )}
      <table className="cart-products fixture-table">
        <thead style={{ backgroundColor: "rgba(128,135,139,.3)" }}>
          <tr>
            {!isReadOnly && <th style={{ width: "55px" }}>Action</th>}
            <th>
              <div>POG</div>
            </th>
            <th>
              <div>Fixture Name</div>
            </th>
            <th>
              <div>SKU</div>
            </th>
            <th>
              <div>Qty</div>
            </th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "white" }}>
          {fixtureList.map((fixture, uniqId) => (
            <TableRow
              key={fixture.id}
              uniqId={uniqId}
              fixture={fixture}
              state={state}
              selected={
                fixture?.limitless
                  ? state[`${fixture?.pogNumber}-${uniqId}`]
                  : state[fixture.id]
              }
              handleSelect={handleSelect}
              isReadOnly={isReadOnly}
              noIndicators={noIndicators}
              showAll={showAll}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

export default FixtureTable;
