import React, { Component } from 'react';
import '../styles.css';
import restUtils from '../../../utils/restUtils';
import { MONTHLY_SPECIALS } from '../../../urlConstants';
import InternalServerError from '../../ErrorPages/500_error_page';
import { Loader, Popup } from 'semantic-ui-react';
import Filter from '../../common/Filter';
import _ from 'lodash';
import { isArray } from 'util';
import utilities, { saleType } from '../../../utils/utilities';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import { connect } from 'react-redux';
import history from '../../../history';
import { DealsDialog } from './DealsDialog';
import { getSortByDirection } from '../../common/CustomComponents';
import { getSelect } from '../../common/ComponentUtilities';
import search_img from '../../images/Search.svg';
import renderImages from '../../common/RenderImages';
import VendorDetailsPopup from '../../Vendors/component/vendor-details-popup';

class MonthlySpecialEventVendors extends Component {
    constructor(props) {
        super(props);
        const eventId = this.props.match.params.eventId 
        const event = this.props.MSEvents.find(event=>{
            return event.promo_nbr === eventId;
        });
        const isWow = this.props.location.pathname.indexOf("wow")>-1;
        this.state = {
            eventVendorDetails: [],
            isWow,
            filters: [],
            clearFilters: false,
            appliedFilters: {},
            showFilters: (this.props.getprefer && this.props.getprefer.filterView) ? this.props.getprefer.filterView==='E'?true:false:window.innerWidth > 768?true:false,
            eventId: event?event.relay_nbr:'',
            promoNmbr: eventId,
            promoName: event?event.PrmoName:'',
            orderDeadline: event?event.endDate:'',
            dealDetails: false,
            selectedvendorName: '',
            serverError: false,
            backPath:'',
            searchby:'item',
            searchvalue :''
        }
        this.pathname = '';
        this.searchby = 'item';
        this.searchvalue = '';
        this.filterString = {};
        this.sortParam = "vendorName";
        this.sortOrder = "asc"
    }

    componentDidMount() {
        let queryParam = window.location.href.split('?');
        this.pathname = this.props.location.pathname;
        if(queryParam[1]) {
            this.getUrlFiltersMonthly(queryParam);
          }
          else{
            this.getReunionEventVendors();
          }
        
    }

    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };

    showDealDetails(vendorName) {
        this.setState({
            dealDetails: true,
            selectedvendorName: vendorName
        });
    }

    componentWillReceiveProps(newProps){
        if(this.props.searchby!==newProps.searchby || this.props.searchvalue!==newProps.searchvalue){
            this.pathname = newProps.pathname;
            this.getReunionEventVendors(newProps);
        }
    }

    DialogClose = () => this.setState({ dealDetails: false });

    filterCallback = (isAdd, filterKey, filterValue) => {
        let appliedFilters = this.state.appliedFilters;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
            appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
        } else if (keyExists) {
            appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
                ? appliedFilters[filterKey]
                : appliedFilters[filterKey].split(',');
            let value = appliedFilters[filterKey];
            let index = value.indexOf(filterValue);
            if (index > -1) {
                value.splice(index, 1);
            } else {
                value.push(filterValue);
            }
            if (!value.length && keyExists) delete appliedFilters[filterKey];
            else appliedFilters[filterKey] = value;
        }
        this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
        this.setState({
            appliedFilters,
            clearFilters: false,
            backPath : this.pathname
        },()=>{
            this.getReunionEventVendors();
        });
        
    };

    optionsSection=(isWow)=>{
        const searchBy = this.getSearchByFields();
        const sortBy = this.getSortByFields();
        return (
           <>
             {isWow?
                <div className='options-div'>
                    <div className='right-justified'>
                        <div className='sortByCategories'>
                            <span>Sort By</span>
                            { sortBy }
                            {
                            getSortByDirection(this.changeSortDirection,null,'asc')
                            }
                        </div>
                    </div>
                </div>
                :
                <div className='ui icon input' id='search_div_address'>
                    <span className='vender-search-header'>Search </span>
                    { searchBy }
                    <input
                        type='text'
                        className='search-box_address'
                        onChange={this.handleSearchValueChange}
                        onKeyDown = {this.searchItems}
                    />
                    {renderImages.getImage({
                        src: search_img,
                        className: 'search_icon_address cursor_p',
                        onClick: this.searchItems,
                        id:'search_icon'
                    })}
                </div>
            }
           </>
        )
    }
 
    changeSearchBy = (e) =>{
        this.scrollPosition = window.pageYOffset;
        this.searchby = e.target.value;
        this.setState({
        searchby: this.searchby,
        searchNumber :''
        });
    }

    getSearchByFields = ()=>{
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSearchBy, id: 'pl_dropdown', value: this.state.searchby};
        let options =[
            {props:{value:'item', id: 'item ', className:'SelectOption'},text:'Item #'},
            {props:{value:'model', id: 'model ', className:'SelectOption'},text:'Model #'},
            {props:{value:'description', id: 'description ', className:'SelectOption'},text:'Description#'},
        ];
        if(!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID)){
            options =[...options, {props:{value:'alternateSku',id:'alternateSKU', className:'SelectOption'},text:'Alternate SKU#'}]  
        }

        return (
            getSelect(selectProps,options)
        );
    }


    getReunionEventVendors = (newProps) => {
        this.setState({ loading: true });
        const {searchby, searchvalue } = newProps?newProps:this.props;
        let url = `${MONTHLY_SPECIALS}/GetMSVendors`;
        let requestPayLoad = {
            "relayNbr": this.state.eventId,
            "promoNbr": this.state.promoNmbr,
            "sortBy":this.sortParam,
            "sortOrder":this.sortOrder,
            ...this.filterString
        }
        if(searchby && searchvalue){
            requestPayLoad = {...requestPayLoad,
                                searchby,
                                searchvalue
                            }
        }
        restUtils
            .postData(url, requestPayLoad)
            .then(response => {
                this.setState({
                    eventVendorDetails: response.data.MSVendorList,
                    filters: response.data.filters,
                    loading: false,
                    serverError: false
                },()=>{
                    this.setBackButtonPath()
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, serverError: true });
            });
    }

    getUrlFiltersMonthly=(queryParam)=>{
        this.scrollPosition = queryParam[1].includes('scrollPosition=') ? queryParam[1].split('scrollPosition=')[1].split('&')[0] : 0;
        this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
        this.appliedFilters = utilities.getFilterArrayDecode(this.filterString);
        this.filterString = utilities.getQueryJsonAssortment(this.appliedFilters);
        this.setState({
        searchNumber: this.searchNumber,
        appliedFilters: this.appliedFilters,
        backPath: this.pathname
        }, () => {
            this.getReunionEventVendors()
        });
    }

    setBackButtonPath = () => {
        let pathName = '';
        if (this.state.appliedFilters && !_.isEmpty(this.state.appliedFilters)) {
            pathName = pathName + '&filterString=' + utilities.getQueryStrForFilterString(this.state.appliedFilters);
          }
          let pushUrl = '';
          if (pathName !== '') {
            pushUrl = this.pathname + '?' + pathName;
            window.history.pushState(window.history.state, "", pushUrl);
          } else {
            pushUrl = this.pathname;
          }
          this.setState({
            backPath: pushUrl
          })
      }
    handleSearchValueChange = (e) =>{
        this.searchvalue = e.target.value;
    }
    searchItems = (e) =>{
        if (e.keyCode !== 13 && e.target.id !=='search_icon') return;

        const { eventId :relayNbr , 
            promoNmbr: promoNbr, 
            promoName, 
            orderDeadline:deadline} = this.state;
        const vendorDetails = {
            relayNbr,
            promoNbr, 
            promoName,
            deadline:utilities.getDateFromTimeStamp(deadline),
            searchby: this.searchby,
            searchvalue:this.searchvalue
        };
        history.push({
            pathname:'/MonthlySpecialsItems/'+ vendorDetails.promoNbr,
            state:{
                isMSSearch:true,
                isWow:this.state.isWow,
                vendorDetailsOffer:vendorDetails
            }
        });
    }
    showItemList = (e) =>{
        const { shipdate1, shipdate2, shipdate3, shipdate4, vendorDisplayName, vendorName, spiff, member_terms } = e.target.dataset;
        const { eventId :relayNbr , promoNmbr: promoNbr, promoName, orderDeadline:deadline,searchvalue, searchby,isWow} = this.state;
        const vendorDetails = 
                {
                    relayNbr,
                    promoNbr,
                    promoName,
                    vendorid : e.target.id,
                    deadline:utilities.getDateFromTimeStamp(deadline),
                    vendorName,
                    vendorDisplayName,
                    shipdate1,
                    shipdate2,
                    shipdate3,
                    shipdate4,
                    spiff,
                    member_terms
                }
            const pathname = isWow?'/wowItems/'+ vendorDetails.promoNbr:'/MonthlySpecialsItems/'+ vendorDetails.promoNbr;
            history.push({
            pathname,
            state:{
                isWow,
                vendorDetailsOffer:vendorDetails,
                searchvalue : searchvalue,
                searchby:searchby
            }
        });
    }
    
    changeSortParam = (e) =>{
        this.sortParam = e.currentTarget.value;
        this.getReunionEventVendors();
    }
    getSortByFields = () => {
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown'};
                    
        const options = [
            {props:{value:'Num',id:'Num', className:'SelectOption'},text:'Number of items'},
            {props:{value:'VendorName', id: 'VendorName', className:'SelectOption'},text:'Vendor Name'},
        ];
    
        return(      
            getSelect(selectProps, options)
        );
    }

    changeSortDirection = (e) =>{
        this.sortOrder = e.target.value;
        this.getReunionEventVendors();
    }
    render() {
        const gridClassName = window.innerWidth < 1024 ? 'ui stackable two column grid':window.innerWidth<1240?'ui stackable three column grid': 'ui stackable five column grid';
        const { eventId, promoNmbr, promoName, isWow, loading} = this.state;
        const saleLabel = saleType(this.props.monthlySizzleSaleValue)
        const header = `${isWow?'WOW':saleLabel}`;
        const breadcrumbPath = [
            { link: '/home', text: 'HOME' },            
            { link: `${isWow?'/wow':'/monthlySpecials'}`, text: header},
            {
                link :'#',
                text : 'Events '+ eventId +': '+promoNmbr + '-' + promoName
            }
        ];
        return (
                this.state.serverError ?
                <InternalServerError />
                :
                <div className="page-wrap">
                    <div className='page-header'>
                        {header}
                    </div>
                    <BreadcrumbComp path={breadcrumbPath}/>
                    <br/>
                    <>
                        {this.optionsSection(isWow)}
                        <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
                            <div id='filter_width_ipad'>
                                <Filter
                                    clearFilters={this.state.clearFilters}
                                    filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                                    appliedFilters={this.state.appliedFilters}
                                    toggleShowFilter={this.toggleShowFilter}
                                    showFilters={this.state.showFilters}
                                    filters={this.state.filters}
                                    callback={this.filterCallback}
                                    isNoProducts={this.state.isNoProducts}
                                    listType = {5}
                                    isLoading = {loading}
                                    screenName = {'MonthlySpecialsVendors'}
                                />
                            </div>

                            <div className='page_details'>
                                <span>Event ID: #{this.state.eventId}</span>
                                <span className="padding_left">Event Name: {this.state.promoNmbr} - {this.state.promoName}</span>
                                <span className="padding_left">Order Deadline: {utilities.getDateFromTimeStamp(this.state.orderDeadline)}</span>
                                {loading ?
                                    <div>
                                        <Loader active />
                                    </div>
                                    :
                                    <div className={gridClassName} id="reunion_items">

                                        {this.state.eventVendorDetails && this.state.eventVendorDetails.map((event,index) => {
                                            const Vendor_Name = `${event.vendorName.trim()} (${event.vendorId})`;
                                            const shipDates = [event.shipDate1, event.shipDate2, event.shipDate3, event.shipDate4];
                                            const vendorTitle = <div
                                                                    onMouseOver={this.DialogClose}                                                                    
                                                                    id = {event.vendorId}
                                                                    data-vendor-display-name = {Vendor_Name}
                                                                    data-vendor-name = {event.vendorName.trim()}
                                                                    data-shipdate1 = { event.shipDate1 }
                                                                    data-shipdate2 = { event.shipDate2 }
                                                                    data-shipdate3 = { event.shipDate3 }
                                                                    data-shipdate4 = { event.shipDate4 }
                                                                    data-member_terms = { event.member_terms }
                                                                    data-spiff = { event.spiff.replace(/([/r])/g," ") }
                                                                    onClick = {this.showItemList}
                                                                    className="discount-vendor_name">
                                                                        {Vendor_Name}
                                                                </div>
                                            return (
                                                <div className='column'>
                                                    <div className="reunion-vendor-card" >
                                                        {
                                                            !isWow ? <> {vendorTitle} </>
                                                            :
                                                                <Popup
                                                                    wide='very'
                                                                    offset='0, 50px'
                                                                    hoverable
                                                                    content={<VendorDetailsPopup isReunionItem={true} vendorInfo = {event.vendorInfo}/>}
                                                                    trigger={vendorTitle}
                                                                />
                                                        }
                                                        <div className="deal_details">                                            
                                                            <span className='no_of_items'                                                                    
                                                                    id = {event.vendorId}
                                                                    data-vendor-display-name = {Vendor_Name}
                                                                    data-vendor-name = {event.vendorName.trim()}
                                                                    data-shipdate1 = { event.shipDate1 }
                                                                    data-shipdate2 = { event.shipDate2 }
                                                                    data-shipdate3 = { event.shipDate3 }
                                                                    data-shipdate4 = { event.shipDate4 }
                                                                    data-member_terms = { event.member_terms }
                                                                    data-spiff = { event.spiff.replace(/([/r])/g," ") }
                                                                    onClick = {this.showItemList}>
                                                                        Item(s): {event.num}
                                                                </span>
                                                            <span onClick={() => this.showDealDetails(Vendor_Name)} className='deal_deadline'>Deal Details</span>
                                                            {this.state.dealDetails && (
                                                                this.state.selectedvendorName === Vendor_Name &&
                                                                <div id='dealDetailsDialog'>
                                                                    <DealsDialog
                                                                        vendorName={Vendor_Name}
                                                                        member_terms={event.member_terms}
                                                                        spiff = {event.spiff.replace(/([/r])/g," ")}
                                                                        shipdate1 ={shipDates[0].split(" ")[0]}
                                                                        shipdate2 ={shipDates.length>1 ? shipDates[1].split(" ")[0]:""}
                                                                        shipdate3 ={shipDates.length>2 ? shipDates[2].split(" ")[0]:""}
                                                                        shipdate4 ={shipDates.length>3 ? shipDates[3].split(" ")[0]:""} 
                                                                        DialogClose={this.DialogClose}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                        </div>
                        </div>
                    </>
                </div>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        MSEvents : state.ReunionReducer.MSEvents,
        getprefer:state.preference.listData,
        aliasSKURetailerGrpID:state?.SessionReducer?.address ? state?.SessionReducer?.address?.aliasSKURetailerGrpID : '',
        monthlySizzleSaleValue: state.cart?.orders?.monthlySizzleSaleValue
    }
}

export default connect(mapStateToProps)(MonthlySpecialEventVendors);
