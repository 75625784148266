import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  authorizeUser,
  getSecretKey,
  login,
  setAuthentication,
  logout,
  setSelectedChildStore,
  setVendorPopUp
} from '../Login/actions';
import jwtDecode from 'jwt-decode';
import utilities from '../../utils/utilities';
import restUtils from '../../utils/restUtils';
import Auth from '../../auth';
import history from '../../history';
import {getPreferences} from '../../views/Preferences/action';
import { switchView } from '../../components/NavBar/actions' ;
import { userObjectUrl, GET_USER_INFO, getRedirectUriMOL } from '../../urlConstants';
import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import _ from 'lodash';
import AuthLoader from './AuthLoader';


class AuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      errorMsg: '',
      isAuthenticated: props.isAuthenticated,
      userName:'',
      storeid:'',
      getprefer:{},
      prevUrlRedirect: false
    };
    this.auth = new Auth();
    this.adminUsers = ['@fperry','@avenkat1','OWNER','@bboyce']
  }

  /* calls the logout method in authentication service */
  logout = () => {
    this.props.logout();            /* clearing redux */
    this.auth.removeHash();         /* clearing window hash */
    localStorage.clear();           /* clearing local storage */
    this.auth.logout();
  };

  componentDidMount() {
    if(!localStorage.getItem('access_token') && !localStorage.getItem('isAuthInvoked')) {
      console.log("isAuthInvoked"+localStorage.getItem("isAuthInvoked"));
      console.log("isAuthInvoked"+localStorage.getItem("access_token"));
      console.log("isAuthInvoked"+localStorage.getItem("isLeaveNW"));
      /*if(localStorage.getItem('isLeaveNW')){
        console.log("Leaving NW to MOL")
        this.auth.clearStorage();
      }*/
      console.log("reauthenticating - ");
      this.auth.login();
    } else if (!localStorage.getItem('access_token')) {
      console.log("isAuthInvoked"+localStorage.getItem("isAuthInvoked"));
      console.log("isAuthInvoked"+localStorage.getItem("access_token"));
      console.log("isAuthInvoked"+localStorage.getItem("isLeaveNW"));
      console.log("autnentication invoked but no token received yet");
      this.handleAuthentication();
    } else if(localStorage.getItem('access_token') && !this.props.access_token){
        let id_token = jwtDecode(localStorage.getItem('id_token'));
        let access_token = jwtDecode(localStorage.getItem('access_token'));
        this.retrieveDetails(id_token,
          access_token,
          localStorage.getItem('expires_at')
        );
    } else {
        if(localStorage.getItem('access_token') && this.props.secretKey){
          if(!this.props.getprefer){
           // this.props.getPreferences(this.props.username,this.props.storeId);
          }
          this.auth.validateToken(this.props.secretKey);
        }
    }
    document.getElementById('dropdownMenuWrapper').classList.remove('setVisiable')
  }

  componentWillMount() {
    if (!this.state.isAuthenticated) {
      console.log("Application Will mount");
     // this.handleAuthentication();
    }
  }

  shouldComponentUpdate(nextProps,nextState){
    return (!nextState.isAuthenticated || nextProps.rdc) && !this.state.prevUrlRedirect;
  }
  componentDidUpdate(){
    document.getElementById('dropdownMenuWrapper').classList.remove('setVisiable')
    if(!localStorage.getItem('access_token') && (!localStorage.getItem('isAuthInvoked') ||
        (localStorage.getItem('isAuthInvoked') && this.props.rdc))) {
      if(localStorage.getItem('isLeaveNW')){
        this.auth.clearStorage();
        return;
      }
      this.auth.login();
    }
    
    if(localStorage.getItem('viewType')){
      this.props.switchView(localStorage.getItem('viewType'));
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
      this.setState({ isAuthenticated: nextProps.isAuthenticated });
    }
    if (nextProps.isAuthenticated && !this.props.rdc && nextProps.rdc && localStorage.getItem('access_token')) {
      if(localStorage.getItem('prevUrl') && localStorage.getItem('prevUrl') !== '/' && localStorage.getItem('prevUrl') !== '/#identity') {
        let redirecturl = localStorage.getItem('prevUrl')
        this.setState({prevUrlRedirect: true});
        history.push(redirecturl);
        this.setState({prevUrlRedirect: false});
      } else {
        history.push('/home');
      }

    } else if (!nextProps.isAuthenticated) {
      //  this.auth.login();
    }
  }
  
  componentDidCatch(error, errorInfo) {
    this.setState({ isError: true, errorMsg: error.message });
    if (error.message === 'Not Authenticated') {
      this.setState({ hasError: true, isAuthenticated: false });
      this.props.setAuthentication(false);
    }
  }

  getUser = userObj => {
    /* should accept storeid and user id */
    const userUrl = userObjectUrl;
    const postObj = {
      ...userObj
    };
    restUtils.postData(userUrl, postObj)
    .then(response => {
      const { user } = response.data;
      const storeDetails = user;
      if(!storeDetails){
        utilities.showToast("User and Store details unavailable!");
      }
      if(_.isEmpty(storeDetails) ||isNaN(user?.userID)) {
        this.props.logout();
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("isLeaveNW",true);
        window.location.assign(getRedirectUriMOL())
        return
      }      
      const address = {
        userName: userObj.userName,
        StoreId: userObj.storeId,
        storeName: utilities.emptyForNull(storeDetails?.storeName),
        addressLine1: utilities.emptyForNull(storeDetails.addressLine1),
        addressLine2: utilities.emptyForNull(storeDetails.addressLine2),
        City: utilities.emptyForNull(storeDetails.city),
        stateCode: utilities.emptyForNull(storeDetails.state),
        postalCode: utilities.emptyForNull(storeDetails.zipCode),
        countryName: utilities.emptyForNull(storeDetails.country),
        phoneNumber: utilities.emptyForNull(storeDetails.phone),
        primaryEmail: utilities.emptyForNull(storeDetails.emailAddress),
        primaryContactName: utilities.emptyForNull(storeDetails.customerName),
        faxNumber : utilities.emptyForNull(storeDetails.fax),
        mailingAddressLine1: utilities.emptyForNull(storeDetails.MailingAddressLine1),
        mailingAddressLine2: utilities.emptyForNull(storeDetails.MailingAddressLine2),
        mailingCity: utilities.emptyForNull(storeDetails.MailingCity),
        mailingStateCode: utilities.emptyForNull(storeDetails.MailingStateCode),
        malingPostalCode: utilities.emptyForNull(storeDetails.MailingPostalCode),
        mailingCountryName: utilities.emptyForNull(storeDetails.MailingCountryName),
        mailingCountryCode: utilities.emptyForNull(storeDetails.MailingCountryCode),
        access: user.access,
        affiliate: storeDetails.affiliate,
        primaryWarehouseNum: storeDetails.primaryWarehouseNum,
        csr: user.csr,
        csrPermissions: user.csrPermissions === null ? false : user.csrPermissions,
        viewCat: user.viewCat,
        viewPOG: user.viewPOG,
        catMbr: user.catMbr,
        rdc: user.primaryWarehouseNum,
        searchSource: 'WH',
        allowedorder:(user.csr && (user.csrPermissions === '' || user.csrPermissions === '' ? user.csrPermissions.indexOf("NW_Ordering") === -1 : '' )) ? false : true,
        hq:parseInt(user.storeID)>=89999 || parseInt(user.storeID)<=1,
        groupCollectionId: storeDetails.groupCollectionId,
        urlId: storeDetails.urlId,
        groupIdList: storeDetails.groupIdList,
        checkDigit: storeDetails.checkDigit,
        fullMemberNbr: storeDetails.fullMemberNbr,
        aliasSKURetailerGrpID: user.aliasSKURetailerGrpID,
        easyAskDic: user.easyAskDic,
        showAdminMenu: user.showAdminMenu,
        isProspectStore : user?.IsProspectStore,
        isReunionOnlyStore : user?.IsReunionOnlyStore,
        feedbackLink: user?.NnwFeedbackUrl,
        loadCipherLab: user?.programCodes.includes('TWC'),
        viewOrders:user?.viewOrders,
        IsStoreReunionAttendant: user?.IsStoreReunionAttendant
      };
      if(isNaN(user.userID)) {
        this.logout()
        return
      }
      this.props.login(
        utilities.emptyForNull(userObj.userName),
        user.userID,
        userObj.storeId,
        address,
        user.mainRDCUnderEmergency,
        true,
        userObj.viewOnly,
        user,
        userObj.userguid, 
        userObj.storeguid     
      );
      restUtils.postDataWithBearer(GET_USER_INFO).then(res=>{
        const { AgreementSigned } = res.data
        this.props.setVendorPopUp(AgreementSigned === null ? true : false)
      }).catch(err=>{
        console.log(err)
      })
      const childStoresSelected = this.state.childStoresSelected?.length > 0 ? this.state.childStoresSelected
            : Object.keys(user?.childStoreList).length > 0 ? [userObj.storeId] : []
      this.props.setSelectedChildStore(childStoresSelected);
      this.setState({ isAuthenticated: true, userName:userObj.userName, storeid: userObj.storeId});
      this.props.setAuthentication(true);
      this.props.getPreferences( userObj.userName, userObj.storeId);
      if(localStorage.getItem('prevUrl') 
      && (localStorage.getItem('prevUrl') !== '/' ) && localStorage.getItem('prevUrl') !== '/#identity') {
        let redirecturl = localStorage.getItem('prevUrl')
        this.setState({prevUrlRedirect: true});
        history.push(redirecturl);
        this.setState({prevUrlRedirect: false});
      } else {
        history.push('/home');
      }
    })
  };

  handleAuthentication = () => {
    console.log("trying to parse Hash")
    if (/access_token|id_token|error/.test(window.location.hash)) {
      console.log("hash Found");
      const authObj = this.auth.handleAuthentication(window.location.hash);
      const { id_token, access_token, expires_at } = authObj;
      this.props.authorizeUser(id_token, access_token, expires_at);
      this.props.getSecretKey(access_token);
      let isAuthenticated = !utilities.isEmptyOrNullString(access_token);
      localStorage.setItem('expires_at', expires_at);
      let userObj = {
        userName: id_token.username,
        viewOnly: (access_token.viewonly === 'Y') ? true :  false,   /* Remove user name later */
        csr: access_token.csr,
        csrPermissions: access_token.csr_permissions,
        CatAccess: access_token.cataccess,
        PogAccess: access_token.pogaccess,
        CatMbr: access_token.catmbr,
        PogMbr: access_token.pogmbr,
        tvr: access_token.tvr,
        managers: id_token.managers,
        MultiStoreOrdering: id_token.multistoreordering,
        storeId: utilities.padWithZeros(access_token.storeid, 5),
        appName: 'NW',
        viewOrders:access_token.vieworders,
        userguid:access_token.userguid,
        storeguid:access_token.storeguid
        
      };
      if (isAuthenticated) {
        this.getUser(userObj);        /* supposed to pass storeid and user id from id token */
      }
      //this.retrieveDetails(id_token,access_token,expires_at);
    }else{
      console.log("hash Not Found - Re authenticating");
      this.auth.login();
    }
  };

  retrieveDetails = (id_token, access_token, expires_at) =>{   
    this.props.authorizeUser(id_token, access_token, expires_at);
    this.props.getSecretKey(access_token);
    let isAuthenticated = !utilities.isEmptyOrNullString(access_token);
    let userObj = {
      userName: id_token.username,
      viewOnly: ( access_token.viewonly === 'Y') ? true :  false,   /* Remove user name later */
      csr: access_token.csr,
      csrPermissions: access_token.csr_permissions,
      CatAccess: access_token.cataccess,
      PogAccess: access_token.pogaccess,
      CatMbr: access_token.catmbr,
      PogMbr: access_token.pogmbr,
      tvr: access_token.tvr,
      managers: id_token.managers,
      MultiStoreOrdering: id_token.multistoreordering,
      storeId: utilities.padWithZeros(access_token.storeid, 5),
      appName: 'NW',
      userguid:access_token.userguid,
      storeguid:access_token.storeguid
    };
    if (isAuthenticated) {
      this.getUser(userObj);        /* supposed to pass storeid and user id from id token */
    }
  }
  render() {
    /* calls the isAuthenticated method in authentication service  */
    /* const { isAuthenticated } = this.props.auth; */
    const { isAuthenticated } = this.state;
    const { children } = this.props;
    if (
      isAuthenticated &&
      (!children ||
        (children.props.location &&
          children.props.location.pathname &&
          children.props.location.pathname === '/'))
    ) {
      if (localStorage.getItem('access_token')) {
        if(window.location.hash === '#identity' && (localStorage.getItem('prevUrl') === '/#identity' || localStorage.getItem('prevUrl') === '/home' || localStorage.getItem('prevUrl') === '/')) {
          this.props.logout();
          localStorage.clear();
          sessionStorage.clear();
        }
          history.push('/home');
      }
      return <></>;
    }
    return (
      <div>
        {isAuthenticated && <>{this.props.children}</>}
        {!isAuthenticated && (
          <AuthLoader/>
        )}
      </div>
    );
        
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.SessionReducer.isAuthenticated,
    rdc: state.SessionReducer.rdc,
    secretKey:state.SessionReducer.secretKey,
    username:state.SessionReducer.userName,
    storeId :state.SessionReducer.storeId,
    access_token:state.SessionReducer.access_token,
    getprefer: state.preference.listData,
    childStoresSelected: state.SessionReducer.childStoresSelected,
  };
};

const mapDispatchToProps = {
  authorizeUser,
  getSecretKey,
  login,
  setAuthentication,
  logout,
  getPreferences,
  switchView,
  setSelectedChildStore,
  setVendorPopUp
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
