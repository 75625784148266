import { GET_REUNION_OFFERINGS, GET_MONTHLY_SPECIALS, SET_LOADER} from './actions';

const initState = {}

const ReunionReducer = (state=initState,{ type, payload }) =>{

    switch(type){
        case GET_REUNION_OFFERINGS:
            return {...state,
                events:payload.events,
                filters:payload.filters,
                loaded:true};
        case GET_MONTHLY_SPECIALS:
            return {...state,
                loaded:true,
                MSEvents:payload.events,
                filters:payload.filters};
        case SET_LOADER:
            return {...state,loaded:payload}
        default :
            return state;
    }
}

export default ReunionReducer;