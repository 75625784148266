import React, { Component } from 'react';
import { connect } from 'react-redux';
import img from '../images/HotDeals.png';
import img1 from '../images/MonthlySpecials.png';
import img2 from '../images/RetailEvents.png';
import img3 from '../images/tv_flashsale.jpg';
import img4 from '../images/HolidayWonderland.png';
import SSMonth from '../images/SSMonthlySpecials.png';
import SSHoliday from '../images/SSHappeningNow.png';

import './styles.css';
import { Link } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';
import renderImages from '../common/RenderImages';
import { deals } from '../../../src/urlConstants';

class wholeSaleDeals extends Component {
  render() {
    const dealsImage = deals === 'Hot Deals' ? img : img4
    let SaleImage = img1
    if(this.props.monthlySizzleSaleValue == 2 ){
      SaleImage = SSHoliday
    } else if(this.props.monthlySizzleSaleValue == 3){
      SaleImage = SSMonth
    }
    return (
      <>
        <Segment vertical>
          <div style={{fontSize:'22px', paddingLeft:'1rem'}}>Wholesale Deals</div>
          <Grid container stackable verticalAlign='middle' columns={5} id='imageLanding'>
            <Grid.Row centered>
              <Grid.Column width={4} style={{padding:'0rem 0rem'}}>
                <Link to='/listing/HotDeals'>{renderImages.getImage({src:dealsImage, className:'image3a ui image', alt:'wholesaledeals'})}</Link>
              </Grid.Column>   
              <Grid.Column width={4} style={{padding:'0rem 0rem'}}>
                 <Link to='/monthlySpecials'>{renderImages.getImage({src:SaleImage, className:'image3a ui image', alt:'wholesaledeals'})}</Link>
              </Grid.Column>   
              <Grid.Column width={4} style={{padding:'0rem 0rem'}}>
                <Link to='/retail-events'>{renderImages.getImage({src:img2, className:'image3b ui image', alt:'wholesaledeals'})}</Link>
              </Grid.Column>   
              <Grid.Column width={4} style={{padding:'0rem 0rem', display: 'none'}}>
                <Link to='/flashSales'>{renderImages.getImage({src:img3, className:'image3c ui image imgwidth', alt:'wholesaledeals'})}</Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }
}

const mapStateToProps = state => {
  const { cart } = state;
  return {
    monthlySizzleSaleValue: cart?.orders?.monthlySizzleSaleValue
  };
};

export default connect(mapStateToProps)(wholeSaleDeals);
