import React from 'react'
import {DealsDialog} from './DealsDialog'
import { Button, Loader, Responsive, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux'
import InternalServerError from '../../ErrorPages/500_error_page';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import search_img from '../../images/Search.svg';
import renderImages from '../../common/RenderImages';
import utilities, { saleType } from '../../../utils/utilities';
import {fetchMonthlyProductsList, searchMonthlyItems, getMonthlyFilterString, getRequestPayload, fetchCompleteList} from './MonthlyOfferingsHandler';
import { showItemCount, getSortByDirection, getPagination } from '../../common/CustomComponents';
import { getSelect, exportExcel } from '../../common/ComponentUtilities';
import Filter from '../../common/Filter';
import _ from 'lodash';
import ProductListing from '../../common/ProductListing';
import ProductListingGrid from '../../common/ProductListingGrid';
import { handleChange, handleChangeValue,addMonthlySpecialToCart, validateProductQuantity } from '../../common/productHandler';
import { MONTHLY_SPECIALS, WOW } from '../../Categories/constants';
import ExcelExport from '../../common/ExcelExport';
import { isArray } from 'util';
import {addMonthlySpecialCart,updateQty} from '../../Cart/actions';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

 class MonthlySpecialsItemListingVendors extends React.Component{
    constructor(props){
        super(props);
        const eventId = props.match && props.match.params  && props.match.params.eventId ? props.match.params.eventId : '';
        const event = props.MSEvents && props.MSEvents.find(event=>{
            return parseInt(event.promo_nbr)===parseInt(eventId);
        })
        const{isMSSearch,isWow, isAllItems,vendorDetailsOffer,searchby,searchvalue,pathname}= props?.location?.state || {}
        this.state={
            isMSSearch,
            isAllItems,
            dealDetails:false,
            eventId,
            isWow,
            vendorDetails:vendorDetailsOffer,
            eventIdReunion: event?event.relay_nbr:'',
            promoNmbr: event?event.promo_nbr:'',
            promoName: event?event.PrmoName:'',
            serverError: false,
            pathname,
            categoryData:[],
            loading: false,
            isNoProducts: false,
            totalItems :0,
            firstItem:0,
            lastItem:0,
            totalPages:0,
            currentPage:0,
            filters : [],
            quantities :[],
            overrideRetails :[],
            clearFilters:false,
            appliedFilters: {},
            sortBy:'item_nbr',
            sortOrder:'asc',
            img_txt_to_display:
            this.props.getprefer &&
                this.props.getprefer.thumbnailImage &&
                this.props.getprefer.thumbnailImage !== 'Y'
                ? 'Show Images'
                : 'Hide Images',
            showFilters:
                this.props.getprefer && this.props.getprefer.filterView
                ? this.props.getprefer.filterView === 'E'
                    ? true
                    : false
                : window.innerWidth <= 768 
                    ? false
                    : true,
            viewType:props.getprefer &&
                        props.getprefer.prdctListlayoutFormat &&
                        props.getprefer.prdctListlayoutFormat !== 'L'
                        ? 'grid'
                        : 'list'
        }
        this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage
                        ? this.props.getprefer.resultPerPage
                        : '50';
        this.getRequestPayload = getRequestPayload.bind(this);
        this.fetchCompleteList = fetchCompleteList.bind(this);
        this.searchMonthlyItems = searchMonthlyItems.bind(this);                        
        this.fetchMonthlyProductsList = fetchMonthlyProductsList.bind(this);
        this.handleChange = handleChange.bind(this);
        this.handleChangeValue = handleChangeValue.bind(this);
        this.addMonthlySpecialToCart = addMonthlySpecialToCart.bind(this);
        this.validateProductQuantity = validateProductQuantity.bind(this);
        this.getMonthlyFilterString = getMonthlyFilterString.bind(this);
        this.sortParam = '';
        this.searchby = 'itemnbr';
        this.searchvalue = '';
        this.pathname = '';
    }
    componentDidMount(){
        this.props.updateQty(false)
        let queryParam = window.location.href.split('?');
        this.pathname = this.props.location?.pathname;
        if(queryParam[1]) {
            this.getUrlFiltersMonthly(queryParam);
          }
          else{
            this.fetchItems();
          }  
    }

    componentWillReceiveProps(newProps){
            if((!this.props.currentCartId && newProps.currentCartId) ||
                (this.props.currentCartId && !newProps.currentCartId) ||
                (this.props.currentCartId && newProps.currentCartId && parseInt(this.props.currentCartId) !== parseInt(newProps.currentCartId))){
                    if(!newProps.qtyUpdate) {
                    this.fetchItems(newProps);
                }
                this.props.updateQty(false) 
            }
    }
    fetchItems = ()=>{
        if(this.state.isMSSearch){
            this.searchMonthlyItems(this.state.vendorDetails);
        }else{
            this.fetchMonthlyProductsList();
        }
    }
    changePageSize = e => {
        this.pageSize = e.currentTarget.value;
        this.page = '1';
        this.scrollPosition = window.pageYOffset;
        this.fetchItems();
    };
    showDealDetails = () => this.setState({ dealDetails: true});
    
    handleSort = (e) => {
        this.setState({ viewType: e });
        return this.e;
    }
    setScrollPosition = () => {
        this.scrollPosition = window.pageYOffset;
        this.setBackButtonPath();
    }
    setBackButtonPath = () => {
      let pathName = '';
      if (this.sortParam && this.sortParam.length > 0) {
        pathName = pathName + '&SortParam=' + this.sortParam;       
      } else {
          pathName = pathName + '&SortParam='+this.state.sortBy;
        }
      if (this.sortDirection && this.sortDirection.length > 0) {
          pathName = pathName + '&SortType=' + this.sortDirection;
        } else {          
          pathName = pathName + '&SortType=' +this.state.sortOrder;
        }
        pathName = this.scrollPosition > 0 ? pathName + '&scrollPosition=' + this.scrollPosition : pathName;
        if (this.filterString && this.filterString !== '') {
          pathName = pathName + '&filterString=' + utilities.getQueryStrForFilterString(this.appliedFilters);
        }
      if (pathName !== '') {
         let pushUrl = this.pathname + '?' + pathName;
        window.history.pushState(window.history.state, "", pushUrl);
      }
    }
    getUrlFiltersMonthly=(queryParam)=>{
        this.scrollPosition = queryParam[1].includes('scrollPosition=') ? queryParam[1].split('scrollPosition=')[1].split('&')[0] : 0;
        this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
        this.appliedFilters =  utilities.getFilterArrayAsMultiSelectObject(this.filterString);
        let FilterApplied = utilities.getFilterArray(this.filterString);
        let selectVendor =  this.state.vendorDetails ? this.state.vendorDetails.vendorName+' - '+this.state.vendorDetails.vendorid : null ;
        this.filterString =utilities.getQueryJsonAssortment(FilterApplied, null, null, selectVendor,true);
        let sortOrder =  queryParam[1].includes('SortType=') ? queryParam[1].split('SortType=')[1].split('&')[0] : 0;
         let sortBy =  queryParam[1].includes('SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : 0;
        this.setState({
        appliedFilters: this.appliedFilters,
        sortOrder: sortOrder,
         sortBy: sortBy,
        backPath: this.pathname
        }, () => {
            this.fetchItems()
        });
    }
    getListing = ()=>{
        const {viewType, img_txt_to_display, categoryData, overrideRetails, quantities, isWow} = this.state;
        const img_style = img_txt_to_display === 'Hide Images' ? 'block' : 'none';
        return (
            <>
            {
                viewType === 'list' && window.innerWidth >= 1024 ? (
                    <ProductListing
                        access={this.props.multiStoreDetails.access}
                        img_style={img_style}
                        isWow = {isWow}
                        tableType={MONTHLY_SPECIALS}
                        viewType={this.props.viewType}
                        categoryData={categoryData}
                        quantities={quantities}
                        overrideRetails={overrideRetails}
                        handleChange={this.handleChange}
                        handleChangeValue={this.handleChangeValue}
                        handleChangeblur={this.handleChangeblur}
                        addToCart={this.addMonthlySpecialToCart}
                        validateProductQuantity={this.validateProductQuantity}
                        storeId={this.props.storeId}
                        setBackButtonPath={this.setScrollPosition}
                        eventId = {this.state.eventId}
                    />
                ) : (
                    <ProductListingGrid
                        access={this.props.multiStoreDetails.access}
                        img_style={img_style}
                        isWow = {isWow}
                        tableType={MONTHLY_SPECIALS}
                        viewType={this.props.viewType}
                        categoryData={categoryData}
                        quantities={quantities}
                        overrideRetails={overrideRetails}
                        handleChange={this.handleChange}
                        handleChangeValue={this.handleChangeValue}
                        handleChangeblur={this.handleChangeblur}
                        addToCart={this.addMonthlySpecialToCart}
                        validateProductQuantity={this.validateProductQuantity}
                        storeId={this.props.storeId}
                        setBackButtonPath={this.setScrollPosition}
                        eventId = {this.state.eventId}
                    />
                )
            }
            </>
        );
    }
    onPageChange = e => {
        let queryPage = e.currentTarget.text;
        if (e.currentTarget.text === 'Next') {
          queryPage =
            this.state.currentPage < this.state.totalPages
              ? this.state.currentPage + 1
              : this.state.currentPage;
        }
        if (e.currentTarget.text === 'Prev') {
          queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
        }
        if (e.currentTarget.text === '»') {
          queryPage = this.state.totalPages;
        }
        if (e.currentTarget.text === '«') {
          queryPage = 1;
        }
        this.scrollPosition = window.pageYOffset;
        this.page = this.state.isCloseouts ? queryPage : '&Page=' + queryPage;
        window.scrollTo(0, 0);
        this.fetchItems();
    }
    
    showHideImages = evt => {
        let img_txt_to_display =
        evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
        this.setState({ img_txt_to_display: img_txt_to_display });
    };

    getListingHeader = () =>{
        return (
            <div className='listing-header'>
                <span>
                    {this.state.firstItem}-{this.state.lastItem} of {' '}
                    {this.state.totalItems} products
                </span>
                <span
                    onClick={this.showHideImages}
                    id='show_and_hide_images'
                >
                    {this.state.img_txt_to_display}
                </span>
                <span className='pagination_top'>
                    {this.state.totalPages > 0 && 
                        getPagination(0,this.state.currentPage,this.state.totalPages,this.onPageChange,null,1)
                    }
                </span>
                {exportExcel({className:'exportPDF exportPdfPLP', onClick:this.fetchCompleteList})}
            </div>
        )
    }
    changeSearchBy = (e) =>{
        this.searchby = e.target.value;
        if(!utilities.isEmptyOrNullString(this.searchvalue)){
            this.fetchMonthlyProductsList();
        }
    }
    changeSearchValue = (e) =>{
        this.searchvalue = e.target.value;
    }
    search = (e) =>{
        if(e.target.name==='search-icon' || e.keyCode===13){ 
            this.page = 0;
            this.fetchMonthlyProductsList();
        }
    }
    getSearchByFields = () =>{
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSearchBy, id: 'pl_dropdown'};
        const options =[
            {props:{value:'itemnbr', id: 'itemnbr', className:'SelectOption'},text:'Item #'},
            {props:{value:'model', id: 'model', className:'SelectOption'}, text:'Model #'},
            {props:{value:'Upc',id:'upc', className:'SelectOption'},text:'UPC'}
        ];

        return (
            getSelect(selectProps,options)
        );
    }
    changeSortParam = (e) =>{
        this.sortParam = e.currentTarget.value;
        this.setState({
            sortBy:this.sortParam
        })  
        this.fetchItems();
    }
    getSortByFields = () => {
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown', value : this.state.sortBy};
        const { isWow } = this.state;
        const options = isWow?
                            [
                                {props:{value:'seqno', id: 'Sequence#', className:'SelectOption'}, text: 'Sequence#'},
                                {props:{value:'item_nbr', id: 'Item#', className:'SelectOption'}, text: 'Item#'},
                                {props:{value:'model', id: 'Model#', className:'SelectOption'}, text: 'Model#'},
                                {props:{value:'upc', id: 'UPC#', className:'SelectOption'}, text: 'UPC#'},
                                {props:{value:'spc_cost', id: 'EvtCost', className:'SelectOption'}, text: 'Event Cost'}
                            ]
                            : 
                            [
                                {props:{value:'item_nbr', id: 'Item#', className:'SelectOption'}, text: 'Item#'},
                                ...(this.props.aliasSKURetailerGrpID && !utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID)
                                ? [{props:{value:'alternateSku', id: 'Alternate SKU#', className:'SelectOption'}, text: 'Alternate SKU#'}] : []),                            
                                {props:{value:'model', id: 'Model#', className:'SelectOption'}, text: 'Model#'},
                                {props:{value:'Description', id: 'Description', className:'SelectOption'}, text: 'Description'},
                                {props:{value:'VendorName', id: 'VendorName', className:'SelectOption'}, text:'Vendor Name'}
                            ]
                            
    
        return(      
            getSelect(selectProps, options)
        );
    }

    changeSortDirection = (e) =>{
        this.sortDirection = e.currentTarget.value;
        this.setState({
            sortOrder:this.sortDirection
        })
        this.fetchItems();
    }
    DialogClose = () => this.setState({ dealDetails: false });
    optionsSection=()=>{
        const sortBy = this.getSortByFields();
        const searchBy = this.getSearchByFields();
        const popupStyle = {fontWeight: 100, fontSize: '10px'}
        return (
            <div className='options-div'>
                {this.state.isWow &&
                    <div className='ui icon input' id='search_div_address'>
                        <span className='vender-search-header'>Search </span>
                        { searchBy }
                        <input
                            type='text'
                            className='search-box_address'
                            onChange={this.changeSearchValue}
                            onKeyDown = {this.search}
                        />
                        <span className = 'search_icon_address' name='search-icon' onClick={this.search} >
                            {renderImages.getImage({
                                src: search_img,
                                name: 'search-icon'
                            })}
                        </span>
                    </div>
                }
                <div className='right-justified'>
                    <span>Show </span>
                    {
                        showItemCount(this.state.pageSize,this.changePageSize)
                    }
                </div>
                <div className='sortByCategories'>
                    <span>Sort by</span>
                    {   sortBy }
                    {
                        getSortByDirection(this.changeSortDirection,['asc','desc'],this.state.sortOrder,this.sortDirection)
                    }
                </div>
                
                <Responsive minWidth={1024}>
                    <div id='icon_align'>
                        <Popup content='List View' style ={popupStyle} trigger ={ <i
                            className='list icon cursor_p'
                            value='List'
                            id='list_icon'
                            size='mini'
                            onClick={() => this.handleSort('list')}
                        ></i>} />
                        |
                        <Popup content='Grid View' style ={popupStyle} trigger ={<i
                            className='th icon cursor_p'
                            value='Grid'
                            id='grid_icon'
                            size='mini'
                            onClick={() => this.handleSort('grid')}
                        ></i> } />
                    </div>
                </Responsive>
            </div>
        )
    }

    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };
    filterCallback = (isAdd, filterKey, filterValue) =>{
        let {appliedFilters, vendorDetails} = this.state;
        this.scrollPosition = window.pageYOffset;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
          appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
        } else if (keyExists) {
          appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
            ? appliedFilters[filterKey]
            : [appliedFilters[filterKey]];
    
          let value = appliedFilters[filterKey];
          let index = value.indexOf(filterValue);
          if (isArray(value) && index > -1) {
            value.splice(index, 1);   // to handle value Array with filter value pre-existing
          } else if (index > -1) {
            value = []         // to handle single string Value 
          } else {
            value.push(filterValue);
          }
          if (!value.length && keyExists) {
            delete appliedFilters[filterKey];
          } else {
            appliedFilters[filterKey] =  value;
          }
        }
        this.filterString = this.getMonthlyFilterString(
              appliedFilters,
              vendorDetails ? vendorDetails.vendorName+' - '+vendorDetails.vendorid : null
            );
        this.setState({
          currentPage: 1,
          appliedFilters,
          clearFilters: false,
          backPath : this.pathname
        });
        this.appliedFilters = appliedFilters;
        this.fetchMonthlyProductsList();
        this.setBackButtonPath();
    }
    render() {
        const{isMSSearch,isAllItems, vendorDetails, clearFilters, isNoProducts, appliedFilters, filters, showFilters, isWow, eventId, searchby,searchvalue,eventIdReunion,promoNmbr,promoName}=this.state;
        let orderDeadline = this.props.location.state?.orderDeadline;
        let saleLabel = saleType(this.props.monthlySizzleSaleValue)
        let header = `${isWow?'WOW':saleLabel}`;
        header = isAllItems?
                    header +  "- #"+utilities.emptyForNull(eventIdReunion)+" - "+utilities.emptyForNull(promoName)
                    :header+"- #"+utilities.emptyForNull(vendorDetails?.relayNbr)+" - "+utilities.emptyForNull(vendorDetails?.promoName);
        let breadcrumbPathMonthlySpecial = [
            { link: '/home', text: 'HOME' },
            { link: isWow?'/wow':'/monthlySpecials', text: header}
        ];
        breadcrumbPathMonthlySpecial = isAllItems?
                                            [...breadcrumbPathMonthlySpecial,
                                                {link:'#',text:"ALL ITEMS"},
                                                
                                            ]
                                            :[ 
                                                ...breadcrumbPathMonthlySpecial,
                                                {
                                                    link:`/${isWow?'wow':'monthlyEventVendors'}/${eventId}`,
                                                    text: 'Events '+ eventIdReunion +': '+promoNmbr + '-' + promoName
                                                },
                                                {link:'#',text:`${isMSSearch?'SEARCH':vendorDetails?.vendorDisplayName}`},
        ];
        return (
            
            this.state.serverError ?
                <InternalServerError />
                :
              <div className="page-wrap">
                   {this.state.loading && (
                        <div>
                            <Loader active />
                        </div>
                    )}
                <div className='page-header'>
                    {header}
                </div>
                <BreadcrumbComp path={breadcrumbPathMonthlySpecial}/>
                {isAllItems ? 
                <div className='monthlyFields'>
                    { `${promoName} (${promoNmbr})`}&nbsp;&nbsp;&nbsp;
                    {`Order Deadline: ${orderDeadline}`}&nbsp;&nbsp;&nbsp;
                </div> :
                    <div className='monthlyFields'>
                            {vendorDetails?.vendorDisplayName}&nbsp;&nbsp;&nbsp;
                            {`Order Deadline: ${vendorDetails?.deadline}`}&nbsp;&nbsp;&nbsp;
                            {!isMSSearch &&
                                <>
                                    <Button 
                                        onMouseEnter={this.showDealDetails} 
                                        onMouseLeave={this.DialogClose}
                                        size='mini'
                                        className='view-details-btn'
                                        content=' Deal Details' 
                                    />
                                    {this.state.dealDetails && (
                                        <div id='dealDetailsDialog'>
                                            <DealsDialog 
                                                vendorName={vendorDetails.vendorDisplayName}
                                                spiff={vendorDetails.spiff}
                                                member_terms={vendorDetails.member_terms}
                                                shipdate1 ={vendorDetails.shipdate1}
                                                shipdate2 ={vendorDetails.shipdate2}
                                                shipdate3 ={vendorDetails.shipdate3}
                                                shipdate4 ={vendorDetails.shipdate4} 
                                                DialogClose={this.DialogClose}
                                            />
                                        </div>      
                                    )}
                                </>
                            }      
                        </div>
                    }
                    {this.optionsSection()}
                    <div className='displayFlex allOrdersTable'>
                        {!isMSSearch &&
                            <div id='filter_width_ipad'>
                                <Filter
                                    clearFilters={clearFilters}
                                    filtersApplied={!_.isEmpty(appliedFilters)}
                                    appliedFilters={appliedFilters}
                                    toggleShowFilter={this.toggleShowFilter}
                                    showFilters={showFilters}
                                    filters={filters}
                                    callback={this.filterCallback}
                                    isNoProducts={isNoProducts}
                                    selectedVendor={vendorDetails?vendorDetails.vendorName+' - '+vendorDetails.vendorid:""}
                                    listType={6}
                                    
                                />
                            </div>
                        }
                        <div id='grid_width' className='full-width pl_data'>
                            {this.getListingHeader()}
                            {isNoProducts ? (
                                <div className='no_prdcts_display'>
                                <h1>No {saleLabel} are available at this time. Please check back next month</h1>
                                </div>
                                ) : (
                                    <>
                                        {this.getListing()}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
        )
    }
 }

const mapStateToProps = (state) =>{
    const viewTypeStorage = localStorage.getItem('viewType');
    return {
        userId: state.SessionReducer.UserId,
        userName: state.SessionReducer.address.userName,
        storeId: state.SessionReducer.storeId,
        currentOrders: state.cart.orders,
        currentCartId: state.cart.orders.currentCart.cartId,
        MSEvents : state.ReunionReducer.MSEvents,
        favorites: state.FavoritesReducer.favItemNums,
        getprefer: state.preference.listData,
        multiStoreDetails: state.SessionReducer.multiStoreDetails,
        viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
        aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
        rdc: state.SessionReducer.rdc,
        monthlySizzleSaleValue: state.cart?.orders?.monthlySizzleSaleValue,
        qtyUpdate: state.cart.orders?.updateQty

    }
}
export default connect(mapStateToProps,{addMonthlySpecialCart,updateQty})(MonthlySpecialsItemListingVendors);