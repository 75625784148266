import React, { Component } from 'react';
import Dialog from 'react-dialog';
import './styles.css';
import restUtils from '../../utils/restUtils';
import { REUNION_OFFERS } from '../../urlConstants';
import { Loader } from 'semantic-ui-react';
import Filter from '../common/Filter';
import _ from 'lodash';
import { isArray } from 'util';
import utilities from '../../utils/utilities';
import { connect } from 'react-redux';
import { DealsDialog } from './MonthlySpecials/DealsDialog';

class ReunionEventVendors extends Component {
    constructor(props) {
        super(props);
        const event = this.props.reunionEvents.find(event=>{
            return event.promo_nbr === props.eventId;
        })
        this.state = {
            eventVendorDetails: [],
            filters: [],
            clearFilters: false,
            appliedFilters: {},
            showFilters: (this.props.getprefer && this.props.getprefer.filterView) ? this.props.getprefer.filterView==='E'?true:false:window.innerWidth > 768?true:false,
            eventId: event?event.relay_nbr:'',
            promoNmbr: props.eventId,
            promoName: event?event.promo_name:'',
            orderDeadline: event?event.endOrderDate:'',
            dealDetails: false,
            selectedvendorName: ''
        }
        this.filterString = {};
    }

    componentDidMount() {
        this.getReunionEventVendors();
    }

    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };

    showDealDetails(vendorName) {
        this.setState({
            dealDetails: true,
            selectedvendorName: vendorName
        });
    }

    componentWillReceiveProps(newProps){
        if(this.props.searchby!==newProps.searchby || this.props.searchvalue!==newProps.searchvalue){
            this.getReunionEventVendors(newProps);
        }
    }

    DialogClose = () => this.setState({ dealDetails: false });

    filterCallback = (isAdd, filterKey, filterValue) => {
        let appliedFilters = this.state.appliedFilters;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
            appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
        } else if (keyExists) {
            appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
                ? appliedFilters[filterKey]
                : appliedFilters[filterKey].split(',');
            let value = appliedFilters[filterKey];
            let index = value.indexOf(filterValue);
            if (index > -1) {
                value.splice(index, 1);
            } else {
                value.push(filterValue);
            }
            if (!value.length && keyExists) delete appliedFilters[filterKey];
            else appliedFilters[filterKey] = value;
        }
        this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
        this.setState({
            appliedFilters,
            clearFilters: false
        });
        this.getReunionEventVendors();
    };

    getReunionEventVendors = (newProps) => {
        this.setState({ loading: true });
        const {searchby, searchvalue } = newProps?newProps:this.props;
        let url = `${REUNION_OFFERS}/listeventvendor`;
        let requestPayLoad = {
            "relayNbr": this.state.eventId,
            "promoNbr": this.state.promoNmbr,
            ...this.filterString
        }
        if(searchby && searchvalue){
            requestPayLoad = {...requestPayLoad,
                                searchby,
                                searchvalue
                            }
        }
        restUtils
            .postData(url, requestPayLoad)
            .then(response => {
                this.setState({
                    eventVendorDetails: response.data.Eventvendordetails,
                    filters: response.data.filters,
                    loading: false,
                    serverError: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, serverError: true });
            });
    }
    showItemList = (e) =>{
        const { shipdate1, shipdate2, shipdate3, shipdate4, vendorName } = e.target.dataset;
        const { eventId :relayNbr , promoNmbr: promoNbr, promoName, orderDeadline:deadline} = this.state;
        this.props.setListType(2,{
                                    relayNbr,
                                    promoNbr,
                                    promoName,
                                    vendorid : e.target.id,
                                    deadline:utilities.getDateFromTimeStamp(deadline),
                                    vendorName,
                                    shipdate1,
                                    shipdate2,
                                    shipdate3,
                                    shipdate4
                                });
    }
    render() {
        const gridClassName = window.innerWidth < 1024 ? 'ui stackable two column grid':window.innerWidth<1240?'ui stackable three column grid': 'ui stackable five column grid';
        return (
            <div className="page-wrap">
                {this.state.loading && (
                    <div>
                        <Loader active />
                    </div>
                )}
                <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
                    <Filter
                        clearFilters={this.state.clearFilters}
                        filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                        appliedFilters={this.state.appliedFilters}
                        toggleShowFilter={this.toggleShowFilter}
                        showFilters={this.state.showFilters}
                        filters={this.state.filters}
                        callback={this.filterCallback}
                        isNoProducts={this.state.isNoProducts}
                    />

                    <div className='page_details'>
                        <span>Event ID: {this.state.eventId}</span>
                        <span className="padding_left">Event Name: {this.state.promoNmbr} - {this.state.promoName}</span>
                        <span className="padding_left">Order Deadline: {utilities.getDateFromTimeStamp(this.state.orderDeadline)}</span>
                        <div className={gridClassName} id="reunion_items">

                        {this.state.eventVendorDetails && this.state.eventVendorDetails.map(event => {
                            const Vendor_Name = event.Vendor_Name.trim();
                            const shipDates = [event.shipdate1, event.shipdate2, event.shipdate3, event.shipdate4];
                            return (
                                <div className='column'>
                                    <div className="reunion-vendor-card" >
                                        <div className="reunion-vendor_name" 
                                            id = {event.vendor_id}
                                            data-vendor-name = {Vendor_Name}
                                            data-shipdate1 = { event.shipdate1 }
                                            data-shipdate2 = { event.shipdate2 }
                                            data-shipdate3 = { event.shipdate3 }
                                            data-shipdate4 = { event.shipdate4 }
                                            onClick = {this.showItemList}>
                                            {Vendor_Name}
                                        </div>

                                        <div className="deal_details">
                                            <span className='no_of_items'>Item(s): {event.num}</span>
                                            <span onClick={() => this.showDealDetails(Vendor_Name)} className='deal_deadline'>Deal Details</span>
                                            {this.state.dealDetails && (
                                                this.state.selectedvendorName === Vendor_Name &&
                                                <div id='dealDetailsDialog'>
                                                    <DealsDialog
                                                        vendorName={Vendor_Name}
                                                        member_terms={event.Member_Terms}
                                                        spiff = {event.note.replace(/([/r])/g," ")}
                                                        shipdate1 ={shipDates[0]}
                                                        shipdate2 ={shipDates[1]}
                                                        shipdate3 ={shipDates[2]}
                                                        shipdate4 ={shipDates[3]} 
                                                        DialogClose={this.DialogClose}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        reunionEvents : state.ReunionReducer.events,
        getprefer:state.preference.listData
    }
}

export default connect(mapStateToProps)(ReunionEventVendors);
