import React from 'react';

export const SortAndOrder = props => {
  const {
    sortOptions,
    sortValue,
    orderOptions,
    orderValue,
    onAction = () => {},
    children
  } = props;

  return (
    <div className='ui input'>
      <span className='filter-label'>Sort By</span>
      <select
        className='sort-criteria combo'
        onChange={e => onAction(e, 'sort')}
        value={sortValue}
      >
        {Options(sortOptions)}
      </select>
      <select
        className='sort-order combo'
        onChange={e => onAction(e, 'order')}
        value={orderValue}
      >
        {Options(orderOptions)}
      </select>
      {children}
    </div>
  );
};

export const Options = options => {
  return options.map(option => {
    const { text, value } = option;
    return (
      <option key={value} value={value}>
        {text}
      </option>
    );
  });
};
