import React, { Component } from 'react';
import feedbackScript from './feedbackScript';
export default class Feedback extends Component {
  componentDidMount() {
    feedbackScript();
  }
  render() {
    return <div id='ZN_dclWCzBXBxLOwyp'></div>;
  }
}
