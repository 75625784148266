import React, { Component } from 'react';
import InternalServerError from '../../ErrorPages/500_error_page';
import '../styles.css';
import restUtils from '../../../utils/restUtils';
import { REUNION_OFFERS } from '../../../urlConstants';
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import { Loader,Popup, Button } from 'semantic-ui-react';
import Filter from '../../common/Filter';
import _ from 'lodash';
import { isArray } from 'util';
import utilities from '../../../utils/utilities';
import history from '../../../history';
import { connect } from 'react-redux';
import { DealsDialog } from '../MonthlySpecials/DealsDialog';
import { getSortByDirection } from '../../common/CustomComponents';
import { getSelect } from '../../common/ComponentUtilities';
import VendorDetailsPopup from '../../Vendors/component/vendor-details-popup';

class DiscountCityEventVendors extends Component {
    constructor(props) {
        super(props);
        const eventId = this.props.match.params.eventId;
        const event = this.props.reunionEvents.find(event=>{
            return event.promo_nbr === eventId;
        });
        this.state = {
            eventVendorDetails: [],
            filters: [],
            clearFilters: false,
            appliedFilters: {},
            showFilters: (this.props.getprefer && this.props.getprefer.filterView) ? this.props.getprefer.filterView==='E'?true:false:window.innerWidth > 768?true:false,
            eventId: event?event.relay_nbr:'',
            promoNmbr: eventId,
            promoName: event?event.promo_name:'',
            orderDeadline: event?event.endOrderDate:'',
            dealDetails: false,
            selectedvendorName: ''
        }
        this.filterString = {};
        this.sortParam = "Num";
        this.sortDirection = "asc";
        this.searchby="upc"
    }
    changeSearchValue = (e) =>{
        this.searchvalue = e.target.value;
    }
    search = (e) =>{
        if(e.keyCode===13){
            this.getReunionEventVendors();
        }
    }
    componentDidMount() {
        this.getReunionEventVendors();
    }

    toggleShowFilter = () => {
        this.setState(prevState => {
            return { showFilters: !prevState.showFilters };
        });
    };

    showDealDetails(vendorName) {
        this.setState({
            dealDetails: true,
            selectedvendorName: vendorName
        });
        }
    searchItems = (e) => { 
        this.getReunionEventVendors();
    }

    componentWillReceiveProps(newProps){
        if(this.props.searchby!==newProps.searchby || this.props.searchvalue!==newProps.searchvalue){
            this.getReunionEventVendors(newProps);
        }
    }

    DialogClose = () => this.setState({ dealDetails: false });

    filterCallback = (isAdd, filterKey, filterValue) => {
        let appliedFilters = this.state.appliedFilters;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
            appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
        } else if (keyExists) {
            appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
                ? appliedFilters[filterKey]
                : appliedFilters[filterKey].split(',');
            let value = appliedFilters[filterKey];
            let index = value.indexOf(filterValue);
            if (index > -1) {
                value.splice(index, 1);
            } else {
                value.push(filterValue);
            }
            if (!value.length && keyExists) delete appliedFilters[filterKey];
            else appliedFilters[filterKey] = value;
        }
        this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
        this.setState({
            appliedFilters,
            clearFilters: false
        });
        this.getReunionEventVendors();
    };

    getReunionEventVendors = (newProps) => {
        this.setState({ loading: true });
        const {searchby, searchvalue } = newProps?newProps:this.props;
        let url = `${REUNION_OFFERS}/listeventvendor`;
        let requestPayLoad = {
            "relayNbr": this.state.eventId,
            "promoNbr": this.state.promoNmbr,
            "sortBy":this.sortParam,
            "sortOrder":this.sortDirection,
            ...this.filterString
        }
        if(this.searchvalue && this.searchvalue){
            requestPayLoad = {...requestPayLoad,
                            "searchvalue":this.searchvalue,
                            "searchby": utilities.isEmptyOrNullString(this.searchvalue)?null:this.searchby,
                            }
        }
        restUtils
            .postData(url, requestPayLoad)
            .then(response => {
                this.setState({
                    eventVendorDetails: response.data.Eventvendordetails,
                    filters: response.data.filters,
                    loading: false,
                    serverError: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, serverError: true });
            });
    }
    showItemList = (e) =>{
        const { shipdate1, shipdate2, shipdate3, shipdate4, vendorDisplayName, vendorName, spiff, member_terms } = e.target.dataset;
        const { eventId :relayNbr , promoNmbr: promoNbr, promoName, orderDeadline:deadline,searchvalue, searchby} = this.state;
        const vendorDetails = 
                {
                    relayNbr,
                    promoNbr,
                    promoName,
                    vendorid : e.target.id,
                    deadline:utilities.getDateFromTimeStamp(deadline),
                    vendorName,
                    vendorDisplayName,
                    shipdate1,
                    shipdate2,
                    shipdate3,
                    shipdate4,
                    spiff,
                    member_terms
                }
            const pathname = '/discountCityItems/'+ vendorDetails.promoNbr;
            history.push({
            pathname,
            state:{
                vendorDetailsOffer:vendorDetails,
                searchvalue : searchvalue,
                searchby:searchby
            }
        });
    }
    changeSortParam = (e) =>{
        this.sortParam = e.currentTarget.value;
        this.getReunionEventVendors();
    }
    changeSearchBy = (e) =>{
        this.searchby = e.target.value;
    }

    getSearchByFields = () =>{
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSearchBy, id: 'pl_dropdown'};
        let  options =  [
            {props:{value:'Upc',id:'upc', className:'SelectOption'},text:'UPC'}, 
            {props:{value:'itemnbr',id:'itemnbr', className:'SelectOption'},text:'Item #'},
            {props:{value:'vendor',id:'vendor', className:'SelectOption'},text:'Vendor Name'},
            {props:{value:'promo',id:'promo', className:'SelectOption'},text:'Promo Number'} ,     
        ]
        if(!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID)){
            options =[...options, {props:{value:'alternateSKU',id:'alternateSKU', className:'SelectOption'},text:'Alternate SKU#'}]  
        }

        return (
            getSelect(selectProps,options)
        );
    }
    getSortByFields = () => {
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown'};
                    
        const options = [
            {props:{value:'Num',id:'Num', className:'SelectOption'},text:'Number of items'},
            {props:{value:'VendorName', id: 'VendorName', className:'SelectOption'},text:'Vendor Name'},
        ];
    
        return(      
            getSelect(selectProps, options)
        );
    } 

    changeSortDirection = (e) =>{
        this.sortDirection = e.target.value;
        this.getReunionEventVendors();
    }
    render() {
        const gridClassName = window.innerWidth < 1024 ? 'ui stackable two column grid':window.innerWidth<1240?'ui stackable three column grid': 'ui stackable five column grid';
        const { eventId, promoNmbr, promoName, isWow} = this.state;
        const header = 'Discount City - ' + promoNmbr + '- Discount City';
        const discountsortBy = this.getSortByFields();
        const discountsearchBy = this.getSearchByFields();
        const breadcrumbPath = [
            { link: '/home', text: 'HOME' },            
            { link: '/discountCity', text: 'Discount City'},
            {
                link :'#',
                text : 'Vendors'
            }
        ];
        return (
            this.state.serverError ?
            <InternalServerError />
            :
            <div className="page-wrap">
                {this.state.loading && (
                    <div>
                        <Loader active />
                    </div>
                )}
                <div className='page-header'>
                {header}
                </div>
                <BreadcrumbComp path={breadcrumbPath}/>
                <br/>
                <div className='discount-vendorHead'>
                    <div className='ui icon input' id='search_div_address'>
                        <span className='vender-search-header'>Search </span>
                        { discountsearchBy }
                        <input
                            type='text'
                            className='search-box_address'
                            onChange={this.changeSearchValue}
                            onKeyDown = {this.search}
                        />
                         {renderImages.getImage({ 
                        src: search_img, 
                        className:'search_icon_address', 
                        onClick: this.searchItems
                        })}
                    </div>
                    <div className='vendor-sortdiscount'>
                    <span>
                        <span>Sort By </span>
                    </span>
                    {discountsortBy}
                    {  getSortByDirection(this.changeSortDirection,null,'asc')}
                    </div>
                </div>
                <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
                    <Filter
                        clearFilters={this.state.clearFilters}
                        filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                        appliedFilters={this.state.appliedFilters}
                        toggleShowFilter={this.toggleShowFilter}
                        showFilters={this.state.showFilters}
                        filters={this.state.filters}
                        callback={this.filterCallback}
                        isNoProducts={this.state.isNoProducts}
                    />
                    <div className='page_details'>
                        <span >Order Deadline: {utilities.getDateFromTimeStamp(this.state.orderDeadline)}</span>
                        <div className={gridClassName} id="reunion_items">
                        {this.state.eventVendorDetails && this.state.eventVendorDetails.map(event => {
                            const Vendor_Name = event.Vendor_Name.trim();
                            const shipDates = [event.shipdate1, event.shipdate2, event.shipdate3, event.shipdate4];
                            return (
                                <div className='column'>
                                    <div className="reunion-vendor-card" >
                                        <div className="discount-vendor_name">
                                        <Popup
                                            wide='very'
                                            hoverable
                                            content={<VendorDetailsPopup ID={event.vendor_id} />}
                                            header={Vendor_Name}
                                            position='bottom left'
                                            trigger={
                                                <Button
                                                size='mini'
                                                className='view-discount'
                                                content={Vendor_Name}
                                                />
                                            }
                                            />
                                        </div>

                                        <div className="deal_details">
                                            <span className='no_of_items discount-items'
                                                id = {event.vendor_id}
                                                data-vendor-name = {Vendor_Name}
                                                data-shipdate1 = { event.shipdate1 }
                                                data-shipdate2 = { event.shipdate2 }
                                                data-shipdate3 = { event.shipdate3 }
                                                data-shipdate4 = { event.shipdate4 }
                                                onClick = {this.showItemList}
                                            
                                            >Item(s): {event.num}</span>
                                            <span onClick={() => this.showDealDetails(Vendor_Name)} className='deal_deadline'>Deal Details</span>
                                            {this.state.dealDetails && (
                                                this.state.selectedvendorName === Vendor_Name &&
                                                <div id='dealDetailsDialog'>
                                                    <DealsDialog
                                                        vendorName={Vendor_Name}
                                                        member_terms={event.Member_Terms}
                                                        spiff = {event.note.replace(/([/r])/g," ")}
                                                        shipdate1 ={shipDates[0]}
                                                        shipdate2 ={shipDates[1]}
                                                        shipdate3 ={shipDates[2]}
                                                        shipdate4 ={shipDates[3]} 
                                                        DialogClose={this.DialogClose}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
       );
    }
}

const mapStateToProps = (state) =>{
    return {
        reunionEvents : state.ReunionReducer.events,
        getprefer:state.preference.listData,
        aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
    }
}

export default connect(mapStateToProps)(DiscountCityEventVendors);
