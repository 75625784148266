export const breadcrumbLinks = [{ link: '/home', text: 'HOME' }];
export const colDefs = [
  // {
  //   heading: 'Action',
  //   key: 'action:Remove'
  // },
  {
    heading: 'Order Date',
    key: 'invoiceDate'
  },
  {
    heading: 'PO #',
    key: 'memberRefNum'
  },
  {
    heading: 'ID #',
    key: 'book'
  },
  {
    heading: 'Promo #',
    key: 'relayNbr'
  },
  {
    heading: 'Promo Year',
    key: 'relayYear'
  },
  {
    heading: 'Item #',
    key: 'itemNbr'
  },
  {
    heading: 'Model #',
    key: 'modelNum'
  },
  {
    heading: 'Item Description',
    key: 'shortDesc'
  },
  {
    heading: 'Pack',
    key: 'packType+packQty'
  },
  {
    heading: 'Qty',
    key: 'userQty'
  },
  {
    heading: 'Cost',
    key: 'cost'
  },
  {
    heading: 'Expected Release Week',
    key: 'shipDate'
  },
  {
    heading: 'Canceled Date',
    key: 'dateSubmitted'
  },
  {
    heading: 'RDC',
    key: 'rdcNum'
  },
  {
    heading: 'Status',
    key: 'status'
  }
];

export const data = [
  {
    orderDate: '2018-9-7',
    po: 'xxxxxxx',
    id: '-',
    promo: '-',
    promoYear: 'xxx',
    itemNumber: 126861,
    modelNumber: 'EHI-0133-06',
    title: 'VH5 BLK Personal Heater',
    vendorName: 'Vornado Heat',
    packType: 'B',
    packQuantity: 6,
    quantity: 13,
    cost: 485.97
  },
  {
    orderDate: '2018-9-7',
    po: 'xxxxxxx',
    id: '-',
    promo: '-',
    promoYear: 'xxx',
    itemNumber: 126861,
    modelNumber: 'EHI-0133-06',
    title: 'VH5 BLK Personal Heater',
    vendorName: 'Vornado Heat',
    packType: 'B',
    packQuantity: 6,
    quantity: 13,
    cost: 485.97
  }
];
