import {
  departmentUrl,
  categoryUrl,
  subcategoryUrl,
  finelineProductListingUrl,
  finelineSaveCatalogUrl,
  finelineGetCatalogsUrl,
  finelineDeleteCatalogUrl,
} from "../../urlConstants";
import { restUtils } from "../../utils/restUtils";
import {
  fetchDepartmentDataFinelineCatalog,
  fetchCategoryDataFinelineCatalog,
  fetchSubcategoryDataFinelineCatalog,
} from "../../components/NavBar/navUtils";
import {
  FETCH_DEPARTMENTS,
  FETCH_DEPARTMENTS_FAIL,
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENT_CLASSES,
  FETCH_DEPARTMENT_CLASSES_FAIL,
  FETCH_DEPARTMENT_CLASSES_SUCCESS,
  FETCH_DEPARTMENT_SUBCLASSES,
  FETCH_DEPARTMENT_SUBCLASSES_SUCCESS,
  FETCH_DEPARTMENT_SUBCLASSES_FAIL,
  ADD_TO_CATALOG,
  ADD_TO_CATALOG_SUCCESS,
  ADD_TO_CATALOG_FAIL,
  REMOVE_FROM_CATALOG,
  CLEAR_CATALOG,
  SELECT_DEPARTMENT_CATALOG,
  SET_FILTER_VALUE,
  REMOVE_DEPARTMENT_FROM_LIST,
  ADD_DEPARTMENT_TO_LIST,
  STORE_CATALOG,
  STORE_CATALOG_FAIL,
  STORE_CATALOG_SUCCESS,
  GET_CATALOGS_SAVED,
  GET_CATALOGS_SAVED_SUCCESS,
  GET_CATALOGS_SAVED_FAIL,
  DELETE_CATALOG,
  ADD_CATALOG_AD,
  REMOVE_CATALOG_AD,
  UPDATE_CATALOG_AD
} from "./actionTypes";
import { ALL_CLASSES } from "./constants";
import { finelineCatalogFiltersName } from "../common/constants";
import { utilities } from "../../utils/utilities";

//
// DEPARTMENTS ACTION CREATORS
//
export const fetchDepartments = () => {
  return (dispatch) => {
    dispatch(fetchDepartmentsStart());
    restUtils
      .getData(departmentUrl)
      .then((response) => {
        const items = fetchDepartmentDataFinelineCatalog(response);
        dispatch(fetchDepartmentsSuccess(items));
      })
      .catch((err) => {
        dispatch(fetchDepartmentsFail(err));
      });
  };
};

export const fetchDepartmentsStart = () => ({
  type: FETCH_DEPARTMENTS,
});

export const fetchDepartmentsSuccess = (departments) => ({
  type: FETCH_DEPARTMENTS_SUCCESS,
  payload: {
    departments,
  },
});

export const fetchDepartmentsFail = (error) => ({
  type: FETCH_DEPARTMENTS_FAIL,
  payload: {
    error,
  },
});


export const removeDepartmentFromList = (department) => ({
  type: REMOVE_DEPARTMENT_FROM_LIST,
  payload: department,
});

export const addDepartmentToList = (payload) => ({
  type: ADD_DEPARTMENT_TO_LIST,
  payload
});


//
// DEPARTMENT CLASSES ACTION CREATORS
//
export const fetchDepartmentClasses = (departmentId) => {
  return (dispatch) => {
    const url = `${departmentUrl}?deptId=${departmentId}`;

    dispatch(fetchDepartmentClassesStart());
    restUtils
      .getData(url)
      .then((response) => {
        const items = fetchCategoryDataFinelineCatalog(response);
        dispatch(fetchDepartmentClassesSuccess(items));
      })
      .catch((err) => {
        dispatch(fetchDepartmentClassesFail(err));
      });
  };
};

export const fetchDepartmentClassesStart = () => ({
  type: FETCH_DEPARTMENT_CLASSES,
});

export const fetchDepartmentClassesSuccess = (classes) => ({
  type: FETCH_DEPARTMENT_CLASSES_SUCCESS,
  payload: {
    classes,
  },
});

export const fetchDepartmentClassesFail = (error) => ({
  type: FETCH_DEPARTMENT_CLASSES_FAIL,
  payload: {
    error,
  },
});

//
// DEPARTMENT CLASSES ACTION CREATORS
//
export const fetchDepartmentSubclasses = (depId, catId) => (dispatch) => {
  const url = `${departmentUrl}?deptId=${depId}&categoryId=${catId}`;

  dispatch(fetchDepartmentSubclassesStart());
  restUtils
    .getData(url)
    .then((response) => {
      const items = fetchSubcategoryDataFinelineCatalog(response);
      dispatch(fetchDepartmentSubclassesSuccess(items));
    })
    .catch((err) => {
      dispatch(fetchDepartmentSubclassesFail(err));
    });
};

export const fetchDepartmentSubclassesStart = () => ({
  type: FETCH_DEPARTMENT_SUBCLASSES,
});

export const fetchDepartmentSubclassesSuccess = (subClasses) => ({
  type: FETCH_DEPARTMENT_SUBCLASSES_SUCCESS,
  payload: {
    subClasses,
  },
});

export const fetchDepartmentSubclassesFail = (error) => ({
  type: FETCH_DEPARTMENT_SUBCLASSES_FAIL,
  payload: {
    error,
  },
});

//
// CATALOG ACTION CREATORS
//
export const addToCatalog = (department, selectedClass, subClasses) => (
  dispatch,
  getState
) => {
  const { finelineCatalog } = getState();
  const { departments } = finelineCatalog;
  let payloadSubClasses = subClasses;

  if (payloadSubClasses.length < 1 && selectedClass.name !== ALL_CLASSES) {
    payloadSubClasses = [...departments.subClasses];
  }

  const catalogPayload = {
    ...department,
    class: {
      ...selectedClass,
      subClasses: [...payloadSubClasses],
    },
  };

  dispatch(
    getCatalogItems({
      pageNumber: 1,
      addItem: {
        newDepartmentPayload: {
          catPath: department.name,
          FineLineClass:
            selectedClass.name === ALL_CLASSES ? [] : [selectedClass.name],
          FineLineSubClass: subClasses.map((s) => s.name),
        },
        catalogPayload,
      },
    })
  );
};

export const addToCatalogStart = (payload) => ({
  type: ADD_TO_CATALOG,
  payload,
});

export const addToCatalogFail = (error) => ({
  type: ADD_TO_CATALOG_FAIL,
  payload: { error },
});

export const addToCatalogSuccess = (itemDescription, items, catalog) => ({
  type: ADD_TO_CATALOG_SUCCESS,
  payload: {
    itemDescription,
    items,
    catalog,
  },
});

export const removeFromCatalog = (departmentId, name) => {
  return (dispatch, getState) => {
    const { finelineCatalog } = getState();
    const {
      catalog: { catalogItems, catalog },
    } = finelineCatalog;
    const newItems = catalogItems.filter((i) => i.Department !== name);
    const departmentData = catalog.find((d) => d.id === departmentId);
    
    if (departmentData) {
      delete departmentData.class;
      dispatch(addDepartmentToList(departmentData));
    }

    dispatch({
      type: REMOVE_FROM_CATALOG,
      payload: {
        newItems,
        departmentId,
      },
    });

    if (newItems.length > 0) {
      dispatch(getCatalogItems({}));
    }
  };
};

export const selectDepartmentCatalog = (departmentId) => (
  dispatch,
  getState
) => {
  const { finelineCatalog } = getState();
  const {
    catalog: { filters },
  } = finelineCatalog;

  dispatch(
    getCatalogItems({
      pageNumber: 1,
      pageSize: filters.pageSize,
      selectedDepartment: departmentId,
    })
  );
  dispatch({
    type: SELECT_DEPARTMENT_CATALOG,
    payload: {
      departmentId,
    },
  });
};

export const clearCatalog = (clearItems) => ({
  type: CLEAR_CATALOG,
  payload: {
    clearItems,
  }
});

export const getCatalogItems = ({
  pageNumber,
  pageSize,
  selectedDepartment,
  addItem = {},
}) => (dispatch, getState) => {
  const { SessionReducer, finelineCatalog } = getState();
  const { storeId, rdc } = SessionReducer;
  const { catalog } = finelineCatalog;
  const { filters } = catalog;

  let departmentsPayload = [
    ...catalog.catalog.map((i) => ({
      catPath: i.name,
      FineLineClass: i.class.name === ALL_CLASSES ? [] : [i.class.name],
      FineLineSubClass: i.class.subClasses.map((s) => s.name),
    })),
  ];

  if (selectedDepartment) {
    departmentsPayload = departmentsPayload.filter(
      (d) => d.catPath === selectedDepartment
    );
  }

  if (addItem && addItem.newDepartmentPayload) {
    departmentsPayload.push(addItem.newDepartmentPayload);
  }

  const requestPayload = {
    rdc,
    storeID: storeId,
    resultsPerPage: pageSize || filters.pageSize,
    sortParam: "",
    sortType: "",
    page: pageNumber || 1,
    department: departmentsPayload,
  };

  dispatch(addToCatalogStart());
  restUtils
    .postData(finelineProductListingUrl, requestPayload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.items.length > 0
      ) {
        const { itemDescription, items } = response.data;
        dispatch(
          addToCatalogSuccess(
            itemDescription,
            items,
            addItem && addItem.catalogPayload
          )
        );

        if (addItem && addItem.catalogPayload) {
          dispatch(removeDepartmentFromList(addItem.catalogPayload));
        }
      }
    })
    .catch((err) => {
      dispatch(addToCatalogFail(err));
    });
};

export const setFilterValue = (filterName, filterValue) => (dispatch) => {
  if (filterName === finelineCatalogFiltersName.pageSize) {
    dispatch(getCatalogItems({ pageNumber: 1, pageSize: filterValue }));
  }
  dispatch({
    type: SET_FILTER_VALUE,
    payload: {
      filterName,
      filterValue,
    },
  });
};

export const storeCatalog = (catalogName, viewType) => (dispatch, getState) => {
  const { SessionReducer, finelineCatalog } = getState();
  const { storeId, rdc, UserId, userName } = SessionReducer;
  const { catalog: catalogState } = finelineCatalog;
  const { filters, catalog} = catalogState;

  const departmentPayload = catalog.reduce((acc, department) => {
    if(department.class.name === ALL_CLASSES){
      return [{catPath: department.id, FineLineClass: '', FineLineSubClass: ''}]
    }

    const subClasses = department.class.subClasses.map((sc) => ({
      catPath: department.id,
      FineLineClass: department.class.id,
      FineLineSubClass: sc.id,
    }))

    return [...acc, ...subClasses];
  }, []);

  const requestPayload = {
    rdc,
    userId: UserId,
    Status: 1,
    DisplayStyle: viewType === 'grid' ? 1 : 2,
    MemberCost: filters.memberCost === 'Show' ? 1 : (filters.memberCost === 'Hide' ? 0 : 2),
    SuggestedRetail: filters.suggestedRetail === 'Show' ? 1 : (filters.suggestedRetail === 'Hide' ? 0 : 2),
    storeId: storeId,
    Location: "",
    department: departmentPayload,
    CatalogName: catalogName,
    CreatedBy: userName,
    RemovedItems: []
  };

  dispatch(storeCatalogStart());

  restUtils
    .postData(finelineSaveCatalogUrl, requestPayload)
    .then((res) => {
      if (res.status === 200) {
        utilities.showToast('Fineline Catalog Saved Successfully!');
        dispatch(storeCatalogSuccess());
      }
    })
    .catch(err => {
      dispatch(storeCatalogFail(err))
      utilities.showToast('There was an issue trying to save the catalog, please try again.', true);
    });
}

export const storeCatalogStart = () => ({
  type: STORE_CATALOG,
});

export const storeCatalogSuccess = (payload) => ({
  type: STORE_CATALOG_SUCCESS,
  payload
});

export const storeCatalogFail = (payload) => ({
  type: STORE_CATALOG_FAIL,
  payload
});

export const getFinelineCatalogs = (payload) => (dispatch, getState) => {
  const { SessionReducer } = getState();
  const { storeId, rdc, UserId } = SessionReducer;

  const payload = {
    userId: UserId,
    rdc,
    storeId,
  };

  dispatch(getFinelineCatalogsStart());

  restUtils
    .postData(finelineGetCatalogsUrl, payload)
    .then((res) => {
      if (res.status === 200) {
        dispatch(getFinelineCatalogsSuccess(res.data));
      }
    })
    .catch(err => {
      dispatch(getFinelineCatalogsFail(err))
    })
};

export const getFinelineCatalogsStart = (payload) => ({
  type: GET_CATALOGS_SAVED,
  payload
});

export const getFinelineCatalogsSuccess = (catalogs) => ({
  type: GET_CATALOGS_SAVED_SUCCESS,
  payload: {
    catalogs
  },
});

export const getFinelineCatalogsFail = (error) => ({
  type: GET_CATALOGS_SAVED_FAIL,
  payload: { error }
})

export const deleteFinelineCatalog = (catalogId) => (dispatch, getState) => {
  const { SessionReducer } = getState();
  const { userName } = SessionReducer;

  const payload = {
    CatalogRequestId: catalogId,
    ModifiedBy: userName
  };

  dispatch(deleteFinelineCatalogStart(catalogId));

  restUtils
    .postData(finelineDeleteCatalogUrl, payload)
}

export const deleteFinelineCatalogStart = (catalogId) => ({
  type: DELETE_CATALOG,
  payload: { catalogId }
})

//
// CATALOG AD ACTION CREATORS
//

export const addCatalogAd = (catalogAd) => ({
  type: ADD_CATALOG_AD,
  payload: {
    catalogAd,
  }
});

export const removeCatalogAd = (catalogAd) => ({
  type: REMOVE_CATALOG_AD,
  payload: {
    ad: catalogAd,
  }
});

export const updateCatalogAd = (catalogAd) => ({
  type: UPDATE_CATALOG_AD,
  payload: {
    ad: catalogAd
  }
})

