import React from 'react'
import { Menu } from "semantic-ui-react";
import LeftMenu from './LeftMenu'
import {withRouter} from 'react-router'

const textInput = React.createRef();

const esc = () => {
    textInput.current.focus()
}

const searchFocus = () => {
  if(document.getElementById('searchbox')){
    document.getElementById('searchbox').focus();
  }
}

const NavBarDesktop = ({location,...rest}) => (
    <Menu onMouseEnter = {esc} onMouseLeave= {searchFocus} inverted secondary className='desktop-menu-bar'>
         <input type="text" className ='hiddenInput' ref = {textInput} />
         <div  style={{width:'100%'}}>
        <LeftMenu currentPath={location} style={{width:'100%'}} />
        </div>
    </Menu>
  );

  export default withRouter(NavBarDesktop);
  