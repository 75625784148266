import React, { Component } from 'react';
import ProductListingGrid from '../common/ProductListingGrid';
import restUtils from '../../utils/restUtils';
import { Responsive, Loader } from 'semantic-ui-react'
import ProductListing from '../common/ProductListing';
import { handleChange, 
        addToCart,
        validateProductQuantity,
        hideToastMessage } from '../common/productHandler';
import classnames from 'classnames';
import { MULTIPLE_ORDER_PAD_ITEMS } from '../../urlConstants';
import { 
  addProduct, 
  createNAddCart,
  chooseCart } from '../Cart/actions';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CompetitorPrices from '../common/CompetitorPricing';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class BarcodeProduct extends Component {
    constructor(props){
        super(props)
        this.state = {
          categoryData: [],
          quantities: [],
          overrideRetails: [],
          tvMatchFound:false,
          gridView:window.innerWidth<960?true:false,
          competitorsShow:false,
          isExactMatch:false,
          isLoading: true,
        };
        this.handleChange = handleChange.bind(this);
        this.addToCart = addToCart.bind(this);
        this.validateProductQuantity = validateProductQuantity.bind(this);
        this.hideToastMessage = hideToastMessage.bind(this);
    }
    componentDidMount() {
      this.fetchProductDetails()
    }
    toggleViewType=(e)=>{
      this.setState({gridView:e.target.id === 'grid_icon'})
    }
    fetchProductDetails = () => {
      const { code } = this.props
      if(!code){
      return;
     }
     restUtils.getData(MULTIPLE_ORDER_PAD_ITEMS+`?upc=`+code).then(response=>{
      const categoryData = response.data;
      const isNoProducts = (!response.data || response.data.length === 0);
      this.setState({
        isNoProducts,
        categoryData,
        quantities:Array(categoryData.length).fill(''),
        overrideRetails:Array(categoryData.length).fill(''),
        tvMatchFound:true,
        toastMessage:'',
        isLoading: false,
        toastError:false
      })
     }).catch(e=>{
       this.setState({ isLoading: false})

     })
    }

    render() {
      const {
        quantities,overrideRetails,categoryData,isNoProducts,tvMatchFound,toastError,toastMessage
      } = this.state;
      
      return (
        <div className="page-wrap scannedWHproduct">
          {this.state.isLoading &&
          <div>
            <Loader active />
          </div>
        }
         {isNoProducts ?
            <div className='no_prdcts_display'>
              <h1>No Products to Display!</h1>
            </div>
            :
            <>
              <Responsive minWidth={1024}>
                <div id='icon_align'>
                  <i className='list icon cursor_p'
                      id='list_icon'
                      onClick={this.toggleViewType}></i>{' '} 
                  |
                  <i className='th icon cursor_p'
                      id='grid_icon'
                      onClick={this.toggleViewType}></i>
                </div>
              </Responsive>
                <>
                  <CompetitorPrices
                    item_upc={this.props.code}
                  />
                </>
              {this.state.gridView?
                <ProductListingGrid
                  viewType={this.props.viewType}
                  tableType={tvMatchFound?"tvProductScan":"competitorProducts"}
                  categoryData={categoryData}
                  quantities={quantities}
                  overrideRetails={overrideRetails}
                  handleChange = {this.handleChange}
                  addToCart = {this.addToCart}
                  validateProductQuantity = {this.validateProductQuantity}
                />
                :
                <ProductListing 
                  viewType={this.props.viewType}
                  tableType={tvMatchFound?"tvProductScan":"competitorProducts"}
                  categoryData={categoryData}
                  quantities={quantities}
                  overrideRetails={overrideRetails}
                  handleChange = {this.handleChange}
                  addToCart = {this.addToCart}
                  validateProductQuantity = {this.validateProductQuantity}
                />
              }
              <div id='snackbar'className={classnames({ red: toastError, show: toastMessage })}>
                {toastMessage}
              </div>
            </>
         }
        </div>
      );
    }
}
function mapStateToProps(state){
    const viewTypeStorage = localStorage.getItem('viewType');
    return{
      currentCartId: state.cart.orders.currentCart.cartId,
      getprefer:state.preference.listData,
      viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return Object.assign(
      { dispatch },
      bindActionCreators(
        {
          addProduct, 
          createNAddCart,
          chooseCart,
        },
        dispatch
      )
    );
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(BarcodeProduct);