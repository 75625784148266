import { combineReducers } from 'redux';
import _ from 'lodash';
import { RECEIVE_VENDOR, RECEIVE_VENDORS, REQUEST_VENDORS } from './actions';

const vendors = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_VENDORS:
      return state;
    case RECEIVE_VENDORS:
      const { key } = action;
      const vendorKey = key && key.length > 1 ? key[0].toUpperCase() : key;
      const newState = Object.assign({}, state, {
        [vendorKey]: Array.isArray(action.vendors) ? action.vendors : []
      });
      return newState;
    case RECEIVE_VENDOR:
      const { vendorDetails } = action;
      const { vendorsWithDetails = {} } = _.cloneDeep(state);
      const { VendorID } = vendorDetails;
      vendorsWithDetails[VendorID] = { ...vendorDetails };
      return { ...state, vendorsWithDetails };
    default:
      return state;
  }
};

const isFetching = (state = 0, action) => {
  switch (action.type) {
    case REQUEST_VENDORS:
      return state + 1;
    case RECEIVE_VENDORS:
      return state - 1;
    default:
      return state;
  }
};

export const getVendorsFetching = state => state.isFetching;
export const getVendors = state => state.vendors;
export const getVendorWithDetails = state => {
  const { vendors = {} } = state;
  const { vendorsWithDetails = {} } = vendors;
  return vendorsWithDetails;
};

export default combineReducers({
  vendors,
  isFetching
});
