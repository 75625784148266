import React, { Component } from 'react';
import utilities from '../../../utils/utilities';
import {ASSORTMENT_UNAVAILABLE_TEXT, NOT_ELIGIBLE_TEXT} from '../../common/constants';
import {ReunionAssortmentGrid} from './AssortmentUtilities'

class AssortmentGridView extends Component {
  render() {
    const { isNoProducts, assortment, backPath, endDate, shoppingAreaName, eventId, shoppingAreaId, shoppingAreaMetaId, flowType, promoName, fromReunionHome } = this.props;
    return (
      <div className='ui stackable three column grid' id='listing_grid'>
        {isNoProducts ? (
          <div className='no_prdcts_display'>
            <h1>{ASSORTMENT_UNAVAILABLE_TEXT}</h1>
          </div>
        ) : (
          assortment.map(assortmentItem => {
            return (
              <ReunionAssortmentGrid
                assortment={assortmentItem}
                endDate={endDate}
                showAssortmentList={this.props.showAssortmentList}
                backPath = {backPath}
                shoppingAreaName = {shoppingAreaName}
                eventId = {eventId}
                shoppingAreaId = {shoppingAreaId}
                shoppingAreaMetaId = {shoppingAreaMetaId}
                flowType = {flowType}
                promoName = {promoName}
                fromReunionHome = {fromReunionHome}
              />
            );
          })
        )}
      </div>
    );
  }
}

export default AssortmentGridView;
