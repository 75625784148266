import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

const FinelineCatalogPreviewDepartmentItem = ({
  item,
  removeDepartment,
  selectDepartmentCatalog,
  isSelected,
}) => {
  const { id, value, name } = item;
  const handleDeleteDepartment = () => {
    removeDepartment(id, name);
  };

  const handleSelectDepartment = (e) => {
    if (!e.target.className.includes("delete")) {
      if (isSelected) {
        selectDepartmentCatalog(null);
      } else {
        selectDepartmentCatalog(name);
      }
    }
  };

  return (
    <div className="department_tag_label--item">
      <Popup
        trigger={
          <a
            className={`ui circular label ${isSelected && "selected"}`}
            id={id}
            onClick={handleSelectDepartment}
          >
            {value}{" "}
            <i className="delete icon" onClick={handleDeleteDepartment}></i>
          </a>
        }
      >
        {item.class && (
          <Popup.Content className="preview_tooltip">
            <label className="preview_tooltip--label">Class</label>
            <small className="preview_tooltip--value">{item.class.value}</small>
            <hr />
            {item.class.subClasses && item.class.subClasses.length > 0 && (
              <>
                <label className="preview_tooltip--label">Sub-Classes</label>

                {item.class.subClasses.map((s) => (
                  <small className="preview_tooltip--value" key={s.id}>
                    - {s.value}
                  </small>
                ))}
              </>
            )}
          </Popup.Content>
        )}
      </Popup>
    </div>
  );
};

FinelineCatalogPreviewDepartmentItem.propTypes = {
  item: PropTypes.object,
  removeDepartment: PropTypes.func.isRequired,
  selectDepartmentCatalog: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default FinelineCatalogPreviewDepartmentItem;
