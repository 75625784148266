import React from 'react';
import { getSelect } from '../../common/ComponentUtilities';
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';
import { Responsive,Popup } from 'semantic-ui-react';
import { getSortByDirection, showItemCount } from '../../common/CustomComponents';
import utilities from '../../../utils/utilities';
export default class OptionsSection extends React.Component {
    getSearchByFields = () => {
        const {searchParam} = this.props;
        const selectProps = { className: 'sort-criteria combo option-style', onChange: this.props.changeSearchBy, id: 'pl_dropdown',...searchParam?{value:searchParam}:{} };
        return (
            getSelect(selectProps, this.props.searchByList)
        );
    }

    search = (e) => {
        if (e.target.name === 'search-icon' || e.keyCode === 13) {
            this.props.searchItems();
            return;
        }
    }
    handleSearchValue= e=>{
        const {searchPattern, changeSearchValue}=this.props;
        let isValid = true;
        if(searchPattern){
            isValid = e.target?.validity?.valid;
        }
        if(isValid){
            changeSearchValue(e);
        }
    }
    isValueValid=(pattern,value)=>{
        const regex = new RegExp(pattern);
        return regex.test(value);
    }
    getSortByFields = () => {
        const { changeSortParam, sortByList,sortParam} = this.props;
        const selectProps = { className: 'sort-criteria combo', onChange: changeSortParam,...!utilities.isEmptyOrNullString(sortParam)?{value:sortParam}:{} };
        return (
            getSelect(selectProps, sortByList)
        );
    }
    render() {
        const sortBy = this.getSortByFields();
        const searchBy = this.getSearchByFields();
        const {searchLabel,searchPattern,searchValue,sortOrder} = this.props;
        const defaultDirection = sortOrder? sortOrder:'asc';
        const popupStyle = {fontWeight: 100, fontSize: '10px'}
        return (
            <div className='options-div'>
                {this.props.isSearchRequired &&
                    <div className='search-section'>
                        <div className='ui icon input' id='search_div_address'>
                            <span className='vender-search-header'>{searchLabel ? searchLabel : "Search"} </span>
                            {searchBy}
                            <input
                                id='search_RetailAssortment'
                                type='text'
                                className='search-box_address'
                                onChange={this.handleSearchValue}
                                pattern = {searchPattern}
                                onKeyDown={this.search}
                                value= {decodeURIComponent(searchValue)}
                            />
                            <span className='search_icon_address' name='search-icon' onClick={this.search} >
                                {renderImages.getImage({
                                    src: search_img,
                                    name: 'search-icon'
                                })}
                            </span>
                        </div>
                    </div>
                }
                {
                    this.props.isShowByRequired &&
                    <div className='show-by-section right-justified'>
                        <span>Show </span>
                        {
                            showItemCount(this.props.pageSize, this.props.changePageSize)
                        }
                    </div>
                }
                {
                    this.props.isSortByRequired &&
                    <div className='sortByCategories'>
                        <span>Sort By</span>
                        {sortBy}
                        {
                            getSortByDirection(this.props.changeSortDirection,['asc','desc'],defaultDirection)
                        }
                    </div>
                }
                <Responsive minWidth={1023}>
                    {this.props.isGridViewIconRequired && <div id='icon_align'>
                    <Popup content='List View' style ={popupStyle} trigger={
                        <i
                            className='list icon cursor_p'
                            value='List'
                            id='list_icon'
                            onClick={() => this.props.handleViewChange(false)} 
                        ></i>} />{' '}
                        |<Popup content='Grid View' style ={popupStyle} trigger={
                        <i
                            className='th icon cursor_p'
                            value='Grid'
                            id='grid_icon'
                            onClick={() => this.props.handleViewChange(true)}
                        ></i>} />
                    </div>
                    }
                </Responsive>
            </div>
        )
    }
}