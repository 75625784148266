import React from 'react';
import classnames from 'classnames';
import { utilities } from '../../../src/utils/utilities';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

export const MaskPrice = props => {
  const { viewType, fieldVal,field,getprefer } = props;
  const isMemberView = (field === 'Member Cost'|| field === 'cart-totals') && viewType === MEMBER_VIEW;
  return (
    <span className={classnames({ cost_red_color: isMemberView })}>
      {(fieldVal || fieldVal === 0) && utilities.getCostBasedOnViewType(viewType, fieldVal,field,getprefer)}
    </span>
  );
};
