import React, { useState, useCallback, useMemo } from "react";
import { Checkbox, Radio } from "semantic-ui-react";

const FixtureLegendWrapperStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

const CTB_FREE_FIXTURE_COLOR = "rgba(33, 189, 69, 0.7)";
const CTB_NON_FREE_FIXTURE_COLOR = "rgba(255, 193, 7,0.7)";

const FixtureLegendStyle = (color=CTB_FREE_FIXTURE_COLOR) => ({
  marginLeft: "5px",
  marginRight: "5px",
  borderRadius: "2px",
  padding: "5px",
  width: "15px",
  height: "15px",
  backgroundColor: `${color}`,
});

const TableRow = React.memo(
  ({
    isReadOnly = false,
    uniqId,
    selected,
    fixture = {},
    handleSelect,
    isDisplayItemLimitless,
    selectedLimitlessId
  }) => {
    const isFree = fixture?.ctbFree || false;
    const handleSelection = (event, data) => {
      handleSelect(fixture, event, data);
    };

    const handleOptionSelection = (event) => {
      const data = {
        id: event.target.id,
        checked:true,
        val: event.target.value
      }      
      handleSelect(fixture, event, data);
    };
    const selectDisplay  = selectedLimitlessId?.filter( x => x.assmtNbr === fixture?.number);
    const displayvalues = isDisplayItemLimitless?.filter(x => x.DisplayItemId == selectDisplay[0]?.displayItemsId )
   
    return (
      <>
      <tr
        style={{
          backgroundColor: isFree
          
        }}
      >
        
        <td>{fixture?.ctb_pog_nbr}</td>
        <td>{fixture?.description || "-"}</td>  
        { isReadOnly && displayvalues[0]?.Description && 
            <td>
             <label>{displayvalues[0]?.Description} </label>
                </td>    } 
      </tr>
      { !isReadOnly && isDisplayItemLimitless.length > 0 &&  
       <tr><td colSpan={"3"}><table>
        <tr>
        
          <td>Limitless paint Display Options, Please select the display option you would like:</td>
        </tr>
        { isDisplayItemLimitless.map((item) => {
          return   <tr>
            <td>
            <input
                    id={`${fixture?.number}`}
                    name={fixture?.number}
                    onClick={handleOptionSelection}
                    style={{ paddingTop: "5px" }}
                   
                    type="radio"
                    value={item.DisplayItemId}
                  /> <label for={`${fixture?.number}`}>{item.Description} </label>
                </td>
            </tr>
        })
        }        
    
        </table></td></tr>}
      </>
       
    );
  }
);

function LimitlessTable({
  limitlessPlanogram = [],
  isReadOnly = false,
  noIndicators = false,
  showAll = false,
  selectedLimitlessId = [],
  updateLimitlessSelection,
  isDisplayItemLimitless
}) {
  const [state, setState] = useState({});
  
  //If show all - then show all fixtures.
  const fixtureList = useMemo(() => {
    const _fixtureList = [...limitlessPlanogram];
    // item fixture listing page
    if (showAll) {
      return _fixtureList;
    }
    return _fixtureList.filter((f) => f?.IsLimitless === "Y");   
  }, [showAll, limitlessPlanogram]);

  const handleSelect = useCallback((fixture, _e, { id, checked, val = 1 }) => {
    setState((state) => {
      const selected = { ...state };     
      if (checked) {
        selected[id] = val ;
      } else {
        delete selected[id];
      }
      updateLimitlessSelection(selected);     
      return selected;
    });    
  }, []);
  
  return (
    <>          
      <table className="cart-products fixture-table">
        <thead style={{ backgroundColor: "rgba(128,135,139,.3)" }}>
          <tr>          
            <th>
              <div>POG</div>
            </th>
            <th>
              <div>POG Description</div>
            </th>
            {isReadOnly && <th><div>Selected Display Items Details</div></th> }           
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "white" }}>
          {fixtureList.map((fixture, uniqId) => (
           
            <TableRow
              key={fixture.number}
              uniqId={uniqId}
              fixture={fixture}
              state={state}
              selected={
                fixture?.limitless
                  ? state[`${fixture?.number}-${uniqId}`]
                  : state[fixture.number]
              }
              handleSelect={handleSelect}
              isReadOnly={isReadOnly}
              isDisplayItemLimitless = {isDisplayItemLimitless}
              selectedLimitlessId = {selectedLimitlessId}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

export default LimitlessTable;
