import React from "react";
import Iframe from "react-iframe";
import { Loader } from "semantic-ui-react";

import './index.css';

class FlipbookViewer extends React.Component {
  state = {
    pdfUrl: null,
    isLoading: true,
  };
  componentDidMount() {
    const pdfParam = this.getParamValue("pdf");
    if (pdfParam) {
      const that = this;

      const xhr = new XMLHttpRequest();
      xhr.open("GET", pdfParam);
      xhr.responseType = "arraybuffer";

      xhr.onload = function () {
        if (this.status === 200) {
          const blob = new Blob([xhr.response], { type: "application/pdf" });
          const objectUrl = URL.createObjectURL(blob);
          that.setState({ pdfUrl: objectUrl, isLoading: false });
        }
      };
      xhr.send();
    } else {
      this.setState({ isLoading: false });
    }
  }

  getParamValue(paramName) {
    const url = window.location.search.substring(1); //get rid of "?" in querystring
    const qArray = url.split("&"); //get key-value pairs
    for (let i = 0; i < qArray.length; i++) {
      const pArr = qArray[i].split("="); //split key and value
      if (pArr[0] === paramName) return pArr[1]; //return value
    }
  }

  render() {
    const { origin } = window.location;
    const { pdfUrl, isLoading } = this.state;

    if (isLoading) return <Loader active />;
    if (!pdfUrl) return <div>No PDF available</div>;

    return (
      <div className="iframe-pdf-container">
        <Iframe
          src={`${origin}/pdf-viewer/external/pdfjs/web/viewer.html?pdf=${pdfUrl}`}
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default FlipbookViewer;
