import { orderNames, infoTypes } from '../Cart/constants';
import utilities from '../../utils/utilities';
import store from '../../configureStore';
import { IMPULSE_MERCHANDISING } from '../Categories/constants';
import _ from 'lodash';

export const quantityValidator = (quantity, allowZero = false) => {
  if (quantity !== '') {
    const regularExpression = /^\d+$/;
    if (!regularExpression.test(quantity)) {
      return false;
    }
    const quantityValue = parseFloat(quantity);
    if (quantityValue <= 0) {
      if(allowZero) {
        return true;
      }
      utilities.showToast(`Quantity should be positive number!`,false);
      return false;
    }

    if (quantityValue > 9999) {
      utilities.showToast(`Quantity should not exceed 9999!`,false);
      return false;
    }
  }

  return true;
};

export const handleChange = function(event) {
  const { dataset, value } = event.target;
  const { field, itemNumber } = dataset;
  const fieldValue = parseFloat(value);

  let validationMessage = '';
  if (field !== 'or' && !quantityValidator(value)) {
    return;
  }
  let { quantities, overrideRetails,planogramMemberCost } = this.state;

  switch (field) {
    case 'qty':
      quantities[itemNumber] = fieldValue || '';
      if(this.state.isPlanogram && !_.isEmpty(planogramMemberCost)) {
        planogramMemberCost[itemNumber].orderQty = isNaN(fieldValue) ? 0 : fieldValue
      }
      break;
    case 'or':
      if (value.trim() === '' || fieldValue < 0) {
        utilities.showToast('Override Retail should be positive number!',true);
      }
      if (/^(\d{0,5}\.\d{0,2}|\d{1,5})$/.test(value)) {
        overrideRetails[itemNumber] = value || '';
      } else {
        overrideRetails[itemNumber] = fieldValue || '';
      }
      break;
    case 'qty1':
      quantities[itemNumber][0] = fieldValue || '';
      break;
    case 'qty2':
      quantities[itemNumber][1] = fieldValue || '';
      break;
    case 'qty3':
      quantities[itemNumber][2] = fieldValue || '';
      break;
    case 'qty4':
      quantities[itemNumber][3] = fieldValue || '';
      break;
    default:
      quantities[itemNumber] = fieldValue || '';
      break;
  }

  this.setState({
    quantities: [...quantities],
    overrideRetails: [...overrideRetails],
    toastMessage: validationMessage,
    toastError: validationMessage ? true : false,
    planogramMemberCost
  });
};
export const handleChangeValue = function(event,indexCheck,field, index =0) {
  try {
   const {value } = event.target;
   let { quantities, overrideRetails} = this.state;
  switch (field) {
    case 'qty':
      quantities[indexCheck] = value || '';
      break;
    case 'shipDate':
      quantities[indexCheck][index] = value || '';
      break;
    case 'override':
      overrideRetails[indexCheck] = value || '';
      break;
    default:
      break;
  }
  } catch (error) {
    console.log("--------error----",error)
  }
};
export const classicHandleChange = function(event) {
  const { dataset, value } = event.target;
  const { field, itemNumber, item } = dataset;
  const fieldValue = parseFloat(value);
  let validationMessage = '';
  if (field !== 'or' && !quantityValidator(value)) {
    return;
  }
  let { quantities, overrideRetails } = this.state;

  switch (field) {
    case 'qty':
      quantities[itemNumber] = fieldValue || '';
      break;
    case 'or':
      if (value.trim() === '' || fieldValue < 0) {
        utilities.showToast('Override Retail should be positive number!',true);
      }
      if (/^(\d{0,5}\.\d{0,2}|\d{1,5})$/.test(value)) {
        overrideRetails[itemNumber] = value || '';
      } else {
        overrideRetails[itemNumber] = fieldValue || '';
      }
      break;
    case 'qty1':
      quantities[itemNumber][0] = fieldValue || '';
      break;
    case 'qty2':
      quantities[itemNumber][1] = fieldValue || '';
      break;
    case 'qty3':
      quantities[itemNumber][2] = fieldValue || '';
      break;
    case 'qty4':
      quantities[itemNumber][3] = fieldValue || '';
      break;
    default:
      quantities[itemNumber] = fieldValue || '';
      break;
  }

  this.setState({
    quantities: [...quantities],
    overrideRetails: [...overrideRetails],
    toastMessage: validationMessage,
    toastError: validationMessage ? true : false
  });
};
export const classicFavHandleChangeblur = function(event) {
  const { dataset, value } = event.target;
  const { itemNumber,packQty,packType,recqty, field, item, label, overrideretail, maxqty, product } = dataset;
  let { dataSetValue, inputFocus, quantities } = this.state;
  const  items = dataSetValue && dataSetValue.map(ele => ele.item)
  let isItemExist = items.includes(parseInt(item))
  if ((field !== 'or' && !quantityValidator(value))) {
    this.setState({inputFocus: [...inputFocus, parseInt(item)], quantities: [...quantities , parseInt(value)]})
    return;
  }
  if (field !== 'or' && !classicValidateProductQuantityblur(packType, packQty, parseInt(value), maxqty)) {
    if(!inputFocus.includes(parseInt(item))) {
      this.setState({inputFocus: [...inputFocus, parseInt(item)], quantities: [...quantities , parseInt(value)]})
    }
  }

  if (field === 'or' && overrideretail !== '' && isItemExist) {
    dataSetValue = dataSetValue.map((ele)=> {
      if(ele.item === parseInt(item)) {
        ele.overrideRetail = overrideretail;
      }
      return ele
    })
  }
  if(field !== 'or' && !isNaN(parseInt(value)) && classicValidateProductQuantityblur(packType, packQty, value, maxqty)) {
    if(inputFocus.includes(parseInt(item))) {
      quantities.splice(inputFocus.indexOf(parseInt(item)), 1)
      inputFocus.splice(inputFocus.indexOf(parseInt(item)), 1)
    }

    if(isItemExist) {
      dataSetValue = dataSetValue.map((ele)=> {
       if(ele.item === item) {
         ele.qty = value;
         ele.overrideRetail = overrideretail;
         ele.productItem = product;
       }
       return ele
     })
    }
    else {
    dataSetValue.push({item: item, qty: value, overrideRetail: overrideretail, productItem: product})
    }
  }
  this.setState({
    dataSetValue: dataSetValue
  });

}
export const classicHandleChangeblur = function(event) {
  const { dataset, value } = event.target;
  const { itemNumber,packQty,packType,recqty, field, label, maxqty } = dataset;
  let { item } = dataset
  item = parseInt(item)
  let { quantities, dataSetValue, overrideRetails, categoryData,inputFocus } = this.state;
  let productItem = categoryData && categoryData.find(ele => parseInt(ele.Item_Number) === item)
  const  items = dataSetValue.map(ele => ele.item)
  let isItemExist = items.includes(item)
  if ((field !== 'or' && !quantityValidator(value))) {
    return;
  }
  if (field !== 'or' && !classicValidateProductQuantityblur(packType, packQty, value, maxqty)) {
    //quantities[itemNumber] = recqty || '';
    if(!inputFocus.includes(item)) {
      this.setState({inputFocus: [...inputFocus, item]})
    }
    
  }
  if(!isNaN(parseInt(quantities[itemNumber])) && classicValidateProductQuantityblur(packType, packQty, value, maxqty)) {
    if(inputFocus.includes(item)) {
      inputFocus.splice(inputFocus.indexOf(item), 1)
    }
    if(isItemExist) {
      dataSetValue = dataSetValue.map((ele)=> {
       if(ele.item === item) {
         ele.qty = quantities[itemNumber];
         ele.overrideRetail = overrideRetails[itemNumber];
         ele.productItem = productItem;
       }
       return ele
     })
    }
    else {
    dataSetValue.push({item: item, qty: quantities[itemNumber], overrideRetail: overrideRetails[itemNumber], productItem})
    }
  }
  
  this.setState({
    dataSetValue: dataSetValue,
  });
};
  
export const handleChangeblur = function(event) {
  const { dataset, value } = event.target;
  const { itemNumber,packQty,packType,recqty } = dataset;
  let { quantities } = this.state;
  if (!validateProductQuantityblur(packType, packQty, value)) {
    quantities[itemNumber] = recqty || '';
  }
  this.setState({
    quantities: [...quantities]
  });
};

export const changeStateForValue = function(event) {
  const {field} = event.target.dataset;
  const { value, name } = event.target;
  if(field && field === 'qty' && !quantityValidator(value)){
    return false;
  }
  this.setState({ [name]: value });
};

export const changeStateForRAValue = function(event, month, index,flag) {
  const { dataset, value } = event.target;
  const { field } = dataset;
  const fieldValue = parseFloat(value);
  if(field && field === 'qty' && !quantityValidator(value)){
    return false;
  }
  let {retailAssortmentQty, ctbAssortmentQty} = this.state;
  if(flag === 'Y'){
    let updateRaflag = false;
    let i = 0;
    for  (i = 0;i < retailAssortmentQty.length;i++) {
        if (retailAssortmentQty[i]?.name === month) {
            retailAssortmentQty[i].value =fieldValue||'' ;
            updateRaflag = true;
        }
    }
    if (!updateRaflag){
      retailAssortmentQty[index] = {...retailAssortmentQty[index], 'name': month,'value': fieldValue || ''}
    }
    this.setState({ retailAssortmentQty});
  }
  else if(flag === 'CTB') {
    let updateRaflag = false;
    let i = 0;
    for  (i = 0;i < ctbAssortmentQty.length;i++) {
        if (ctbAssortmentQty[i]?.name === month) {
          ctbAssortmentQty[i].value =fieldValue||'' ;
            updateRaflag = true;
        }
    }
    if (!updateRaflag){
      ctbAssortmentQty[index] = {...ctbAssortmentQty[index], 'name': month,'value': fieldValue || ''}
    }
    this.setState({ ctbAssortmentQty});
  }
  else{
    this.setState({ retailAssortmentQty});
  }

};
export const isMaxQtyValid = (qty, maxQty, isMultiples) => {
  if (!(maxQty && maxQty > 0)) return true;
  if (!isMultiples && qty > maxQty) {
    utilities.showToast("Maximum Order quantity is " + maxQty);
    return false;
  } else if (isMultiples && qty % maxQty !== 0) {
    utilities.showToast("Quantity should be a multiple of " + maxQty);
    return false;
  }
  return true;
};

export const validateQty = function(packType, packQty, qty, maxQty = 0) {
  let toastError = false;
  if (packType === 'F' && qty % packQty) {
    toastError = true;
  }else if (isRestrictedQuantity(qty)) {
    toastError = true;
  }else if (qty > 9999) {
    toastError = true;
  }
  else if (maxQty && qty > maxQty) {
    toastError = true;
  }
  return toastError;
}

export const validateProductQuantity = function(packType, packQty, qty, maxQty = 0) {
  let toastError = false;
  let toastMessage = '';
  if (packType === 'F' && qty % packQty) {
    toastMessage = `Quantity should be multiple of ${packQty}!`;
    toastError = true;
  } else if (!utilities.isValidQuantity(qty)) {
    toastMessage = 'Please enter a valid quantity';
    toastError = true;
  }else if (isRestrictedQuantity(qty)) {
    toastMessage = `Quantity should not be ${qty}!`;
    toastError = true;
  }else if (qty > 9999) {
    toastMessage = `Quantity should not exceed 9999!`
    toastError = true;
  }
  else if (maxQty && qty > maxQty) {
    toastMessage = `Maximum Order quantity is ${maxQty}`
    toastError = true;
  }
  if (toastError) {
    utilities.showToast(toastMessage,toastError)
  }
  return !toastError;
};

export const validateCartProductQuantity = function(packType, packQty, qty, maxQty = 0) {
  let toastError = false;
  let toastMessage = '';
  if (packType === 'F' && qty % packQty) {
    toastMessage = `Quantity should be multiple of ${packQty}!`;
    toastError = true;
  } else if (!utilities.isValidQuantity(qty)) {
    toastMessage = 'Please enter a valid quantity';
    toastError = true;
  }else if (isRestrictedQuantityOrderPad(qty)) {
    toastMessage = `Quantity should not be ${qty}!`;
    toastError = true;
  }else if (qty > 9999) {
    toastMessage = `Quantity should not exceed 9999!`
    toastError = true;
  }
  else if (maxQty && qty > maxQty) {
    toastMessage = `Maximum Order quantity is ${maxQty}`
    toastError = true;
  }
  if (toastError) {
    utilities.showToast(toastMessage,toastError)
  }
  return !toastError;
};

export const classicValidateProductQuantityblur = function(packType, packQty, qty, maxQty = 0) {
  let toastError = false;
  let toastMessage = '';
  if (packType === 'F' && qty % packQty) {
    toastMessage = `Quantity should be multiple of ${packQty}!`;
    toastError = true;
  }else if (isRestrictedQuantity(qty)) {
    toastMessage = `Quantity should not be ${qty}!`;
    toastError = true;
  }else if (qty > 9999) {
    toastMessage = `Quantity should not exceed 9999!`
    toastError = true;
  }
  else if ( parseInt(maxQty) > 0 && parseInt(qty) > parseInt(maxQty)) {
    toastMessage = `Maximum Order quantity is ${maxQty}`
    toastError = true;
  }
  if (toastError) {
    utilities.showToast(toastMessage,toastError)
  }
  return !toastError;
  };

export const validateProductQuantityblur = function(packType, packQty, qty) {
  let toastError = false;
  let toastMessage = '';
  if (packType === 'F' && qty % packQty) {
    toastMessage = `Quantity should be multiple of ${packQty}!`;
    toastError = true;
  }
  if (toastError) {
    utilities.showToast(toastMessage,toastError)
  }
  return !toastError;
};

export const isRestrictedQuantity = qty => {
  const restrictedValues = [777, 888, 999];
  return restrictedValues.indexOf(+qty) !== -1;
};

export const isRestrictedQuantityOrderPad = qty => {
  const restrictedValues = [777, 999];
  return restrictedValues.indexOf(+qty) !== -1;
};

export const addToCart = function(
  itemNumber,
  cost,
  qty,
  or,
  packType,
  packQty,
  selectedCartId = null,
  rdcNum='',
  storeId='',
  maxQty
) {

  if (!validateProductQuantity(packType, +packQty, qty, maxQty)) {
    return false;
  }
  const cartId = selectedCartId ? selectedCartId : this.props.currentCartId;
  if (!cartId || cartId === 'new') {
    this.props.createNAddCart(
      itemNumber, 
      qty, 
      or ? or : '', 
      cost,infoTypes[1],
      1,
      "Default",
      rdcNum?rdcNum:this.props.rdc,
      storeId?storeId:this.props.storeId,
    );
  } else {
    this.props.addProduct(
      cartId, 
      itemNumber,
      qty, 
      or ? or : '', 
      cost,
      infoTypes[1],
      '', 
      rdcNum?rdcNum:this.props.rdc,
      storeId?storeId:this.props.storeId,
    );
  }
};
export const classicAddToCart = function(products,selectedCartId = null) {
   const cartId = selectedCartId ? selectedCartId : this.props.currentCartId;
   if (!cartId || cartId === 'new') {
     this.props.classicCreateNAddCart(products,infoTypes[1],1, "Default")
   } else {
     this.props.classicAddProduct(products, cartId)
     
   }
 };
 export  const getClassicViewPayload = function(orderType) {
   let payLoads = []
   const { dataSetValue } = this.state;
   dataSetValue && dataSetValue.map((ele) => {
   const rdcNum = utilities.rdcBasedonCentralship(ele.productItem?.rdctype, ele.productItem?.CsRDC, this.props.rdc);
   const storeRdcNum = this.props.multiStoreDetails?.childStoreDetails !== {} ?
      this.props.multiStoreDetails.childStoreDetails[this.props.storeId] : '';
     let payLoad =[
       {
         CartId: this.props.currentCartId,
         ItemNumber: ele.item,
         ItemQty: ele.qty,
         OverrideRetail: ele.overrideRetail,
         RDC: utilities.isEmptyOrNullString(rdcNum)
           ? this.props.rdc
           : rdcNum,
         ItemSource: orderType !== "DOM" ? orderNames[infoTypes[1]].key : ele.productItem.promo_nbr,
         TotalQtyInOrder:ele.qty,
         userName: this.props.userName,
         storeId: this.props.storeId,
         primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : ''
       },{
         product:ele.productItem
       }]
       payLoads.push(payLoad)
     })
   return payLoads
	 }

export const addPlanogramToCart = function(
  assortmentId,
  carts,
  costs,
  userId,
  storeId
) {
  let { quantities } = this.state;
  const products = this.state.categoryData.filter((product, index) => {
    return quantities[index] && utilities.isValidQuantity(quantities[index]);
  });
  quantities = quantities.filter(qty => qty && parseInt(qty) > 0);
  if (!quantities || quantities.length === 0) {
    this.setState({
      toastMessage: 'Please enter a valid quantity',
      toastError: true
    });
    return;
  }
  let isfullpack=true;
  let itemList = products.map((product, index) => {
    if (!this.validateProductQuantity(product.S_Pack_Type, product.S_Pack_Qty, quantities[index],product.maxQty)) {
      isfullpack = false;
    }
    const qty = quantities[index];
    return {
      ItemNumber: product.Item_Number,
      userQuantity: qty,
      rdcNum: this.props.rdc,
      source: 'WH',
      userName: this.props.userName
    };
  });
  let requestPayLoad = {
    storeId: storeId,
    userId: userId,
    molUserName: store.getState().SessionReducer.userName,
    planogramNumber: assortmentId,
    primaryWarehouseNumber: this.props.rdc,
    addItems: itemList
  };
  isfullpack && this.props.addItemstoCartPlanogram(requestPayLoad, costs);
};
export const addDomProduct = function(
  itemNumber,
  cost,
  qty,
  or,
  packType,
  packQty,
  promoNbr
) {
  if (!this.validateProductQuantity(packType, +packQty, qty)) {
    return false;
  }
  const cartName = 'Promo '+promoNbr;
  const { carts } = this.props;
  const existingCart = carts.find(
    cart => {
      return  (cart.TypeId === orderNames['DOM'].infoType) && cart.cartName===cartName;
    }
  );
  if (!existingCart) {
    this.props.createNAddCart(
      itemNumber,
      qty,
      or ? or : '',
      cost,
      'DOM',
      orderNames['DOM'].infoType,
      cartName,
      this.props.rdc,
      '',
      '',
      '',
      promoNbr
    );
  } else {
    this.props.chooseCart(existingCart);
    this.props.addProduct(
      existingCart.cartId,
      itemNumber,
      qty,
      or ? or : '',
      cost,
      'DOM',
      '',
      this.props.rdc,
      this.props.storeId,
      promoNbr
    );
  }
};

export const classicAddDomProduct = function(products,selectedCartId = null) {
  const cartName = 'Promo '+products[0][1].product.promo_nbr;
  const { carts } = this.props;
  const existingCart = carts.find(
    cart => {
      return  (cart.TypeId === orderNames['DOM'].infoType) && cart.cartName===cartName;
    }
  );
  if (!existingCart) {
    this.props.classicCreateNAddCart(products,'DOM',orderNames['DOM'].infoType, cartName, 'DOM')
  
  } else {
    this.props.chooseCart(existingCart)
    this.props.classicAddProduct(products, existingCart.cartId, cartName, 'DOM')
    
  }
  };
  
export const addImToCart = function(costs) {
  const { overrideRetails } = this.state;
  let { quantities } = this.state;
  const products = this.state.categoryData.filter((product, index) => {
    return quantities[index] && parseInt(quantities[index]) > 0;
  });
  quantities = quantities.filter(qty => qty && parseInt(qty) > 0);
  if (!quantities || quantities.length === 0) {
    this.setState({
      toastMessage: 'Please enter a valid quantity',
      toastError: true
    });
    return;
  }

  const { carts } = this.props;
  let itemList = products.map((product, index) => {
    const qty = quantities[index];
    const storeRdcNum = this.props.multiStoreDetails?.childStoreDetails !== {} ?
      this.props.multiStoreDetails.childStoreDetails[this.props.storeId] : '';
    return {
      ItemNumber: product.Item_Number,
      ItemQty: qty,
      OverrideRetail: overrideRetails[index],
      RDC: this.props.rdc,
      ItemSource: 'WH',
      TotalQtyInOrder: qty,
      userName: this.props.userName,
      primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : ''
    };
  });

  const existingCart = carts.find(
    cart => cart.TypeId === orderNames[IMPULSE_MERCHANDISING].infoType
  );
  if (!existingCart) {
    this.props.createNAddMany(
      costs,
      itemList,
      orderNames[IMPULSE_MERCHANDISING].infoType
    );
  } else {
    this.props.chooseCart(existingCart);
    itemList = itemList.map(item => {
      return { ...item, CartId: existingCart.cartId };
    });
    this.props.addManyProducts(existingCart.cartId, costs, itemList);
  }
};

export const addRetailAssortmentToCart = function(
        assortmentNbr, 
        qty,
        ordertype,
        rdc,
        userName,
        userId,
        storeId,
        RAcost,
        ctbAssortment,
        serialNumber,
        missing) {

    if ((typeof(qty) === 'number' || typeof(qty) === 'string') && !utilities.isValidQuantity(qty) || !qty || qty.length === 0 ) {
      utilities.showToast('Please enter a valid quantity',true)
      return;
    }
  else{
    const existingCart = this.props.carts.find(
      cart => cart.TypeId === orderNames['RETAIL_ASSORTMENT'].infoType);

    this.props.addAssortmentProduct(
      assortmentNbr,
      qty,
      ordertype,
      rdc,
      userName,
      userId,
      storeId,
      RAcost,
      ctbAssortment,
      serialNumber,
      missing
    );
  }

};

export const addReunionOfferingToCart = function(
  itemNumber,
  index,
  relay_nbr,
  Vendor_Id,
  packType,
  packQty,
  promoNbr
) {
  const quantity = this.state.quantities[index];
  const or = this.state.overrideRetails[index];
  const existingCart = this.props.carts.find(
    cart => cart.TypeId === orderNames['REUNION'].infoType
  );
  if (
    !utilities.isValidQuantity(quantity[0]) &&
    !utilities.isValidQuantity(quantity[1]) &&
    !utilities.isValidQuantity(quantity[2]) &&
    !utilities.isValidQuantity(quantity[3])
  ) {
    this.setState({
      toastMessage: 'Enter atleast one valid quantity',
      toastError: true
    });
    return;
  }
  let isValid = true;
  quantity.every(qty => {
    if (
      utilities.isValidQuantity(qty) &&
      !this.validateProductQuantity(packType, packQty, qty)
    ) {
      isValid = false;
      return false;
    }
    return true;
  });
  if (!isValid) {
    return;
  }
  if (!existingCart) {
    this.props.createNAddCart(
      itemNumber,
      quantity[0],
      or ? or : '',
      '',
      'REUNION',
      orderNames['REUNION'].infoType,
      'Events',
      this.props.rdc,
      quantity[1],
      quantity[2],
      quantity[3],
      relay_nbr,
      Vendor_Id,
      promoNbr
    );
  } else {
    this.props.chooseCart(existingCart);
    this.props.addReunionCartProduct({
      CartId: parseInt(existingCart.cartId),
      ItemNumber: itemNumber,
      ItemQty: quantity[0],
      ItemQty2: quantity[1],
      ItemQty3: quantity[2],
      ItemQty4: quantity[3],
      ItemSource: promoNbr,
      Vendor_Id,
      relay_nbr,
      OverrideRetail: or,
      RDC: this.props.rdc
    });
  }
};


export const addMonthlySpecialToCartpdp = function(
  itemNumber,
  RelayNbr,
  promoNbr,
  vendorID,
  packType,
  packQty,
  quantitys,
  overrideRetails
) {
  const or = overrideRetails;
  if (
    (quantitys === ''|| quantitys === 0)
  ) {
      utilities.showToast(`Please Enter a Valid Quantity`,false);
      return true;
  }
  this.props.addMonthlySpecialCart({
    RelayNbr: RelayNbr.toString(),
    userID: this.props.userId.toString(),
    promoNbr: promoNbr.toString(),
    itemNum: itemNumber.toString(),
    userQty: quantitys.toString(),
    vendorID: vendorID.toString(),
    rdcNbr: this.props.rdc.toString(),
    overrideRetail: utilities.undefindForNull(or),
    molUserName: store.getState().SessionReducer.userName,
    cartID: this.props.currentCartId
  });
};

export const addMonthlySpecialToCart = function(
  itemNumber,
  index,
  RelayNbr,
  promoNbr,
  vendorID,
  packType,
  packQty,
  storeId,
  newCart,
  maxQty = 0
) {
  const quantity = this.state.quantities[index];
  const or = this.state.overrideRetails[index];
  if (
    !utilities.isValidQuantity(quantity[0]) &&
    !utilities.isValidQuantity(quantity[1]) &&
    !utilities.isValidQuantity(quantity[2]) &&
    !utilities.isValidQuantity(quantity[3])
  ) {
    utilities.showToast('Enter atleast one valid quantity',true)
    return;
  }
  let isValid = true;
  let totalQty = quantity.reduce((a,b) => parseInt(a)+parseInt(b))
  if(!isMaxQtyValid(totalQty,maxQty,false)) {
    return
  }
  quantity.every(qty => {
    if (
      utilities.isValidQuantity(qty) &&
      !this.validateProductQuantity(packType, packQty, qty)
    ) {
      isValid = false;
      return false;
    }
    return true;
  });
  if (!isValid) {
    return;
  }
  if (newCart) {
    this.props.updateQty(true)
    utilities.showToast('Cart created successfully');
  }
  this.props.addMonthlySpecialCart({
    userID: this.props.userId.toString(),
    itemNum: itemNumber.toString(),
    userQty: quantity[0].toString(),
    userQty2: quantity[1].toString(),
    userQty3: quantity[2].toString(),
    userQty4: quantity[3].toString(),
    vendorID: vendorID.toString(),
    RelayNbr: RelayNbr.toString(),
    promoNbr: promoNbr.toString(),
    overrideRetail: utilities.undefindForNull(or),
    rdcNbr: this.props.rdc.toString(),
    molUserName: this.props.userName.toString(),
    cartID: this.props.currentCartId
  });
};

export const hideToastMessage = function() {
  setTimeout(() => {
    this.setState({ toastMessage: '' });
  }, 5000);
};

export const closeModal = function() {
  this.setState({
    visible: false,
    click: 'Notclicked'
  });
  if (this.props.blurfun) {
    this.props.blurfun(false);
  }
};

export const addimg = function(data, num) {
  this.setState({
    quickviewdata: data,
    item: num
  });
};

export const delimg = function(data) {
  this.setState({
    quickviewdata: data
  });
};
