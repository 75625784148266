import auth0 from "auth0-js";
import history from "./history";
import jwtDecode from "jwt-decode";
import jwt from "jsonwebtoken";
import { getRedirectUri, getRedirectUriMOL, getDomain } from "./urlConstants";
import store from "./configureStore";
import { logout } from "./views/Login/actions";
import _ from "lodash";
import utilities from "./utils/utilities";

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: getDomain(),
    clientID: "NewNetWarehouse",
    redirectUri: getRedirectUri(),
    responseType: "id_token token",
    scope: "openid nwidentity nwresource",
  });

  login = () => {
    this.clearStorage();
    let prevUrl =
      window.location.pathname + window.location.hash + window.location.search;
    localStorage.setItem("prevUrl", prevUrl);
    localStorage.setItem("isAuthInvoked", true);
    this.auth0.authorize();
  };

  clearStorage = () => {
    localStorage.removeItem("id_token", "access_token", "expires_at");
    sessionStorage.clear();
    utilities.cookieClear();
  };

  logout = () => {
    this.clearStorage();
    window.location.assign(getRedirectUriMOL());
  };

  isAccessTokenValid = false;
  parseHash = (hash) => {
    try {
      this.clearStorage();
      let id_token_index = hash.indexOf("#id_token=");
      let access_token_index = hash.indexOf("&access_token=");
      let token_type_index = hash.indexOf("&token_type=");
      let expires_in_index = hash.indexOf("&expires_in=");
      let scope_index = hash.indexOf("&scope=");
      let id_token =
        id_token_index > -1
          ? hash.substring(id_token_index + 10, access_token_index)
          : "";
      localStorage.setItem("id_token", id_token);
      let access_token =
        access_token_index > -1
          ? hash.substring(access_token_index + 14, token_type_index)
          : "";
      localStorage.setItem("access_token", access_token);
      let expires_in =
        expires_in_index > -1
          ? hash.substring(expires_in_index + 12, scope_index)
          : "";
      id_token = jwtDecode(id_token);
      access_token = jwtDecode(access_token);
      let expires_at = JSON.stringify(expires_in * 1000 + new Date().getTime());
      localStorage.setItem("expires_at", expires_at);
      return {
        id_token,
        access_token,
        expires_at,
      };
    } catch (err) {
      console.log("invalid token");
      this.isAccessTokenValid = false;
      throw new Error("Not Authenticated");
    }
  };

  validateToken = (secretKey) => {
    const access_token = localStorage.getItem("access_token");
    let publicKey = `-----BEGIN CERTIFICATE-----${secretKey}-----END CERTIFICATE-----`;
    jwt.verify(
      access_token,
      publicKey,
      { algorithm: "RS256", ignoreNotBefore: true },
      (err, decoded) => {
        if (err) {
          console.log(err);
          store.dispatch(logout());
          this.clearStorage();
        }
      }
    );
  };

  // parses the result after authentication from URL hash
  handleAuthentication = (hash) => {
    if (hash === "#identity") {
      localStorage.setItem("isAuthInvoked", true);
      this.auth0.authorize();
    }
    return this.parseHash(hash);
  };

  removeHash = () => {
    var scrollV,
      scrollH,
      loc = window.location;
    if ("pushState" in history)
      history.pushState("", document.title, loc.pathname + loc.search);
    else {
      // Prevent scrolling by storing the page's current scroll offset
      scrollV = document.body.scrollTop;
      scrollH = document.body.scrollLeft;

      loc.hash = "";
      // Restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  };

  // checks if the user is authenticated
  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = localStorage.getItem("expires_at");
    const now = new Date().getTime();
    if (expiresAt && +expiresAt - now > 0) {
      this.isAccessTokenValid = true;
    } else {
      this.isAccessTokenValid = false;
    }
    return this.isAccessTokenValid;
  };
}
