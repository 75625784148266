import React, { Component } from 'react';
import { Icon, Grid, Loader, Header } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import utilities from '../../../utils/utilities';

class ReunionVendorDetailsPopup extends Component {
  render() {
    const { vendorInfo, attributeValue } = this.props;
    const vendorDetails = vendorInfo

    const {
      dspVendorCity = '',
      dspVendorSt = '',
      DspProvince = '',
      vendorAddress1 = '',
      VendorAddress2 = '',
      vendorCity = '',
      saName = '',
      saAddress1 = '',
      SaAddress2='',
      saEmailAddr = '',
      saCity = '',
      saST = '',
      saZIP = '',
      vendoMinCodeDesc = '',
      saFirstName = '',
      saLastName = '',
      sa800Phone = '',
      saPRPhone = '',
      saFaxPhone = '',
      vendorZip = '',
      vendorSt = '',
      scAddress1 = '',
      scAddress2 = '',
      scST = '',
      ScCity='',
      scFirstName = '',
      scLastName = '',
      scFaxPhone = '',
      sc800Phone='',
      scPRPhone = '',
      scZIP = '',
      scName = '',
      vendorConFirstName = '',
      vendorConLastName = '',
      vendorConPRPhone = '',
      vendorConFaxPhone = '',
      vendorConEmailAddr = '',
      vendorCon800Phone = '',
      vendorMinOrder = 0,
      vendorMinComment = '',
      additionalInfo = "",
      vendorPPDTermsValue = 0,
      VendorpFreightCodeDesc='',
      VendorComment=''
    } = vendorDetails || {};

    return (
      <div className="wordBreak">
        {!vendorDetails ? (
          <>
            <Header as='h4'>{attributeValue}</Header>
            <Loader active />
          </>
        ) : (
            <div ref={el => (this.componentRef = el)} className='print-component'>
              <Header as='h4'>
                {utilities.replaceAll(attributeValue, '%2F', '/')}
                <ReactToPrint
                  trigger={() => (
                    <i aria-hidden='true' className='print big icon'></i>
                  )}
                  content={() => this.componentRef}
                />
              </Header>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column>
                    <p className="vendor-Headuline">Store</p>
                    <p>
                      {vendorAddress1} <br></br>{VendorAddress2 ?<div>{VendorAddress2}</div> : ''}
                      {vendorCity}, {vendorSt} {vendorZip}
                    </p>
                  </Grid.Column>
                  {saAddress1 && <Grid.Column>
                    {saAddress1 && <p className="vendor-Headuline">Sales Agent:</p>}
                    {saName && <>{saName}<br></br></>}
                    {saAddress1 && <> {saAddress1}<br></br></>}{SaAddress2 ?<div>{SaAddress2}</div> : ''}
                    {saCity && <>{saCity},</>}{saST && <>{saST},<br></br></>}
                    {saZIP}

                  </Grid.Column>}
                  {vendorConFirstName && <><Grid.Column>
                    <p className="vendor-Head">{`${vendorConFirstName || ''} ${vendorConLastName || ''}`}</p>
                    <p>
                      { vendorCon800Phone && <><Icon name='phone' /> {vendorCon800Phone}</>}
                    </p>
                    <p>
                      { vendorConPRPhone  && <><Icon name='phone' /> {vendorConPRPhone}</>}
                    </p>
                    <p>
                      {vendorConFaxPhone && <><Icon name='fax' /> {vendorConFaxPhone}</>}
                    </p>
                    <p>
                      {vendorConEmailAddr && <><Icon name='mail' /> {vendorConEmailAddr}</>}
                    </p>
                  </Grid.Column></>}
                  {saFirstName && <Grid.Column>
                    <p className="vendor-Head">{`${saFirstName || ''} ${saLastName || ''}`}</p>
                    <p>
                      {sa800Phone &&
                        <><Icon name='phone' /> {sa800Phone}</>
                      }
                    </p>
                    <p>
                      {saPRPhone &&
                        <><Icon name='phone' /> {saPRPhone}</>
                      }
                    </p>
                    <p>
                      {saFaxPhone &&
                        <>
                          <Icon name='fax' /> {saFaxPhone}
                        </>
                      }
                    </p>
                    <p>
                      {saEmailAddr && <><Icon name='mail' /> {saEmailAddr}</>}
                    </p>
                ` </Grid.Column>}
                  {vendorConFirstName && <Grid.Column>
                      <p>
                        <span className="vendor-Headuline">DS FOB</span>: {dspVendorCity},{dspVendorSt && <> {dspVendorSt}</>}{DspProvince && <> {DspProvince}</>}
                      </p>
                      <p><span className="vendor-Headuline">Frt. Ppd. On:</span> <br /> {vendorPPDTermsValue}&nbsp;{VendorpFreightCodeDesc}<br />{VendorComment}</p>
                      <p><span className="vendor-Headuline">Min Order:</span> <br /> {vendorMinOrder}&nbsp;{vendoMinCodeDesc}<br />{vendorMinComment}</p>
                    </Grid.Column>}                 
                  {scAddress1 && <Grid.Column>
                    {scAddress1 && <p className="vendor-Headuline">Service Center:</p>}
                    <p>
                      {scName && <>{scName}<br></br></>}
                      {scAddress1 && <>{scAddress1}<br></br></>}{scAddress2 ? <div>{scAddress2}</div> : ''}
                      {ScCity && <>{ScCity},</>}{scST && <> {scST}, </>}
                      {scZIP}  
                    </p>
                  </Grid.Column>}
                  {scFirstName && <Grid.Column>
                    <p className="vendor-Head">{`${scFirstName || ''} ${scLastName || ''}`}</p>
                    <p>
                      {
                        sc800Phone &&
                        <><Icon name='phone' /> {sc800Phone}</>}
                    </p>
                    <p>
                      {
                        scPRPhone &&
                        <><Icon name='phone' /> {scPRPhone}</>}
                    </p>
                    <p>
                      {scFaxPhone &&
                        <><Icon name='fax' /> {scFaxPhone}</>
                      }
                    </p>
                  </Grid.Column>}
                </Grid.Row>
              </Grid>
              <p className="vendor-Headuline">Additional Info: </p>
              {additionalInfo}
            </div>
          )}
      </div>
    );
  }
}

export default ReunionVendorDetailsPopup;
