import React, { Component } from 'react';
import utilities from '../../../utils/utilities';

class SpecTable  extends React.Component{
    constructor(props){
        super(props);
        }

        render() {
            const products=this.props.product;
            const multiStoreDetails=this.props.multiStoreDetails;
            const viewType = this.props.viewType;
    return (
        <div className='spec_table'>
        <table className='stackable' id={viewType==='list'?'dc_desc_table_list':'dc_desc_table'}>
            <thead className='AddressTableHead'>
                <tr>
                    <th>Item SKU</th>
                    {multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) &&
                    <>
                      <th>Alternate SKU#</th>  
                    </>
                    }
                    <th>Model #</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Market Cost</th>
                    <th>Member Cost</th>
                    <th>Suggested Retail</th>
                    <th>Non Stocked</th>
                </tr>
            </thead>
            <tbody>
            {products &&
                        products.map((product, index) => {
                            return(
                        <DetailTable
                            itemsku={product.Item_Number}
                            multiStoreDetails={multiStoreDetails}
                            aliassku={product.aliassku}
                            model={product.Model}
                            description={product.shortDesc}
                            quantity={product.userSLQty}
                            marketcost={product.pricing.ME_Cost}
                            memebercost={product.pricing.MC_Cost}
                            SuggestedRetail={product.spc_cost}
                            nonstocket={product.stocked}
                        />
                            );})}
                        
            </tbody>
        </table>
        </div>
    );
};
}

export default (SpecTable);

export const DetailTable = props => {
    const {
      itemsku,
      model,
      description,
      quantity,
      marketcost,
      memebercost,
      SuggestedRetail,
      nonstocket,
      aliasSku,
      multiStoreDetails
    } = props;
  
    return (
      <tr>
        <td >
          {itemsku}
        </td>
        {multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID) &&
          <>
            <td >
            {aliasSku}
            </td>
          </>
        }
        <td >
            {model}
        </td>
        <td>
            {description}
        </td>
        <td>
            {quantity}
        </td>
        <td>
            {marketcost}
        </td>
        <td>
            {memebercost}
        </td>
        <td>
            {SuggestedRetail}
        </td>
        <td>
            {nonstocket}
        </td>
      </tr>
    );
  };
