import React from 'react';
import { GET_CTB_ADMIN_POG_ENABLE } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { getTextNameField, getButton, getTableRow, getTable } from "../common/ComponentUtilities";
import "../Orders/PromoShip/styles.css";
import { Loader } from "semantic-ui-react";
import Bin from '../../images/bin.svg';
import renderImages from '../common/RenderImages';
import "./admin.css";
import _ from 'lodash'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import utilities from '../../utils/utilities';

class POGEnable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pogNumber: "",
      storeNumber: this.props.storeId,
      pogStatusDetails: [],
      loading: false,
      fetachStatus: true,
      finalStatus: false,
      finalMessage: ""
    };
    this.fetchPogStatus = this.fetchPogStatus.bind(this);
    this.confirmPOGEnable = this.confirmPOGEnable.bind(this);
  }
  pogNumberOnChange = (e) => {
    this.setState({ pogNumber: e.target.value })
  }

  fetchPogStatus = () => {
    if (this.state.pogNumber !== "" && this.state.storeNumber !== "") {
      const url = `${GET_CTB_ADMIN_POG_ENABLE}`;
      let requestPayLoad = {
        "memNbr": this.state.storeNumber,
        "PogNbr": this.state.pogNumber,
        "isCtbRestrict": "true",
        "flag": "F"
      }
      restUtils.postData(url, requestPayLoad)
        .then(response => {
          // Get Fetch POG Status 
          let statusDetails = [];
          statusDetails.push(response.data);
          this.setState({ pogStatusDetails: statusDetails, loading: false, fetachStatus: false, finalStatus: false });
        })
        .catch(error => { console.log(error); this.setState({ loading: false, fetachStatus: true, finalStatus: false }) })
    }
    else {
      utilities.showToast('Please Enter the POG Number.');
    }
  }
  confirmPOGEnable = () => {
    const url = `${GET_CTB_ADMIN_POG_ENABLE}`;
    let requestPayLoad = {
      "memNbr": this.state.storeNumber,
      "PogNbr": this.state.pogNumber,
      "isCtbRestrict": "true",
      "flag": "U"
    }
    restUtils.postData(url, requestPayLoad)
      .then(response => {
        if (response.data.Message == "Success") {
          this.setState({ finalMessage: `POG Number (${response.data.PogNbr}) is enabled Succesfully`, finalStatus: true });
        } else {
          this.setState({ finalMessage: "POG Number is not Enable, Please check with Admin Team", finalStatus: true });
        }
      }).catch(error => { console.log(error); this.setState({ fetachStatus: true }) })
  }

  fetchStatusData = () => {
    return (
      <div className='upload-link'>
        <div class="displayFlex page-header mb-2">CTB Reopen for Single Store</div>
        <div className='displayFlex'>
          <div style={{ "marginLeft": "30px" }}>
            {getTextNameField("Enter POG Number", "pogNumberID", this.state.pogNumber, this.pogNumberOnChange, 'pogLabelName')}
          </div>
        </div>
        <div className='displayFlex pt-3'>
          <div className='pl-15'>
            {getButton({ type: 'button', className: 'fluid ui red button submit', name: 'fetchStatus', onClick: this.fetchPogStatus }, 'Fetch POG Status')}
          </div>
        </div>
      </div>
    )
  }

  getPogData = () => {
    return (
      <>
        {
          this.state.pogStatusDetails.length > 0 && (this.state.pogStatusDetails[0]?.PogNbr != "" && this.state.pogStatusDetails[0]?.PogNbr != undefined) ?
            <div className='upload-link'>
              <div className='overFlow'>
                <table className="ui celled table all-wh-table shoppingArea-table" id="font-size-orders">
                  <thead>
                    <th className="allOdrHeader">POG Number</th>
                    <th className="allOdrHeader">POG Status</th>
                  </thead>
                  <tbody>
                    {
                      this.state.pogStatusDetails.map((el) => {
                        return <tr className="tableRow">
                          <td class="t-center">{el.PogNbr}</td>
                          <td class="t-center">{el.Message}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className='displayFlex pt-3 mb-3'>
                <div className='pl-15'>
                  {getButton({ type: 'button', className: 'fluid ui red button submit', name: 'cfmPogEnable', onClick: this.confirmPOGEnable }, 'Confirm to Re-Open POG')}
                </div>
              </div>
            </div> :
            <div className='upload-link page-header cost_red_color '><p style={{ "marginTop": "30px", "textAlign": "center" }}>{utilities.showToast(this.state.pogStatusDetails[0]?.Message ? this.state.pogStatusDetails[0]?.Message : "Please Enter the Valid POG Number")}</p></div>
        }
      </>
    )
  }

  render() {
    const { fetachStatus, finalStatus, finalMessage } = this.state;
    return (
      <>
        <div>{this.fetchStatusData()}</div>
        {
          !finalStatus && !fetachStatus ? this.getPogData() :
            <div className='upload-link page-header cost_red_color '><p style={{ "marginTop": "30px", "textAlign": "center" }}>{finalMessage}</p></div>
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    storeId: state.SessionReducer.storeId,
    userName: state.SessionReducer.userName
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({}, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(POGEnable);