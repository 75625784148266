import { combineReducers } from 'redux';
import * as types from './actions';
import { MEMBER_VIEW } from '../NavBar/menuConstants';

const userMenu = (state = { viewType: MEMBER_VIEW }, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SWITCH_VIEW:
      return { viewType: payload };
    default:
      return state;
  }
};
const sideMenu = (state = false, action) => {
  switch (action.type) {
    case types.MENU_OPEN:
      return true;
    case types.MENU_CLOSE:
      return false;
    default:
      return state;
  }
};

const search = (state = false, action) => {
  switch (action.type) {
    case types.SEARCH_OPEN:
      return true;
    case types.SEARCH_CLOSE:
      return false;
    default:
      return state;
  }
};

const searchFocus = (state = false, action)=>{
  switch(action.type){
      case types.FOCUS_CHANGE:
      return !state;
    default:
      return state;
  }
}
const wpMenu = (state = [], action)=>{
  switch(action.type){
      case types.SETWPMENU:
        return {
          ...state, ... action.payload
        }
    default:
      return state;
  }
}

const businessToolsMenu = (state=[], action)=>{
  switch(action.type){
    case types.SET_BT_MENU:
      return {
        ...state, ... action.payload
      }
  default:
    return state;
}
}

export const isMenuVisible = state => state.sideMenu;
export const isSearchVisible = state => state.search;

export default combineReducers({ userMenu, sideMenu, search, searchFocus,wpMenu,businessToolsMenu});
