import React, { Component } from 'react';
import './styles.css';
import { restUtils } from '../../../utils/restUtils';
import { RETAILASSORTMENT } from '../../../urlConstants';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import InternalServerError from '../../ErrorPages/500_error_page';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import history from '../../../history';
import {connect} from 'react-redux';

class ReunionAssortmentDeptPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      departmentDetails: [],
      loading: false
    }
    this.pathname = '';
  }

  componentDidMount() {
    this.pathname = this.props.location && this.props.location.pathname;
    this.getReunionAssortmentDepartments();
  }

  getReunionAssortmentDepartments = (newProps) => {
    this.setState({ loading: true });
    const url = `${RETAILASSORTMENT}`;
    restUtils
      .getData(url + `/flyout`)
      .then(response => {
        this.setState({
          departmentDetails: response.data,
          loading: false,
          serverError: false
        })        
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  };

  showDeptDetails = (e) => {
    const deptId = e.target.id;
    history.push({
      pathname:'/reunionAssortments/'+deptId,
      state:{
        path_from_dept: this.pathname
      }
    })
  }

  render(){
    const gridClassName = window.innerWidth < 1024 
                            ? 'ui stackable two column grid'
                            : window.innerWidth<1240
                              ? 'ui stackable three column grid' 
                              : 'ui stackable five column grid';
    const breadcrumbPath = [{ link: '/home', text: 'HOME' },
        { link: this.pathname, text: 'Reunion Retail Assortments' } ];
      return(
          this.state.serverError ?
            <InternalServerError/>
          :
          <div className="page-wrap">
            {this.state.loading && (
              <div>
                <Loader active />
              </div>
            )}
            <div className='page-header'> Reunion Retail Assortments</div>
            <BreadcrumbComp path={breadcrumbPath}/><br/><br/>
            <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
              <div className='page_details_dept'>
                <span>Event ID: #</span>
                <span className="padding_left">Order Deadline: &nbsp; {this.props.RaDate}</span>
                <div className={gridClassName} id="reunion_items">
                  {this.state.departmentDetails && this.state.departmentDetails.map(event =>{
                    const Department_Name = (event.departmentName !== null &&
                                              event.departmentName !== '') ? 
                                                event.departmentName.trim()
                                                    :'' ;
                    return(
                      <div className='column'>
                        <div className="reunion-dept-card" >
                          <div className="reunion-dept_name"
                            id = {event.departmentId}
                            data-dept-name = {Department_Name}>
                            {Department_Name}
                          </div>
                          <span onClick={this.showDeptDetails} 
                                id = {event.departmentName} 
                                className='view_assortment'>View Assortments</span> 
                        </div>
                      </div>
                    )
                   })
                  }
                </div> 
              </div>
            </div> 
          </div>    
      )
  }
}

const mapStateToProps = state => {
  const { cart } = state
  return {
    RaDate: cart?.orders?.eventDate
  };
};

export default connect(mapStateToProps, null)(ReunionAssortmentDeptPage);