import React from 'react';
import restUtils from '../../../utils/restUtils';
import { Loader, Responsive, Pagination } from 'semantic-ui-react';
import { connect } from 'react-redux';
import history from '../../../history';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import "../../AllOrders/AllOrders.css"
import _ from 'lodash';
import utilities from '../../../utils/utilities';
import GenericExcelExport from '../../common/GenericExcelExport';
import SAM_Icon from '../../AllOrders/SAM_Icon.gif'
import renderImages from '../../common/RenderImages';
import { Link } from 'react-router-dom';
import { MaskPrice } from '../../common/MaskPrice';
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';
import search_img from '../../images/Search.svg';
import { EDIT_ORDERS } from '../../../urlConstants';
import InternalServerError from '../../ErrorPages/500_error_page';
import Filter from '../../common/Filter';
import { colDefs } from './EditOrderModel';
import ReactToPrint from 'react-to-print';

class EditOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editableOrders: [],
      filters: [],
      loading: false,
      serverError: false,
      clearFilters: false,
      appliedFilters: {},
      showFilters:(this.props.getprefer && this.props.getprefer.filterView) 
                    ? this.props.getprefer.filterView === 'E' ? true : false : window.innerWidth > 768 ? true :false,
      isNoProducts: false,
      noOfOrders: 0,
      totalItems: 0,
      firstItem: 0,
      lastItem: 0,
      currentPage: 1,
      totalPages: 0,
      totalItemsToShow: 50,
      resultPerPage: 0,
      pagination : [],
      needHelpNumber: this.props?.needHelpNum
    }
    this.pageSize = 50;
    this.page = 1;
    this.filterString = {};
    this.searchParam = 'itemNbr';
    this.searchNumber = '';
    this.sortParam = 'OrderCreatedDate';
    this.sortDirection = 'DESC';
    this.pathname = '';
    this.scrollPosition = 0;
  }

  componentDidMount() {
    this.page = 1;
    this.pageSize =
      this.props.getprefer && this.props.getprefer.resultPerPage
        ? this.props.getprefer.resultPerPage
        : '50';    
    this.setState({
      totalItemsToShow:this.pageSize
    });
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.getOrders();
    }
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps?.needHelpNum){
      this.setState({
        needHelpNumber: nextProps?.needHelpNum
        })
    }
    }
  changeSearchParam = e => {
    this.scrollPosition = window.pageYOffset;
    this.searchParam = e.currentTarget.value;
  };

  searchItems = () => {
    this.scrollPosition = window.pageYOffset;
    this.getOrders();
  };

  handleSearchChange=(e)=>{
    this.scrollPosition = window.pageYOffset;
    this.searchNumber = e.target.value;
  }
  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  changeSortParam = e => {
    window.scrollTo(0, 0);
    this.scrollPosition = window.pageYOffset;
    this.page =1;
    this.sortParam = e.currentTarget.value;
    this.getOrders();
  };

  changeSortDirection = e => {
    window.scrollTo(0, 0);
    this.scrollPosition = window.pageYOffset;
    this.page =1;
    this.sortDirection = e.currentTarget.value;
    this.getOrders();
  };

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.page = getParams[0].split('=')[1];
    this.pageSize =  getParams[1].split('=')[1];
    let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
    if(searchParam !== ''){
      let search = searchParam.split('=');
      this.searchParam = search[0];
      this.searchNumber = search[1].split('&')[0];
    }
    document.getElementById('search_order_status').value = this.searchNumber;
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=ASC') ? 'ASC' : 
                          queryParam[1].includes('SortType=DESC') ? 'DESC' : '';    
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    document.getElementById('order_sts_dropdown').value = this.searchParam;
    let appliedFilters = utilities.getFilterArrayDecode(this.filterString);
    let filterApplied = utilities.getFilterArray(this.filterString);
    this.filterString = utilities.getQueryJson(filterApplied);
    this.setState({
      appliedFilters : appliedFilters,
      totalItemsToShow : this.pageSize
    },() => {
      this.getOrders();
    });
  }

  setScrollPosition = (order) => {
    this.scrollPosition = window.pageYOffset;
    const pushUrl = this.setBackButtonPath();
    history.push({
      pathname: `/EditOrderItemLevelDetails/${order.orderNum}`,
      state: { orderDetails: order, pushUrl: pushUrl}
    })
  }

  setBackButtonPath = () => {
    let pathName = ''; 
    pathName = utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page='+this.page;
    pathName = pathName + '&ResultsPerPage='+this.pageSize;
    if (this.searchNumber) {
      pathName = pathName + '&searchBy='+this.searchParam+'='+this.searchNumber;
    }
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam='+this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType='+this.sortDirection;
      } else {
        pathName = pathName + '&SortType=ASC';
      }
    }    
    pathName = pathName + '&scrollPosition='+this.scrollPosition;
    if (!_.isEmpty(this.filterString)) {
      let obj = utilities.getQueryStrFromJson(this.filterString, 'editOrders');
      pathName = pathName + '&filterString='+ utilities.getQueryStrForFilterString(obj);
    }    
    let pushUrl = this.pathname +'?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
    return pushUrl
  }

    getOrders = () =>{
      let queryString = '';
      if(this.filterString.filterBy && this.filterString.filterBy.length > 0){
        this.filterString.filterBy.map((filter, index) => {
          if(filter.criterionName === "RDC"){ 
            queryString = queryString + '&filterByRDC='+filter.criterionValue;
          }
          if(filter.criterionName === "Order Type"){
            queryString = queryString + '&filterByOrderType='+filter.criterionValue;
          }
          if(filter.criterionName === "Status"){
            queryString =  queryString + '&filterByStatus='+filter.criterionValue;
          }

          return filter;
        });
      }
      this.setState({ loading: true });
        restUtils.getData(EDIT_ORDERS+'?storeId='+this.props.storeId+'&rdcnum='+this.props.rdc+'&searchBy='+this.searchParam+'&searchValue='+this.searchNumber+'&sortParam='
        +this.sortParam+'&sortOrder='+this.sortDirection+'&page='+this.page+'&resultPerPage='+this.pageSize+queryString)
            .then(response=>{
                const {data} = response;
                this.setState({editableOrders:data.editOrdersList,
                    filters:response.data.filters,
                    pagination:response.data.pagination,
                    lastItem : response.data.pagination[0].lastItem,
                    totalPages : parseInt(response.data.pagination[0].totalPages),
                    totalItems : response.data.pagination[0].totalItems,
                    firstItem : response.data.pagination[0].firstItem,
                    currentPage : parseInt(response.data.pagination[0].currentPage),
                    noOfOrders:data?data.editOrdersList.length:0,
                    loading:false
                })
                if (!this.state.showFilters) {
                  this.setState({
                    showFilters: true
                  }, () => {
                    for (let key in this.state.appliedFilters) {
                      document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
                    }
                    this.setState({
                      showFilters: false
                    });
                  });
                } else {
                  for (let key in this.state.appliedFilters) {
                    document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
                  }
                }
                window.scrollTo(0,this.scrollPosition);
                this.setBackButtonPath();
            })
    }

  onShowQuantityChange = e => {
    this.scrollPosition = window.pageYOffset;
    this.pageSize = e.target.value;
    this.setState({
      totalItemsToShow:this.pageSize
    });
    this.page = 1;
    this.getOrders();
  };

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  
  onPageChange = (e, { activePage }) => {
    this.scrollPosition = window.pageYOffset;
    this.setState({
      currentPage: activePage
    });

    let queryPage = e.currentTarget.text;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? this.state.currentPage + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.page = queryPage;
    window.scrollTo(0, 0);
    this.getOrders();
  }

  filterCallback = (isAdd, filterKey, filterValue) => {
    this.scrollPosition = window.pageYOffset;
    this.page = 1;
    let appliedFilters = this.state.appliedFilters;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
    } else if (keyExists) {
      let value = appliedFilters[filterKey].split(',');
      let index = value.indexOf(filterValue);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        value.push(filterValue);
      }
      if (!value.length && keyExists) delete appliedFilters[filterKey];
      else appliedFilters[filterKey] = value.join(',');
    }
    this.filterString = utilities.getQueryJson(appliedFilters);
    this.setState({
      appliedFilters,
      clearFilters: false
    });
    this.getOrders();
  };

  render() {
    const { noOfOrders, editableOrders, totalItemsToShow, totalItems } = this.state;
    const EDITORDER_URI = EDIT_ORDERS+'?storeId='+this.props.storeId+'&rdcnum='+this.props.rdc+'&searchBy='+this.searchParam+'&searchValue='+this.searchNumber+'&sortParam='
        +this.sortParam+'&sortOrder='+this.sortDirection+'&page='+this.page+'&resultPerPage='+totalItems;
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
      const rdcList = this.props.migratedRdcList ?? {};
	     const hasKeys = Object.keys(rdcList).length > 0 ;
       const entries = Object.entries(rdcList).map(([key, value], index) => ( 
        <span key={key}>
        {index === Object.entries(rdcList).length - 1 ? `and ${value} (DC${key})` : index === 0 ? `${value} (DC${key})` : `, ${value} (DC${key})`}
        </span>
        
      ));      
    return (
      
      this.state.serverError ? <InternalServerError /> :
      <div className='page-wrap fix-fotter'  ref={node => (this.componentRef = node)}>
        <div className = "editOrderHeaderTop">
        <span className='page-header'>Edit Orders</span>
       { hasKeys &&  <p className='editOrderOms'>
        
       To modify warehouse  orders placed for <span className='rdcNameSpan'>{entries}</span><span>, Please contact Customer Care for assistance at { utilities.getAfterFirstHyphen(this.state?.needHelpNumber)}.</span>
       
	      </p>}
        <span id='print_img'>
              <ReactToPrint
                pageStyle='@page{size:landscape;}'
                trigger={() => (
                  <i className='print icon' id='printicon'></i>
                )}
                content={() => this.componentRef}
              /> 
        </span>
        </div>
        {this.props.multiStoreDetails.access === 1 ? (
         <>
          <h4> You don't have the permission to view this page. Please contact the True Value Administrator. </h4>
         </>
        ) : (
          <>
        {this.state.loading &&
          <div>
            <Loader active />
          </div>
        }
        <div className="editOrderHeaderTwo">
        <div className='sortFields' style={{width:'100%'}}>
          <div id='input_search'>
            <span>
              {window.innerWidth < 768 ? 'Search' : 'Order Search'}
            </span>
            <select
              className='sort-criteria combo'
              onChange={this.changeSearchParam}
              id='order_sts_dropdown'>
              <option value='itemNbr'>Item #</option>
              <option value='model'>Model #</option>
              {/* <option value='upcNbr'>UPC #</option> */}
              {/* <option value='InvoiceNo'>Invoice #</option> */}
              <option value='PO'>PO #</option>              
            </select>
            <input
              id='search_order_status'
              onChange = {this.handleSearchChange}
              onKeyDown={this.handleEnterKey}
              type='text'
            />
            {renderImages.getImage({
              src: search_img,
              className: 'search_icon_address cursor_p',
              onClick: this.searchItems
            })}
          </div>

        <div className='sort_by editOrder_sort'>
            <span>
              <span> Show</span>
            </span>
            <select
              value={totalItemsToShow}
              className='select-range'
              onChange={this.onShowQuantityChange}
            >
              <option className='SelectOption' value={50}>
                50
              </option>
              <option className='SelectOption' value={100}>
                100
              </option>
            </select>
          <span className='filter-label'>Sort By</span>
          <select className='sort-criteria combo' onChange={this.changeSortParam} id='pl_dropdown' value={this.sortParam}>
            <option value='OrderCreatedDate'>Order Created Date</option>
            {/* <option value='InvoiceNo'>Invoice #</option> */}
            <option value='PO'>PO#</option>
            <option value='OrderType'>Order Type</option>
            {/* <option value='ExpectedReleaseWeek'>Expected Release Week</option> */}
            <option value='Status'>Status</option>
          </select>
          <select className='sort-order combo' onChange={this.changeSortDirection} value={this.sortDirection}>
            <option value='ASC'>Ascending</option>
            <option value='DESC'>Descending</option>
          </select>
        </div>
        </div>
        </div>
        <br />
        <div className={window.innerWidth > 1024 ? "displayFlex allOrdersTable" : "allOrderstablemobile editOrderMobile"}>
          <div>
          <Filter
            clearFilters={this.state.clearFilters}
            filtersApplied={!_.isEmpty(this.state.appliedFilters)}
            appliedFilters={this.state.appliedFilters}
            toggleShowFilter={this.toggleShowFilter}
            showFilters={this.state.showFilters}
            filters={this.state.filters}
            callback={this.filterCallback}
            isNoProducts={this.state.isNoProducts}
            listType={'editOrders'}
          />
          </div>
          <Responsive minWidth='1025'>
            <div class="eighteen wide column" id='allOrdersTable'>
            <div style={{position:'sticky', top:'11rem', background:'#f2f2f2'}}>
            <div className="order-pagination">
              <span className="prodcuts_count">
                  {this.state.firstItem}-{this.state.lastItem} of{' '}
                  {this.state.totalItems} products
              </span> 
              <span className='paginationAllorders'>      
                {this.state.totalPages > 0 && (
                  <Pagination
                    className='pagination paginationMobile'
                    boundaryRange={0}
                    activePage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.onPageChange}
                    ellipsisItem={null}
                    siblingRange={1}
                    firstItem={this.state.currentPage === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                    lastItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                    prevItem={this.state.currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                    nextItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                  />
                )}
                </span>
                <span className='order_count'>Total {this.state.totalItems} Orders
                </span>
                <span className='filter-label'>
                  <GenericExcelExport
                      url={EDITORDER_URI}
                      fileName='Edit Order'
                      columnDefs={{
                        colDefs: colDefs,
                        responseDataPath: 'data.editOrdersList'
                      }}
                      viewType={this.props.viewType}
                      getPrefer={
                        this.props.getprefer &&
                        this.props.getprefer.customerView
                        ? this.props.getprefer.customerView
                        : 'c1'
                      }
                    />
                </span>
            </div>
            </div>
              <table className="ui celled table all-wh-table stickyTableEditOrder" id='font-size-orders'>
                <thead>
                  <tr>
                    {/* <th className='allOdrHeader' id='no_border' >SAM</th> */}
                    <th className='allOdrHeader' id='no_border'  >Order Created Date</th>
                    {/* <th className='allOdrHeader' id='no_border' >Invoice Date</th> */}
                    {/* <th className='allOdrHeader' id='no_border' >Invoice#</th> */}
                    <th className='allOdrHeader' id='no_border' >PO#</th>
                    <th className='allOdrHeader' id='no_border' >Order Type</th>
                    {/* <th className='allOdrHeader' id='no_border' >Description and Category</th> */}
                    <th className='allOdrHeader' id='no_border' >Item Count</th>
                    <th className='allOdrHeader' id='no_border' >RDC</th>
                    <th className='allOdrHeader' id='no_border' >Ship To</th>
                    {/* <th className='allOdrHeader' id='no_border' >Expected Release Week</th> */}
                    <th className='allOdrHeader' id='no_border' >Shipping Method</th>
                    { this.props.viewType !== MEMBER_VIEW && (getPrefer === 'c3' || getPrefer ==='c4')  ?  "" :
                    <th className='allOdrHeader' id='no_border' >{utilities.getLabelsByType(this.props.viewType,"TOTAL_COST", getPrefer)}</th>
                    }
                    {/* <th className='allOdrHeader' id='no_border' >Cancel Code</th> */}
                    <th className='allOdrHeader' id='no_border' >Status</th>
                  </tr>
                </thead>
                <tbody>
                  {noOfOrders === 0 ? 
                      <tr>
                        <td colSpan='11' className='t-center'>
                          No orders to display!
                        </td>
                      </tr> : 
                    editableOrders.map((order, index) => {
                        return (
                          <>
                            <tr>
                              {/* <td className='t-center' id='field_width_edit'>{index===0 ? renderImages.getImage({ src: SAM_Icon }) : ''}</td> */}
                              <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.dateSubmitted)}</td>
                              {/* <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.invoiceDate)}</td> */}
                              {/* <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.invoiceNbr)}</td> */}
                              <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.memberRefNum)}</td>
                              <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.invoiceType)}</td>
                              {/* <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.desc)}</td> */}
                              <td className='t-center' id='field_width_edit'>{order.numofItems ? order.numofItems : '0'}</td>
                              <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.rdcNum)}</td>
                              <td className='t-center' id='field_width_edit'>{utilities.hyphenForZero(order.consumerName)}</td>
                              {/* <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.shipDate)}</td> */}
                              <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.shippingMethod)}</td>
                              {  this.props.viewType !== MEMBER_VIEW && (getPrefer === 'c3' || getPrefer ==='c4')  ?  "" :
                              <td className='t-center' id='field_width'>
                                {order.subcost ? <MaskPrice
                                  field = 'Member Cost black'
                                  getprefer={getPrefer}
                                  viewType={this.props.viewType}
                                  fieldVal={order.subcost}
                                /> : "-"}
                              </td>}
                              {/* <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.cancelRsnCode)}</td> */}
                              <td className='t-center' id='field_width_edit'>{utilities.hyphenForNull(order.status)}</td>
                            </tr>
                            <tr className='wh-row'>
                              {/* <td colspan='2' className='t-left'><i className='created_by'>{`Created by: ${order.createdBy ? order.createdBy : ''}`}</i></td>
                              <td colspan='12' className='t-left' id='remove_border'>Tracking #: {order.retailerTrackingNumber}</td> */}
                              <td colSpan='12' className='t-right checkoutPointer' id='remove_border'><p onClick={() => {this.setScrollPosition(order)}}>View Details</p></td>
                            </tr>
                          </>
                        )
                      }
                    )
                  }
                </tbody>
              </table>
            </div>
          </Responsive>
          <Responsive maxWidth='1024'>
          <div className="portrait-pagination">
                <div className='OrderTotalitem'>
                  {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.totalItems} products
                </div>
                <div className='paginationAllorders'>                
                {this.state.totalPages > 0 && ( <Pagination
                    className='pagination'
                    boundaryRange={0}
                    activePage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.onPageChange}
                    ellipsisItem={null}
                    siblingRange={1}
                    firstItem={this.state.currentPage === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                    lastItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                    prevItem={this.state.currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                    nextItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                  />)}
                </div>
                <div className='order_count-portarit'>Total {this.state.totalItems} Orders
                </div>
                <span className='filter-label'>
                  <GenericExcelExport
                      url={EDITORDER_URI}
                      fileName='Export'
                      columnDefs={{
                        colDefs: colDefs,
                        responseDataPath: 'data.editOrdersList'
                      }}
                      viewType={this.props.viewType}
                      getPrefer={
                        this.props.getprefer &&
                        this.props.getprefer.customerView
                        ? this.props.getprefer.customerView
                        : 'c1'
                      }
                    />
                  </span>
            </div>
            {this.state.editableOrders.map((order_details, index) => {
               return <OrderCard order={order_details} loggedInUser={this.props.loggedInUser} props={this.props} />
              })
            }
          </Responsive>
        </div>
        </>
        )}
      </div>
    )
  }
}
const OrderCard = ({ order, loggedInUser, props }) => {
  const getPrefer = props.getprefer &&
    props.getprefer.customerView
    ? props.getprefer.customerView
    : 'c1'; 
  return (
    <div className='all-orders-card'>
      <div className='floatRight view-details-link'>
        <Link to={{ pathname: `/EditOrderItemLevelDetails/${order.orderNum}`, state: { orderDetails: order } }}>View Details</Link></div>
      <table id='order-card-table'>
        {/* <tr>
          <td className='order-card-field'>SAM</td>
          <td>{renderImages.getImage({ src: SAM_Icon })}</td>
        </tr> */}
        <tr>
          <td className='order-card-field'>Order Created Date</td>
          <td>{utilities.hyphenForNull(order.dateSubmitted)}</td>
        </tr>
        {/* <tr>
          <td className='order-card-field'>Invoice Date</td>
          <td>{utilities.hyphenForNull(order.invoiceDate)}</td>
        </tr> */}
        {/* <tr>
          <td className='order-card-field'>Invoice #</td>
          <td>{utilities.hyphenForNull(order.invoiceNbr)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field'>PO #</td>
          <td>{utilities.hyphenForNull(order.memberRefNum)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Order Type</td>
          <td>{utilities.hyphenForNull(order.invoiceType)}</td>
        </tr>
        {/* <tr>
          <td className='order-card-field'>Description and Category</td>
          <td>{utilities.hyphenForNull(order.desc)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field'>Item Count</td>
          <td>{order.numofItems ? order.numofItems : '0'}</td>
        </tr>
        <tr>
          <td className='order-card-field'>RDC</td>
          <td>{utilities.hyphenForNull(order.rdcNum)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>Ship To</td>
          <td>{utilities.hyphenForZero(order.consumerName)}</td>
        </tr>
        {/* <tr>
          <td className='order-card-field'>Expected Release Week</td>
          <td>{utilities.hyphenForNull(order.shipDate)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field'>Shipping Method</td>
          <td>{utilities.hyphenForNull(order.shippingMethod)}</td>
        </tr>
        <tr>
          <td className='order-card-field'>{utilities.getLabelsByType(props.viewType,"TOTAL_COST", getPrefer)}</td>
          <td>{order.subCostTotal ? 
            <MaskPrice
              field = 'Member Cost black'
              getprefer={getPrefer}
              viewType={props.viewType}
              fieldVal={order.subcost}
            /> : "-"}</td>
        </tr>
        {/* <tr>
          <td className='order-card-field'>Cancel Code</td>
          <td>{utilities.hyphenForNull(order.cancelRsnCode)}</td>
        </tr> */}
        <tr>
          <td className='order-card-field'>Status</td>
          <td>{utilities.hyphenForNull(order.status)}</td>
        </tr>

        <tr>
          <td className='t-left hide_created_by'>
            {/* <i className='created_by'>Created by: {loggedInUser}</i> */}
          </td>
          <td className='t-left tracking-data'>
            {/* Tracking #: {order.retailerTrackingNumber} */}
          </td>
        </tr>
      </table>
    </div>
  )
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  const loggedInUser = state.SessionReducer.address.userName;
  const viewTypeStorage = localStorage.getItem('viewType');  
  const needHelpNum = state.SessionReducer?.wpMenu?.needHelpNum;
  return {
    storeId, userName, userId, loggedInUser,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    getprefer: state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    rdc: state.SessionReducer.rdc,    
    migratedRdcList:state.SessionReducer.multiStoreDetails?.migratedRdcList,
    needHelpNum
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({}, dispatch)
  );
};

export default withRouter( connect(mapStateToProps,mapDispatchToProps)(EditOrders));
